import gql from 'graphql-tag';

export const UPSERT_CLIENT = gql`
  mutation upsertClient(
    $client: clients_insert_input!
    $userId: Int!
    $on_conflict: clients_on_conflict!
  ) {
    insert_clients(objects: [$client], on_conflict: $on_conflict) {
      affected_rows
      returning {
        id
        client_users(where: { userId: { _eq: $userId } }) {
          id
        }
      }
    }
  }
`;

export const UPDATE_TICKET_PRIORITY = gql`
  mutation updateTicketPriority($ticketCode: String!, $priority: Int!) {
    update_tickets(
      where: { code: { _eq: $ticketCode } }
      _set: { priority: $priority }
    ) {
      returning {
        priority
      }
    }
  }
`;

export const UPDATE_TICKET_TYPE = gql`
  mutation updateTicketType($ticketCode: String!, $type: tickets_types_enum!) {
    update_tickets(
      where: { code: { _eq: $ticketCode } }
      _set: { type: $type }
    ) {
      returning {
        type
      }
    }
  }
`;

export const CREATE_AGENCY = gql`
  mutation insertAgency($agency: agencies_insert_input!, $userId: Int!) {
    insert_agencies(objects: [$agency]) {
      affected_rows
      returning {
        id
        agency_users(where: { userId: { _eq: $userId } }) {
          id
        }
      }
    }
  }
`;

export const CREATE_DEVELOPER = gql`
  mutation updateUser(
    $id: Int!
    $user: users_set_input!
    $developer: developers_insert_input!
  ) {
    update_users(_set: $user, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
    insert_developers(objects: [$developer]) {
      affected_rows
      returning {
        id
        login
      }
    }
  }
`;

export const INSERT_TASK_SUBMISSION = gql`
  mutation insertTaskSubmission($objects: [task_submissions_insert_input!]!) {
    insert_task_submissions(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GENERATE_GCP_STORAGE_LINKS = gql`
  mutation generateGcpStorageLinks(
    $fileName: String!
    $mimetype: String!
    $sizeInBytes: Int!
    $extension: String!
    $originalFileName: String!
    $sha1: String!
    $sha256: String!
    $sha512: String!
  ) {
    gcpStorageLinks(
      fileName: $fileName
      mimetype: $mimetype
      sizeInBytes: $sizeInBytes
      extension: $extension
      originalFileName: $originalFileName
      sha1: $sha1
      sha256: $sha256
      sha512: $sha512
    ) {
      sid
      uploadLink
      uploadLinkExpiresAt
      readLink
    }
  }
`;

export const INSERT_TICKET_ATTACHMENT = gql`
  mutation insertTicketAttachment(
    $objects: [ticket_attachments_insert_input!]!
    $onConflict: ticket_attachments_on_conflict!
  ) {
    insert_ticket_attachments(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        gcpObjectId
        ticketId
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($userId: Int!, $updatedProfile: users_set_input!) {
    update_users(where: { id: { _eq: $userId } }, _set: $updatedProfile) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_TICKET_CREDIT_APPROVAL = gql`
  mutation updateTicketCreditApproval(
    $id: Int!
    $status: ticket_credit_status_enum
    $reviewerId: Int
    $updatedAt: timestamptz!
  ) {
    update_ticket_credit_approvals(
      where: { id: { _eq: $id } }
      _set: {
        status: $status
        reviewedByUserId: $reviewerId
        updatedAt: $updatedAt
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_TICKET_CREDIT_COST = gql`
  mutation updateTicketCreditCost($ticketId: Int!, $costInCredits: Int!) {
    update_tickets_by_pk(
      pk_columns: { id: $ticketId }
      _set: { costInCredits: $costInCredits }
    ) {
      id
    }
  }
`;

export const REJECT_AND_CREATE_CREDIT_REQUEST = gql`
  mutation rejectAndCreateCreditRequest(
    $rejectedRequestId: Int!
    $rejectedRequest: ticket_credit_approvals_set_input!
    $newCreditRequest: ticket_credit_approvals_insert_input!
    $ticketId: Int!
    $lowerBudget: Int!
    $upperBudget: Int!
  ) {
    update_ticket_credit_approvals_by_pk(
      pk_columns: { id: $rejectedRequestId }
      _set: $rejectedRequest
    ) {
      id
    }
    insert_ticket_credit_approvals_one(object: $newCreditRequest) {
      id
    }
    update_tickets_by_pk(
      pk_columns: { id: $ticketId }
      _set: {
        costInCredits: $lowerBudget
        lowerBudget: $lowerBudget
        upperBudget: $upperBudget
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CREDIT_REQUEST = gql`
  mutation updateCreditRequest(
    $id: Int!
    $creditRequest: ticket_credit_approvals_set_input!
    $ticketId: Int!
    $lowerBudget: Int!
    $upperBudget: Int!
  ) {
    update_ticket_credit_approvals_by_pk(
      pk_columns: { id: $id }
      _set: $creditRequest
    ) {
      id
    }
    update_tickets_by_pk(
      pk_columns: { id: $ticketId }
      _set: {
        costInCredits: $lowerBudget
        lowerBudget: $lowerBudget
        upperBudget: $upperBudget
      }
    ) {
      id
    }
  }
`;

export const INSERT_TICKET_CREDIT_APPROVAL = gql`
  mutation insertTicketCreditApproval(
    $object: ticket_credit_approvals_insert_input!
    $ticketId: Int!
    $lowerBudget: Int!
    $upperBudget: Int!
  ) {
    insert_ticket_credit_approvals_one(object: $object) {
      id
    }
    update_tickets_by_pk(
      pk_columns: { id: $ticketId }
      _set: {
        costInCredits: $lowerBudget
        lowerBudget: $lowerBudget
        upperBudget: $upperBudget
      }
    ) {
      id
    }
  }
`;

export const REVIEW_CREDIT_REQUEST = gql`
  mutation reviewCreditRequest(
    $actionType: reviewActionType_enum!
    $defaultCreditBudget: Int
    $lowerBudget: Int!
    $reason: String
    $requestId: Int!
    $taskId: Int
    $ticketId: Int!
    $updatedTaskState: String
    $upperBudget: Int!
    $userId: Int
  ) {
    reviewCreditRequest(
      actionType: $actionType
      defaultCreditBudget: $defaultCreditBudget
      lowerBudget: $lowerBudget
      reason: $reason
      requestId: $requestId
      taskId: $taskId
      ticketId: $ticketId
      updatedTaskState: $updatedTaskState
      upperBudget: $upperBudget
      userId: $userId
    ) {
      id
    }
  }
`;

export const REQUEST_CREDIT_APPROVAL = gql`
  mutation requestCreditApproval(
    $actionType: requestActionType_enum!
    $defaultCreditBudget: Int
    $lowerBudget: Int!
    $reason: String
    $requestId: Int
    $taskId: Int
    $ticketId: Int!
    $updatedTaskState: String
    $upperBudget: Int!
    $userId: Int
  ) {
    requestCreditApproval(
      actionType: $actionType
      defaultCreditBudget: $defaultCreditBudget
      lowerBudget: $lowerBudget
      reason: $reason
      requestId: $requestId
      taskId: $taskId
      ticketId: $ticketId
      updatedTaskState: $updatedTaskState
      upperBudget: $upperBudget
      userId: $userId
    ) {
      id
    }
  }
`;

export const UPDATE_TASK_STATE_ONLY = gql`
  mutation updateTaskStateOnly($taskId: Int!, $updatedTaskState: String!) {
    taskStateUpdate(taskId: $taskId, updatedTaskState: $updatedTaskState) {
      currentState
      state
      error
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation updateTask($taskData: tasks_set_input!, $taskID: Int!) {
    update_tasks_by_pk(pk_columns: { id: $taskID }, _set: $taskData) {
      id
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation updateTicket($ticketData: tickets_set_input!, $ticketID: Int!) {
    update_tickets_by_pk(pk_columns: { id: $ticketID }, _set: $ticketData) {
      id
    }
  }
`;

export const UPDATE_NEXT_TASK = gql`
  mutation updateNextTask($taskId: Int!, $taskData: tasks_set_input!) {
    update_tasks(
      where: { task_dependencies: { childTaskId: { _eq: $taskId } } }
      _set: $taskData
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPSERT_TASK_REVIEWERS = gql`
  mutation upsertTaskReviewers(
    $taskId: Int!
    $input: [task_reviewers_insert_input!]!
    $reviewerIdsToRemove: [String!]!
  ) {
    delete_task_reviewers(
      where: {
        _and: [
          { task: { id: { _eq: $taskId } } }
          { developersId: { _in: $reviewerIdsToRemove } }
        ]
      }
    ) {
      affected_rows
    }
    insert_task_reviewers(
      objects: $input
      on_conflict: {
        constraint: PK_907b0f58778afe6e17250ae416e
        update_columns: developersId
      }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_TASKS = gql`
  mutation upsertTasks(
    $tasks: [tasks_insert_input!]!
    $onConflict: tasks_on_conflict
  ) {
    insert_tasks(objects: $tasks, on_conflict: $onConflict) {
      returning {
        id
      }
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword(
    $userId: Int!
    $password: String!
    $token: String!
  ) {
    changePassword(userId: $userId, password: $password, token: $token) {
      id
    }
  }
`;

export const INSERT_TICKET_SOURCE = gql`
  mutation addTicketSource(
    $clientId: String!
    $token: String!
    $key: String!
    $projectName: String!
    $sourceType: String!
    $sourceSid: String!
  ) {
    addTicketSource(
      clientId: $clientId
      token: $token
      key: $key
      projectName: $projectName
      sourceType: $sourceType
      sourceSid: $sourceSid
    ) {
      id
    }
  }
`;

export const INSERT_DISCUSSION_COMMENT = gql`
  mutation insertDiscussionComment(
    $token: String!
    $discussionCommentInput: insertDiscussionCommentInput!
  ) {
    insertDiscussionComment(token: $token, args: $discussionCommentInput) {
      updatedCommentId
    }
  }
`;

export const UPSERT_TICKET_ONE = gql`
  mutation upsertTicketOne(
    $ticket: tickets_insert_input!
    $onConflict: tickets_on_conflict!
  ) {
    insert_tickets_one(object: $ticket, on_conflict: $onConflict) {
      id
      code
      clientId
    }
  }
`;

export const ADD_GIT_SLICE = gql`
  mutation addGitSlice($input: addGitSliceInput!, $token: String!) {
    addGitSlice(args: $input, token: $token) {
      slicedFromBranchName
      slicedFromRepoId
      slicedFolders
      ignoredPaths
    }
  }
`;

export const INVITE_TO_CLIENT = gql`
  mutation inviteToClient($input: inviteToClientInput!, $token: String!) {
    inviteToClient(args: [$input], token: $token) {
      clientId
      email
      role
      projectId
    }
  }
`;

export const SEND_USER_DAILY_STANDUPS = gql`
  mutation sendUserDailyStandups(
    $standups: [sendUserDailyStandupsInput!]!
    $token: String!
  ) {
    sendUserDailyStandups(args: $standups, token: $token) {
      postedToSlack
      userDailyStandupId
    }
  }
`;

export const REQUEST_TASK_CREDIT_APPROVAL = gql`
  mutation requestTaskCreditApproval(
    $actionType: requestActionType_enum!
    $defaultCreditBudget: Int
    $lowerBudget: Int!
    $reason: String
    $requestId: Int
    $taskId: Int!
    $updatedTaskState: String
    $upperBudget: Int!
    $userId: Int
    $ticketCreditApprovalId: Int!
  ) {
    requestTaskCreditApproval(
      actionType: $actionType
      defaultCreditBudget: $defaultCreditBudget
      lowerBudget: $lowerBudget
      reason: $reason
      requestId: $requestId
      taskId: $taskId
      updatedTaskState: $updatedTaskState
      upperBudget: $upperBudget
      userId: $userId
      ticketCreditApprovalId: $ticketCreditApprovalId
    ) {
      id
    }
  }
`;

export const REVIEW_TASK_CREDIT_REQUEST = gql`
  mutation reviewTaskCreditRequest(
    $actionType: reviewActionType_enum!
    $defaultCreditBudget: Int
    $lowerBudget: Int!
    $reason: String
    $requestId: Int!
    $taskId: Int!
    $updatedTaskState: String
    $upperBudget: Int!
    $userId: Int
    $ticketCreditApprovalId: Int!
  ) {
    reviewTaskCreditRequest(
      actionType: $actionType
      defaultCreditBudget: $defaultCreditBudget
      lowerBudget: $lowerBudget
      reason: $reason
      requestId: $requestId
      taskId: $taskId
      updatedTaskState: $updatedTaskState
      upperBudget: $upperBudget
      userId: $userId
      ticketCreditApprovalId: $ticketCreditApprovalId
    ) {
      id
    }
  }
`;
