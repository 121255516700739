import React from 'react';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps as DateFieldProps,
} from '@material-ui/pickers';

const DateField: React.FC<DateFieldProps> = ({
  format = 'DD/MM/YYYY',
  variant = 'inline',
  inputVariant = 'outlined',
  margin = 'normal',
  id = 'date-picker-inline',
  ...other
}) => {
  return (
    <KeyboardDatePicker
      variant={variant}
      format={format}
      inputVariant={inputVariant}
      margin={margin}
      id={id}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...other}
    />
  );
};

export default DateField;
export type { DateFieldProps };
