import { Card, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/styles';
import { useAuthContext } from 'components/auth';
import { useSnackbar } from 'lib/hooks';
import { getGitStartHooksOrigins } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { Theme } from 'template/theme';
import { IntegrationType } from '../types';
import onGitHubAppClick from './onClick';

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    height: '160px',
    width: '100%',
    maxWidth: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#231F20',
    },
  },
  buttonContentHover: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoverText: {
    color: 'white',
    marginRight: theme.spacing(0.3),
  },
  openInNewIcon: {
    height: 18,
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  onSuccess?:
    | null
    | ((integrationName: IntegrationType, message?: string) => void);
  onClick?: null | ((integrationName: IntegrationType) => void);
};

const GithubAppIntegration = ({ onSuccess = null, onClick = null }: Props) => {
  const { jwtToken } = useAuthContext();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [hovered, setHovered] = useState(false);

  const receivePopupMessage = (e: MessageEvent) => {
    if (getGitStartHooksOrigins.includes(e.origin)) {
      const data = JSON.parse(e.data);
      if (data.error) {
        showSnackbar({
          variant: 'error',
          message: data.error,
        });
      } else if (!onSuccess) {
        showSnackbar({
          variant: 'success',
          message: 'Successfully connected GitHub',
        });
      } else {
        onSuccess('GitHub', 'Successfully connected GitHub');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', receivePopupMessage);
    return () => {
      window.removeEventListener('message', receivePopupMessage);
    };
  }, []);

  const handleInstall = () => {
    if (onClick) onClick('GitHub');
    const popup = onGitHubAppClick(jwtToken);
    if (!popup) {
      showSnackbar({
        variant: 'error',
        message: 'Error opening pop-up window',
      });
    }
  };

  return (
    <Card
      className={classes.button}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleInstall}
    >
      {hovered ? (
        <div className={classes.buttonContentHover}>
          <Typography variant="button" className={classes.hoverText}>
            Configure
          </Typography>
          <OpenInNewIcon className={classes.openInNewIcon} />
        </div>
      ) : (
        <div className={classes.buttonContent}>
          <img src="/images/logos/GitHub-logo.svg" alt="github mark" />
          <Typography variant="h4">GitHub</Typography>
        </div>
      )}
    </Card>
  );
};

export default GithubAppIntegration;
