import { makeStyles } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from 'components/auth';
import { useApolloQuery } from 'lib/client/utils';
import {
  getStripePaymentMethods,
  getStripePaymentMethodsVariables,
} from '@engine/common/graphql/roles/client/generated/getStripePaymentMethods';
import { FETCH_STRIPE_PAYMENT_METHODS } from 'lib/graphql/roles/client/queries';
import getConfig from 'next/config';
import React, { useEffect, useState } from 'react';
import { Theme } from 'template/theme';
import StripePaymentCheckout, {
  StripePaymentCheckoutProps,
} from './StripePaymentCheckout';

const STRIPE_PK_KEY = getConfig?.()?.publicRuntimeConfig?.STRIPE_PK_KEY;

const useStyles = makeStyles<Theme>(theme => ({
  actions: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: 700,
    margin: '0 auto',
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const stripePromise = loadStripe(STRIPE_PK_KEY);

type Props = Omit<StripePaymentCheckoutProps, 'paymentMethods'>;
const StripePayment: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const {
    costInUSD,
    costInCredits,
    invoiceIds,
    onSuccessful,
    setError,
    setLoading,
    clientId,
  } = props;
  const ctx = useAuth(true);

  const { data, error } = useApolloQuery<
    getStripePaymentMethods,
    getStripePaymentMethodsVariables
  >({
    ctx,
    role: 'client',
    query: FETCH_STRIPE_PAYMENT_METHODS,
    variables: {
      clientId,
    },
  });

  const paymentMethods = data?.getStripePaymentMethods.paymentMethods || [];

  useEffect(() => {
    stripePromise.then(() => setLoaded(true));
  }, []);

  return (
    <div className={classes.content}>
      {loaded && (!!data || !!error) ? (
        <Elements stripe={stripePromise}>
          <StripePaymentCheckout
            setError={setError}
            setLoading={setLoading}
            onSuccessful={onSuccessful}
            costInUSD={costInUSD}
            paymentMethods={paymentMethods}
            costInCredits={costInCredits}
            invoiceIds={invoiceIds}
            clientId={clientId}
          />
        </Elements>
      ) : null}
    </div>
  );
};

export default StripePayment;
