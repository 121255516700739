import React from 'react';
import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@material-ui/lab';

export type SkeletonProps = MuiSkeletonProps & { heightScale?: number };

const Skeleton = (props: SkeletonProps) => {
  return (
    <MuiSkeleton
      animation="wave"
      {...props}
      style={{
        transform: `scale(1, ${props.heightScale ?? 1})`,
        ...props.style,
      }}
    />
  );
};

export default Skeleton;
