import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'lib/hooks';
import React from 'react';
import { Theme } from 'template/theme';
import { IntegrationType } from '../types';

const useStyles = makeStyles<Theme>(() => ({
  button: {
    height: '160px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  onSuccess?:
    | null
    | ((integrationName: IntegrationType, message?: string) => void);
};

const GitlabIntegration = ({ onSuccess }: Props) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const handleInstall = () => {
    if (onSuccess) onSuccess('GitLab', '');
    else {
      showSnackbar({
        open: true,
        variant: 'error',
        message: 'GitLab integration is not implemented yet',
      });
    }
  };

  return (
    <Card className={classes.button} onClick={handleInstall}>
      <div className={classes.buttonContent}>
        <img src="/images/logos/GitLab-logo.svg" alt="gitlab icon" />
        <Typography variant="h4">GitLab</Typography>
      </div>
    </Card>
  );
};

export default GitlabIntegration;
