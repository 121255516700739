import gql from 'graphql-tag';
import { TICKET_TIME_TO_SURFACE } from 'lib/graphql/roles/user/fragments/time_to_surface';

export const USER_AVATAR_DETAILS = gql`
  fragment USER_AVATAR_DETAILS on users {
    id
    firstName
    lastName
    avatarUrl
  }
`;

// TODO?: Remove this fragment and use DISCUSSION_COMMENT_PARSED_DETAILS instead??
export const DISCUSSION_COMMENT_DETAILS = gql`
  fragment DISCUSSION_COMMENT_DETAILS on discussion_comments {
    id
    syncTableName
    rawBodyText
    discussionSourceId
    authorUserId
    authorUser {
      ...USER_AVATAR_DETAILS
    }
    parentCommentId
    rawBodyTypeId
    commentedAt
    diffHunk
    isResolved
  }
  ${USER_AVATAR_DETAILS}
`;

export const DISCUSSION_COMMENT_PARSED_DETAILS = gql`
  fragment DISCUSSION_COMMENT_PARSED_DETAILS on discussion_comments_parsed {
    id
    discussion_comment {
      id
      isInternalForClient
    }
    authorFirstName
    authorLastName
    authorAvatarUrl
    rawBodyText
    rawBodyType
    commentedAt
    isResolved
    diffHunk
    discussionName
    path
    # TODO: add file path once its been added to discussion_comments_parsed view
    discussionSourceId
  }
`;

// FIXME: Fragment should be on generalized table/view like discussion_sources_parsed (does not exist yet) instead of discussions_related_to_tickets
export const DISCUSSION_SOURCE_DETAILS = gql`
  fragment DISCUSSION_SOURCE_DETAILS on discussions_related_to_tickets {
    discussionSourceId
    discussionName
    ticket {
      id
      code
    }
  }
`;

export const NOTIFICATION_DETAILS = gql`
  fragment NOTIFICATION_DETAILS on notifications {
    id
    body
    appLink
    discussionCommentId
    createdAt
    taskId
    ticketId
    isSeen
    isArchived
    discussion_comment {
      ...DISCUSSION_COMMENT_DETAILS
    }
  }
  ${DISCUSSION_COMMENT_DETAILS}
`;

// convert to aggregate
export const FETCH_NOTIFICATIONS_FOR_TOP_BAR = gql`
  query fetchNotificationsForTopBar($userId: Int!, $limit: Int) {
    notifications_aggregate(
      where: { user: { id: { _eq: $userId } } }
      limit: $limit
      order_by: { createdAt: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const FETCH_NOTIFICATIONS = gql`
  query fetchNotifications($userId: Int!, $limit: Int) {
    notifications(
      where: { user: { id: { _eq: $userId } } }
      limit: $limit
      order_by: { createdAt: desc }
    ) {
      user {
        id
        ...USER_AVATAR_DETAILS
      }
      ...NOTIFICATION_DETAILS
    }
  }
  ${USER_AVATAR_DETAILS}
  ${NOTIFICATION_DETAILS}
`;

export const DEVELOPER_AVATAR_DETAILS = gql`
  fragment DEVELOPER_AVATAR_DETAILS on developers {
    id
    user {
      ...USER_AVATAR_DETAILS
    }
  }
  ${USER_AVATAR_DETAILS}
`;

export const TICKET_STATUS = gql`
  fragment TICKET_STATUS on tickets {
    id
    status
    needsApproval
  }
`;

export const TASK_LIST_ITEM = gql`
  fragment TASK_LIST_ITEM on tasks {
    clientId
    ticketCode
    taskCode
    # above 3 IDs/codes are required to construct the task URL
    id
    title
    status
    type
    developerByDeveloperid {
      ...DEVELOPER_AVATAR_DETAILS
    }
    developerByManagerid {
      ...DEVELOPER_AVATAR_DETAILS
    }
    task_reviewers {
      developer {
        ...DEVELOPER_AVATAR_DETAILS
      }
    }
  }
  ${DEVELOPER_AVATAR_DETAILS}
`;

export const CREDIT_REQUEST_DETAILS = gql`
  fragment CREDIT_REQUEST_DETAILS on ticket_credit_approvals {
    id
    status
    lowerBudget
    upperBudget
    reason
  }
`;

export const TICKET_STATUS_POPOVER = gql`
  fragment TICKET_STATUS_POPOVER on tickets {
    statusUpdatedAt
    statusUpdateReason
    tasks(order_by: { id: asc }) {
      id
      taskCode
      status
    }
  }
`;

export const FETCH_TICKET_STATUS_POPOVER = gql`
  query fetchTicketStatusPopover($ticketId: Int!) {
    ticket: tickets_by_pk(id: $ticketId) {
      ...TICKET_STATUS_POPOVER
      clientId
    }
  }
  ${TICKET_STATUS_POPOVER}
`;

export const FETCH_AGENCY_PROFILE = gql`
  query fetchAgencyProfile($agencyId: String!) {
    agency: agencies_by_pk(id: $agencyId) {
      id
      name
    }
  }
`;

export const FETCH_DEVELOPERS_LIST_WITH_SKILLS = gql`
  query fetchDevelopersListWithSkills {
    developers(
      where: {
        developer_contracts: {
          _or: [
            { gracePeriodEndDate: { _is_null: true } }
            { gracePeriodEndDate: { _gte: "now()" } }
          ]
        }
      }
    ) {
      user {
        firstName
        lastName
        user_skills {
          aggregatedMonthsOfExperience
          skill {
            name
          }
        }
      }
    }
  }
`;

export const FETCH_USER_INFO = gql`
  query fetchUserInfo($id: Int!) {
    user_teams(
      where: {
        _or: [
          {
            user_team_members: {
              _and: [
                { userId: { _eq: $id } }
                {
                  _or: [
                    { endAt: { _is_null: true } }
                    { endAt: { _gte: "now()" } }
                  ]
                }
              ]
            }
          }
          { isPublic: { _eq: true } }
        ]
      }
    ) {
      id
      teamName
    }

    user: users_by_pk(id: $id) {
      id
      firstName
      avatarUrl
      lastName
      defaultEmail
      nickname
      telephone
      login
      timezone

      user_emails {
        email
      }

      admin {
        id
      }

      developer {
        id
        developer_contracts {
          user {
            id
          }
        }
      }

      client_users {
        id
        role
        clientId
        client {
          id
          name
          onBoardedAt
          meetings {
            scheduledAt
          }
        }
      }

      agency_user {
        id
        agencyId
        agency {
          id
          name
        }
      }

      git_users {
        githubAccountLogin
      }

      user_contracts(
        where: {
          _or: [{ endDate: { _is_null: true } }, { endDate: { _gte: now } }]
        }
      ) {
        id
      }
    }
  }
`;

// TODO: Consolidate task_reviewers table and reviewerId column in tasks table?
export const GET_USER_DATA = gql`
  query getUserData(
    $developerId: String!
    $taskFilter: tasks_bool_exp!
    $ticketFilter: tickets_bool_exp!
    $taskSort: tasks_order_by!
    $ticketSort: tickets_order_by!
  ) {
    tasks(
      where: {
        _and: [
          {
            _or: [
              { developerByManagerid: { id: { _eq: $developerId } } }
              { developerByDeveloperid: { id: { _eq: $developerId } } }
              { developerByReviewerid: { id: { _eq: $developerId } } }
              { task_reviewers: { developersId: { _eq: $developerId } } }
            ]
          }
          $taskFilter
        ]
      }
      order_by: [$taskSort]
    ) {
      id
      taskCode
      ticketCode
      title
      client {
        id
        name
      }
    }
    tickets(
      where: { _and: [{ managerId: { _eq: $developerId } }, $ticketFilter] }
      order_by: [$ticketSort]
    ) {
      id
      code
      title
      client {
        id
        name
      }
    }
    task_reviews(
      where: {
        _or: [
          { managerId: { _eq: $developerId } }
          { developerId: { _eq: $developerId } }
        ]
      }
    ) {
      id
      task {
        taskCode
        id
        title
        ticketCode
        client {
          id
          name
        }
        ticket {
          id
          code
          title
          client {
            id
            name
          }
        }
      }
    }
    user_teams {
      id
      teamName
    }
  }
`;

export const FETCH_TASK_DETAILS = gql`
  query fetchTaskDetails($taskId: Int!, $userId: Int) {
    task_dependencies(where: { childTaskId: { _eq: $taskId } }) {
      parentTaskId
      childTaskId
      taskByChildTaskId {
        id
        title
        taskCode
        description
        task_dependencies {
          parentTaskId
          childTaskId
          task {
            id
            title
            taskCode
            description
            task_dependencies {
              parentTaskId
              childTaskId
            }
            task_reviews {
              id
              approvesTaskId
              developer {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              developerByDeveloperid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
            }
            status
            type
            ticketCode
            developerByDeveloperid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            developerByManagerid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            developerByReviewerid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            client {
              id
              name
            }
          }
        }
        task_reviews {
          id
          approvesTaskId
          developer {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByDeveloperid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
        }
        status
        type
        ticketCode
        developerByDeveloperid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByManagerid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByReviewerid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        client {
          id
          name
        }
      }
      task {
        id
        title
        taskCode
        description
        task_dependencies {
          parentTaskId
          childTaskId
          task {
            id
            title
            taskCode
            description
            task_dependencies {
              parentTaskId
              childTaskId
            }
            task_reviews {
              id
              approvesTaskId
              developer {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              developerByDeveloperid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
            }
            status
            type
            ticketCode
            developerByDeveloperid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            developerByManagerid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            developerByReviewerid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            client {
              id
              name
            }
          }
        }
        task_reviews {
          id
          approvesTaskId
          developer {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByDeveloperid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
        }
        status
        type
        ticketCode
        developerByDeveloperid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByManagerid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByReviewerid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        client {
          id
          name
        }
      }
    }
    tasks(where: { id: { _eq: $taskId } }) {
      id
      taskCode
      budget
      description
      createdAt
      branchName
      costInUSD
      status
      title
      updatedAt
      completedAt
      ticketCode
      branchName
      type
      clientId
      gitRepoId
      managerId
      reviewerId
      developerId
      isBillable
      duplicateOfTaskId
      client {
        id
        defaultCreditBudget
        client_users(where: { userId: { _eq: $userId } }) {
          userId
        }
      }
      ticket {
        id
        description
        client_project {
          client_project_pricing_plans(
            where: {
              _or: [{ endAt: { _is_null: true } }, { endAt: { _gte: "now()" } }]
            }
            limit: 1
          ) {
            id
            credit_pricing_plan {
              id
              creditsPerMidLevelHour
            }
          }
        }
        ticket_attachments {
          ticketId
          gcpObjectId
          gcp_storage_object {
            id
            readLink
            mimetype
            originalFileName
            sizeInBytes
          }
        }
      }
      task_attributes {
        id
        remainingDuplicateLimit
      }
      duplicatedTasks {
        id
        developerId
      }
      git_pull_request {
        id
        title
        git_pull_request_slice {
          id
          git_repo_slice {
            id

            git_repo_sliced_from {
              id
              name
            }

            git_repo_sliced_to {
              id
              name
            }
          }
          git_slice_pushes {
            startedAt
            finishedAt
            error
          }
        }

        syncable_entity {
          id
          sourceUrl
        }
        git_pull_request_reviewers {
          reviewerUserId
          pullRequestId
          git_user {
            id
            user {
              login
              ...USER_AVATAR_DETAILS
            }
          }
          pullRequestId
        }
        git_pull_request_state {
          id
        }
        base_branch_ref {
          branchName
          repoId
          git_branches {
            id
            git_branch_commits {
              git_commit {
                id
                git_user {
                  id
                  user {
                    login
                    ...USER_AVATAR_DETAILS
                  }
                }
              }
            }
          }
        }
      }
      task_daily_standups(limit: 1, order_by: { submittedAt: desc }) {
        id
        taskId
        summary
        submittedAt
        updatedETA
      }
      task_reviewers {
        tasksId
        developersId
        developer {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
      }
      task_technologies {
        tasksId
        technology {
          id
          name
        }
      }
      task_reviews {
        id
        approvesTaskId
        developer {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByDeveloperid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
      }
      developerByDeveloperid {
        id
        login
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      developerByManagerid {
        id
        login
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      developerByReviewerid {
        id
        login
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      task_dependencies {
        parentTaskId
        childTaskId
        task {
          id
          title
          taskCode
          description
          task_dependencies {
            parentTaskId
            childTaskId
            task {
              id
              title
              taskCode
              description
              task_dependencies {
                parentTaskId
                childTaskId
              }
              task_reviews {
                id
                approvesTaskId
                developer {
                  id
                  user {
                    ...USER_AVATAR_DETAILS
                  }
                }
                developerByDeveloperid {
                  id
                  user {
                    ...USER_AVATAR_DETAILS
                  }
                }
              }
              status
              type
              ticketCode
              developerByDeveloperid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              developerByManagerid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              developerByReviewerid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              client {
                id
                name
              }
            }
          }
          task_reviews {
            id
            approvesTaskId
            developer {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            developerByDeveloperid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
          }
          status
          type
          ticketCode
          developerByDeveloperid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByManagerid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByReviewerid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          client {
            id
            name
          }
        }
        taskByChildTaskId {
          id
          title
          taskCode
          description
          task_dependencies {
            parentTaskId
            childTaskId
            task {
              id
              title
              taskCode
              description
              task_dependencies {
                parentTaskId
                childTaskId
              }
              status
              type
              ticketCode
              developerByDeveloperid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              developerByManagerid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              developerByReviewerid {
                id
                user {
                  ...USER_AVATAR_DETAILS
                }
              }
              client {
                id
                name
              }
            }
          }
          task_reviews {
            id
            approvesTaskId
            developer {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
            developerByDeveloperid {
              id
              user {
                ...USER_AVATAR_DETAILS
              }
            }
          }
          status
          type
          ticketCode
          developerByDeveloperid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByManagerid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByReviewerid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          client {
            id
            name
          }
        }
      }
    }
    users(where: { user_time_logs: { taskId: { _eq: $taskId } } }) {
      user_time_logs_aggregate(where: { taskId: { _eq: $taskId } }) {
        aggregate {
          sum {
            timeSpentInSecs
          }
        }
      }
      ...USER_AVATAR_DETAILS
    }
    developers(
      where: { user: { user_contracts: { id: { _is_null: false } } } }
      order_by: { user: { firstName: asc } }
    ) {
      id
      user {
        ...USER_AVATAR_DETAILS
      }
    }
    dev_tasks_total: tasks_aggregate(
      where: {
        _and: [
          { status: { _in: [in_progress, needs_changes] } }
          { developerByDeveloperid: { user: { id: { _eq: $userId } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    tasks_total_cost: tasks_aggregate(
      where: {
        _and: [
          { status: { _in: [in_progress, needs_changes] } }
          { developerByDeveloperid: { user: { id: { _eq: $userId } } } }
        ]
      }
    ) {
      aggregate {
        sum {
          costInUSD
        }
      }
    }
  }
  ${USER_AVATAR_DETAILS}
`;

export const FETCH_TICKET_BY_TICKETCODE = gql`
  query fetchTicketDetailsByTicketCode($ticketCode: String!) {
    tickets(where: { code: { _eq: $ticketCode } }) {
      ...TICKET_TIME_TO_SURFACE
      status
      statusUpdateReason
      code
      completedAt
      clientId
      client {
        defaultCreditBudget
      }
      cancelledAt
      createdAt
      costInCredits
      description
      id
      invoiceId
      startedAt
      type
      managerId
      manager {
        ...USER_AVATAR_DETAILS
      }
      syncedAt
      title
      updatedAt
      ticketLink
      ticket_daily_standups {
        message
        updatedAt
        updatedETA
      }
      ticket_attachments {
        gcp_storage_object {
          id
          readLink
          mimetype
          originalFileName
          sizeInBytes
        }
      }
      tasks {
        taskCode
        id
        title
        description
        createdAt
        branchName
        prLink: git_pull_request {
          id
          title
          syncable_entity {
            id
            sourceUrl
          }
        }
        status
        type
        ticketCode
        task_technologies {
          technology {
            id
            name
          }
        }
        task_reviews {
          id
          approvesTaskId
          developer {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByDeveloperid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
        }
        developerByDeveloperid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByManagerid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByReviewerid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        task_daily_standups {
          summary
          updatedAt
          updatedETA
        }
      }
      client_project {
        id
        name
      }
      ticket_credit_approvals(order_by: { createdAt: desc }) {
        id
        status
        upperBudget
        lowerBudget
      }
      ticket_credit_approval {
        need_approval
      }
    }
    users(
      where: { user_time_logs: { ticket: { code: { _eq: $ticketCode } } } }
    ) {
      user_time_logs_aggregate(
        where: { ticket: { code: { _eq: $ticketCode } } }
      ) {
        aggregate {
          sum {
            timeSpentInSecs
          }
        }
      }
      ...USER_AVATAR_DETAILS
    }
  }
  ${TICKET_TIME_TO_SURFACE}
  ${USER_AVATAR_DETAILS}
`;

export const FETCH_TIMELINE_BY_TICKET_ID = gql`
  query fetchTicketTimeline($ticketId: Int!) {
    discussion_comments_parsed(
      where: {
        relatedToTicketId: { _eq: $ticketId }
        parentCommentId: { _is_null: true }
      }
      order_by: { commentedAt: desc_nulls_last }
    ) {
      ...DISCUSSION_COMMENT_PARSED_DETAILS
      replies: child_comments_parsed {
        ...DISCUSSION_COMMENT_PARSED_DETAILS
      }
    }
  }
  ${DISCUSSION_COMMENT_PARSED_DETAILS}
`;

export const FETCH_DISCUSSION_SOURCES_BY_TICKET_ID = gql`
  query fetchTicketDiscussionSources($ticketId: Int!) {
    ticketDefaultSource: discussions_related_to_tickets(
      where: { ticketId: { _eq: $ticketId }, source: { _eq: "ticket" } }
    ) {
      ...DISCUSSION_SOURCE_DETAILS
    }
    otherSources: discussions_related_to_tickets(
      where: { ticketId: { _eq: $ticketId }, source: { _neq: "ticket" } }
    ) {
      ...DISCUSSION_SOURCE_DETAILS
    }
  }
  ${DISCUSSION_SOURCE_DETAILS}
`;

export const FETCH_TASK_BY_TICKETCODE_AND_TASKCODE = gql`
  query fetchTaskByCodes(
    $ticketCode: String!
    $taskCode: String!
    $clientId: String!
  ) {
    tasks(
      where: {
        _and: [
          { ticketCode: { _eq: $ticketCode } }
          { taskCode: { _eq: $taskCode } }
          { ticket: { clientId: { _eq: $clientId } } }
        ]
      }
      limit: 1
    ) {
      id
      title
      status
      taskCode
      description
      lowerBudget
      upperBudget
      specLink
      git_pull_request {
        number
        git_repo {
          name
        }
      }
      task_daily_standups(limit: 1, order_by: { submittedAt: desc }) {
        id
        taskId
        summary
        submittedAt
        updatedETA
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      task_reviewers {
        tasksId
        developersId
        developer {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
      }
      developerByManagerid {
        id
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      developerByDeveloperid {
        id
        user {
          ...USER_AVATAR_DETAILS
        }
      }
    }
  }
  ${USER_AVATAR_DETAILS}
`;

export const FETCH_TICKET_APPROVAL = gql`
  query fetchTicketApproval($ticketCode: String!) {
    ticket_credit_approvals(
      where: { ticket: { code: { _eq: $ticketCode } } }
      order_by: { createdAt: desc }
    ) {
      id
      createdAt
      updatedAt
      lowerBudget
      upperBudget
      status
      applicant {
        id
        firstName
        lastName
        avatarUrl
        developer {
          id
        }
      }
      reviewer {
        firstName
        lastName
      }
    }
  }
`;

export const FETCH_PENDING_TICKET_BLOCKERS_BY_TICKETCODE = gql`
  query fetchPendingTicketBlockers($ticketCode: String!) {
    client_blockers(
      where: {
        _and: {
          status: { _eq: pending }
          ticket_client_blockers: { ticket: { code: { _eq: $ticketCode } } }
        }
      }
      order_by: { status: asc }
    ) {
      id
    }
  }
`;

export const FETCH_CLIENT_MANAGER_APPROVAL_USERS = gql`
  query fetchClientManagerApprovalUsers($clientId: String!) {
    client_managers_view(where: { clientId: { _eq: $clientId } }) {
      userId
    }
    client_users(
      where: { clientId: { _eq: $clientId } }
      order_by: { user: { firstName: asc } }
    ) {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const FETCH_TASK_STATES = gql`
  query fetchTaskStates($taskId: Int!) {
    taskPossibleStates(taskId: $taskId) {
      error
      state
      taskId
      currentState
    }
    task: tasks_by_pk(id: $taskId) {
      id
      type
    }
  }
`;

export const FETCH_ALL_TASKS = gql`
  query fetchAllTasks($taskFilter: tasks_bool_exp!) {
    tasks(
      where: {
        _and: [
          { status: { _neq: finished } }
          { status: { _neq: cancelled } }
          $taskFilter
        ]
      }
      order_by: { updatedAt: desc }
    ) {
      id
      title
      description
      task_reviews {
        id
        approvesTaskId
        developer {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
        developerByDeveloperid {
          id
          user {
            ...USER_AVATAR_DETAILS
          }
        }
      }
      status
      type
      ticketCode
      taskCode
      task_dependencies {
        parentTaskId
        childTaskId
        task {
          id
          title
          taskCode
          description
          task_dependencies {
            parentTaskId
            childTaskId
          }
          status
          type
          ticketCode
          developerByDeveloperid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByManagerid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          developerByReviewerid {
            id
            user {
              ...USER_AVATAR_DETAILS
            }
          }
          client {
            id
            name
          }
        }
      }
      developerByDeveloperid {
        id
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      developerByManagerid {
        id
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      developerByReviewerid {
        id
        user {
          ...USER_AVATAR_DETAILS
        }
      }
      client {
        id
        name
      }
    }
  }
  ${USER_AVATAR_DETAILS}
`;

export const FETCH_PR_BY_TASK = gql`
  query fetchPRsByTask($taskId: Int!, $userId: Int!) {
    task: tasks_by_pk(id: $taskId) {
      id
      ticket {
        id
        ticket_repos {
          git_repo {
            id
            git_pull_requests(
              where: {
                state: { _neq: CLOSED }
                _or: [
                  { git_user: { user: { id: { _eq: $userId } } } }
                  {
                    git_pull_request_assignees: {
                      git_user: { user: { id: { _eq: $userId } } }
                    }
                  }
                ]
              }
            ) {
              id
              title
              git_repo {
                id
                github_repo_by_sid {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_SLAS = gql`
  query fetchSLAs($userId: Int!) {
    client_standups_sla_view(
      where: {
        client: {
          _and: [
            { tickets: { developer: { user: { id: { _eq: $userId } } } } }
            { tickets: { completedAt: { _is_null: true } } }
            { tickets: { cancelledAt: { _is_null: true } } }
            { tickets: { status: { _nin: [finished, cancelled] } } }
          ]
        }
      }
    ) {
      clientId
      dueAt
    }
    ticket_standups_sla_view(
      where: {
        ticket: {
          _and: [
            { tasks: { status: { _nin: [backlog] } } }
            { completedAt: { _is_null: true } }
            { cancelledAt: { _is_null: true } }
            {
              _or: [
                { status: { _is_null: true } }
                { status: { _nin: [finished, cancelled] } }
              ]
            }
            {
              _or: [
                {
                  tasks: {
                    developerByManagerid: { user: { id: { _eq: $userId } } }
                  }
                }
              ]
            }
          ]
        }
      }
    ) {
      dueAt
      ticketId
    }
    user_team_stats(
      where: {
        user_team_members: {
          _and: [{ userId: { _eq: $userId } }, { endAt: { _is_null: true } }]
        }
      }
    ) {
      id
      available_tasks_in_team
      available_tickets_in_team
    }
    client_stats(where: { client_users: { userId: { _eq: $userId } } }) {
      id
      needs_credit_approval
    }
    task_standups_sla_view(
      where: {
        task: { developerByDeveloperid: { user: { id: { _eq: $userId } } } }
      }
    ) {
      dueAt
      taskId
    }
    user_standups_sla_view(where: { userId: { _eq: $userId } }) {
      dueAt
      userId
    }
  }
`;

export const FETCH_TEAM_MEMBERS = gql`
  query fetchTeamMembers($userId: Int!) {
    user_team_members(
      where: {
        _and: [
          { user_team: { user_team_members: { userId: { _eq: $userId } } } }
        ]
      }
    ) {
      user {
        ...USER_AVATAR_DETAILS
        developer {
          id
        }
      }
    }
  }
  ${USER_AVATAR_DETAILS}
`;

// Finished but not not added to any invoice
export const FETCH_UN_INVOICED_CLIENT_TICKETS = gql`
  query fetchUnInvoicedClientTickets($clientId: String!) {
    tickets(
      where: {
        _and: [
          { clientId: { _eq: $clientId } }
          { invoiceId: { _is_null: true } }
          { isInternal: { _eq: false } }
          {
            _or: [
              { completedAt: { _is_null: false } }
              { status: { _eq: finished } }
            ]
          }
        ]
      }
    ) {
      id
      ticketLink
      ...TICKET_STATUS
      statusUpdateReason
      code
      completedAt
      createdAt
      billableCostInCredits
      invoiceId
      costInCredits
      title
      client_project {
        id
        name
      }
      tasks {
        id
        status
        git_repo {
          id
          name
        }
      }
      ticket_credit_approval {
        need_approval
      }
      updatedAt
      ticket_daily_standups(order_by: { submittedAt: desc }, limit: 1) {
        updatedETA
      }
      ...TICKET_TIME_TO_SURFACE
    }
  }
  ${TICKET_STATUS}
  ${TICKET_TIME_TO_SURFACE}
`;

export const FETCH_TEAM_MEMBERS_LIST = gql`
  query fetchTeamMembersList(
    $userTeamId: Int!
    $sprintStart: timestamptz!
    $sprintEnd: timestamptz!
  ) {
    user_team: user_teams_by_pk(id: $userTeamId) {
      teamName
      user_team_members(
        where: {
          _and: [
            {
              _or: [
                { endAt: { _is_null: true } }
                { endAt: { _gte: $sprintEnd } }
              ]
            }
            { startAt: { _lte: $sprintEnd } }
            {
              user: {
                user_contracts: {
                  _and: [
                    { startDate: { _lte: $sprintEnd } }
                    {
                      _or: [
                        { gracePeriodEndDate: { _is_null: true } }
                        { gracePeriodEndDate: { _gte: $sprintEnd } }
                      ]
                    }
                  ]
                }
              }
            }
          ]
        }
        order_by: {
          user: {
            developer: { tasks_aggregate: { sum: { budget: desc_nulls_last } } }
          }
        }
      ) {
        user {
          ...USER_AVATAR_DETAILS
          login
          developer {
            tasks_aggregate: tasksByDeveloperid_aggregate(
              where: {
                _and: [
                  { startedAt: { _lte: $sprintEnd } }
                  {
                    _or: [
                      { completedAt: { _is_null: true } }
                      {
                        _and: [
                          { completedAt: { _lte: $sprintEnd } }
                          { completedAt: { _gte: $sprintStart } }
                        ]
                      }
                    ]
                  }
                ]
              }
            ) {
              aggregate {
                count
                sum {
                  budget
                }
              }
            }
          }
          user_time_logs_aggregate(
            where: {
              _or: [
                { finishedAt: { _is_null: true } }
                {
                  _and: [
                    { startedAt: { _gte: $sprintStart } }
                    { startedAt: { _lte: $sprintEnd } }
                    { finishedAt: { _gte: $sprintStart } }
                    { finishedAt: { _lte: $sprintEnd } }
                  ]
                }
              ]
            }
          ) {
            aggregate {
              sum {
                timeSpentInSecs
              }
            }
          }
          git_users {
            git_pull_request_reviews_aggregate(
              where: {
                _and: [
                  { createdAt: { _gte: $sprintStart } }
                  { createdAt: { _lte: $sprintEnd } }
                ]
              }
            ) {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
  ${USER_AVATAR_DETAILS}
`;

export const FETCH_TASK_STATUS_MODAL_DETAILS = gql`
  query fetchTaskStatusModalDetails(
    $taskId: Int!
    $include_taskReviewers: Boolean!
    $include_pullRequests: Boolean!
    $include_manageTasks: Boolean!
    $include_specLink: Boolean!
  ) {
    task: tasks_by_pk(id: $taskId) {
      title
      description
      specLink @include(if: $include_specLink)
      taskCode
      ticketCode
      lowerBudget
      upperBudget
      clientId
      isBillable
      startedAt
      developerByDeveloperid {
        ...DEVELOPER_AVATAR_DETAILS
      }
      task_reviewers @include(if: $include_taskReviewers) {
        developer {
          ...DEVELOPER_AVATAR_DETAILS
        }
      }
      git_pull_request @include(if: $include_pullRequests) {
        key: id
        value: title
      }

      ticket {
        id
        client_project {
          client_project_pricing_plans(
            where: {
              _or: [{ endAt: { _is_null: true } }, { endAt: { _gte: "now()" } }]
            }
            limit: 1
          ) {
            credit_pricing_plan {
              creditsPerMidLevelHour
            }
          }
        }
        client {
          id
          name
          defaultCreditBudget
        }
        ticket_credit_approvals(
          order_by: { createdAt: desc_nulls_last }
          limit: 1
        ) {
          id
          lowerBudget
          upperBudget
          status
          applicant {
            id
            developer {
              id
            }
            client_users {
              role
              clientId
            }
          }
        }
        tasks(
          where: { status: { _neq: cancelled } }
          order_by: { id: asc_nulls_last }
        ) @include(if: $include_manageTasks) {
          id
          title
          description
          taskCode
          type
          status
          lowerBudget
          upperBudget
          specLink
          isBillable
          developerByDeveloperid {
            ...DEVELOPER_AVATAR_DETAILS
          }
        }
      }
    }
    developers(
      where: {
        developer_contracts: {
          _or: [
            { gracePeriodEndDate: { _is_null: true } }
            { gracePeriodEndDate: { _gte: "now()" } }
          ]
        }
      }
      order_by: [
        { user: { firstName: asc_nulls_last } }
        { user: { lastName: asc_nulls_last } }
      ]
    ) {
      ...DEVELOPER_AVATAR_DETAILS
    }
    task_reviewers(where: { tasksId: { _eq: $taskId } }) {
      developersId
    }
  }
  ${DEVELOPER_AVATAR_DETAILS}
`;

export const FETCH_GITSTART_DEVS = gql`
  query fetchGitStartDevs {
    developers(
      where: {
        developer_contracts: {
          _or: [
            { gracePeriodEndDate: { _is_null: true } }
            { gracePeriodEndDate: { _gte: "now()" } }
          ]
        }
      }
      order_by: [
        { user: { firstName: asc_nulls_last } }
        { user: { lastName: asc_nulls_last } }
      ]
    ) {
      id
      user {
        ...USER_AVATAR_DETAILS
      }
    }
  }
  ${USER_AVATAR_DETAILS}
`;

export const FETCH_GITSTART_PRS = gql`
  query fetchGitStartPRs($userId: Int!, $afterDate: timestamptz!) {
    users_PRs: git_pull_requests(
      where: {
        _and: [
          { git_user: { userId: { _eq: $userId } } }
          { createdAt: { _gte: $afterDate } }
        ]
      }
      order_by: [{ updatedAt: desc_nulls_last }, { createdAt: desc_nulls_last }]
    ) {
      id
      title
    }
    others_PRs: git_pull_requests(
      where: {
        _and: [
          { git_user: { userId: { _neq: $userId } } }
          { createdAt: { _gte: $afterDate } }
        ]
      }
      order_by: [{ updatedAt: desc_nulls_last }, { createdAt: desc_nulls_last }]
    ) {
      id
      title
    }
  }
`;

export const FETCH_GITSTART_REPOS = gql`
  query fetchGitStartRepos($clientId: String) {
    git_repos(
      where: {
        _or: [
          { clientId: { _eq: $clientId } }
          { git_organization: { clientId: { _eq: $clientId } } }
        ]
      }
    ) {
      id
      name
    }
  }
`;

export const FETCH_GIT_USER_INFO = gql`
  query fetchGitUserInfo($userId: Int!) {
    git_users(where: { userId: { _eq: $userId } }) {
      id
      githubAccountLogin
      git_pull_requests {
        id
        repoId
      }
    }
  }
`;

export const FETCH_CLIENT_USER_POSITIONS = gql`
  query fetchClientUserPositions {
    client_user_positions {
      id
      description
    }
  }
`;

// DELETE ME: This query will only be used to redirect from the previous ticket links (/tickets/[ticketCode]) to the new ones (/clients/[clientId]/tickets/[ticketCode])
export const FETCH_CLIENTID_FROM_TICKET_TO_REDIRECT = gql`
  query fetchClientIdFromTicketToRedirect($ticketCode: String!) {
    tickets(where: { code: { _eq: $ticketCode } }, limit: 1) {
      clientId
    }
  }
`;

export const FETCH_CLIENT_JIRA_PROJECTS = gql`
  query fetchJiraProjectsForClient($clientId: String!) {
    jira_all: jira_organizations(where: { clientId: { _eq: $clientId } }) {
      jira_projects {
        id
        sid
        name
        key
        description
      }
    }
    jira_linked: ticket_sources(where: { clientId: { _eq: $clientId } }) {
      jiraProjectSid
      projectName
    }
  }
`;

export const TICKET_CLIENT_MESSAGE = gql`
  fragment TICKET_CLIENT_MESSAGE on tickets {
    id
    clientId
    title
    code
    status
    statusUpdatedAt
    updatedAt
    ticketLink
  }
`;

export const FETCH_CLIENT_PROJECT_MESSAGE_MODAL_DETAILS = gql`
  query fetchClientProjectMessageModalDetaills(
    $clientProjectId: Int!
    $ticketIds: [Int!]!
  ) {
    tickets(
      where: {
        id: { _in: $ticketIds }
        clientProjectId: { _eq: $clientProjectId } # redundant as all ticketIds should already be linked to project, but better to check
      }
    ) {
      ...TICKET_CLIENT_MESSAGE
      tasks {
        type
        status
        task_daily_standups(order_by: { updatedAt: desc }, limit: 1) {
          summary
        }
      }
    }
    tickets_needing_approval: tickets(
      where: {
        clientProjectId: { _eq: $clientProjectId }
        ticket_credit_approvals: { status: { _eq: pending } }
      }
    ) {
      ...TICKET_CLIENT_MESSAGE
      ticket_credit_approvals(
        where: { status: { _eq: pending } }
        order_by: { createdAt: desc }
        limit: 1
      ) {
        id
        reason
        lowerBudget
        upperBudget
      }
    }
  }
  ${TICKET_CLIENT_MESSAGE}
`;

export const FETCH_TICKET_CHANGES_BY_STATUS_DETAILS = gql`
  query fetchTicketChangesByStatusDetails($ticketCode: String!) {
    ticket_changes_by_status(
      where: { code: { _eq: $ticketCode } }
      order_by: { transitionedAt: asc }
    ) {
      status
      transitionedAt
      code
    }
  }
`;

export const FETCH_CLIENT_SID_AND_MANAGER_ID = gql`
  query fetchClientSid($clientId: String!) {
    jira_accounts(
      where: { jira_organization: { clientId: { _eq: $clientId } } }
    ) {
      sid
    }
    user_team_clients(where: { clientId: { _eq: $clientId } }) {
      managerId
    }
  }
`;
