import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import getConfig from 'next/config';
import { GraphQLError } from 'graphql';
import { useMemo } from 'react';
import { getUserFromToken } from '@engine/common/index';

const { HASURA_GRAPHQL_ENGINE_DOMAIN } =
  getConfig?.()?.publicRuntimeConfig ?? {};

export interface Record<T extends object = {}> {
  [key: string]: T;
}
export interface RecordMap {
  // theoretically, this should be `[dataID: DataID]`, but `DataID` is a string.
  [dataID: string]: Record | null | undefined;
}

let relayEnvironment: Environment;

function createEnvironment(_initialRecords: RecordMap, token: string) {
  return new Environment({
    network: Network.create(async (params, variables) => {
      console.log(
        `fetching query ${params.name} with ${JSON.stringify(variables)}`
      );
      const text = params.text;
      const host = HASURA_GRAPHQL_ENGINE_DOMAIN;
      const path = '/v1beta1/relay';

      const isSecure = !(
        host.indexOf('.local') !== -1 || host.indexOf('localhost') !== -1
      );

      // const user = getUserFromToken(token);
      const uri = `${isSecure ? 'https' : 'http'}://${host}${path}`;
      const requestHeaders: HeadersInit = new Headers([
        ['Content-Type', 'application/json'],
        ['credentials', 'same-origin'],
        ['x-gitstart-relay', 'true'],
      ]);

      requestHeaders.set('Authorization', `Bearer ${token}`);

      requestHeaders.set('x-hasura-role', 'user');

      const resp = await fetch(uri, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({
          query: text,
          variables,
        }),
      });

      if (!resp.ok) {
        throw new GraphQLError(resp.statusText);
      }

      return await resp.json();
    }),
    store: new Store(new RecordSource()),
  });
}

// inspired from: https://github.com/vercel/next.js/blob/canary/examples/with-relay-modern/lib/relay.js

export function initEnvironment(
  initialRecords: RecordMap,
  token: string | null | undefined
) {
  if (!token || !getUserFromToken(token)) {
    return null;
  }

  // Create a network layer from the fetch function
  const environment =
    relayEnvironment ?? createEnvironment(initialRecords, token);

  // If your page has Next.js data fetching methods that use Relay, the initial records
  // will get hydrated here
  if (initialRecords) {
    environment.getStore().publish(new RecordSource(initialRecords));
  }
  // For SSG and SSR always create a new Relay environment
  if (typeof window === 'undefined') return environment;

  // Create the Relay environment once in the client
  if (!relayEnvironment) relayEnvironment = environment;

  return relayEnvironment;
}

export function useEnvironment(
  initialRecords: RecordMap,
  token: string | undefined | null
) {
  const store = useMemo(() => initEnvironment(initialRecords, token), [
    initialRecords,
    token,
  ]);

  return store;
}
