import { useContext, useMemo } from 'react';
import { IToasts, ToastContext } from 'v2/components/atoms/Toast';

type IToastOptions = Partial<Pick<IToasts, 'id' | 'config' | 'type'>>;

const defaultToastId = 'app_toast';

const defaultConfig = {
  autoClose: true,
  delay: 5000,
};

export enum ToastType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export const useToast = () => {
  const { add, remove } = useContext(ToastContext);

  return useMemo(
    () => ({
      add: ({
        content,
        title,
        options,
      }: {
        content: string;
        title: string;
        options?: IToastOptions;
      }) => {
        add({
          type: options?.type ?? ToastType.info,
          content,
          title,
          id: options?.id ?? defaultToastId,
          config: { ...defaultConfig, ...options?.config },
        });
      },
      remove: (toastId: string) => remove(toastId),
    }),
    []
  );
};
