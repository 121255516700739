import React from 'react';
import Flex from 'ui/Flex';
import CircularSpinner from 'ui/Loader/CircularSpinner';

type Props = {
  height?: number;
};

const DefaultLoader = (props: Props) => {
  return (
    <Flex style={{ width: '100%', height: props.height ?? 100 }}>
      <CircularSpinner color="grey" fadeInTime="none" />
    </Flex>
  );
};

export default DefaultLoader;
