import { getClientInvoiceStatus } from 'lib/client/utils';
import { CLIENT_OVERVIEW_INVOICES } from '@engine/common/graphql/roles/client/generated/CLIENT_OVERVIEW_INVOICES';
import React, { createContext, useEffect, useState } from 'react';
import PendingInvoicesPaymentsModal from '../modals/PendingInvoicesPaymentModal';
import MakePayment from './MakePayment';

type InvoiceAnalytics = {
  unpaid: CLIENT_OVERVIEW_INVOICES[];
  paid: CLIENT_OVERVIEW_INVOICES[];
  partiallyPaid: CLIENT_OVERVIEW_INVOICES[];
};

const invoicesBreakDown = (
  invoices: CLIENT_OVERVIEW_INVOICES[],
  initialBreakdown: InvoiceAnalytics
) =>
  invoices.reduce((acc: InvoiceAnalytics, inv: CLIENT_OVERVIEW_INVOICES) => {
    const { costInUSD, dueAt } = inv;
    const { unpaid, partiallyPaid, paid } = acc;

    const status = getClientInvoiceStatus({
      costInUSD,
      dueDate: dueAt,
      currrent_paid_balance_in_usd: +(
        inv.client_invoice_balance?.currrent_paid_balance_in_usd || 0
      ),
      previous_balance_in_usd: +(
        inv.client_invoice_balance?.previous_balance_in_usd || 0
      ),
    });

    switch (status) {
      case 'not-required':
        return { ...acc, paid: [...paid, inv] };
      case 'paid':
        return { ...acc, paid: [...paid, inv] };
      case 'partially-paid':
        return { ...acc, partiallyPaid: [...partiallyPaid, inv] };
      default:
        return { ...acc, unpaid: [...unpaid, inv] };
    }
  }, initialBreakdown);

type PaymentContextType = {
  control: number;
  selectedInvoiceIds: null | number[];
  activate: (selectedInvoiceIds: number[] | null) => void;
};

export const PaymentContext = createContext<PaymentContextType | null>(null);

export const getInvoicesAnalytics = (invoices: CLIENT_OVERVIEW_INVOICES[]) => {
  const initialAnalytic: InvoiceAnalytics = {
    unpaid: [],
    partiallyPaid: [],
    paid: [],
  };

  const { unpaid, partiallyPaid, ...aggregates } = invoicesBreakDown(
    invoices,
    initialAnalytic
  );

  return {
    ...aggregates,
    unPaidInvoices: partiallyPaid.concat(unpaid),
    unpaid,
    partiallyPaid,
  };
};

type SelectedInvoiceDetails = {
  costInUSD: number;
  costInCredit: number;
  selectedInvoices: number[];
};

type Props = {
  clientId: string;
  open: boolean;
  close: () => void;
  onPaymentComplete: () => void;
  unpaidInvoices: CLIENT_OVERVIEW_INVOICES[];
  selectedInvoiceIds: number[] | null;
};

const InvoicePayment: React.FC<Props> = (props: Props) => {
  const [
    selectedInvoicesDetails,
    setSelectedInvoicesDetails,
  ] = useState<SelectedInvoiceDetails>({
    costInUSD: 0,
    costInCredit: 0,
    selectedInvoices: [],
  });
  const [makePaymentModalOpen, setmakePaymentModalOpen] = useState(false);

  const {
    open,
    close,
    clientId,
    onPaymentComplete,
    unpaidInvoices,
    selectedInvoiceIds,
  } = props;

  useEffect(() => {
    if (selectedInvoicesDetails.costInUSD > 0) {
      setmakePaymentModalOpen(true);
      close();
    }
  }, [selectedInvoicesDetails]);

  useEffect(() => {
    if (makePaymentModalOpen) close();
  }, [makePaymentModalOpen]);

  const onPaymentSucessful = async () => {
    await onPaymentComplete();
    setmakePaymentModalOpen(false);
    close();
  };

  const onStartPayment = (
    costInCredit: number,
    costInUSD: number,
    invoices: number[]
  ) => {
    setSelectedInvoicesDetails({
      costInUSD,
      costInCredit,
      selectedInvoices: invoices,
    });
  };

  const { costInUSD, costInCredit, selectedInvoices } = selectedInvoicesDetails;

  return (
    <>
      {!!unpaidInvoices.length && !!unpaidInvoices[0] && (
        <PendingInvoicesPaymentsModal
          onCancel={close}
          onStartPayment={onStartPayment}
          open={open}
          clientId={clientId}
          invoices={unpaidInvoices}
          selectedInvoiceIds={selectedInvoiceIds || [unpaidInvoices[0]?.id]}
        />
      )}
      <MakePayment
        open={makePaymentModalOpen}
        costInUSD={costInUSD}
        costInCredits={costInCredit}
        clientId={clientId}
        invoices={selectedInvoices}
        onCancel={() => setmakePaymentModalOpen(false)}
        onSuccessful={onPaymentSucessful}
      />
    </>
  );
};

export default InvoicePayment;
