import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { defaultTheme, ThemeProvider, Preflight } from '@xstyled/emotion';
import '@emotion/react';
import '@emotion/styled';
import { AuthProvider } from 'components/auth';
import ErrorBoundaryWithFallback from 'components/ErrorBoundary';
import 'lib/global.css';
import { LoadingContainer, SnackbarContainer } from 'lib/hooks';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import React, { ReactNode } from 'react';
import 'template/assets/scss/main.scss';
import { ScrollReset } from 'template/components';
import 'template/mixins/chartjs';
import 'template/mixins/prismjs';
import 'template/mixins/validate';
import { theme as muiTheme } from 'template/theme';
import Url from 'url';
import Collapse from '@material-ui/core/Collapse';
import { SnackbarProvider } from 'notistack';
import cookieJS from 'js-cookie';
import { getUserFromToken } from '@engine/common/index';
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
} from 'kbar';
import { RenderResults, actions, appendDeveloperId } from 'components/kbar';
import { Action } from 'components/kbar/actions';

import {
  initEnvironment,
  RecordMap,
} from 'lib/graphql/relay/relay_environment';
import { fetchQuery } from 'react-relay';
import { DashboardUserQuery } from 'components/layouts/Dashboard';
import { getCookie } from 'lib/utils';
import { InitialQueryProvider } from 'v2/components/domains/Providers/InitialQueryProvider';
import { GlobalStyle } from 'v2/components/domains/Layout/GlobalStyle';
import { ToastProvider } from 'v2/components/atoms/Toast';
import { NewDashboardRedirect } from '../components/NewDashboardRedirect';
const { publicRuntimeConfig } = getConfig();
const GITSTART_HOOKS_URL = publicRuntimeConfig.GITSTART_HOOKS_URL;

const animatorStyle = {
  maxWidth: '600px',
  width: '100%',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
};

const searchStyle = {
  padding: '12px 16px',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
  outline: 'none',
  border: 'none',
  background: 'rgb(252 252 252)',
  color: 'rgb(28 28 29)',
};

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    ['primary-50']: defaultTheme.colors['indigo-50'],
    ['primary-100']: defaultTheme.colors['indigo-100'],
    ['primary-200']: defaultTheme.colors['indigo-200'],
    ['primary-300']: defaultTheme.colors['indigo-300'],
    ['primary-400']: defaultTheme.colors['indigo-400'],
    ['primary-500']: defaultTheme.colors['indigo-500'],
    ['primary-600']: defaultTheme.colors['indigo-600'],
    ['primary-700']: defaultTheme.colors['indigo-700'],
    ['primary-800']: defaultTheme.colors['indigo-800'],
    ['primary-900']: defaultTheme.colors['indigo-900'],
  },
};

const useSecureCookies = !!GITSTART_HOOKS_URL?.startsWith('https://');
class MainApp extends App<{
  pageUrl?: string | null;
  initToken?: string | null;
  pageProps: Object;
  initialRecords: RecordMap;
  Component: ReactNode;
  classes: { animator: string; search: string };
}> {
  constructor(props: any) {
    super(props);
    this.setIsLogin = this.setIsLogin.bind(this);
  }

  state = {
    isLogin: false,
  };

  static getInitialProps: typeof App.getInitialProps = async context => {
    const { ctx } = context;
    const props = await App.getInitialProps(context);
    const cookiePrefix = useSecureCookies ? '__Secure-' : '';
    const pageUrl = ctx.req ? ctx.req.url : null;
    const parsedUrl = pageUrl ? Url.parse(pageUrl, true) : null;
    const queryTokens = parsedUrl?.query?.['token'];
    const queryToken = Array.isArray(queryTokens)
      ? queryTokens[0]
      : queryTokens;
    const token =
      queryToken ||
      getCookie(ctx, `${cookiePrefix}next-auth.session-token`) ||
      getCookie(ctx, 'jwtToken');
    if (token) {
      cookieJS.set('jwtToken', token);
    }

    const user = getUserFromToken(token);
    const { pageProps, ...otherProps } = props;
    const { query, variables, ...leftOverPageProps } = pageProps;

    let initialRecords = {};
    const environment = initEnvironment(initialRecords || {}, token);

    if (environment && user?.id) {
      try {
        await Promise.all([
          query && variables
            ? fetchQuery(environment, query, variables).toPromise()
            : null,
          fetchQuery(environment, DashboardUserQuery, {
            userId: user.id,
          }).toPromise(),
        ]);
      } catch (ex) {
        console.log(
          'got back error quering from the backend. Token is prob. wrong: ',
          ex
        );

        return {
          ...otherProps,
          pageProps: leftOverPageProps,
          initialRecords: {},
          initToken: null,
          pageUrl,
        };
      }

      initialRecords = environment.getStore().getSource().toJSON();
    }

    return {
      ...otherProps,
      pageProps: leftOverPageProps,
      initialRecords,
      initToken: user ? token : null,
      pageUrl,
    };
  };

  setIsLogin(isLogin: boolean) {
    this.setState({
      isLogin,
    });
  }

  render(): JSX.Element {
    const {
      Component,
      pageProps,
      pageUrl,
      initialRecords,
      initToken,
    } = this.props;
    const { isLogin } = this.state;
    let parsedUrl = pageUrl ? Url.parse(pageUrl, true) : null;
    if (typeof window !== 'undefined') {
      parsedUrl = Url.parse(window.location.href, true);
    }
    const URL = 'https://app.gitstart.com/';
    let kbarActions: Action[] = [];
    if (pageProps.developerId) {
      kbarActions = appendDeveloperId(actions, pageProps.developerId);
    }
    return (
      <>
        <Head>
          <title>{isLogin ? 'Login | GitStart' : 'GitStart'}</title>
          <meta
            property="og:title"
            content="accelerating engineering teams remotely"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="Gitstart is a resourcing and training platform for remote developers that helps engineering teams accelerate their velocity" // TODO: rephrase and fix this
          />
          <meta property="og:url" content={URL} />
          <meta property="og:image" content={`${URL}/favicon.ico`} />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>
        <ErrorBoundaryWithFallback>
          <KBarProvider actions={kbarActions}>
            <KBarPortal>
              <KBarPositioner>
                <KBarAnimator style={animatorStyle}>
                  <KBarSearch style={searchStyle} />
                  <RenderResults />
                </KBarAnimator>
              </KBarPositioner>
            </KBarPortal>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <Preflight />
                <GlobalStyle />
                <AuthProvider
                  setIsLogin={this.setIsLogin}
                  initialRecords={initialRecords}
                  domain={publicRuntimeConfig.AUTH0_DOMAIN || ''}
                  client_id={publicRuntimeConfig.AUTH0_CLIENTID || ''}
                  initToken={initToken}
                  hostUrl={
                    parsedUrl
                      ? `${parsedUrl.protocol}//${parsedUrl.host}`
                      : publicRuntimeConfig.AUTH0_REDIRECT
                  }
                  redirect_uri={
                    parsedUrl
                      ? `${parsedUrl.protocol}//${parsedUrl.host}`
                      : publicRuntimeConfig.AUTH0_REDIRECT
                  }
                  initialState={{}}
                >
                  <NewDashboardRedirect>
                    <CssBaseline />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <SnackbarContainer />
                      <LoadingContainer />
                      <ScrollReset />
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        style={{ transform: 'translateY(-68px)' }} // this places snackbars just above the Intercom FAB
                        TransitionComponent={Collapse}
                      >
                        <InitialQueryProvider>
                          <ToastProvider>
                            <Component {...pageProps} />
                          </ToastProvider>
                        </InitialQueryProvider>
                      </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                  </NewDashboardRedirect>
                </AuthProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </KBarProvider>
        </ErrorBoundaryWithFallback>
      </>
    );
  }
}

export default MainApp;
