import cookie from 'js-cookie';
import initApollo from 'lib/graphql/init-apollo';
import { Context, User } from 'lib/types';
import getConfig from 'next/config';
import React, { useContext, useEffect, useState } from 'react';
import { getUserFromToken } from '@engine/common/index';
import { RecordMap, useEnvironment } from 'lib/graphql/relay/relay_environment';
import { ReactRelayContext } from 'react-relay';
import { DashboardLoading } from './layouts/Dashboard';
import Page from './Page';
import { useRouter } from 'next/router';
import { isProd } from 'lib/utils';
import { NormalizedCacheObject } from '@apollo/client/cache';

const { HASURA_GRAPHQL_ENGINE_DOMAIN, GITSTART_HOOKS_URL, GITSTART_APP_URL } =
  getConfig?.()?.publicRuntimeConfig ?? {};

const isHooksSecure = !!GITSTART_HOOKS_URL?.startsWith('https://');
const isAppSecure = !!GITSTART_APP_URL?.startsWith('https://');

const initHasuraClient = (token: string, initialState: NormalizedCacheObject) =>
  initApollo(
    {
      token,
      path: '/v1/graphql',
      host: HASURA_GRAPHQL_ENGINE_DOMAIN,
    },
    initialState
  );

const initBackendClient = (
  hostUrl: string,
  token: string,
  initialState: NormalizedCacheObject
) => initApollo({ token, host: hostUrl, path: '/api/graphql' }, initialState);

type AuthProviderProps = {
  setIsLogin: (isLogin: boolean) => void;
  children: React.ReactNode;
  initToken?: string | null;
  domain: string;
  initialRecords: RecordMap;
  hostUrl: string;
  client_id: string;
  redirect_uri: string;
  initialState: NormalizedCacheObject;
};

const initContext: Context = {
  user: null,
  jwtToken: null,
  graphqlClient: null,
  backendClient: null,
  isLoading: false,
  logout: () => {},
  loginWithPopup: () => Promise,
};

const AuthContext = React.createContext(initContext);

export const useAuthContext = () => useContext(AuthContext);
export const useAuth = (autoLogin: boolean) => {
  const ctx = useAuthContext();
  const jwtToken = cookie.get('jwtToken');

  if (jwtToken && !ctx.isFake) {
    try {
      if (!getUserFromToken(jwtToken)) {
        ctx.logout();
      }
    } catch (ex) {
      console.error('error:', ex);
    }
  }

  useEffect(() => {
    if (ctx.user === null && autoLogin && !ctx.isFake) {
      ctx.loginWithPopup();
    }
  }, [ctx.isLoading, !!ctx.user, !!autoLogin]);

  return ctx;
};

// let authClient: Auth0LockStatic | null = null;

export const AuthProvider = ({
  setIsLogin,
  children,
  initToken = null,
  initialRecords,
  initialState,
  hostUrl,
}: // ...initOptions
AuthProviderProps) => {
  const [user] = useState<User | null>(
    initToken ? getUserFromToken(initToken) : null
  );
  const [token] = useState<string | null>(initToken);

  const [graphqlClient, setGraphqlClient] = useState<Context['graphqlClient']>(
    initToken ? initHasuraClient(initToken, initialState) : null
  );

  const [backendClient, setBackendClient] = useState<Context['backendClient']>(
    initToken ? initBackendClient(hostUrl, initToken, {}) : null
  );

  const router = useRouter();

  const [isLoading, setIsLoading] = useState(!initToken);

  const loginWithPopup = async () => {
    console.log('logging in with popup');

    return new Promise<void>(() => {
      console.log('going into login state');
      setIsLogin(true);
      setIsLoading(true);

      const url = isAppSecure
        ? `${GITSTART_HOOKS_URL}/login?redirectUrl=${GITSTART_APP_URL}${router.asPath}`
        : `${GITSTART_HOOKS_URL}/redirect?url=${GITSTART_APP_URL}${router.asPath}`;
      window.location.replace(url);
    });
  };

  const logout = () => {
    console.log('logging out ...');
    cookie.remove('jwtToken');
    const cookiePrefix = isHooksSecure ? '__Secure-' : '';
    const secureCookie = isHooksSecure ? 'Secure;' : '';
    const hostName = new URL(GITSTART_HOOKS_URL).hostname;
    const domainName =
      hostName === 'localhost'
        ? hostName
        : `.gitstart.${isProd() ? 'com' : 'dev'}`;
    // cookie.remove(`${cookiePrefix}next-auth.session-token`);
    document.cookie =
      `${cookiePrefix}next-auth.session-token` +
      `=; Path=/;${secureCookie}Expires=Thu, 01 Jan 1970 00:00:01 GMT;` +
      `domain=${domainName}`;
    // authClient =
    //   authClient ||
    //   new Auth0Lock(initOptions.client_id, initOptions.domain, options);

    // authClient?.logout({
    //   returnTo: hostUrl,
    // });

    setGraphqlClient(null);
    setBackendClient(null);
    setIsLoading(false);

    // return loginWithPopup();

    return new Promise<void>(() => {
      console.log('going into login state');
      setIsLogin(true);
      setIsLoading(true);

      const url = `${GITSTART_HOOKS_URL}/logout`;
      window.location.replace(url);
    });
  };

  const environment = useEnvironment(initialRecords, token);

  if (environment) {
    return (
      <AuthContext.Provider
        value={{
          loginWithPopup,
          backendClient,
          user,
          graphqlClient,
          isLoading,
          logout,
          jwtToken: token,
        }}
      >
        <ReactRelayContext.Provider value={{ environment }}>
          {children}
        </ReactRelayContext.Provider>
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        loginWithPopup,
        backendClient,
        user,
        graphqlClient,
        isLoading,
        logout,
        jwtToken: token,
      }}
    >
      <DashboardLoading title={''} loading>
        <Page> </Page>
      </DashboardLoading>
    </AuthContext.Provider>
  );
};

export const FakeAuthProvider = (props: { children: React.ReactNode }) => {
  const user: User = {
    id: 1,
    roles: ['user'],
    defaultRole: 'user',
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        jwtToken: null,
        graphqlClient: null,
        backendClient: null,
        isLoading: false,
        logout: () => console.log('Logging out within fake context.'),
        loginWithPopup: () =>
          console.log('Logging in with popup within fake context.'),
        isFake: true,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
