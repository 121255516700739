import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IntegrationType } from 'components/integrations/types';
import React from 'react';
import { Theme } from 'template/theme';

export type companyDetails = {
  companyDetails?: {
    companyName?: string;
    companyLegalName?: string;
    companyURL?: string;
    clientId?: string;
    clientUserId?: string;
  };
};

export type gitProviders = {
  gitProviderOptions?: Set<IntegrationType>;
};

export type StepperState = companyDetails &
  gitProviders & {
    calendlyPrefill?: {
      firstName?: string;
      lastName?: string;
      email?: string;
    };
  };

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  BACK: {
    float: 'left',
  },
  NEXT: {
    float: 'right',
  },
}));

export type StepperNavigationProps = {
  onBack: () => void;
  disableNext?: boolean;
  nextLabel?: string;
  onNext: (state?: StepperState) => void;
  state?: StepperState;
};

const StepperNavigations = ({
  onBack,
  disableNext = false,
  nextLabel = 'Next',
  onNext,
}: StepperNavigationProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button onClick={onBack} className={classes.BACK}>
        Back
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={disableNext}
        onClick={() => onNext()}
        type="submit"
        className={classes.NEXT}
      >
        {nextLabel}
      </Button>
    </div>
  );
};

export default StepperNavigations;
