import React from 'react';
import {
  makeStyles,
  Divider as MuiDivider,
  DividerProps,
} from '@material-ui/core';
import clsx from 'clsx';

import { Theme } from 'template/theme';

const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: '100%',
  },
}));

const Divider = (props: DividerProps) => {
  const classes = useStyles();

  return <MuiDivider className={clsx(classes.root, props.className)} />;
};

export default Divider;
export type { DividerProps };
