import getConfig from 'next/config';

const onGitHubAppClick = (jwtToken: string | null) => {
  const GITSTART_HOOKS_URL = getConfig?.()?.publicRuntimeConfig
    ?.GITSTART_HOOKS_URL;
  const url = `${GITSTART_HOOKS_URL}/api/github/installation?token=${jwtToken}`;
  if (window && window?.top) {
    const left = window.top.outerWidth / 2 + window.top.screenX - 1100 / 2;
    const top = window.top.outerHeight / 2 + window.top.screenY - 700 / 2;
    return window.open(
      url,
      'Connect Github',
      `left=${left},top=${top},width=1100,height=700`
    );
  }
};

export default onGitHubAppClick;
