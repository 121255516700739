import { makeStyles } from '@material-ui/styles';
import { Theme } from 'template/theme';
import { State } from 'ui/utils';
import { stdFlex } from 'lib/utils';
import { Typography, Badge } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  legends: {
    margin: 'auto',
    ...stdFlex('row', 'center', 'center', theme.spacing(0.5)),
  },
  legend: {
    ...stdFlex('row', 'center', 'center'),
  },
}));

const LegendColoredDot = (props: { color: string }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    badge: {
      backgroundColor: props.color,
    },
    margin: {
      margin: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Badge
        classes={{ badge: classes.badge }}
        className={classes.margin}
        badgeContent=" "
        variant="dot"
      />
    </div>
  );
};

const CalendarLegend = () => {
  const classes = useStyles();
  return (
    <div className={classes.legends}>
      <div className={classes.legend}>
        <LegendColoredDot color={State.Today} />
        <Typography variant="body1">Today</Typography>
      </div>
      <div className={classes.legend}>
        <LegendColoredDot color={State.Full} />
        <Typography variant="body1">Full activity</Typography>
      </div>
      <div className={classes.legend}>
        <LegendColoredDot color={State.Incomplete} />
        <Typography variant="body1">Incomplete activity</Typography>
      </div>
      <div className={classes.legend}>
        <LegendColoredDot color={State.Missing} />
        <Typography variant="body1">Missing activity</Typography>
      </div>
      <div className={classes.legend}>
        <LegendColoredDot color={State.Leave} />
        <Typography variant="body1">Leave</Typography>
      </div>
      <div className={classes.legend}>
        <LegendColoredDot color={State.Pending} />
        <Typography variant="body1">Pending Leave</Typography>
      </div>
    </div>
  );
};

export default CalendarLegend;
