import gql from 'graphql-tag';

export const GIT_REPO_FRAGMENT = gql`
  fragment GIT_REPO_FRAGMENT on git_repos {
    id
    name
    owner
    source
  }
`;

export const GIT_BRANCH_REFS_FRAGMENT = gql`
  fragment GIT_BRANCH_REFS_FRAGMENT on git_branch_refs {
    branchName
    repoId
  }
`;

export const GIT_REPO_SLICES_FRAGMENT = gql`
  fragment GIT_REPO_SLICES_FRAGMENT on git_repo_slices {
    id
    slicedFromBranchName
  }
`;
