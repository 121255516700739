/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type client_user_roles_enum =
  | 'admin'
  | 'contractor'
  | 'internal'
  | 'user'
  | '%future added value';
export type isClient_user = {
  readonly client_users: ReadonlyArray<{
    readonly clientId: string;
    readonly role: client_user_roles_enum;
  }>;
  readonly ' $refType': 'isClient_user';
};
export type isClient_user$data = isClient_user;
export type isClient_user$key = {
  readonly ' $data'?: isClient_user$data;
  readonly ' $fragmentRefs': FragmentRefs<'isClient_user'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'isClient_user',
};
(node as any).hash = '408a0e65c08fabaed5df3a18f5582d2b';
export default node;
