import React, { ReactNode } from 'react';
import {
  colors,
  SnackbarContent,
  Snackbar as MUISnackbar,
  SnackbarOrigin,
  useTheme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import { makeStyles } from '@material-ui/styles';
import { createStore, useStore } from 'react-hookstore';
import { Theme } from 'template/theme';
import {
  tasks_type_enum as TType,
  tasks_status_enum as TS,
  tickets_types_enum as TT,
  ticket_credit_status_enum as TCS,
  task_credit_status_enum as TCSE,
} from '@engine/common/graphql/roles/admin/generated/globalTypes';
import { ticket_status_enum } from '@engine/common/graphql/roles/user/generated/globalTypes';
import getConfig from 'next/config';
import { captureException } from './sentry';
import { GraphqlClient } from 'server/utils';
import { LINK_TICKET_TO_INVOICE } from './graphql/roles/admin/mutations';
import {
  updateTicketByCodeAndLinkInvoice,
  updateTicketByCodeAndLinkInvoiceVariables,
} from '@engine/common/src/graphql/roles/admin/generated/updateTicketByCodeAndLinkInvoice';

type TicketStatusEnum = Omit<ticket_status_enum, '__typename'>;
const useStyles = makeStyles<Theme>(theme => ({
  success: {
    backgroundColor: colors.green[600],
  },
  error: {
    backgroundColor: colors.red[600],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

export type SnackbarProps = {
  variant: 'error' | 'success';
  message: string;
  open?: boolean;
  onClose?: () => void;
  anchorOrigin?: SnackbarOrigin;
};

export const useSnackbar = () => {
  const [, showSnackbar] = useStore<SnackbarProps>('snackbar');
  return { showSnackbar };
};

export const snackbarStore = createStore<SnackbarProps>('snackbar', {
  variant: 'success',
  message: '',
});
export const SnackbarContainer = () => {
  const [snackbar, setSnackbar] = useStore<SnackbarProps>('snackbar');
  const classes = useStyles();

  const icons: { [key: string]: ReactNode } = {
    success: <CheckCircleIcon className={classes.icon} />,
    error: <ErrorIcon className={classes.icon} />,
  };

  return (
    <MUISnackbar
      anchorOrigin={
        snackbar.anchorOrigin || {
          vertical: 'top',
          horizontal: 'center',
        }
      }
      autoHideDuration={6000}
      onClose={
        snackbar.onClose ||
        (() => setSnackbar({ ...snackbar, open: false, message: '' }))
      }
      open={snackbar.open || snackbar.message !== ''}
    >
      <SnackbarContent
        className={classes[snackbar.variant]}
        message={
          <span className={classes.message}>
            {icons[snackbar.variant]}
            {snackbar.message}
          </span>
        }
        variant="elevation"
      />
    </MUISnackbar>
  );
};

export type LoadingReducer = {
  type: 'add' | 'remove';
  payload: string;
};

export type LoadingStore = { loading: boolean; loadingSet: Set<string> };

export type LoadingResult = {
  loading: boolean;
  setLoading: (state: boolean, identifier: string | number) => void;
  load: <T>(
    process: () => Promise<T>,
    identifier: string | number,
    disabled?: boolean | undefined
  ) => Promise<T>;
  watch: (identifiers: (string | number)[]) => boolean;
};

export function useLoading(): LoadingResult {
  const [{ loading, loadingSet }, dispatch] = useStore<
    LoadingStore,
    LoadingReducer
  >('loading');

  const addLoadingProcess = (identifier: string | number) => {
    dispatch({ type: 'add', payload: identifier.toString() });
  };

  const removeLoadingProcess = (identifier: string | number) => {
    dispatch({ type: 'remove', payload: identifier.toString() });
  };

  async function load<T>(
    process: () => Promise<T>,
    identifier: string | number,
    disabled?: boolean
  ): Promise<T> {
    if (disabled) {
      return process();
    }

    const start = new Date();
    try {
      console.log('adding loading process: ', identifier);
      addLoadingProcess(identifier);
      const res = await process();
      removeLoadingProcess(identifier);
      console.log(
        `finishing loading process: ${identifier} in ${+new Date() - +start}ms`
      );
      return res;
    } catch (error) {
      removeLoadingProcess(identifier);
      console.log(
        `finishing loading process: ${identifier} in ${+new Date() - +start}ms`
      );
      throw error;
    }
  }

  function setLoading(state: boolean, identifier: string | number) {
    if (state) {
      addLoadingProcess(identifier);
    } else {
      removeLoadingProcess(identifier);
    }
  }

  function watch(identifiers: (string | number)[]) {
    const found = [...loadingSet].find(l => identifiers.includes(l));
    return !!found;
  }

  return {
    loading,
    setLoading,
    load,
    watch,
  };
}

export const loadingStoreReducer = (
  { loadingSet, loading }: LoadingStore,
  action: LoadingReducer
) => {
  const addSet = new Set([...loadingSet, action.payload]);
  const removeSet = new Set([...loadingSet].filter(l => l !== action.payload));
  switch (action.type) {
    case 'add':
      return { loading: addSet.size > 0, loadingSet: addSet };
    case 'remove':
      return {
        loading: removeSet.size > 0,
        loadingSet: removeSet,
      };
    default:
      return { loadingSet, loading };
  }
};

const state = { loading: false, loadingSet: new Set<string>() };
export const loadingStore = createStore<LoadingStore>(
  'loading',
  state,
  loadingStoreReducer
);
export const LoadingContainer = () => {
  return null;
};

export type TicketStatusObj = {
  label: string;
  description?: string; // TODO @ziahamza @ edward.so: Add description for all ticket statuses and remove optional keyword (?)
  color: string;
  status: TicketStatusEnum;
  sequence: number;
};

export const useTicketStatus = () => {
  const theme = useTheme<Theme>();
  const { setLoading } = useLoading();
  const { showSnackbar } = useSnackbar();

  const statuses: {
    [key in ticket_status_enum]: TicketStatusObj;
  } = {
    backlog: {
      label: 'Backlog',
      description: 'Ticket is not ready to be worked on or was paused.',
      color: theme.palette.taskStatus.open,
      status: ticket_status_enum.backlog,
      sequence: -1,
    },
    available: {
      label: 'Available',
      description: 'Ticket is available.',
      color: theme.palette.taskStatus.open,
      status: ticket_status_enum.available,
      sequence: 0,
    },
    in_progress: {
      label: 'In Progress',
      description: 'Ticket is in progress.',
      color: theme.palette.taskStatus.open,
      status: ticket_status_enum.in_progress,
      sequence: 1,
    },
    partially_under_review: {
      label: 'Partially Under Review',
      description: 'Some tasks within the ticket are under review.',
      color: theme.palette.taskStatus.review,
      status: ticket_status_enum.partially_under_review,
      sequence: 2,
    },
    under_review: {
      label: 'Under Review',
      description: 'Ticket and its tasks are under review.',
      color: theme.palette.taskStatus.review,
      status: ticket_status_enum.under_review,
      sequence: 3,
    },
    partially_blocked: {
      label: 'Partially Blocked',
      description:
        'Some developer(s) working on this ticket have some blockers.',
      color: theme.palette.taskStatus.errored,
      status: ticket_status_enum.partially_blocked,
      sequence: 4,
    },
    blocked: {
      label: 'Blocked',
      description: 'Developer(s) working on this ticket have some blockers.',
      color: theme.palette.taskStatus.errored,
      status: ticket_status_enum.blocked,
      sequence: 5,
    },
    cancelled: {
      label: 'Cancelled',
      description: 'Ticket and its tasks have been cancelled.',
      color: 'purple',
      status: ticket_status_enum.cancelled,
      sequence: 6,
    },
    finished: {
      label: 'Finished',
      description: 'Ticket has finished development and was shipped.',
      color: theme.palette.taskStatus.resolved,
      status: ticket_status_enum.finished,
      sequence: 7,
    },
    errored: {
      label: 'Errored',
      description:
        'Something went wrong with the ticket. Please see "Reason it updated".',
      color: theme.palette.taskStatus.errored,
      status: ticket_status_enum.errored,
      sequence: 8,
    },
  };

  const getTicketStatus = (status: TicketStatusEnum) => {
    return statuses[status as ticket_status_enum];
  };

  // TODO: update Relay store/cache instead of having to keep a state.
  async function refreshTicketStatus(
    ticketId: number,
    currentStatus: TicketStatusEnum,
    analyticOptions?: Partial<{
      userId: number;
      clientId: string;
      ticketCode: string;
    }>
  ) {
    const loadingName = 'refreshTicketStatus';
    setLoading(true, loadingName);

    const config = getConfig?.();
    const url = `${config?.publicRuntimeConfig?.GITSTART_HOOKS_URL}/api/tickets/status_update?ticketId=${ticketId}`;

    let newStatus: TicketStatusEnum = currentStatus;
    try {
      const res = await fetch(url, { method: 'GET' });
      const textRes = await res.text();
      if (!res.ok) {
        throw new Error(JSON.parse(textRes).message);
      }
      const updatedStatus: TicketStatusEnum = JSON.parse(textRes)?.[
        'updatedTickets'
      ]?.[0]?.updatedStatus;
      newStatus = updatedStatus || currentStatus;
      showSnackbar({
        variant: 'success',
        message: 'Successfully refreshed status',
      });
      if (analyticOptions) {
        analytics.track('Manual status update of tickets triggered', {
          ...analyticOptions,
          previousStatus: currentStatus,
          newStatus,
        });
      }
    } catch (e) {
      captureException(e);
      showSnackbar({
        variant: 'error',
        message: `Failed to refresh: ${(e as Error).message}`,
      });
    }
    setLoading(false, loadingName);

    return newStatus;
  }

  return {
    getTicketStatus,
    refreshTicketStatus,
  };
};

// Migrating away from MUI theme -> tailwindcss
export const useTaskStatus = () => {
  const thm = useTheme<Theme>();

  const taskStatusHumanReadible: { [key in TS]: string } = {
    available: 'Available',
    backlog: 'Backlog',
    cancelled: 'Cancelled',
    client_review: 'Client Review',
    parked: 'Parked',
    finished: 'Finished',
    in_progress: 'In Progress',
    internal_review: 'Internal Review',
    needs_changes: 'Needs Changes',
    errored: 'Errored',
  };

  const statusColors = {
    [TS.client_review]: thm.palette.taskStatus.review,
    [TS.internal_review]: thm.palette.taskStatus.review,
    [TS.in_progress]: thm.palette.taskStatus.open,
    [TS.needs_changes]: thm.palette.taskStatus.review,
    [TS.cancelled]: 'purple',
    [TS.finished]: thm.palette.taskStatus.resolved,
    [TS.backlog]: thm.palette.taskStatus.open,
    [TS.available]: thm.palette.taskStatus.open,
    [TS.parked]: thm.palette.taskStatus.errored,
    [TS.errored]: thm.palette.taskStatus.errored,
  };

  const statusHoverColors = {
    [TS.client_review]: `${thm.palette.taskStatus.review}88`,
    [TS.internal_review]: `${thm.palette.taskStatus.review}88`,
    [TS.in_progress]: `${thm.palette.taskStatus.open}88`,
    [TS.needs_changes]: `${thm.palette.taskStatus.review}88`,
    [TS.cancelled]: 'purple',
    [TS.finished]: `${thm.palette.taskStatus.resolved}88`,
    [TS.backlog]: `${thm.palette.taskStatus.open}88`,
    [TS.available]: `${thm.palette.taskStatus.open}88`,
    [TS.parked]: `${thm.palette.taskStatus.errored}88`,
    [TS.errored]: `${thm.palette.taskStatus.errored}88`,
  };

  const statusAction = {
    [TType.spec]: {
      [TS.client_review]: 'Ready for client review',
      [TS.internal_review]: 'Ready for review',
      [TS.in_progress]: 'Start speccing',
      [TS.needs_changes]: 'Request spec changes',
      [TS.cancelled]: 'Cancel speccing',
      [TS.finished]: 'Finish speccing',
      [TS.backlog]: 'Put on hold',
      [TS.available]: 'Make available',
      [TS.parked]: 'Make dependent on another task',
      [TS.errored]: 'Something went wrong?',
    },
    [TType.code]: {
      [TS.client_review]: 'Ready for client review',
      [TS.internal_review]: 'Ready for review',
      [TS.in_progress]: 'Start coding',
      [TS.needs_changes]: 'Request code changes',
      [TS.cancelled]: 'Cancel coding',
      [TS.finished]: 'Finish coding',
      [TS.backlog]: 'Put on hold',
      [TS.available]: 'Make available',
      [TS.parked]: 'Make dependent on another task',
      [TS.errored]: 'Something went wrong?',
    },
  };

  const getTaskStatus = (status: TS, type?: TType) => {
    return {
      status: taskStatusHumanReadible[status],
      color: statusColors[status],
      hoverColor: statusHoverColors[status],
      action:
        statusAction[type === TType.spec ? TType.spec : TType.code]?.[status],
    };
  };

  return {
    getTaskStatus,
  };
};

export const useTicketPriority = () => {
  const thm = useTheme<Theme>();

  const statusColors: { [key: number]: string } = {
    1: thm.palette.success.dark,
    2: thm.palette.success.main,
    3: thm.palette.warning.main,
    4: thm.palette.error.main,
    5: thm.palette.error.dark,
  };
  const taskStatusHumanReadible: { [key: number]: string } = {
    1: 'Lowest',
    2: 'Low',
    3: 'Medium',
    4: 'High',
    5: 'Highest',
  };
  const getTicketPriority = (priority: number) => {
    return {
      status: taskStatusHumanReadible[priority],
      color: statusColors[priority],
    };
  };

  return {
    getTicketPriority,
  };
};

export const useTicketType = () => {
  const thm = useTheme<Theme>();

  const statusColors: { [key in TT]: string } = {
    [TT.bug]: thm.palette.error.main,
    [TT.consult]: thm.palette.black,
    [TT.feature]: thm.palette.primary.main,
    [TT.maintain]: thm.palette.warning.main,
  };
  const taskStatusHumanReadible: { [key in TT]: string } = {
    [TT.bug]: 'Bug',
    [TT.consult]: 'Consult',
    [TT.feature]: 'Feature',
    [TT.maintain]: 'Maintain',
  };
  const getTicketType = (type: TT) => ({
    status: taskStatusHumanReadible[type],
    color: statusColors[type],
  });

  return {
    getTicketType,
  };
};

export const useCreditStatus = () => {
  const thm = useTheme<Theme>();
  const statusColors: { [key in TCS]: string } = {
    [TCS.approved]: thm.palette.success.main,
    [TCS.pending]: thm.palette.warning.main,
    [TCS.rejected]: thm.palette.error.main,
    [TCS.auto_approved]: thm.palette.success.main,
  };
  const taskStatusHumanReadible: { [key in TCS]: string } = {
    [TCS.approved]: 'Approved',
    [TCS.pending]: 'Pending',
    [TCS.rejected]: 'Rejected',
    [TCS.auto_approved]: 'Auto Approved',
  };
  const getCreditStatus = (status: TCS) => ({
    status: taskStatusHumanReadible[status],
    color: statusColors[status],
  });
  return {
    getCreditStatus,
  };
};

export const useTaskCreditStatus = () => {
  const thm = useTheme<Theme>();
  const statusColors: { [key in TCS]: string } = {
    [TCSE.approved]: thm.palette.success.main,
    [TCSE.pending]: thm.palette.warning.main,
    [TCSE.rejected]: thm.palette.error.main,
    [TCSE.auto_approved]: thm.palette.success.main,
  };
  const taskStatusHumanReadible: { [key in TCSE]: string } = {
    [TCSE.approved]: 'Approved',
    [TCSE.pending]: 'Pending',
    [TCSE.rejected]: 'Rejected',
    [TCSE.auto_approved]: 'Auto Approved',
  };
  const getCreditStatus = (status: TCSE) => ({
    status: taskStatusHumanReadible[status],
    color: statusColors[status],
  });
  return {
    getCreditStatus,
  };
};
/**
 * This was copied over from `@engine/hooks/src/server/utils/ticket_status.ts`
 * TODO: make this code shared in the common folder
 *
 * @param client
 * @param variables
 */
export const linkTicketToInvoice = async (
  client: GraphqlClient,
  variables: updateTicketByCodeAndLinkInvoiceVariables
) => {
  const { data } = await client.mutate<
    updateTicketByCodeAndLinkInvoice,
    updateTicketByCodeAndLinkInvoiceVariables
  >({
    mutation: LINK_TICKET_TO_INVOICE,
    variables,
  });

  if (!data || !data.generatePendingInvoicesForClientWithTicket) {
    throw new Error('Error when linking invoice');
  }

  // The ticket might be already linked, so it is possible not to have returning pendingInvoices as part of the update result
  console.log(
    'Link result:',
    JSON.stringify(data.generatePendingInvoicesForClientWithTicket)
  );
  return data.generatePendingInvoicesForClientWithTicket;
};
