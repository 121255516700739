import {
  Button,
  Checkbox,
  colors,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  accumulateInvoiceCostCredit,
  invoicePendingBalance,
} from 'lib/client/utils';
import { CLIENT_OVERVIEW_INVOICES } from '@engine/common/graphql/roles/client/generated/CLIENT_OVERVIEW_INVOICES';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Modal from './Modal';

const useStyles = makeStyles<Theme>(theme => ({
  content: {
    padding: theme.spacing(1),
    maxWidth: 930,
    margin: '0 auto',
  },
  actions: {
    padding: theme.spacing(2),
  },
  startButton: {
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
}));

type InvoicesCreditCostSum = {
  credit: number;
  cost: number;
};

type Props = {
  selectedInvoiceIds?: number[];
  selectAll?: boolean;
  onCancel: () => void;
  onStartPayment: (
    credits: number,
    costInUSD: number,
    invoices: number[]
  ) => void;
  clientId: string;
  open: boolean;
  invoices: CLIENT_OVERVIEW_INVOICES[];
};

const PendingInvoicesPaymentsModal: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const {
    onCancel,
    onStartPayment,
    selectAll,
    open,
    selectedInvoiceIds,
    invoices,
    clientId,
  } = props;

  const initialCreditCostSum = {
    credit: 0,
    cost: 0,
  };

  const [selectedInvoices, setSelectedInvoices] = useState<number[] | null>(
    null
  );
  const selectedOrDefaultInvoices =
    selectedInvoices || selectedInvoiceIds || [];
  const [
    selectedCreditCostSum,
    setSelectedCreditCostSum,
  ] = useState<InvoicesCreditCostSum>(initialCreditCostSum);

  const getCreditCostSum = () => {
    return accumulateInvoiceCostCredit(
      invoices.filter(invoice => selectedOrDefaultInvoices.includes(invoice.id))
    );
  };

  const handleSelectAll = (checked: boolean) => {
    const selected = checked ? invoices.map(invoice => invoice.id) : [];

    setSelectedInvoices(selected);
  };

  useEffect(() => {
    if (selectAll) handleSelectAll(true);
  }, [handleSelectAll, selectAll]);

  useEffect(() => {
    setSelectedCreditCostSum(getCreditCostSum());
  }, [selectedOrDefaultInvoices, invoices]);

  const selectSingleInvoice = (checked: boolean, invoice: number) => {
    const newSelected = checked
      ? [
          ...selectedOrDefaultInvoices,
          ...(!selectedOrDefaultInvoices.includes(invoice) ? [invoice] : []),
        ]
      : selectedOrDefaultInvoices.filter(inv => inv !== invoice);

    setSelectedInvoices(newSelected);
  };

  const { cost, credit } = selectedCreditCostSum;

  const onPayment = () =>
    onStartPayment(credit, cost, selectedOrDefaultInvoices);

  return (
    <Modal
      maxWidth="lg"
      onClose={onCancel}
      open={open}
      className={classes.content}
      header={
        <Typography align="center" gutterBottom variant="h3">
          PAY PENDING INVOICES
        </Typography>
      }
    >
      <div className={classes.content}>
        <Typography align="left" gutterBottom variant="h5">
          Select invoices to pay
        </Typography>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size="medium">
                    <Checkbox
                      checked={
                        invoices.length === selectedOrDefaultInvoices.length
                      }
                      color="primary"
                      indeterminate={
                        selectedOrDefaultInvoices.length > 0 &&
                        selectedOrDefaultInvoices.length < invoices.length
                      }
                      onChange={(_event, checked) => handleSelectAll(checked)}
                    />
                    Select All
                  </TableCell>
                  <TableCell size="small">Invoice Date</TableCell>
                  <TableCell size="small">Pending Credits</TableCell>
                  <TableCell size="small">Pending Cost (USD)</TableCell>
                  <TableCell align="right" size="small" />
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map(invoice => (
                  <TableRow
                    hover
                    key={invoice.id}
                    selected={selectedOrDefaultInvoices.includes(invoice.id)}
                  >
                    <TableCell size="medium">
                      <Checkbox
                        checked={selectedOrDefaultInvoices.includes(invoice.id)}
                        color="primary"
                        onChange={(_event, checked) =>
                          selectSingleInvoice(checked, invoice.id)
                        }
                        value
                      />
                    </TableCell>
                    <TableCell size="small">
                      {moment(invoice.dueAt || undefined)
                        .subtract(1, 'day')
                        .format('MMM YY')}
                    </TableCell>
                    <TableCell size="small">
                      {invoicePendingBalance(invoice).credit} Credits{' '}
                    </TableCell>
                    <TableCell size="small">
                      ${invoicePendingBalance(invoice).cost}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button
                        color="primary"
                        size="small"
                        href={`/clients/${clientId}/invoices/${invoice.id}`}
                        variant="outlined"
                      >
                        View Usage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
      <div className={classes.actions}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Button
              disabled={!selectedOrDefaultInvoices.length}
              className={classes.startButton}
              onClick={onPayment}
              variant="contained"
            >
              {selectedOrDefaultInvoices.length ? `PAY ${cost} USD` : 'PAY'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default PendingInvoicesPaymentsModal;
