import React from 'react';
import {
  ButtonProps,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
} from '@material-ui/core';

export type IconButtonProps = MuiIconButtonProps &
  ButtonProps & { tooltip?: string };
type Ref = HTMLButtonElement;

const IconButton = React.forwardRef<Ref, IconButtonProps>((props, ref) => {
  if (props.tooltip) {
    return (
      <Tooltip title={props.tooltip}>
        <MuiIconButton {...props} ref={ref} />
      </Tooltip>
    );
  }

  return <MuiIconButton {...props} ref={ref} />;
});

export default IconButton;
