import React from 'react';
import Spinner from 'react-spinkit';

type Props = {
  color?: string;
  fadeInTime?: 'full' | 'half' | 'quarter' | 'none';
};

const CircularSpinner = (props: Props) => {
  return (
    <div>
      <Spinner
        name="ball-clip-rotate"
        color={props.color ?? 'white'}
        fadeIn={props.fadeInTime}
      />
    </div>
  );
};

export default CircularSpinner;
