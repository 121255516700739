import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import counterConfig from 'components/layouts/Dashboard/counterConfig';
import { getRelayId } from 'lib/graphql/relay/utils';
import { SLACounters } from 'lib/stores';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import CallSplitIcon from '@material-ui/icons/CallSplit';

export default function ClientDashboardNavigationConfig(
  clientId: string,
  role: string,
  slaCounters: SLACounters,
  clientName: string,
  clientProjects: Readonly<
    {
      id: string;
      name: string | null;
    }[]
  > | null
) {
  const projects = clientProjects || [];
  const firstProject = projects[0];
  const ticketApprovalCounter = slaCounters.client_stats.find(
    a => a.id === clientId
  );

  return {
    subheader: `${clientName || clientId} Dashboard`,
    items: [
      {
        title: 'Home',
        href: `/clients/${clientId}/home`,
        icon: HomeIcon,
      },
      {
        title: projects.length > 1 ? 'All Tickets' : 'Tickets',
        href: `/clients/${clientId}/tickets`,
        icon: ListAltIcon,
        ...(ticketApprovalCounter &&
          counterConfig(ticketApprovalCounter, `$$ tickets need approval`)),
      },
      // Small or legacy clients. Dont show projects dropdown, just inline pages
      ...(firstProject && projects.length <= 1
        ? [
            // {
            //   title: 'Git Repo Slices',
            //   href: `/clients/${clientId}/projects/${getRelayId(
            //     firstProject.id
            //   )}/slices`,
            //   icon: CallSplitIcon,
            // },
            // {
            //   title: 'Pull Requests',
            //   href: `/clients/${clientId}/projects/${getRelayId(
            //     firstProject.id
            //   )}/prs`,
            //   icon: AccountTreeOutlinedIcon,
            // },
          ]
        : []),
      // Large clients with > 1 project
      ...(projects.length > 1
        ? projects.map(cp => ({
            title: cp.name,
            icon: DashboardOutlinedIcon,
            items: [
              {
                title: 'Git Repo Slices',
                href: `/clients/${clientId}/projects/${getRelayId(
                  cp.id
                )}/slices`,
                icon: CallSplitIcon,
              },
            ],
          }))
        : []),
      ...(role === 'admin' || role === 'internal'
        ? [
            {
              title: 'Billing',
              href: `/clients/${clientId}/billing`,
              icon: AttachMoneyOutlinedIcon,
            },
          ]
        : []),
      // TODO: move this up, after redesigning settings page
      ...(role === 'internal'
        ? [
            {
              title: 'Settings - Internal Only',
              href: `/clients/${clientId}/settings/general`,
              icon: BuildOutlinedIcon,
            },
          ]
        : []),
    ],
  };
}
