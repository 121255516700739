import gql from 'graphql-tag';

export const TASK_TIME_USER_TOTALS = gql`
  fragment TASK_TIME_USER_TOTALS on task_time_user_totals {
    totalOrganizedTimeInSecs
    user {
      firstName
      lastName
    }
  }
`;

export const TICKET_TIME_USER_TOTALS = gql`
  fragment TICKET_TIME_USER_TOTALS on ticket_time_user_totals {
    totalOrganizedTimeInSecs
    user {
      firstName
      lastName
    }
  }
`;

export const TICKET_TIME_TO_SURFACE = gql`
  fragment TICKET_TIME_TO_SURFACE on tickets {
    ticket_time_user_totals {
      ...TICKET_TIME_USER_TOTALS
    }
    tasks {
      task_time_user_totals {
        ...TASK_TIME_USER_TOTALS
      }
    }
  }
  ${TASK_TIME_USER_TOTALS}
  ${TICKET_TIME_USER_TOTALS}
`;
