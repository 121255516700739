import { colors } from '@material-ui/core';
import { fetchClientTickets_tickets } from '@engine/common/graphql/roles/client/generated/fetchClientTickets';
import {
  tasks_status_enum,
  ticket_status_enum,
} from '@engine/common/graphql/roles/client/generated/globalTypes';
import { ticketListFragment } from '@engine/common/graphql/roles/client/generated/ticketListFragment';
import { fetchTeamOverview_user_teams_user_team_members } from '@engine/common/graphql/roles/developer/generated/fetchTeamOverview';
import {
  DevTaskFilterType,
  TaskFilterDataType,
  technology_enum,
  TicketFilterDataType,
  TicketSequence,
} from 'lib/types';
import size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { theme as thm, Theme } from 'template/theme';
import { tickets_bool_exp } from '@engine/app/src/lib/graphql/relay/__generated__/TicketsPaginatedRefetchQuery.graphql';

const theme = thm as Theme;

interface Priority {
  [key: string]: number[];
}

export const getReadableTicketStatusObject = (
  searchString: string | ticket_status_enum | null
) => {
  return (
    getTicketStatusArray.find(
      d =>
        d.status === searchString ||
        d.type === searchString ||
        d.title === searchString
    ) || getTicketStatusArray[0]
  );
};

export const getFilterBySort = (sortType: string) => {
  let sortBy = {};
  if (sortType === 'newest') sortBy = { createdAt: 'desc' };
  if (sortType === 'oldest') sortBy = { createdAt: 'asc' };
  if (sortType === 'recently_updated') sortBy = { updatedAt: 'desc' };
  if (sortType === 'least_recently_updated') sortBy = { updatedAt: 'asc' };
  return sortBy;
};

export const sortByOptions = [
  {
    label: 'NEWEST',
    name: 'newest',
  },
  {
    label: 'OLDEST',
    name: 'oldest',
  },
  {
    label: 'RECENTLY UPDATED',
    name: 'recently_updated',
  },
  {
    label: 'LEAST RECENTLY UPDATED',
    name: 'least_recently_updated',
  },
];

export const getTicketStatusArray = [
  {
    status: 'available',
    colorCode: theme.palette.taskStatus.open,
    title: 'Available',
    type: ticket_status_enum.available,
    sequence: 0,
  },
  {
    status: 'in_progress',
    colorCode: theme.palette.taskStatus.open,
    title: 'In Progress',
    type: ticket_status_enum.in_progress,
    sequence: 1,
  },
  {
    status: 'partially_under_review',
    colorCode: theme.palette.taskStatus.review,
    title: 'Partially Under Review',
    type: ticket_status_enum.partially_under_review,
    sequence: 2,
  },
  {
    status: 'under_review',
    colorCode: theme.palette.taskStatus.review,
    title: 'Under Review',
    type: ticket_status_enum.under_review,
    sequence: 3,
  },
  {
    status: 'partially_blocked',
    colorCode: theme.palette.taskStatus.errored,
    title: 'Partially Blocked',
    type: ticket_status_enum.partially_blocked,
    sequence: 4,
  },
  {
    status: 'blocked',
    colorCode: theme.palette.taskStatus.errored,
    title: 'Blocked',
    type: ticket_status_enum.blocked,
    sequence: 5,
  },
  {
    status: 'cancelled',
    colorCode: theme.palette.taskStatus.resolved,
    title: 'Cancelled',
    type: ticket_status_enum.cancelled,
    sequence: 6,
  },
  {
    status: 'finished',
    colorCode: theme.palette.taskStatus.resolved,
    title: 'Finished',
    type: ticket_status_enum.finished,
    sequence: 7,
  },
  {
    status: 'errored',
    colorCode: theme.palette.taskStatus.errored,
    title: 'Errored',
    type: ticket_status_enum.errored,
    sequence: 8,
  },
  {
    status: 'backlog',
    colorCode: theme.palette.taskStatus.open,
    title: 'Backlog',
    type: ticket_status_enum.backlog,
    sequence: 9,
  },
];

export const getTaskStatusArray = [
  {
    status: 'available',
    colorCode: colors.orange[400],
    title: 'Available',
    type: tasks_status_enum.available,
    sequence: 1,
  },
  {
    status: 'backlog',
    colorCode: colors.orange[500],
    title: 'Backlog',
    type: tasks_status_enum.backlog,
    sequence: 2,
  },
  {
    status: 'client_review',
    colorCode: colors.red[600],
    title: 'Client Review',
    type: tasks_status_enum.client_review,
    sequence: 3,
  },
  {
    status: 'in_progress',
    colorCode: colors.red[600],
    title: 'In Progress',
    type: tasks_status_enum.in_progress,
    sequence: 4,
  },
  {
    status: 'internal_review',
    colorCode: colors.green[500],
    title: 'Internal Review',
    type: tasks_status_enum.internal_review,
    sequence: 5,
  },
  {
    status: 'needs_changes',
    colorCode: colors.green[400],
    title: 'Needs Changes',
    type: tasks_status_enum.needs_changes,
    sequence: 6,
  },
  {
    status: 'cancelled',
    colorCode: colors.grey[600],
    title: 'Cancelled',
    type: tasks_status_enum.cancelled,
    sequence: 8,
  },
  {
    status: 'finished',
    colorCode: colors.green[600],
    title: 'Finished',
    type: tasks_status_enum.finished,
    sequence: 9,
  },
  {
    status: 'parked',
    colorCode: colors.red[600],
    title: 'Parked',
    type: tasks_status_enum.parked,
    sequence: 10,
  },
];

export const getRepository = (tickets: ticketListFragment[]): string[] => {
  const repositories: string[] = [];
  tickets.forEach(({ ticket_repos }) => {
    if (ticket_repos) {
      ticket_repos.forEach(r => {
        if (r.git_repo) {
          repositories.push(r.git_repo.name);
        }
      });
    }
  });
  return uniq(repositories);
};

export const getTeamDevelopers = (
  userTeam: fetchTeamOverview_user_teams_user_team_members[]
): (string | undefined)[] => {
  return userTeam.map(u => {
    return u.user.developer?.id;
  });
};

export const getSortedTickets = (tickets: fetchClientTickets_tickets[]) => {
  const sortedTickets: TicketSequence[] = [];
  tickets.forEach((ticket: fetchClientTickets_tickets) => {
    sortedTickets.push({
      ...ticket,
      status: ticket.status || getTicketStatusArray[0]?.type || null,
      sequence: getReadableTicketStatusObject(ticket.status)?.sequence,
    });
  });
  return _sortBy(sortedTickets, 'sequence');
};

export const getFilterFromUrl = () => {
  if (!window) return;
  const filterParam: TicketFilterDataType = {};
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('status')) {
    filterParam.status = urlParams.get('status')?.split(',');
  }
  if (urlParams.has('repo')) {
    filterParam.repo = urlParams.get('repo')?.split(',');
  }
  if (urlParams.has('developer')) {
    filterParam.developer = urlParams.get('developer')?.split(',');
  }
  if (urlParams.has('project')) {
    filterParam.project = urlParams.get('project')?.split(',');
  }
  if (urlParams.has('client')) {
    filterParam.client = urlParams.get('client')?.split(',');
  }
  if (urlParams.has('keyword')) {
    filterParam.keyword = urlParams.get('keyword')?.split(',');
  }
  if (urlParams.has('technology')) {
    filterParam.technology = urlParams.get('technology')?.split(',');
  }
  if (urlParams.has('page')) {
    filterParam.page = urlParams.get('page')?.split(',');
  }
  if (urlParams.has('need_approval')) {
    filterParam.need_approval = urlParams.get('need_approval');
  }

  return !filterParam ? null : filterParam;
};

export const priorities: Priority = {
  'LOW PRIORITY': [1, 2],
  'MEDIUM PRIORITY': [3, 4],
  'HIGH PRIORITY': [5],
};

export const handleFilterFromData = (filterData: TicketFilterDataType) => {
  const filterOptions = {
    ticketFilter: {},
  };

  const filters: tickets_bool_exp[] = [];
  if ('keyword' in filterData) {
    if (filterData.keyword && filterData.keyword.length > 0) {
      filters.push({
        _and: filterData.keyword?.map(k => ({
          _or: [
            { title: { _ilike: `%${k}%` } },
            { description: { _ilike: `%${k}%` } },
            { code: { _ilike: `%${k}%` } },
          ],
        })),
      });
    }
  }
  if ('status' in filterData) {
    if (filterData.status && filterData.status.length > 0) {
      filters.push({
        status: {
          _in: filterData.status?.map(
            s => ticket_status_enum[s as keyof typeof ticket_status_enum]
          ),
        },
      });
    }
  }

  if ('priority' in filterData) {
    if (filterData.priority && filterData.priority.length > 0) {
      let priority = priorities[filterData.priority[0] || 0];
      filters.push({
        priority: {
          _in: priority,
        },
      });
    }
  }

  if ('technology' in filterData) {
    if (filterData.technology && filterData.technology.length > 0) {
      filters.push({
        client_project: {
          client_project_technologies: {
            technology: {
              name: {
                _in: filterData.technology?.map(
                  s => technology_enum[s as keyof typeof technology_enum]
                ),
              },
            },
          },
        },
      });
    }
  }

  if ('repo' in filterData) {
    if (filterData.repo && filterData.repo.length > 0) {
      filters.push({
        _or: filterData.repo?.map(r => ({
          tasks: {
            git_repo: {
              name: {
                _eq: `${r}`,
              },
            },
          },
        })),
      });
    }
  }
  if ('project' in filterData) {
    if (filterData.project && filterData.project.length > 0) {
      filters.push({
        client_project: {
          name: {
            _in: filterData.project,
          },
        },
      });
    }
  }
  if ('client' in filterData) {
    if (filterData.client && filterData.client.length > 0) {
      filters.push({
        clientId: {
          _in: filterData.client,
        },
      });
    }
  }
  if ('startDate' in filterData) {
    filters.push({
      createdAt: {
        _gte: filterData.startDate,
      },
    });
  }
  if ('endDate' in filterData) {
    filters.push({
      createdAt: {
        _lte: filterData.endDate,
      },
    });
  }

  if ('need_approval' in filterData) {
    if (filterData.need_approval && filterData.need_approval === 'true') {
      filters.push({
        ticket_credit_approval: {
          need_approval: { _eq: true },
        },
      });
    }
  }
  filterOptions.ticketFilter = { _and: filters };
  return filterOptions;
};

export const handleTaskFilterFromData = (filterData: TaskFilterDataType) => {
  const filterOptions: DevTaskFilterType = {
    taskFilter: {},
  };
  const filters = [];

  if ('keyword' in filterData) {
    if (filterData.keyword && filterData.keyword.length > 0) {
      filters.push({
        _and: filterData.keyword?.map(k => ({
          _or: [
            { title: { _ilike: `%${k}%` } },
            { description: { _ilike: `%${k}%` } },
            { ticketCode: { _ilike: `%${k}%` } },
          ],
        })),
      });
    }
  }

  if ('status' in filterData) {
    if (filterData.status && filterData.status.length > 0) {
      filters.push({
        status: {
          _in: filterData.status?.map(
            s => tasks_status_enum[s as keyof typeof tasks_status_enum]
          ),
        },
      });
    }
  }

  if ('client' in filterData) {
    if (filterData.client && filterData.client.length > 0) {
      filters.push({
        clientId: {
          _in: filterData.client,
        },
      });
    }
  }

  if ('developer' in filterData) {
    if (size(filterData.developer)) {
      filters.push({
        developerId: {
          _in: filterData.developer,
        },
      });
    }
  }

  if ('technology' in filterData) {
    if (filterData.technology && filterData.technology.length > 0) {
      filters.push({
        task_technologies: {
          technology: {
            name: {
              _in: filterData.technology?.map(
                s => technology_enum[s as keyof typeof technology_enum]
              ),
            },
          },
        },
      });
    }
  }
  if ('startDate' in filterData) {
    filters.push({
      createdAt: {
        _gte: filterData.startDate,
      },
    });
  }
  if ('endDate' in filterData) {
    filters.push({
      createdAt: {
        _lte: filterData.endDate,
      },
    });
  }
  filterOptions.taskFilter = { _and: filters };
  return filterOptions;
};
