import React from 'react';
import Radio, { RadioProps as MuiRadioProps } from '@material-ui/core/Radio';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export type RadioButtonProps = MuiRadioProps &
  Partial<Pick<FormControlLabelProps, 'label'>>;

const RadioButton: React.FC<RadioButtonProps> = ({
  color = 'primary',
  size = 'small',
  ...other
}) => {
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        key={other.key}
        control={<Radio color={color} size={size} {...other} />}
        label={other.label}
      />
    </FormControl>
  );
};

export default RadioButton;
