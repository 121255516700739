import React from 'react';
import { SLACounter } from 'lib/stores';
import Label from 'template/components/Label';
/**
 * This function returns an object containing label and tooltip.
 * @param SLAcounter is a counter of type `{value: number, color: string}`
 * @param tooltip is the text shown in the tooltip, where "$$" is replaced by `SLACounter.value`
 */
const counterConfig = (SLAcounter: SLACounter, tooltip: string) => {
  if (!SLAcounter || SLAcounter.value === 0) return {};
  return {
    label: () => (
      <>
        <Label color={SLAcounter.color} shape="rounded">
          {SLAcounter.value}
        </Label>
      </>
    ),
    tooltip: tooltip?.replace('$$', `${SLAcounter.value}`),
  };
};

export default counterConfig;
