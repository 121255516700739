import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { DEVELOPER_AVATAR_DETAILS } from '@engine/common/graphql/roles/user/generated/DEVELOPER_AVATAR_DETAILS';
import { Theme } from 'template/theme';
import { Avatar } from 'ui';

const useStyles = makeStyles<Theme, { small?: boolean }>(theme => ({
  root: {
    display: 'flex',
    paddingLeft: ({ small }) => (small ? 10 : 16),
    justifyItems: 'center',
    alignItems: 'center',
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    marginLeft: ({ small }) => -1 * (small ? 10 : 16),
    '&:hover': {
      zIndex: 2,
    },
    height: ({ small }) => (small ? 28 : 40),
    width: ({ small }) => (small ? 28 : 40),
  },
  more: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export type DeveloperAvatarsProps = {
  avatars: DEVELOPER_AVATAR_DETAILS[]; // TODO: change to USER_AVATAR_DETAILS
  limit?: number;
  className?: string;
  size?: 'default' | 'small';
};

const Avatars = ({ size = 'default', ...props }: DeveloperAvatarsProps) => {
  const { avatars, className, limit } = props;
  const defaultLimit = limit || 3;
  const classes = useStyles({ small: size === 'small' });
  const avatarNodes = avatars
    .slice(0, defaultLimit)
    .map((item: DEVELOPER_AVATAR_DETAILS) => (
      <Avatar
        key={item.id}
        className={classes.avatar}
        src={item.user.avatarUrl || ''}
        name={`${item.user.firstName} ${item.user.lastName}`}
        alt={`${item.user.firstName} ${item.user.lastName}`}
      />
    ));

  if (avatars.length > defaultLimit) {
    avatarNodes.push(
      <Avatar
        key="others"
        name="View"
        className={clsx(classes.avatar, classes.more)}
      >
        +{avatars.length - defaultLimit}
      </Avatar>
    );
  }
  return <div className={clsx(classes.root, className)}>{avatarNodes}</div>;
};

export default memo(Avatars);
