import gql from 'graphql-tag';
import { TICKET_LIST_FRAGMENT } from '../admin/fragments/ticket_list';
import { CLIENT_STATS_FRAGMENT } from '../user/fragments/client_stats';
import { TICKET_TIME_TO_SURFACE } from '../user/fragments/time_to_surface';
import {
  GIT_BRANCH_REFS_FRAGMENT,
  GIT_REPO_FRAGMENT,
  GIT_REPO_SLICES_FRAGMENT,
} from './fragments/git_repos';

const CLIENT_OVERVIEW_INVOICES = gql`
  fragment CLIENT_OVERVIEW_INVOICES on client_invoices {
    costInUSD
    startAt
    endAt
    dueAt
    costInCredits
    clientId
    id
    client_invoice_balance {
      id
      currrent_paid_balance_in_usd
      previous_balance_in_usd
      previous_balance_in_credits
      previous_consumed_balance_in_credits
      consumed_balance_in_credits
    }
  }
`;

export const TICKET_STATUS = gql`
  fragment TICKET_STATUS on tickets {
    id
    status
    needsApproval
  }
`;

export const FETCH_CLIENT_BILLING_DETAILS = gql`
  query fetchClientBillingDetails(
    $clientId: String!
    $clientInvoiceIds: [Int!]!
  ) {
    client: clients_by_pk(id: $clientId) {
      id
      legalName
      name
      billingEmail
      stripeCustomerId
      addressAddress
      creditBatchSize

      client_invoices(where: { id: { _in: $clientInvoiceIds } }) {
        id
        ...CLIENT_OVERVIEW_INVOICES
        client_transaction {
          status
          id
          costInUSD
          costInCredits
        }
      }
    }
  }
  ${CLIENT_OVERVIEW_INVOICES}
`;

export const FETCH_CLIENT_BLOCKERS_BY_CLIENTID = gql`
  query fetchClientBlockers($clientId: String!) {
    client: clients_by_pk(id: $clientId) {
      client_blockers(order_by: { status: asc }) {
        id
        details
        summary
        createdAt
        updatedAt
        status
        unblockDetails
        user {
          name
        }
        ticket_client_blockers {
          ticket {
            code
            title
          }
        }
      }
    }
  }
`;

export const CLIENT_TRANSACTIONS_FRAGMENT = gql`
  fragment CLIENT_TRANSACTIONS_FRAGMENT on client_transactions {
    id
    transactedAt
    costInUSD
    costInCredits
    status
  }
`;

export const FETCH_CLIENT_OVERVIEW = gql`
  query fetchClientOverview($clientId: String!) {
    client: clients_by_pk(id: $clientId) {
      id
      name
      onBoardedAt
      meetings(order_by: { scheduledAt: desc }) {
        scheduledAt
        id
      }
      tickets {
        code
        id
        completedAt
        status
        costInCredits
        billableCostInCredits
        upperBudget
        lowerBudget
        tasks {
          costInCredits
          completedAt
          status
        }
        ticket_refunds(where: { deletedAt: { _is_null: true } }) {
          createdAt
          costInCredits
        }
        ticket_credit_approval {
          need_approval
        }
      }

      client_invoices(order_by: { endAt: desc }) {
        ...CLIENT_OVERVIEW_INVOICES
      }

      client_transactions(order_by: { transactedAt: desc }) {
        ...CLIENT_TRANSACTIONS_FRAGMENT
      }

      pending_transactions: client_transactions(
        where: { status: { _nin: [in_progress, successful, cancelled] } }
      ) {
        id
        costInUSD
        costInCredits
        transactedAt
        clientId
      }

      pending_approval_tickets: tickets_aggregate(
        where: { ticket_credit_approval: { need_approval: { _eq: true } } }
      ) {
        aggregate {
          count
        }
      }

      tasks(
        where: {
          status: {
            _nin: [
              cancelled
              finished
              backlog
              parked
              available
              errored
              client_review
            ]
          }
        }
      ) {
        ticketCode
        id
        ticket {
          id
          client_project {
            id
            client_project_users {
              users
            }
          }
        }
      }

      # draftInvoices: client_invoices(where: { status: { _eq: draft } }) {
      #   id
      #   costInCredits
      #   costInUSD
      # }

      git_repos {
        id
      }
      client_projects {
        client_project_pricing_plans(
          where: {
            _or: [{ endAt: { _is_null: true } }, { endAt: { _gte: "now()" } }]
          }
        ) {
          id
          credit_pricing_plan {
            id
            costPerCredit
          }
        }
      }
    }

    distinctDevs: developers_aggregate(
      where: {
        _or: [
          {
            tasksByManagerid: {
              clientId: { _eq: $clientId }
              status: {
                _nin: [available, backlog, cancelled, finished, parked]
              }
            }
          }
          {
            tasksByDeveloperid: {
              clientId: { _eq: $clientId }
              status: {
                _nin: [available, backlog, cancelled, finished, parked]
              }
            }
          }
          {
            task_reviewers: {
              task: {
                clientId: { _eq: $clientId }
                status: {
                  _nin: [available, backlog, cancelled, finished, parked]
                }
              }
            }
          }
        ]
      }
      distinct_on: [id]
    ) {
      aggregate {
        count
      }
    }

    client_daily_standups(
      where: { clientId: { _eq: $clientId } }
      order_by: { submittedAt: desc_nulls_last }
    ) {
      summary
      submittedAt
      ticket_daily_standups {
        updatedETA
        message
        ticket {
          code
          ticketLink
          completedAt
        }
      }
    }

    project_daily_standup(
      where: { client_project: { clientId: { _eq: $clientId } } }
      order_by: { submittedAt: desc_nulls_last }
    ) {
      summary
      submittedAt
      client_project {
        name
      }
      ticket_daily_standups {
        updatedETA
        message
        ticket {
          code
          ticketLink
          completedAt
        }
      }
    }
    github_repos(
      where: {
        _and: [
          { git_repo_by_sid: { clientId: { _eq: $clientId } } }
          { deletedAt: { _is_null: true } }
        ]
        # _or: [
        #   {
        #     _and: [
        #       { git_repo_by_sid: { clientId: { _eq: $clientId } } } # this will def show empty as it's not linked yet.
        #       { deletedAt: { _is_null: true } }
        #     ]
        #   }
        #   {
        #     github_repo_users: { login: { _eq: "accountSid_of_current_user" } }
        #   }
        # ]
      }
    ) {
      id
      name
      # github_repo_users {
      #   id
      #   login
      # }
    }
    github_app_installations(
      where: {
        _and: [
          {
            github_organization: {
              git_organization: { clientId: { _eq: $clientId } }
            }
          }
          { deletedAt: { _is_null: true } }
        ]
      }
    ) {
      id
    }
    client_stats(where: { id: { _eq: $clientId } }) {
      ...CLIENT_STATS_FRAGMENT
    }
  }
  ${CLIENT_STATS_FRAGMENT}
  ${CLIENT_OVERVIEW_INVOICES}
  ${CLIENT_TRANSACTIONS_FRAGMENT}
`;

export const FETCH_ACTIVE_CLIENT_PRICING_PLAN = gql`
  query fetchActiveClientPricingPlan($clientId: String) {
    client_project_pricing_plans(
      where: {
        _and: [
          { _or: [{ endAt: { _is_null: true } }, { endAt: { _gte: "now()" } }] }
          { client_project: { clientId: { _eq: $clientId } } }
        ]
      }
      limit: 1
    ) {
      id
      clientProjectId
      credit_pricing_plan {
        id
        costPerCredit
      }
    }
  }
`;

export const FETCH_ALL_ACTIVE_CLIENT_PRICING_PLANS = gql`
  query fetchAllActiveClientPricingPlans {
    client_project_pricing_plans(
      where: {
        _and: [
          { _or: [{ endAt: { _is_null: true } }, { endAt: { _gte: "now()" } }] }
        ]
      }
    ) {
      id
      credit_pricing_plan {
        id
        costPerCredit
      }
    }
  }
`;

export const FETCH_CLIENT_INVOICES = gql`
  query fetchClientInvoices($clientId: String!) {
    client_invoices(
      where: { clientId: { _eq: $clientId } }
      order_by: { dueAt: desc }
    ) {
      id
      dueAt
      costInUSD
      costInCredits
      startAt
      endAt
    }
  }
`;

export const FETCH_CLIENT_INVOICES_AND_TICKETS = gql`
  query fetchClientInvoicesAndTickets($clientId: String!) {
    client_invoices(
      where: { clientId: { _eq: $clientId } }
      order_by: { dueAt: desc }
    ) {
      id
      dueAt
      costInUSD
      costInCredits
      maxBudgetInCredits
      startAt
      endAt
      tickets {
        id
        code
        completedAt
        costInCredits
        createdAt
        description
        ticket_credit_approval {
          need_approval
        }
      }
    }
  }
`;

export const FETCH_CLIENT_TRANSACTIONS = gql`
  query fetchClientTransactions($clientId: String!) {
    client_transactions(where: { clientId: { _eq: $clientId } }) {
      id
      invoiceCode
      costInCredits
      costInUSD
      dueAt
      status
      transactedAt
      channelType
      channelTransactionId
    }
  }
`;

export const FETCH_CLIENT_INVOICES_AND_TRANSACTIONS = gql`
  query fetchClientInvoicesAndTransactions($clientId: String!) {
    client_invoices(
      where: { clientId: { _eq: $clientId } }
      order_by: { dueAt: desc }
    ) {
      id
      dueAt
      costInUSD
      costInCredits
      startAt
      endAt
    }
    client_transactions(
      where: { clientId: { _eq: $clientId } }
      order_by: { transactedAt: asc }
    ) {
      id
      invoiceCode
      costInCredits
      costInUSD
      dueAt
      status
      transactedAt
      channelType
      channelTransactionId
    }
  }
`;

export const FETCH_CLIENT_TICKET_OVERVIEW = gql`
  query fetchClientTicketOverview(
    $clientId: String!
    $projectFilter: tickets_bool_exp!
  ) {
    tickets(
      where: {
        _and: [
          { clientId: { _eq: $clientId } }
          { isInternal: { _eq: false } }
          $projectFilter
        ]
      }
      order_by: { createdAt: desc }
    ) {
      ...ticketListFragment
      status
      tasks {
        git_repo {
          id
          name
        }
      }
    }
    client_projects(
      order_by: { createdAt: desc }
      where: { clientId: { _eq: $clientId } }
    ) {
      id
      name
    }
  }
  ${TICKET_LIST_FRAGMENT}
`;

export const FETCH_CLIENT_TICKET_FILTER_GQL = gql`
  query fetchClientTickets(
    $clientId: String!
    $ticketFilter: tickets_bool_exp!
    $sortedFilter: tickets_order_by!
    $projectFilter: tickets_bool_exp!
  ) {
    tickets(
      where: {
        _and: [
          { clientId: { _eq: $clientId } }
          { isInternal: { _eq: false } }
          $projectFilter
          $ticketFilter
        ]
      }
      order_by: [$sortedFilter]
    ) {
      id
      ticketLink
      ...TICKET_STATUS
      statusUpdateReason
      code
      completedAt
      createdAt
      costInCredits
      billableCostInCredits
      title
      invoiceId
      client_project {
        id
        name
      }
      tasks {
        id
        status
        git_repo {
          id
          name
        }
      }
      updatedAt
      ticket_daily_standups(order_by: { submittedAt: desc }, limit: 1) {
        updatedETA
      }
      ...TICKET_TIME_TO_SURFACE
    }
  }
  ${TICKET_STATUS}
  ${TICKET_TIME_TO_SURFACE}
`;

export const FETCH_CLIENT_DUE_INVOICES = gql`
  query fetchClientDueInvoices($clientId: String!) {
    client_invoices(
      where: { dueAt: { _lte: "now()" }, clientId: { _eq: $clientId } }
    ) {
      clientId
      dueAt
      costInUSD
      costInCredits
      id
    }
  }
`;

export const FETCH_CLIENT_INVOICES_OVERVIEW = gql`
  query fetchClientInvoicesOverview($clientId: String!) {
    clients_by_pk(id: $clientId) {
      client_projects {
        client_project_pricing_plans(
          where: {
            _or: [{ endAt: { _gte: "now()" } }, { endAt: { _is_null: true } }]
          }
        ) {
          id
          startAt
          endAt
          credit_pricing_plan {
            id
          }
        }
      }
      id
      name
    }
    client_invoices(
      where: { clientId: { _eq: $clientId } }
      order_by: { dueAt: asc_nulls_first }
    ) {
      ...CLIENT_OVERVIEW_INVOICES
    }
  }

  ${CLIENT_OVERVIEW_INVOICES}
`;

export const FETCH_CLIENT_DUE_INVOICES_AGGREGATE = gql`
  query fetchClientDueInvoicesAggregate($clientId: String!) {
    client_transactions_aggregate(
      where: {
        clientId: { _eq: $clientId }
        status: { _nin: [cancelled, failed] }
      }
    ) {
      aggregate {
        sum {
          costInUSD
          costInCredits
        }
      }
    }
    client_invoices_aggregate(
      where: { dueAt: { _lte: "now()" }, clientId: { _eq: $clientId } }
    ) {
      aggregate {
        sum {
          costInUSD
          costInCredits
        }
      }
    }
  }
`;

export const FETCH_CLIENT_ON_BOARDING_AT_AND_MEETINGS = gql`
  query fetchClientOnBoardingAtAndMeetings($userId: Int!) {
    user: users_by_pk(id: $userId) {
      firstName
      lastName
      defaultEmail
      client_users {
        client {
          id
          name
          onBoardedAt
          meetings {
            scheduledAt
          }
        }
      }
    }
  }
`;

export const CLIENT_TIME_USER_TOTALS = gql`
  fragment CLIENT_TIME_USER_TOTALS on client_invoice_time_user_totals {
    totalOrganizedTimeInSecs
    user {
      firstName
      lastName
    }
  }
`;

export const INVOICE_TIME_TO_SURFACE = gql`
  fragment INVOICE_TIME_TO_SURFACE on client_invoices {
    client_invoice_time_user_totals {
      ...CLIENT_TIME_USER_TOTALS
    }
    tickets {
      ...TICKET_TIME_TO_SURFACE
    }
  }
  ${CLIENT_TIME_USER_TOTALS}
  ${TICKET_TIME_TO_SURFACE}
`;

export const FETCH_CLIENT_INVOICES_WITH_BALANCES_AND_TRANSACTIONS = gql`
  query fetchClientInvoicesWithBalanceAndTransactions($clientId: String!) {
    client_invoices_with_balance(
      where: { clientId: { _eq: $clientId } }
      order_by: { dueAt: desc }
    ) {
      id
      dueAt
      costInUSD
      costInCredits
      currrent_paid_balance_in_usd
      previous_balance_in_credits
      previous_balance_in_usd
      previous_consumed_balance_in_credits
      consumed_balance_in_credits
      consumed_balance_in_credits_undiscounted
      createdAt
      unfinishedTickets: tickets_aggregate(
        where: { status: { _nin: [finished, cancelled] } }
        order_by: { completedAt: asc_nulls_last }
      ) {
        aggregate {
          count
        }
      }
    }
    client_transactions(
      where: { clientId: { _eq: $clientId } }
      order_by: { transactedAt: desc }
    ) {
      id
      invoiceCode
      costInCredits
      costInUSD
      dueAt
      status
      transactedAt
      channelType
      channelTransactionId
    }
    task_credit_usage_aggregated(
      where: { clientId: { _eq: $clientId } }
      order_by: { usageMonth: desc }
    ) {
      usageMonth
      ticketCount
      devCount
      discountedCreditsConsumed
    }
  }
  ${INVOICE_TIME_TO_SURFACE}
`;

export const FETCH_CLIENT_INVOICES_AND_BALANCES_AND_TICKETS = gql`
  query fetchClientInvoicesAndBalances($clientId: String!, $invoicesId: Int!) {
    client_invoices_with_balance(
      where: { clientId: { _eq: $clientId }, id: { _eq: $invoicesId } }
      order_by: { dueAt: desc }
    ) {
      id
      client {
        name
        legalName
        assignedEntityName
        addressAddress
        id
      }
      clientId
      client_invoice {
        ...INVOICE_TIME_TO_SURFACE
      }
      dueAt
      costInUSD
      costInCredits
      currrent_paid_balance_in_usd
      previous_balance_in_usd
      previous_balance_in_credits
      previous_consumed_balance_in_credits
      createdAt
      tickets(
        where: { isInternal: { _eq: false } }
        order_by: { completedAt: asc_nulls_last }
      ) {
        id
        title
        status
        code
        completedAt
        ticketLink
        costInCredits
        createdAt
        description
        billableCostInCredits
        clientProjectId
        client_project {
          name
          client_project_pricing_plans {
            id
            startAt
            endAt
            credit_pricing_plan {
              id
              costPerCredit
              creditsPerMidLevelHour
            }
          }
        }
        ticket_credit_approval {
          need_approval
        }
        ticket_credit_approvals(where: { status: { _eq: approved } }) {
          reviewer {
            firstName
            lastName
          }
        }
        ...TICKET_TIME_TO_SURFACE
      }
    }
  }
  ${INVOICE_TIME_TO_SURFACE}
  ${TICKET_TIME_TO_SURFACE}
`;

export const FETCH_TICKET_BLOCKERS_BY_TICKETID = gql`
  query fetchTicketBlockers($ticketId: Int!) {
    client_blockers(
      where: {
        _and: { ticket_client_blockers: { ticket: { id: { _eq: $ticketId } } } }
      }
      order_by: { status: asc }
    ) {
      id
      details
      summary
      createdAt
      updatedAt
      status
      unblockDetails
      user {
        name
      }
      ticket_client_blockers {
        ticket {
          code
          title
        }
      }
    }
  }
`;

export const FETCH_CLIENT_APP_INSTALLATIONS_FROM_USER_ID = gql`
  query fetchClientAppInstallationsFromUserId($userId: Int!) {
    user: users_by_pk(id: $userId) {
      git_users {
        id
        github_account {
          id
          sid
          github_app_installations(
            where: { _and: [{ deletedAt: { _is_null: true } }] }
          ) {
            id
            sid
            organizationSid
            github_organization {
              id
              sid
              login
              git_organization {
                id
                syncTableName
                syncSid
                clientId
              }
            }
          }
          linked_installations: github_app_installations(
            where: {
              _and: [
                {
                  deletedAt: { _is_null: true }
                  github_organization: {
                    git_organization: { clientId: { _is_null: false } }
                  }
                }
              ]
            }
          ) {
            id
            sid
            organizationSid
            github_organization {
              id
              login
              git_organization {
                id
                clientId
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_CLIENT_USER_ROLE = gql`
  query fetchClientUserRole($clientId: String!, $userId: Int!) {
    client_users(
      where: {
        _and: [{ clientId: { _eq: $clientId } }, { userId: { _eq: $userId } }]
      }
    ) {
      role
    }
  }
`;

export const FETCH_CLIENT_BUDGET = gql`
  query fetchClientBudget($clientId: String!) {
    client: clients_by_pk(id: $clientId) {
      defaultCreditBudget
    }
  }
`;

export const FETCH_STRIPE_PAYMENT_METHODS = gql`
  query getStripePaymentMethods($clientId: String!) {
    getStripePaymentMethods(clientId: $clientId) {
      paymentMethods {
        id
        object
        type
        card {
          last4
          brand
          exp_month
          exp_year
          funding
          name
        }
        bank {
          accountName
          accountType
          accountHolderType
          last4
          routingNumber
          bankName
        }
      }
      clientId
    }
  }
`;

export const FETCH_GIT_REPO_SLICES_BY_CLIENTID = gql`
  query fetchGitRepoSlicesByClientId($clientId: String!) {
    git_repo_slices(
      where: { git_repo_sliced_from: { clientId: { _eq: $clientId } } }
    ) {
      ...GIT_REPO_SLICES_FRAGMENT
      git_repo_sliced_from {
        id
        name
        owner
        source
      }
    }
    ${GIT_REPO_SLICES_FRAGMENT}
  }
`;

export const FETCH_CLIENT_GIT_REPOS = gql`
  query fetchClientGitRepos($clientId: String!) {
    git_repos(where: { clientId: { _eq: $clientId } }) {
      ...GIT_REPO_FRAGMENT
    }
    ${GIT_REPO_FRAGMENT}
  }
`;

export const FETCH_GIT_BRANCH_REFS_BY_REPO_ID = gql`
  query fetchGitBranchRefsByRepoId($repoId: Int!) {
    git_branch_refs(where: { repoId: { _eq: $repoId } }) {
      ...GIT_BRANCH_REFS_FRAGMENT
    }
    ${GIT_BRANCH_REFS_FRAGMENT}
  }
`;

export const FETCH_CLIENT_NAME = gql`
  query fetchClientName($clientId: String!) {
    clients_by_pk(id: $clientId) {
      id
      name
    }
  }
`;

export const FETCH_CLIENT_LINEAR_TEAMS = gql`
  query fetchClientLinearTeams($clientId: String!) {
    linear_teams(where: { clientId: { _eq: $clientId } }) {
      id
      key
      name
    }
  }
`;
