import React from 'react';
import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@material-ui/core';

export type MenuProps = MuiMenuProps & {
  align?: number | 'center' | 'left' | 'right';
  disablePadding?: boolean;
};

const Menu = (props: MenuProps) => {
  return (
    <MuiMenu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: props.align ?? 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: props.align ?? 'center',
      }}
      style={{ padding: props.disablePadding ? 0 : 'initial', ...props.style }}
      {...props}
    />
  );
};

export default Menu;
