import { createContext, useCallback, useState, useEffect } from 'react';
import {
  XIcon,
  ExclamationIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

import { x } from '@xstyled/emotion';
import { ToastType, useToast } from 'v2/hooks/useToast';

interface IToastConfig {
  autoClose?: boolean;
  delay?: number;
}

export interface IToasts {
  id: string;
  type: ToastType;
  content: string;
  title: string;
  config: IToastConfig;
}

interface IToastContext {
  toasts: { [key: string]: IToasts };
  add: (toast: IToasts) => void;
  remove: (toastId: string) => void;
}

const ToastTypeIcons = {
  [ToastType.success]: { Icon: CheckCircleIcon, color: 'green-400' },
  [ToastType.error]: { Icon: XCircleIcon, color: 'red-400' },
  [ToastType.warning]: { Icon: ExclamationIcon, color: 'yellow-400' },
  [ToastType.info]: {
    Icon: InformationCircleIcon,
    color: 'primary-400',
  },
};

const colorStyles = {
  [ToastType.success]: 'green-800',
  [ToastType.error]: 'red-700',
  [ToastType.warning]: 'yellow-800',
  [ToastType.info]: 'primary-700',
};

const Toast: React.VFC<{
  toast: IToasts;
}> = ({ toast }) => {
  const { remove } = useToast();

  const { title, content, type, config } = toast;

  const ToastIconDetails = ToastTypeIcons[type];

  const onClose = () => remove(toast.id);

  useEffect(() => {
    if (!config.autoClose) {
      return;
    }

    const timeout = setTimeout(() => {
      onClose();
    }, config.delay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <x.div
      display="flex"
      bg="white"
      border="1"
      borderColor="gray-300"
      p={4}
      borderRadius="md"
      justifyContent="space-between"
      alignItems="flex-start"
      boxShadow="lg"
    >
      <x.div color={ToastIconDetails.color}>
        <ToastIconDetails.Icon height={25} widths={25} />
      </x.div>
      <x.div px={4} flexGrow="1">
        <x.p fontWeight="medium" fontSize="sm" color={colorStyles[type]}>
          {title}
        </x.p>
        <x.p color="gray-800" fontSize="sm">
          {content}
        </x.p>
      </x.div>
      <x.button
        bg="unset"
        borderRadius="md"
        display="inline-flex"
        color="gray-400"
        onClick={onClose}
      >
        <XIcon height={20} widths={20} color={'gray-400'} />
      </x.button>
    </x.div>
  );
};

export const ToastContext = createContext<IToastContext>({
  toasts: {},
  add: () => {},
  remove: () => {},
});

export const ToastProvider: React.FC<{}> = ({ children }) => {
  const [toasts, setToasts] = useState<{ [key: string]: IToasts }>({});

  const addToast = useCallback(
    (toast: IToasts) => setToasts(prev => ({ ...prev, [toast.id]: toast })),
    []
  );
  const removeToast = useCallback(
    (toastId: string) => setToasts(({ [toastId]: _, ...prev }) => prev),
    []
  );

  return (
    <ToastContext.Provider
      value={{
        toasts,
        add: addToast,
        remove: removeToast,
      }}
    >
      {Object.keys(toasts).length > 0 && (
        <x.div
          position="fixed"
          top={0}
          right={0}
          zIndex={100000}
          w={'sm'}
          px={4}
          py={6}
          spaceY={4}
          display="flex"
          flexDirection="column"
        >
          {Object.values(toasts).map(toast => (
            <Toast key={toast.id} toast={toast} />
          ))}
        </x.div>
      )}
      {children}
    </ToastContext.Provider>
  );
};
