import React, { ReactNode } from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { Theme } from 'template/theme';
import clsx from 'clsx';
import { USER_AVATAR_DETAILS } from '@engine/common/graphql/roles/user/generated/USER_AVATAR_DETAILS';
import { PartialBy } from 'lib/types';

const useStyles = makeStyles<Theme, { size: Size }>(() => ({
  avatar: {
    height: ({ size }) => (size === 'small' ? 24 : 36),
    width: ({ size }) => (size === 'small' ? 24 : 36),
  },
}));

type Size = 'default' | 'small';

export type AvatarProps = MuiAvatarProps & {
  alt?: string;
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  src?: string;
  name?: string;
  size?: Size;
  variant?: 'square' | 'circle' | 'rounded' | 'circular';
  avatarDetails?: PartialBy<USER_AVATAR_DETAILS, '__typename' | 'id'>;
};
const Avatar = (props: AvatarProps) => {
  const avatarDetails = props.avatarDetails;
  const {
    alt = `Avatar of ${avatarDetails?.firstName} ${avatarDetails?.lastName}`,
    onClick,
    className,
    src = avatarDetails?.avatarUrl ?? '',
    name = `${avatarDetails?.firstName}${
      avatarDetails?.lastName ? ` ${avatarDetails.lastName}` : ''
    }`,
    size = 'default',
    children,
  } = props;

  const classes = useStyles({ size: size });
  return (
    <Tooltip title={`${name}`}>
      <MuiAvatar
        {...props}
        alt={alt}
        onClick={onClick}
        className={clsx(classes.avatar, className)}
        src={src}
        variant={props.variant ?? 'circle'}
      >
        {!src && getInitials(name)}
        {children}
      </MuiAvatar>
    </Tooltip>
  );
};

function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0]?.toUpperCase())
    .join('');
}

export default Avatar;
