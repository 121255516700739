import {
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/styles';
import { IntegrationType } from 'components/integrations/types';
import { checkEqSet } from 'lib/utils';
import getConfig from 'next/config';
import React, { useEffect, useState } from 'react';
import { Theme } from 'template/theme';
import Modal from '../Modal';
import StepperNavigations, {
  StepperNavigationProps,
} from './StepperNavigations';

const CALENDLY_ONBOARDING_LINK = getConfig?.()?.publicRuntimeConfig
  ?.CALENDLY_ONBOARDING_LINK;

const useStyles = makeStyles<Theme>(theme => ({
  root: {},
  dialogContent: {
    height: '100%',
    overflow: 'hidden',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      width: '100vw',
    },
  },
}));

export const implementedIntegrations: Set<IntegrationType> = new Set([
  'GitHub',
]);

type Props = StepperNavigationProps & {};

const ScheduleACallWithUs = ({ onBack, onNext, state }: Props) => {
  const url = CALENDLY_ONBOARDING_LINK;
  const classes = useStyles();
  const [openCalendly, setOpenCalendly] = useState(false);
  const [scheduled, setScheduled] = useState(false);

  const gitProviders = state?.gitProviderOptions || new Set<IntegrationType>();

  const handleEntered = () => {
    // prefill git providers (a2)
    let a2 = '';
    [...gitProviders].forEach((prov, i, provs) => {
      if (prov === 'GitHub') a2 += '1';
      if (prov === 'GitLab') a2 += '2';
      if (prov === 'Bitbucket') a2 += '3';
      if (prov === 'other') a2 += '4';
      if (i < provs.length - 1) a2 += ',';
    });

    const prefill = {
      ...state?.calendlyPrefill,
      customAnswers: {
        a1: state?.companyDetails?.companyName || '',
        a2,
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Calendly.initInlineWidget({
      url,
      prefill,
      parentElement: document.getElementById('calendly-embed'),
      utm: {
        utmSource: state?.companyDetails?.clientId || '',
      },
    });
  };

  const handleCalendlyClose = async () => {
    setOpenCalendly(false);
    if (scheduled) {
      onNext();
    }
  };

  const isCalendlyEvent = (e: MessageEvent): boolean => {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  };

  useEffect(() => {
    window.addEventListener('message', e => {
      if (isCalendlyEvent(e)) {
        console.log(e.data, e.data.event);
        if (e.data.event === 'calendly.event_scheduled') setScheduled(true);
      }
    });
  });

  return (
    <div className={classes.root}>
      {checkEqSet(gitProviders, implementedIntegrations) ? (
        <Typography>
          Schedule a meeting with us so that we can better understand your
          needs.
        </Typography>
      ) : (
        <>
          <Typography>
            You will need to schedule a meeting to setup the following with us
            before we can start.
          </Typography>
          <List>
            {[...gitProviders].map(prov => {
              if (implementedIntegrations.has(prov)) return <></>;
              return (
                <ListItem key={prov}>
                  <ListItemIcon>
                    {prov !== 'other' ? (
                      <img
                        src={`/images/logos/${prov}-logo.svg`}
                        alt={`${prov} logo`}
                        height="24"
                      />
                    ) : (
                      <HelpIcon />
                    )}
                  </ListItemIcon>
                  {prov !== 'other' ? (
                    <ListItemText primary={`Configure your ${prov} projects`} />
                  ) : (
                    <ListItemText primary="Configure your projects from another platform" />
                  )}
                </ListItem>
              );
            })}
          </List>
        </>
      )}
      <StepperNavigations
        onBack={onBack}
        onNext={() => setOpenCalendly(true)}
        nextLabel="schedule meeting"
      />
      <Modal
        onClose={handleCalendlyClose}
        aria-labelledby="calendly-modal"
        open={openCalendly}
        onEntered={handleEntered}
        fullScreen
        className={classes.modal}
      >
        <DialogContent id="calendly-embed" className={classes.dialogContent} />
      </Modal>
    </div>
  );
};

export default ScheduleACallWithUs;
