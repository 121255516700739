import Head from 'next/head';
import React, { ReactNode } from 'react';

type Props = {
  title?: string;
  children: ReactNode;
};

export const Layout = ({ title = 'GitStart', children }: Props) => {
  return (
    <>
      <Head>
        <title>{title === 'GitStart' ? title : `${title} | GitStart`}</title>
      </Head>
      {children}
    </>
  );
};
