import gql from 'graphql-tag';
import { CLIENT_INVOICE_FRAGMENT } from './fragments';

export const UPSERT_USER_BY_EMAIL = gql`
  mutation upsertUserByEmail(
    $userEmail: user_emails_insert_input!
    $onConflict: user_emails_on_conflict!
  ) {
    insert_user_emails(objects: [$userEmail], on_conflict: $onConflict) {
      returning {
        email
        user {
          id
          firstName
          lastName
          passwordHash
          agency_user {
            id
            agencyId
          }

          client_users {
            id
            clientId
          }

          admin {
            id
          }
          developer {
            id
            githubId
            agencyId
          }

          git_users {
            githubAccountLogin
          }

          user_team_members {
            user_team {
              user_team_clients {
                clientId
              }
            }
          }
        }
      }
    }
  }
`;

export const SIGNUP_WITH_USER = gql`
  mutation signUpUserWithEmailAndPassword(
    $defaultEmail: String!
    $passwordHash: String!
    $firstName: String
    $lastName: String
  ) {
    insert_user_emails(
      objects: {
        email: $defaultEmail
        user: {
          data: {
            passwordHash: $passwordHash
            defaultEmail: $defaultEmail
            firstName: $firstName
            lastName: $lastName
          }
        }
      }
    ) {
      returning {
        user {
          defaultEmail
          passwordHash
          id
          firstName
          lastName
          passwordHash
          agency_user {
            id
            agencyId
          }
          client_users {
            id
            clientId
          }
          admin {
            id
          }
          developer {
            id

            githubId
            agencyId
          }
          git_users {
            githubAccountLogin
          }
          user_team_members {
            user_team {
              user_team_clients {
                clientId
              }
            }
          }
        }
      }
    }
  }
`;

export const INSERT_NEW_USER = gql`
  mutation insertNewUser($objects: [users_insert_input!]!) {
    insert_users(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_NEW_CANDIDATE = gql`
  mutation insertNewCandidate($objects: [developers_insert_input!]!) {
    insert_developers(objects: $objects) {
      returning {
        id
        user {
          id
          user_emails {
            email
          }
        }
        githubId
        city
        country
        noticePeriod
        culture_screen_feedback
        tech_screen_feedback
        stage
        earliestStartDate
        timezone
        yearsOfExperience
        source
        agencyId
      }
    }
  }
`;

export const INSERT_CLIENT_PENDING_INVOICES = gql`
  mutation insertClientInvoices(
    $invoices: [client_invoices_insert_input!]!
    $onConflict: client_invoices_on_conflict!
  ) {
    insert_client_invoices(objects: $invoices, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...CLIENT_INVOICE_FRAGMENT
      }
    }
  }

  ${CLIENT_INVOICE_FRAGMENT}
`;

export const UPSERT_DEVELOPER_PENDING_INVOICE = gql`
  mutation insertUserInvoices(
    $invoices: [user_invoices_insert_input!]!
    $onConflict: user_invoices_on_conflict!
  ) {
    insert_user_invoices(objects: $invoices, on_conflict: $onConflict) {
      affected_rows
      returning {
        id
        userId
        adjustedCostInUSD
        costInUSD
        dueAt
        startAt
        endAt
        developer_bonus_earnings {
          id
          amountInUSD
        }
        task_reviews {
          id
          costInUSD
        }
        taskReviewsByManagerinvoiceid {
          id
          costInUSD
        }
        tasks {
          id
          costInUSD
        }
        tasksByManagerinvoiceid {
          id
          costInUSD
        }
        task_works {
          id
          costInUSD
        }
        taskWorksByManagerinvoiceid {
          id
          costInUSD
        }
        user_time_earnings {
          id
          earningsinusd
        }
        user {
          login
        }
      }
    }
  }
`;

export const INSERT_NEW_WEEKLY_EARNINGS = gql`
  mutation insertNewWeeklyEarnings(
    $values: [developer_weekly_earning_insert_input!]!
  ) {
    insert_developer_weekly_earning(objects: $values) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT_INVOICES_STARTAT = gql`
  mutation updateClientInvoicesStartAt(
    $invoiceIds: [Int!]!
    $startAt: timestamptz!
  ) {
    update_client_invoices(
      where: { id: { _in: $invoiceIds } }
      _set: { startAt: $startAt }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CLIENT_INVOICES_ENDAT = gql`
  mutation updateClientInvoicesEndAt(
    $invoiceIds: [Int!]!
    $endAt: timestamptz!
  ) {
    update_client_invoices(
      where: { id: { _in: $invoiceIds } }
      _set: { endAt: $endAt }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_TASK_REVIEW_STATE_FINISHED = gql`
  mutation updateTaskReviewStateToFinished(
    $taskId: Int!
    $taskReviewId: Int!
    $taskReviewStatus: task_reviews_status_enum!
    $taskStatus: tasks_status_enum!
    $updatedAt: timestamptz!
  ) {
    update_task_reviews(
      where: { id: { _eq: $taskReviewId } }
      _set: { status: $taskReviewStatus, updatedAt: $updatedAt }
    ) {
      returning {
        id
        status
        costInUSD
        developerId
        managerId
        approvesTaskId
      }
    }

    update_tasks(
      where: { id: { _eq: $taskId } }
      _set: { status: $taskStatus, updatedAt: $updatedAt }
    ) {
      returning {
        id
        status
        title
        prLink: git_pull_request {
          id
          syncable_entity {
            id
            sourceUrl
          }
        }
        description
        costInUSD
        clientId
        reviewerId
        developerId
        managerId
      }
    }
  }
`;

export const UPDATE_TASK_REVIEW_STATE = gql`
  mutation updateTaskReviewState(
    $taskReviewId: Int!
    $status: task_reviews_status_enum!
    $updatedAt: timestamptz!
  ) {
    update_task_reviews(
      where: { id: { _eq: $taskReviewId } }
      _set: { status: $status, updatedAt: $updatedAt }
    ) {
      returning {
        id
        status
        costInUSD
        developerId
        managerId
        approvesTaskId

        task {
          id
          status
          title
          prLink: git_pull_request {
            id
            syncable_entity {
              id
              sourceUrl
            }
          }
          description
          costInUSD
          clientId
          reviewerId
          developerId
          managerId
        }
      }
    }
  }
`;

export const INSERT_CLIENT_USER = gql`
  mutation addClientUser($clientUser: [client_users_insert_input!]!) {
    insert_client_users(objects: $clientUser) {
      returning {
        id
        client {
          id
        }
      }
    }
  }
`;

export const LINK_TICKET_TO_INVOICE = gql`
  mutation updateTicketByCodeAndLinkInvoice(
    $clientId: String!
    $ticketId: Float!
  ) {
    generatePendingInvoicesForClientWithTicket(
      clientId: $clientId
      ticketId: $ticketId
    ) {
      clientId
      pendingInvoices {
        id
        previousCostInUSD
        previousCostInCredits
        newTicketIds
        maxBudgetInCredits
        previousLeftoverBalanceInCredits
        clientId
        costInUSD
        consumedCredits
        costInCredits
        dueAt
      }
    }
  }
`;
