export enum State {
  Today = '#1E88E3',
  Full = '#2B932F',
  Incomplete = '#4FDB2C',
  Missing = '#EF5350',
  Leave = '#000000',
  Pending = '#546E7A',
  Nothing = '#E5E5E5',
  NothingWhite = '#FFFFFF',
}

export interface StatusType {
  [key: string]: string;
}

export const StatusBackgrounds: StatusType = {
  available: '#546e7a',
  backlog: '#546e7a',
  client_review: '#1E88E5',
  closed: '#E53935',
  finished: '#09b43a',
  in_progress: '#546e7a',
  internal_review: '#1E88E5',
  merged: '#E53935',
  needs_changes: '#1E88E5',
  open: '#4CAF50',
  parked: '#E53935',
  partially_blocked: '#E53935',
  partially_under_review: '#1E88E5',
};
