import React from 'react';
import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@material-ui/core';

export type PopoverProps = MuiPopoverProps & {
  align?: number | 'center' | 'left' | 'right';
};

const Popover = (props: PopoverProps) => {
  return (
    <MuiPopover
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: props.align ?? 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: props.align ?? 'center',
      }}
      {...props}
    />
  );
};

export default Popover;
