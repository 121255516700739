import React from 'react';
import { default as RouterLink } from 'template/utils/link';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors,
} from '@material-ui/core';
import NotificationList from './NotificationList';
import Placeholder from './Placeholder';
import { fetchNotifications_notifications } from '@engine/common/graphql/roles/user/generated/fetchNotifications';

type Props = {
  anchorEl: any;
  className?: string;
  notifications: fetchNotifications_notifications[];
  onClose: () => void;
  open: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: '100%',
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center',
  },
}));

const NotificationsPopover = ({ notifications, anchorEl, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList
            className={undefined}
            notifications={notifications}
          />
        ) : (
          <Placeholder />
        )}
        <Divider />
        <CardActions className={classes.actions}>
          <Button component={RouterLink} size="small" href="/notifications">
            See all
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
};

export default NotificationsPopover;
