/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommentBodyType {
  HTML = 'HTML',
  MARKDOWN = 'MARKDOWN',
}

export enum agency_user_roles_enum {
  admin = 'admin',
  internal = 'internal',
  member = 'member',
}

export enum candidate_stage_enum {
  backlog = 'backlog',
  failed_culture_screen = 'failed_culture_screen',
  failed_hack_week = 'failed_hack_week',
  failed_tech_screen = 'failed_tech_screen',
  hired = 'hired',
  invited_for_hack_week = 'invited_for_hack_week',
  missed_culture_screen = 'missed_culture_screen',
  missed_tech_screen = 'missed_tech_screen',
  needs_culture_screen = 'needs_culture_screen',
  needs_hack_week = 'needs_hack_week',
  needs_tech_screen = 'needs_tech_screen',
  on_hold = 'on_hold',
  undergoing_hack_week = 'undergoing_hack_week',
  unqualified = 'unqualified',
}

export enum client_blocker_status_enum {
  pending = 'pending',
  resolved = 'resolved',
  review = 'review',
}

/**
 * unique or primary key constraints on table "client_blockers"
 */
export enum client_blockers_constraint {
  PK_6e24a89cf5ae85282634062b5dc = 'PK_6e24a89cf5ae85282634062b5dc',
}

/**
 * update columns of table "client_blockers"
 */
export enum client_blockers_update_column {
  clientId = 'clientId',
  details = 'details',
  id = 'id',
  projectId = 'projectId',
  resolvedById = 'resolvedById',
  status = 'status',
  summary = 'summary',
  unblockDetails = 'unblockDetails',
  updatedAt = 'updatedAt',
}

export enum client_transaction_status_enum {
  cancelled = 'cancelled',
  failed = 'failed',
  in_progress = 'in_progress',
  pending = 'pending',
  successful = 'successful',
}

export enum client_transaction_types_enum {
  bonus = 'bonus',
  eu_bank = 'eu_bank',
  gb_bank = 'gb_bank',
  hk_bank = 'hk_bank',
  paypal = 'paypal',
  stripe = 'stripe',
  transferwise = 'transferwise',
  us_bank = 'us_bank',
}

/**
 * unique or primary key constraints on table "client_transactions"
 */
export enum client_transactions_constraint {
  PK_affbc9a9a22aa18f40b1bf7a9b3 = 'PK_affbc9a9a22aa18f40b1bf7a9b3',
}

/**
 * update columns of table "client_transactions"
 */
export enum client_transactions_update_column {
  status = 'status',
  transactionError = 'transactionError',
}

export enum client_user_roles_enum {
  admin = 'admin',
  contractor = 'contractor',
  internal = 'internal',
  user = 'user',
}

/**
 * unique or primary key constraints on table "client_users"
 */
export enum client_users_constraint {
  PK_fe74bfd4d01077395ee4204b553 = 'PK_fe74bfd4d01077395ee4204b553',
  UQ_CLIENT_USER = 'UQ_CLIENT_USER',
}

/**
 * update columns of table "client_users"
 */
export enum client_users_update_column {
  clientId = 'clientId',
  id = 'id',
  position = 'position',
  role = 'role',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "clients"
 */
export enum clients_constraint {
  PK_f1ab7cf3a5714dbc6bb4e1c28a4 = 'PK_f1ab7cf3a5714dbc6bb4e1c28a4',
  UQ_910e83629c5861ac0f2feaf9e2b = 'UQ_910e83629c5861ac0f2feaf9e2b',
}

/**
 * update columns of table "clients"
 */
export enum clients_update_column {
  id = 'id',
  legalName = 'legalName',
  name = 'name',
  updatedAt = 'updatedAt',
}

export enum credit_pricing_plan_payment_types_enum {
  on_approval = 'on_approval',
  post_limit = 'post_limit',
  post_monthly = 'post_monthly',
  upfront = 'upfront',
}

export enum description_types_enum {
  html = 'html',
  jira = 'jira',
  markdown = 'markdown',
}

/**
 * unique or primary key constraints on table "developers"
 */
export enum developers_constraint {
  PK_247719240b950bd26dec14bdd21 = 'PK_247719240b950bd26dec14bdd21',
  UQ_247719240b950bd26dec14bdd21 = 'UQ_247719240b950bd26dec14bdd21',
  UQ_b857ddc4fd4392133599b69bc64 = 'UQ_b857ddc4fd4392133599b69bc64',
}

/**
 * update columns of table "developers"
 */
export enum developers_update_column {
  agencyId = 'agencyId',
  appliedAt = 'appliedAt',
  bio = 'bio',
  canRelocate = 'canRelocate',
  city = 'city',
  codeSampleUrl = 'codeSampleUrl',
  cohort = 'cohort',
  country = 'country',
  currentMonthlySalary = 'currentMonthlySalary',
  cv = 'cv',
  earliestStartDate = 'earliestStartDate',
  githubId = 'githubId',
  id = 'id',
  isActiveStudent = 'isActiveStudent',
  isActivelyEmployed = 'isActivelyEmployed',
  linkedInUrl = 'linkedInUrl',
  login = 'login',
  minAnnualSalary = 'minAnnualSalary',
  noticePeriod = 'noticePeriod',
  previousWorkExp = 'previousWorkExp',
  source = 'source',
  targetCurrency = 'targetCurrency',
  timezone = 'timezone',
  transferwiseId = 'transferwiseId',
  workMachineOS = 'workMachineOS',
  yearsOfExperience = 'yearsOfExperience',
}

export enum discussion_comment_body_types_enum {
  html = 'html',
  json = 'json',
  markdown = 'markdown',
}

export enum discussion_comment_read_types_enum {
  dismissed = 'dismissed',
  seen = 'seen',
}

/**
 * unique or primary key constraints on table "discussion_sources"
 */
export enum discussion_sources_constraint {
  PK_bcabc8c7f6adb5051e16d66d67c = 'PK_bcabc8c7f6adb5051e16d66d67c',
  REL_154365d23e8bb5e018c5671405 = 'REL_154365d23e8bb5e018c5671405',
  REL_869d6954b6498b6a0e2420bcb6 = 'REL_869d6954b6498b6a0e2420bcb6',
  UQ_bd18be4f31030393d5ba84af6b2 = 'UQ_bd18be4f31030393d5ba84af6b2',
}

/**
 * update columns of table "discussion_sources"
 */
export enum discussion_sources_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isInternal = 'isInternal',
  resolvedAt = 'resolvedAt',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  taskCode = 'taskCode',
  ticketCode = 'ticketCode',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gcp_storage_objects"
 */
export enum gcp_storage_objects_constraint {
  PK_8cef844a166a77c34d468fb955e = 'PK_8cef844a166a77c34d468fb955e',
  UQ_b0d3334906f86ca63d1769cd63f = 'UQ_b0d3334906f86ca63d1769cd63f',
  UQ_f4bf5b2bab18cdc0e503d850361 = 'UQ_f4bf5b2bab18cdc0e503d850361',
}

/**
 * update columns of table "gcp_storage_objects"
 */
export enum gcp_storage_objects_update_column {
  readLink = 'readLink',
  sid = 'sid',
  updatedAt = 'updatedAt',
  uploadLink = 'uploadLink',
}

export enum git_pull_request_review_state_enum {
  APPROVED = 'APPROVED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  COMMENTED = 'COMMENTED',
  DISMISSED = 'DISMISSED',
  PENDING = 'PENDING',
}

export enum git_pull_request_state_enum {
  CLOSED = 'CLOSED',
  MERGED = 'MERGED',
  OPEN = 'OPEN',
}

export enum git_sources_enum {
  github = 'github',
  gitlab = 'gitlab',
}

export enum inviteToClientRole {
  ADMIN = 'ADMIN',
  CONTRACTOR = 'CONTRACTOR',
}

export enum job_intervals_enum {
  day = 'day',
  hr = 'hr',
  min = 'min',
  never = 'never',
  week = 'week',
}

/**
 * unique or primary key constraints on table "meetings"
 */
export enum meetings_constraint {
  PK_aa73be861afa77eb4ed31f3ed57 = 'PK_aa73be861afa77eb4ed31f3ed57',
  UQ_MEETING_TIME = 'UQ_MEETING_TIME',
  UQ_c801cca6def3dc9f638928affee = 'UQ_c801cca6def3dc9f638928affee',
}

/**
 * update columns of table "meetings"
 */
export enum meetings_update_column {
  associatedClientId = 'associatedClientId',
  calendlyMeetingId = 'calendlyMeetingId',
  createdAt = 'createdAt',
  id = 'id',
  scheduledAt = 'scheduledAt',
  updatedAt = 'updatedAt',
  zoomMeetingSid = 'zoomMeetingSid',
}

/**
 * unique or primary key constraints on table "notifications"
 */
export enum notifications_constraint {
  PK_6a72c3c0f683f6462415e653c3a = 'PK_6a72c3c0f683f6462415e653c3a',
}

/**
 * update columns of table "notifications"
 */
export enum notifications_update_column {
  isArchived = 'isArchived',
  isSeen = 'isSeen',
  updatedAt = 'updatedAt',
}

/**
 * column ordering options
 */
export enum order_by {
  asc = 'asc',
  asc_nulls_first = 'asc_nulls_first',
  asc_nulls_last = 'asc_nulls_last',
  desc = 'desc',
  desc_nulls_first = 'desc_nulls_first',
  desc_nulls_last = 'desc_nulls_last',
}

/**
 * unique or primary key constraints on table "project_daily_standup"
 */
export enum project_daily_standup_constraint {
  PK_a519705a416a1c8f67c6d0cb7c7 = 'PK_a519705a416a1c8f67c6d0cb7c7',
}

/**
 * update columns of table "project_daily_standup"
 */
export enum project_daily_standup_update_column {
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  id = 'id',
  messageForClient = 'messageForClient',
  postedToClient = 'postedToClient',
  submittedAt = 'submittedAt',
  summarizedInClientDailyStandupId = 'summarizedInClientDailyStandupId',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  summary = 'summary',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

export enum requestActionType_enum {
  request = 'request',
  update = 'update',
}

export enum reviewActionType_enum {
  approve = 'approve',
  reject = 'reject',
}

export enum syncable_entity_types_enum {
  clickup_folders = 'clickup_folders',
  clickup_lists = 'clickup_lists',
  clickup_spaces = 'clickup_spaces',
  clickup_task_comments = 'clickup_task_comments',
  clickup_tasks = 'clickup_tasks',
  gcp_storage_buckets = 'gcp_storage_buckets',
  git_repos = 'git_repos',
  github_accounts = 'github_accounts',
  github_app_installations = 'github_app_installations',
  github_branches = 'github_branches',
  github_comment_reactions = 'github_comment_reactions',
  github_commit_contexts = 'github_commit_contexts',
  github_commits = 'github_commits',
  github_issue_comments = 'github_issue_comments',
  github_issues = 'github_issues',
  github_organizations = 'github_organizations',
  github_owners = 'github_owners',
  github_pull_request_comments = 'github_pull_request_comments',
  github_pull_request_review_comments = 'github_pull_request_review_comments',
  github_pull_request_review_threads = 'github_pull_request_review_threads',
  github_pull_request_reviews = 'github_pull_request_reviews',
  github_pull_requests = 'github_pull_requests',
  github_repos = 'github_repos',
  gitlab_issue_comments = 'gitlab_issue_comments',
  gitlab_issues = 'gitlab_issues',
  gitlab_projects = 'gitlab_projects',
  gitlab_pull_request_comments = 'gitlab_pull_request_comments',
  gitlab_pull_requests = 'gitlab_pull_requests',
  gitlab_users = 'gitlab_users',
  google = 'google',
  jira_accounts = 'jira_accounts',
  jira_issue_comments = 'jira_issue_comments',
  jira_issues = 'jira_issues',
  jira_organizations = 'jira_organizations',
  linear_accounts = 'linear_accounts',
  linear_comments = 'linear_comments',
  linear_issues = 'linear_issues',
  linear_teams = 'linear_teams',
  linkedin = 'linkedin',
  rippling_companies = 'rippling_companies',
  rippling_employees = 'rippling_employees',
  rippling_payroll_earning_types = 'rippling_payroll_earning_types',
  rippling_payroll_run_details = 'rippling_payroll_run_details',
  rippling_payroll_run_earnings = 'rippling_payroll_run_earnings',
  rippling_payroll_runs = 'rippling_payroll_runs',
  rippling_payroll_schedules = 'rippling_payroll_schedules',
  slack_apps = 'slack_apps',
  slack_channels = 'slack_channels',
  zoom_meeting_instances = 'zoom_meeting_instances',
  zoom_meetings = 'zoom_meetings',
  zoom_recording_files = 'zoom_recording_files',
  zoom_recordings = 'zoom_recordings',
  zoom_users = 'zoom_users',
}

/**
 * unique or primary key constraints on table "task_client_blockers"
 */
export enum task_client_blockers_constraint {
  PK_f314fcd6eddf7ff5e2d518a12ce = 'PK_f314fcd6eddf7ff5e2d518a12ce',
}

/**
 * update columns of table "task_client_blockers"
 */
export enum task_client_blockers_update_column {
  clientBlockersId = 'clientBlockersId',
  tasksId = 'tasksId',
}

/**
 * unique or primary key constraints on table "task_credit_approval"
 */
export enum task_credit_approval_constraint {
  PK_0195b11109fc1e7ce530608fed7 = 'PK_0195b11109fc1e7ce530608fed7',
}

/**
 * update columns of table "task_credit_approval"
 */
export enum task_credit_approval_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  reason = 'reason',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  status = 'status',
  taskId = 'taskId',
  ticketCreditApprovalId = 'ticketCreditApprovalId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

export enum task_credit_status_enum {
  approved = 'approved',
  auto_approved = 'auto_approved',
  pending = 'pending',
  rejected = 'rejected',
}

/**
 * unique or primary key constraints on table "task_daily_standups"
 */
export enum task_daily_standups_constraint {
  PK_62edf80b503d3d011e049824561 = 'PK_62edf80b503d3d011e049824561',
}

/**
 * update columns of table "task_daily_standups"
 */
export enum task_daily_standups_update_column {
  createdAt = 'createdAt',
  id = 'id',
  submittedAt = 'submittedAt',
  summarizedInTicketDailyStandupId = 'summarizedInTicketDailyStandupId',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  summary = 'summary',
  taskId = 'taskId',
  updatedAt = 'updatedAt',
  updatedETA = 'updatedETA',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "task_dependencies"
 */
export enum task_dependencies_constraint {
  PK_e31de0e173af595a21c4ec8e48b = 'PK_e31de0e173af595a21c4ec8e48b',
}

/**
 * update columns of table "task_dependencies"
 */
export enum task_dependencies_update_column {
  deletedAt = 'deletedAt',
}

/**
 * unique or primary key constraints on table "task_reviewers"
 */
export enum task_reviewers_constraint {
  PK_907b0f58778afe6e17250ae416e = 'PK_907b0f58778afe6e17250ae416e',
}

/**
 * update columns of table "task_reviewers"
 */
export enum task_reviewers_update_column {
  developersId = 'developersId',
  tasksId = 'tasksId',
}

export enum task_reviews_status_enum {
  assigned = 'assigned',
  available = 'available',
  cancelled = 'cancelled',
  failed = 'failed',
  in_progress = 'in_progress',
  passed = 'passed',
}

/**
 * unique or primary key constraints on table "tasks"
 */
export enum tasks_constraint {
  PK_8d12ff38fcc62aaba2cab748772 = 'PK_8d12ff38fcc62aaba2cab748772',
  UQ_050d1f08bba5bc4c4dbafee4417 = 'UQ_050d1f08bba5bc4c4dbafee4417',
  UQ_TASK_CODE_PER_TICKET = 'UQ_TASK_CODE_PER_TICKET',
}

export enum tasks_status_enum {
  available = 'available',
  backlog = 'backlog',
  cancelled = 'cancelled',
  client_review = 'client_review',
  errored = 'errored',
  finished = 'finished',
  in_progress = 'in_progress',
  internal_review = 'internal_review',
  needs_changes = 'needs_changes',
  parked = 'parked',
}

export enum tasks_type_enum {
  code = 'code',
  learning = 'learning',
  qa = 'qa',
  review = 'review',
  spec = 'spec',
}

/**
 * update columns of table "tasks"
 */
export enum tasks_update_column {
  branchName = 'branchName',
  budget = 'budget',
  clientCommitDate = 'clientCommitDate',
  clientCommitMessage = 'clientCommitMessage',
  clientId = 'clientId',
  completedAt = 'completedAt',
  costInCredits = 'costInCredits',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  description = 'description',
  descriptionType = 'descriptionType',
  developerId = 'developerId',
  gitRepoId = 'gitRepoId',
  id = 'id',
  isBillable = 'isBillable',
  lowerBudget = 'lowerBudget',
  managerId = 'managerId',
  prLink = 'prLink',
  reviewerId = 'reviewerId',
  slicedPullRequestId = 'slicedPullRequestId',
  specLink = 'specLink',
  startedAt = 'startedAt',
  status = 'status',
  taskCode = 'taskCode',
  ticketCode = 'ticketCode',
  title = 'title',
  type = 'type',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "ticket_attachments"
 */
export enum ticket_attachments_constraint {
  PK_87a3bf2fd454316a4382b84e80d = 'PK_87a3bf2fd454316a4382b84e80d',
}

/**
 * update columns of table "ticket_attachments"
 */
export enum ticket_attachments_update_column {
  gcpObjectId = 'gcpObjectId',
  ticketId = 'ticketId',
}

/**
 * unique or primary key constraints on table "ticket_credit_approvals"
 */
export enum ticket_credit_approvals_constraint {
  PK_13cfee4da26174bfd556e347f75 = 'PK_13cfee4da26174bfd556e347f75',
}

/**
 * update columns of table "ticket_credit_approvals"
 */
export enum ticket_credit_approvals_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  reason = 'reason',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  status = 'status',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

export enum ticket_credit_status_enum {
  approved = 'approved',
  auto_approved = 'auto_approved',
  pending = 'pending',
  rejected = 'rejected',
}

/**
 * unique or primary key constraints on table "ticket_daily_standups"
 */
export enum ticket_daily_standups_constraint {
  PK_e2c56eb9a2625999ace654b54f9 = 'PK_e2c56eb9a2625999ace654b54f9',
}

/**
 * update columns of table "ticket_daily_standups"
 */
export enum ticket_daily_standups_update_column {
  createdAt = 'createdAt',
  id = 'id',
  message = 'message',
  submittedAt = 'submittedAt',
  summarizedInClientDailyStandupId = 'summarizedInClientDailyStandupId',
  summarizedInProjectDailyStandupId = 'summarizedInProjectDailyStandupId',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  updatedETA = 'updatedETA',
  userId = 'userId',
}

export enum ticket_status_enum {
  available = 'available',
  backlog = 'backlog',
  blocked = 'blocked',
  cancelled = 'cancelled',
  errored = 'errored',
  finished = 'finished',
  in_progress = 'in_progress',
  partially_blocked = 'partially_blocked',
  partially_under_review = 'partially_under_review',
  under_review = 'under_review',
}

/**
 * unique or primary key constraints on table "tickets"
 */
export enum tickets_constraint {
  PK_343bc942ae261cf7a1377f48fd0 = 'PK_343bc942ae261cf7a1377f48fd0',
  UQ_013dd8562f32b40a5d76287c819 = 'UQ_013dd8562f32b40a5d76287c819',
  UQ_1907c2dc72947998e1a95e5b67d = 'UQ_1907c2dc72947998e1a95e5b67d',
  UQ_3a17387be419fb7f4087680f76d = 'UQ_3a17387be419fb7f4087680f76d',
  UQ_90f8619ba4d35663b3ad5701d88 = 'UQ_90f8619ba4d35663b3ad5701d88',
  UQ_c6e20a830c0f8b571abd331b775 = 'UQ_c6e20a830c0f8b571abd331b775',
  UQ_c7f04cb0c8f1a8a8649e8f628d5 = 'UQ_c7f04cb0c8f1a8a8649e8f628d5',
}

export enum tickets_types_enum {
  bug = 'bug',
  consult = 'consult',
  feature = 'feature',
  maintain = 'maintain',
}

/**
 * update columns of table "tickets"
 */
export enum tickets_update_column {
  cancelledAt = 'cancelledAt',
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  code = 'code',
  completedAt = 'completedAt',
  costInCredits = 'costInCredits',
  createdAt = 'createdAt',
  description = 'description',
  descriptionType = 'descriptionType',
  gitRepoId = 'gitRepoId',
  id = 'id',
  invoiceId = 'invoiceId',
  isInternal = 'isInternal',
  lowerBudget = 'lowerBudget',
  managerId = 'managerId',
  priority = 'priority',
  startedAt = 'startedAt',
  status = 'status',
  statusUpdateReason = 'statusUpdateReason',
  statusUpdatedAt = 'statusUpdatedAt',
  ticketLink = 'ticketLink',
  title = 'title',
  type = 'type',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "user_emails"
 */
export enum user_emails_constraint {
  PK_6594597afde633cfeab9a806e4f = 'PK_6594597afde633cfeab9a806e4f',
}

/**
 * update columns of table "user_emails"
 */
export enum user_emails_update_column {
  email = 'email',
  userId = 'userId',
}

export enum user_event_source_types_enum {
  browser_extension = 'browser_extension',
  dashboard = 'dashboard',
  vscode_extension = 'vscode_extension',
}

export enum user_event_types_enum {
  activity_pause = 'activity_pause',
  activity_resume = 'activity_resume',
  app_use = 'app_use',
  browse_url = 'browse_url',
  end_day = 'end_day',
  file_edit = 'file_edit',
  project_pause = 'project_pause',
  project_resume = 'project_resume',
  start_day = 'start_day',
  task_pause = 'task_pause',
  task_resume = 'task_resume',
  ticket_pause = 'ticket_pause',
  ticket_resume = 'ticket_resume',
}

/**
 * unique or primary key constraints on table "user_events"
 */
export enum user_events_constraint {
  PK_22f49067e87f2c8a3fff76543d1 = 'PK_22f49067e87f2c8a3fff76543d1',
}

/**
 * update columns of table "user_events"
 */
export enum user_events_update_column {
  appName = 'appName',
  browserUrl = 'browserUrl',
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  duration = 'duration',
  eventType = 'eventType',
  filePath = 'filePath',
  id = 'id',
  occurredAt = 'occurredAt',
  pollInterval = 'pollInterval',
  taskId = 'taskId',
  ticketId = 'ticketId',
  title = 'title',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

export enum user_leave_types_enum {
  emergency_leave = 'emergency_leave',
  paid_leave = 'paid_leave',
  public_holiday = 'public_holiday',
  sick_leave = 'sick_leave',
  unpaid_leave = 'unpaid_leave',
}

/**
 * unique or primary key constraints on table "user_leaves"
 */
export enum user_leaves_constraint {
  PK_976206f5ab434b02badd34fbfe4 = 'PK_976206f5ab434b02badd34fbfe4',
}

/**
 * update columns of table "user_leaves"
 */
export enum user_leaves_update_column {
  approvedById = 'approvedById',
  deletedAt = 'deletedAt',
  isApproved = 'isApproved',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "user_opportunity_interests"
 */
export enum user_opportunity_interests_constraint {
  PK_6dc8d96d4f445dd2988862ec89d = 'PK_6dc8d96d4f445dd2988862ec89d',
}

/**
 * update columns of table "user_opportunity_interests"
 */
export enum user_opportunity_interests_update_column {
  opportunitiesId = 'opportunitiesId',
}

export enum user_payment_status_enum {
  failed = 'failed',
  in_progress = 'in_progress',
  pending = 'pending',
  successful = 'successful',
}

export enum user_payment_types_enum {
  bank_hk_hsb = 'bank_hk_hsb',
  bank_hk_hsbc = 'bank_hk_hsbc',
  bank_pk_hbl = 'bank_pk_hbl',
  bank_us_frb = 'bank_us_frb',
  brex = 'brex',
  payoneer = 'payoneer',
  paypal = 'paypal',
  rippling = 'rippling',
  transferwise = 'transferwise',
  transferwise_hk = 'transferwise_hk',
}

/**
 * unique or primary key constraints on table "user_skills"
 */
export enum user_skills_constraint {
  PK_060bea7fd45868588324719de3c = 'PK_060bea7fd45868588324719de3c',
}

/**
 * update columns of table "user_skills"
 */
export enum user_skills_update_column {
  aggregatedMonthsOfExperience = 'aggregatedMonthsOfExperience',
  competencyId = 'competencyId',
  lastUsedDate = 'lastUsedDate',
  startDate = 'startDate',
}

/**
 * unique or primary key constraints on table "user_work_logs"
 */
export enum user_work_logs_constraint {
  PK_63ebe792e2faf96740d825edf51 = 'PK_63ebe792e2faf96740d825edf51',
}

/**
 * update columns of table "user_work_logs"
 */
export enum user_work_logs_update_column {
  endAt = 'endAt',
}

/**
 * unique or primary key constraints on table "users"
 */
export enum users_constraint {
  PK_a3ffb1c0c8416b9fc6f907b7433 = 'PK_a3ffb1c0c8416b9fc6f907b7433',
  UQ_2d443082eccd5198f95f2a36e2c = 'UQ_2d443082eccd5198f95f2a36e2c',
  UQ_3afb62555b874925fb7352eca43 = 'UQ_3afb62555b874925fb7352eca43',
  UQ_53a495e5b24279804db49702cc1 = 'UQ_53a495e5b24279804db49702cc1',
  UQ_9e487924df94a4241b487b2b52e = 'UQ_9e487924df94a4241b487b2b52e',
  UQ_aacbcbfc16077f6b485951adfb4 = 'UQ_aacbcbfc16077f6b485951adfb4',
  UQ_f53d1c88763af9aa36c01b2a801 = 'UQ_f53d1c88763af9aa36c01b2a801',
}

/**
 * update columns of table "users"
 */
export enum users_update_column {
  avatarUrl = 'avatarUrl',
  firstName = 'firstName',
  lastName = 'lastName',
  login = 'login',
  nickname = 'nickname',
  telephone = 'telephone',
  timezone = 'timezone',
}

export enum work_log_approval_status_types_enum {
  approved = 'approved',
  auto = 'auto',
  rejected = 'rejected',
  under_review = 'under_review',
}

export enum work_log_meeting_types_enum {
  all_hands = 'all_hands',
  client_call = 'client_call',
  daily_standup = 'daily_standup',
  team_retrospective = 'team_retrospective',
  weekly_demos = 'weekly_demos',
}

export enum work_log_status_types_enum {
  confirmed = 'confirmed',
  locked = 'locked',
  needs_confirmation = 'needs_confirmation',
}

export enum work_log_types_enum {
  activity = 'activity',
  client = 'client',
  client_billed = 'client_billed',
  client_project = 'client_project',
  learning = 'learning',
  meeting = 'meeting',
  other = 'other',
  task = 'task',
  ticket = 'ticket',
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'.
 */
export interface _text_comparison_exp {
  _eq?: hasura__text | null;
  _gt?: hasura__text | null;
  _gte?: hasura__text | null;
  _in?: hasura__text[] | null;
  _is_null?: boolean | null;
  _lt?: hasura__text | null;
  _lte?: hasura__text | null;
  _neq?: hasura__text | null;
  _nin?: hasura__text[] | null;
}

export interface addGitSliceInput {
  ignoredPaths: string[];
  projectId: number;
  slicedFolders: string[];
  slicedFromBranchName: string;
  slicedFromRepoId: number;
  slicedToRepoName: string;
}

/**
 * order by aggregate values of table "admin"
 */
export interface admin_aggregate_order_by {
  avg?: admin_avg_order_by | null;
  count?: order_by | null;
  max?: admin_max_order_by | null;
  min?: admin_min_order_by | null;
  stddev?: admin_stddev_order_by | null;
  stddev_pop?: admin_stddev_pop_order_by | null;
  stddev_samp?: admin_stddev_samp_order_by | null;
  sum?: admin_sum_order_by | null;
  var_pop?: admin_var_pop_order_by | null;
  var_samp?: admin_var_samp_order_by | null;
  variance?: admin_variance_order_by | null;
}

/**
 * order by avg() on columns of table "admin"
 */
export interface admin_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "admin". All fields are combined with a logical 'AND'.
 */
export interface admin_bool_exp {
  _and?: admin_bool_exp[] | null;
  _not?: admin_bool_exp | null;
  _or?: admin_bool_exp[] | null;
  id?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userById?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "admin"
 */
export interface admin_max_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by min() on columns of table "admin"
 */
export interface admin_min_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * Ordering options when selecting data from "admin".
 */
export interface admin_order_by {
  id?: order_by | null;
  user?: users_order_by | null;
  userById?: users_order_by | null;
  userId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by stddev() on columns of table "admin"
 */
export interface admin_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "admin"
 */
export interface admin_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "admin"
 */
export interface admin_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "admin"
 */
export interface admin_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "admin"
 */
export interface admin_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "admin"
 */
export interface admin_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "admin"
 */
export interface admin_variance_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "agencies". All fields are combined with a logical 'AND'.
 */
export interface agencies_bool_exp {
  _and?: agencies_bool_exp[] | null;
  _not?: agencies_bool_exp | null;
  _or?: agencies_bool_exp[] | null;
  agency_users?: agency_users_bool_exp | null;
  clients?: clients_bool_exp | null;
  developers?: developers_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  id?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "agencies"
 */
export interface agencies_insert_input {
  agency_users?: agency_users_arr_rel_insert_input | null;
  clients?: clients_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  developers?: developers_arr_rel_insert_input | null;
  id?: string | null;
  legalName?: string | null;
  name?: string | null;
  updatedAt?: hasura_timestamptz | null;
  userDomains?: string | null;
}

/**
 * input type for inserting object relation for remote table "agencies"
 */
export interface agencies_obj_rel_insert_input {
  data: agencies_insert_input;
}

/**
 * Ordering options when selecting data from "agencies".
 */
export interface agencies_order_by {
  agency_users_aggregate?: agency_users_aggregate_order_by | null;
  clients_aggregate?: clients_aggregate_order_by | null;
  developers_aggregate?: developers_aggregate_order_by | null;
  git_users_aggregate?: git_users_aggregate_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "agency_user_roles_enum". All fields are combined with logical 'AND'.
 */
export interface agency_user_roles_enum_comparison_exp {
  _eq?: agency_user_roles_enum | null;
  _in?: agency_user_roles_enum[] | null;
  _is_null?: boolean | null;
  _neq?: agency_user_roles_enum | null;
  _nin?: agency_user_roles_enum[] | null;
}

/**
 * order by aggregate values of table "agency_users"
 */
export interface agency_users_aggregate_order_by {
  avg?: agency_users_avg_order_by | null;
  count?: order_by | null;
  max?: agency_users_max_order_by | null;
  min?: agency_users_min_order_by | null;
  stddev?: agency_users_stddev_order_by | null;
  stddev_pop?: agency_users_stddev_pop_order_by | null;
  stddev_samp?: agency_users_stddev_samp_order_by | null;
  sum?: agency_users_sum_order_by | null;
  var_pop?: agency_users_var_pop_order_by | null;
  var_samp?: agency_users_var_samp_order_by | null;
  variance?: agency_users_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "agency_users"
 */
export interface agency_users_arr_rel_insert_input {
  data: agency_users_insert_input[];
}

/**
 * order by avg() on columns of table "agency_users"
 */
export interface agency_users_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "agency_users". All fields are combined with a logical 'AND'.
 */
export interface agency_users_bool_exp {
  _and?: agency_users_bool_exp[] | null;
  _not?: agency_users_bool_exp | null;
  _or?: agency_users_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  role?: agency_user_roles_enum_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "agency_users"
 */
export interface agency_users_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  id?: string | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "agency_users"
 */
export interface agency_users_max_order_by {
  agencyId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "agency_users"
 */
export interface agency_users_min_order_by {
  agencyId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "agency_users"
 */
export interface agency_users_obj_rel_insert_input {
  data: agency_users_insert_input;
}

/**
 * Ordering options when selecting data from "agency_users".
 */
export interface agency_users_order_by {
  agency?: agencies_order_by | null;
  agencyId?: order_by | null;
  id?: order_by | null;
  role?: order_by | null;
  user?: users_order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "agency_users"
 */
export interface agency_users_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "agency_users"
 */
export interface agency_users_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "agency_users"
 */
export interface agency_users_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "agency_users"
 */
export interface agency_users_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "agency_users"
 */
export interface agency_users_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "agency_users"
 */
export interface agency_users_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "agency_users"
 */
export interface agency_users_variance_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: hasura_bigint | null;
  _gt?: hasura_bigint | null;
  _gte?: hasura_bigint | null;
  _in?: hasura_bigint[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_bigint | null;
  _lte?: hasura_bigint | null;
  _neq?: hasura_bigint | null;
  _nin?: hasura_bigint[] | null;
}

/**
 * Boolean expression to compare columns of type "candidate_stage_enum". All fields are combined with logical 'AND'.
 */
export interface candidate_stage_enum_comparison_exp {
  _eq?: candidate_stage_enum | null;
  _in?: candidate_stage_enum[] | null;
  _is_null?: boolean | null;
  _neq?: candidate_stage_enum | null;
  _nin?: candidate_stage_enum[] | null;
}

/**
 * order by aggregate values of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_aggregate_order_by {
  avg?: client_billing_aggregated_structure_avg_order_by | null;
  count?: order_by | null;
  max?: client_billing_aggregated_structure_max_order_by | null;
  min?: client_billing_aggregated_structure_min_order_by | null;
  stddev?: client_billing_aggregated_structure_stddev_order_by | null;
  stddev_pop?: client_billing_aggregated_structure_stddev_pop_order_by | null;
  stddev_samp?: client_billing_aggregated_structure_stddev_samp_order_by | null;
  sum?: client_billing_aggregated_structure_sum_order_by | null;
  var_pop?: client_billing_aggregated_structure_var_pop_order_by | null;
  var_samp?: client_billing_aggregated_structure_var_samp_order_by | null;
  variance?: client_billing_aggregated_structure_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_avg_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_billing_aggregated_structure". All fields are combined with a logical 'AND'.
 */
export interface client_billing_aggregated_structure_bool_exp {
  _and?: client_billing_aggregated_structure_bool_exp[] | null;
  _not?: client_billing_aggregated_structure_bool_exp | null;
  _or?: client_billing_aggregated_structure_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientInvoiceId?: Int_comparison_exp | null;
  clientTransactionId?: Int_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  occuredAt?: date_comparison_exp | null;
  status?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_max_order_by {
  clientId?: order_by | null;
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  occuredAt?: order_by | null;
  status?: order_by | null;
  type?: order_by | null;
}

/**
 * order by min() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_min_order_by {
  clientId?: order_by | null;
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  occuredAt?: order_by | null;
  status?: order_by | null;
  type?: order_by | null;
}

/**
 * Ordering options when selecting data from "client_billing_aggregated_structure".
 */
export interface client_billing_aggregated_structure_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  client_invoice?: client_invoices_order_by | null;
  client_transaction?: client_transactions_order_by | null;
  costInCredits?: order_by | null;
  occuredAt?: order_by | null;
  status?: order_by | null;
  type?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_stddev_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_stddev_pop_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_stddev_samp_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by sum() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_sum_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_var_pop_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_var_samp_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by variance() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_variance_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_blocker_status". All fields are combined with a logical 'AND'.
 */
export interface client_blocker_status_bool_exp {
  _and?: client_blocker_status_bool_exp[] | null;
  _not?: client_blocker_status_bool_exp | null;
  _or?: client_blocker_status_bool_exp[] | null;
  client_blockers?: client_blockers_bool_exp | null;
  description?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_blocker_status_enum". All fields are combined with logical 'AND'.
 */
export interface client_blocker_status_enum_comparison_exp {
  _eq?: client_blocker_status_enum | null;
  _in?: client_blocker_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_blocker_status_enum | null;
  _nin?: client_blocker_status_enum[] | null;
}

/**
 * order by aggregate values of table "client_blockers"
 */
export interface client_blockers_aggregate_order_by {
  avg?: client_blockers_avg_order_by | null;
  count?: order_by | null;
  max?: client_blockers_max_order_by | null;
  min?: client_blockers_min_order_by | null;
  stddev?: client_blockers_stddev_order_by | null;
  stddev_pop?: client_blockers_stddev_pop_order_by | null;
  stddev_samp?: client_blockers_stddev_samp_order_by | null;
  sum?: client_blockers_sum_order_by | null;
  var_pop?: client_blockers_var_pop_order_by | null;
  var_samp?: client_blockers_var_samp_order_by | null;
  variance?: client_blockers_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "client_blockers"
 */
export interface client_blockers_arr_rel_insert_input {
  data: client_blockers_insert_input[];
  on_conflict?: client_blockers_on_conflict | null;
}

/**
 * order by avg() on columns of table "client_blockers"
 */
export interface client_blockers_avg_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_blockers". All fields are combined with a logical 'AND'.
 */
export interface client_blockers_bool_exp {
  _and?: client_blockers_bool_exp[] | null;
  _not?: client_blockers_bool_exp | null;
  _or?: client_blockers_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_blocker_status?: client_blocker_status_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  details?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  projectId?: Int_comparison_exp | null;
  resolvedById?: Int_comparison_exp | null;
  status?: client_blocker_status_enum_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task_client_blockers?: task_client_blockers_bool_exp | null;
  ticket_client_blockers?: ticket_client_blockers_bool_exp | null;
  unblockDetails?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_blockers"
 */
export interface client_blockers_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  details?: string | null;
  id?: number | null;
  projectId?: number | null;
  status?: client_blocker_status_enum | null;
  summary?: string | null;
  task_client_blockers?: task_client_blockers_arr_rel_insert_input | null;
  ticket_client_blockers?: ticket_client_blockers_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "client_blockers"
 */
export interface client_blockers_max_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  details?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
  summary?: order_by | null;
  unblockDetails?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_blockers"
 */
export interface client_blockers_min_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  details?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
  summary?: order_by | null;
  unblockDetails?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "client_blockers"
 */
export interface client_blockers_obj_rel_insert_input {
  data: client_blockers_insert_input;
  on_conflict?: client_blockers_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_blockers"
 */
export interface client_blockers_on_conflict {
  constraint: client_blockers_constraint;
  update_columns: client_blockers_update_column[];
  where?: client_blockers_bool_exp | null;
}

/**
 * order by stddev() on columns of table "client_blockers"
 */
export interface client_blockers_stddev_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_blockers"
 */
export interface client_blockers_stddev_pop_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_blockers"
 */
export interface client_blockers_stddev_samp_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by sum() on columns of table "client_blockers"
 */
export interface client_blockers_sum_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_blockers"
 */
export interface client_blockers_var_pop_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_blockers"
 */
export interface client_blockers_var_samp_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by variance() on columns of table "client_blockers"
 */
export interface client_blockers_variance_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by aggregate values of table "client_feature_flags"
 */
export interface client_feature_flags_aggregate_order_by {
  avg?: client_feature_flags_avg_order_by | null;
  count?: order_by | null;
  max?: client_feature_flags_max_order_by | null;
  min?: client_feature_flags_min_order_by | null;
  stddev?: client_feature_flags_stddev_order_by | null;
  stddev_pop?: client_feature_flags_stddev_pop_order_by | null;
  stddev_samp?: client_feature_flags_stddev_samp_order_by | null;
  sum?: client_feature_flags_sum_order_by | null;
  var_pop?: client_feature_flags_var_pop_order_by | null;
  var_samp?: client_feature_flags_var_samp_order_by | null;
  variance?: client_feature_flags_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_avg_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_feature_flags". All fields are combined with a logical 'AND'.
 */
export interface client_feature_flags_bool_exp {
  _and?: client_feature_flags_bool_exp[] | null;
  _not?: client_feature_flags_bool_exp | null;
  _or?: client_feature_flags_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  featureFlagId?: Int_comparison_exp | null;
  feature_flag?: feature_flags_bool_exp | null;
  id?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_max_order_by {
  clientId?: order_by | null;
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by min() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_min_order_by {
  clientId?: order_by | null;
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_stddev_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_stddev_pop_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_stddev_samp_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_sum_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_var_pop_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_var_samp_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_feature_flags"
 */
export interface client_feature_flags_variance_order_by {
  featureFlagId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_invoices"
 */
export interface client_invoices_aggregate_order_by {
  avg?: client_invoices_avg_order_by | null;
  count?: order_by | null;
  max?: client_invoices_max_order_by | null;
  min?: client_invoices_min_order_by | null;
  stddev?: client_invoices_stddev_order_by | null;
  stddev_pop?: client_invoices_stddev_pop_order_by | null;
  stddev_samp?: client_invoices_stddev_samp_order_by | null;
  sum?: client_invoices_sum_order_by | null;
  var_pop?: client_invoices_var_pop_order_by | null;
  var_samp?: client_invoices_var_samp_order_by | null;
  variance?: client_invoices_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "client_invoices"
 */
export interface client_invoices_arr_rel_insert_input {
  data: client_invoices_insert_input[];
}

/**
 * order by avg() on columns of table "client_invoices"
 */
export interface client_invoices_avg_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_invoices". All fields are combined with a logical 'AND'.
 */
export interface client_invoices_bool_exp {
  _and?: client_invoices_bool_exp[] | null;
  _not?: client_invoices_bool_exp | null;
  _or?: client_invoices_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  isTopUp?: Boolean_comparison_exp | null;
  maxBudgetInCredits?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_invoices"
 */
export interface client_invoices_insert_input {
  associatedTransactionId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_transaction?: client_transactions_obj_rel_insert_input | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  creditPricingPlanId?: number | null;
  dueAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  maxBudgetInCredits?: number | null;
  startAt?: hasura_timestamptz | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * order by max() on columns of table "client_invoices"
 */
export interface client_invoices_max_order_by {
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  deletedAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_invoices"
 */
export interface client_invoices_min_order_by {
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  deletedAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "client_invoices"
 */
export interface client_invoices_obj_rel_insert_input {
  data: client_invoices_insert_input;
}

/**
 * Ordering options when selecting data from "client_invoices".
 */
export interface client_invoices_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_order_by | null;
  client_transaction?: client_transactions_order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  credit_pricing_plan?: credit_pricing_plans_order_by | null;
  deletedAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  isTopUp?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  startAt?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_invoices"
 */
export interface client_invoices_stddev_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_invoices"
 */
export interface client_invoices_stddev_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_invoices"
 */
export interface client_invoices_stddev_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by sum() on columns of table "client_invoices"
 */
export interface client_invoices_sum_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_invoices"
 */
export interface client_invoices_var_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_invoices"
 */
export interface client_invoices_var_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by variance() on columns of table "client_invoices"
 */
export interface client_invoices_variance_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by aggregate values of table "client_jobs"
 */
export interface client_jobs_aggregate_order_by {
  avg?: client_jobs_avg_order_by | null;
  count?: order_by | null;
  max?: client_jobs_max_order_by | null;
  min?: client_jobs_min_order_by | null;
  stddev?: client_jobs_stddev_order_by | null;
  stddev_pop?: client_jobs_stddev_pop_order_by | null;
  stddev_samp?: client_jobs_stddev_samp_order_by | null;
  sum?: client_jobs_sum_order_by | null;
  var_pop?: client_jobs_var_pop_order_by | null;
  var_samp?: client_jobs_var_samp_order_by | null;
  variance?: client_jobs_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_jobs"
 */
export interface client_jobs_avg_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_jobs". All fields are combined with a logical 'AND'.
 */
export interface client_jobs_bool_exp {
  _and?: client_jobs_bool_exp[] | null;
  _not?: client_jobs_bool_exp | null;
  _or?: client_jobs_bool_exp[] | null;
  canSponsorVisa?: Boolean_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  earliestStartDate?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  isRemote?: Boolean_comparison_exp | null;
  linkToJobDescription?: String_comparison_exp | null;
  maxAnnualSalary?: Int_comparison_exp | null;
  minQualifiedRank?: Int_comparison_exp | null;
  qualifiedCities?: String_comparison_exp | null;
  qualifiedCountries?: String_comparison_exp | null;
  qualifiedTimezoneOverlap?: Int_comparison_exp | null;
  totalPlacements?: Int_comparison_exp | null;
  workTimeZone?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_jobs"
 */
export interface client_jobs_max_order_by {
  clientId?: order_by | null;
  earliestStartDate?: order_by | null;
  id?: order_by | null;
  linkToJobDescription?: order_by | null;
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedCities?: order_by | null;
  qualifiedCountries?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by min() on columns of table "client_jobs"
 */
export interface client_jobs_min_order_by {
  clientId?: order_by | null;
  earliestStartDate?: order_by | null;
  id?: order_by | null;
  linkToJobDescription?: order_by | null;
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedCities?: order_by | null;
  qualifiedCountries?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_jobs"
 */
export interface client_jobs_stddev_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_jobs"
 */
export interface client_jobs_stddev_pop_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_jobs"
 */
export interface client_jobs_stddev_samp_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by sum() on columns of table "client_jobs"
 */
export interface client_jobs_sum_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_jobs"
 */
export interface client_jobs_var_pop_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_jobs"
 */
export interface client_jobs_var_samp_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by variance() on columns of table "client_jobs"
 */
export interface client_jobs_variance_order_by {
  maxAnnualSalary?: order_by | null;
  minQualifiedRank?: order_by | null;
  qualifiedTimezoneOverlap?: order_by | null;
  totalPlacements?: order_by | null;
  workTimeZone?: order_by | null;
}

/**
 * order by aggregate values of table "client_managers_view"
 */
export interface client_managers_view_aggregate_order_by {
  avg?: client_managers_view_avg_order_by | null;
  count?: order_by | null;
  max?: client_managers_view_max_order_by | null;
  min?: client_managers_view_min_order_by | null;
  stddev?: client_managers_view_stddev_order_by | null;
  stddev_pop?: client_managers_view_stddev_pop_order_by | null;
  stddev_samp?: client_managers_view_stddev_samp_order_by | null;
  sum?: client_managers_view_sum_order_by | null;
  var_pop?: client_managers_view_var_pop_order_by | null;
  var_samp?: client_managers_view_var_samp_order_by | null;
  variance?: client_managers_view_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_managers_view"
 */
export interface client_managers_view_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_managers_view". All fields are combined with a logical 'AND'.
 */
export interface client_managers_view_bool_exp {
  _and?: client_managers_view_bool_exp[] | null;
  _not?: client_managers_view_bool_exp | null;
  _or?: client_managers_view_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_managers_view"
 */
export interface client_managers_view_max_order_by {
  clientId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "client_managers_view"
 */
export interface client_managers_view_min_order_by {
  clientId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_managers_view"
 */
export interface client_managers_view_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_managers_view"
 */
export interface client_managers_view_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_managers_view"
 */
export interface client_managers_view_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_managers_view"
 */
export interface client_managers_view_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_managers_view"
 */
export interface client_managers_view_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_managers_view"
 */
export interface client_managers_view_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_managers_view"
 */
export interface client_managers_view_variance_order_by {
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "client_pricing_plans"
 */
export interface client_pricing_plans_aggregate_order_by {
  avg?: client_pricing_plans_avg_order_by | null;
  count?: order_by | null;
  max?: client_pricing_plans_max_order_by | null;
  min?: client_pricing_plans_min_order_by | null;
  stddev?: client_pricing_plans_stddev_order_by | null;
  stddev_pop?: client_pricing_plans_stddev_pop_order_by | null;
  stddev_samp?: client_pricing_plans_stddev_samp_order_by | null;
  sum?: client_pricing_plans_sum_order_by | null;
  var_pop?: client_pricing_plans_var_pop_order_by | null;
  var_samp?: client_pricing_plans_var_samp_order_by | null;
  variance?: client_pricing_plans_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_avg_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface client_pricing_plans_bool_exp {
  _and?: client_pricing_plans_bool_exp[] | null;
  _not?: client_pricing_plans_bool_exp | null;
  _or?: client_pricing_plans_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_max_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_min_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_stddev_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_stddev_pop_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_stddev_samp_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_sum_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_var_pop_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_var_samp_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_variance_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_aggregate_order_by {
  avg?: client_project_pricing_plans_avg_order_by | null;
  count?: order_by | null;
  max?: client_project_pricing_plans_max_order_by | null;
  min?: client_project_pricing_plans_min_order_by | null;
  stddev?: client_project_pricing_plans_stddev_order_by | null;
  stddev_pop?: client_project_pricing_plans_stddev_pop_order_by | null;
  stddev_samp?: client_project_pricing_plans_stddev_samp_order_by | null;
  sum?: client_project_pricing_plans_sum_order_by | null;
  var_pop?: client_project_pricing_plans_var_pop_order_by | null;
  var_samp?: client_project_pricing_plans_var_samp_order_by | null;
  variance?: client_project_pricing_plans_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_avg_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface client_project_pricing_plans_bool_exp {
  _and?: client_project_pricing_plans_bool_exp[] | null;
  _not?: client_project_pricing_plans_bool_exp | null;
  _or?: client_project_pricing_plans_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_max_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_min_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_stddev_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_sum_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_var_pop_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_var_samp_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_variance_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_project_repositories"
 */
export interface client_project_repositories_aggregate_order_by {
  avg?: client_project_repositories_avg_order_by | null;
  count?: order_by | null;
  max?: client_project_repositories_max_order_by | null;
  min?: client_project_repositories_min_order_by | null;
  stddev?: client_project_repositories_stddev_order_by | null;
  stddev_pop?: client_project_repositories_stddev_pop_order_by | null;
  stddev_samp?: client_project_repositories_stddev_samp_order_by | null;
  sum?: client_project_repositories_sum_order_by | null;
  var_pop?: client_project_repositories_var_pop_order_by | null;
  var_samp?: client_project_repositories_var_samp_order_by | null;
  variance?: client_project_repositories_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_avg_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_repositories". All fields are combined with a logical 'AND'.
 */
export interface client_project_repositories_bool_exp {
  _and?: client_project_repositories_bool_exp[] | null;
  _not?: client_project_repositories_bool_exp | null;
  _or?: client_project_repositories_bool_exp[] | null;
  clientProjectsId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  gitReposId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
}

/**
 * order by max() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_max_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by min() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_min_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_stddev_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_stddev_pop_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_stddev_samp_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_sum_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_var_pop_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_var_samp_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_project_repositories"
 */
export interface client_project_repositories_variance_order_by {
  clientProjectsId?: order_by | null;
  gitReposId?: order_by | null;
}

/**
 * order by aggregate values of table "client_project_technologies"
 */
export interface client_project_technologies_aggregate_order_by {
  avg?: client_project_technologies_avg_order_by | null;
  count?: order_by | null;
  max?: client_project_technologies_max_order_by | null;
  min?: client_project_technologies_min_order_by | null;
  stddev?: client_project_technologies_stddev_order_by | null;
  stddev_pop?: client_project_technologies_stddev_pop_order_by | null;
  stddev_samp?: client_project_technologies_stddev_samp_order_by | null;
  sum?: client_project_technologies_sum_order_by | null;
  var_pop?: client_project_technologies_var_pop_order_by | null;
  var_samp?: client_project_technologies_var_samp_order_by | null;
  variance?: client_project_technologies_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_avg_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_technologies". All fields are combined with a logical 'AND'.
 */
export interface client_project_technologies_bool_exp {
  _and?: client_project_technologies_bool_exp[] | null;
  _not?: client_project_technologies_bool_exp | null;
  _or?: client_project_technologies_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  projectId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
  technologyId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_max_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by min() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_min_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_stddev_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_stddev_pop_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_stddev_samp_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_sum_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_var_pop_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_var_samp_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_project_technologies"
 */
export interface client_project_technologies_variance_order_by {
  projectId?: order_by | null;
  technologyId?: order_by | null;
}

/**
 * order by aggregate values of table "client_project_users"
 */
export interface client_project_users_aggregate_order_by {
  avg?: client_project_users_avg_order_by | null;
  count?: order_by | null;
  max?: client_project_users_max_order_by | null;
  min?: client_project_users_min_order_by | null;
  stddev?: client_project_users_stddev_order_by | null;
  stddev_pop?: client_project_users_stddev_pop_order_by | null;
  stddev_samp?: client_project_users_stddev_samp_order_by | null;
  sum?: client_project_users_sum_order_by | null;
  var_pop?: client_project_users_var_pop_order_by | null;
  var_samp?: client_project_users_var_samp_order_by | null;
  variance?: client_project_users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_project_users"
 */
export interface client_project_users_avg_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_users". All fields are combined with a logical 'AND'.
 */
export interface client_project_users_bool_exp {
  _and?: client_project_users_bool_exp[] | null;
  _not?: client_project_users_bool_exp | null;
  _or?: client_project_users_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  projects?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  users?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_project_users"
 */
export interface client_project_users_max_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by min() on columns of table "client_project_users"
 */
export interface client_project_users_min_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_project_users"
 */
export interface client_project_users_stddev_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_project_users"
 */
export interface client_project_users_stddev_pop_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_project_users"
 */
export interface client_project_users_stddev_samp_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by sum() on columns of table "client_project_users"
 */
export interface client_project_users_sum_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_project_users"
 */
export interface client_project_users_var_pop_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_project_users"
 */
export interface client_project_users_var_samp_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by variance() on columns of table "client_project_users"
 */
export interface client_project_users_variance_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by aggregate values of table "client_projects"
 */
export interface client_projects_aggregate_order_by {
  avg?: client_projects_avg_order_by | null;
  count?: order_by | null;
  max?: client_projects_max_order_by | null;
  min?: client_projects_min_order_by | null;
  stddev?: client_projects_stddev_order_by | null;
  stddev_pop?: client_projects_stddev_pop_order_by | null;
  stddev_samp?: client_projects_stddev_samp_order_by | null;
  sum?: client_projects_sum_order_by | null;
  var_pop?: client_projects_var_pop_order_by | null;
  var_samp?: client_projects_var_samp_order_by | null;
  variance?: client_projects_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_projects"
 */
export interface client_projects_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_projects". All fields are combined with a logical 'AND'.
 */
export interface client_projects_bool_exp {
  _and?: client_projects_bool_exp[] | null;
  _not?: client_projects_bool_exp | null;
  _or?: client_projects_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_project_pricing_plans?: client_project_pricing_plans_bool_exp | null;
  client_project_repositories?: client_project_repositories_bool_exp | null;
  client_project_technologies?: client_project_technologies_bool_exp | null;
  client_project_users?: client_project_users_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  git_repo_slices?: git_repo_slices_bool_exp | null;
  id?: Int_comparison_exp | null;
  jira_projects?: jira_projects_bool_exp | null;
  name?: String_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  project_managers?: project_managers_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  user_events?: user_events_bool_exp | null;
  user_team?: user_teams_bool_exp | null;
  user_team_client?: user_team_clients_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * order by max() on columns of table "client_projects"
 */
export interface client_projects_max_order_by {
  clientId?: order_by | null;
  deletedAt?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * order by min() on columns of table "client_projects"
 */
export interface client_projects_min_order_by {
  clientId?: order_by | null;
  deletedAt?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * Ordering options when selecting data from "client_projects".
 */
export interface client_projects_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_blockers_aggregate?: client_blockers_aggregate_order_by | null;
  client_project_pricing_plans_aggregate?: client_project_pricing_plans_aggregate_order_by | null;
  client_project_repositories_aggregate?: client_project_repositories_aggregate_order_by | null;
  client_project_technologies_aggregate?: client_project_technologies_aggregate_order_by | null;
  client_project_users_aggregate?: client_project_users_aggregate_order_by | null;
  deletedAt?: order_by | null;
  description?: order_by | null;
  discussion_source?: discussion_sources_order_by | null;
  git_repo_slices_aggregate?: git_repo_slices_aggregate_order_by | null;
  id?: order_by | null;
  jira_projects_aggregate?: jira_projects_aggregate_order_by | null;
  name?: order_by | null;
  notifications_aggregate?: notifications_aggregate_order_by | null;
  project_daily_standups_aggregate?: project_daily_standup_aggregate_order_by | null;
  project_managers_aggregate?: project_managers_aggregate_order_by | null;
  ticket_sources_aggregate?: ticket_sources_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  user_events_aggregate?: user_events_aggregate_order_by | null;
  user_team?: user_teams_order_by | null;
  user_team_client?: user_team_clients_order_by | null;
  user_team_clients_aggregate?: user_team_clients_aggregate_order_by | null;
  user_work_logs_aggregate?: user_work_logs_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "client_projects"
 */
export interface client_projects_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_projects"
 */
export interface client_projects_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_projects"
 */
export interface client_projects_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_projects"
 */
export interface client_projects_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_projects"
 */
export interface client_projects_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_projects"
 */
export interface client_projects_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_projects"
 */
export interface client_projects_variance_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface client_standups_sla_view_bool_exp {
  _and?: client_standups_sla_view_bool_exp[] | null;
  _not?: client_standups_sla_view_bool_exp | null;
  _or?: client_standups_sla_view_bool_exp[] | null;
  assigneeId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "client_standups_sla_view".
 */
export interface client_standups_sla_view_order_by {
  assigneeId?: order_by | null;
  client?: clients_order_by | null;
  clientId?: order_by | null;
  dueAt?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_transaction_status". All fields are combined with a logical 'AND'.
 */
export interface client_transaction_status_bool_exp {
  _and?: client_transaction_status_bool_exp[] | null;
  _not?: client_transaction_status_bool_exp | null;
  _or?: client_transaction_status_bool_exp[] | null;
  client_transactions?: client_transactions_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_transaction_status_enum". All fields are combined with logical 'AND'.
 */
export interface client_transaction_status_enum_comparison_exp {
  _eq?: client_transaction_status_enum | null;
  _in?: client_transaction_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_transaction_status_enum | null;
  _nin?: client_transaction_status_enum[] | null;
}

/**
 * Ordering options when selecting data from "client_transaction_status".
 */
export interface client_transaction_status_order_by {
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  description?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_transaction_types". All fields are combined with a logical 'AND'.
 */
export interface client_transaction_types_bool_exp {
  _and?: client_transaction_types_bool_exp[] | null;
  _not?: client_transaction_types_bool_exp | null;
  _or?: client_transaction_types_bool_exp[] | null;
  client_transactions?: client_transactions_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_transaction_types_enum". All fields are combined with logical 'AND'.
 */
export interface client_transaction_types_enum_comparison_exp {
  _eq?: client_transaction_types_enum | null;
  _in?: client_transaction_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_transaction_types_enum | null;
  _nin?: client_transaction_types_enum[] | null;
}

/**
 * Ordering options when selecting data from "client_transaction_types".
 */
export interface client_transaction_types_order_by {
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  description?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_transactions"
 */
export interface client_transactions_aggregate_order_by {
  avg?: client_transactions_avg_order_by | null;
  count?: order_by | null;
  max?: client_transactions_max_order_by | null;
  min?: client_transactions_min_order_by | null;
  stddev?: client_transactions_stddev_order_by | null;
  stddev_pop?: client_transactions_stddev_pop_order_by | null;
  stddev_samp?: client_transactions_stddev_samp_order_by | null;
  sum?: client_transactions_sum_order_by | null;
  var_pop?: client_transactions_var_pop_order_by | null;
  var_samp?: client_transactions_var_samp_order_by | null;
  variance?: client_transactions_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "client_transactions"
 */
export interface client_transactions_arr_rel_insert_input {
  data: client_transactions_insert_input[];
  on_conflict?: client_transactions_on_conflict | null;
}

/**
 * order by avg() on columns of table "client_transactions"
 */
export interface client_transactions_avg_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_transactions". All fields are combined with a logical 'AND'.
 */
export interface client_transactions_bool_exp {
  _and?: client_transactions_bool_exp[] | null;
  _not?: client_transactions_bool_exp | null;
  _or?: client_transactions_bool_exp[] | null;
  channelTransactionId?: String_comparison_exp | null;
  channelType?: client_transaction_types_enum_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_transaction_status?: client_transaction_status_bool_exp | null;
  client_transaction_type?: client_transaction_types_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  status?: client_transaction_status_enum_comparison_exp | null;
  transactedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_transactions"
 */
export interface client_transactions_insert_input {
  channelTransactionId?: string | null;
  channelType?: client_transaction_types_enum | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_invoices?: client_invoices_arr_rel_insert_input | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  id?: number | null;
  invoiceCode?: string | null;
  status?: client_transaction_status_enum | null;
  transactedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "client_transactions"
 */
export interface client_transactions_max_order_by {
  channelTransactionId?: order_by | null;
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  transactedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_transactions"
 */
export interface client_transactions_min_order_by {
  channelTransactionId?: order_by | null;
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  transactedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "client_transactions"
 */
export interface client_transactions_obj_rel_insert_input {
  data: client_transactions_insert_input;
  on_conflict?: client_transactions_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_transactions"
 */
export interface client_transactions_on_conflict {
  constraint: client_transactions_constraint;
  update_columns: client_transactions_update_column[];
  where?: client_transactions_bool_exp | null;
}

/**
 * Ordering options when selecting data from "client_transactions".
 */
export interface client_transactions_order_by {
  channelTransactionId?: order_by | null;
  channelType?: order_by | null;
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_order_by | null;
  client_invoices_aggregate?: client_invoices_aggregate_order_by | null;
  client_transaction_status?: client_transaction_status_order_by | null;
  client_transaction_type?: client_transaction_types_order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  credit_pricing_plan?: credit_pricing_plans_order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  status?: order_by | null;
  transactedAt?: order_by | null;
  user?: users_order_by | null;
}

/**
 * order by stddev() on columns of table "client_transactions"
 */
export interface client_transactions_stddev_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_transactions"
 */
export interface client_transactions_stddev_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_transactions"
 */
export interface client_transactions_stddev_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_transactions"
 */
export interface client_transactions_sum_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_transactions"
 */
export interface client_transactions_var_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_transactions"
 */
export interface client_transactions_var_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_transactions"
 */
export interface client_transactions_variance_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_user_positions". All fields are combined with a logical 'AND'.
 */
export interface client_user_positions_bool_exp {
  _and?: client_user_positions_bool_exp[] | null;
  _not?: client_user_positions_bool_exp | null;
  _or?: client_user_positions_bool_exp[] | null;
  client_users?: client_users_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_user_roles". All fields are combined with a logical 'AND'.
 */
export interface client_user_roles_bool_exp {
  _and?: client_user_roles_bool_exp[] | null;
  _not?: client_user_roles_bool_exp | null;
  _or?: client_user_roles_bool_exp[] | null;
  client_users?: client_users_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_user_roles_enum". All fields are combined with logical 'AND'.
 */
export interface client_user_roles_enum_comparison_exp {
  _eq?: client_user_roles_enum | null;
  _in?: client_user_roles_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_user_roles_enum | null;
  _nin?: client_user_roles_enum[] | null;
}

/**
 * order by aggregate values of table "client_users"
 */
export interface client_users_aggregate_order_by {
  avg?: client_users_avg_order_by | null;
  count?: order_by | null;
  max?: client_users_max_order_by | null;
  min?: client_users_min_order_by | null;
  stddev?: client_users_stddev_order_by | null;
  stddev_pop?: client_users_stddev_pop_order_by | null;
  stddev_samp?: client_users_stddev_samp_order_by | null;
  sum?: client_users_sum_order_by | null;
  var_pop?: client_users_var_pop_order_by | null;
  var_samp?: client_users_var_samp_order_by | null;
  variance?: client_users_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "client_users"
 */
export interface client_users_arr_rel_insert_input {
  data: client_users_insert_input[];
  on_conflict?: client_users_on_conflict | null;
}

/**
 * order by avg() on columns of table "client_users"
 */
export interface client_users_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_users". All fields are combined with a logical 'AND'.
 */
export interface client_users_bool_exp {
  _and?: client_users_bool_exp[] | null;
  _not?: client_users_bool_exp | null;
  _or?: client_users_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_user_position?: client_user_positions_bool_exp | null;
  client_user_role?: client_user_roles_bool_exp | null;
  id?: uuid_comparison_exp | null;
  position?: String_comparison_exp | null;
  role?: client_user_roles_enum_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_users"
 */
export interface client_users_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  id?: hasura_uuid | null;
  position?: string | null;
  role?: client_user_roles_enum | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "client_users"
 */
export interface client_users_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  position?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "client_users"
 */
export interface client_users_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  position?: order_by | null;
  userId?: order_by | null;
}

/**
 * on_conflict condition type for table "client_users"
 */
export interface client_users_on_conflict {
  constraint: client_users_constraint;
  update_columns: client_users_update_column[];
  where?: client_users_bool_exp | null;
}

/**
 * order by stddev() on columns of table "client_users"
 */
export interface client_users_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_users"
 */
export interface client_users_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_users"
 */
export interface client_users_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_users"
 */
export interface client_users_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_users"
 */
export interface client_users_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_users"
 */
export interface client_users_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_users"
 */
export interface client_users_variance_order_by {
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "clients"
 */
export interface clients_aggregate_order_by {
  avg?: clients_avg_order_by | null;
  count?: order_by | null;
  max?: clients_max_order_by | null;
  min?: clients_min_order_by | null;
  stddev?: clients_stddev_order_by | null;
  stddev_pop?: clients_stddev_pop_order_by | null;
  stddev_samp?: clients_stddev_samp_order_by | null;
  sum?: clients_sum_order_by | null;
  var_pop?: clients_var_pop_order_by | null;
  var_samp?: clients_var_samp_order_by | null;
  variance?: clients_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "clients"
 */
export interface clients_arr_rel_insert_input {
  data: clients_insert_input[];
  on_conflict?: clients_on_conflict | null;
}

/**
 * order by avg() on columns of table "clients"
 */
export interface clients_avg_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'.
 */
export interface clients_bool_exp {
  _and?: clients_bool_exp[] | null;
  _not?: clients_bool_exp | null;
  _or?: clients_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  churnedAt?: timestamptz_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_jobs?: client_jobs_bool_exp | null;
  client_managers_view?: client_managers_view_bool_exp | null;
  client_pricing_plans?: client_pricing_plans_bool_exp | null;
  client_projects?: client_projects_bool_exp | null;
  client_standups_sla?: client_standups_sla_view_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  client_users?: client_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditBatchSize?: Int_comparison_exp | null;
  defaultCreditBudget?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  feature_flags?: client_feature_flags_bool_exp | null;
  git_organizations?: git_organizations_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  id?: String_comparison_exp | null;
  jira_organization?: jira_organizations_bool_exp | null;
  jira_organizations?: jira_organizations_bool_exp | null;
  legalName?: String_comparison_exp | null;
  logoUrl?: String_comparison_exp | null;
  meetings?: meetings_bool_exp | null;
  name?: String_comparison_exp | null;
  onBoardedAt?: timestamptz_comparison_exp | null;
  project_managers?: project_managers_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userDomains?: String_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "clients"
 */
export interface clients_insert_input {
  addressAddress?: hasura_jsonb | null;
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  client_blockers?: client_blockers_arr_rel_insert_input | null;
  client_invoices?: client_invoices_arr_rel_insert_input | null;
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  client_users?: client_users_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creditBatchSize?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: string | null;
  legalName?: string | null;
  logoUrl?: string | null;
  meetings?: meetings_arr_rel_insert_input | null;
  name?: string | null;
  tasks?: tasks_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  userDomains?: string | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "clients"
 */
export interface clients_max_order_by {
  agencyId?: order_by | null;
  churnedAt?: order_by | null;
  createdAt?: order_by | null;
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
  id?: order_by | null;
  legalName?: order_by | null;
  logoUrl?: order_by | null;
  name?: order_by | null;
  onBoardedAt?: order_by | null;
  updatedAt?: order_by | null;
  userDomains?: order_by | null;
}

/**
 * order by min() on columns of table "clients"
 */
export interface clients_min_order_by {
  agencyId?: order_by | null;
  churnedAt?: order_by | null;
  createdAt?: order_by | null;
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
  id?: order_by | null;
  legalName?: order_by | null;
  logoUrl?: order_by | null;
  name?: order_by | null;
  onBoardedAt?: order_by | null;
  updatedAt?: order_by | null;
  userDomains?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "clients"
 */
export interface clients_obj_rel_insert_input {
  data: clients_insert_input;
  on_conflict?: clients_on_conflict | null;
}

/**
 * on_conflict condition type for table "clients"
 */
export interface clients_on_conflict {
  constraint: clients_constraint;
  update_columns: clients_update_column[];
  where?: clients_bool_exp | null;
}

/**
 * Ordering options when selecting data from "clients".
 */
export interface clients_order_by {
  agency?: agencies_order_by | null;
  agencyId?: order_by | null;
  churnedAt?: order_by | null;
  client_billing_aggregated_structure_aggregate?: client_billing_aggregated_structure_aggregate_order_by | null;
  client_blockers_aggregate?: client_blockers_aggregate_order_by | null;
  client_invoices_aggregate?: client_invoices_aggregate_order_by | null;
  client_jobs_aggregate?: client_jobs_aggregate_order_by | null;
  client_managers_view_aggregate?: client_managers_view_aggregate_order_by | null;
  client_pricing_plans_aggregate?: client_pricing_plans_aggregate_order_by | null;
  client_projects_aggregate?: client_projects_aggregate_order_by | null;
  client_standups_sla?: client_standups_sla_view_order_by | null;
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  client_users_aggregate?: client_users_aggregate_order_by | null;
  createdAt?: order_by | null;
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
  discussion_source?: discussion_sources_order_by | null;
  feature_flags_aggregate?: client_feature_flags_aggregate_order_by | null;
  git_organizations_aggregate?: git_organizations_aggregate_order_by | null;
  git_repos_aggregate?: git_repos_aggregate_order_by | null;
  id?: order_by | null;
  jira_organization?: jira_organizations_order_by | null;
  jira_organizations_aggregate?: jira_organizations_aggregate_order_by | null;
  legalName?: order_by | null;
  logoUrl?: order_by | null;
  meetings_aggregate?: meetings_aggregate_order_by | null;
  name?: order_by | null;
  onBoardedAt?: order_by | null;
  project_managers_aggregate?: project_managers_aggregate_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticket_sources_aggregate?: ticket_sources_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
  userDomains?: order_by | null;
  user_events_aggregate?: user_events_aggregate_order_by | null;
  user_team_clients_aggregate?: user_team_clients_aggregate_order_by | null;
  user_time_logs_aggregate?: user_time_logs_view_aggregate_order_by | null;
  user_work_logs_aggregate?: user_work_logs_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "clients"
 */
export interface clients_stddev_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "clients"
 */
export interface clients_stddev_pop_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "clients"
 */
export interface clients_stddev_samp_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "clients"
 */
export interface clients_sum_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * order by var_pop() on columns of table "clients"
 */
export interface clients_var_pop_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "clients"
 */
export interface clients_var_samp_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "clients"
 */
export interface clients_variance_order_by {
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "credit_pricing_plan_payment_types_enum". All fields are combined with logical 'AND'.
 */
export interface credit_pricing_plan_payment_types_enum_comparison_exp {
  _eq?: credit_pricing_plan_payment_types_enum | null;
  _in?: credit_pricing_plan_payment_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: credit_pricing_plan_payment_types_enum | null;
  _nin?: credit_pricing_plan_payment_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "credit_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface credit_pricing_plans_bool_exp {
  _and?: credit_pricing_plans_bool_exp[] | null;
  _not?: credit_pricing_plans_bool_exp | null;
  _or?: credit_pricing_plans_bool_exp[] | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_pricing_plans?: client_pricing_plans_bool_exp | null;
  client_project_pricing_plans?: client_project_pricing_plans_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  costPerCredit?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditsPerMidLevelHour?: Int_comparison_exp | null;
  defaultSLA?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discontinuedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isCustomPlan?: Boolean_comparison_exp | null;
  maxFreeSupportHoursPerWeek?: Int_comparison_exp | null;
  minCreditUsagePerWeek?: Int_comparison_exp | null;
  minimumCreditPurchase?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  paymentTypeId?: credit_pricing_plan_payment_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "credit_pricing_plans".
 */
export interface credit_pricing_plans_order_by {
  client_invoices_aggregate?: client_invoices_aggregate_order_by | null;
  client_pricing_plans_aggregate?: client_pricing_plans_aggregate_order_by | null;
  client_project_pricing_plans_aggregate?: client_project_pricing_plans_aggregate_order_by | null;
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  costPerCredit?: order_by | null;
  createdAt?: order_by | null;
  creditsPerMidLevelHour?: order_by | null;
  defaultSLA?: order_by | null;
  deletedAt?: order_by | null;
  discontinuedAt?: order_by | null;
  id?: order_by | null;
  isCustomPlan?: order_by | null;
  maxFreeSupportHoursPerWeek?: order_by | null;
  minCreditUsagePerWeek?: order_by | null;
  minimumCreditPurchase?: order_by | null;
  name?: order_by | null;
  paymentTypeId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: hasura_date | null;
  _gt?: hasura_date | null;
  _gte?: hasura_date | null;
  _in?: hasura_date[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_date | null;
  _lte?: hasura_date | null;
  _neq?: hasura_date | null;
  _nin?: hasura_date[] | null;
}

/**
 * Boolean expression to filter rows from the table "description_types". All fields are combined with a logical 'AND'.
 */
export interface description_types_bool_exp {
  _and?: description_types_bool_exp[] | null;
  _not?: description_types_bool_exp | null;
  _or?: description_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "description_types_enum". All fields are combined with logical 'AND'.
 */
export interface description_types_enum_comparison_exp {
  _eq?: description_types_enum | null;
  _in?: description_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: description_types_enum | null;
  _nin?: description_types_enum[] | null;
}

/**
 * Ordering options when selecting data from "description_types".
 */
export interface description_types_order_by {
  description?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  type?: order_by | null;
}

/**
 * order by aggregate values of table "developers"
 */
export interface developers_aggregate_order_by {
  avg?: developers_avg_order_by | null;
  count?: order_by | null;
  max?: developers_max_order_by | null;
  min?: developers_min_order_by | null;
  stddev?: developers_stddev_order_by | null;
  stddev_pop?: developers_stddev_pop_order_by | null;
  stddev_samp?: developers_stddev_samp_order_by | null;
  sum?: developers_sum_order_by | null;
  var_pop?: developers_var_pop_order_by | null;
  var_samp?: developers_var_samp_order_by | null;
  variance?: developers_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "developers"
 */
export interface developers_arr_rel_insert_input {
  data: developers_insert_input[];
  on_conflict?: developers_on_conflict | null;
}

/**
 * order by avg() on columns of table "developers"
 */
export interface developers_avg_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "developers". All fields are combined with a logical 'AND'.
 */
export interface developers_bool_exp {
  _and?: developers_bool_exp[] | null;
  _not?: developers_bool_exp | null;
  _or?: developers_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  bio?: String_comparison_exp | null;
  canRelocate?: Boolean_comparison_exp | null;
  city?: String_comparison_exp | null;
  codeSampleUrl?: String_comparison_exp | null;
  country?: String_comparison_exp | null;
  culture_screen_feedback?: String_comparison_exp | null;
  cv?: String_comparison_exp | null;
  dev_developers?: developers_developer_view_bool_exp | null;
  developer_contracts?: user_contracts_bool_exp | null;
  developer_payments?: user_payments_bool_exp | null;
  earliestStartDate?: timestamptz_comparison_exp | null;
  githubId?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  isActiveStudent?: Boolean_comparison_exp | null;
  isActivelyEmployed?: Boolean_comparison_exp | null;
  linkedInUrl?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  noticePeriod?: Int_comparison_exp | null;
  previousWorkExp?: String_comparison_exp | null;
  source?: String_comparison_exp | null;
  stage?: candidate_stage_enum_comparison_exp | null;
  taskReviewsByDeveloperid?: task_reviews_bool_exp | null;
  task_reviewers?: task_reviewers_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  tasksByDeveloperid?: tasks_bool_exp | null;
  tasksByManagerid?: tasks_bool_exp | null;
  tech_screen_feedback?: String_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  transferwiseId?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  workMachineOS?: String_comparison_exp | null;
  work_machine_os?: work_machine_os_bool_exp | null;
  yearsOfExperience?: Int_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "developers_developer_view". All fields are combined with a logical 'AND'.
 */
export interface developers_developer_view_bool_exp {
  _and?: developers_developer_view_bool_exp[] | null;
  _not?: developers_developer_view_bool_exp | null;
  _or?: developers_developer_view_bool_exp[] | null;
  cohort?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  stage?: String_comparison_exp | null;
  targetCurrency?: String_comparison_exp | null;
  transferwiseId?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "developers_developer_view".
 */
export interface developers_developer_view_order_by {
  cohort?: order_by | null;
  id?: order_by | null;
  stage?: order_by | null;
  targetCurrency?: order_by | null;
  transferwiseId?: order_by | null;
}

/**
 * input type for inserting data into table "developers"
 */
export interface developers_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  canRelocate?: boolean | null;
  city?: string | null;
  cohort?: string | null;
  country?: string | null;
  currentMonthlySalary?: number | null;
  cv?: string | null;
  developer_contracts?: user_contracts_arr_rel_insert_input | null;
  developer_payments?: user_payments_arr_rel_insert_input | null;
  earliestStartDate?: hasura_timestamptz | null;
  githubId?: string | null;
  id?: string | null;
  isActiveStudent?: boolean | null;
  isActivelyEmployed?: boolean | null;
  login?: string | null;
  minAnnualSalary?: number | null;
  noticePeriod?: number | null;
  source?: string | null;
  targetCurrency?: string | null;
  task_reviewers?: task_reviewers_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  tasksByDeveloperid?: tasks_arr_rel_insert_input | null;
  tasksByManagerid?: tasks_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  timezone?: number | null;
  transferwiseId?: string | null;
  user?: users_obj_rel_insert_input | null;
  yearsOfExperience?: number | null;
}

/**
 * order by max() on columns of table "developers"
 */
export interface developers_max_order_by {
  bio?: order_by | null;
  city?: order_by | null;
  codeSampleUrl?: order_by | null;
  country?: order_by | null;
  culture_screen_feedback?: order_by | null;
  cv?: order_by | null;
  earliestStartDate?: order_by | null;
  githubId?: order_by | null;
  id?: order_by | null;
  linkedInUrl?: order_by | null;
  login?: order_by | null;
  noticePeriod?: order_by | null;
  previousWorkExp?: order_by | null;
  source?: order_by | null;
  tech_screen_feedback?: order_by | null;
  transferwiseId?: order_by | null;
  workMachineOS?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by min() on columns of table "developers"
 */
export interface developers_min_order_by {
  bio?: order_by | null;
  city?: order_by | null;
  codeSampleUrl?: order_by | null;
  country?: order_by | null;
  culture_screen_feedback?: order_by | null;
  cv?: order_by | null;
  earliestStartDate?: order_by | null;
  githubId?: order_by | null;
  id?: order_by | null;
  linkedInUrl?: order_by | null;
  login?: order_by | null;
  noticePeriod?: order_by | null;
  previousWorkExp?: order_by | null;
  source?: order_by | null;
  tech_screen_feedback?: order_by | null;
  transferwiseId?: order_by | null;
  workMachineOS?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "developers"
 */
export interface developers_obj_rel_insert_input {
  data: developers_insert_input;
  on_conflict?: developers_on_conflict | null;
}

/**
 * on_conflict condition type for table "developers"
 */
export interface developers_on_conflict {
  constraint: developers_constraint;
  update_columns: developers_update_column[];
  where?: developers_bool_exp | null;
}

/**
 * Ordering options when selecting data from "developers".
 */
export interface developers_order_by {
  agency?: agencies_order_by | null;
  bio?: order_by | null;
  canRelocate?: order_by | null;
  city?: order_by | null;
  codeSampleUrl?: order_by | null;
  country?: order_by | null;
  culture_screen_feedback?: order_by | null;
  cv?: order_by | null;
  dev_developers?: developers_developer_view_order_by | null;
  developer_contracts_aggregate?: user_contracts_aggregate_order_by | null;
  developer_payments_aggregate?: user_payments_aggregate_order_by | null;
  earliestStartDate?: order_by | null;
  githubId?: order_by | null;
  id?: order_by | null;
  isActiveStudent?: order_by | null;
  isActivelyEmployed?: order_by | null;
  linkedInUrl?: order_by | null;
  login?: order_by | null;
  noticePeriod?: order_by | null;
  previousWorkExp?: order_by | null;
  source?: order_by | null;
  stage?: order_by | null;
  taskReviewsByDeveloperid_aggregate?: task_reviews_aggregate_order_by | null;
  task_reviewers_aggregate?: task_reviewers_aggregate_order_by | null;
  task_reviews_aggregate?: task_reviews_aggregate_order_by | null;
  tasksByDeveloperid_aggregate?: tasks_aggregate_order_by | null;
  tasksByManagerid_aggregate?: tasks_aggregate_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  tech_screen_feedback?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  transferwiseId?: order_by | null;
  user?: users_order_by | null;
  workMachineOS?: order_by | null;
  work_machine_os?: work_machine_os_order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by stddev() on columns of table "developers"
 */
export interface developers_stddev_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "developers"
 */
export interface developers_stddev_pop_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "developers"
 */
export interface developers_stddev_samp_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by sum() on columns of table "developers"
 */
export interface developers_sum_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by var_pop() on columns of table "developers"
 */
export interface developers_var_pop_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by var_samp() on columns of table "developers"
 */
export interface developers_var_samp_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * order by variance() on columns of table "developers"
 */
export interface developers_variance_order_by {
  noticePeriod?: order_by | null;
  yearsOfExperience?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comment_body_types". All fields are combined with a logical 'AND'.
 */
export interface discussion_comment_body_types_bool_exp {
  _and?: discussion_comment_body_types_bool_exp[] | null;
  _not?: discussion_comment_body_types_bool_exp | null;
  _or?: discussion_comment_body_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "discussion_comment_body_types_enum". All fields are combined with logical 'AND'.
 */
export interface discussion_comment_body_types_enum_comparison_exp {
  _eq?: discussion_comment_body_types_enum | null;
  _in?: discussion_comment_body_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: discussion_comment_body_types_enum | null;
  _nin?: discussion_comment_body_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comment_read_types". All fields are combined with a logical 'AND'.
 */
export interface discussion_comment_read_types_bool_exp {
  _and?: discussion_comment_read_types_bool_exp[] | null;
  _not?: discussion_comment_read_types_bool_exp | null;
  _or?: discussion_comment_read_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  discussion_comment_reads?: discussion_comment_reads_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "discussion_comment_read_types_enum". All fields are combined with logical 'AND'.
 */
export interface discussion_comment_read_types_enum_comparison_exp {
  _eq?: discussion_comment_read_types_enum | null;
  _in?: discussion_comment_read_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: discussion_comment_read_types_enum | null;
  _nin?: discussion_comment_read_types_enum[] | null;
}

/**
 * order by aggregate values of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_aggregate_order_by {
  avg?: discussion_comment_reads_avg_order_by | null;
  count?: order_by | null;
  max?: discussion_comment_reads_max_order_by | null;
  min?: discussion_comment_reads_min_order_by | null;
  stddev?: discussion_comment_reads_stddev_order_by | null;
  stddev_pop?: discussion_comment_reads_stddev_pop_order_by | null;
  stddev_samp?: discussion_comment_reads_stddev_samp_order_by | null;
  sum?: discussion_comment_reads_sum_order_by | null;
  var_pop?: discussion_comment_reads_var_pop_order_by | null;
  var_samp?: discussion_comment_reads_var_samp_order_by | null;
  variance?: discussion_comment_reads_variance_order_by | null;
}

/**
 * order by avg() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_avg_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comment_reads". All fields are combined with a logical 'AND'.
 */
export interface discussion_comment_reads_bool_exp {
  _and?: discussion_comment_reads_bool_exp[] | null;
  _not?: discussion_comment_reads_bool_exp | null;
  _or?: discussion_comment_reads_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  discussion_comment_read_type?: discussion_comment_read_types_bool_exp | null;
  id?: Int_comparison_exp | null;
  readAt?: timestamptz_comparison_exp | null;
  readByUser?: users_bool_exp | null;
  readByUserId?: Int_comparison_exp | null;
  readTypeId?: discussion_comment_read_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readAt?: order_by | null;
  readByUserId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readAt?: order_by | null;
  readByUserId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_stddev_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_stddev_pop_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_stddev_samp_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by sum() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_sum_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_var_pop_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_var_samp_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by variance() on columns of table "discussion_comment_reads"
 */
export interface discussion_comment_reads_variance_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  readByUserId?: order_by | null;
}

/**
 * order by aggregate values of table "discussion_comments"
 */
export interface discussion_comments_aggregate_order_by {
  avg?: discussion_comments_avg_order_by | null;
  count?: order_by | null;
  max?: discussion_comments_max_order_by | null;
  min?: discussion_comments_min_order_by | null;
  stddev?: discussion_comments_stddev_order_by | null;
  stddev_pop?: discussion_comments_stddev_pop_order_by | null;
  stddev_samp?: discussion_comments_stddev_samp_order_by | null;
  sum?: discussion_comments_sum_order_by | null;
  var_pop?: discussion_comments_var_pop_order_by | null;
  var_samp?: discussion_comments_var_samp_order_by | null;
  variance?: discussion_comments_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "discussion_comments"
 */
export interface discussion_comments_arr_rel_insert_input {
  data: discussion_comments_insert_input[];
}

/**
 * order by avg() on columns of table "discussion_comments"
 */
export interface discussion_comments_avg_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comments". All fields are combined with a logical 'AND'.
 */
export interface discussion_comments_bool_exp {
  _and?: discussion_comments_bool_exp[] | null;
  _not?: discussion_comments_bool_exp | null;
  _or?: discussion_comments_bool_exp[] | null;
  authorAvatarUrl?: String_comparison_exp | null;
  authorFirstName?: String_comparison_exp | null;
  authorLastName?: String_comparison_exp | null;
  authorSid?: String_comparison_exp | null;
  authorTableName?: String_comparison_exp | null;
  authorUser?: users_bool_exp | null;
  authorUserId?: Int_comparison_exp | null;
  author_syncable_entity?: syncable_entities_bool_exp | null;
  child_comments?: discussion_comments_bool_exp | null;
  commentedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  diffHunk?: String_comparison_exp | null;
  discussionName?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_comment_body_type?: discussion_comment_body_types_bool_exp | null;
  discussion_comment_reads?: discussion_comment_reads_bool_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  errorId?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  isInternalForClient?: Boolean_comparison_exp | null;
  isResolved?: Boolean_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  notifiedToRelevantParties?: Boolean_comparison_exp | null;
  originalPosition?: Int_comparison_exp | null;
  outdated?: Boolean_comparison_exp | null;
  parentCommentId?: Int_comparison_exp | null;
  parent_comment?: discussion_comments_bool_exp | null;
  path?: String_comparison_exp | null;
  position?: Int_comparison_exp | null;
  rawBodyText?: String_comparison_exp | null;
  rawBodyTypeId?: discussion_comment_body_types_enum_comparison_exp | null;
  relatedToTicketId?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "discussion_comments"
 */
export interface discussion_comments_insert_input {
  authorSid?: string | null;
  authorTableName?: string | null;
  authorUser?: users_obj_rel_insert_input | null;
  authorUserId?: number | null;
  child_comments?: discussion_comments_arr_rel_insert_input | null;
  commentedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  diffHunk?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  isInternal?: boolean | null;
  isResolved?: boolean | null;
  notifications?: notifications_arr_rel_insert_input | null;
  notifiedToRelevantParties?: boolean | null;
  parentCommentId?: number | null;
  parent_comment?: discussion_comments_obj_rel_insert_input | null;
  rawBodyText?: string | null;
  rawBodyTypeId?: discussion_comment_body_types_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * order by max() on columns of table "discussion_comments"
 */
export interface discussion_comments_max_order_by {
  authorSid?: order_by | null;
  authorTableName?: order_by | null;
  authorUserId?: order_by | null;
  commentedAt?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  diffHunk?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  path?: order_by | null;
  position?: order_by | null;
  rawBodyText?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "discussion_comments"
 */
export interface discussion_comments_min_order_by {
  authorSid?: order_by | null;
  authorTableName?: order_by | null;
  authorUserId?: order_by | null;
  commentedAt?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  diffHunk?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  path?: order_by | null;
  position?: order_by | null;
  rawBodyText?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "discussion_comments"
 */
export interface discussion_comments_obj_rel_insert_input {
  data: discussion_comments_insert_input;
}

/**
 * order by stddev() on columns of table "discussion_comments"
 */
export interface discussion_comments_stddev_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "discussion_comments"
 */
export interface discussion_comments_stddev_pop_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "discussion_comments"
 */
export interface discussion_comments_stddev_samp_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by sum() on columns of table "discussion_comments"
 */
export interface discussion_comments_sum_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by var_pop() on columns of table "discussion_comments"
 */
export interface discussion_comments_var_pop_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by var_samp() on columns of table "discussion_comments"
 */
export interface discussion_comments_var_samp_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by variance() on columns of table "discussion_comments"
 */
export interface discussion_comments_variance_order_by {
  authorUserId?: order_by | null;
  discussionSourceId?: order_by | null;
  errorId?: order_by | null;
  id?: order_by | null;
  originalPosition?: order_by | null;
  parentCommentId?: order_by | null;
  position?: order_by | null;
}

/**
 * order by aggregate values of table "discussion_sources"
 */
export interface discussion_sources_aggregate_order_by {
  avg?: discussion_sources_avg_order_by | null;
  count?: order_by | null;
  max?: discussion_sources_max_order_by | null;
  min?: discussion_sources_min_order_by | null;
  stddev?: discussion_sources_stddev_order_by | null;
  stddev_pop?: discussion_sources_stddev_pop_order_by | null;
  stddev_samp?: discussion_sources_stddev_samp_order_by | null;
  sum?: discussion_sources_sum_order_by | null;
  var_pop?: discussion_sources_var_pop_order_by | null;
  var_samp?: discussion_sources_var_samp_order_by | null;
  variance?: discussion_sources_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "discussion_sources"
 */
export interface discussion_sources_arr_rel_insert_input {
  data: discussion_sources_insert_input[];
  on_conflict?: discussion_sources_on_conflict | null;
}

/**
 * order by avg() on columns of table "discussion_sources"
 */
export interface discussion_sources_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_sources". All fields are combined with a logical 'AND'.
 */
export interface discussion_sources_bool_exp {
  _and?: discussion_sources_bool_exp[] | null;
  _not?: discussion_sources_bool_exp | null;
  _or?: discussion_sources_bool_exp[] | null;
  client?: clients_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  client_projects?: client_projects_bool_exp | null;
  clients?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  id?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  resolvedAt?: timestamptz_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task?: tasks_bool_exp | null;
  taskByTaskCode?: tasks_bool_exp | null;
  taskCode?: String_comparison_exp | null;
  taskTicketCode?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketByTicketcode?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "discussion_sources"
 */
export interface discussion_sources_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clients?: clients_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  id?: number | null;
  isInternal?: boolean | null;
  resolvedAt?: hasura_timestamptz | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  taskByTaskCode?: tasks_obj_rel_insert_input | null;
  taskCode?: string | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketByTicketcode?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * order by max() on columns of table "discussion_sources"
 */
export interface discussion_sources_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  resolvedAt?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  taskCode?: order_by | null;
  taskTicketCode?: order_by | null;
  ticketCode?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "discussion_sources"
 */
export interface discussion_sources_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  resolvedAt?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  taskCode?: order_by | null;
  taskTicketCode?: order_by | null;
  ticketCode?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "discussion_sources"
 */
export interface discussion_sources_obj_rel_insert_input {
  data: discussion_sources_insert_input;
  on_conflict?: discussion_sources_on_conflict | null;
}

/**
 * on_conflict condition type for table "discussion_sources"
 */
export interface discussion_sources_on_conflict {
  constraint: discussion_sources_constraint;
  update_columns: discussion_sources_update_column[];
  where?: discussion_sources_bool_exp | null;
}

/**
 * Ordering options when selecting data from "discussion_sources".
 */
export interface discussion_sources_order_by {
  client?: clients_order_by | null;
  client_project?: client_projects_order_by | null;
  client_projects_aggregate?: client_projects_aggregate_order_by | null;
  clients_aggregate?: clients_aggregate_order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussion_comments_aggregate?: discussion_comments_aggregate_order_by | null;
  git_pull_request?: git_pull_requests_order_by | null;
  git_pull_request_review?: git_pull_request_reviews_order_by | null;
  git_pull_request_reviews_aggregate?: git_pull_request_reviews_aggregate_order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  id?: order_by | null;
  isInternal?: order_by | null;
  resolvedAt?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  task?: tasks_order_by | null;
  taskByTaskCode?: tasks_order_by | null;
  taskCode?: order_by | null;
  taskTicketCode?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticket?: tickets_order_by | null;
  ticketByTicketcode?: tickets_order_by | null;
  ticketCode?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "discussion_sources"
 */
export interface discussion_sources_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "discussion_sources"
 */
export interface discussion_sources_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "discussion_sources"
 */
export interface discussion_sources_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "discussion_sources"
 */
export interface discussion_sources_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "discussion_sources"
 */
export interface discussion_sources_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "discussion_sources"
 */
export interface discussion_sources_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "discussion_sources"
 */
export interface discussion_sources_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_aggregate_order_by {
  avg?: discussions_related_to_tickets_avg_order_by | null;
  count?: order_by | null;
  max?: discussions_related_to_tickets_max_order_by | null;
  min?: discussions_related_to_tickets_min_order_by | null;
  stddev?: discussions_related_to_tickets_stddev_order_by | null;
  stddev_pop?: discussions_related_to_tickets_stddev_pop_order_by | null;
  stddev_samp?: discussions_related_to_tickets_stddev_samp_order_by | null;
  sum?: discussions_related_to_tickets_sum_order_by | null;
  var_pop?: discussions_related_to_tickets_var_pop_order_by | null;
  var_samp?: discussions_related_to_tickets_var_samp_order_by | null;
  variance?: discussions_related_to_tickets_variance_order_by | null;
}

/**
 * order by avg() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_avg_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "discussions_related_to_tickets". All fields are combined with a logical 'AND'.
 */
export interface discussions_related_to_tickets_bool_exp {
  _and?: discussions_related_to_tickets_bool_exp[] | null;
  _not?: discussions_related_to_tickets_bool_exp | null;
  _or?: discussions_related_to_tickets_bool_exp[] | null;
  discussionName?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  source?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_max_order_by {
  discussionName?: order_by | null;
  discussionSourceId?: order_by | null;
  source?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by min() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_min_order_by {
  discussionName?: order_by | null;
  discussionSourceId?: order_by | null;
  source?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_stddev_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_stddev_pop_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_stddev_samp_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_sum_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_var_pop_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_var_samp_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_variance_order_by {
  discussionSourceId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "feature_flags". All fields are combined with a logical 'AND'.
 */
export interface feature_flags_bool_exp {
  _and?: feature_flags_bool_exp[] | null;
  _not?: feature_flags_bool_exp | null;
  _or?: feature_flags_bool_exp[] | null;
  client_feature_flags?: client_feature_flags_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'.
 */
export interface float8_comparison_exp {
  _eq?: hasura_float8 | null;
  _gt?: hasura_float8 | null;
  _gte?: hasura_float8 | null;
  _in?: hasura_float8[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_float8 | null;
  _lte?: hasura_float8 | null;
  _neq?: hasura_float8 | null;
  _nin?: hasura_float8[] | null;
}

/**
 * order by aggregate values of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_aggregate_order_by {
  avg?: gcp_storage_objects_avg_order_by | null;
  count?: order_by | null;
  max?: gcp_storage_objects_max_order_by | null;
  min?: gcp_storage_objects_min_order_by | null;
  stddev?: gcp_storage_objects_stddev_order_by | null;
  stddev_pop?: gcp_storage_objects_stddev_pop_order_by | null;
  stddev_samp?: gcp_storage_objects_stddev_samp_order_by | null;
  sum?: gcp_storage_objects_sum_order_by | null;
  var_pop?: gcp_storage_objects_var_pop_order_by | null;
  var_samp?: gcp_storage_objects_var_samp_order_by | null;
  variance?: gcp_storage_objects_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "gcp_storage_objects"
 */
export interface gcp_storage_objects_arr_rel_insert_input {
  data: gcp_storage_objects_insert_input[];
  on_conflict?: gcp_storage_objects_on_conflict | null;
}

/**
 * order by avg() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_avg_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "gcp_storage_objects". All fields are combined with a logical 'AND'.
 */
export interface gcp_storage_objects_bool_exp {
  _and?: gcp_storage_objects_bool_exp[] | null;
  _not?: gcp_storage_objects_bool_exp | null;
  _or?: gcp_storage_objects_bool_exp[] | null;
  authoredAt?: timestamptz_comparison_exp | null;
  bucketId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creatorId?: Int_comparison_exp | null;
  extension?: String_comparison_exp | null;
  filename?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  originalFileName?: String_comparison_exp | null;
  readLink?: String_comparison_exp | null;
  sha1?: String_comparison_exp | null;
  sha256?: String_comparison_exp | null;
  sha512?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sizeInBytes?: Int_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task_submissions?: task_submissions_bool_exp | null;
  ticket_attachments?: ticket_attachments_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  uploadLink?: String_comparison_exp | null;
  uploadLinkExpiresAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "gcp_storage_objects"
 */
export interface gcp_storage_objects_insert_input {
  authoredAt?: hasura_timestamptz | null;
  bucketId?: number | null;
  createdAt?: hasura_timestamptz | null;
  extension?: string | null;
  filename?: string | null;
  id?: number | null;
  mimetype?: string | null;
  originalFileName?: string | null;
  readLink?: string | null;
  sha1?: string | null;
  sha256?: string | null;
  sha512?: string | null;
  sid?: string | null;
  sizeInBytes?: number | null;
  syncEntityId?: number | null;
  task_submissions?: task_submissions_arr_rel_insert_input | null;
  ticket_attachments?: ticket_attachments_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  uploadLink?: string | null;
  uploadLinkExpiresAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_max_order_by {
  authoredAt?: order_by | null;
  bucketId?: order_by | null;
  createdAt?: order_by | null;
  creatorId?: order_by | null;
  extension?: order_by | null;
  filename?: order_by | null;
  id?: order_by | null;
  mimetype?: order_by | null;
  originalFileName?: order_by | null;
  readLink?: order_by | null;
  sha1?: order_by | null;
  sha256?: order_by | null;
  sha512?: order_by | null;
  sid?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
  updatedAt?: order_by | null;
  uploadLink?: order_by | null;
  uploadLinkExpiresAt?: order_by | null;
}

/**
 * order by min() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_min_order_by {
  authoredAt?: order_by | null;
  bucketId?: order_by | null;
  createdAt?: order_by | null;
  creatorId?: order_by | null;
  extension?: order_by | null;
  filename?: order_by | null;
  id?: order_by | null;
  mimetype?: order_by | null;
  originalFileName?: order_by | null;
  readLink?: order_by | null;
  sha1?: order_by | null;
  sha256?: order_by | null;
  sha512?: order_by | null;
  sid?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
  updatedAt?: order_by | null;
  uploadLink?: order_by | null;
  uploadLinkExpiresAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "gcp_storage_objects"
 */
export interface gcp_storage_objects_obj_rel_insert_input {
  data: gcp_storage_objects_insert_input;
  on_conflict?: gcp_storage_objects_on_conflict | null;
}

/**
 * on_conflict condition type for table "gcp_storage_objects"
 */
export interface gcp_storage_objects_on_conflict {
  constraint: gcp_storage_objects_constraint;
  update_columns: gcp_storage_objects_update_column[];
  where?: gcp_storage_objects_bool_exp | null;
}

/**
 * Ordering options when selecting data from "gcp_storage_objects".
 */
export interface gcp_storage_objects_order_by {
  authoredAt?: order_by | null;
  bucketId?: order_by | null;
  createdAt?: order_by | null;
  creatorId?: order_by | null;
  extension?: order_by | null;
  filename?: order_by | null;
  id?: order_by | null;
  mimetype?: order_by | null;
  originalFileName?: order_by | null;
  readLink?: order_by | null;
  sha1?: order_by | null;
  sha256?: order_by | null;
  sha512?: order_by | null;
  sid?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  task_submissions_aggregate?: task_submissions_aggregate_order_by | null;
  ticket_attachments_aggregate?: ticket_attachments_aggregate_order_by | null;
  updatedAt?: order_by | null;
  uploadLink?: order_by | null;
  uploadLinkExpiresAt?: order_by | null;
  user?: users_order_by | null;
}

/**
 * order by stddev() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_stddev_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_stddev_pop_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_stddev_samp_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by sum() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_sum_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_var_pop_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_var_samp_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by variance() on columns of table "gcp_storage_objects"
 */
export interface gcp_storage_objects_variance_order_by {
  bucketId?: order_by | null;
  creatorId?: order_by | null;
  id?: order_by | null;
  sizeInBytes?: order_by | null;
  syncEntityId?: order_by | null;
}

/**
 * order by aggregate values of table "git_branch_commits"
 */
export interface git_branch_commits_aggregate_order_by {
  avg?: git_branch_commits_avg_order_by | null;
  count?: order_by | null;
  max?: git_branch_commits_max_order_by | null;
  min?: git_branch_commits_min_order_by | null;
  stddev?: git_branch_commits_stddev_order_by | null;
  stddev_pop?: git_branch_commits_stddev_pop_order_by | null;
  stddev_samp?: git_branch_commits_stddev_samp_order_by | null;
  sum?: git_branch_commits_sum_order_by | null;
  var_pop?: git_branch_commits_var_pop_order_by | null;
  var_samp?: git_branch_commits_var_samp_order_by | null;
  variance?: git_branch_commits_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_avg_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_branch_commits". All fields are combined with a logical 'AND'.
 */
export interface git_branch_commits_bool_exp {
  _and?: git_branch_commits_bool_exp[] | null;
  _not?: git_branch_commits_bool_exp | null;
  _or?: git_branch_commits_bool_exp[] | null;
  branchId?: Int_comparison_exp | null;
  commitId?: Int_comparison_exp | null;
  git_branch?: git_branches_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
}

/**
 * order by max() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_max_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by min() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_min_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_stddev_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_stddev_pop_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_stddev_samp_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_sum_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_var_pop_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_var_samp_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_branch_commits"
 */
export interface git_branch_commits_variance_order_by {
  branchId?: order_by | null;
  commitId?: order_by | null;
}

/**
 * order by aggregate values of table "git_branch_refs"
 */
export interface git_branch_refs_aggregate_order_by {
  avg?: git_branch_refs_avg_order_by | null;
  count?: order_by | null;
  max?: git_branch_refs_max_order_by | null;
  min?: git_branch_refs_min_order_by | null;
  stddev?: git_branch_refs_stddev_order_by | null;
  stddev_pop?: git_branch_refs_stddev_pop_order_by | null;
  stddev_samp?: git_branch_refs_stddev_samp_order_by | null;
  sum?: git_branch_refs_sum_order_by | null;
  var_pop?: git_branch_refs_var_pop_order_by | null;
  var_samp?: git_branch_refs_var_samp_order_by | null;
  variance?: git_branch_refs_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_avg_order_by {
  repoId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_branch_refs". All fields are combined with a logical 'AND'.
 */
export interface git_branch_refs_bool_exp {
  _and?: git_branch_refs_bool_exp[] | null;
  _not?: git_branch_refs_bool_exp | null;
  _or?: git_branch_refs_bool_exp[] | null;
  branchName?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_branches?: git_branches_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_pull_requests_with_this_base?: git_pull_requests_bool_exp | null;
  git_pull_requests_with_this_target?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_repo_slices_from_this_branch?: git_repo_slices_bool_exp | null;
  git_repo_slices_to_this_branch?: git_repo_slices_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_max_order_by {
  branchName?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  repoId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_min_order_by {
  branchName?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  repoId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_branch_refs".
 */
export interface git_branch_refs_order_by {
  branchName?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  git_branches_aggregate?: git_branches_aggregate_order_by | null;
  git_commit?: git_commits_order_by | null;
  git_pull_requests_with_this_base_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_pull_requests_with_this_target_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_repo?: git_repos_order_by | null;
  git_repo_slices_from_this_branch_aggregate?: git_repo_slices_aggregate_order_by | null;
  git_repo_slices_to_this_branch_aggregate?: git_repo_slices_aggregate_order_by | null;
  repoId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_stddev_order_by {
  repoId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_stddev_pop_order_by {
  repoId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_stddev_samp_order_by {
  repoId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_sum_order_by {
  repoId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_var_pop_order_by {
  repoId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_var_samp_order_by {
  repoId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_variance_order_by {
  repoId?: order_by | null;
}

/**
 * order by aggregate values of table "git_branches"
 */
export interface git_branches_aggregate_order_by {
  avg?: git_branches_avg_order_by | null;
  count?: order_by | null;
  max?: git_branches_max_order_by | null;
  min?: git_branches_min_order_by | null;
  stddev?: git_branches_stddev_order_by | null;
  stddev_pop?: git_branches_stddev_pop_order_by | null;
  stddev_samp?: git_branches_stddev_samp_order_by | null;
  sum?: git_branches_sum_order_by | null;
  var_pop?: git_branches_var_pop_order_by | null;
  var_samp?: git_branches_var_samp_order_by | null;
  variance?: git_branches_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_branches"
 */
export interface git_branches_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_branches". All fields are combined with a logical 'AND'.
 */
export interface git_branches_bool_exp {
  _and?: git_branches_bool_exp[] | null;
  _not?: git_branches_bool_exp | null;
  _or?: git_branches_bool_exp[] | null;
  git_branch_commits?: git_branch_commits_bool_exp | null;
  git_branch_ref?: git_branch_refs_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  github_branch?: github_branches_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  id?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
}

/**
 * order by max() on columns of table "git_branches"
 */
export interface git_branches_max_order_by {
  id?: order_by | null;
}

/**
 * order by min() on columns of table "git_branches"
 */
export interface git_branches_min_order_by {
  id?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_branches".
 */
export interface git_branches_order_by {
  git_branch_commits_aggregate?: git_branch_commits_aggregate_order_by | null;
  git_branch_ref?: git_branch_refs_order_by | null;
  git_commit?: git_commits_order_by | null;
  git_repo?: git_repos_order_by | null;
  github_branch?: github_branches_order_by | null;
  github_branches_aggregate?: github_branches_aggregate_order_by | null;
  id?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
}

/**
 * order by stddev() on columns of table "git_branches"
 */
export interface git_branches_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_branches"
 */
export interface git_branches_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_branches"
 */
export interface git_branches_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "git_branches"
 */
export interface git_branches_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_branches"
 */
export interface git_branches_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_branches"
 */
export interface git_branches_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "git_branches"
 */
export interface git_branches_variance_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_developer_view". All fields are combined with a logical 'AND'.
 */
export interface git_commit_developer_view_bool_exp {
  _and?: git_commit_developer_view_bool_exp[] | null;
  _not?: git_commit_developer_view_bool_exp | null;
  _or?: git_commit_developer_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "git_commit_developer_view".
 */
export interface git_commit_developer_view_order_by {
  id?: order_by | null;
  syncSid?: order_by | null;
}

/**
 * order by aggregate values of table "git_commit_slices"
 */
export interface git_commit_slices_aggregate_order_by {
  avg?: git_commit_slices_avg_order_by | null;
  count?: order_by | null;
  max?: git_commit_slices_max_order_by | null;
  min?: git_commit_slices_min_order_by | null;
  stddev?: git_commit_slices_stddev_order_by | null;
  stddev_pop?: git_commit_slices_stddev_pop_order_by | null;
  stddev_samp?: git_commit_slices_stddev_samp_order_by | null;
  sum?: git_commit_slices_sum_order_by | null;
  var_pop?: git_commit_slices_var_pop_order_by | null;
  var_samp?: git_commit_slices_var_samp_order_by | null;
  variance?: git_commit_slices_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_avg_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_slices". All fields are combined with a logical 'AND'.
 */
export interface git_commit_slices_bool_exp {
  _and?: git_commit_slices_bool_exp[] | null;
  _not?: git_commit_slices_bool_exp | null;
  _or?: git_commit_slices_bool_exp[] | null;
  git_commit_origin?: git_commits_bool_exp | null;
  git_commit_target?: git_commits_bool_exp | null;
  git_repo_slice?: git_repo_slices_bool_exp | null;
  git_repo_slices?: git_repo_slices_bool_exp | null;
  git_slice_push?: git_slice_pushes_bool_exp | null;
  git_slice_pushes?: git_slice_pushes_bool_exp | null;
  id?: Int_comparison_exp | null;
  originCommitId?: Int_comparison_exp | null;
  slicedAt?: timestamptz_comparison_exp | null;
  targetCommitId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_max_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  slicedAt?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by min() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_min_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  slicedAt?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_commit_slices".
 */
export interface git_commit_slices_order_by {
  git_commit_origin?: git_commits_order_by | null;
  git_commit_target?: git_commits_order_by | null;
  git_repo_slice?: git_repo_slices_order_by | null;
  git_repo_slices_aggregate?: git_repo_slices_aggregate_order_by | null;
  git_slice_push?: git_slice_pushes_order_by | null;
  git_slice_pushes_aggregate?: git_slice_pushes_aggregate_order_by | null;
  id?: order_by | null;
  originCommitId?: order_by | null;
  slicedAt?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_stddev_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_stddev_pop_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_stddev_samp_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_sum_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_var_pop_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_var_samp_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_commit_slices"
 */
export interface git_commit_slices_variance_order_by {
  id?: order_by | null;
  originCommitId?: order_by | null;
  targetCommitId?: order_by | null;
}

/**
 * order by aggregate values of table "git_commits"
 */
export interface git_commits_aggregate_order_by {
  avg?: git_commits_avg_order_by | null;
  count?: order_by | null;
  max?: git_commits_max_order_by | null;
  min?: git_commits_min_order_by | null;
  stddev?: git_commits_stddev_order_by | null;
  stddev_pop?: git_commits_stddev_pop_order_by | null;
  stddev_samp?: git_commits_stddev_samp_order_by | null;
  sum?: git_commits_sum_order_by | null;
  var_pop?: git_commits_var_pop_order_by | null;
  var_samp?: git_commits_var_samp_order_by | null;
  variance?: git_commits_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_commits"
 */
export interface git_commits_avg_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_commits". All fields are combined with a logical 'AND'.
 */
export interface git_commits_bool_exp {
  _and?: git_commits_bool_exp[] | null;
  _not?: git_commits_bool_exp | null;
  _or?: git_commits_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  dev_git_commit?: git_commit_developer_view_bool_exp | null;
  gitCommitSliceById?: git_commit_slices_bool_exp | null;
  gitCommitSlicesByTargetcommitid?: git_commit_slices_bool_exp | null;
  gitPullRequestsByTargetheadcommit?: git_pull_requests_bool_exp | null;
  git_branch_commits?: git_branch_commits_bool_exp | null;
  git_branch_refs?: git_branch_refs_bool_exp | null;
  git_branches?: git_branches_bool_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_commit_slices?: git_commit_slices_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  message?: String_comparison_exp | null;
  oid?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task?: tasks_bool_exp | null;
}

/**
 * order by max() on columns of table "git_commits"
 */
export interface git_commits_max_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  message?: order_by | null;
  oid?: order_by | null;
}

/**
 * order by min() on columns of table "git_commits"
 */
export interface git_commits_min_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  message?: order_by | null;
  oid?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_commits".
 */
export interface git_commits_order_by {
  authorId?: order_by | null;
  dev_git_commit?: git_commit_developer_view_order_by | null;
  gitCommitSliceById?: git_commit_slices_order_by | null;
  gitCommitSlicesByTargetcommitid_aggregate?: git_commit_slices_aggregate_order_by | null;
  gitPullRequestsByTargetheadcommit_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_branch_commits_aggregate?: git_branch_commits_aggregate_order_by | null;
  git_branch_refs_aggregate?: git_branch_refs_aggregate_order_by | null;
  git_branches_aggregate?: git_branches_aggregate_order_by | null;
  git_commit_slice?: git_commit_slices_order_by | null;
  git_commit_slices_aggregate?: git_commit_slices_aggregate_order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_repo?: git_repos_order_by | null;
  git_user?: git_users_order_by | null;
  id?: order_by | null;
  message?: order_by | null;
  oid?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  task?: tasks_order_by | null;
}

/**
 * order by stddev() on columns of table "git_commits"
 */
export interface git_commits_stddev_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_commits"
 */
export interface git_commits_stddev_pop_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_commits"
 */
export interface git_commits_stddev_samp_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "git_commits"
 */
export interface git_commits_sum_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_commits"
 */
export interface git_commits_var_pop_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_commits"
 */
export interface git_commits_var_samp_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "git_commits"
 */
export interface git_commits_variance_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "git_organizations"
 */
export interface git_organizations_aggregate_order_by {
  avg?: git_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: git_organizations_max_order_by | null;
  min?: git_organizations_min_order_by | null;
  stddev?: git_organizations_stddev_order_by | null;
  stddev_pop?: git_organizations_stddev_pop_order_by | null;
  stddev_samp?: git_organizations_stddev_samp_order_by | null;
  sum?: git_organizations_sum_order_by | null;
  var_pop?: git_organizations_var_pop_order_by | null;
  var_samp?: git_organizations_var_samp_order_by | null;
  variance?: git_organizations_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_organizations"
 */
export interface git_organizations_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_organizations". All fields are combined with a logical 'AND'.
 */
export interface git_organizations_bool_exp {
  _and?: git_organizations_bool_exp[] | null;
  _not?: git_organizations_bool_exp | null;
  _or?: git_organizations_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  git_repos?: git_repos_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_organizations?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
}

/**
 * order by max() on columns of table "git_organizations"
 */
export interface git_organizations_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  syncSid?: order_by | null;
}

/**
 * order by min() on columns of table "git_organizations"
 */
export interface git_organizations_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  syncSid?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_organizations".
 */
export interface git_organizations_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  git_repos_aggregate?: git_repos_aggregate_order_by | null;
  github_organization?: github_organizations_order_by | null;
  github_organizations_aggregate?: github_organizations_aggregate_order_by | null;
  id?: order_by | null;
  syncSid?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
}

/**
 * order by stddev() on columns of table "git_organizations"
 */
export interface git_organizations_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_organizations"
 */
export interface git_organizations_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_organizations"
 */
export interface git_organizations_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "git_organizations"
 */
export interface git_organizations_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_organizations"
 */
export interface git_organizations_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_organizations"
 */
export interface git_organizations_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "git_organizations"
 */
export interface git_organizations_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_aggregate_order_by {
  avg?: git_pull_request_assignees_avg_order_by | null;
  count?: order_by | null;
  max?: git_pull_request_assignees_max_order_by | null;
  min?: git_pull_request_assignees_min_order_by | null;
  stddev?: git_pull_request_assignees_stddev_order_by | null;
  stddev_pop?: git_pull_request_assignees_stddev_pop_order_by | null;
  stddev_samp?: git_pull_request_assignees_stddev_samp_order_by | null;
  sum?: git_pull_request_assignees_sum_order_by | null;
  var_pop?: git_pull_request_assignees_var_pop_order_by | null;
  var_samp?: git_pull_request_assignees_var_samp_order_by | null;
  variance?: git_pull_request_assignees_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_avg_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_assignees". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_assignees_bool_exp {
  _and?: git_pull_request_assignees_bool_exp[] | null;
  _not?: git_pull_request_assignees_bool_exp | null;
  _or?: git_pull_request_assignees_bool_exp[] | null;
  assigneeUserId?: Int_comparison_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  pullRequestId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_max_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by min() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_min_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_stddev_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_stddev_pop_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_stddev_samp_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_sum_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_var_pop_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_var_samp_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_variance_order_by {
  assigneeUserId?: order_by | null;
  pullRequestId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_developer_view". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_developer_view_bool_exp {
  _and?: git_pull_request_developer_view_bool_exp[] | null;
  _not?: git_pull_request_developer_view_bool_exp | null;
  _or?: git_pull_request_developer_view_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isDraft?: Boolean_comparison_exp | null;
  isMerged?: Boolean_comparison_exp | null;
  mergeable?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  state?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "git_pull_request_developer_view".
 */
export interface git_pull_request_developer_view_order_by {
  authorId?: order_by | null;
  body?: order_by | null;
  id?: order_by | null;
  isDraft?: order_by | null;
  isMerged?: order_by | null;
  mergeable?: order_by | null;
  mergedAt?: order_by | null;
  repoId?: order_by | null;
  state?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "git_pull_request_review_state_enum". All fields are combined with logical 'AND'.
 */
export interface git_pull_request_review_state_enum_comparison_exp {
  _eq?: git_pull_request_review_state_enum | null;
  _in?: git_pull_request_review_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_pull_request_review_state_enum | null;
  _nin?: git_pull_request_review_state_enum[] | null;
}

/**
 * order by aggregate values of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_aggregate_order_by {
  avg?: git_pull_request_reviewers_avg_order_by | null;
  count?: order_by | null;
  max?: git_pull_request_reviewers_max_order_by | null;
  min?: git_pull_request_reviewers_min_order_by | null;
  stddev?: git_pull_request_reviewers_stddev_order_by | null;
  stddev_pop?: git_pull_request_reviewers_stddev_pop_order_by | null;
  stddev_samp?: git_pull_request_reviewers_stddev_samp_order_by | null;
  sum?: git_pull_request_reviewers_sum_order_by | null;
  var_pop?: git_pull_request_reviewers_var_pop_order_by | null;
  var_samp?: git_pull_request_reviewers_var_samp_order_by | null;
  variance?: git_pull_request_reviewers_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_avg_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_reviewers". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_reviewers_bool_exp {
  _and?: git_pull_request_reviewers_bool_exp[] | null;
  _not?: git_pull_request_reviewers_bool_exp | null;
  _or?: git_pull_request_reviewers_bool_exp[] | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  reviewerUserId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_max_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by min() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_min_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_stddev_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_stddev_pop_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_stddev_samp_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_sum_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_var_pop_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_var_samp_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_variance_order_by {
  pullRequestId?: order_by | null;
  reviewerUserId?: order_by | null;
}

/**
 * order by aggregate values of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_aggregate_order_by {
  avg?: git_pull_request_reviews_avg_order_by | null;
  count?: order_by | null;
  max?: git_pull_request_reviews_max_order_by | null;
  min?: git_pull_request_reviews_min_order_by | null;
  stddev?: git_pull_request_reviews_stddev_order_by | null;
  stddev_pop?: git_pull_request_reviews_stddev_pop_order_by | null;
  stddev_samp?: git_pull_request_reviews_stddev_samp_order_by | null;
  sum?: git_pull_request_reviews_sum_order_by | null;
  var_pop?: git_pull_request_reviews_var_pop_order_by | null;
  var_samp?: git_pull_request_reviews_var_samp_order_by | null;
  variance?: git_pull_request_reviews_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_avg_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_reviews". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_reviews_bool_exp {
  _and?: git_pull_request_reviews_bool_exp[] | null;
  _not?: git_pull_request_reviews_bool_exp | null;
  _or?: git_pull_request_reviews_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  state?: git_pull_request_review_state_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  taskReviewId?: Int_comparison_exp | null;
  task_review?: task_reviews_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_max_order_by {
  authorId?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  taskReviewId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_min_order_by {
  authorId?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  taskReviewId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_pull_request_reviews".
 */
export interface git_pull_request_reviews_order_by {
  authorId?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussion_source?: discussion_sources_order_by | null;
  git_pull_request?: git_pull_requests_order_by | null;
  git_user?: git_users_order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  state?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  taskReviewId?: order_by | null;
  task_review?: task_reviews_order_by | null;
  task_reviews_aggregate?: task_reviews_aggregate_order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_stddev_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_stddev_pop_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_stddev_samp_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_sum_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_var_pop_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_var_samp_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_variance_order_by {
  authorId?: order_by | null;
  id?: order_by | null;
  pullRequestId?: order_by | null;
  taskReviewId?: order_by | null;
}

/**
 * order by aggregate values of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_aggregate_order_by {
  avg?: git_pull_request_slice_avg_order_by | null;
  count?: order_by | null;
  max?: git_pull_request_slice_max_order_by | null;
  min?: git_pull_request_slice_min_order_by | null;
  stddev?: git_pull_request_slice_stddev_order_by | null;
  stddev_pop?: git_pull_request_slice_stddev_pop_order_by | null;
  stddev_samp?: git_pull_request_slice_stddev_samp_order_by | null;
  sum?: git_pull_request_slice_sum_order_by | null;
  var_pop?: git_pull_request_slice_var_pop_order_by | null;
  var_samp?: git_pull_request_slice_var_samp_order_by | null;
  variance?: git_pull_request_slice_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_avg_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_slice". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_slice_bool_exp {
  _and?: git_pull_request_slice_bool_exp[] | null;
  _not?: git_pull_request_slice_bool_exp | null;
  _or?: git_pull_request_slice_bool_exp[] | null;
  basePullRequestId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_pull_request_by_base_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_by_pushed_pull_request?: git_pull_requests_bool_exp | null;
  git_repo_slice?: git_repo_slices_bool_exp | null;
  git_slice_pushes?: git_slice_pushes_bool_exp | null;
  id?: Int_comparison_exp | null;
  pushedPullRequestId?: Int_comparison_exp | null;
  repoSliceId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_max_order_by {
  basePullRequestId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_min_order_by {
  basePullRequestId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_pull_request_slice".
 */
export interface git_pull_request_slice_order_by {
  basePullRequestId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  git_pull_request_by_base_pull_request?: git_pull_requests_order_by | null;
  git_pull_request_by_pushed_pull_request?: git_pull_requests_order_by | null;
  git_repo_slice?: git_repo_slices_order_by | null;
  git_slice_pushes_aggregate?: git_slice_pushes_aggregate_order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_stddev_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_stddev_pop_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_stddev_samp_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_sum_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_var_pop_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_var_samp_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_pull_request_slice"
 */
export interface git_pull_request_slice_variance_order_by {
  basePullRequestId?: order_by | null;
  id?: order_by | null;
  pushedPullRequestId?: order_by | null;
  repoSliceId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_state". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_state_bool_exp {
  _and?: git_pull_request_state_bool_exp[] | null;
  _not?: git_pull_request_state_bool_exp | null;
  _or?: git_pull_request_state_bool_exp[] | null;
  description?: String_comparison_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "git_pull_request_state_enum". All fields are combined with logical 'AND'.
 */
export interface git_pull_request_state_enum_comparison_exp {
  _eq?: git_pull_request_state_enum | null;
  _in?: git_pull_request_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_pull_request_state_enum | null;
  _nin?: git_pull_request_state_enum[] | null;
}

/**
 * Ordering options when selecting data from "git_pull_request_state".
 */
export interface git_pull_request_state_order_by {
  description?: order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "git_pull_requests"
 */
export interface git_pull_requests_aggregate_order_by {
  avg?: git_pull_requests_avg_order_by | null;
  count?: order_by | null;
  max?: git_pull_requests_max_order_by | null;
  min?: git_pull_requests_min_order_by | null;
  stddev?: git_pull_requests_stddev_order_by | null;
  stddev_pop?: git_pull_requests_stddev_pop_order_by | null;
  stddev_samp?: git_pull_requests_stddev_samp_order_by | null;
  sum?: git_pull_requests_sum_order_by | null;
  var_pop?: git_pull_requests_var_pop_order_by | null;
  var_samp?: git_pull_requests_var_samp_order_by | null;
  variance?: git_pull_requests_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_avg_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_requests". All fields are combined with a logical 'AND'.
 */
export interface git_pull_requests_bool_exp {
  _and?: git_pull_requests_bool_exp[] | null;
  _not?: git_pull_requests_bool_exp | null;
  _or?: git_pull_requests_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  baseBranchRef?: String_comparison_exp | null;
  baseHeadCommit?: String_comparison_exp | null;
  base_branch_ref?: git_branch_refs_bool_exp | null;
  base_head_commit?: git_commits_bool_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_git_pull_request?: git_pull_request_developer_view_bool_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  gitPullRequestSliceByPushedpullrequestid?: git_pull_request_slice_bool_exp | null;
  git_pull_request_assignees?: git_pull_request_assignees_bool_exp | null;
  git_pull_request_reviewers?: git_pull_request_reviewers_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_slice?: git_pull_request_slice_bool_exp | null;
  git_pull_request_state?: git_pull_request_state_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  isDraft?: Boolean_comparison_exp | null;
  isMerged?: Boolean_comparison_exp | null;
  mergeable?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  number?: Int_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  state?: git_pull_request_state_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  targetBranchRef?: String_comparison_exp | null;
  targetHeadCommit?: String_comparison_exp | null;
  targetRepoId?: Int_comparison_exp | null;
  target_branch_ref?: git_branch_refs_bool_exp | null;
  target_head_commit?: git_commits_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_max_order_by {
  authorId?: order_by | null;
  baseBranchRef?: order_by | null;
  baseHeadCommit?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  mergedAt?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  targetBranchRef?: order_by | null;
  targetHeadCommit?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_min_order_by {
  authorId?: order_by | null;
  baseBranchRef?: order_by | null;
  baseHeadCommit?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  mergedAt?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  targetBranchRef?: order_by | null;
  targetHeadCommit?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_pull_requests".
 */
export interface git_pull_requests_order_by {
  authorId?: order_by | null;
  baseBranchRef?: order_by | null;
  baseHeadCommit?: order_by | null;
  base_branch_ref?: git_branch_refs_order_by | null;
  base_head_commit?: git_commits_order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  dev_git_pull_request?: git_pull_request_developer_view_order_by | null;
  discussionSourceId?: order_by | null;
  discussion_source?: discussion_sources_order_by | null;
  gitPullRequestSliceByPushedpullrequestid_aggregate?: git_pull_request_slice_aggregate_order_by | null;
  git_pull_request_assignees_aggregate?: git_pull_request_assignees_aggregate_order_by | null;
  git_pull_request_reviewers_aggregate?: git_pull_request_reviewers_aggregate_order_by | null;
  git_pull_request_reviews_aggregate?: git_pull_request_reviews_aggregate_order_by | null;
  git_pull_request_slice_aggregate?: git_pull_request_slice_aggregate_order_by | null;
  git_pull_request_state?: git_pull_request_state_order_by | null;
  git_repo?: git_repos_order_by | null;
  git_user?: git_users_order_by | null;
  id?: order_by | null;
  isDraft?: order_by | null;
  isMerged?: order_by | null;
  mergeable?: order_by | null;
  mergedAt?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  state?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  targetBranchRef?: order_by | null;
  targetHeadCommit?: order_by | null;
  targetRepoId?: order_by | null;
  target_branch_ref?: git_branch_refs_order_by | null;
  target_head_commit?: git_commits_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticket?: tickets_order_by | null;
  ticketId?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_stddev_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_stddev_pop_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_stddev_samp_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_sum_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_var_pop_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_var_samp_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_pull_requests"
 */
export interface git_pull_requests_variance_order_by {
  authorId?: order_by | null;
  discussionSourceId?: order_by | null;
  id?: order_by | null;
  number?: order_by | null;
  repoId?: order_by | null;
  targetRepoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by aggregate values of table "git_repo_slices"
 */
export interface git_repo_slices_aggregate_order_by {
  avg?: git_repo_slices_avg_order_by | null;
  count?: order_by | null;
  max?: git_repo_slices_max_order_by | null;
  min?: git_repo_slices_min_order_by | null;
  stddev?: git_repo_slices_stddev_order_by | null;
  stddev_pop?: git_repo_slices_stddev_pop_order_by | null;
  stddev_samp?: git_repo_slices_stddev_samp_order_by | null;
  sum?: git_repo_slices_sum_order_by | null;
  var_pop?: git_repo_slices_var_pop_order_by | null;
  var_samp?: git_repo_slices_var_samp_order_by | null;
  variance?: git_repo_slices_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_avg_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_repo_slices". All fields are combined with a logical 'AND'.
 */
export interface git_repo_slices_bool_exp {
  _and?: git_repo_slices_bool_exp[] | null;
  _not?: git_repo_slices_bool_exp | null;
  _or?: git_repo_slices_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_branch_ref_sliced_from?: git_branch_refs_bool_exp | null;
  git_branch_ref_sliced_to?: git_branch_refs_bool_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_pull_request_slice?: git_pull_request_slice_bool_exp | null;
  git_repo_sliced_from?: git_repos_bool_exp | null;
  git_repo_sliced_to?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  ignoredPaths?: _text_comparison_exp | null;
  latestCommitSliceId?: Int_comparison_exp | null;
  slicedFolders?: _text_comparison_exp | null;
  slicedFromBranchName?: String_comparison_exp | null;
  slicedFromRepoId?: Int_comparison_exp | null;
  slicedToBranchName?: String_comparison_exp | null;
  slicedToRepoId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_max_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromBranchName?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToBranchName?: order_by | null;
  slicedToRepoId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_min_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromBranchName?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToBranchName?: order_by | null;
  slicedToRepoId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_repo_slices".
 */
export interface git_repo_slices_order_by {
  clientProjectId?: order_by | null;
  client_project?: client_projects_order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  git_branch_ref_sliced_from?: git_branch_refs_order_by | null;
  git_branch_ref_sliced_to?: git_branch_refs_order_by | null;
  git_commit_slice?: git_commit_slices_order_by | null;
  git_pull_request_slice_aggregate?: git_pull_request_slice_aggregate_order_by | null;
  git_repo_sliced_from?: git_repos_order_by | null;
  git_repo_sliced_to?: git_repos_order_by | null;
  id?: order_by | null;
  ignoredPaths?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFolders?: order_by | null;
  slicedFromBranchName?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToBranchName?: order_by | null;
  slicedToRepoId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_stddev_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_sum_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_var_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_var_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_variance_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  latestCommitSliceId?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by aggregate values of table "git_repo_users"
 */
export interface git_repo_users_aggregate_order_by {
  avg?: git_repo_users_avg_order_by | null;
  count?: order_by | null;
  max?: git_repo_users_max_order_by | null;
  min?: git_repo_users_min_order_by | null;
  stddev?: git_repo_users_stddev_order_by | null;
  stddev_pop?: git_repo_users_stddev_pop_order_by | null;
  stddev_samp?: git_repo_users_stddev_samp_order_by | null;
  sum?: git_repo_users_sum_order_by | null;
  var_pop?: git_repo_users_var_pop_order_by | null;
  var_samp?: git_repo_users_var_samp_order_by | null;
  variance?: git_repo_users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_repo_users"
 */
export interface git_repo_users_avg_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_repo_users". All fields are combined with a logical 'AND'.
 */
export interface git_repo_users_bool_exp {
  _and?: git_repo_users_bool_exp[] | null;
  _not?: git_repo_users_bool_exp | null;
  _or?: git_repo_users_bool_exp[] | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_repo_users"
 */
export interface git_repo_users_max_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "git_repo_users"
 */
export interface git_repo_users_min_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_repo_users"
 */
export interface git_repo_users_stddev_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_repo_users"
 */
export interface git_repo_users_stddev_pop_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_repo_users"
 */
export interface git_repo_users_stddev_samp_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_repo_users"
 */
export interface git_repo_users_sum_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_repo_users"
 */
export interface git_repo_users_var_pop_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_repo_users"
 */
export interface git_repo_users_var_samp_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_repo_users"
 */
export interface git_repo_users_variance_order_by {
  repoId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "git_repos"
 */
export interface git_repos_aggregate_order_by {
  avg?: git_repos_avg_order_by | null;
  count?: order_by | null;
  max?: git_repos_max_order_by | null;
  min?: git_repos_min_order_by | null;
  stddev?: git_repos_stddev_order_by | null;
  stddev_pop?: git_repos_stddev_pop_order_by | null;
  stddev_samp?: git_repos_stddev_samp_order_by | null;
  sum?: git_repos_sum_order_by | null;
  var_pop?: git_repos_var_pop_order_by | null;
  var_samp?: git_repos_var_samp_order_by | null;
  variance?: git_repos_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_repos"
 */
export interface git_repos_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_repos". All fields are combined with a logical 'AND'.
 */
export interface git_repos_bool_exp {
  _and?: git_repos_bool_exp[] | null;
  _not?: git_repos_bool_exp | null;
  _or?: git_repos_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project_repositories?: client_project_repositories_bool_exp | null;
  git_branch_refs?: git_branch_refs_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo_sliced_to?: git_repo_slices_bool_exp | null;
  git_repo_users?: git_repo_users_bool_exp | null;
  git_repos_sliced_from?: git_repo_slices_bool_exp | null;
  github_repo_by_sid?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  owner?: String_comparison_exp | null;
  source?: git_sources_enum_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticketSourcesByGitrepoid?: ticket_sources_bool_exp | null;
  ticketSourcesByRepoid?: ticket_sources_bool_exp | null;
  ticket_repos?: ticket_repos_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  tickets?: tickets_bool_exp | null;
}

/**
 * order by max() on columns of table "git_repos"
 */
export interface git_repos_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  owner?: order_by | null;
}

/**
 * order by min() on columns of table "git_repos"
 */
export interface git_repos_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  owner?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_repos".
 */
export interface git_repos_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_project_repositories_aggregate?: client_project_repositories_aggregate_order_by | null;
  git_branch_refs_aggregate?: git_branch_refs_aggregate_order_by | null;
  git_commits_aggregate?: git_commits_aggregate_order_by | null;
  git_organization?: git_organizations_order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_repo_sliced_to?: git_repo_slices_order_by | null;
  git_repo_users_aggregate?: git_repo_users_aggregate_order_by | null;
  git_repos_sliced_from_aggregate?: git_repo_slices_aggregate_order_by | null;
  github_repo_by_sid?: github_repos_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  owner?: order_by | null;
  source?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticketSourcesByGitrepoid_aggregate?: ticket_sources_aggregate_order_by | null;
  ticketSourcesByRepoid_aggregate?: ticket_sources_aggregate_order_by | null;
  ticket_repos_aggregate?: ticket_repos_aggregate_order_by | null;
  ticket_sources?: ticket_sources_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "git_repos"
 */
export interface git_repos_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_repos"
 */
export interface git_repos_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_repos"
 */
export interface git_repos_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "git_repos"
 */
export interface git_repos_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_repos"
 */
export interface git_repos_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_repos"
 */
export interface git_repos_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "git_repos"
 */
export interface git_repos_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "git_slice_pushes"
 */
export interface git_slice_pushes_aggregate_order_by {
  avg?: git_slice_pushes_avg_order_by | null;
  count?: order_by | null;
  max?: git_slice_pushes_max_order_by | null;
  min?: git_slice_pushes_min_order_by | null;
  stddev?: git_slice_pushes_stddev_order_by | null;
  stddev_pop?: git_slice_pushes_stddev_pop_order_by | null;
  stddev_samp?: git_slice_pushes_stddev_samp_order_by | null;
  sum?: git_slice_pushes_sum_order_by | null;
  var_pop?: git_slice_pushes_var_pop_order_by | null;
  var_samp?: git_slice_pushes_var_samp_order_by | null;
  variance?: git_slice_pushes_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_avg_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_slice_pushes". All fields are combined with a logical 'AND'.
 */
export interface git_slice_pushes_bool_exp {
  _and?: git_slice_pushes_bool_exp[] | null;
  _not?: git_slice_pushes_bool_exp | null;
  _or?: git_slice_pushes_bool_exp[] | null;
  commitSliceId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  error?: jsonb_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_pull_request_slice?: git_pull_request_slice_bool_exp | null;
  id?: Int_comparison_exp | null;
  logs?: String_comparison_exp | null;
  pullRequestSliceId?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_max_order_by {
  commitSliceId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  finishedAt?: order_by | null;
  id?: order_by | null;
  logs?: order_by | null;
  pullRequestSliceId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_min_order_by {
  commitSliceId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  finishedAt?: order_by | null;
  id?: order_by | null;
  logs?: order_by | null;
  pullRequestSliceId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_slice_pushes".
 */
export interface git_slice_pushes_order_by {
  commitSliceId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  error?: order_by | null;
  finishedAt?: order_by | null;
  git_commit_slice?: git_commit_slices_order_by | null;
  git_pull_request_slice?: git_pull_request_slice_order_by | null;
  id?: order_by | null;
  logs?: order_by | null;
  pullRequestSliceId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_stddev_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_stddev_pop_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_stddev_samp_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_sum_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_var_pop_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_var_samp_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_slice_pushes"
 */
export interface git_slice_pushes_variance_order_by {
  commitSliceId?: order_by | null;
  id?: order_by | null;
  pullRequestSliceId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "git_sources_enum". All fields are combined with logical 'AND'.
 */
export interface git_sources_enum_comparison_exp {
  _eq?: git_sources_enum | null;
  _in?: git_sources_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_sources_enum | null;
  _nin?: git_sources_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "git_user_developer_view". All fields are combined with a logical 'AND'.
 */
export interface git_user_developer_view_bool_exp {
  _and?: git_user_developer_view_bool_exp[] | null;
  _not?: git_user_developer_view_bool_exp | null;
  _or?: git_user_developer_view_bool_exp[] | null;
  agencyId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  password?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "git_user_developer_view".
 */
export interface git_user_developer_view_order_by {
  agencyId?: order_by | null;
  createdAt?: order_by | null;
  email?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  password?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by aggregate values of table "git_users"
 */
export interface git_users_aggregate_order_by {
  avg?: git_users_avg_order_by | null;
  count?: order_by | null;
  max?: git_users_max_order_by | null;
  min?: git_users_min_order_by | null;
  stddev?: git_users_stddev_order_by | null;
  stddev_pop?: git_users_stddev_pop_order_by | null;
  stddev_samp?: git_users_stddev_samp_order_by | null;
  sum?: git_users_sum_order_by | null;
  var_pop?: git_users_var_pop_order_by | null;
  var_samp?: git_users_var_samp_order_by | null;
  variance?: git_users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_users"
 */
export interface git_users_avg_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_users". All fields are combined with a logical 'AND'.
 */
export interface git_users_bool_exp {
  _and?: git_users_bool_exp[] | null;
  _not?: git_users_bool_exp | null;
  _or?: git_users_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  avatarUrl?: String_comparison_exp | null;
  dev_git_users?: git_user_developer_view_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_pull_request_assignees?: git_pull_request_assignees_bool_exp | null;
  git_pull_request_reviewers?: git_pull_request_reviewers_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo_users?: git_repo_users_bool_exp | null;
  githubAccountLogin?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * order by max() on columns of table "git_users"
 */
export interface git_users_max_order_by {
  avatarUrl?: order_by | null;
  githubAccountLogin?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "git_users"
 */
export interface git_users_min_order_by {
  avatarUrl?: order_by | null;
  githubAccountLogin?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  userId?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_users".
 */
export interface git_users_order_by {
  agency?: agencies_order_by | null;
  avatarUrl?: order_by | null;
  dev_git_users?: git_user_developer_view_order_by | null;
  git_commits_aggregate?: git_commits_aggregate_order_by | null;
  git_pull_request_assignees_aggregate?: git_pull_request_assignees_aggregate_order_by | null;
  git_pull_request_reviewers_aggregate?: git_pull_request_reviewers_aggregate_order_by | null;
  git_pull_request_reviews_aggregate?: git_pull_request_reviews_aggregate_order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_repo_users_aggregate?: git_repo_users_aggregate_order_by | null;
  githubAccountLogin?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  user?: users_order_by | null;
  userId?: order_by | null;
  user_email?: user_emails_order_by | null;
}

/**
 * order by stddev() on columns of table "git_users"
 */
export interface git_users_stddev_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_users"
 */
export interface git_users_stddev_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_users"
 */
export interface git_users_stddev_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_users"
 */
export interface git_users_sum_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_users"
 */
export interface git_users_var_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_users"
 */
export interface git_users_var_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_users"
 */
export interface git_users_variance_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "github_branches"
 */
export interface github_branches_aggregate_order_by {
  avg?: github_branches_avg_order_by | null;
  count?: order_by | null;
  max?: github_branches_max_order_by | null;
  min?: github_branches_min_order_by | null;
  stddev?: github_branches_stddev_order_by | null;
  stddev_pop?: github_branches_stddev_pop_order_by | null;
  stddev_samp?: github_branches_stddev_samp_order_by | null;
  sum?: github_branches_sum_order_by | null;
  var_pop?: github_branches_var_pop_order_by | null;
  var_samp?: github_branches_var_samp_order_by | null;
  variance?: github_branches_variance_order_by | null;
}

/**
 * order by avg() on columns of table "github_branches"
 */
export interface github_branches_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_branches". All fields are combined with a logical 'AND'.
 */
export interface github_branches_bool_exp {
  _and?: github_branches_bool_exp[] | null;
  _not?: github_branches_bool_exp | null;
  _or?: github_branches_bool_exp[] | null;
  git_branch?: git_branches_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
}

/**
 * order by max() on columns of table "github_branches"
 */
export interface github_branches_max_order_by {
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * order by min() on columns of table "github_branches"
 */
export interface github_branches_min_order_by {
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * Ordering options when selecting data from "github_branches".
 */
export interface github_branches_order_by {
  git_branch?: git_branches_order_by | null;
  github_repo?: github_repos_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
}

/**
 * order by stddev() on columns of table "github_branches"
 */
export interface github_branches_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "github_branches"
 */
export interface github_branches_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "github_branches"
 */
export interface github_branches_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "github_branches"
 */
export interface github_branches_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "github_branches"
 */
export interface github_branches_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "github_branches"
 */
export interface github_branches_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "github_branches"
 */
export interface github_branches_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "github_organizations"
 */
export interface github_organizations_aggregate_order_by {
  avg?: github_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: github_organizations_max_order_by | null;
  min?: github_organizations_min_order_by | null;
  stddev?: github_organizations_stddev_order_by | null;
  stddev_pop?: github_organizations_stddev_pop_order_by | null;
  stddev_samp?: github_organizations_stddev_samp_order_by | null;
  sum?: github_organizations_sum_order_by | null;
  var_pop?: github_organizations_var_pop_order_by | null;
  var_samp?: github_organizations_var_samp_order_by | null;
  variance?: github_organizations_variance_order_by | null;
}

/**
 * order by avg() on columns of table "github_organizations"
 */
export interface github_organizations_avg_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_organizations". All fields are combined with a logical 'AND'.
 */
export interface github_organizations_bool_exp {
  _and?: github_organizations_bool_exp[] | null;
  _not?: github_organizations_bool_exp | null;
  _or?: github_organizations_bool_exp[] | null;
  gitOrganizationId?: Int_comparison_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
}

/**
 * order by max() on columns of table "github_organizations"
 */
export interface github_organizations_max_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
  login?: order_by | null;
}

/**
 * order by min() on columns of table "github_organizations"
 */
export interface github_organizations_min_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
  login?: order_by | null;
}

/**
 * Ordering options when selecting data from "github_organizations".
 */
export interface github_organizations_order_by {
  gitOrganizationId?: order_by | null;
  git_organization?: git_organizations_order_by | null;
  id?: order_by | null;
  login?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
}

/**
 * order by stddev() on columns of table "github_organizations"
 */
export interface github_organizations_stddev_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "github_organizations"
 */
export interface github_organizations_stddev_pop_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "github_organizations"
 */
export interface github_organizations_stddev_samp_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "github_organizations"
 */
export interface github_organizations_sum_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "github_organizations"
 */
export interface github_organizations_var_pop_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "github_organizations"
 */
export interface github_organizations_var_samp_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "github_organizations"
 */
export interface github_organizations_variance_order_by {
  gitOrganizationId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_repo_developer_view". All fields are combined with a logical 'AND'.
 */
export interface github_repo_developer_view_bool_exp {
  _and?: github_repo_developer_view_bool_exp[] | null;
  _not?: github_repo_developer_view_bool_exp | null;
  _or?: github_repo_developer_view_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  github_repos?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isDisabled?: Boolean_comparison_exp | null;
  isFork?: Boolean_comparison_exp | null;
  isLocked?: Boolean_comparison_exp | null;
  isPrivate?: Boolean_comparison_exp | null;
  isTemplate?: Boolean_comparison_exp | null;
  ownerLogin?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "github_repo_developer_view".
 */
export interface github_repo_developer_view_order_by {
  createdAt?: order_by | null;
  description?: order_by | null;
  gitRepoId?: order_by | null;
  git_repo?: git_repos_order_by | null;
  github_repos?: github_repos_order_by | null;
  id?: order_by | null;
  isArchived?: order_by | null;
  isDisabled?: order_by | null;
  isFork?: order_by | null;
  isLocked?: order_by | null;
  isPrivate?: order_by | null;
  isTemplate?: order_by | null;
  ownerLogin?: order_by | null;
  sid?: order_by | null;
  syncEntityId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by aggregate values of table "github_repos"
 */
export interface github_repos_aggregate_order_by {
  avg?: github_repos_avg_order_by | null;
  count?: order_by | null;
  max?: github_repos_max_order_by | null;
  min?: github_repos_min_order_by | null;
  stddev?: github_repos_stddev_order_by | null;
  stddev_pop?: github_repos_stddev_pop_order_by | null;
  stddev_samp?: github_repos_stddev_samp_order_by | null;
  sum?: github_repos_sum_order_by | null;
  var_pop?: github_repos_var_pop_order_by | null;
  var_samp?: github_repos_var_samp_order_by | null;
  variance?: github_repos_variance_order_by | null;
}

/**
 * order by avg() on columns of table "github_repos"
 */
export interface github_repos_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_repos". All fields are combined with a logical 'AND'.
 */
export interface github_repos_bool_exp {
  _and?: github_repos_bool_exp[] | null;
  _not?: github_repos_bool_exp | null;
  _or?: github_repos_bool_exp[] | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_github_repos?: github_repo_developer_view_bool_exp | null;
  git_repo_by_sid?: git_repos_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
}

/**
 * order by max() on columns of table "github_repos"
 */
export interface github_repos_max_order_by {
  deletedAt?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * order by min() on columns of table "github_repos"
 */
export interface github_repos_min_order_by {
  deletedAt?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
}

/**
 * Ordering options when selecting data from "github_repos".
 */
export interface github_repos_order_by {
  deletedAt?: order_by | null;
  dev_github_repos?: github_repo_developer_view_order_by | null;
  git_repo_by_sid?: git_repos_order_by | null;
  github_branches_aggregate?: github_branches_aggregate_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
}

/**
 * order by stddev() on columns of table "github_repos"
 */
export interface github_repos_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "github_repos"
 */
export interface github_repos_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "github_repos"
 */
export interface github_repos_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "github_repos"
 */
export interface github_repos_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "github_repos"
 */
export interface github_repos_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "github_repos"
 */
export interface github_repos_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "github_repos"
 */
export interface github_repos_variance_order_by {
  id?: order_by | null;
}

export interface insertDiscussionCommentInput {
  authorSid?: string | null;
  authorSyncTableName?: string | null;
  authorUserId?: number | null;
  body: string;
  bodyType: CommentBodyType;
  discussionSourceId: number;
  parentId?: number | null;
}

export interface inviteToClientInput {
  clientId: string;
  email: string;
  projectId: number;
  role: inviteToClientRole;
}

/**
 * order by aggregate values of table "jira_accounts"
 */
export interface jira_accounts_aggregate_order_by {
  avg?: jira_accounts_avg_order_by | null;
  count?: order_by | null;
  max?: jira_accounts_max_order_by | null;
  min?: jira_accounts_min_order_by | null;
  stddev?: jira_accounts_stddev_order_by | null;
  stddev_pop?: jira_accounts_stddev_pop_order_by | null;
  stddev_samp?: jira_accounts_stddev_samp_order_by | null;
  sum?: jira_accounts_sum_order_by | null;
  var_pop?: jira_accounts_var_pop_order_by | null;
  var_samp?: jira_accounts_var_samp_order_by | null;
  variance?: jira_accounts_variance_order_by | null;
}

/**
 * order by avg() on columns of table "jira_accounts"
 */
export interface jira_accounts_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "jira_accounts". All fields are combined with a logical 'AND'.
 */
export interface jira_accounts_bool_exp {
  _and?: jira_accounts_bool_exp[] | null;
  _not?: jira_accounts_bool_exp | null;
  _or?: jira_accounts_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  jiraOrgEndpoint?: String_comparison_exp | null;
  jira_organization?: jira_organizations_bool_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * order by max() on columns of table "jira_accounts"
 */
export interface jira_accounts_max_order_by {
  id?: order_by | null;
  jiraOrgEndpoint?: order_by | null;
  name?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by min() on columns of table "jira_accounts"
 */
export interface jira_accounts_min_order_by {
  id?: order_by | null;
  jiraOrgEndpoint?: order_by | null;
  name?: order_by | null;
  sid?: order_by | null;
}

/**
 * Ordering options when selecting data from "jira_accounts".
 */
export interface jira_accounts_order_by {
  id?: order_by | null;
  jiraOrgEndpoint?: order_by | null;
  jira_organization?: jira_organizations_order_by | null;
  name?: order_by | null;
  sid?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  ticket_sources_aggregate?: ticket_sources_aggregate_order_by | null;
  user_email?: user_emails_order_by | null;
}

/**
 * order by stddev() on columns of table "jira_accounts"
 */
export interface jira_accounts_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "jira_accounts"
 */
export interface jira_accounts_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "jira_accounts"
 */
export interface jira_accounts_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "jira_accounts"
 */
export interface jira_accounts_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "jira_accounts"
 */
export interface jira_accounts_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "jira_accounts"
 */
export interface jira_accounts_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "jira_accounts"
 */
export interface jira_accounts_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "jira_organizations"
 */
export interface jira_organizations_aggregate_order_by {
  count?: order_by | null;
  max?: jira_organizations_max_order_by | null;
  min?: jira_organizations_min_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "jira_organizations". All fields are combined with a logical 'AND'.
 */
export interface jira_organizations_bool_exp {
  _and?: jira_organizations_bool_exp[] | null;
  _not?: jira_organizations_bool_exp | null;
  _or?: jira_organizations_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  endpoint?: String_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_accounts?: jira_accounts_bool_exp | null;
  jira_projects?: jira_projects_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
}

/**
 * order by max() on columns of table "jira_organizations"
 */
export interface jira_organizations_max_order_by {
  clientId?: order_by | null;
  endpoint?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by min() on columns of table "jira_organizations"
 */
export interface jira_organizations_min_order_by {
  clientId?: order_by | null;
  endpoint?: order_by | null;
  sid?: order_by | null;
}

/**
 * Ordering options when selecting data from "jira_organizations".
 */
export interface jira_organizations_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  endpoint?: order_by | null;
  jira_account?: jira_accounts_order_by | null;
  jira_accounts_aggregate?: jira_accounts_aggregate_order_by | null;
  jira_projects_aggregate?: jira_projects_aggregate_order_by | null;
  sid?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
}

/**
 * order by aggregate values of table "jira_projects"
 */
export interface jira_projects_aggregate_order_by {
  avg?: jira_projects_avg_order_by | null;
  count?: order_by | null;
  max?: jira_projects_max_order_by | null;
  min?: jira_projects_min_order_by | null;
  stddev?: jira_projects_stddev_order_by | null;
  stddev_pop?: jira_projects_stddev_pop_order_by | null;
  stddev_samp?: jira_projects_stddev_samp_order_by | null;
  sum?: jira_projects_sum_order_by | null;
  var_pop?: jira_projects_var_pop_order_by | null;
  var_samp?: jira_projects_var_samp_order_by | null;
  variance?: jira_projects_variance_order_by | null;
}

/**
 * order by avg() on columns of table "jira_projects"
 */
export interface jira_projects_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "jira_projects". All fields are combined with a logical 'AND'.
 */
export interface jira_projects_bool_exp {
  _and?: jira_projects_bool_exp[] | null;
  _not?: jira_projects_bool_exp | null;
  _or?: jira_projects_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  description?: String_comparison_exp | null;
  endpoint?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jira_organization?: jira_organizations_bool_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
}

/**
 * order by max() on columns of table "jira_projects"
 */
export interface jira_projects_max_order_by {
  description?: order_by | null;
  endpoint?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by min() on columns of table "jira_projects"
 */
export interface jira_projects_min_order_by {
  description?: order_by | null;
  endpoint?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  sid?: order_by | null;
}

/**
 * Ordering options when selecting data from "jira_projects".
 */
export interface jira_projects_order_by {
  client_project?: client_projects_order_by | null;
  description?: order_by | null;
  endpoint?: order_by | null;
  id?: order_by | null;
  jira_organization?: jira_organizations_order_by | null;
  key?: order_by | null;
  name?: order_by | null;
  sid?: order_by | null;
  syncable_entity?: syncable_entities_order_by | null;
  ticket_source?: ticket_sources_order_by | null;
  ticket_sources_aggregate?: ticket_sources_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "jira_projects"
 */
export interface jira_projects_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "jira_projects"
 */
export interface jira_projects_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "jira_projects"
 */
export interface jira_projects_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "jira_projects"
 */
export interface jira_projects_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "jira_projects"
 */
export interface jira_projects_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "jira_projects"
 */
export interface jira_projects_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "jira_projects"
 */
export interface jira_projects_variance_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "job_intervals_enum". All fields are combined with logical 'AND'.
 */
export interface job_intervals_enum_comparison_exp {
  _eq?: job_intervals_enum | null;
  _in?: job_intervals_enum[] | null;
  _is_null?: boolean | null;
  _neq?: job_intervals_enum | null;
  _nin?: job_intervals_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _contained_in?: hasura_jsonb | null;
  _contains?: hasura_jsonb | null;
  _eq?: hasura_jsonb | null;
  _gt?: hasura_jsonb | null;
  _gte?: hasura_jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: hasura_jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_jsonb | null;
  _lte?: hasura_jsonb | null;
  _neq?: hasura_jsonb | null;
  _nin?: hasura_jsonb[] | null;
}

/**
 * order by aggregate values of table "meetings"
 */
export interface meetings_aggregate_order_by {
  avg?: meetings_avg_order_by | null;
  count?: order_by | null;
  max?: meetings_max_order_by | null;
  min?: meetings_min_order_by | null;
  stddev?: meetings_stddev_order_by | null;
  stddev_pop?: meetings_stddev_pop_order_by | null;
  stddev_samp?: meetings_stddev_samp_order_by | null;
  sum?: meetings_sum_order_by | null;
  var_pop?: meetings_var_pop_order_by | null;
  var_samp?: meetings_var_samp_order_by | null;
  variance?: meetings_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "meetings"
 */
export interface meetings_arr_rel_insert_input {
  data: meetings_insert_input[];
  on_conflict?: meetings_on_conflict | null;
}

/**
 * order by avg() on columns of table "meetings"
 */
export interface meetings_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "meetings". All fields are combined with a logical 'AND'.
 */
export interface meetings_bool_exp {
  _and?: meetings_bool_exp[] | null;
  _not?: meetings_bool_exp | null;
  _or?: meetings_bool_exp[] | null;
  associatedClientId?: String_comparison_exp | null;
  calendlyMeetingId?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  scheduledAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "meetings"
 */
export interface meetings_insert_input {
  associatedClientId?: string | null;
  calendlyMeetingId?: string | null;
  client?: clients_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  id?: number | null;
  scheduledAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeetingSid?: string | null;
}

/**
 * order by max() on columns of table "meetings"
 */
export interface meetings_max_order_by {
  associatedClientId?: order_by | null;
  calendlyMeetingId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  scheduledAt?: order_by | null;
  updatedAt?: order_by | null;
  zoomMeetingSid?: order_by | null;
}

/**
 * order by min() on columns of table "meetings"
 */
export interface meetings_min_order_by {
  associatedClientId?: order_by | null;
  calendlyMeetingId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  scheduledAt?: order_by | null;
  updatedAt?: order_by | null;
  zoomMeetingSid?: order_by | null;
}

/**
 * on_conflict condition type for table "meetings"
 */
export interface meetings_on_conflict {
  constraint: meetings_constraint;
  update_columns: meetings_update_column[];
  where?: meetings_bool_exp | null;
}

/**
 * order by stddev() on columns of table "meetings"
 */
export interface meetings_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "meetings"
 */
export interface meetings_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "meetings"
 */
export interface meetings_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "meetings"
 */
export interface meetings_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "meetings"
 */
export interface meetings_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "meetings"
 */
export interface meetings_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "meetings"
 */
export interface meetings_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "mentorships"
 */
export interface mentorships_aggregate_order_by {
  avg?: mentorships_avg_order_by | null;
  count?: order_by | null;
  max?: mentorships_max_order_by | null;
  min?: mentorships_min_order_by | null;
  stddev?: mentorships_stddev_order_by | null;
  stddev_pop?: mentorships_stddev_pop_order_by | null;
  stddev_samp?: mentorships_stddev_samp_order_by | null;
  sum?: mentorships_sum_order_by | null;
  var_pop?: mentorships_var_pop_order_by | null;
  var_samp?: mentorships_var_samp_order_by | null;
  variance?: mentorships_variance_order_by | null;
}

/**
 * order by avg() on columns of table "mentorships"
 */
export interface mentorships_avg_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "mentorships". All fields are combined with a logical 'AND'.
 */
export interface mentorships_bool_exp {
  _and?: mentorships_bool_exp[] | null;
  _not?: mentorships_bool_exp | null;
  _or?: mentorships_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  mentee?: users_bool_exp | null;
  menteeUserId?: Int_comparison_exp | null;
  mentor?: users_bool_exp | null;
  mentorUserId?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "mentorships"
 */
export interface mentorships_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  endDate?: order_by | null;
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
  startDate?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "mentorships"
 */
export interface mentorships_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  endDate?: order_by | null;
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
  startDate?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "mentorships"
 */
export interface mentorships_stddev_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "mentorships"
 */
export interface mentorships_stddev_pop_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "mentorships"
 */
export interface mentorships_stddev_samp_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by sum() on columns of table "mentorships"
 */
export interface mentorships_sum_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "mentorships"
 */
export interface mentorships_var_pop_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "mentorships"
 */
export interface mentorships_var_samp_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by variance() on columns of table "mentorships"
 */
export interface mentorships_variance_order_by {
  id?: order_by | null;
  menteeUserId?: order_by | null;
  mentorUserId?: order_by | null;
}

/**
 * order by aggregate values of table "notifications"
 */
export interface notifications_aggregate_order_by {
  avg?: notifications_avg_order_by | null;
  count?: order_by | null;
  max?: notifications_max_order_by | null;
  min?: notifications_min_order_by | null;
  stddev?: notifications_stddev_order_by | null;
  stddev_pop?: notifications_stddev_pop_order_by | null;
  stddev_samp?: notifications_stddev_samp_order_by | null;
  sum?: notifications_sum_order_by | null;
  var_pop?: notifications_var_pop_order_by | null;
  var_samp?: notifications_var_samp_order_by | null;
  variance?: notifications_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "notifications"
 */
export interface notifications_arr_rel_insert_input {
  data: notifications_insert_input[];
  on_conflict?: notifications_on_conflict | null;
}

/**
 * order by avg() on columns of table "notifications"
 */
export interface notifications_avg_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'.
 */
export interface notifications_bool_exp {
  _and?: notifications_bool_exp[] | null;
  _not?: notifications_bool_exp | null;
  _or?: notifications_bool_exp[] | null;
  appLink?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isSeen?: Boolean_comparison_exp | null;
  projectId?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting data into table "notifications"
 */
export interface notifications_insert_input {
  appLink?: string | null;
  body?: string | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  task?: tasks_obj_rel_insert_input | null;
  teamId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "notifications"
 */
export interface notifications_max_order_by {
  appLink?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "notifications"
 */
export interface notifications_min_order_by {
  appLink?: order_by | null;
  body?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * on_conflict condition type for table "notifications"
 */
export interface notifications_on_conflict {
  constraint: notifications_constraint;
  update_columns: notifications_update_column[];
  where?: notifications_bool_exp | null;
}

/**
 * order by stddev() on columns of table "notifications"
 */
export interface notifications_stddev_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "notifications"
 */
export interface notifications_stddev_pop_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "notifications"
 */
export interface notifications_stddev_samp_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "notifications"
 */
export interface notifications_sum_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "notifications"
 */
export interface notifications_var_pop_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "notifications"
 */
export interface notifications_var_samp_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "notifications"
 */
export interface notifications_variance_order_by {
  discussionCommentId?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: hasura_numeric | null;
  _gt?: hasura_numeric | null;
  _gte?: hasura_numeric | null;
  _in?: hasura_numeric[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_numeric | null;
  _lte?: hasura_numeric | null;
  _neq?: hasura_numeric | null;
  _nin?: hasura_numeric[] | null;
}

/**
 * Boolean expression to filter rows from the table "opportunities". All fields are combined with a logical 'AND'.
 */
export interface opportunities_bool_exp {
  _and?: opportunities_bool_exp[] | null;
  _not?: opportunities_bool_exp | null;
  _or?: opportunities_bool_exp[] | null;
  availableUntil?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  user_opportunity_interests?: user_opportunity_interests_bool_exp | null;
}

/**
 * order by aggregate values of table "project_daily_standup"
 */
export interface project_daily_standup_aggregate_order_by {
  avg?: project_daily_standup_avg_order_by | null;
  count?: order_by | null;
  max?: project_daily_standup_max_order_by | null;
  min?: project_daily_standup_min_order_by | null;
  stddev?: project_daily_standup_stddev_order_by | null;
  stddev_pop?: project_daily_standup_stddev_pop_order_by | null;
  stddev_samp?: project_daily_standup_stddev_samp_order_by | null;
  sum?: project_daily_standup_sum_order_by | null;
  var_pop?: project_daily_standup_var_pop_order_by | null;
  var_samp?: project_daily_standup_var_samp_order_by | null;
  variance?: project_daily_standup_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "project_daily_standup"
 */
export interface project_daily_standup_arr_rel_insert_input {
  data: project_daily_standup_insert_input[];
  on_conflict?: project_daily_standup_on_conflict | null;
}

/**
 * order by avg() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_avg_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "project_daily_standup". All fields are combined with a logical 'AND'.
 */
export interface project_daily_standup_bool_exp {
  _and?: project_daily_standup_bool_exp[] | null;
  _not?: project_daily_standup_bool_exp | null;
  _or?: project_daily_standup_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  messageForClient?: String_comparison_exp | null;
  postedToClient?: Boolean_comparison_exp | null;
  submittedAt?: timestamptz_comparison_exp | null;
  summary?: String_comparison_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "project_daily_standup"
 */
export interface project_daily_standup_insert_input {
  clientProjectId?: number | null;
  createdAt?: hasura_timestamptz | null;
  id?: number | null;
  messageForClient?: string | null;
  postedToClient?: boolean | null;
  submittedAt?: hasura_timestamptz | null;
  summarizedInClientDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  summary?: string | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_max_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  messageForClient?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_min_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  messageForClient?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "project_daily_standup"
 */
export interface project_daily_standup_obj_rel_insert_input {
  data: project_daily_standup_insert_input;
  on_conflict?: project_daily_standup_on_conflict | null;
}

/**
 * on_conflict condition type for table "project_daily_standup"
 */
export interface project_daily_standup_on_conflict {
  constraint: project_daily_standup_constraint;
  update_columns: project_daily_standup_update_column[];
  where?: project_daily_standup_bool_exp | null;
}

/**
 * order by stddev() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_stddev_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_sum_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_var_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_var_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_variance_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "project_managers"
 */
export interface project_managers_aggregate_order_by {
  avg?: project_managers_avg_order_by | null;
  count?: order_by | null;
  max?: project_managers_max_order_by | null;
  min?: project_managers_min_order_by | null;
  stddev?: project_managers_stddev_order_by | null;
  stddev_pop?: project_managers_stddev_pop_order_by | null;
  stddev_samp?: project_managers_stddev_samp_order_by | null;
  sum?: project_managers_sum_order_by | null;
  var_pop?: project_managers_var_pop_order_by | null;
  var_samp?: project_managers_var_samp_order_by | null;
  variance?: project_managers_variance_order_by | null;
}

/**
 * order by avg() on columns of table "project_managers"
 */
export interface project_managers_avg_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "project_managers". All fields are combined with a logical 'AND'.
 */
export interface project_managers_bool_exp {
  _and?: project_managers_bool_exp[] | null;
  _not?: project_managers_bool_exp | null;
  _or?: project_managers_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  projectId?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "project_managers"
 */
export interface project_managers_max_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "project_managers"
 */
export interface project_managers_min_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "project_managers"
 */
export interface project_managers_stddev_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "project_managers"
 */
export interface project_managers_stddev_pop_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "project_managers"
 */
export interface project_managers_stddev_samp_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "project_managers"
 */
export interface project_managers_sum_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "project_managers"
 */
export interface project_managers_var_pop_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "project_managers"
 */
export interface project_managers_var_samp_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "project_managers"
 */
export interface project_managers_variance_order_by {
  projectId?: order_by | null;
  userId?: order_by | null;
}

export interface sendUserDailyStandupsInput {
  messageForTeams?: string | null;
  userDailyStandupId: number;
}

/**
 * Boolean expression to filter rows from the table "skill_relationships". All fields are combined with a logical 'AND'.
 */
export interface skill_relationships_bool_exp {
  _and?: skill_relationships_bool_exp[] | null;
  _not?: skill_relationships_bool_exp | null;
  _or?: skill_relationships_bool_exp[] | null;
  childSkill?: String_comparison_exp | null;
  parentSkill?: String_comparison_exp | null;
  skill?: skills_bool_exp | null;
  skillByChildskill?: skills_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "skills". All fields are combined with a logical 'AND'.
 */
export interface skills_bool_exp {
  _and?: skills_bool_exp[] | null;
  _not?: skills_bool_exp | null;
  _or?: skills_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  skillRelationshipsByParentskill?: skill_relationships_bool_exp | null;
  skill_relationships?: skill_relationships_bool_exp | null;
  technology?: technologies_bool_exp | null;
  type?: String_comparison_exp | null;
  user_skills?: user_skills_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "syncable_entities". All fields are combined with a logical 'AND'.
 */
export interface syncable_entities_bool_exp {
  _and?: syncable_entities_bool_exp[] | null;
  _not?: syncable_entities_bool_exp | null;
  _or?: syncable_entities_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  createdAtSource?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentsBySyncsidSynctablename?: discussion_comments_bool_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  discussion_sources?: discussion_sources_bool_exp | null;
  etag?: String_comparison_exp | null;
  expiresInType?: job_intervals_enum_comparison_exp | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  gcp_storage_objects?: gcp_storage_objects_bool_exp | null;
  git_branch?: git_branches_bool_exp | null;
  git_branches?: git_branches_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  git_organizations?: git_organizations_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  github_branch?: github_branches_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_organizations?: github_organizations_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  github_repos?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isForked?: Boolean_comparison_exp | null;
  isOutdated?: Boolean_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_accounts?: jira_accounts_bool_exp | null;
  jira_organizations?: jira_organizations_bool_exp | null;
  jira_projects?: jira_projects_bool_exp | null;
  redundantFields?: jsonb_comparison_exp | null;
  shallow?: Boolean_comparison_exp | null;
  shouldStartSyncAt?: timestamptz_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sourceUrl?: String_comparison_exp | null;
  syncStartedAt?: timestamptz_comparison_exp | null;
  syncTriggeredAt?: timestamptz_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  tableName?: syncable_entity_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedAtSource?: timestamptz_comparison_exp | null;
  weight?: float8_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "syncable_entities".
 */
export interface syncable_entities_order_by {
  createdAt?: order_by | null;
  createdAtSource?: order_by | null;
  deletedAt?: order_by | null;
  discussionCommentsBySyncsidSynctablename_aggregate?: discussion_comments_aggregate_order_by | null;
  discussion_comments_aggregate?: discussion_comments_aggregate_order_by | null;
  discussion_sources_aggregate?: discussion_sources_aggregate_order_by | null;
  etag?: order_by | null;
  expiresInType?: order_by | null;
  gcp_storage_object?: gcp_storage_objects_order_by | null;
  gcp_storage_objects_aggregate?: gcp_storage_objects_aggregate_order_by | null;
  git_branch?: git_branches_order_by | null;
  git_branches_aggregate?: git_branches_aggregate_order_by | null;
  git_commit?: git_commits_order_by | null;
  git_commits_aggregate?: git_commits_aggregate_order_by | null;
  git_organization?: git_organizations_order_by | null;
  git_organizations_aggregate?: git_organizations_aggregate_order_by | null;
  git_pull_request?: git_pull_requests_order_by | null;
  git_pull_request_review?: git_pull_request_reviews_order_by | null;
  git_pull_request_reviews_aggregate?: git_pull_request_reviews_aggregate_order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_repo?: git_repos_order_by | null;
  git_repos_aggregate?: git_repos_aggregate_order_by | null;
  git_user?: git_users_order_by | null;
  git_users_aggregate?: git_users_aggregate_order_by | null;
  github_branch?: github_branches_order_by | null;
  github_branches_aggregate?: github_branches_aggregate_order_by | null;
  github_organization?: github_organizations_order_by | null;
  github_organizations_aggregate?: github_organizations_aggregate_order_by | null;
  github_repo?: github_repos_order_by | null;
  github_repos_aggregate?: github_repos_aggregate_order_by | null;
  id?: order_by | null;
  isForked?: order_by | null;
  isOutdated?: order_by | null;
  jira_account?: jira_accounts_order_by | null;
  jira_accounts_aggregate?: jira_accounts_aggregate_order_by | null;
  jira_organizations_aggregate?: jira_organizations_aggregate_order_by | null;
  jira_projects_aggregate?: jira_projects_aggregate_order_by | null;
  redundantFields?: order_by | null;
  shallow?: order_by | null;
  shouldStartSyncAt?: order_by | null;
  sid?: order_by | null;
  sourceUrl?: order_by | null;
  syncStartedAt?: order_by | null;
  syncTriggeredAt?: order_by | null;
  syncedAt?: order_by | null;
  tableName?: order_by | null;
  updatedAt?: order_by | null;
  updatedAtSource?: order_by | null;
  weight?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "syncable_entity_types_enum". All fields are combined with logical 'AND'.
 */
export interface syncable_entity_types_enum_comparison_exp {
  _eq?: syncable_entity_types_enum | null;
  _in?: syncable_entity_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: syncable_entity_types_enum | null;
  _nin?: syncable_entity_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "task_attributes". All fields are combined with a logical 'AND'.
 */
export interface task_attributes_bool_exp {
  _and?: task_attributes_bool_exp[] | null;
  _not?: task_attributes_bool_exp | null;
  _or?: task_attributes_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  remainingDuplicateLimit?: bigint_comparison_exp | null;
  timeBasedDeveloperCost?: bigint_comparison_exp | null;
  timeSpentByDeveloperInSecs?: bigint_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "task_attributes".
 */
export interface task_attributes_order_by {
  id?: order_by | null;
  remainingDuplicateLimit?: order_by | null;
  timeBasedDeveloperCost?: order_by | null;
  timeSpentByDeveloperInSecs?: order_by | null;
}

/**
 * order by aggregate values of table "task_changes_by_status"
 */
export interface task_changes_by_status_aggregate_order_by {
  avg?: task_changes_by_status_avg_order_by | null;
  count?: order_by | null;
  max?: task_changes_by_status_max_order_by | null;
  min?: task_changes_by_status_min_order_by | null;
  stddev?: task_changes_by_status_stddev_order_by | null;
  stddev_pop?: task_changes_by_status_stddev_pop_order_by | null;
  stddev_samp?: task_changes_by_status_stddev_samp_order_by | null;
  sum?: task_changes_by_status_sum_order_by | null;
  var_pop?: task_changes_by_status_var_pop_order_by | null;
  var_samp?: task_changes_by_status_var_samp_order_by | null;
  variance?: task_changes_by_status_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_changes_by_status". All fields are combined with a logical 'AND'.
 */
export interface task_changes_by_status_bool_exp {
  _and?: task_changes_by_status_bool_exp[] | null;
  _not?: task_changes_by_status_bool_exp | null;
  _or?: task_changes_by_status_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  transitionedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  status?: order_by | null;
  ticketCode?: order_by | null;
  transitionedAt?: order_by | null;
}

/**
 * order by min() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  status?: order_by | null;
  ticketCode?: order_by | null;
  transitionedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "task_changes_by_status"
 */
export interface task_changes_by_status_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "task_client_blockers"
 */
export interface task_client_blockers_aggregate_order_by {
  avg?: task_client_blockers_avg_order_by | null;
  count?: order_by | null;
  max?: task_client_blockers_max_order_by | null;
  min?: task_client_blockers_min_order_by | null;
  stddev?: task_client_blockers_stddev_order_by | null;
  stddev_pop?: task_client_blockers_stddev_pop_order_by | null;
  stddev_samp?: task_client_blockers_stddev_samp_order_by | null;
  sum?: task_client_blockers_sum_order_by | null;
  var_pop?: task_client_blockers_var_pop_order_by | null;
  var_samp?: task_client_blockers_var_samp_order_by | null;
  variance?: task_client_blockers_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_client_blockers"
 */
export interface task_client_blockers_arr_rel_insert_input {
  data: task_client_blockers_insert_input[];
  on_conflict?: task_client_blockers_on_conflict | null;
}

/**
 * order by avg() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_avg_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_client_blockers". All fields are combined with a logical 'AND'.
 */
export interface task_client_blockers_bool_exp {
  _and?: task_client_blockers_bool_exp[] | null;
  _not?: task_client_blockers_bool_exp | null;
  _or?: task_client_blockers_bool_exp[] | null;
  clientBlockersId?: Int_comparison_exp | null;
  client_blocker?: client_blockers_bool_exp | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_client_blockers"
 */
export interface task_client_blockers_insert_input {
  clientBlockersId?: number | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  task?: tasks_obj_rel_insert_input | null;
  tasksId?: number | null;
}

/**
 * order by max() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_max_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by min() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_min_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * on_conflict condition type for table "task_client_blockers"
 */
export interface task_client_blockers_on_conflict {
  constraint: task_client_blockers_constraint;
  update_columns: task_client_blockers_update_column[];
  where?: task_client_blockers_bool_exp | null;
}

/**
 * order by stddev() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_stddev_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_stddev_pop_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_stddev_samp_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_sum_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_var_pop_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_var_samp_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_client_blockers"
 */
export interface task_client_blockers_variance_order_by {
  clientBlockersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by aggregate values of table "task_credit_approval"
 */
export interface task_credit_approval_aggregate_order_by {
  avg?: task_credit_approval_avg_order_by | null;
  count?: order_by | null;
  max?: task_credit_approval_max_order_by | null;
  min?: task_credit_approval_min_order_by | null;
  stddev?: task_credit_approval_stddev_order_by | null;
  stddev_pop?: task_credit_approval_stddev_pop_order_by | null;
  stddev_samp?: task_credit_approval_stddev_samp_order_by | null;
  sum?: task_credit_approval_sum_order_by | null;
  var_pop?: task_credit_approval_var_pop_order_by | null;
  var_samp?: task_credit_approval_var_samp_order_by | null;
  variance?: task_credit_approval_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_credit_approval"
 */
export interface task_credit_approval_arr_rel_insert_input {
  data: task_credit_approval_insert_input[];
  on_conflict?: task_credit_approval_on_conflict | null;
}

/**
 * order by avg() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_avg_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_credit_approval". All fields are combined with a logical 'AND'.
 */
export interface task_credit_approval_bool_exp {
  _and?: task_credit_approval_bool_exp[] | null;
  _not?: task_credit_approval_bool_exp | null;
  _or?: task_credit_approval_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUser?: users_bool_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUser?: users_bool_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  status?: task_credit_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticketCreditApprovalId?: Int_comparison_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_credit_approval"
 */
export interface task_credit_approval_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUser?: users_obj_rel_insert_input | null;
  requestedByUserId?: number | null;
  reviewedByUser?: users_obj_rel_insert_input | null;
  reviewedByUserId?: number | null;
  status?: task_credit_status_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  ticketCreditApprovalId?: number | null;
  ticket_credit_approval?: ticket_credit_approvals_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * order by max() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * on_conflict condition type for table "task_credit_approval"
 */
export interface task_credit_approval_on_conflict {
  constraint: task_credit_approval_constraint;
  update_columns: task_credit_approval_update_column[];
  where?: task_credit_approval_bool_exp | null;
}

/**
 * order by stddev() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_stddev_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_stddev_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_stddev_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_sum_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_var_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_var_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_variance_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_credit_overrides"
 */
export interface task_credit_overrides_arr_rel_insert_input {
  data: task_credit_overrides_insert_input[];
}

/**
 * input type for inserting data into table "task_credit_overrides"
 */
export interface task_credit_overrides_insert_input {
  appliedByUserId?: number | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  id?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * Boolean expression to compare columns of type "task_credit_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_credit_status_enum_comparison_exp {
  _eq?: task_credit_status_enum | null;
  _in?: task_credit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_credit_status_enum | null;
  _nin?: task_credit_status_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "task_daily_standup_client_view". All fields are combined with a logical 'AND'.
 */
export interface task_daily_standup_client_view_bool_exp {
  _and?: task_daily_standup_client_view_bool_exp[] | null;
  _not?: task_daily_standup_client_view_bool_exp | null;
  _or?: task_daily_standup_client_view_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  summarizedInTicketDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  task_daily_standup?: task_daily_standups_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by aggregate values of table "task_daily_standups"
 */
export interface task_daily_standups_aggregate_order_by {
  avg?: task_daily_standups_avg_order_by | null;
  count?: order_by | null;
  max?: task_daily_standups_max_order_by | null;
  min?: task_daily_standups_min_order_by | null;
  stddev?: task_daily_standups_stddev_order_by | null;
  stddev_pop?: task_daily_standups_stddev_pop_order_by | null;
  stddev_samp?: task_daily_standups_stddev_samp_order_by | null;
  sum?: task_daily_standups_sum_order_by | null;
  var_pop?: task_daily_standups_var_pop_order_by | null;
  var_samp?: task_daily_standups_var_samp_order_by | null;
  variance?: task_daily_standups_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_daily_standups"
 */
export interface task_daily_standups_arr_rel_insert_input {
  data: task_daily_standups_insert_input[];
  on_conflict?: task_daily_standups_on_conflict | null;
}

/**
 * order by avg() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_avg_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface task_daily_standups_bool_exp {
  _and?: task_daily_standups_bool_exp[] | null;
  _not?: task_daily_standups_bool_exp | null;
  _or?: task_daily_standups_bool_exp[] | null;
  client_task_daily_standup?: task_daily_standup_client_view_bool_exp | null;
  dev_task_daily_standups?: task_daily_standups_bool_exp | null;
  id?: Int_comparison_exp | null;
  submittedAt?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket_daily_standup?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedETA?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_daily_standups"
 */
export interface task_daily_standups_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dev_task_daily_standups?: task_daily_standups_obj_rel_insert_input | null;
  errorMessage?: string | null;
  erroredAt?: hasura_timestamptz | null;
  id?: number | null;
  postedToSlack?: boolean | null;
  postedToWorkplace?: boolean | null;
  submittedAt?: string | null;
  summarizedInTicketDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  summary?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  ticket_daily_standup?: ticket_daily_standups_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  updatedETA?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_max_order_by {
  id?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  taskId?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_min_order_by {
  id?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  taskId?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
  userId?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "task_daily_standups"
 */
export interface task_daily_standups_obj_rel_insert_input {
  data: task_daily_standups_insert_input;
  on_conflict?: task_daily_standups_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_daily_standups"
 */
export interface task_daily_standups_on_conflict {
  constraint: task_daily_standups_constraint;
  update_columns: task_daily_standups_update_column[];
  where?: task_daily_standups_bool_exp | null;
}

/**
 * order by stddev() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_stddev_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_stddev_pop_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_stddev_samp_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_sum_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_var_pop_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_var_samp_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_variance_order_by {
  id?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "task_dependencies"
 */
export interface task_dependencies_aggregate_order_by {
  avg?: task_dependencies_avg_order_by | null;
  count?: order_by | null;
  max?: task_dependencies_max_order_by | null;
  min?: task_dependencies_min_order_by | null;
  stddev?: task_dependencies_stddev_order_by | null;
  stddev_pop?: task_dependencies_stddev_pop_order_by | null;
  stddev_samp?: task_dependencies_stddev_samp_order_by | null;
  sum?: task_dependencies_sum_order_by | null;
  var_pop?: task_dependencies_var_pop_order_by | null;
  var_samp?: task_dependencies_var_samp_order_by | null;
  variance?: task_dependencies_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_dependencies"
 */
export interface task_dependencies_arr_rel_insert_input {
  data: task_dependencies_insert_input[];
  on_conflict?: task_dependencies_on_conflict | null;
}

/**
 * order by avg() on columns of table "task_dependencies"
 */
export interface task_dependencies_avg_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_dependencies". All fields are combined with a logical 'AND'.
 */
export interface task_dependencies_bool_exp {
  _and?: task_dependencies_bool_exp[] | null;
  _not?: task_dependencies_bool_exp | null;
  _or?: task_dependencies_bool_exp[] | null;
  childTaskId?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  parentTaskId?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskByChildTaskId?: tasks_bool_exp | null;
}

/**
 * input type for inserting data into table "task_dependencies"
 */
export interface task_dependencies_insert_input {
  childTaskId?: number | null;
  parentTaskId?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskByChildTaskId?: tasks_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "task_dependencies"
 */
export interface task_dependencies_max_order_by {
  childTaskId?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by min() on columns of table "task_dependencies"
 */
export interface task_dependencies_min_order_by {
  childTaskId?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * on_conflict condition type for table "task_dependencies"
 */
export interface task_dependencies_on_conflict {
  constraint: task_dependencies_constraint;
  update_columns: task_dependencies_update_column[];
  where?: task_dependencies_bool_exp | null;
}

/**
 * order by stddev() on columns of table "task_dependencies"
 */
export interface task_dependencies_stddev_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_dependencies"
 */
export interface task_dependencies_stddev_pop_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_dependencies"
 */
export interface task_dependencies_stddev_samp_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_dependencies"
 */
export interface task_dependencies_sum_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_dependencies"
 */
export interface task_dependencies_var_pop_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_dependencies"
 */
export interface task_dependencies_var_samp_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_dependencies"
 */
export interface task_dependencies_variance_order_by {
  childTaskId?: order_by | null;
  id?: order_by | null;
  parentTaskId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_developer_view". All fields are combined with a logical 'AND'.
 */
export interface task_developer_view_bool_exp {
  _and?: task_developer_view_bool_exp[] | null;
  _not?: task_developer_view_bool_exp | null;
  _or?: task_developer_view_bool_exp[] | null;
  developerInvoiceId?: Int_comparison_exp | null;
  duplicateOfTaskId?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  maxDuplicateLimit?: Int_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
}

/**
 * Ordering options when selecting data from "task_developer_view".
 */
export interface task_developer_view_order_by {
  developerInvoiceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  id?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  tasks?: tasks_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_review_developer_view". All fields are combined with a logical 'AND'.
 */
export interface task_review_developer_view_bool_exp {
  _and?: task_review_developer_view_bool_exp[] | null;
  _not?: task_review_developer_view_bool_exp | null;
  _or?: task_review_developer_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  reviewerInvoiceId?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "task_review_developer_view".
 */
export interface task_review_developer_view_order_by {
  id?: order_by | null;
  managerInvoiceId?: order_by | null;
  reviewerInvoiceId?: order_by | null;
}

/**
 * order by aggregate values of table "task_reviewers"
 */
export interface task_reviewers_aggregate_order_by {
  avg?: task_reviewers_avg_order_by | null;
  count?: order_by | null;
  max?: task_reviewers_max_order_by | null;
  min?: task_reviewers_min_order_by | null;
  stddev?: task_reviewers_stddev_order_by | null;
  stddev_pop?: task_reviewers_stddev_pop_order_by | null;
  stddev_samp?: task_reviewers_stddev_samp_order_by | null;
  sum?: task_reviewers_sum_order_by | null;
  var_pop?: task_reviewers_var_pop_order_by | null;
  var_samp?: task_reviewers_var_samp_order_by | null;
  variance?: task_reviewers_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_reviewers"
 */
export interface task_reviewers_arr_rel_insert_input {
  data: task_reviewers_insert_input[];
  on_conflict?: task_reviewers_on_conflict | null;
}

/**
 * order by avg() on columns of table "task_reviewers"
 */
export interface task_reviewers_avg_order_by {
  tasksId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviewers". All fields are combined with a logical 'AND'.
 */
export interface task_reviewers_bool_exp {
  _and?: task_reviewers_bool_exp[] | null;
  _not?: task_reviewers_bool_exp | null;
  _or?: task_reviewers_bool_exp[] | null;
  developer?: developers_bool_exp | null;
  developersId?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_reviewers"
 */
export interface task_reviewers_insert_input {
  developer?: developers_obj_rel_insert_input | null;
  developersId?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  tasksId?: number | null;
}

/**
 * order by max() on columns of table "task_reviewers"
 */
export interface task_reviewers_max_order_by {
  developersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by min() on columns of table "task_reviewers"
 */
export interface task_reviewers_min_order_by {
  developersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * on_conflict condition type for table "task_reviewers"
 */
export interface task_reviewers_on_conflict {
  constraint: task_reviewers_constraint;
  update_columns: task_reviewers_update_column[];
  where?: task_reviewers_bool_exp | null;
}

/**
 * order by stddev() on columns of table "task_reviewers"
 */
export interface task_reviewers_stddev_order_by {
  tasksId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_reviewers"
 */
export interface task_reviewers_stddev_pop_order_by {
  tasksId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_reviewers"
 */
export interface task_reviewers_stddev_samp_order_by {
  tasksId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_reviewers"
 */
export interface task_reviewers_sum_order_by {
  tasksId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_reviewers"
 */
export interface task_reviewers_var_pop_order_by {
  tasksId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_reviewers"
 */
export interface task_reviewers_var_samp_order_by {
  tasksId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_reviewers"
 */
export interface task_reviewers_variance_order_by {
  tasksId?: order_by | null;
}

/**
 * order by aggregate values of table "task_reviews"
 */
export interface task_reviews_aggregate_order_by {
  avg?: task_reviews_avg_order_by | null;
  count?: order_by | null;
  max?: task_reviews_max_order_by | null;
  min?: task_reviews_min_order_by | null;
  stddev?: task_reviews_stddev_order_by | null;
  stddev_pop?: task_reviews_stddev_pop_order_by | null;
  stddev_samp?: task_reviews_stddev_samp_order_by | null;
  sum?: task_reviews_sum_order_by | null;
  var_pop?: task_reviews_var_pop_order_by | null;
  var_samp?: task_reviews_var_samp_order_by | null;
  variance?: task_reviews_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_reviews"
 */
export interface task_reviews_avg_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviews". All fields are combined with a logical 'AND'.
 */
export interface task_reviews_bool_exp {
  _and?: task_reviews_bool_exp[] | null;
  _not?: task_reviews_bool_exp | null;
  _or?: task_reviews_bool_exp[] | null;
  approvesTaskId?: Int_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dev_task_reviews?: task_review_developer_view_bool_exp | null;
  developer?: developers_bool_exp | null;
  developerByDeveloperid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  id?: Int_comparison_exp | null;
  managerId?: String_comparison_exp | null;
  pullRequestReviewId?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: task_reviews_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  task_reviews_status?: task_reviews_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userInvoiceByReviewerinvoiceid?: user_invoices_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
}

/**
 * order by max() on columns of table "task_reviews"
 */
export interface task_reviews_max_order_by {
  approvesTaskId?: order_by | null;
  completedAt?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  developerId?: order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  pullRequestReviewId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "task_reviews"
 */
export interface task_reviews_min_order_by {
  approvesTaskId?: order_by | null;
  completedAt?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  developerId?: order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  pullRequestReviewId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "task_reviews".
 */
export interface task_reviews_order_by {
  approvesTaskId?: order_by | null;
  completedAt?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  dev_task_reviews?: task_review_developer_view_order_by | null;
  developer?: developers_order_by | null;
  developerByDeveloperid?: developers_order_by | null;
  developerId?: order_by | null;
  git_pull_request_review?: git_pull_request_reviews_order_by | null;
  git_pull_request_reviews_aggregate?: git_pull_request_reviews_aggregate_order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  pullRequestReviewId?: order_by | null;
  startedAt?: order_by | null;
  status?: order_by | null;
  task?: tasks_order_by | null;
  task_reviews_status?: task_reviews_status_order_by | null;
  updatedAt?: order_by | null;
  userInvoiceByReviewerinvoiceid?: user_invoices_order_by | null;
  user_invoice?: user_invoices_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviews_status". All fields are combined with a logical 'AND'.
 */
export interface task_reviews_status_bool_exp {
  _and?: task_reviews_status_bool_exp[] | null;
  _not?: task_reviews_status_bool_exp | null;
  _or?: task_reviews_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "task_reviews_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_reviews_status_enum_comparison_exp {
  _eq?: task_reviews_status_enum | null;
  _in?: task_reviews_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_reviews_status_enum | null;
  _nin?: task_reviews_status_enum[] | null;
}

/**
 * Ordering options when selecting data from "task_reviews_status".
 */
export interface task_reviews_status_order_by {
  description?: order_by | null;
  task_reviews_aggregate?: task_reviews_aggregate_order_by | null;
  type?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_reviews"
 */
export interface task_reviews_stddev_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_reviews"
 */
export interface task_reviews_stddev_pop_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_reviews"
 */
export interface task_reviews_stddev_samp_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_reviews"
 */
export interface task_reviews_sum_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_reviews"
 */
export interface task_reviews_var_pop_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_reviews"
 */
export interface task_reviews_var_samp_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_reviews"
 */
export interface task_reviews_variance_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  pullRequestReviewId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface task_standups_sla_view_bool_exp {
  _and?: task_standups_sla_view_bool_exp[] | null;
  _not?: task_standups_sla_view_bool_exp | null;
  _or?: task_standups_sla_view_bool_exp[] | null;
  dueAt?: timestamptz_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "task_standups_sla_view".
 */
export interface task_standups_sla_view_order_by {
  dueAt?: order_by | null;
  task?: tasks_order_by | null;
  taskId?: order_by | null;
}

/**
 * order by aggregate values of table "task_submissions"
 */
export interface task_submissions_aggregate_order_by {
  avg?: task_submissions_avg_order_by | null;
  count?: order_by | null;
  max?: task_submissions_max_order_by | null;
  min?: task_submissions_min_order_by | null;
  stddev?: task_submissions_stddev_order_by | null;
  stddev_pop?: task_submissions_stddev_pop_order_by | null;
  stddev_samp?: task_submissions_stddev_samp_order_by | null;
  sum?: task_submissions_sum_order_by | null;
  var_pop?: task_submissions_var_pop_order_by | null;
  var_samp?: task_submissions_var_samp_order_by | null;
  variance?: task_submissions_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_submissions"
 */
export interface task_submissions_arr_rel_insert_input {
  data: task_submissions_insert_input[];
}

/**
 * order by avg() on columns of table "task_submissions"
 */
export interface task_submissions_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_submissions". All fields are combined with a logical 'AND'.
 */
export interface task_submissions_bool_exp {
  _and?: task_submissions_bool_exp[] | null;
  _not?: task_submissions_bool_exp | null;
  _or?: task_submissions_bool_exp[] | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  id?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "task_submissions"
 */
export interface task_submissions_insert_input {
  createdAt?: hasura_timestamptz | null;
  gcpObjectId?: string | null;
  gcp_storage_object?: gcp_storage_objects_obj_rel_insert_input | null;
  id?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "task_submissions"
 */
export interface task_submissions_max_order_by {
  id?: order_by | null;
}

/**
 * order by min() on columns of table "task_submissions"
 */
export interface task_submissions_min_order_by {
  id?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_submissions"
 */
export interface task_submissions_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_submissions"
 */
export interface task_submissions_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_submissions"
 */
export interface task_submissions_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "task_submissions"
 */
export interface task_submissions_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_submissions"
 */
export interface task_submissions_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_submissions"
 */
export interface task_submissions_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "task_submissions"
 */
export interface task_submissions_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "task_technologies"
 */
export interface task_technologies_aggregate_order_by {
  avg?: task_technologies_avg_order_by | null;
  count?: order_by | null;
  max?: task_technologies_max_order_by | null;
  min?: task_technologies_min_order_by | null;
  stddev?: task_technologies_stddev_order_by | null;
  stddev_pop?: task_technologies_stddev_pop_order_by | null;
  stddev_samp?: task_technologies_stddev_samp_order_by | null;
  sum?: task_technologies_sum_order_by | null;
  var_pop?: task_technologies_var_pop_order_by | null;
  var_samp?: task_technologies_var_samp_order_by | null;
  variance?: task_technologies_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_technologies"
 */
export interface task_technologies_avg_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_technologies". All fields are combined with a logical 'AND'.
 */
export interface task_technologies_bool_exp {
  _and?: task_technologies_bool_exp[] | null;
  _not?: task_technologies_bool_exp | null;
  _or?: task_technologies_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
}

/**
 * order by max() on columns of table "task_technologies"
 */
export interface task_technologies_max_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by min() on columns of table "task_technologies"
 */
export interface task_technologies_min_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_technologies"
 */
export interface task_technologies_stddev_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_technologies"
 */
export interface task_technologies_stddev_pop_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_technologies"
 */
export interface task_technologies_stddev_samp_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_technologies"
 */
export interface task_technologies_sum_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_technologies"
 */
export interface task_technologies_var_pop_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_technologies"
 */
export interface task_technologies_var_samp_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_technologies"
 */
export interface task_technologies_variance_order_by {
  tasksId?: order_by | null;
  technologiesId?: order_by | null;
}

/**
 * order by aggregate values of table "task_time_user_totals"
 */
export interface task_time_user_totals_aggregate_order_by {
  avg?: task_time_user_totals_avg_order_by | null;
  count?: order_by | null;
  max?: task_time_user_totals_max_order_by | null;
  min?: task_time_user_totals_min_order_by | null;
  stddev?: task_time_user_totals_stddev_order_by | null;
  stddev_pop?: task_time_user_totals_stddev_pop_order_by | null;
  stddev_samp?: task_time_user_totals_stddev_samp_order_by | null;
  sum?: task_time_user_totals_sum_order_by | null;
  var_pop?: task_time_user_totals_var_pop_order_by | null;
  var_samp?: task_time_user_totals_var_samp_order_by | null;
  variance?: task_time_user_totals_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_avg_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface task_time_user_totals_bool_exp {
  _and?: task_time_user_totals_bool_exp[] | null;
  _not?: task_time_user_totals_bool_exp | null;
  _or?: task_time_user_totals_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_max_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_min_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_stddev_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_stddev_pop_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_stddev_samp_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_sum_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_var_pop_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_var_samp_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_time_user_totals"
 */
export interface task_time_user_totals_variance_order_by {
  taskId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "tasks"
 */
export interface tasks_aggregate_order_by {
  avg?: tasks_avg_order_by | null;
  count?: order_by | null;
  max?: tasks_max_order_by | null;
  min?: tasks_min_order_by | null;
  stddev?: tasks_stddev_order_by | null;
  stddev_pop?: tasks_stddev_pop_order_by | null;
  stddev_samp?: tasks_stddev_samp_order_by | null;
  sum?: tasks_sum_order_by | null;
  var_pop?: tasks_var_pop_order_by | null;
  var_samp?: tasks_var_samp_order_by | null;
  variance?: tasks_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "tasks"
 */
export interface tasks_arr_rel_insert_input {
  data: tasks_insert_input[];
  on_conflict?: tasks_on_conflict | null;
}

/**
 * order by avg() on columns of table "tasks"
 */
export interface tasks_avg_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'.
 */
export interface tasks_bool_exp {
  _and?: tasks_bool_exp[] | null;
  _not?: tasks_bool_exp | null;
  _or?: tasks_bool_exp[] | null;
  branchName?: String_comparison_exp | null;
  budget?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientCommitDate?: timestamptz_comparison_exp | null;
  clientCommitMessage?: String_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  dev_task?: task_developer_view_bool_exp | null;
  developerByDeveloperid?: developers_bool_exp | null;
  developerByManagerid?: developers_bool_exp | null;
  developerByReviewerid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  developerInvoiceId?: Int_comparison_exp | null;
  discussionSourceByTicketcodeTaskcode?: discussion_sources_bool_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussionSourcesByTaskcodeTaskticketcode?: discussion_sources_bool_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  discussion_sources?: discussion_sources_bool_exp | null;
  discussions_related?: discussions_related_to_tickets_bool_exp | null;
  duplicateOfTask?: tasks_bool_exp | null;
  duplicateOfTaskId?: Int_comparison_exp | null;
  duplicatedTasks?: tasks_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isBillable?: Boolean_comparison_exp | null;
  isParked?: Boolean_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  manager?: users_bool_exp | null;
  managerId?: String_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  maxDuplicateLimit?: Int_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  prLink?: String_comparison_exp | null;
  reviewerId?: String_comparison_exp | null;
  slicedPullRequestId?: Int_comparison_exp | null;
  specLink?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: tasks_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  taskCode?: String_comparison_exp | null;
  taskDependenciesByChildTaskId?: task_dependencies_bool_exp | null;
  task_attributes?: task_attributes_bool_exp | null;
  task_changes_by_status?: task_changes_by_status_bool_exp | null;
  task_client_blockers?: task_client_blockers_bool_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  task_dependencies?: task_dependencies_bool_exp | null;
  task_reviewers?: task_reviewers_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  task_standups_sla?: task_standups_sla_view_bool_exp | null;
  task_status?: tasks_status_bool_exp | null;
  task_submissions?: task_submissions_bool_exp | null;
  task_technologies?: task_technologies_bool_exp | null;
  task_time_user_totals?: task_time_user_totals_bool_exp | null;
  task_type?: tasks_type_bool_exp | null;
  tasks_status?: tasks_status_bool_exp | null;
  tasks_type?: tasks_type_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  timebased?: Boolean_comparison_exp | null;
  title?: String_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  type?: tasks_type_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  userInvoiceByDeveloperinvoiceid?: user_invoices_bool_exp | null;
  user_events?: user_events_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "tasks"
 */
export interface tasks_insert_input {
  branchName?: string | null;
  budget?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientCommitDate?: hasura_timestamptz | null;
  clientCommitMessage?: string | null;
  clientId?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  developerByDeveloperid?: developers_obj_rel_insert_input | null;
  developerByManagerid?: developers_obj_rel_insert_input | null;
  developerByReviewerid?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  discussionSourceByTicketcodeTaskcode?: discussion_sources_obj_rel_insert_input | null;
  discussionSourcesByTaskcodeTaskticketcode?: discussion_sources_arr_rel_insert_input | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  discussion_sources?: discussion_sources_arr_rel_insert_input | null;
  duplicateOfTask?: tasks_obj_rel_insert_input | null;
  duplicateOfTaskId?: number | null;
  duplicatedTasks?: tasks_arr_rel_insert_input | null;
  gitRepoId?: number | null;
  id?: number | null;
  isBillable?: boolean | null;
  lowerBudget?: number | null;
  manager?: users_obj_rel_insert_input | null;
  managerId?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
  prLink?: string | null;
  reviewerId?: string | null;
  slicedPullRequestId?: number | null;
  specLink?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: tasks_status_enum | null;
  taskCode?: string | null;
  taskDependenciesByChildTaskId?: task_dependencies_arr_rel_insert_input | null;
  task_client_blockers?: task_client_blockers_arr_rel_insert_input | null;
  task_credit_approvals?: task_credit_approval_arr_rel_insert_input | null;
  task_credit_overrides?: task_credit_overrides_arr_rel_insert_input | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  task_dependencies?: task_dependencies_arr_rel_insert_input | null;
  task_reviewers?: task_reviewers_arr_rel_insert_input | null;
  task_submissions?: task_submissions_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  title?: string | null;
  type?: tasks_type_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "tasks"
 */
export interface tasks_max_order_by {
  branchName?: order_by | null;
  budget?: order_by | null;
  clientCommitDate?: order_by | null;
  clientCommitMessage?: order_by | null;
  clientId?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  developerId?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  prLink?: order_by | null;
  reviewerId?: order_by | null;
  slicedPullRequestId?: order_by | null;
  specLink?: order_by | null;
  startedAt?: order_by | null;
  statusUpdateReason?: order_by | null;
  taskCode?: order_by | null;
  ticketCode?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "tasks"
 */
export interface tasks_min_order_by {
  branchName?: order_by | null;
  budget?: order_by | null;
  clientCommitDate?: order_by | null;
  clientCommitMessage?: order_by | null;
  clientId?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  developerId?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  prLink?: order_by | null;
  reviewerId?: order_by | null;
  slicedPullRequestId?: order_by | null;
  specLink?: order_by | null;
  startedAt?: order_by | null;
  statusUpdateReason?: order_by | null;
  taskCode?: order_by | null;
  ticketCode?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "tasks"
 */
export interface tasks_obj_rel_insert_input {
  data: tasks_insert_input;
  on_conflict?: tasks_on_conflict | null;
}

/**
 * on_conflict condition type for table "tasks"
 */
export interface tasks_on_conflict {
  constraint: tasks_constraint;
  update_columns: tasks_update_column[];
  where?: tasks_bool_exp | null;
}

/**
 * Ordering options when selecting data from "tasks".
 */
export interface tasks_order_by {
  branchName?: order_by | null;
  budget?: order_by | null;
  client?: clients_order_by | null;
  clientCommitDate?: order_by | null;
  clientCommitMessage?: order_by | null;
  clientId?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  descriptionType?: order_by | null;
  description_type?: description_types_order_by | null;
  dev_task?: task_developer_view_order_by | null;
  developerByDeveloperid?: developers_order_by | null;
  developerByManagerid?: developers_order_by | null;
  developerByReviewerid?: developers_order_by | null;
  developerId?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceByTicketcodeTaskcode?: discussion_sources_order_by | null;
  discussionSourceId?: order_by | null;
  discussionSourcesByTaskcodeTaskticketcode_aggregate?: discussion_sources_aggregate_order_by | null;
  discussion_source?: discussion_sources_order_by | null;
  discussion_sources_aggregate?: discussion_sources_aggregate_order_by | null;
  discussions_related_aggregate?: discussions_related_to_tickets_aggregate_order_by | null;
  duplicateOfTask?: tasks_order_by | null;
  duplicateOfTaskId?: order_by | null;
  duplicatedTasks_aggregate?: tasks_aggregate_order_by | null;
  gitRepoId?: order_by | null;
  git_commits_aggregate?: git_commits_aggregate_order_by | null;
  git_pull_request?: git_pull_requests_order_by | null;
  git_repo?: git_repos_order_by | null;
  id?: order_by | null;
  isBillable?: order_by | null;
  isParked?: order_by | null;
  lowerBudget?: order_by | null;
  manager?: users_order_by | null;
  managerId?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  notifications_aggregate?: notifications_aggregate_order_by | null;
  prLink?: order_by | null;
  reviewerId?: order_by | null;
  slicedPullRequestId?: order_by | null;
  specLink?: order_by | null;
  startedAt?: order_by | null;
  status?: order_by | null;
  statusUpdateReason?: order_by | null;
  taskCode?: order_by | null;
  taskDependenciesByChildTaskId_aggregate?: task_dependencies_aggregate_order_by | null;
  task_attributes?: task_attributes_order_by | null;
  task_changes_by_status_aggregate?: task_changes_by_status_aggregate_order_by | null;
  task_client_blockers_aggregate?: task_client_blockers_aggregate_order_by | null;
  task_credit_approvals_aggregate?: task_credit_approval_aggregate_order_by | null;
  task_daily_standups_aggregate?: task_daily_standups_aggregate_order_by | null;
  task_dependencies_aggregate?: task_dependencies_aggregate_order_by | null;
  task_reviewers_aggregate?: task_reviewers_aggregate_order_by | null;
  task_reviews_aggregate?: task_reviews_aggregate_order_by | null;
  task_standups_sla?: task_standups_sla_view_order_by | null;
  task_status?: tasks_status_order_by | null;
  task_submissions_aggregate?: task_submissions_aggregate_order_by | null;
  task_technologies_aggregate?: task_technologies_aggregate_order_by | null;
  task_time_user_totals_aggregate?: task_time_user_totals_aggregate_order_by | null;
  task_type?: tasks_type_order_by | null;
  tasks_status?: tasks_status_order_by | null;
  tasks_type?: tasks_type_order_by | null;
  ticket?: tickets_order_by | null;
  ticketCode?: order_by | null;
  timebased?: order_by | null;
  title?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  type?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
  userInvoiceByDeveloperinvoiceid?: user_invoices_order_by | null;
  user_events_aggregate?: user_events_aggregate_order_by | null;
  user_invoice?: user_invoices_order_by | null;
  user_time_logs_aggregate?: user_time_logs_view_aggregate_order_by | null;
  user_work_logs_aggregate?: user_work_logs_aggregate_order_by | null;
}

/**
 * input type for updating data in table "tasks"
 */
export interface tasks_set_input {
  branchName?: string | null;
  budget?: number | null;
  clientCommitDate?: hasura_timestamptz | null;
  clientCommitMessage?: string | null;
  clientId?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  developerId?: string | null;
  gitRepoId?: number | null;
  id?: number | null;
  isBillable?: boolean | null;
  lowerBudget?: number | null;
  managerId?: string | null;
  prLink?: string | null;
  reviewerId?: string | null;
  slicedPullRequestId?: number | null;
  specLink?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: tasks_status_enum | null;
  taskCode?: string | null;
  ticketCode?: string | null;
  title?: string | null;
  type?: tasks_type_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * Boolean expression to filter rows from the table "tasks_status". All fields are combined with a logical 'AND'.
 */
export interface tasks_status_bool_exp {
  _and?: tasks_status_bool_exp[] | null;
  _not?: tasks_status_bool_exp | null;
  _or?: tasks_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "tasks_status_enum". All fields are combined with logical 'AND'.
 */
export interface tasks_status_enum_comparison_exp {
  _eq?: tasks_status_enum | null;
  _in?: tasks_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tasks_status_enum | null;
  _nin?: tasks_status_enum[] | null;
}

/**
 * Ordering options when selecting data from "tasks_status".
 */
export interface tasks_status_order_by {
  description?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  type?: order_by | null;
}

/**
 * order by stddev() on columns of table "tasks"
 */
export interface tasks_stddev_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "tasks"
 */
export interface tasks_stddev_pop_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "tasks"
 */
export interface tasks_stddev_samp_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "tasks"
 */
export interface tasks_sum_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tasks_type". All fields are combined with a logical 'AND'.
 */
export interface tasks_type_bool_exp {
  _and?: tasks_type_bool_exp[] | null;
  _not?: tasks_type_bool_exp | null;
  _or?: tasks_type_bool_exp[] | null;
  description?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "tasks_type_enum". All fields are combined with logical 'AND'.
 */
export interface tasks_type_enum_comparison_exp {
  _eq?: tasks_type_enum | null;
  _in?: tasks_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tasks_type_enum | null;
  _nin?: tasks_type_enum[] | null;
}

/**
 * Ordering options when selecting data from "tasks_type".
 */
export interface tasks_type_order_by {
  description?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  type?: order_by | null;
}

/**
 * order by var_pop() on columns of table "tasks"
 */
export interface tasks_var_pop_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "tasks"
 */
export interface tasks_var_samp_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "tasks"
 */
export interface tasks_variance_order_by {
  budget?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  developerInvoiceId?: order_by | null;
  discussionSourceId?: order_by | null;
  duplicateOfTaskId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerInvoiceId?: order_by | null;
  maxDuplicateLimit?: order_by | null;
  slicedPullRequestId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "technologies". All fields are combined with a logical 'AND'.
 */
export interface technologies_bool_exp {
  _and?: technologies_bool_exp[] | null;
  _not?: technologies_bool_exp | null;
  _or?: technologies_bool_exp[] | null;
  client_project_technologies?: client_project_technologies_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  skills?: skills_bool_exp | null;
  task_technologies?: task_technologies_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * order by aggregate values of table "ticket_attachments"
 */
export interface ticket_attachments_aggregate_order_by {
  avg?: ticket_attachments_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_attachments_max_order_by | null;
  min?: ticket_attachments_min_order_by | null;
  stddev?: ticket_attachments_stddev_order_by | null;
  stddev_pop?: ticket_attachments_stddev_pop_order_by | null;
  stddev_samp?: ticket_attachments_stddev_samp_order_by | null;
  sum?: ticket_attachments_sum_order_by | null;
  var_pop?: ticket_attachments_var_pop_order_by | null;
  var_samp?: ticket_attachments_var_samp_order_by | null;
  variance?: ticket_attachments_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_attachments"
 */
export interface ticket_attachments_arr_rel_insert_input {
  data: ticket_attachments_insert_input[];
  on_conflict?: ticket_attachments_on_conflict | null;
}

/**
 * order by avg() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_avg_order_by {
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_attachments". All fields are combined with a logical 'AND'.
 */
export interface ticket_attachments_bool_exp {
  _and?: ticket_attachments_bool_exp[] | null;
  _not?: ticket_attachments_bool_exp | null;
  _or?: ticket_attachments_bool_exp[] | null;
  gcpObjectId?: String_comparison_exp | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_attachments"
 */
export interface ticket_attachments_insert_input {
  gcpObjectId?: string | null;
  gcp_storage_object?: gcp_storage_objects_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
}

/**
 * order by max() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_max_order_by {
  gcpObjectId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_min_order_by {
  gcpObjectId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * on_conflict condition type for table "ticket_attachments"
 */
export interface ticket_attachments_on_conflict {
  constraint: ticket_attachments_constraint;
  update_columns: ticket_attachments_update_column[];
  where?: ticket_attachments_bool_exp | null;
}

/**
 * order by stddev() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_stddev_order_by {
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_stddev_pop_order_by {
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_stddev_samp_order_by {
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_sum_order_by {
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_var_pop_order_by {
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_var_samp_order_by {
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_attachments"
 */
export interface ticket_attachments_variance_order_by {
  ticketId?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_aggregate_order_by {
  avg?: ticket_billable_overrides_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_billable_overrides_max_order_by | null;
  min?: ticket_billable_overrides_min_order_by | null;
  stddev?: ticket_billable_overrides_stddev_order_by | null;
  stddev_pop?: ticket_billable_overrides_stddev_pop_order_by | null;
  stddev_samp?: ticket_billable_overrides_stddev_samp_order_by | null;
  sum?: ticket_billable_overrides_sum_order_by | null;
  var_pop?: ticket_billable_overrides_var_pop_order_by | null;
  var_samp?: ticket_billable_overrides_var_samp_order_by | null;
  variance?: ticket_billable_overrides_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_arr_rel_insert_input {
  data: ticket_billable_overrides_insert_input[];
}

/**
 * order by avg() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_avg_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_billable_overrides". All fields are combined with a logical 'AND'.
 */
export interface ticket_billable_overrides_bool_exp {
  _and?: ticket_billable_overrides_bool_exp[] | null;
  _not?: ticket_billable_overrides_bool_exp | null;
  _or?: ticket_billable_overrides_bool_exp[] | null;
  billableAt?: timestamptz_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_insert_input {
  billableAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  reason?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_max_order_by {
  billableAt?: order_by | null;
  changedByUserId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  reason?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_min_order_by {
  billableAt?: order_by | null;
  changedByUserId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  reason?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_stddev_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_stddev_pop_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_stddev_samp_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_sum_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_var_pop_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_var_samp_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_variance_order_by {
  changedByUserId?: order_by | null;
  id?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_aggregate_order_by {
  avg?: ticket_changes_by_status_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_changes_by_status_max_order_by | null;
  min?: ticket_changes_by_status_min_order_by | null;
  stddev?: ticket_changes_by_status_stddev_order_by | null;
  stddev_pop?: ticket_changes_by_status_stddev_pop_order_by | null;
  stddev_samp?: ticket_changes_by_status_stddev_samp_order_by | null;
  sum?: ticket_changes_by_status_sum_order_by | null;
  var_pop?: ticket_changes_by_status_var_pop_order_by | null;
  var_samp?: ticket_changes_by_status_var_samp_order_by | null;
  variance?: ticket_changes_by_status_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_changes_by_status". All fields are combined with a logical 'AND'.
 */
export interface ticket_changes_by_status_bool_exp {
  _and?: ticket_changes_by_status_bool_exp[] | null;
  _not?: ticket_changes_by_status_bool_exp | null;
  _or?: ticket_changes_by_status_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  code?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  transitionedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_max_order_by {
  clientId?: order_by | null;
  code?: order_by | null;
  id?: order_by | null;
  status?: order_by | null;
  transitionedAt?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_min_order_by {
  clientId?: order_by | null;
  code?: order_by | null;
  id?: order_by | null;
  status?: order_by | null;
  transitionedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_client_blockers"
 */
export interface ticket_client_blockers_aggregate_order_by {
  count?: order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_client_blockers"
 */
export interface ticket_client_blockers_arr_rel_insert_input {
  data: ticket_client_blockers_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "ticket_client_blockers". All fields are combined with a logical 'AND'.
 */
export interface ticket_client_blockers_bool_exp {
  _and?: ticket_client_blockers_bool_exp[] | null;
  _not?: ticket_client_blockers_bool_exp | null;
  _or?: ticket_client_blockers_bool_exp[] | null;
  client_blocker?: client_blockers_bool_exp | null;
  ticket?: tickets_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_client_blockers"
 */
export interface ticket_client_blockers_insert_input {
  clientBlockersId?: number | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketsId?: number | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_client_view". All fields are combined with a logical 'AND'.
 */
export interface ticket_client_view_bool_exp {
  _and?: ticket_client_view_bool_exp[] | null;
  _not?: ticket_client_view_bool_exp | null;
  _or?: ticket_client_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "ticket_client_view".
 */
export interface ticket_client_view_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  tickets?: tickets_order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_aggregate_order_by {
  avg?: ticket_credit_approvals_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_credit_approvals_max_order_by | null;
  min?: ticket_credit_approvals_min_order_by | null;
  stddev?: ticket_credit_approvals_stddev_order_by | null;
  stddev_pop?: ticket_credit_approvals_stddev_pop_order_by | null;
  stddev_samp?: ticket_credit_approvals_stddev_samp_order_by | null;
  sum?: ticket_credit_approvals_sum_order_by | null;
  var_pop?: ticket_credit_approvals_var_pop_order_by | null;
  var_samp?: ticket_credit_approvals_var_samp_order_by | null;
  variance?: ticket_credit_approvals_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_arr_rel_insert_input {
  data: ticket_credit_approvals_insert_input[];
  on_conflict?: ticket_credit_approvals_on_conflict | null;
}

/**
 * order by avg() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_avg_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approvals". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approvals_bool_exp {
  _and?: ticket_credit_approvals_bool_exp[] | null;
  _not?: ticket_credit_approvals_bool_exp | null;
  _or?: ticket_credit_approvals_bool_exp[] | null;
  applicant?: users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  reviewer?: users_bool_exp | null;
  status?: ticket_credit_status_enum_comparison_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_insert_input {
  applicant?: users_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  reviewer?: users_obj_rel_insert_input | null;
  status?: ticket_credit_status_enum | null;
  task_credit_approvals?: task_credit_approval_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * order by max() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_obj_rel_insert_input {
  data: ticket_credit_approvals_insert_input;
  on_conflict?: ticket_credit_approvals_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_on_conflict {
  constraint: ticket_credit_approvals_constraint;
  update_columns: ticket_credit_approvals_update_column[];
  where?: ticket_credit_approvals_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approvals_parsed". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approvals_parsed_bool_exp {
  _and?: ticket_credit_approvals_parsed_bool_exp[] | null;
  _not?: ticket_credit_approvals_parsed_bool_exp | null;
  _or?: ticket_credit_approvals_parsed_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  need_approval?: Boolean_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "ticket_credit_approvals_parsed".
 */
export interface ticket_credit_approvals_parsed_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  need_approval?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  status?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for updating data in table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_set_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  status?: ticket_credit_status_enum | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * order by stddev() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_stddev_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_stddev_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_stddev_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_sum_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_var_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_var_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_variance_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "ticket_credit_status_enum". All fields are combined with logical 'AND'.
 */
export interface ticket_credit_status_enum_comparison_exp {
  _eq?: ticket_credit_status_enum | null;
  _in?: ticket_credit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: ticket_credit_status_enum | null;
  _nin?: ticket_credit_status_enum[] | null;
}

/**
 * order by aggregate values of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_aggregate_order_by {
  avg?: ticket_daily_standups_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_daily_standups_max_order_by | null;
  min?: ticket_daily_standups_min_order_by | null;
  stddev?: ticket_daily_standups_stddev_order_by | null;
  stddev_pop?: ticket_daily_standups_stddev_pop_order_by | null;
  stddev_samp?: ticket_daily_standups_stddev_samp_order_by | null;
  sum?: ticket_daily_standups_sum_order_by | null;
  var_pop?: ticket_daily_standups_var_pop_order_by | null;
  var_samp?: ticket_daily_standups_var_samp_order_by | null;
  variance?: ticket_daily_standups_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_daily_standups"
 */
export interface ticket_daily_standups_arr_rel_insert_input {
  data: ticket_daily_standups_insert_input[];
  on_conflict?: ticket_daily_standups_on_conflict | null;
}

/**
 * order by avg() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_avg_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface ticket_daily_standups_bool_exp {
  _and?: ticket_daily_standups_bool_exp[] | null;
  _not?: ticket_daily_standups_bool_exp | null;
  _or?: ticket_daily_standups_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  message?: String_comparison_exp | null;
  project_daily_standup?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  summarizedInClientDailyStandupId?: Int_comparison_exp | null;
  summarizedInProjectDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedETA?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_daily_standups"
 */
export interface ticket_daily_standups_insert_input {
  createdAt?: hasura_timestamptz | null;
  id?: number | null;
  message?: string | null;
  project_daily_standup?: project_daily_standup_obj_rel_insert_input | null;
  submittedAt?: string | null;
  summarizedInClientDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  updatedETA?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_max_order_by {
  createdAt?: order_by | null;
  id?: order_by | null;
  message?: order_by | null;
  submittedAt?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_min_order_by {
  createdAt?: order_by | null;
  id?: order_by | null;
  message?: order_by | null;
  submittedAt?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
  userId?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "ticket_daily_standups"
 */
export interface ticket_daily_standups_obj_rel_insert_input {
  data: ticket_daily_standups_insert_input;
  on_conflict?: ticket_daily_standups_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_daily_standups"
 */
export interface ticket_daily_standups_on_conflict {
  constraint: ticket_daily_standups_constraint;
  update_columns: ticket_daily_standups_update_column[];
  where?: ticket_daily_standups_bool_exp | null;
}

/**
 * order by stddev() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_stddev_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_stddev_pop_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_stddev_samp_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_sum_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_var_pop_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_var_samp_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_variance_order_by {
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInProjectDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_developer_view". All fields are combined with a logical 'AND'.
 */
export interface ticket_developer_view_bool_exp {
  _and?: ticket_developer_view_bool_exp[] | null;
  _not?: ticket_developer_view_bool_exp | null;
  _or?: ticket_developer_view_bool_exp[] | null;
  githubIssueSid?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jiraIssueSid?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "ticket_developer_view".
 */
export interface ticket_developer_view_order_by {
  githubIssueSid?: order_by | null;
  id?: order_by | null;
  jiraIssueSid?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_refund"
 */
export interface ticket_refund_aggregate_order_by {
  avg?: ticket_refund_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_refund_max_order_by | null;
  min?: ticket_refund_min_order_by | null;
  stddev?: ticket_refund_stddev_order_by | null;
  stddev_pop?: ticket_refund_stddev_pop_order_by | null;
  stddev_samp?: ticket_refund_stddev_samp_order_by | null;
  sum?: ticket_refund_sum_order_by | null;
  var_pop?: ticket_refund_var_pop_order_by | null;
  var_samp?: ticket_refund_var_samp_order_by | null;
  variance?: ticket_refund_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_refund"
 */
export interface ticket_refund_avg_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_refund". All fields are combined with a logical 'AND'.
 */
export interface ticket_refund_bool_exp {
  _and?: ticket_refund_bool_exp[] | null;
  _not?: ticket_refund_bool_exp | null;
  _or?: ticket_refund_bool_exp[] | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  issuedByUserId?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * order by max() on columns of table "ticket_refund"
 */
export interface ticket_refund_max_order_by {
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  reason?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_refund"
 */
export interface ticket_refund_min_order_by {
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  reason?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_refund"
 */
export interface ticket_refund_stddev_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_refund"
 */
export interface ticket_refund_stddev_pop_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_refund"
 */
export interface ticket_refund_stddev_samp_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_refund"
 */
export interface ticket_refund_sum_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_refund"
 */
export interface ticket_refund_var_pop_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_refund"
 */
export interface ticket_refund_var_samp_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_refund"
 */
export interface ticket_refund_variance_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_repos"
 */
export interface ticket_repos_aggregate_order_by {
  avg?: ticket_repos_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_repos_max_order_by | null;
  min?: ticket_repos_min_order_by | null;
  stddev?: ticket_repos_stddev_order_by | null;
  stddev_pop?: ticket_repos_stddev_pop_order_by | null;
  stddev_samp?: ticket_repos_stddev_samp_order_by | null;
  sum?: ticket_repos_sum_order_by | null;
  var_pop?: ticket_repos_var_pop_order_by | null;
  var_samp?: ticket_repos_var_samp_order_by | null;
  variance?: ticket_repos_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_repos"
 */
export interface ticket_repos_avg_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_repos". All fields are combined with a logical 'AND'.
 */
export interface ticket_repos_bool_exp {
  _and?: ticket_repos_bool_exp[] | null;
  _not?: ticket_repos_bool_exp | null;
  _or?: ticket_repos_bool_exp[] | null;
  git_repo?: git_repos_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "ticket_repos"
 */
export interface ticket_repos_max_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_repos"
 */
export interface ticket_repos_min_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_repos"
 */
export interface ticket_repos_stddev_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_repos"
 */
export interface ticket_repos_stddev_pop_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_repos"
 */
export interface ticket_repos_stddev_samp_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_repos"
 */
export interface ticket_repos_sum_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_repos"
 */
export interface ticket_repos_var_pop_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_repos"
 */
export interface ticket_repos_var_samp_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_repos"
 */
export interface ticket_repos_variance_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_sources"
 */
export interface ticket_sources_aggregate_order_by {
  avg?: ticket_sources_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_sources_max_order_by | null;
  min?: ticket_sources_min_order_by | null;
  stddev?: ticket_sources_stddev_order_by | null;
  stddev_pop?: ticket_sources_stddev_pop_order_by | null;
  stddev_samp?: ticket_sources_stddev_samp_order_by | null;
  sum?: ticket_sources_sum_order_by | null;
  var_pop?: ticket_sources_var_pop_order_by | null;
  var_samp?: ticket_sources_var_samp_order_by | null;
  variance?: ticket_sources_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_sources"
 */
export interface ticket_sources_avg_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_sources". All fields are combined with a logical 'AND'.
 */
export interface ticket_sources_bool_exp {
  _and?: ticket_sources_bool_exp[] | null;
  _not?: ticket_sources_bool_exp | null;
  _or?: ticket_sources_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  gitRepoByGitrepoid?: git_repos_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  jiraProjectSid?: String_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  linearTeamSid?: String_comparison_exp | null;
  prefix?: String_comparison_exp | null;
  projectName?: String_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
}

/**
 * order by max() on columns of table "ticket_sources"
 */
export interface ticket_sources_max_order_by {
  clientId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  jiraProjectSid?: order_by | null;
  linearTeamSid?: order_by | null;
  prefix?: order_by | null;
  projectName?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_sources"
 */
export interface ticket_sources_min_order_by {
  clientId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  jiraProjectSid?: order_by | null;
  linearTeamSid?: order_by | null;
  prefix?: order_by | null;
  projectName?: order_by | null;
  repoId?: order_by | null;
}

/**
 * Ordering options when selecting data from "ticket_sources".
 */
export interface ticket_sources_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_project?: client_projects_order_by | null;
  gitRepoByGitrepoid?: git_repos_order_by | null;
  gitRepoId?: order_by | null;
  git_repo?: git_repos_order_by | null;
  id?: order_by | null;
  jiraProjectSid?: order_by | null;
  jira_account?: jira_accounts_order_by | null;
  jira_project?: jira_projects_order_by | null;
  linearTeamSid?: order_by | null;
  prefix?: order_by | null;
  projectName?: order_by | null;
  repoId?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_sources"
 */
export interface ticket_sources_stddev_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_sources"
 */
export interface ticket_sources_stddev_pop_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_sources"
 */
export interface ticket_sources_stddev_samp_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_sources"
 */
export interface ticket_sources_sum_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_sources"
 */
export interface ticket_sources_var_pop_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_sources"
 */
export interface ticket_sources_var_samp_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_sources"
 */
export interface ticket_sources_variance_order_by {
  gitRepoId?: order_by | null;
  id?: order_by | null;
  repoId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface ticket_standups_sla_view_bool_exp {
  _and?: ticket_standups_sla_view_bool_exp[] | null;
  _not?: ticket_standups_sla_view_bool_exp | null;
  _or?: ticket_standups_sla_view_bool_exp[] | null;
  dueAt?: timestamptz_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "ticket_standups_sla_view".
 */
export interface ticket_standups_sla_view_order_by {
  dueAt?: order_by | null;
  ticket?: tickets_order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_status". All fields are combined with a logical 'AND'.
 */
export interface ticket_status_bool_exp {
  _and?: ticket_status_bool_exp[] | null;
  _not?: ticket_status_bool_exp | null;
  _or?: ticket_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "ticket_status_enum". All fields are combined with logical 'AND'.
 */
export interface ticket_status_enum_comparison_exp {
  _eq?: ticket_status_enum | null;
  _in?: ticket_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: ticket_status_enum | null;
  _nin?: ticket_status_enum[] | null;
}

/**
 * Ordering options when selecting data from "ticket_status".
 */
export interface ticket_status_order_by {
  description?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  type?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_aggregate_order_by {
  avg?: ticket_time_user_totals_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_time_user_totals_max_order_by | null;
  min?: ticket_time_user_totals_min_order_by | null;
  stddev?: ticket_time_user_totals_stddev_order_by | null;
  stddev_pop?: ticket_time_user_totals_stddev_pop_order_by | null;
  stddev_samp?: ticket_time_user_totals_stddev_samp_order_by | null;
  sum?: ticket_time_user_totals_sum_order_by | null;
  var_pop?: ticket_time_user_totals_var_pop_order_by | null;
  var_samp?: ticket_time_user_totals_var_samp_order_by | null;
  variance?: ticket_time_user_totals_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_avg_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface ticket_time_user_totals_bool_exp {
  _and?: ticket_time_user_totals_bool_exp[] | null;
  _not?: ticket_time_user_totals_bool_exp | null;
  _or?: ticket_time_user_totals_bool_exp[] | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_max_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_min_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_stddev_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_stddev_pop_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_stddev_samp_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_sum_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_var_pop_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_var_samp_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_variance_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "tickets"
 */
export interface tickets_aggregate_order_by {
  avg?: tickets_avg_order_by | null;
  count?: order_by | null;
  max?: tickets_max_order_by | null;
  min?: tickets_min_order_by | null;
  stddev?: tickets_stddev_order_by | null;
  stddev_pop?: tickets_stddev_pop_order_by | null;
  stddev_samp?: tickets_stddev_samp_order_by | null;
  sum?: tickets_sum_order_by | null;
  var_pop?: tickets_var_pop_order_by | null;
  var_samp?: tickets_var_samp_order_by | null;
  variance?: tickets_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "tickets"
 */
export interface tickets_arr_rel_insert_input {
  data: tickets_insert_input[];
  on_conflict?: tickets_on_conflict | null;
}

/**
 * order by avg() on columns of table "tickets"
 */
export interface tickets_avg_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tickets". All fields are combined with a logical 'AND'.
 */
export interface tickets_bool_exp {
  _and?: tickets_bool_exp[] | null;
  _not?: tickets_bool_exp | null;
  _or?: tickets_bool_exp[] | null;
  billableAt?: timestamptz_comparison_exp | null;
  billableCostInCredits?: Int_comparison_exp | null;
  cancelledAt?: timestamptz_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  client_tickets?: ticket_client_view_bool_exp | null;
  code?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  dev_tickets?: ticket_developer_view_bool_exp | null;
  developer?: developers_bool_exp | null;
  discussionSourceByCode?: discussion_sources_bool_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  discussion_sources?: discussion_sources_bool_exp | null;
  discussions_related?: discussions_related_to_tickets_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  githubIssueSid?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  isSynced?: Boolean_comparison_exp | null;
  jiraIssueSid?: String_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  manager?: users_bool_exp | null;
  managerId?: String_comparison_exp | null;
  needsApproval?: Boolean_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  priority?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: ticket_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  statusUpdatedAt?: timestamptz_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  task_changes_by_status?: task_changes_by_status_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticketLink?: String_comparison_exp | null;
  ticket_attachments?: ticket_attachments_bool_exp | null;
  ticket_billable_overrides?: ticket_billable_overrides_bool_exp | null;
  ticket_changes_by_status?: ticket_changes_by_status_bool_exp | null;
  ticket_client_blockers?: ticket_client_blockers_bool_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_parsed_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  ticket_refunds?: ticket_refund_bool_exp | null;
  ticket_repos?: ticket_repos_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_standups_sla?: ticket_standups_sla_view_bool_exp | null;
  ticket_status?: ticket_status_bool_exp | null;
  ticket_time_user_totals?: ticket_time_user_totals_bool_exp | null;
  title?: String_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  type?: tickets_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
  user_team?: user_teams_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "tickets"
 */
export interface tickets_insert_input {
  cancelledAt?: hasura_timestamptz | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  code?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  developer?: developers_obj_rel_insert_input | null;
  discussionSourceByCode?: discussion_sources_obj_rel_insert_input | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  discussion_sources?: discussion_sources_arr_rel_insert_input | null;
  gitRepoId?: number | null;
  id?: number | null;
  isInternal?: boolean | null;
  isSynced?: boolean | null;
  lowerBudget?: number | null;
  manager?: users_obj_rel_insert_input | null;
  managerId?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
  startedAt?: hasura_timestamptz | null;
  status?: ticket_status_enum | null;
  statusUpdateReason?: string | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticketLink?: string | null;
  ticket_attachments?: ticket_attachments_arr_rel_insert_input | null;
  ticket_billable_overrides?: ticket_billable_overrides_arr_rel_insert_input | null;
  ticket_client_blockers?: ticket_client_blockers_arr_rel_insert_input | null;
  ticket_credit_approvals?: ticket_credit_approvals_arr_rel_insert_input | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  title?: string | null;
  type?: tickets_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "tickets"
 */
export interface tickets_max_order_by {
  cancelledAt?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  code?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  gitRepoId?: order_by | null;
  githubIssueSid?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  jiraIssueSid?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  priority?: order_by | null;
  startedAt?: order_by | null;
  statusUpdateReason?: order_by | null;
  statusUpdatedAt?: order_by | null;
  syncedAt?: order_by | null;
  ticketLink?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "tickets"
 */
export interface tickets_min_order_by {
  cancelledAt?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  code?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  gitRepoId?: order_by | null;
  githubIssueSid?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  jiraIssueSid?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  priority?: order_by | null;
  startedAt?: order_by | null;
  statusUpdateReason?: order_by | null;
  statusUpdatedAt?: order_by | null;
  syncedAt?: order_by | null;
  ticketLink?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "tickets"
 */
export interface tickets_obj_rel_insert_input {
  data: tickets_insert_input;
  on_conflict?: tickets_on_conflict | null;
}

/**
 * on_conflict condition type for table "tickets"
 */
export interface tickets_on_conflict {
  constraint: tickets_constraint;
  update_columns: tickets_update_column[];
  where?: tickets_bool_exp | null;
}

/**
 * Ordering options when selecting data from "tickets".
 */
export interface tickets_order_by {
  billableAt?: order_by | null;
  billableCostInCredits?: order_by | null;
  cancelledAt?: order_by | null;
  client?: clients_order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  client_invoice?: client_invoices_order_by | null;
  client_project?: client_projects_order_by | null;
  client_tickets?: ticket_client_view_order_by | null;
  code?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  descriptionType?: order_by | null;
  description_type?: description_types_order_by | null;
  dev_tickets?: ticket_developer_view_order_by | null;
  developer?: developers_order_by | null;
  discussionSourceByCode?: discussion_sources_order_by | null;
  discussion_source?: discussion_sources_order_by | null;
  discussion_sources_aggregate?: discussion_sources_aggregate_order_by | null;
  discussions_related_aggregate?: discussions_related_to_tickets_aggregate_order_by | null;
  gitRepoId?: order_by | null;
  git_pull_requests_aggregate?: git_pull_requests_aggregate_order_by | null;
  git_repo?: git_repos_order_by | null;
  githubIssueSid?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  isInternal?: order_by | null;
  isSynced?: order_by | null;
  jiraIssueSid?: order_by | null;
  lowerBudget?: order_by | null;
  manager?: users_order_by | null;
  managerId?: order_by | null;
  needsApproval?: order_by | null;
  notifications_aggregate?: notifications_aggregate_order_by | null;
  priority?: order_by | null;
  startedAt?: order_by | null;
  status?: order_by | null;
  statusUpdateReason?: order_by | null;
  statusUpdatedAt?: order_by | null;
  syncedAt?: order_by | null;
  task_changes_by_status_aggregate?: task_changes_by_status_aggregate_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticketLink?: order_by | null;
  ticket_attachments_aggregate?: ticket_attachments_aggregate_order_by | null;
  ticket_billable_overrides_aggregate?: ticket_billable_overrides_aggregate_order_by | null;
  ticket_changes_by_status_aggregate?: ticket_changes_by_status_aggregate_order_by | null;
  ticket_client_blockers_aggregate?: ticket_client_blockers_aggregate_order_by | null;
  ticket_credit_approval?: ticket_credit_approvals_parsed_order_by | null;
  ticket_credit_approvals_aggregate?: ticket_credit_approvals_aggregate_order_by | null;
  ticket_daily_standups_aggregate?: ticket_daily_standups_aggregate_order_by | null;
  ticket_refunds_aggregate?: ticket_refund_aggregate_order_by | null;
  ticket_repos_aggregate?: ticket_repos_aggregate_order_by | null;
  ticket_source?: ticket_sources_order_by | null;
  ticket_standups_sla?: ticket_standups_sla_view_order_by | null;
  ticket_status?: ticket_status_order_by | null;
  ticket_time_user_totals_aggregate?: ticket_time_user_totals_aggregate_order_by | null;
  title?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  type?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
  user_events_aggregate?: user_events_aggregate_order_by | null;
  user_team?: user_teams_order_by | null;
  user_time_logs_aggregate?: user_time_logs_view_aggregate_order_by | null;
  user_work_logs_aggregate?: user_work_logs_aggregate_order_by | null;
}

/**
 * input type for updating data in table "tickets"
 */
export interface tickets_set_input {
  cancelledAt?: hasura_timestamptz | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  code?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  gitRepoId?: number | null;
  id?: number | null;
  invoiceId?: number | null;
  isInternal?: boolean | null;
  lowerBudget?: number | null;
  managerId?: string | null;
  priority?: number | null;
  startedAt?: hasura_timestamptz | null;
  status?: ticket_status_enum | null;
  statusUpdateReason?: string | null;
  statusUpdatedAt?: hasura_timestamptz | null;
  ticketLink?: string | null;
  title?: string | null;
  type?: tickets_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * order by stddev() on columns of table "tickets"
 */
export interface tickets_stddev_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "tickets"
 */
export interface tickets_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "tickets"
 */
export interface tickets_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "tickets"
 */
export interface tickets_sum_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "tickets_types_enum". All fields are combined with logical 'AND'.
 */
export interface tickets_types_enum_comparison_exp {
  _eq?: tickets_types_enum | null;
  _in?: tickets_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tickets_types_enum | null;
  _nin?: tickets_types_enum[] | null;
}

/**
 * order by var_pop() on columns of table "tickets"
 */
export interface tickets_var_pop_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "tickets"
 */
export interface tickets_var_samp_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "tickets"
 */
export interface tickets_variance_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  priority?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: hasura_timestamptz | null;
  _gt?: hasura_timestamptz | null;
  _gte?: hasura_timestamptz | null;
  _in?: hasura_timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_timestamptz | null;
  _lte?: hasura_timestamptz | null;
  _neq?: hasura_timestamptz | null;
  _nin?: hasura_timestamptz[] | null;
}

/**
 * order by aggregate values of table "user_contracts"
 */
export interface user_contracts_aggregate_order_by {
  avg?: user_contracts_avg_order_by | null;
  count?: order_by | null;
  max?: user_contracts_max_order_by | null;
  min?: user_contracts_min_order_by | null;
  stddev?: user_contracts_stddev_order_by | null;
  stddev_pop?: user_contracts_stddev_pop_order_by | null;
  stddev_samp?: user_contracts_stddev_samp_order_by | null;
  sum?: user_contracts_sum_order_by | null;
  var_pop?: user_contracts_var_pop_order_by | null;
  var_samp?: user_contracts_var_samp_order_by | null;
  variance?: user_contracts_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_contracts"
 */
export interface user_contracts_arr_rel_insert_input {
  data: user_contracts_insert_input[];
}

/**
 * order by avg() on columns of table "user_contracts"
 */
export interface user_contracts_avg_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_contracts". All fields are combined with a logical 'AND'.
 */
export interface user_contracts_bool_exp {
  _and?: user_contracts_bool_exp[] | null;
  _not?: user_contracts_bool_exp | null;
  _or?: user_contracts_bool_exp[] | null;
  defaultCoefficient?: numeric_comparison_exp | null;
  developer?: developers_bool_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  gracePeriodEndDate?: timestamptz_comparison_exp | null;
  hoursPerWeek?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  title?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_contracts"
 */
export interface user_contracts_insert_input {
  developer?: developers_obj_rel_insert_input | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * order by max() on columns of table "user_contracts"
 */
export interface user_contracts_max_order_by {
  defaultCoefficient?: order_by | null;
  endDate?: order_by | null;
  gracePeriodEndDate?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
  startDate?: order_by | null;
  title?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by min() on columns of table "user_contracts"
 */
export interface user_contracts_min_order_by {
  defaultCoefficient?: order_by | null;
  endDate?: order_by | null;
  gracePeriodEndDate?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
  startDate?: order_by | null;
  title?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_contracts"
 */
export interface user_contracts_stddev_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_contracts"
 */
export interface user_contracts_stddev_pop_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_contracts"
 */
export interface user_contracts_stddev_samp_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "user_contracts"
 */
export interface user_contracts_sum_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_contracts"
 */
export interface user_contracts_var_pop_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_contracts"
 */
export interface user_contracts_var_samp_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "user_contracts"
 */
export interface user_contracts_variance_order_by {
  defaultCoefficient?: order_by | null;
  hoursPerWeek?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_aggregate_order_by {
  avg?: user_daily_facts_structure_avg_order_by | null;
  count?: order_by | null;
  max?: user_daily_facts_structure_max_order_by | null;
  min?: user_daily_facts_structure_min_order_by | null;
  stddev?: user_daily_facts_structure_stddev_order_by | null;
  stddev_pop?: user_daily_facts_structure_stddev_pop_order_by | null;
  stddev_samp?: user_daily_facts_structure_stddev_samp_order_by | null;
  sum?: user_daily_facts_structure_sum_order_by | null;
  var_pop?: user_daily_facts_structure_var_pop_order_by | null;
  var_samp?: user_daily_facts_structure_var_samp_order_by | null;
  variance?: user_daily_facts_structure_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_avg_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_facts_structure". All fields are combined with a logical 'AND'.
 */
export interface user_daily_facts_structure_bool_exp {
  _and?: user_daily_facts_structure_bool_exp[] | null;
  _not?: user_daily_facts_structure_bool_exp | null;
  _or?: user_daily_facts_structure_bool_exp[] | null;
  hoursLogged?: Int_comparison_exp | null;
  hoursLoggedOnOther?: Int_comparison_exp | null;
  hoursRequired?: Int_comparison_exp | null;
  leaveId?: Int_comparison_exp | null;
  leaveIsApproved?: Boolean_comparison_exp | null;
  occurredAt?: date_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_leave?: user_leaves_bool_exp | null;
}

/**
 * order by max() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_max_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  occurredAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_min_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  occurredAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_stddev_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_stddev_pop_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_stddev_samp_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_sum_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_var_pop_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_var_samp_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_variance_order_by {
  hoursLogged?: order_by | null;
  hoursLoggedOnOther?: order_by | null;
  hoursRequired?: order_by | null;
  leaveId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_developer_view". All fields are combined with a logical 'AND'.
 */
export interface user_developer_view_bool_exp {
  _and?: user_developer_view_bool_exp[] | null;
  _not?: user_developer_view_bool_exp | null;
  _or?: user_developer_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  passwordHash?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "user_developer_view".
 */
export interface user_developer_view_order_by {
  id?: order_by | null;
  passwordHash?: order_by | null;
}

/**
 * order by aggregate values of table "user_emails"
 */
export interface user_emails_aggregate_order_by {
  avg?: user_emails_avg_order_by | null;
  count?: order_by | null;
  max?: user_emails_max_order_by | null;
  min?: user_emails_min_order_by | null;
  stddev?: user_emails_stddev_order_by | null;
  stddev_pop?: user_emails_stddev_pop_order_by | null;
  stddev_samp?: user_emails_stddev_samp_order_by | null;
  sum?: user_emails_sum_order_by | null;
  var_pop?: user_emails_var_pop_order_by | null;
  var_samp?: user_emails_var_samp_order_by | null;
  variance?: user_emails_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_emails"
 */
export interface user_emails_arr_rel_insert_input {
  data: user_emails_insert_input[];
  on_conflict?: user_emails_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_emails"
 */
export interface user_emails_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_emails". All fields are combined with a logical 'AND'.
 */
export interface user_emails_bool_exp {
  _and?: user_emails_bool_exp[] | null;
  _not?: user_emails_bool_exp | null;
  _or?: user_emails_bool_exp[] | null;
  email?: String_comparison_exp | null;
  git_users?: git_users_bool_exp | null;
  jira_accounts?: jira_accounts_bool_exp | null;
  user?: users_bool_exp | null;
  userByEmail?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  users?: users_bool_exp | null;
  usersByUnverifiedemail?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "user_emails"
 */
export interface user_emails_insert_input {
  email?: string | null;
  user?: users_obj_rel_insert_input | null;
  userByEmail?: users_obj_rel_insert_input | null;
  userId?: number | null;
  users?: users_arr_rel_insert_input | null;
  usersByUnverifiedemail?: users_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "user_emails"
 */
export interface user_emails_max_order_by {
  email?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_emails"
 */
export interface user_emails_min_order_by {
  email?: order_by | null;
  userId?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "user_emails"
 */
export interface user_emails_obj_rel_insert_input {
  data: user_emails_insert_input;
  on_conflict?: user_emails_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_emails"
 */
export interface user_emails_on_conflict {
  constraint: user_emails_constraint;
  update_columns: user_emails_update_column[];
  where?: user_emails_bool_exp | null;
}

/**
 * Ordering options when selecting data from "user_emails".
 */
export interface user_emails_order_by {
  email?: order_by | null;
  git_users_aggregate?: git_users_aggregate_order_by | null;
  jira_accounts_aggregate?: jira_accounts_aggregate_order_by | null;
  user?: users_order_by | null;
  userByEmail?: users_order_by | null;
  userId?: order_by | null;
  usersByUnverifiedemail_aggregate?: users_aggregate_order_by | null;
  users_aggregate?: users_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "user_emails"
 */
export interface user_emails_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_emails"
 */
export interface user_emails_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_emails"
 */
export interface user_emails_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_emails"
 */
export interface user_emails_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_emails"
 */
export interface user_emails_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_emails"
 */
export interface user_emails_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_emails"
 */
export interface user_emails_variance_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "user_event_source_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_event_source_types_enum_comparison_exp {
  _eq?: user_event_source_types_enum | null;
  _in?: user_event_source_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_event_source_types_enum | null;
  _nin?: user_event_source_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "user_event_types". All fields are combined with a logical 'AND'.
 */
export interface user_event_types_bool_exp {
  _and?: user_event_types_bool_exp[] | null;
  _not?: user_event_types_bool_exp | null;
  _or?: user_event_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_event_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_event_types_enum_comparison_exp {
  _eq?: user_event_types_enum | null;
  _in?: user_event_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_event_types_enum | null;
  _nin?: user_event_types_enum[] | null;
}

/**
 * order by aggregate values of table "user_events"
 */
export interface user_events_aggregate_order_by {
  avg?: user_events_avg_order_by | null;
  count?: order_by | null;
  max?: user_events_max_order_by | null;
  min?: user_events_min_order_by | null;
  stddev?: user_events_stddev_order_by | null;
  stddev_pop?: user_events_stddev_pop_order_by | null;
  stddev_samp?: user_events_stddev_samp_order_by | null;
  sum?: user_events_sum_order_by | null;
  var_pop?: user_events_var_pop_order_by | null;
  var_samp?: user_events_var_samp_order_by | null;
  variance?: user_events_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_events"
 */
export interface user_events_arr_rel_insert_input {
  data: user_events_insert_input[];
  on_conflict?: user_events_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_events"
 */
export interface user_events_avg_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_events". All fields are combined with a logical 'AND'.
 */
export interface user_events_bool_exp {
  _and?: user_events_bool_exp[] | null;
  _not?: user_events_bool_exp | null;
  _or?: user_events_bool_exp[] | null;
  appName?: String_comparison_exp | null;
  browserUrl?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  duration?: Int_comparison_exp | null;
  eventSource?: user_event_source_types_enum_comparison_exp | null;
  eventType?: user_event_types_enum_comparison_exp | null;
  event_type?: user_event_types_bool_exp | null;
  filePath?: String_comparison_exp | null;
  gitBranchRef?: String_comparison_exp | null;
  gitRepoName?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  isProcessed?: Boolean_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  pollInterval?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_events"
 */
export interface user_events_insert_input {
  appName?: string | null;
  browserUrl?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  duration?: number | null;
  eventSource?: user_event_source_types_enum | null;
  eventType?: user_event_types_enum | null;
  filePath?: string | null;
  gitBranchRef?: string | null;
  gitCommitHash?: string | null;
  gitOwner?: string | null;
  gitRelativeFilePath?: string | null;
  gitRepoName?: string | null;
  id?: hasura_uuid | null;
  isProcessed?: boolean | null;
  occurredAt?: hasura_timestamptz | null;
  pollInterval?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "user_events"
 */
export interface user_events_max_order_by {
  appName?: order_by | null;
  browserUrl?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  duration?: order_by | null;
  filePath?: order_by | null;
  gitBranchRef?: order_by | null;
  gitRepoName?: order_by | null;
  id?: order_by | null;
  occurredAt?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_events"
 */
export interface user_events_min_order_by {
  appName?: order_by | null;
  browserUrl?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  duration?: order_by | null;
  filePath?: order_by | null;
  gitBranchRef?: order_by | null;
  gitRepoName?: order_by | null;
  id?: order_by | null;
  occurredAt?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * on_conflict condition type for table "user_events"
 */
export interface user_events_on_conflict {
  constraint: user_events_constraint;
  update_columns: user_events_update_column[];
  where?: user_events_bool_exp | null;
}

/**
 * order by stddev() on columns of table "user_events"
 */
export interface user_events_stddev_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_events"
 */
export interface user_events_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_events"
 */
export interface user_events_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_events"
 */
export interface user_events_sum_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_events"
 */
export interface user_events_var_pop_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_events"
 */
export interface user_events_var_samp_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_events"
 */
export interface user_events_variance_order_by {
  clientProjectId?: order_by | null;
  duration?: order_by | null;
  pollInterval?: order_by | null;
  taskId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "user_facts_structure"
 */
export interface user_facts_structure_aggregate_order_by {
  avg?: user_facts_structure_avg_order_by | null;
  count?: order_by | null;
  max?: user_facts_structure_max_order_by | null;
  min?: user_facts_structure_min_order_by | null;
  stddev?: user_facts_structure_stddev_order_by | null;
  stddev_pop?: user_facts_structure_stddev_pop_order_by | null;
  stddev_samp?: user_facts_structure_stddev_samp_order_by | null;
  sum?: user_facts_structure_sum_order_by | null;
  var_pop?: user_facts_structure_var_pop_order_by | null;
  var_samp?: user_facts_structure_var_samp_order_by | null;
  variance?: user_facts_structure_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_avg_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_facts_structure". All fields are combined with a logical 'AND'.
 */
export interface user_facts_structure_bool_exp {
  _and?: user_facts_structure_bool_exp[] | null;
  _not?: user_facts_structure_bool_exp | null;
  _or?: user_facts_structure_bool_exp[] | null;
  paidLeavesEarned?: Int_comparison_exp | null;
  paidLeavesUtilized?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_max_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_min_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_stddev_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_stddev_pop_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_stddev_samp_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_sum_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_var_pop_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_var_samp_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_facts_structure"
 */
export interface user_facts_structure_variance_order_by {
  paidLeavesEarned?: order_by | null;
  paidLeavesUtilized?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "user_invoice_balances"
 */
export interface user_invoice_balances_aggregate_order_by {
  count?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_invoice_balances". All fields are combined with a logical 'AND'.
 */
export interface user_invoice_balances_bool_exp {
  _and?: user_invoice_balances_bool_exp[] | null;
  _not?: user_invoice_balances_bool_exp | null;
  _or?: user_invoice_balances_bool_exp[] | null;
  task_reviews_as_manager?: task_reviews_bool_exp | null;
  task_reviews_as_reviewer?: task_reviews_bool_exp | null;
  tasks_as_developer?: tasks_bool_exp | null;
  tasks_as_manager?: tasks_bool_exp | null;
  user?: users_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
}

/**
 * Ordering options when selecting data from "user_invoice_balances".
 */
export interface user_invoice_balances_order_by {
  task_reviews_as_manager_aggregate?: task_reviews_aggregate_order_by | null;
  task_reviews_as_reviewer_aggregate?: task_reviews_aggregate_order_by | null;
  tasks_as_developer_aggregate?: tasks_aggregate_order_by | null;
  tasks_as_manager_aggregate?: tasks_aggregate_order_by | null;
  user?: users_order_by | null;
  user_invoice?: user_invoices_order_by | null;
  user_time_logs_aggregate?: user_time_logs_view_aggregate_order_by | null;
}

/**
 * order by aggregate values of table "user_invoices"
 */
export interface user_invoices_aggregate_order_by {
  avg?: user_invoices_avg_order_by | null;
  count?: order_by | null;
  max?: user_invoices_max_order_by | null;
  min?: user_invoices_min_order_by | null;
  stddev?: user_invoices_stddev_order_by | null;
  stddev_pop?: user_invoices_stddev_pop_order_by | null;
  stddev_samp?: user_invoices_stddev_samp_order_by | null;
  sum?: user_invoices_sum_order_by | null;
  var_pop?: user_invoices_var_pop_order_by | null;
  var_samp?: user_invoices_var_samp_order_by | null;
  variance?: user_invoices_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_invoices"
 */
export interface user_invoices_avg_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_invoices". All fields are combined with a logical 'AND'.
 */
export interface user_invoices_bool_exp {
  _and?: user_invoices_bool_exp[] | null;
  _not?: user_invoices_bool_exp | null;
  _or?: user_invoices_bool_exp[] | null;
  adjustedCostInUSD?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  paidByAgencyInvoiceId?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  taskReviewsByManagerinvoiceid?: task_reviews_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  tasksByManagerinvoiceid?: tasks_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_invoice_balance?: user_invoice_balances_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
}

/**
 * order by max() on columns of table "user_invoices"
 */
export interface user_invoices_max_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_invoices"
 */
export interface user_invoices_min_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * Ordering options when selecting data from "user_invoices".
 */
export interface user_invoices_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  startAt?: order_by | null;
  taskReviewsByManagerinvoiceid_aggregate?: task_reviews_aggregate_order_by | null;
  task_reviews_aggregate?: task_reviews_aggregate_order_by | null;
  tasksByManagerinvoiceid_aggregate?: tasks_aggregate_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  updatedAt?: order_by | null;
  user?: users_order_by | null;
  userId?: order_by | null;
  user_invoice_balance?: user_invoice_balances_order_by | null;
  user_time_logs_aggregate?: user_time_logs_view_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "user_invoices"
 */
export interface user_invoices_stddev_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_invoices"
 */
export interface user_invoices_stddev_pop_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_invoices"
 */
export interface user_invoices_stddev_samp_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_invoices"
 */
export interface user_invoices_sum_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_invoices"
 */
export interface user_invoices_var_pop_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_invoices"
 */
export interface user_invoices_var_samp_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_invoices"
 */
export interface user_invoices_variance_order_by {
  adjustedCostInUSD?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  paidByAgencyInvoiceId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_leave_types". All fields are combined with a logical 'AND'.
 */
export interface user_leave_types_bool_exp {
  _and?: user_leave_types_bool_exp[] | null;
  _not?: user_leave_types_bool_exp | null;
  _or?: user_leave_types_bool_exp[] | null;
  id?: String_comparison_exp | null;
  user_leaves?: user_leaves_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_leave_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_leave_types_enum_comparison_exp {
  _eq?: user_leave_types_enum | null;
  _in?: user_leave_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_leave_types_enum | null;
  _nin?: user_leave_types_enum[] | null;
}

/**
 * order by aggregate values of table "user_leaves"
 */
export interface user_leaves_aggregate_order_by {
  avg?: user_leaves_avg_order_by | null;
  count?: order_by | null;
  max?: user_leaves_max_order_by | null;
  min?: user_leaves_min_order_by | null;
  stddev?: user_leaves_stddev_order_by | null;
  stddev_pop?: user_leaves_stddev_pop_order_by | null;
  stddev_samp?: user_leaves_stddev_samp_order_by | null;
  sum?: user_leaves_sum_order_by | null;
  var_pop?: user_leaves_var_pop_order_by | null;
  var_samp?: user_leaves_var_samp_order_by | null;
  variance?: user_leaves_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_leaves"
 */
export interface user_leaves_arr_rel_insert_input {
  data: user_leaves_insert_input[];
  on_conflict?: user_leaves_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_leaves"
 */
export interface user_leaves_avg_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_leaves". All fields are combined with a logical 'AND'.
 */
export interface user_leaves_bool_exp {
  _and?: user_leaves_bool_exp[] | null;
  _not?: user_leaves_bool_exp | null;
  _or?: user_leaves_bool_exp[] | null;
  approvedById?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isApproved?: Boolean_comparison_exp | null;
  leaveReason?: String_comparison_exp | null;
  leaveTypeId?: user_leave_types_enum_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByUserid?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_facts_structures?: user_daily_facts_structure_bool_exp | null;
  user_leave_type?: user_leave_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_leaves"
 */
export interface user_leaves_insert_input {
  approvedById?: number | null;
  endAt?: hasura_timestamptz | null;
  leaveReason?: string | null;
  leaveTypeId?: user_leave_types_enum | null;
  startAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByUserid?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "user_leaves"
 */
export interface user_leaves_max_order_by {
  approvedById?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  leaveReason?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_leaves"
 */
export interface user_leaves_min_order_by {
  approvedById?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  leaveReason?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * on_conflict condition type for table "user_leaves"
 */
export interface user_leaves_on_conflict {
  constraint: user_leaves_constraint;
  update_columns: user_leaves_update_column[];
  where?: user_leaves_bool_exp | null;
}

/**
 * order by stddev() on columns of table "user_leaves"
 */
export interface user_leaves_stddev_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_leaves"
 */
export interface user_leaves_stddev_pop_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_leaves"
 */
export interface user_leaves_stddev_samp_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_leaves"
 */
export interface user_leaves_sum_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_leaves"
 */
export interface user_leaves_var_pop_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_leaves"
 */
export interface user_leaves_var_samp_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_leaves"
 */
export interface user_leaves_variance_order_by {
  approvedById?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_aggregate_order_by {
  avg?: user_opportunity_interests_avg_order_by | null;
  count?: order_by | null;
  max?: user_opportunity_interests_max_order_by | null;
  min?: user_opportunity_interests_min_order_by | null;
  stddev?: user_opportunity_interests_stddev_order_by | null;
  stddev_pop?: user_opportunity_interests_stddev_pop_order_by | null;
  stddev_samp?: user_opportunity_interests_stddev_samp_order_by | null;
  sum?: user_opportunity_interests_sum_order_by | null;
  var_pop?: user_opportunity_interests_var_pop_order_by | null;
  var_samp?: user_opportunity_interests_var_samp_order_by | null;
  variance?: user_opportunity_interests_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_opportunity_interests"
 */
export interface user_opportunity_interests_arr_rel_insert_input {
  data: user_opportunity_interests_insert_input[];
  on_conflict?: user_opportunity_interests_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_avg_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_opportunity_interests". All fields are combined with a logical 'AND'.
 */
export interface user_opportunity_interests_bool_exp {
  _and?: user_opportunity_interests_bool_exp[] | null;
  _not?: user_opportunity_interests_bool_exp | null;
  _or?: user_opportunity_interests_bool_exp[] | null;
  opportunitiesId?: Int_comparison_exp | null;
  opportunity?: opportunities_bool_exp | null;
  user?: users_bool_exp | null;
  usersId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_opportunity_interests"
 */
export interface user_opportunity_interests_insert_input {
  opportunitiesId?: number | null;
  user?: users_obj_rel_insert_input | null;
  usersId?: number | null;
}

/**
 * order by max() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_max_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by min() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_min_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * on_conflict condition type for table "user_opportunity_interests"
 */
export interface user_opportunity_interests_on_conflict {
  constraint: user_opportunity_interests_constraint;
  update_columns: user_opportunity_interests_update_column[];
  where?: user_opportunity_interests_bool_exp | null;
}

/**
 * order by stddev() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_stddev_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_stddev_pop_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_stddev_samp_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_sum_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_var_pop_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_var_samp_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_opportunity_interests"
 */
export interface user_opportunity_interests_variance_order_by {
  opportunitiesId?: order_by | null;
  usersId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_payment_status". All fields are combined with a logical 'AND'.
 */
export interface user_payment_status_bool_exp {
  _and?: user_payment_status_bool_exp[] | null;
  _not?: user_payment_status_bool_exp | null;
  _or?: user_payment_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_payments?: user_payments_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_payment_status_enum". All fields are combined with logical 'AND'.
 */
export interface user_payment_status_enum_comparison_exp {
  _eq?: user_payment_status_enum | null;
  _in?: user_payment_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_payment_status_enum | null;
  _nin?: user_payment_status_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "user_payment_types". All fields are combined with a logical 'AND'.
 */
export interface user_payment_types_bool_exp {
  _and?: user_payment_types_bool_exp[] | null;
  _not?: user_payment_types_bool_exp | null;
  _or?: user_payment_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_payments?: user_payments_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_payment_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_payment_types_enum_comparison_exp {
  _eq?: user_payment_types_enum | null;
  _in?: user_payment_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_payment_types_enum | null;
  _nin?: user_payment_types_enum[] | null;
}

/**
 * order by aggregate values of table "user_payments"
 */
export interface user_payments_aggregate_order_by {
  avg?: user_payments_avg_order_by | null;
  count?: order_by | null;
  max?: user_payments_max_order_by | null;
  min?: user_payments_min_order_by | null;
  stddev?: user_payments_stddev_order_by | null;
  stddev_pop?: user_payments_stddev_pop_order_by | null;
  stddev_samp?: user_payments_stddev_samp_order_by | null;
  sum?: user_payments_sum_order_by | null;
  var_pop?: user_payments_var_pop_order_by | null;
  var_samp?: user_payments_var_samp_order_by | null;
  variance?: user_payments_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_payments"
 */
export interface user_payments_arr_rel_insert_input {
  data: user_payments_insert_input[];
}

/**
 * order by avg() on columns of table "user_payments"
 */
export interface user_payments_avg_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_payments". All fields are combined with a logical 'AND'.
 */
export interface user_payments_bool_exp {
  _and?: user_payments_bool_exp[] | null;
  _not?: user_payments_bool_exp | null;
  _or?: user_payments_bool_exp[] | null;
  amountInUSD?: float8_comparison_exp | null;
  channelTransactionId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  developer?: developers_bool_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  paymentType?: user_payment_types_enum_comparison_exp | null;
  status?: user_payment_status_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
  user_payment_status?: user_payment_status_bool_exp | null;
  user_payment_type?: user_payment_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_payments"
 */
export interface user_payments_insert_input {
  amountInUSD?: hasura_float8 | null;
  channelTransactionId?: string | null;
  createdAt?: hasura_timestamptz | null;
  developer?: developers_obj_rel_insert_input | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  paymentType?: user_payment_types_enum | null;
  status?: user_payment_status_enum | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
}

/**
 * order by max() on columns of table "user_payments"
 */
export interface user_payments_max_order_by {
  amountInUSD?: order_by | null;
  channelTransactionId?: order_by | null;
  createdAt?: order_by | null;
  finishedAt?: order_by | null;
  id?: order_by | null;
  updatedAt?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by min() on columns of table "user_payments"
 */
export interface user_payments_min_order_by {
  amountInUSD?: order_by | null;
  channelTransactionId?: order_by | null;
  createdAt?: order_by | null;
  finishedAt?: order_by | null;
  id?: order_by | null;
  updatedAt?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_payments"
 */
export interface user_payments_stddev_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_payments"
 */
export interface user_payments_stddev_pop_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_payments"
 */
export interface user_payments_stddev_samp_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "user_payments"
 */
export interface user_payments_sum_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_payments"
 */
export interface user_payments_var_pop_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_payments"
 */
export interface user_payments_var_samp_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "user_payments"
 */
export interface user_payments_variance_order_by {
  amountInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "user_skills"
 */
export interface user_skills_aggregate_order_by {
  avg?: user_skills_avg_order_by | null;
  count?: order_by | null;
  max?: user_skills_max_order_by | null;
  min?: user_skills_min_order_by | null;
  stddev?: user_skills_stddev_order_by | null;
  stddev_pop?: user_skills_stddev_pop_order_by | null;
  stddev_samp?: user_skills_stddev_samp_order_by | null;
  sum?: user_skills_sum_order_by | null;
  var_pop?: user_skills_var_pop_order_by | null;
  var_samp?: user_skills_var_samp_order_by | null;
  variance?: user_skills_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_skills"
 */
export interface user_skills_arr_rel_insert_input {
  data: user_skills_insert_input[];
  on_conflict?: user_skills_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_skills"
 */
export interface user_skills_avg_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_skills". All fields are combined with a logical 'AND'.
 */
export interface user_skills_bool_exp {
  _and?: user_skills_bool_exp[] | null;
  _not?: user_skills_bool_exp | null;
  _or?: user_skills_bool_exp[] | null;
  aggregatedMonthsOfExperience?: Int_comparison_exp | null;
  competencyId?: String_comparison_exp | null;
  skill?: skills_bool_exp | null;
  skillId?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_skills"
 */
export interface user_skills_insert_input {
  aggregatedMonthsOfExperience?: number | null;
  competencyId?: string | null;
  skillId?: number | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * order by max() on columns of table "user_skills"
 */
export interface user_skills_max_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  competencyId?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_skills"
 */
export interface user_skills_min_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  competencyId?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * on_conflict condition type for table "user_skills"
 */
export interface user_skills_on_conflict {
  constraint: user_skills_constraint;
  update_columns: user_skills_update_column[];
  where?: user_skills_bool_exp | null;
}

/**
 * order by stddev() on columns of table "user_skills"
 */
export interface user_skills_stddev_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_skills"
 */
export interface user_skills_stddev_pop_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_skills"
 */
export interface user_skills_stddev_samp_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_skills"
 */
export interface user_skills_sum_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_skills"
 */
export interface user_skills_var_pop_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_skills"
 */
export interface user_skills_var_samp_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_skills"
 */
export interface user_skills_variance_order_by {
  aggregatedMonthsOfExperience?: order_by | null;
  skillId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface user_standups_sla_view_bool_exp {
  _and?: user_standups_sla_view_bool_exp[] | null;
  _not?: user_standups_sla_view_bool_exp | null;
  _or?: user_standups_sla_view_bool_exp[] | null;
  dueAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "user_standups_sla_view".
 */
export interface user_standups_sla_view_order_by {
  dueAt?: order_by | null;
  user?: users_order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "user_team_clients"
 */
export interface user_team_clients_aggregate_order_by {
  avg?: user_team_clients_avg_order_by | null;
  count?: order_by | null;
  max?: user_team_clients_max_order_by | null;
  min?: user_team_clients_min_order_by | null;
  stddev?: user_team_clients_stddev_order_by | null;
  stddev_pop?: user_team_clients_stddev_pop_order_by | null;
  stddev_samp?: user_team_clients_stddev_samp_order_by | null;
  sum?: user_team_clients_sum_order_by | null;
  var_pop?: user_team_clients_var_pop_order_by | null;
  var_samp?: user_team_clients_var_samp_order_by | null;
  variance?: user_team_clients_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_team_clients"
 */
export interface user_team_clients_avg_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_clients". All fields are combined with a logical 'AND'.
 */
export interface user_team_clients_bool_exp {
  _and?: user_team_clients_bool_exp[] | null;
  _not?: user_team_clients_bool_exp | null;
  _or?: user_team_clients_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isOwned?: Boolean_comparison_exp | null;
  managerId?: Int_comparison_exp | null;
  projectName?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * order by max() on columns of table "user_team_clients"
 */
export interface user_team_clients_max_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  projectName?: order_by | null;
  updatedAt?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by min() on columns of table "user_team_clients"
 */
export interface user_team_clients_min_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  projectName?: order_by | null;
  updatedAt?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * Ordering options when selecting data from "user_team_clients".
 */
export interface user_team_clients_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_project?: client_projects_order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  isOwned?: order_by | null;
  managerId?: order_by | null;
  projectName?: order_by | null;
  updatedAt?: order_by | null;
  user?: users_order_by | null;
  userTeamId?: order_by | null;
  user_team?: user_teams_order_by | null;
}

/**
 * order by stddev() on columns of table "user_team_clients"
 */
export interface user_team_clients_stddev_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_team_clients"
 */
export interface user_team_clients_stddev_pop_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_team_clients"
 */
export interface user_team_clients_stddev_samp_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_team_clients"
 */
export interface user_team_clients_sum_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_team_clients"
 */
export interface user_team_clients_var_pop_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_team_clients"
 */
export interface user_team_clients_var_samp_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_team_clients"
 */
export interface user_team_clients_variance_order_by {
  id?: order_by | null;
  managerId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by aggregate values of table "user_team_members"
 */
export interface user_team_members_aggregate_order_by {
  avg?: user_team_members_avg_order_by | null;
  count?: order_by | null;
  max?: user_team_members_max_order_by | null;
  min?: user_team_members_min_order_by | null;
  stddev?: user_team_members_stddev_order_by | null;
  stddev_pop?: user_team_members_stddev_pop_order_by | null;
  stddev_samp?: user_team_members_stddev_samp_order_by | null;
  sum?: user_team_members_sum_order_by | null;
  var_pop?: user_team_members_var_pop_order_by | null;
  var_samp?: user_team_members_var_samp_order_by | null;
  variance?: user_team_members_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_team_members"
 */
export interface user_team_members_avg_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_members". All fields are combined with a logical 'AND'.
 */
export interface user_team_members_bool_exp {
  _and?: user_team_members_bool_exp[] | null;
  _not?: user_team_members_bool_exp | null;
  _or?: user_team_members_bool_exp[] | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * order by max() on columns of table "user_team_members"
 */
export interface user_team_members_max_order_by {
  endAt?: order_by | null;
  id?: order_by | null;
  role?: order_by | null;
  startAt?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by min() on columns of table "user_team_members"
 */
export interface user_team_members_min_order_by {
  endAt?: order_by | null;
  id?: order_by | null;
  role?: order_by | null;
  startAt?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_team_members"
 */
export interface user_team_members_stddev_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_team_members"
 */
export interface user_team_members_stddev_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_team_members"
 */
export interface user_team_members_stddev_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_team_members"
 */
export interface user_team_members_sum_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_team_members"
 */
export interface user_team_members_var_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_team_members"
 */
export interface user_team_members_var_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_team_members"
 */
export interface user_team_members_variance_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userTeamId?: order_by | null;
}

/**
 * order by aggregate values of table "user_teams"
 */
export interface user_teams_aggregate_order_by {
  avg?: user_teams_avg_order_by | null;
  count?: order_by | null;
  max?: user_teams_max_order_by | null;
  min?: user_teams_min_order_by | null;
  stddev?: user_teams_stddev_order_by | null;
  stddev_pop?: user_teams_stddev_pop_order_by | null;
  stddev_samp?: user_teams_stddev_samp_order_by | null;
  sum?: user_teams_sum_order_by | null;
  var_pop?: user_teams_var_pop_order_by | null;
  var_samp?: user_teams_var_samp_order_by | null;
  variance?: user_teams_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_teams"
 */
export interface user_teams_avg_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_teams". All fields are combined with a logical 'AND'.
 */
export interface user_teams_bool_exp {
  _and?: user_teams_bool_exp[] | null;
  _not?: user_teams_bool_exp | null;
  _or?: user_teams_bool_exp[] | null;
  child_user_teams?: user_teams_bool_exp | null;
  id?: Int_comparison_exp | null;
  isPublic?: Boolean_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  parent_user_team?: user_teams_bool_exp | null;
  standupTime?: timestamptz_comparison_exp | null;
  teamLeadUserId?: Int_comparison_exp | null;
  teamName?: String_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  user?: users_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_team_members?: user_team_members_bool_exp | null;
}

/**
 * order by max() on columns of table "user_teams"
 */
export interface user_teams_max_order_by {
  id?: order_by | null;
  standupTime?: order_by | null;
  teamLeadUserId?: order_by | null;
  teamName?: order_by | null;
}

/**
 * order by min() on columns of table "user_teams"
 */
export interface user_teams_min_order_by {
  id?: order_by | null;
  standupTime?: order_by | null;
  teamLeadUserId?: order_by | null;
  teamName?: order_by | null;
}

/**
 * Ordering options when selecting data from "user_teams".
 */
export interface user_teams_order_by {
  child_user_teams_aggregate?: user_teams_aggregate_order_by | null;
  id?: order_by | null;
  isPublic?: order_by | null;
  notifications_aggregate?: notifications_aggregate_order_by | null;
  parent_user_team?: user_teams_order_by | null;
  standupTime?: order_by | null;
  teamLeadUserId?: order_by | null;
  teamName?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  user?: users_order_by | null;
  user_team_clients_aggregate?: user_team_clients_aggregate_order_by | null;
  user_team_members_aggregate?: user_team_members_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "user_teams"
 */
export interface user_teams_stddev_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_teams"
 */
export interface user_teams_stddev_pop_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_teams"
 */
export interface user_teams_stddev_samp_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_teams"
 */
export interface user_teams_sum_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_teams"
 */
export interface user_teams_var_pop_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_teams"
 */
export interface user_teams_var_samp_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_teams"
 */
export interface user_teams_variance_order_by {
  id?: order_by | null;
  teamLeadUserId?: order_by | null;
}

/**
 * order by aggregate values of table "user_time_logs_view"
 */
export interface user_time_logs_view_aggregate_order_by {
  avg?: user_time_logs_view_avg_order_by | null;
  count?: order_by | null;
  max?: user_time_logs_view_max_order_by | null;
  min?: user_time_logs_view_min_order_by | null;
  stddev?: user_time_logs_view_stddev_order_by | null;
  stddev_pop?: user_time_logs_view_stddev_pop_order_by | null;
  stddev_samp?: user_time_logs_view_stddev_samp_order_by | null;
  sum?: user_time_logs_view_sum_order_by | null;
  var_pop?: user_time_logs_view_var_pop_order_by | null;
  var_samp?: user_time_logs_view_var_samp_order_by | null;
  variance?: user_time_logs_view_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_avg_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_time_logs_view". All fields are combined with a logical 'AND'.
 */
export interface user_time_logs_view_bool_exp {
  _and?: user_time_logs_view_bool_exp[] | null;
  _not?: user_time_logs_view_bool_exp | null;
  _or?: user_time_logs_view_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  taskWorkId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  timeSpentInSecs?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userInvoiceId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_max_order_by {
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  finishedAt?: order_by | null;
  id?: order_by | null;
  startedAt?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by min() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_min_order_by {
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  finishedAt?: order_by | null;
  id?: order_by | null;
  startedAt?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_stddev_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_sum_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_var_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_var_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_time_logs_view"
 */
export interface user_time_logs_view_variance_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  taskWorkId?: order_by | null;
  ticketId?: order_by | null;
  timeSpentInSecs?: order_by | null;
  userId?: order_by | null;
  userInvoiceId?: order_by | null;
}

/**
 * order by aggregate values of table "user_work_logs"
 */
export interface user_work_logs_aggregate_order_by {
  avg?: user_work_logs_avg_order_by | null;
  count?: order_by | null;
  max?: user_work_logs_max_order_by | null;
  min?: user_work_logs_min_order_by | null;
  stddev?: user_work_logs_stddev_order_by | null;
  stddev_pop?: user_work_logs_stddev_pop_order_by | null;
  stddev_samp?: user_work_logs_stddev_samp_order_by | null;
  sum?: user_work_logs_sum_order_by | null;
  var_pop?: user_work_logs_var_pop_order_by | null;
  var_samp?: user_work_logs_var_samp_order_by | null;
  variance?: user_work_logs_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "user_work_logs"
 */
export interface user_work_logs_arr_rel_insert_input {
  data: user_work_logs_insert_input[];
  on_conflict?: user_work_logs_on_conflict | null;
}

/**
 * order by avg() on columns of table "user_work_logs"
 */
export interface user_work_logs_avg_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_work_logs". All fields are combined with a logical 'AND'.
 */
export interface user_work_logs_bool_exp {
  _and?: user_work_logs_bool_exp[] | null;
  _not?: user_work_logs_bool_exp | null;
  _or?: user_work_logs_bool_exp[] | null;
  approvalStatus?: work_log_approval_status_types_enum_comparison_exp | null;
  approvedBy?: Int_comparison_exp | null;
  billableToClient?: Boolean_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  meetingType?: work_log_meeting_types_enum_comparison_exp | null;
  source?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  status?: work_log_status_types_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
  technologyId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByApprovedby?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  workDescription?: String_comparison_exp | null;
  workType?: work_log_types_enum_comparison_exp | null;
  work_log_approval_status_type?: work_log_approval_status_types_bool_exp | null;
  work_log_meeting_type?: work_log_meeting_types_bool_exp | null;
  work_log_status_type?: work_log_status_types_bool_exp | null;
  work_log_type?: work_log_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_work_logs"
 */
export interface user_work_logs_insert_input {
  approvalStatus?: work_log_approval_status_types_enum | null;
  billableToClient?: boolean | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  meetingType?: work_log_meeting_types_enum | null;
  source?: string | null;
  startAt?: hasura_timestamptz | null;
  status?: work_log_status_types_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  technologyId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByApprovedby?: users_obj_rel_insert_input | null;
  userId?: number | null;
  workDescription?: string | null;
  workType?: work_log_types_enum | null;
}

/**
 * order by max() on columns of table "user_work_logs"
 */
export interface user_work_logs_max_order_by {
  approvedBy?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  source?: order_by | null;
  startAt?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
  workDescription?: order_by | null;
}

/**
 * order by min() on columns of table "user_work_logs"
 */
export interface user_work_logs_min_order_by {
  approvedBy?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  source?: order_by | null;
  startAt?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
  workDescription?: order_by | null;
}

/**
 * on_conflict condition type for table "user_work_logs"
 */
export interface user_work_logs_on_conflict {
  constraint: user_work_logs_constraint;
  update_columns: user_work_logs_update_column[];
  where?: user_work_logs_bool_exp | null;
}

/**
 * order by stddev() on columns of table "user_work_logs"
 */
export interface user_work_logs_stddev_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_work_logs"
 */
export interface user_work_logs_stddev_pop_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_work_logs"
 */
export interface user_work_logs_stddev_samp_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_work_logs"
 */
export interface user_work_logs_sum_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_work_logs"
 */
export interface user_work_logs_var_pop_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_work_logs"
 */
export interface user_work_logs_var_samp_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_work_logs"
 */
export interface user_work_logs_variance_order_by {
  approvedBy?: order_by | null;
  clientProjectId?: order_by | null;
  id?: order_by | null;
  taskId?: order_by | null;
  technologyId?: order_by | null;
  ticketId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "users"
 */
export interface users_aggregate_order_by {
  avg?: users_avg_order_by | null;
  count?: order_by | null;
  max?: users_max_order_by | null;
  min?: users_min_order_by | null;
  stddev?: users_stddev_order_by | null;
  stddev_pop?: users_stddev_pop_order_by | null;
  stddev_samp?: users_stddev_samp_order_by | null;
  sum?: users_sum_order_by | null;
  var_pop?: users_var_pop_order_by | null;
  var_samp?: users_var_samp_order_by | null;
  variance?: users_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "users"
 */
export interface users_arr_rel_insert_input {
  data: users_insert_input[];
  on_conflict?: users_on_conflict | null;
}

/**
 * order by avg() on columns of table "users"
 */
export interface users_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'.
 */
export interface users_bool_exp {
  _and?: users_bool_exp[] | null;
  _not?: users_bool_exp | null;
  _or?: users_bool_exp[] | null;
  admin?: admin_bool_exp | null;
  admins?: admin_bool_exp | null;
  adminsByUserid?: admin_bool_exp | null;
  agency_user?: agency_users_bool_exp | null;
  agency_users?: agency_users_bool_exp | null;
  avatarUrl?: String_comparison_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_managers_view?: client_managers_view_bool_exp | null;
  client_project_users?: client_project_users_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  client_users?: client_users_bool_exp | null;
  defaultEmail?: String_comparison_exp | null;
  dev_users?: user_developer_view_bool_exp | null;
  developer?: developers_bool_exp | null;
  developers?: developers_bool_exp | null;
  discussion_comment_reads?: discussion_comment_reads_bool_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  firstName?: String_comparison_exp | null;
  gcp_storage_objects?: gcp_storage_objects_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  mentorships?: mentorships_bool_exp | null;
  mentorshipsByMentoruserid?: mentorships_bool_exp | null;
  name?: String_comparison_exp | null;
  nickname?: String_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  project_managers?: project_managers_bool_exp | null;
  requestedTaskCreditApprovals?: task_credit_approval_bool_exp | null;
  reviewedTaskCreditApprovals?: task_credit_approval_bool_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  task_submissions?: task_submissions_bool_exp | null;
  telephone?: String_comparison_exp | null;
  ticketCreditApprovalsByReviewedbyuserid?: ticket_credit_approvals_bool_exp | null;
  ticket_billable_overrides?: ticket_billable_overrides_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  ticket_refunds?: ticket_refund_bool_exp | null;
  timezone?: String_comparison_exp | null;
  userEmailByDefaultEmail?: user_emails_bool_exp | null;
  userEmailByUnverifiedemail?: user_emails_bool_exp | null;
  userLeavesByApprovedByUserId?: user_leaves_bool_exp | null;
  userWorkLogsByApprovedby?: user_work_logs_bool_exp | null;
  user_contracts?: user_contracts_bool_exp | null;
  user_daily_facts_structures?: user_daily_facts_structure_bool_exp | null;
  user_emails?: user_emails_bool_exp | null;
  user_events?: user_events_bool_exp | null;
  user_facts_structures?: user_facts_structure_bool_exp | null;
  user_invoice_balances?: user_invoice_balances_bool_exp | null;
  user_invoices?: user_invoices_bool_exp | null;
  user_leaves?: user_leaves_bool_exp | null;
  user_opportunity_interests?: user_opportunity_interests_bool_exp | null;
  user_payments?: user_payments_bool_exp | null;
  user_skills?: user_skills_bool_exp | null;
  user_standups_sla?: user_standups_sla_view_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_team_members?: user_team_members_bool_exp | null;
  user_teams?: user_teams_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "users"
 */
export interface users_insert_input {
  agency_user?: agency_users_obj_rel_insert_input | null;
  agency_users?: agency_users_arr_rel_insert_input | null;
  client_blockers?: client_blockers_arr_rel_insert_input | null;
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  client_users?: client_users_arr_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  developers?: developers_arr_rel_insert_input | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  gcp_storage_objects?: gcp_storage_objects_arr_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
  project_daily_standups?: project_daily_standup_arr_rel_insert_input | null;
  requestedTaskCreditApprovals?: task_credit_approval_arr_rel_insert_input | null;
  reviewedTaskCreditApprovals?: task_credit_approval_arr_rel_insert_input | null;
  task_credit_overrides?: task_credit_overrides_arr_rel_insert_input | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  task_submissions?: task_submissions_arr_rel_insert_input | null;
  ticketCreditApprovalsByReviewedbyuserid?: ticket_credit_approvals_arr_rel_insert_input | null;
  ticket_billable_overrides?: ticket_billable_overrides_arr_rel_insert_input | null;
  ticket_credit_approvals?: ticket_credit_approvals_arr_rel_insert_input | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  userEmailByDefaultEmail?: user_emails_obj_rel_insert_input | null;
  userEmailByUnverifiedemail?: user_emails_obj_rel_insert_input | null;
  userLeavesByApprovedByUserId?: user_leaves_arr_rel_insert_input | null;
  userWorkLogsByApprovedby?: user_work_logs_arr_rel_insert_input | null;
  user_contracts?: user_contracts_arr_rel_insert_input | null;
  user_emails?: user_emails_arr_rel_insert_input | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_leaves?: user_leaves_arr_rel_insert_input | null;
  user_opportunity_interests?: user_opportunity_interests_arr_rel_insert_input | null;
  user_payments?: user_payments_arr_rel_insert_input | null;
  user_skills?: user_skills_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "users"
 */
export interface users_max_order_by {
  avatarUrl?: order_by | null;
  defaultEmail?: order_by | null;
  firstName?: order_by | null;
  id?: order_by | null;
  lastName?: order_by | null;
  login?: order_by | null;
  name?: order_by | null;
  nickname?: order_by | null;
  telephone?: order_by | null;
  timezone?: order_by | null;
}

/**
 * order by min() on columns of table "users"
 */
export interface users_min_order_by {
  avatarUrl?: order_by | null;
  defaultEmail?: order_by | null;
  firstName?: order_by | null;
  id?: order_by | null;
  lastName?: order_by | null;
  login?: order_by | null;
  name?: order_by | null;
  nickname?: order_by | null;
  telephone?: order_by | null;
  timezone?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "users"
 */
export interface users_obj_rel_insert_input {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null;
}

/**
 * on_conflict condition type for table "users"
 */
export interface users_on_conflict {
  constraint: users_constraint;
  update_columns: users_update_column[];
  where?: users_bool_exp | null;
}

/**
 * Ordering options when selecting data from "users".
 */
export interface users_order_by {
  admin?: admin_order_by | null;
  adminsByUserid_aggregate?: admin_aggregate_order_by | null;
  admins_aggregate?: admin_aggregate_order_by | null;
  agency_user?: agency_users_order_by | null;
  agency_users_aggregate?: agency_users_aggregate_order_by | null;
  avatarUrl?: order_by | null;
  client_blockers_aggregate?: client_blockers_aggregate_order_by | null;
  client_managers_view_aggregate?: client_managers_view_aggregate_order_by | null;
  client_project_users_aggregate?: client_project_users_aggregate_order_by | null;
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  client_users_aggregate?: client_users_aggregate_order_by | null;
  defaultEmail?: order_by | null;
  dev_users?: user_developer_view_order_by | null;
  developer?: developers_order_by | null;
  developers_aggregate?: developers_aggregate_order_by | null;
  discussion_comment_reads_aggregate?: discussion_comment_reads_aggregate_order_by | null;
  discussion_comments_aggregate?: discussion_comments_aggregate_order_by | null;
  firstName?: order_by | null;
  gcp_storage_objects_aggregate?: gcp_storage_objects_aggregate_order_by | null;
  git_users_aggregate?: git_users_aggregate_order_by | null;
  id?: order_by | null;
  lastName?: order_by | null;
  login?: order_by | null;
  mentorshipsByMentoruserid_aggregate?: mentorships_aggregate_order_by | null;
  mentorships_aggregate?: mentorships_aggregate_order_by | null;
  name?: order_by | null;
  nickname?: order_by | null;
  notifications_aggregate?: notifications_aggregate_order_by | null;
  project_daily_standups_aggregate?: project_daily_standup_aggregate_order_by | null;
  project_managers_aggregate?: project_managers_aggregate_order_by | null;
  requestedTaskCreditApprovals_aggregate?: task_credit_approval_aggregate_order_by | null;
  reviewedTaskCreditApprovals_aggregate?: task_credit_approval_aggregate_order_by | null;
  task_daily_standups_aggregate?: task_daily_standups_aggregate_order_by | null;
  task_submissions_aggregate?: task_submissions_aggregate_order_by | null;
  telephone?: order_by | null;
  ticketCreditApprovalsByReviewedbyuserid_aggregate?: ticket_credit_approvals_aggregate_order_by | null;
  ticket_billable_overrides_aggregate?: ticket_billable_overrides_aggregate_order_by | null;
  ticket_credit_approvals_aggregate?: ticket_credit_approvals_aggregate_order_by | null;
  ticket_daily_standups_aggregate?: ticket_daily_standups_aggregate_order_by | null;
  ticket_refunds_aggregate?: ticket_refund_aggregate_order_by | null;
  timezone?: order_by | null;
  userEmailByDefaultEmail?: user_emails_order_by | null;
  userEmailByUnverifiedemail?: user_emails_order_by | null;
  userLeavesByApprovedByUserId_aggregate?: user_leaves_aggregate_order_by | null;
  userWorkLogsByApprovedby_aggregate?: user_work_logs_aggregate_order_by | null;
  user_contracts_aggregate?: user_contracts_aggregate_order_by | null;
  user_daily_facts_structures_aggregate?: user_daily_facts_structure_aggregate_order_by | null;
  user_emails_aggregate?: user_emails_aggregate_order_by | null;
  user_events_aggregate?: user_events_aggregate_order_by | null;
  user_facts_structures_aggregate?: user_facts_structure_aggregate_order_by | null;
  user_invoice_balances_aggregate?: user_invoice_balances_aggregate_order_by | null;
  user_invoices_aggregate?: user_invoices_aggregate_order_by | null;
  user_leaves_aggregate?: user_leaves_aggregate_order_by | null;
  user_opportunity_interests_aggregate?: user_opportunity_interests_aggregate_order_by | null;
  user_payments_aggregate?: user_payments_aggregate_order_by | null;
  user_skills_aggregate?: user_skills_aggregate_order_by | null;
  user_standups_sla?: user_standups_sla_view_order_by | null;
  user_team_clients_aggregate?: user_team_clients_aggregate_order_by | null;
  user_team_members_aggregate?: user_team_members_aggregate_order_by | null;
  user_teams_aggregate?: user_teams_aggregate_order_by | null;
  user_time_logs_aggregate?: user_time_logs_view_aggregate_order_by | null;
  user_work_logs_aggregate?: user_work_logs_aggregate_order_by | null;
}

/**
 * input type for updating data in table "users"
 */
export interface users_set_input {
  avatarUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  login?: string | null;
  nickname?: string | null;
  telephone?: string | null;
  timezone?: string | null;
}

/**
 * order by stddev() on columns of table "users"
 */
export interface users_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "users"
 */
export interface users_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "users"
 */
export interface users_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "users"
 */
export interface users_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "users"
 */
export interface users_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "users"
 */
export interface users_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "users"
 */
export interface users_variance_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: hasura_uuid | null;
  _gt?: hasura_uuid | null;
  _gte?: hasura_uuid | null;
  _in?: hasura_uuid[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_uuid | null;
  _lte?: hasura_uuid | null;
  _neq?: hasura_uuid | null;
  _nin?: hasura_uuid[] | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_approval_status_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_approval_status_types_bool_exp {
  _and?: work_log_approval_status_types_bool_exp[] | null;
  _not?: work_log_approval_status_types_bool_exp | null;
  _or?: work_log_approval_status_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_approval_status_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_approval_status_types_enum_comparison_exp {
  _eq?: work_log_approval_status_types_enum | null;
  _in?: work_log_approval_status_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_approval_status_types_enum | null;
  _nin?: work_log_approval_status_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_meeting_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_meeting_types_bool_exp {
  _and?: work_log_meeting_types_bool_exp[] | null;
  _not?: work_log_meeting_types_bool_exp | null;
  _or?: work_log_meeting_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_meeting_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_meeting_types_enum_comparison_exp {
  _eq?: work_log_meeting_types_enum | null;
  _in?: work_log_meeting_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_meeting_types_enum | null;
  _nin?: work_log_meeting_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_status_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_status_types_bool_exp {
  _and?: work_log_status_types_bool_exp[] | null;
  _not?: work_log_status_types_bool_exp | null;
  _or?: work_log_status_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_status_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_status_types_enum_comparison_exp {
  _eq?: work_log_status_types_enum | null;
  _in?: work_log_status_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_status_types_enum | null;
  _nin?: work_log_status_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_types_bool_exp {
  _and?: work_log_types_bool_exp[] | null;
  _not?: work_log_types_bool_exp | null;
  _or?: work_log_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_types_enum_comparison_exp {
  _eq?: work_log_types_enum | null;
  _in?: work_log_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_types_enum | null;
  _nin?: work_log_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "work_machine_os". All fields are combined with a logical 'AND'.
 */
export interface work_machine_os_bool_exp {
  _and?: work_machine_os_bool_exp[] | null;
  _not?: work_machine_os_bool_exp | null;
  _or?: work_machine_os_bool_exp[] | null;
  developers?: developers_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "work_machine_os".
 */
export interface work_machine_os_order_by {
  developers_aggregate?: developers_aggregate_order_by | null;
  type?: order_by | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
