import { Button, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ClientGettingStartedModal from 'components/modals/ClientGettingStarted';
import { StepperState } from 'components/modals/ClientGettingStarted/StepperNavigations';
import React, { useState } from 'react';
import { Theme } from 'template/theme';
import { OnBoardingScreenProps } from './types';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  dialogContent: {
    height: 600,
    overflow: 'hidden',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      width: '1000px',
    },
  },
}));

const OnBoardingScreen = (props: OnBoardingScreenProps) => {
  const {
    displayButton,
    displayMessage,
    className = '',
    userData,
    ...rest
  } = props;
  const classes = useStyles();
  const [openSetup, setOpenSetup] = useState(false);

  const handleFinish = () => {
    setOpenSetup(false);
    window.location.reload();
  };

  const { companyName, clientId, firstName, lastName, email } = userData || {};
  const initStepperState: StepperState = {
    companyDetails: {
      companyName,
      clientId,
    },
    calendlyPrefill: {
      firstName,
      lastName,
      email,
    },
  };

  return (
    <div {...rest} className={clsx(classes[className])}>
      {userData?.firstName ? (
        <div className={classes.title}>
          <Typography variant="h3">
            Welcome to GitStart {userData?.firstName}!
          </Typography>
        </div>
      ) : null}
      {displayMessage && (
        <div className={classes.content}>{props.children}</div>
      )}
      {displayButton && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<AccountCircleIcon />}
          onClick={() => setOpenSetup(true)}
        >
          COMPLETE SETUP
        </Button>
      )}
      <ClientGettingStartedModal
        open={openSetup}
        onClose={() => setOpenSetup(false)}
        onFinish={handleFinish}
        start={1}
        state={initStepperState}
      />
    </div>
  );
};

export default OnBoardingScreen;
