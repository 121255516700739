import React from 'react';
import { KBarResults, useDeepMatches } from 'kbar';
import ResultItem from './ResultItem';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  groupName: {
    padding: '8px 16px',
    fontSize: '10px',
    textTransform: 'uppercase' as const,
    opacity: 0.5,
  },
});

function RenderResults() {
  const { results } = useDeepMatches();
  const classes = useStyles();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div className={classes.groupName}>{item}</div>
        ) : (
          <ResultItem action={item} active={active} />
        )
      }
    />
  );
}

export default RenderResults;
