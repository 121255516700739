/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "activities"
 */
export enum activities_constraint {
  activities_pkey = 'activities_pkey',
}

/**
 * update columns of table "activities"
 */
export enum activities_update_column {
  assigned_user_id = 'assigned_user_id',
  id = 'id',
  inserted_at = 'inserted_at',
  instance_id = 'instance_id',
  related_entity = 'related_entity',
  status = 'status',
  ticket_id = 'ticket_id',
  title = 'title',
  updated_at = 'updated_at',
}

/**
 * unique or primary key constraints on table "admin"
 */
export enum admin_constraint {
  PK_e032310bcef831fb83101899b10 = 'PK_e032310bcef831fb83101899b10',
  REL_f8a889c4362d78f056960ca6da = 'REL_f8a889c4362d78f056960ca6da',
}

/**
 * update columns of table "admin"
 */
export enum admin_update_column {
  id = 'id',
  userId = 'userId',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "agencies"
 */
export enum agencies_constraint {
  PK_8ab1f1f53f56c8255b0d7e68b28 = 'PK_8ab1f1f53f56c8255b0d7e68b28',
  UQ_e84f1da241204c195429e0e444d = 'UQ_e84f1da241204c195429e0e444d',
}

/**
 * update columns of table "agencies"
 */
export enum agencies_update_column {
  addressAddress = 'addressAddress',
  createdAt = 'createdAt',
  id = 'id',
  legalName = 'legalName',
  name = 'name',
  sid = 'sid',
  updatedAt = 'updatedAt',
  userDomains = 'userDomains',
}

/**
 * unique or primary key constraints on table "agency_invoices"
 */
export enum agency_invoices_constraint {
  PK_6169defa906f59c4709d35a47a4 = 'PK_6169defa906f59c4709d35a47a4',
}

/**
 * update columns of table "agency_invoices"
 */
export enum agency_invoices_update_column {
  agencyId = 'agencyId',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  dueAt = 'dueAt',
  id = 'id',
  invoiceCode = 'invoiceCode',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "agency_payment_types"
 */
export enum agency_payment_types_constraint {
  PK_c3a39e8fe1fa716ef4d529d88ed = 'PK_c3a39e8fe1fa716ef4d529d88ed',
}

/**
 * update columns of table "agency_payment_types"
 */
export enum agency_payment_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "agency_payments"
 */
export enum agency_payments_constraint {
  PK_5b356505119b8a5c530675f7fb8 = 'PK_5b356505119b8a5c530675f7fb8',
  UQ_b26372d21555b581a1384851444 = 'UQ_b26372d21555b581a1384851444',
}

/**
 * unique or primary key constraints on table "agency_payments_status"
 */
export enum agency_payments_status_constraint {
  PK_d8035bcf3109bbf590561417ee8 = 'PK_d8035bcf3109bbf590561417ee8',
}

export enum agency_payments_status_enum {
  failed = 'failed',
  in_progress = 'in_progress',
  pending = 'pending',
  successful = 'successful',
}

/**
 * update columns of table "agency_payments_status"
 */
export enum agency_payments_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * update columns of table "agency_payments"
 */
export enum agency_payments_update_column {
  agencyId = 'agencyId',
  amountInUSD = 'amountInUSD',
  channelTransactionId = 'channelTransactionId',
  createdAt = 'createdAt',
  finishedAt = 'finishedAt',
  id = 'id',
  paymentType = 'paymentType',
  status = 'status',
}

/**
 * unique or primary key constraints on table "agency_user_roles"
 */
export enum agency_user_roles_constraint {
  PK_78eac9eb51c7267763a78f77a08 = 'PK_78eac9eb51c7267763a78f77a08',
}

export enum agency_user_roles_enum {
  admin = 'admin',
  internal = 'internal',
  member = 'member',
}

/**
 * update columns of table "agency_user_roles"
 */
export enum agency_user_roles_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "agency_users"
 */
export enum agency_users_constraint {
  PK_ae72ca39f0a748bf74eef99df42 = 'PK_ae72ca39f0a748bf74eef99df42',
}

/**
 * update columns of table "agency_users"
 */
export enum agency_users_update_column {
  agencyId = 'agencyId',
  id = 'id',
  role = 'role',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "blockers"
 */
export enum blockers_constraint {
  blockers_pkey = 'blockers_pkey',
  one_unresolved_blocker_per_pr_estimate = 'one_unresolved_blocker_per_pr_estimate',
}

/**
 * update columns of table "blockers"
 */
export enum blockers_update_column {
  id = 'id',
  inserted_at = 'inserted_at',
  pull_request_estimate_id = 'pull_request_estimate_id',
  pull_request_id = 'pull_request_id',
  resolved_at = 'resolved_at',
  resolved_by_user_id = 'resolved_by_user_id',
  ticket_id = 'ticket_id',
  type = 'type',
  updated_at = 'updated_at',
}

/**
 * unique or primary key constraints on table "bonus_types"
 */
export enum bonus_types_constraint {
  PK_ae65e47304014aca57f2b882ecf = 'PK_ae65e47304014aca57f2b882ecf',
}

export enum bonus_types_enum {
  advance_bonus = 'advance_bonus',
  annual_leave = 'annual_leave',
  developer_referral = 'developer_referral',
  holiday_bonus = 'holiday_bonus',
  public_holiday = 'public_holiday',
  special_approved_leave = 'special_approved_leave',
  weekly_bonus = 'weekly_bonus',
  workplace_bonus = 'workplace_bonus',
}

/**
 * update columns of table "bonus_types"
 */
export enum bonus_types_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "bot_platform_types"
 */
export enum bot_platform_types_constraint {
  PK_f2713edc83199e55a560bc0800c = 'PK_f2713edc83199e55a560bc0800c',
}

export enum bot_platform_types_enum {
  asana = 'asana',
  clubhouse = 'clubhouse',
  email = 'email',
  github = 'github',
  gitlab = 'gitlab',
  jira = 'jira',
  linear = 'linear',
  microsoft_teams = 'microsoft_teams',
  monday = 'monday',
  slack = 'slack',
  trello = 'trello',
}

/**
 * update columns of table "bot_platform_types"
 */
export enum bot_platform_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "bots"
 */
export enum bots_constraint {
  PK_8b1b0180229dec2cbfdf5e776e4 = 'PK_8b1b0180229dec2cbfdf5e776e4',
  REL_36c6c77587d5c9279ff84798bf = 'REL_36c6c77587d5c9279ff84798bf',
  UQ_19a63e0c24c6c2b7ed580ad08f4 = 'UQ_19a63e0c24c6c2b7ed580ad08f4',
  UQ_cfcda39e91d105b29d4fbbc040a = 'UQ_cfcda39e91d105b29d4fbbc040a',
  UQ_e79c244f85c2ba644ca038e9d0c = 'UQ_e79c244f85c2ba644ca038e9d0c',
}

/**
 * update columns of table "bots"
 */
export enum bots_update_column {
  clientBotId = 'clientBotId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  githubAccountSid = 'githubAccountSid',
  gitlabUserSid = 'gitlabUserSid',
  id = 'id',
  jiraAccountSid = 'jiraAccountSid',
  linearAccountSid = 'linearAccountSid',
  platformId = 'platformId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "candidate_stage"
 */
export enum candidate_stage_constraint {
  PK_0bd65f365a75982be3729b2a106 = 'PK_0bd65f365a75982be3729b2a106',
}

export enum candidate_stage_enum {
  backlog = 'backlog',
  failed_culture_screen = 'failed_culture_screen',
  failed_hack_week = 'failed_hack_week',
  failed_tech_screen = 'failed_tech_screen',
  hired = 'hired',
  invited_for_hack_week = 'invited_for_hack_week',
  missed_culture_screen = 'missed_culture_screen',
  missed_tech_screen = 'missed_tech_screen',
  needs_culture_screen = 'needs_culture_screen',
  needs_hack_week = 'needs_hack_week',
  needs_tech_screen = 'needs_tech_screen',
  on_hold = 'on_hold',
  undergoing_hack_week = 'undergoing_hack_week',
  unqualified = 'unqualified',
}

/**
 * update columns of table "candidate_stage"
 */
export enum candidate_stage_update_column {
  type = 'type',
}

/**
 * unique or primary key constraints on table "career_ladder_levels"
 */
export enum career_ladder_levels_constraint {
  PK_69d92d166542dc6aa21c897bcdd = 'PK_69d92d166542dc6aa21c897bcdd',
}

/**
 * update columns of table "career_ladder_levels"
 */
export enum career_ladder_levels_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "clickup_apps"
 */
export enum clickup_apps_constraint {
  PK_9d7aa994a9b4b9675b9facb4c20 = 'PK_9d7aa994a9b4b9675b9facb4c20',
  REL_05713a0aadb4ad358437f10e0e = 'REL_05713a0aadb4ad358437f10e0e',
  UQ_7e34fc7001ef135ddcb46bf1dcb = 'UQ_7e34fc7001ef135ddcb46bf1dcb',
}

/**
 * update columns of table "clickup_apps"
 */
export enum clickup_apps_update_column {
  accessToken = 'accessToken',
  apiToken = 'apiToken',
  authCode = 'authCode',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
  webhookSid = 'webhookSid',
}

/**
 * unique or primary key constraints on table "clickup_folders"
 */
export enum clickup_folders_constraint {
  PK_17fe5c6c7304328ca645a5fefac = 'PK_17fe5c6c7304328ca645a5fefac',
  REL_a01e6937dca5dab77aa8e35a6f = 'REL_a01e6937dca5dab77aa8e35a6f',
  UQ_30c681ecd7cdc4b2f3528b1793e = 'UQ_30c681ecd7cdc4b2f3528b1793e',
}

/**
 * update columns of table "clickup_folders"
 */
export enum clickup_folders_update_column {
  archive = 'archive',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  hidden = 'hidden',
  id = 'id',
  name = 'name',
  orderindex = 'orderindex',
  ovverrideStatuses = 'ovverrideStatuses',
  sid = 'sid',
  spaceSid = 'spaceSid',
  syncEntityId = 'syncEntityId',
  taskCount = 'taskCount',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "clickup_lists"
 */
export enum clickup_lists_constraint {
  PK_ea37552a7d3407ea7b57ccf94a4 = 'PK_ea37552a7d3407ea7b57ccf94a4',
  REL_2da1bcf052a32ee03ebf204b4d = 'REL_2da1bcf052a32ee03ebf204b4d',
  UQ_fd430ced390ff092c783ae605af = 'UQ_fd430ced390ff092c783ae605af',
}

/**
 * update columns of table "clickup_lists"
 */
export enum clickup_lists_update_column {
  archive = 'archive',
  assignee = 'assignee',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  dueDate = 'dueDate',
  folderSid = 'folderSid',
  id = 'id',
  name = 'name',
  orderindex = 'orderindex',
  override_statuses = 'override_statuses',
  priority = 'priority',
  sid = 'sid',
  spaceSid = 'spaceSid',
  startDate = 'startDate',
  syncEntityId = 'syncEntityId',
  taskCount = 'taskCount',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "clickup_spaces"
 */
export enum clickup_spaces_constraint {
  PK_c57d0aae1272344f64e33cbabba = 'PK_c57d0aae1272344f64e33cbabba',
  REL_f465e668610bc62e14ce87244a = 'REL_f465e668610bc62e14ce87244a',
  UQ_6da544a20fddf0565a3ce4add36 = 'UQ_6da544a20fddf0565a3ce4add36',
}

/**
 * update columns of table "clickup_spaces"
 */
export enum clickup_spaces_update_column {
  archive = 'archive',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  features = 'features',
  id = 'id',
  multipleAssignees = 'multipleAssignees',
  name = 'name',
  private = 'private',
  sid = 'sid',
  spaceStatuses = 'spaceStatuses',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "clickup_task_comments"
 */
export enum clickup_task_comments_constraint {
  PK_cbb086f2b2a47c7f033492d7ae0 = 'PK_cbb086f2b2a47c7f033492d7ae0',
  REL_dac355b3c450769aa7c8bb5c49 = 'REL_dac355b3c450769aa7c8bb5c49',
  REL_dd8cf2f6fcc820266d66e89143 = 'REL_dd8cf2f6fcc820266d66e89143',
  UQ_468bcc13b788669e386a81cedf0 = 'UQ_468bcc13b788669e386a81cedf0',
}

/**
 * update columns of table "clickup_task_comments"
 */
export enum clickup_task_comments_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  taskSid = 'taskSid',
  textContent = 'textContent',
  updatedAt = 'updatedAt',
  userSid = 'userSid',
}

/**
 * unique or primary key constraints on table "clickup_task_status_types"
 */
export enum clickup_task_status_types_constraint {
  PK_a318143ae4c2b4f895c2205ccb2 = 'PK_a318143ae4c2b4f895c2205ccb2',
}

/**
 * update columns of table "clickup_task_status_types"
 */
export enum clickup_task_status_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "clickup_tasks"
 */
export enum clickup_tasks_constraint {
  PK_fc707e84ddac6599835e4337520 = 'PK_fc707e84ddac6599835e4337520',
  REL_716bef015d3773aa0aeae01c2a = 'REL_716bef015d3773aa0aeae01c2a',
  REL_9251faaa819693bc900c77b995 = 'REL_9251faaa819693bc900c77b995',
  UQ_c5c22c2d77af06cfa770ca0e56b = 'UQ_c5c22c2d77af06cfa770ca0e56b',
}

/**
 * update columns of table "clickup_tasks"
 */
export enum clickup_tasks_update_column {
  archive = 'archive',
  clickupTaskAssignedSids = 'clickupTaskAssignedSids',
  clickupTaskWatechedSids = 'clickupTaskWatechedSids',
  createdAt = 'createdAt',
  creatorSid = 'creatorSid',
  deletedAt = 'deletedAt',
  description = 'description',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  listSid = 'listSid',
  name = 'name',
  priority = 'priority',
  sid = 'sid',
  status = 'status',
  syncEntityId = 'syncEntityId',
  tags = 'tags',
  taskStatusType = 'taskStatusType',
  textContent = 'textContent',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "clickup_teams"
 */
export enum clickup_teams_constraint {
  PK_db3acb8f2604f1d479fce4e3b4a = 'PK_db3acb8f2604f1d479fce4e3b4a',
  REL_d9c2ef77590b94a7a7d7e7f223 = 'REL_d9c2ef77590b94a7a7d7e7f223',
  UQ_785924fb6ea49d86ec04ab909fd = 'UQ_785924fb6ea49d86ec04ab909fd',
}

/**
 * update columns of table "clickup_teams"
 */
export enum clickup_teams_update_column {
  accessClickupMembers = 'accessClickupMembers',
  avatar = 'avatar',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "clickup_users"
 */
export enum clickup_users_constraint {
  PK_c99a14022dde5cae21832a9cbdc = 'PK_c99a14022dde5cae21832a9cbdc',
  REL_5b344234f9de2c2ff889e9015a = 'REL_5b344234f9de2c2ff889e9015a',
  UQ_26f841bd5a954b1a79cb3d04bb5 = 'UQ_26f841bd5a954b1a79cb3d04bb5',
}

/**
 * update columns of table "clickup_users"
 */
export enum clickup_users_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  email = 'email',
  id = 'id',
  initials = 'initials',
  name = 'name',
  profilePicture = 'profilePicture',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_billing_aggregated_structure"
 */
export enum client_billing_aggregated_structure_constraint {
  PK_e5b19782986c2d189201d133727 = 'PK_e5b19782986c2d189201d133727',
  UQ_9eae7aabc41eecd29eb9667675d = 'UQ_9eae7aabc41eecd29eb9667675d',
  UQ_d62aeddfb03e470702d9fd82e13 = 'UQ_d62aeddfb03e470702d9fd82e13',
}

/**
 * update columns of table "client_billing_aggregated_structure"
 */
export enum client_billing_aggregated_structure_update_column {
  clientId = 'clientId',
  clientInvoiceId = 'clientInvoiceId',
  clientTransactionId = 'clientTransactionId',
  costInCredits = 'costInCredits',
  occuredAt = 'occuredAt',
  status = 'status',
  type = 'type',
}

/**
 * unique or primary key constraints on table "client_blocker_status"
 */
export enum client_blocker_status_constraint {
  PK_e5d5ae275bef16666e36effbfc1 = 'PK_e5d5ae275bef16666e36effbfc1',
}

export enum client_blocker_status_enum {
  pending = 'pending',
  resolved = 'resolved',
  review = 'review',
}

/**
 * update columns of table "client_blocker_status"
 */
export enum client_blocker_status_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "client_blockers"
 */
export enum client_blockers_constraint {
  PK_6e24a89cf5ae85282634062b5dc = 'PK_6e24a89cf5ae85282634062b5dc',
}

/**
 * update columns of table "client_blockers"
 */
export enum client_blockers_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  details = 'details',
  id = 'id',
  projectId = 'projectId',
  resolvedById = 'resolvedById',
  status = 'status',
  summary = 'summary',
  unblockDetails = 'unblockDetails',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_bot_types"
 */
export enum client_bot_types_constraint {
  PK_ab9747a5210f3714d2d0c1a1fdd = 'PK_ab9747a5210f3714d2d0c1a1fdd',
}

export enum client_bot_types_enum {
  communication = 'communication',
  git = 'git',
  ticketing = 'ticketing',
}

/**
 * update columns of table "client_bot_types"
 */
export enum client_bot_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "client_bots"
 */
export enum client_bots_constraint {
  PK_3e7582805875021cc6ededd2342 = 'PK_3e7582805875021cc6ededd2342',
  UQ_BOTS_GLOBALLY = 'UQ_BOTS_GLOBALLY',
}

/**
 * update columns of table "client_bots"
 */
export enum client_bots_update_column {
  accessEndpoint = 'accessEndpoint',
  clientId = 'clientId',
  id = 'id',
  password = 'password',
  token = 'token',
  typeId = 'typeId',
  username = 'username',
  verified = 'verified',
}

/**
 * unique or primary key constraints on table "client_changes"
 */
export enum client_changes_constraint {
  PK_68e16b9fbad2b5bfbbb85905772 = 'PK_68e16b9fbad2b5bfbbb85905772',
}

/**
 * update columns of table "client_changes"
 */
export enum client_changes_update_column {
  addressAddress = 'addressAddress',
  agencyId = 'agencyId',
  assignedEntityName = 'assignedEntityName',
  automaticallyChanged = 'automaticallyChanged',
  billingEmail = 'billingEmail',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  churnedAt = 'churnedAt',
  createdAt = 'createdAt',
  creditBatchSize = 'creditBatchSize',
  defaultCreditBudget = 'defaultCreditBudget',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  intercomCompanyId = 'intercomCompanyId',
  legalName = 'legalName',
  logoUrl = 'logoUrl',
  name = 'name',
  onBoardedAt = 'onBoardedAt',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  primaryPhoneNumber = 'primaryPhoneNumber',
  reasonForChange = 'reasonForChange',
  sqlQuery = 'sqlQuery',
  stripeCustomerId = 'stripeCustomerId',
  updatedAt = 'updatedAt',
  userDomains = 'userDomains',
}

/**
 * unique or primary key constraints on table "client_daily_standups"
 */
export enum client_daily_standups_constraint {
  PK_3d02aa88b33cbd92f26af22c37b = 'PK_3d02aa88b33cbd92f26af22c37b',
}

/**
 * update columns of table "client_daily_standups"
 */
export enum client_daily_standups_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  errorMessage = 'errorMessage',
  erroredAt = 'erroredAt',
  id = 'id',
  messageForClient = 'messageForClient',
  postedToClient = 'postedToClient',
  submittedAt = 'submittedAt',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  summary = 'summary',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "client_feature_flags"
 */
export enum client_feature_flags_constraint {
  PK_563464e8d191cf41db90a664a73 = 'PK_563464e8d191cf41db90a664a73',
  UQ_CLIENT_FEATURE_FLAG = 'UQ_CLIENT_FEATURE_FLAG',
}

/**
 * update columns of table "client_feature_flags"
 */
export enum client_feature_flags_update_column {
  clientId = 'clientId',
  featureFlagId = 'featureFlagId',
  id = 'id',
}

/**
 * unique or primary key constraints on table "client_invoice_changes"
 */
export enum client_invoice_changes_constraint {
  PK_173721ee6c82d9a590299bcb111 = 'PK_173721ee6c82d9a590299bcb111',
}

/**
 * update columns of table "client_invoice_changes"
 */
export enum client_invoice_changes_update_column {
  associatedTransactionId = 'associatedTransactionId',
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientId = 'clientId',
  costInCredits = 'costInCredits',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  creditPricingPlanId = 'creditPricingPlanId',
  deletedAt = 'deletedAt',
  dueAt = 'dueAt',
  endAt = 'endAt',
  id = 'id',
  invoiceCode = 'invoiceCode',
  isTopUp = 'isTopUp',
  maxBudgetInCredits = 'maxBudgetInCredits',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  sqlQuery = 'sqlQuery',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_invoices"
 */
export enum client_invoices_constraint {
  PK_e710ecb019ac03ca2d3f3905024 = 'PK_e710ecb019ac03ca2d3f3905024',
  UQ_CLIENT_INVOICE_DUE_AT = 'UQ_CLIENT_INVOICE_DUE_AT',
}

/**
 * update columns of table "client_invoices"
 */
export enum client_invoices_update_column {
  associatedTransactionId = 'associatedTransactionId',
  clientId = 'clientId',
  costInCredits = 'costInCredits',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  creditPricingPlanId = 'creditPricingPlanId',
  deletedAt = 'deletedAt',
  dueAt = 'dueAt',
  endAt = 'endAt',
  id = 'id',
  invoiceCode = 'invoiceCode',
  isTopUp = 'isTopUp',
  maxBudgetInCredits = 'maxBudgetInCredits',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_jobs"
 */
export enum client_jobs_constraint {
  PK_2b3cf801ee3ba060593224e382e = 'PK_2b3cf801ee3ba060593224e382e',
}

/**
 * update columns of table "client_jobs"
 */
export enum client_jobs_update_column {
  canSponsorVisa = 'canSponsorVisa',
  clientId = 'clientId',
  earliestStartDate = 'earliestStartDate',
  id = 'id',
  isRemote = 'isRemote',
  linkToJobDescription = 'linkToJobDescription',
  maxAnnualSalary = 'maxAnnualSalary',
  minQualifiedRank = 'minQualifiedRank',
  qualifiedCities = 'qualifiedCities',
  qualifiedCountries = 'qualifiedCountries',
  qualifiedTimezoneOverlap = 'qualifiedTimezoneOverlap',
  totalPlacements = 'totalPlacements',
  workTimeZone = 'workTimeZone',
}

/**
 * unique or primary key constraints on table "client_pricing_plans"
 */
export enum client_pricing_plans_constraint {
  PK_3b7fe37ef2a35ff84108129f77a = 'PK_3b7fe37ef2a35ff84108129f77a',
}

/**
 * update columns of table "client_pricing_plans"
 */
export enum client_pricing_plans_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  creditPricingPlanId = 'creditPricingPlanId',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_project_changes"
 */
export enum client_project_changes_constraint {
  PK_60cfa3554f2b8d08ccf04a2e294 = 'PK_60cfa3554f2b8d08ccf04a2e294',
}

/**
 * update columns of table "client_project_changes"
 */
export enum client_project_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  internalSlackChannelSid = 'internalSlackChannelSid',
  name = 'name',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  repositoriesId = 'repositoriesId',
  sqlQuery = 'sqlQuery',
  technologiesId = 'technologiesId',
  type = 'type',
  updatedAt = 'updatedAt',
  user_team_id = 'user_team_id',
  usersId = 'usersId',
}

/**
 * unique or primary key constraints on table "client_project_managers"
 */
export enum client_project_managers_constraint {
  PK_c62f0855f3261f6b92065e14c65 = 'PK_c62f0855f3261f6b92065e14c65',
}

/**
 * update columns of table "client_project_managers"
 */
export enum client_project_managers_update_column {
  clientProjectId = 'clientProjectId',
  endAt = 'endAt',
  id = 'id',
  isShadow = 'isShadow',
  projectManagerUserId = 'projectManagerUserId',
  startAt = 'startAt',
}

/**
 * unique or primary key constraints on table "client_project_pricing_plans"
 */
export enum client_project_pricing_plans_constraint {
  PK_32511deee3fe35d6b7ce0fd488b = 'PK_32511deee3fe35d6b7ce0fd488b',
}

/**
 * update columns of table "client_project_pricing_plans"
 */
export enum client_project_pricing_plans_update_column {
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  creditPricingPlanId = 'creditPricingPlanId',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_project_repositories"
 */
export enum client_project_repositories_constraint {
  PK_0ac81329c5921fe676e16dd8b86 = 'PK_0ac81329c5921fe676e16dd8b86',
}

/**
 * update columns of table "client_project_repositories"
 */
export enum client_project_repositories_update_column {
  clientProjectsId = 'clientProjectsId',
  gitReposId = 'gitReposId',
}

/**
 * unique or primary key constraints on table "client_project_technologies"
 */
export enum client_project_technologies_constraint {
  PK_92994097e642ab7904d2833c124 = 'PK_92994097e642ab7904d2833c124',
}

/**
 * update columns of table "client_project_technologies"
 */
export enum client_project_technologies_update_column {
  projectId = 'projectId',
  technologyId = 'technologyId',
}

/**
 * unique or primary key constraints on table "client_project_users"
 */
export enum client_project_users_constraint {
  PK_b4382166cee6704cac2886b1ac3 = 'PK_b4382166cee6704cac2886b1ac3',
}

/**
 * update columns of table "client_project_users"
 */
export enum client_project_users_update_column {
  projects = 'projects',
  users = 'users',
}

/**
 * unique or primary key constraints on table "client_projects"
 */
export enum client_projects_constraint {
  PK_25cf83f0d86704a0363ddd713ca = 'PK_25cf83f0d86704a0363ddd713ca',
  UQ_65467248950e9c7e06c4b88dcb1 = 'UQ_65467248950e9c7e06c4b88dcb1',
  UQ_CLIENT_PROJECT_NAME = 'UQ_CLIENT_PROJECT_NAME',
}

/**
 * update columns of table "client_projects"
 */
export enum client_projects_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  internalSlackChannelSid = 'internalSlackChannelSid',
  name = 'name',
  type = 'type',
  updatedAt = 'updatedAt',
  user_team_id = 'user_team_id',
}

/**
 * unique or primary key constraints on table "client_transaction_status"
 */
export enum client_transaction_status_constraint {
  PK_f64edcb50f43b2172f741303312 = 'PK_f64edcb50f43b2172f741303312',
}

export enum client_transaction_status_enum {
  cancelled = 'cancelled',
  failed = 'failed',
  in_progress = 'in_progress',
  pending = 'pending',
  successful = 'successful',
}

/**
 * update columns of table "client_transaction_status"
 */
export enum client_transaction_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "client_transaction_types"
 */
export enum client_transaction_types_constraint {
  PK_2215a362a65a4cbba8343a4234b = 'PK_2215a362a65a4cbba8343a4234b',
}

export enum client_transaction_types_enum {
  bonus = 'bonus',
  eu_bank = 'eu_bank',
  gb_bank = 'gb_bank',
  hk_bank = 'hk_bank',
  paypal = 'paypal',
  stripe = 'stripe',
  transferwise = 'transferwise',
  us_bank = 'us_bank',
}

/**
 * update columns of table "client_transaction_types"
 */
export enum client_transaction_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "client_transactions"
 */
export enum client_transactions_constraint {
  PK_affbc9a9a22aa18f40b1bf7a9b3 = 'PK_affbc9a9a22aa18f40b1bf7a9b3',
}

/**
 * update columns of table "client_transactions"
 */
export enum client_transactions_update_column {
  channelTransactionId = 'channelTransactionId',
  channelType = 'channelType',
  clientId = 'clientId',
  costInCredits = 'costInCredits',
  costInUSD = 'costInUSD',
  creditPricingPlanId = 'creditPricingPlanId',
  dueAt = 'dueAt',
  id = 'id',
  initiatedByUserId = 'initiatedByUserId',
  invoiceCode = 'invoiceCode',
  status = 'status',
  stripeIntentSecret = 'stripeIntentSecret',
  transactedAt = 'transactedAt',
  transactionError = 'transactionError',
}

/**
 * unique or primary key constraints on table "client_user_positions"
 */
export enum client_user_positions_constraint {
  PK_1b1e899972ae0d6da4e4261afc3 = 'PK_1b1e899972ae0d6da4e4261afc3',
}

/**
 * update columns of table "client_user_positions"
 */
export enum client_user_positions_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "client_user_roles"
 */
export enum client_user_roles_constraint {
  PK_289ec4f30f5190f01c543136ecb = 'PK_289ec4f30f5190f01c543136ecb',
}

export enum client_user_roles_enum {
  admin = 'admin',
  contractor = 'contractor',
  internal = 'internal',
  user = 'user',
}

/**
 * update columns of table "client_user_roles"
 */
export enum client_user_roles_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "client_users"
 */
export enum client_users_constraint {
  PK_fe74bfd4d01077395ee4204b553 = 'PK_fe74bfd4d01077395ee4204b553',
  UQ_CLIENT_USER = 'UQ_CLIENT_USER',
}

/**
 * update columns of table "client_users"
 */
export enum client_users_update_column {
  clientId = 'clientId',
  id = 'id',
  position = 'position',
  role = 'role',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "clients"
 */
export enum clients_constraint {
  PK_f1ab7cf3a5714dbc6bb4e1c28a4 = 'PK_f1ab7cf3a5714dbc6bb4e1c28a4',
  UQ_910e83629c5861ac0f2feaf9e2b = 'UQ_910e83629c5861ac0f2feaf9e2b',
}

/**
 * update columns of table "clients"
 */
export enum clients_update_column {
  addressAddress = 'addressAddress',
  agencyId = 'agencyId',
  assignedEntityName = 'assignedEntityName',
  billingEmail = 'billingEmail',
  churnedAt = 'churnedAt',
  createdAt = 'createdAt',
  creditBatchSize = 'creditBatchSize',
  defaultCreditBudget = 'defaultCreditBudget',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  intercomCompanyId = 'intercomCompanyId',
  legalName = 'legalName',
  logoUrl = 'logoUrl',
  name = 'name',
  onBoardedAt = 'onBoardedAt',
  primaryPhoneNumber = 'primaryPhoneNumber',
  stripeCustomerId = 'stripeCustomerId',
  updatedAt = 'updatedAt',
  userDomains = 'userDomains',
}

/**
 * unique or primary key constraints on table "competency_types"
 */
export enum competency_types_constraint {
  PK_91ac3acbb853fbe6e948f5b34bf = 'PK_91ac3acbb853fbe6e948f5b34bf',
}

/**
 * update columns of table "competency_types"
 */
export enum competency_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "credit_pricing_plan_payment_types"
 */
export enum credit_pricing_plan_payment_types_constraint {
  PK_0aeef74edbcb84c7bde8cf80216 = 'PK_0aeef74edbcb84c7bde8cf80216',
}

export enum credit_pricing_plan_payment_types_enum {
  on_approval = 'on_approval',
  post_limit = 'post_limit',
  post_monthly = 'post_monthly',
  upfront = 'upfront',
}

/**
 * update columns of table "credit_pricing_plan_payment_types"
 */
export enum credit_pricing_plan_payment_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "credit_pricing_plans"
 */
export enum credit_pricing_plans_constraint {
  PK_4ce1db1ba7034d0329c248677c5 = 'PK_4ce1db1ba7034d0329c248677c5',
}

/**
 * update columns of table "credit_pricing_plans"
 */
export enum credit_pricing_plans_update_column {
  costPerCredit = 'costPerCredit',
  createdAt = 'createdAt',
  creditsPerMidLevelHour = 'creditsPerMidLevelHour',
  defaultSLA = 'defaultSLA',
  deletedAt = 'deletedAt',
  discontinuedAt = 'discontinuedAt',
  id = 'id',
  isCustomPlan = 'isCustomPlan',
  maxFreeSupportHoursPerWeek = 'maxFreeSupportHoursPerWeek',
  minCreditUsagePerWeek = 'minCreditUsagePerWeek',
  minimumCreditPurchase = 'minimumCreditPurchase',
  name = 'name',
  paymentTypeId = 'paymentTypeId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "description_types"
 */
export enum description_types_constraint {
  PK_45ae11bae61eeb6bb51f9c158dc = 'PK_45ae11bae61eeb6bb51f9c158dc',
}

export enum description_types_enum {
  html = 'html',
  jira = 'jira',
  markdown = 'markdown',
}

/**
 * update columns of table "description_types"
 */
export enum description_types_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "developer_bonus_earning"
 */
export enum developer_bonus_earning_constraint {
  PK_c48f5e0409fe7f51ca64525b721 = 'PK_c48f5e0409fe7f51ca64525b721',
}

/**
 * update columns of table "developer_bonus_earning"
 */
export enum developer_bonus_earning_update_column {
  amountInUSD = 'amountInUSD',
  bonusType = 'bonusType',
  endDate = 'endDate',
  id = 'id',
  startDate = 'startDate',
  userInvoiceId = 'userInvoiceId',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "developer_changes"
 */
export enum developer_changes_constraint {
  PK_bf4892f94fdb50a7d90c7d9e962 = 'PK_bf4892f94fdb50a7d90c7d9e962',
}

/**
 * update columns of table "developer_changes"
 */
export enum developer_changes_update_column {
  addedToAirtableAt = 'addedToAirtableAt',
  agencyId = 'agencyId',
  appliedAt = 'appliedAt',
  automaticallyChanged = 'automaticallyChanged',
  bio = 'bio',
  canRelocate = 'canRelocate',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  city = 'city',
  codeSampleUrl = 'codeSampleUrl',
  cohort = 'cohort',
  country = 'country',
  createdAt = 'createdAt',
  culture_screen_feedback = 'culture_screen_feedback',
  currentMonthlySalary = 'currentMonthlySalary',
  cv = 'cv',
  deletedAt = 'deletedAt',
  earliestStartDate = 'earliestStartDate',
  failedToAddToAirtableAt = 'failedToAddToAirtableAt',
  githubId = 'githubId',
  id = 'id',
  isActiveStudent = 'isActiveStudent',
  isActivelyEmployed = 'isActivelyEmployed',
  levelId = 'levelId',
  linkedInUrl = 'linkedInUrl',
  login = 'login',
  minAnnualSalary = 'minAnnualSalary',
  noticePeriod = 'noticePeriod',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  previousWorkExp = 'previousWorkExp',
  reasonForChange = 'reasonForChange',
  source = 'source',
  sqlQuery = 'sqlQuery',
  stage = 'stage',
  targetCurrency = 'targetCurrency',
  tech_screen_feedback = 'tech_screen_feedback',
  timezone = 'timezone',
  transferwiseId = 'transferwiseId',
  updatedAt = 'updatedAt',
  workMachineOS = 'workMachineOS',
  yearsOfExperience = 'yearsOfExperience',
}

/**
 * unique or primary key constraints on table "developer_daily_log"
 */
export enum developer_daily_log_constraint {
  PK_7cc18edd08a1ba851e4651f49ef = 'PK_7cc18edd08a1ba851e4651f49ef',
}

/**
 * update columns of table "developer_daily_log"
 */
export enum developer_daily_log_update_column {
  createdAt = 'createdAt',
  developerId = 'developerId',
  id = 'id',
  numberOfCommits = 'numberOfCommits',
  timeLogged = 'timeLogged',
  updatedAt = 'updatedAt',
  workDate = 'workDate',
}

/**
 * unique or primary key constraints on table "developer_weekly_earning"
 */
export enum developer_weekly_earning_constraint {
  PK_7765206b04b419f25b60adabe56 = 'PK_7765206b04b419f25b60adabe56',
}

/**
 * update columns of table "developer_weekly_earning"
 */
export enum developer_weekly_earning_update_column {
  amountEarnedWithTasks = 'amountEarnedWithTasks',
  developerId = 'developerId',
  endDate = 'endDate',
  id = 'id',
  minimumAmountEarned = 'minimumAmountEarned',
  startDate = 'startDate',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "developers"
 */
export enum developers_constraint {
  PK_247719240b950bd26dec14bdd21 = 'PK_247719240b950bd26dec14bdd21',
  UQ_247719240b950bd26dec14bdd21 = 'UQ_247719240b950bd26dec14bdd21',
  UQ_b857ddc4fd4392133599b69bc64 = 'UQ_b857ddc4fd4392133599b69bc64',
}

/**
 * update columns of table "developers"
 */
export enum developers_update_column {
  addedToAirtableAt = 'addedToAirtableAt',
  agencyId = 'agencyId',
  appliedAt = 'appliedAt',
  bio = 'bio',
  canRelocate = 'canRelocate',
  city = 'city',
  codeSampleUrl = 'codeSampleUrl',
  cohort = 'cohort',
  country = 'country',
  createdAt = 'createdAt',
  culture_screen_feedback = 'culture_screen_feedback',
  currentMonthlySalary = 'currentMonthlySalary',
  cv = 'cv',
  deletedAt = 'deletedAt',
  earliestStartDate = 'earliestStartDate',
  failedToAddToAirtableAt = 'failedToAddToAirtableAt',
  githubId = 'githubId',
  id = 'id',
  isActiveStudent = 'isActiveStudent',
  isActivelyEmployed = 'isActivelyEmployed',
  levelId = 'levelId',
  linkedInUrl = 'linkedInUrl',
  login = 'login',
  minAnnualSalary = 'minAnnualSalary',
  noticePeriod = 'noticePeriod',
  previousWorkExp = 'previousWorkExp',
  source = 'source',
  stage = 'stage',
  targetCurrency = 'targetCurrency',
  tech_screen_feedback = 'tech_screen_feedback',
  timezone = 'timezone',
  transferwiseId = 'transferwiseId',
  updatedAt = 'updatedAt',
  workMachineOS = 'workMachineOS',
  yearsOfExperience = 'yearsOfExperience',
}

/**
 * unique or primary key constraints on table "discussion_comment_body_types"
 */
export enum discussion_comment_body_types_constraint {
  PK_06d4af29b80a29d747f21e41f05 = 'PK_06d4af29b80a29d747f21e41f05',
}

export enum discussion_comment_body_types_enum {
  html = 'html',
  json = 'json',
  markdown = 'markdown',
}

/**
 * update columns of table "discussion_comment_body_types"
 */
export enum discussion_comment_body_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "discussion_comment_read_types"
 */
export enum discussion_comment_read_types_constraint {
  PK_4d7ffef9fdcb1aeba52046048f8 = 'PK_4d7ffef9fdcb1aeba52046048f8',
}

export enum discussion_comment_read_types_enum {
  dismissed = 'dismissed',
  seen = 'seen',
}

/**
 * update columns of table "discussion_comment_read_types"
 */
export enum discussion_comment_read_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "discussion_comment_reads"
 */
export enum discussion_comment_reads_constraint {
  PK_790c31cb6cfdce7d6b216d2624a = 'PK_790c31cb6cfdce7d6b216d2624a',
}

/**
 * update columns of table "discussion_comment_reads"
 */
export enum discussion_comment_reads_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  readAt = 'readAt',
  readByUserId = 'readByUserId',
  readTypeId = 'readTypeId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "discussion_comments"
 */
export enum discussion_comments_constraint {
  PK_a258af95cc84633ce1cd4c00dc4 = 'PK_a258af95cc84633ce1cd4c00dc4',
  REL_32bb9b67859cfd45f65bcb0cc1 = 'REL_32bb9b67859cfd45f65bcb0cc1',
}

/**
 * update columns of table "discussion_comments"
 */
export enum discussion_comments_update_column {
  authorSid = 'authorSid',
  authorTableName = 'authorTableName',
  authorUserId = 'authorUserId',
  commentedAt = 'commentedAt',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  diffHunk = 'diffHunk',
  discussionSourceId = 'discussionSourceId',
  errorId = 'errorId',
  id = 'id',
  isInternal = 'isInternal',
  isResolved = 'isResolved',
  notifiedToRelevantParties = 'notifiedToRelevantParties',
  originalPosition = 'originalPosition',
  outdated = 'outdated',
  parentCommentId = 'parentCommentId',
  path = 'path',
  position = 'position',
  rawBodyText = 'rawBodyText',
  rawBodyTypeId = 'rawBodyTypeId',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "discussion_sources"
 */
export enum discussion_sources_constraint {
  PK_bcabc8c7f6adb5051e16d66d67c = 'PK_bcabc8c7f6adb5051e16d66d67c',
  REL_154365d23e8bb5e018c5671405 = 'REL_154365d23e8bb5e018c5671405',
  REL_869d6954b6498b6a0e2420bcb6 = 'REL_869d6954b6498b6a0e2420bcb6',
  UQ_bd18be4f31030393d5ba84af6b2 = 'UQ_bd18be4f31030393d5ba84af6b2',
}

/**
 * update columns of table "discussion_sources"
 */
export enum discussion_sources_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isInternal = 'isInternal',
  resolvedAt = 'resolvedAt',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  taskCode = 'taskCode',
  taskTicketCode = 'taskTicketCode',
  ticketCode = 'ticketCode',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "errors"
 */
export enum errors_constraint {
  PK_f1ab2df89a11cd21f48ff90febb = 'PK_f1ab2df89a11cd21f48ff90febb',
}

/**
 * update columns of table "errors"
 */
export enum errors_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  error = 'error',
  errorMessage = 'errorMessage',
  erroredAt = 'erroredAt',
  id = 'id',
  sentryErrorId = 'sentryErrorId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "feature_flags"
 */
export enum feature_flags_constraint {
  PK_db657d344e9caacfc9d5cf8bbac = 'PK_db657d344e9caacfc9d5cf8bbac',
  UQ_d5a8bb2df6ade7ced134d957963 = 'UQ_d5a8bb2df6ade7ced134d957963',
}

/**
 * update columns of table "feature_flags"
 */
export enum feature_flags_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  name = 'name',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "further_reviews"
 */
export enum further_reviews_constraint {
  PK_647fefe2525a96c307e9a1760be = 'PK_647fefe2525a96c307e9a1760be',
}

/**
 * update columns of table "further_reviews"
 */
export enum further_reviews_update_column {
  taskReviewsId = 'taskReviewsId',
  tasksId = 'tasksId',
}

/**
 * unique or primary key constraints on table "gcp_storage_buckets"
 */
export enum gcp_storage_buckets_constraint {
  PK_82b02e1ec106e8c0bbacedd2ab6 = 'PK_82b02e1ec106e8c0bbacedd2ab6',
  UQ_0afff3019039581d49a8ec7061f = 'UQ_0afff3019039581d49a8ec7061f',
  UQ_2317eabdf7038d36bdfb0975fdd = 'UQ_2317eabdf7038d36bdfb0975fdd',
}

/**
 * update columns of table "gcp_storage_buckets"
 */
export enum gcp_storage_buckets_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gcp_storage_objects"
 */
export enum gcp_storage_objects_constraint {
  PK_8cef844a166a77c34d468fb955e = 'PK_8cef844a166a77c34d468fb955e',
  UQ_b0d3334906f86ca63d1769cd63f = 'UQ_b0d3334906f86ca63d1769cd63f',
  UQ_f4bf5b2bab18cdc0e503d850361 = 'UQ_f4bf5b2bab18cdc0e503d850361',
}

/**
 * update columns of table "gcp_storage_objects"
 */
export enum gcp_storage_objects_update_column {
  authoredAt = 'authoredAt',
  bucketId = 'bucketId',
  createdAt = 'createdAt',
  creatorId = 'creatorId',
  deletedAt = 'deletedAt',
  extension = 'extension',
  filename = 'filename',
  id = 'id',
  mimetype = 'mimetype',
  originalFileName = 'originalFileName',
  readLink = 'readLink',
  sha1 = 'sha1',
  sha256 = 'sha256',
  sha512 = 'sha512',
  sid = 'sid',
  sizeInBytes = 'sizeInBytes',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  uploadLink = 'uploadLink',
  uploadLinkExpiresAt = 'uploadLinkExpiresAt',
}

/**
 * unique or primary key constraints on table "git_branch_commits"
 */
export enum git_branch_commits_constraint {
  PK_8114bd8b1c6b568581914d93fc0 = 'PK_8114bd8b1c6b568581914d93fc0',
}

/**
 * update columns of table "git_branch_commits"
 */
export enum git_branch_commits_update_column {
  branchId = 'branchId',
  commitId = 'commitId',
}

/**
 * unique or primary key constraints on table "git_branch_refs"
 */
export enum git_branch_refs_constraint {
  PK_525fd6c5b1a996f8d3414bfd798 = 'PK_525fd6c5b1a996f8d3414bfd798',
}

/**
 * update columns of table "git_branch_refs"
 */
export enum git_branch_refs_update_column {
  branchName = 'branchName',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  headCommit = 'headCommit',
  repoId = 'repoId',
  syncedAt = 'syncedAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_branches"
 */
export enum git_branches_constraint {
  PK_e452173d18c31eba971f888d09b = 'PK_e452173d18c31eba971f888d09b',
  REL_f72e31e3748925e7bae474035c = 'REL_f72e31e3748925e7bae474035c',
  UQ_GIT_BRANCH = 'UQ_GIT_BRANCH',
}

/**
 * update columns of table "git_branches"
 */
export enum git_branches_update_column {
  branchRef = 'branchRef',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitRepoId = 'gitRepoId',
  headCommitId = 'headCommitId',
  id = 'id',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_comment_reactions"
 */
export enum git_comment_reactions_constraint {
  PK_3dbd179d36ebb71ac5d697325e4 = 'PK_3dbd179d36ebb71ac5d697325e4',
  REL_6d2c4e3454cbb54833085bb9cd = 'REL_6d2c4e3454cbb54833085bb9cd',
}

/**
 * update columns of table "git_comment_reactions"
 */
export enum git_comment_reactions_update_column {
  authorId = 'authorId',
  content = 'content',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  pullRequestCommentId = 'pullRequestCommentId',
  pullRequestId = 'pullRequestId',
  pullRequestReviewCommentId = 'pullRequestReviewCommentId',
  pullRequestReviewId = 'pullRequestReviewId',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_commit_comments"
 */
export enum git_commit_comments_constraint {
  PK_644080e8f9ab80b170815c89e03 = 'PK_644080e8f9ab80b170815c89e03',
  REL_054dc3bdff5170e50d6d8b816d = 'REL_054dc3bdff5170e50d6d8b816d',
  UQ_75b0e16f245d13f104103732344 = 'UQ_75b0e16f245d13f104103732344',
}

/**
 * update columns of table "git_commit_comments"
 */
export enum git_commit_comments_update_column {
  authorId = 'authorId',
  body = 'body',
  commitId = 'commitId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  position = 'position',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_commit_contexts"
 */
export enum git_commit_contexts_constraint {
  PK_2a93245eb338ce04ce2fe812035 = 'PK_2a93245eb338ce04ce2fe812035',
  REL_5db25c55dbc36cd2c71e70d20e = 'REL_5db25c55dbc36cd2c71e70d20e',
}

/**
 * update columns of table "git_commit_contexts"
 */
export enum git_commit_contexts_update_column {
  avatarUrl = 'avatarUrl',
  commitId = 'commitId',
  context = 'context',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  status = 'status',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  targetUrl = 'targetUrl',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_commit_slices"
 */
export enum git_commit_slices_constraint {
  PK_c4215a7dcd569a8b4665fac13a8 = 'PK_c4215a7dcd569a8b4665fac13a8',
  REL_f8a9485b76653657da7f8d567c = 'REL_f8a9485b76653657da7f8d567c',
}

/**
 * update columns of table "git_commit_slices"
 */
export enum git_commit_slices_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  originCommitId = 'originCommitId',
  slicedAt = 'slicedAt',
  targetCommitId = 'targetCommitId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_commit_status"
 */
export enum git_commit_status_constraint {
  PK_700d03a534413dee6c079989e50 = 'PK_700d03a534413dee6c079989e50',
}

export enum git_commit_status_enum {
  ERROR = 'ERROR',
  EXPECTED = 'EXPECTED',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

/**
 * update columns of table "git_commit_status"
 */
export enum git_commit_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "git_commits"
 */
export enum git_commits_constraint {
  PK_89075cd165d10bd3c6f659f5b53 = 'PK_89075cd165d10bd3c6f659f5b53',
  REL_e9c23ec49c3d2df230bfe140f2 = 'REL_e9c23ec49c3d2df230bfe140f2',
  UQ_cc0921eae0ac3f67657763d7db8 = 'UQ_cc0921eae0ac3f67657763d7db8',
}

/**
 * update columns of table "git_commits"
 */
export enum git_commits_update_column {
  authorId = 'authorId',
  committedAt = 'committedAt',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  message = 'message',
  oid = 'oid',
  parentCommits = 'parentCommits',
  repoId = 'repoId',
  shallow = 'shallow',
  status = 'status',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  taskId = 'taskId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_organizations"
 */
export enum git_organizations_constraint {
  PK_33fd98d09e05427408c9f864571 = 'PK_33fd98d09e05427408c9f864571',
  REL_f03c717070bd1dc5d2d2e79e4d = 'REL_f03c717070bd1dc5d2d2e79e4d',
}

/**
 * update columns of table "git_organizations"
 */
export enum git_organizations_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_assignees"
 */
export enum git_pull_request_assignees_constraint {
  PK_233509c388d23bc58a35a7c48e3 = 'PK_233509c388d23bc58a35a7c48e3',
}

/**
 * update columns of table "git_pull_request_assignees"
 */
export enum git_pull_request_assignees_update_column {
  assigneeUserId = 'assigneeUserId',
  pullRequestId = 'pullRequestId',
}

/**
 * unique or primary key constraints on table "git_pull_request_changes"
 */
export enum git_pull_request_changes_constraint {
  PK_1bb0d9ab503cfc7cd0312b2c575 = 'PK_1bb0d9ab503cfc7cd0312b2c575',
}

/**
 * update columns of table "git_pull_request_changes"
 */
export enum git_pull_request_changes_update_column {
  assignedGitUsers = 'assignedGitUsers',
  assignedToReviewGitUsers = 'assignedToReviewGitUsers',
  authorId = 'authorId',
  automaticallyChanged = 'automaticallyChanged',
  baseBranchRef = 'baseBranchRef',
  baseHeadCommit = 'baseHeadCommit',
  body = 'body',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  isDraft = 'isDraft',
  isMerged = 'isMerged',
  mergeable = 'mergeable',
  mergedAt = 'mergedAt',
  number = 'number',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  repoId = 'repoId',
  sqlQuery = 'sqlQuery',
  state = 'state',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  targetBranchRef = 'targetBranchRef',
  targetHeadCommit = 'targetHeadCommit',
  targetRepoId = 'targetRepoId',
  ticketId = 'ticketId',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_comments"
 */
export enum git_pull_request_comments_constraint {
  PK_c06551647d1dace2dc2ef732c7d = 'PK_c06551647d1dace2dc2ef732c7d',
  REL_786b23a8917e5ba8c4d90971ad = 'REL_786b23a8917e5ba8c4d90971ad',
  UQ_c39463c0a9ae09004f57e10b642 = 'UQ_c39463c0a9ae09004f57e10b642',
}

/**
 * update columns of table "git_pull_request_comments"
 */
export enum git_pull_request_comments_update_column {
  authorId = 'authorId',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  pullRequestId = 'pullRequestId',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_review_comment_state"
 */
export enum git_pull_request_review_comment_state_constraint {
  PK_25dd4deed317074f00df1d861b5 = 'PK_25dd4deed317074f00df1d861b5',
}

export enum git_pull_request_review_comment_state_enum {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
}

/**
 * update columns of table "git_pull_request_review_comment_state"
 */
export enum git_pull_request_review_comment_state_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "git_pull_request_review_comments"
 */
export enum git_pull_request_review_comments_constraint {
  PK_99239583898b108dd1ab7d169a6 = 'PK_99239583898b108dd1ab7d169a6',
  REL_1d1cc4b8cea77cf0e529319b56 = 'REL_1d1cc4b8cea77cf0e529319b56',
  UQ_67bca6a9199e243748a26ca4300 = 'UQ_67bca6a9199e243748a26ca4300',
}

/**
 * update columns of table "git_pull_request_review_comments"
 */
export enum git_pull_request_review_comments_update_column {
  authorId = 'authorId',
  body = 'body',
  commitId = 'commitId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  diffHunk = 'diffHunk',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  originalCommitId = 'originalCommitId',
  originalPosition = 'originalPosition',
  outdated = 'outdated',
  path = 'path',
  position = 'position',
  pullRequestId = 'pullRequestId',
  pullRequestReviewId = 'pullRequestReviewId',
  pullRequestReviewThreadId = 'pullRequestReviewThreadId',
  replyToCommentId = 'replyToCommentId',
  state = 'state',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_review_state"
 */
export enum git_pull_request_review_state_constraint {
  PK_0710721310cf682af7f4cad5565 = 'PK_0710721310cf682af7f4cad5565',
}

export enum git_pull_request_review_state_enum {
  APPROVED = 'APPROVED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  COMMENTED = 'COMMENTED',
  DISMISSED = 'DISMISSED',
  PENDING = 'PENDING',
}

/**
 * update columns of table "git_pull_request_review_state"
 */
export enum git_pull_request_review_state_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "git_pull_request_review_threads"
 */
export enum git_pull_request_review_threads_constraint {
  PK_9e08b43b494850697fceedad7ac = 'PK_9e08b43b494850697fceedad7ac',
  REL_ff5f8088d6f85d89ae80a348c9 = 'REL_ff5f8088d6f85d89ae80a348c9',
}

/**
 * update columns of table "git_pull_request_review_threads"
 */
export enum git_pull_request_review_threads_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isResolved = 'isResolved',
  pullRequestId = 'pullRequestId',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_reviewers"
 */
export enum git_pull_request_reviewers_constraint {
  PK_9fd52493a3bfe60ea7e536de6f9 = 'PK_9fd52493a3bfe60ea7e536de6f9',
}

/**
 * update columns of table "git_pull_request_reviewers"
 */
export enum git_pull_request_reviewers_update_column {
  pullRequestId = 'pullRequestId',
  reviewerUserId = 'reviewerUserId',
}

/**
 * unique or primary key constraints on table "git_pull_request_reviews"
 */
export enum git_pull_request_reviews_constraint {
  PK_985d4c78583ae42ae65233ca35f = 'PK_985d4c78583ae42ae65233ca35f',
  REL_447bf53cbdc5138ea41894a1a3 = 'REL_447bf53cbdc5138ea41894a1a3',
  UQ_6bf52c96f125e5397c6204c13dd = 'UQ_6bf52c96f125e5397c6204c13dd',
  UQ_ad31fd70961392c0cd937f6412a = 'UQ_ad31fd70961392c0cd937f6412a',
}

/**
 * update columns of table "git_pull_request_reviews"
 */
export enum git_pull_request_reviews_update_column {
  authorId = 'authorId',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  pullRequestId = 'pullRequestId',
  state = 'state',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  taskReviewId = 'taskReviewId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_slice"
 */
export enum git_pull_request_slice_constraint {
  PK_3ce1b017cf97e658b5de02c7340 = 'PK_3ce1b017cf97e658b5de02c7340',
  UQ_REPO_PR = 'UQ_REPO_PR',
}

/**
 * update columns of table "git_pull_request_slice"
 */
export enum git_pull_request_slice_update_column {
  basePullRequestId = 'basePullRequestId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  pushedPullRequestId = 'pushedPullRequestId',
  repoSliceId = 'repoSliceId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_pull_request_state"
 */
export enum git_pull_request_state_constraint {
  PK_43596bcc8b623b3716a9d3b394e = 'PK_43596bcc8b623b3716a9d3b394e',
}

export enum git_pull_request_state_enum {
  CLOSED = 'CLOSED',
  MERGED = 'MERGED',
  OPEN = 'OPEN',
}

/**
 * update columns of table "git_pull_request_state"
 */
export enum git_pull_request_state_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "git_pull_requests"
 */
export enum git_pull_requests_constraint {
  GIT_PULL_REQUEST_NUMBER_UQ = 'GIT_PULL_REQUEST_NUMBER_UQ',
  PK_671f6b2a8a6ccef3c5ba79b6a3a = 'PK_671f6b2a8a6ccef3c5ba79b6a3a',
  REL_e7f8dbf49cb3c9449095101b9f = 'REL_e7f8dbf49cb3c9449095101b9f',
  UQ_f85b99b7667c4457c6e020cd0ce = 'UQ_f85b99b7667c4457c6e020cd0ce',
}

/**
 * update columns of table "git_pull_requests"
 */
export enum git_pull_requests_update_column {
  assignedGitUsers = 'assignedGitUsers',
  assignedToReviewGitUsers = 'assignedToReviewGitUsers',
  authorId = 'authorId',
  baseBranchRef = 'baseBranchRef',
  baseHeadCommit = 'baseHeadCommit',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  isDraft = 'isDraft',
  isMerged = 'isMerged',
  mergeable = 'mergeable',
  mergedAt = 'mergedAt',
  number = 'number',
  repoId = 'repoId',
  state = 'state',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  targetBranchRef = 'targetBranchRef',
  targetHeadCommit = 'targetHeadCommit',
  targetRepoId = 'targetRepoId',
  ticketId = 'ticketId',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_repo_changes"
 */
export enum git_repo_changes_constraint {
  PK_5799748a979ce63f734af98a45a = 'PK_5799748a979ce63f734af98a45a',
}

/**
 * update columns of table "git_repo_changes"
 */
export enum git_repo_changes_update_column {
  accessGitUserIds = 'accessGitUserIds',
  authUrl = 'authUrl',
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  isArchived = 'isArchived',
  isDisabled = 'isDisabled',
  isFork = 'isFork',
  isLocked = 'isLocked',
  isPrivate = 'isPrivate',
  isTemplate = 'isTemplate',
  name = 'name',
  operation = 'operation',
  organizationId = 'organizationId',
  owner = 'owner',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  source = 'source',
  sqlQuery = 'sqlQuery',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_repo_slice_changes"
 */
export enum git_repo_slice_changes_constraint {
  PK_80f71e43536e4e20229929bb247 = 'PK_80f71e43536e4e20229929bb247',
}

/**
 * update columns of table "git_repo_slice_changes"
 */
export enum git_repo_slice_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  handledByAction = 'handledByAction',
  id = 'id',
  ignoredPaths = 'ignoredPaths',
  latestCommitSliceId = 'latestCommitSliceId',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  pullIntervalInHr = 'pullIntervalInHr',
  reasonForChange = 'reasonForChange',
  slicedFolders = 'slicedFolders',
  slicedFromBranchName = 'slicedFromBranchName',
  slicedFromRepoId = 'slicedFromRepoId',
  slicedToBranchName = 'slicedToBranchName',
  slicedToRepoId = 'slicedToRepoId',
  sqlQuery = 'sqlQuery',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_repo_slices"
 */
export enum git_repo_slices_constraint {
  PK_66aee72d0065925d3bbcdfdaa5c = 'PK_66aee72d0065925d3bbcdfdaa5c',
  UQ_965cb16d2234a2b4775841be458 = 'UQ_965cb16d2234a2b4775841be458',
  UQ_e271855d9d16b8c57f0dcd0965d = 'UQ_e271855d9d16b8c57f0dcd0965d',
}

/**
 * update columns of table "git_repo_slices"
 */
export enum git_repo_slices_update_column {
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  handledByAction = 'handledByAction',
  id = 'id',
  ignoredPaths = 'ignoredPaths',
  latestCommitSliceId = 'latestCommitSliceId',
  pullIntervalInHr = 'pullIntervalInHr',
  slicedFolders = 'slicedFolders',
  slicedFromBranchName = 'slicedFromBranchName',
  slicedFromRepoId = 'slicedFromRepoId',
  slicedToBranchName = 'slicedToBranchName',
  slicedToRepoId = 'slicedToRepoId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_repos"
 */
export enum git_repos_constraint {
  GIT_REPO_UQ = 'GIT_REPO_UQ',
  PK_bfe52eadd3f50e722a5f3796af6 = 'PK_bfe52eadd3f50e722a5f3796af6',
  REL_f5c6fe36bde2d84109dd6e97bb = 'REL_f5c6fe36bde2d84109dd6e97bb',
}

/**
 * update columns of table "git_repos"
 */
export enum git_repos_update_column {
  accessGitUserIds = 'accessGitUserIds',
  authUrl = 'authUrl',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  isArchived = 'isArchived',
  isDisabled = 'isDisabled',
  isFork = 'isFork',
  isLocked = 'isLocked',
  isPrivate = 'isPrivate',
  isTemplate = 'isTemplate',
  name = 'name',
  organizationId = 'organizationId',
  owner = 'owner',
  source = 'source',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_slice_pulls"
 */
export enum git_slice_pulls_constraint {
  PK_12fcc492726eef23182c754d799 = 'PK_12fcc492726eef23182c754d799',
  REL_e03e016c70d11e72edc94ea534 = 'REL_e03e016c70d11e72edc94ea534',
}

/**
 * update columns of table "git_slice_pulls"
 */
export enum git_slice_pulls_update_column {
  commitSliceId = 'commitSliceId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  error = 'error',
  finishedAt = 'finishedAt',
  id = 'id',
  logs = 'logs',
  repoSliceId = 'repoSliceId',
  startedAt = 'startedAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_slice_pushes"
 */
export enum git_slice_pushes_constraint {
  PK_b83750170e205d2464aa959fb85 = 'PK_b83750170e205d2464aa959fb85',
  REL_f555cfa51516e01c390d6941b1 = 'REL_f555cfa51516e01c390d6941b1',
}

/**
 * update columns of table "git_slice_pushes"
 */
export enum git_slice_pushes_update_column {
  commitSliceId = 'commitSliceId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  error = 'error',
  finishedAt = 'finishedAt',
  id = 'id',
  logs = 'logs',
  pullRequestSliceId = 'pullRequestSliceId',
  startedAt = 'startedAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "git_sources"
 */
export enum git_sources_constraint {
  PK_07249e6d38a60e66f663022fc72 = 'PK_07249e6d38a60e66f663022fc72',
}

export enum git_sources_enum {
  github = 'github',
  gitlab = 'gitlab',
}

/**
 * update columns of table "git_sources"
 */
export enum git_sources_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "git_users"
 */
export enum git_users_constraint {
  PK_095ec487e1e4bd8e76a11f25e0e = 'PK_095ec487e1e4bd8e76a11f25e0e',
  REL_7aa87a422253bf155595785eee = 'REL_7aa87a422253bf155595785eee',
  UQ_0405547e44541a0a2c84d74c26e = 'UQ_0405547e44541a0a2c84d74c26e',
  UQ_c8f44733e7d0a09efe6a10664ed = 'UQ_c8f44733e7d0a09efe6a10664ed',
}

/**
 * update columns of table "git_users"
 */
export enum git_users_update_column {
  agencyId = 'agencyId',
  avatarUrl = 'avatarUrl',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  email = 'email',
  githubAccountLogin = 'githubAccountLogin',
  gitlabUserLogin = 'gitlabUserLogin',
  id = 'id',
  name = 'name',
  password = 'password',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "github_accounts"
 */
export enum github_accounts_constraint {
  PK_01b75d48868462b91c920cc80dc = 'PK_01b75d48868462b91c920cc80dc',
  UQ_GITHUB_LOGIN = 'UQ_GITHUB_LOGIN',
  UQ_a4c948d5f1d6dbef67bdf4b160c = 'UQ_a4c948d5f1d6dbef67bdf4b160c',
  UQ_cc0c7497fb0741c0bfe782b9b99 = 'UQ_cc0c7497fb0741c0bfe782b9b99',
  UQ_d1f58014d7320eeca5e0497dbad = 'UQ_d1f58014d7320eeca5e0497dbad',
  UQ_d3a85d85ab19520445ade53150d = 'UQ_d3a85d85ab19520445ade53150d',
  UQ_e86ca9a6f5c85173103df89c73b = 'UQ_e86ca9a6f5c85173103df89c73b',
}

/**
 * update columns of table "github_accounts"
 */
export enum github_accounts_update_column {
  agencyId = 'agencyId',
  avatarUrl = 'avatarUrl',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  email = 'email',
  gitUserId = 'gitUserId',
  id = 'id',
  login = 'login',
  name = 'name',
  password = 'password',
  personalAccessToken = 'personalAccessToken',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_app_installations"
 */
export enum github_app_installations_constraint {
  PK_25a63d76935ddf621bfb6277954 = 'PK_25a63d76935ddf621bfb6277954',
  UQ_01b5bcd8f8865fe457a26bd2f3c = 'UQ_01b5bcd8f8865fe457a26bd2f3c',
  UQ_04f89bc7719f929019b7398b1e1 = 'UQ_04f89bc7719f929019b7398b1e1',
}

/**
 * update columns of table "github_app_installations"
 */
export enum github_app_installations_update_column {
  accountSid = 'accountSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  githubAppSid = 'githubAppSid',
  id = 'id',
  organizationSid = 'organizationSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_apps"
 */
export enum github_apps_constraint {
  PK_4d362c8601aee838dc04e3e1904 = 'PK_4d362c8601aee838dc04e3e1904',
  UQ_3c3d2434df6adcd1aceb1ed7927 = 'UQ_3c3d2434df6adcd1aceb1ed7927',
  UQ_45178db4708e689b75f88e07a23 = 'UQ_45178db4708e689b75f88e07a23',
  UQ_b99f282d90d05c05006c09edbed = 'UQ_b99f282d90d05c05006c09edbed',
  UQ_c5f0cc22818db7b3976e6ab05b7 = 'UQ_c5f0cc22818db7b3976e6ab05b7',
  UQ_f49df63ed9faeade2db9eefa9ff = 'UQ_f49df63ed9faeade2db9eefa9ff',
}

/**
 * update columns of table "github_apps"
 */
export enum github_apps_update_column {
  adminAccountSid = 'adminAccountSid',
  admingOrganizationSid = 'admingOrganizationSid',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  oAuthKey = 'oAuthKey',
  oAuthSecret = 'oAuthSecret',
  privateKey = 'privateKey',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_branch_commits"
 */
export enum github_branch_commits_constraint {
  PK_09f54f4cb0888c191dabc256a05 = 'PK_09f54f4cb0888c191dabc256a05',
}

/**
 * update columns of table "github_branch_commits"
 */
export enum github_branch_commits_update_column {
  branchSid = 'branchSid',
  commitSid = 'commitSid',
}

/**
 * unique or primary key constraints on table "github_branches"
 */
export enum github_branches_constraint {
  PK_1d6eef97fe8fe9c1d02adb090af = 'PK_1d6eef97fe8fe9c1d02adb090af',
  UQ_44174ce964dac5e91ba6967d87c = 'UQ_44174ce964dac5e91ba6967d87c',
  UQ_640316971cf162e4437fb8bd589 = 'UQ_640316971cf162e4437fb8bd589',
  UQ_7f0d3f1716dcbb15379072c45a2 = 'UQ_7f0d3f1716dcbb15379072c45a2',
  UQ_GITHUB_BRANCH_REPO_NAME = 'UQ_GITHUB_BRANCH_REPO_NAME',
}

/**
 * update columns of table "github_branches"
 */
export enum github_branches_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitBranchId = 'gitBranchId',
  headCommitSid = 'headCommitSid',
  id = 'id',
  name = 'name',
  oid = 'oid',
  repoSid = 'repoSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_code_comments"
 */
export enum github_code_comments_constraint {
  PK_7d81f0f7b48b1a2e9a4b0e1bff4 = 'PK_7d81f0f7b48b1a2e9a4b0e1bff4',
  UQ_27feda6b32a0f3dba99ea2ac150 = 'UQ_27feda6b32a0f3dba99ea2ac150',
}

/**
 * update columns of table "github_code_comments"
 */
export enum github_code_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  commitSid = 'commitSid',
  createdAt = 'createdAt',
  diffHunk = 'diffHunk',
  expiresInType = 'expiresInType',
  id = 'id',
  isForked = 'isForked',
  isOutdated = 'isOutdated',
  path = 'path',
  pullRequestSid = 'pullRequestSid',
  redundantFields = 'redundantFields',
  sid = 'sid',
  syncError = 'syncError',
  syncStartedAt = 'syncStartedAt',
  syncedAt = 'syncedAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_comment_reactions"
 */
export enum github_comment_reactions_constraint {
  PK_380f1eb202a6d6705f3f5397f5d = 'PK_380f1eb202a6d6705f3f5397f5d',
  UQ_57c14f9f3b662bc7cc882384733 = 'UQ_57c14f9f3b662bc7cc882384733',
  UQ_7bf31b1b401b40bdabc3da65ec1 = 'UQ_7bf31b1b401b40bdabc3da65ec1',
  UQ_ce6c28c59334ed806116f91dbfd = 'UQ_ce6c28c59334ed806116f91dbfd',
}

/**
 * update columns of table "github_comment_reactions"
 */
export enum github_comment_reactions_update_column {
  authorLogin = 'authorLogin',
  content = 'content',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitCommentReactionId = 'gitCommentReactionId',
  id = 'id',
  issueCommentSid = 'issueCommentSid',
  issueSid = 'issueSid',
  pullRequestCommentSid = 'pullRequestCommentSid',
  pullRequestReviewCommentSid = 'pullRequestReviewCommentSid',
  pullRequestReviewSid = 'pullRequestReviewSid',
  pullRequestSid = 'pullRequestSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  syncTableName = 'syncTableName',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_comments"
 */
export enum github_comments_constraint {
  PK_2772404f0123e57638e37fccfcb = 'PK_2772404f0123e57638e37fccfcb',
  UQ_0d5e5f0f7948dc5187204b312f9 = 'UQ_0d5e5f0f7948dc5187204b312f9',
}

/**
 * update columns of table "github_comments"
 */
export enum github_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  expiresInType = 'expiresInType',
  id = 'id',
  isForked = 'isForked',
  isOutdated = 'isOutdated',
  issueSid = 'issueSid',
  pullRequestSid = 'pullRequestSid',
  redundantFields = 'redundantFields',
  sid = 'sid',
  syncError = 'syncError',
  syncStartedAt = 'syncStartedAt',
  syncedAt = 'syncedAt',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_commit_comments"
 */
export enum github_commit_comments_constraint {
  PK_41d2c1cb209dea0f416e21238eb = 'PK_41d2c1cb209dea0f416e21238eb',
  REL_493430ec38c160841af1cc07be = 'REL_493430ec38c160841af1cc07be',
  UQ_e0d7ca36f78c8a4fb7f371ff2f3 = 'UQ_e0d7ca36f78c8a4fb7f371ff2f3',
  UQ_f4310c04176a4e2ccb5f0ef4e5c = 'UQ_f4310c04176a4e2ccb5f0ef4e5c',
  UQ_f512a103d49b474c81ec887ec98 = 'UQ_f512a103d49b474c81ec887ec98',
}

/**
 * update columns of table "github_commit_comments"
 */
export enum github_commit_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  commitSid = 'commitSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  gitCommitCommentId = 'gitCommitCommentId',
  id = 'id',
  position = 'position',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_commit_contexts"
 */
export enum github_commit_contexts_constraint {
  PK_7269d39084d66d4a00e6d5b603a = 'PK_7269d39084d66d4a00e6d5b603a',
  UQ_3320c5d5531de7660418eea62f6 = 'UQ_3320c5d5531de7660418eea62f6',
  UQ_44a6fe91a28b85b9cc16e44bdfa = 'UQ_44a6fe91a28b85b9cc16e44bdfa',
  UQ_6e45523083e9959a03c64252a29 = 'UQ_6e45523083e9959a03c64252a29',
}

/**
 * update columns of table "github_commit_contexts"
 */
export enum github_commit_contexts_update_column {
  avatarUrl = 'avatarUrl',
  commitSid = 'commitSid',
  context = 'context',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  gitCommitContextId = 'gitCommitContextId',
  id = 'id',
  sid = 'sid',
  status = 'status',
  syncEntityId = 'syncEntityId',
  targetUrl = 'targetUrl',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_commit_status"
 */
export enum github_commit_status_constraint {
  PK_b79673170f5024e1dcc54090854 = 'PK_b79673170f5024e1dcc54090854',
}

export enum github_commit_status_enum {
  ERROR = 'ERROR',
  EXPECTED = 'EXPECTED',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

/**
 * update columns of table "github_commit_status"
 */
export enum github_commit_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "github_commits"
 */
export enum github_commits_constraint {
  PK_57b96ef49e8ced6fa62005e0d45 = 'PK_57b96ef49e8ced6fa62005e0d45',
  UQ_633fe494675abe239c98e7eccf6 = 'UQ_633fe494675abe239c98e7eccf6',
  UQ_c5335637dbe3260add6111d8d2f = 'UQ_c5335637dbe3260add6111d8d2f',
  UQ_dd93878f52b3a8275984523d92e = 'UQ_dd93878f52b3a8275984523d92e',
}

/**
 * update columns of table "github_commits"
 */
export enum github_commits_update_column {
  authorLogin = 'authorLogin',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitCommitId = 'gitCommitId',
  id = 'id',
  oid = 'oid',
  sid = 'sid',
  status = 'status',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_issue_changes"
 */
export enum github_issue_changes_constraint {
  PK_5d8c43207abd310fa5abaa6c12f = 'PK_5d8c43207abd310fa5abaa6c12f',
}

/**
 * update columns of table "github_issue_changes"
 */
export enum github_issue_changes_update_column {
  associatedRepoLabels = 'associatedRepoLabels',
  associatedTicketId = 'associatedTicketId',
  authorLogin = 'authorLogin',
  automaticallyChanged = 'automaticallyChanged',
  body = 'body',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  githubAccountAssignedSids = 'githubAccountAssignedSids',
  id = 'id',
  number = 'number',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  repoSid = 'repoSid',
  sid = 'sid',
  sqlQuery = 'sqlQuery',
  state = 'state',
  syncEntityId = 'syncEntityId',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_issue_comments"
 */
export enum github_issue_comments_constraint {
  PK_38b3f9b5475c81cf7ef4cad0f25 = 'PK_38b3f9b5475c81cf7ef4cad0f25',
  UQ_232ae046bcb3913578c12023011 = 'UQ_232ae046bcb3913578c12023011',
  UQ_615910122f72297868922b5d13c = 'UQ_615910122f72297868922b5d13c',
  UQ_cb4ff495bb5a8ab167df031a63b = 'UQ_cb4ff495bb5a8ab167df031a63b',
}

/**
 * update columns of table "github_issue_comments"
 */
export enum github_issue_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  issueSid = 'issueSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_issue_state"
 */
export enum github_issue_state_constraint {
  PK_496822808a3c78bfa8e30b0be28 = 'PK_496822808a3c78bfa8e30b0be28',
}

export enum github_issue_state_enum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

/**
 * update columns of table "github_issue_state"
 */
export enum github_issue_state_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "github_issue_ticket_status_mapping"
 */
export enum github_issue_ticket_status_mapping_constraint {
  PK_ebe805c75d5f3f31234f94bac48 = 'PK_ebe805c75d5f3f31234f94bac48',
  UQ_GITHUB_STATUS_MAPPING = 'UQ_GITHUB_STATUS_MAPPING',
}

/**
 * update columns of table "github_issue_ticket_status_mapping"
 */
export enum github_issue_ticket_status_mapping_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  githubAccountSid = 'githubAccountSid',
  githubRepoSid = 'githubRepoSid',
  id = 'id',
  issueState = 'issueState',
  labelId = 'labelId',
  prioritizeUpstream = 'prioritizeUpstream',
  ticketStatus = 'ticketStatus',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_issues"
 */
export enum github_issues_constraint {
  PK_756f34b9803d3eebfb311e07150 = 'PK_756f34b9803d3eebfb311e07150',
  UQ_4f8f84b70c26adabc43ed735d06 = 'UQ_4f8f84b70c26adabc43ed735d06',
  UQ_763d8e062081ec0c19bea619853 = 'UQ_763d8e062081ec0c19bea619853',
  UQ_bc860e689cd90c50052b4d5e425 = 'UQ_bc860e689cd90c50052b4d5e425',
}

/**
 * update columns of table "github_issues"
 */
export enum github_issues_update_column {
  associatedRepoLabels = 'associatedRepoLabels',
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  githubAccountAssignedSids = 'githubAccountAssignedSids',
  id = 'id',
  number = 'number',
  repoSid = 'repoSid',
  sid = 'sid',
  state = 'state',
  syncEntityId = 'syncEntityId',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_organization_members"
 */
export enum github_organization_members_constraint {
  PK_b34326414556afabd677fdedbba = 'PK_b34326414556afabd677fdedbba',
  REL_3575a0d201c12648e8d260c039 = 'REL_3575a0d201c12648e8d260c039',
  UQ_4aa7b7f2c41839515204d853683 = 'UQ_4aa7b7f2c41839515204d853683',
}

/**
 * update columns of table "github_organization_members"
 */
export enum github_organization_members_update_column {
  accountLogin = 'accountLogin',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  organizationSid = 'organizationSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_organizations"
 */
export enum github_organizations_constraint {
  PK_1a69589fba675b6d60d497f5ec8 = 'PK_1a69589fba675b6d60d497f5ec8',
  REL_48ce8aab4daba5e58b832c4130 = 'REL_48ce8aab4daba5e58b832c4130',
  UQ_13c2a462950dfd1f3c69cedf6df = 'UQ_13c2a462950dfd1f3c69cedf6df',
  UQ_534f98da41a0d26e8efed61ed9b = 'UQ_534f98da41a0d26e8efed61ed9b',
  UQ_ce9830129c9706d84fa400b44ed = 'UQ_ce9830129c9706d84fa400b44ed',
}

/**
 * update columns of table "github_organizations"
 */
export enum github_organizations_update_column {
  avatarUrl = 'avatarUrl',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitOrganizationId = 'gitOrganizationId',
  id = 'id',
  login = 'login',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_owners"
 */
export enum github_owners_constraint {
  PK_ab3ace114efcda7fdba945629cc = 'PK_ab3ace114efcda7fdba945629cc',
  UQ_46897942074a6f1c35cd6536a5e = 'UQ_46897942074a6f1c35cd6536a5e',
  UQ_61c92398cc8af1ada4d35eaeccd = 'UQ_61c92398cc8af1ada4d35eaeccd',
  UQ_63a03135f0c134170b89f45e86d = 'UQ_63a03135f0c134170b89f45e86d',
}

/**
 * update columns of table "github_owners"
 */
export enum github_owners_update_column {
  agencyId = 'agencyId',
  avatarUrl = 'avatarUrl',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  login = 'login',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_pull_request_comments"
 */
export enum github_pull_request_comments_constraint {
  PK_2caa6ccaeb876aaf96bc88aaa59 = 'PK_2caa6ccaeb876aaf96bc88aaa59',
  UQ_06e813633f94aadb00e37eb6180 = 'UQ_06e813633f94aadb00e37eb6180',
  UQ_89e9bbd819631cb57f7dfa91fa3 = 'UQ_89e9bbd819631cb57f7dfa91fa3',
  UQ_90588fabf1c082dfb9400e2f3dd = 'UQ_90588fabf1c082dfb9400e2f3dd',
  UQ_a25ca01d7f2c284faaea11ffd0f = 'UQ_a25ca01d7f2c284faaea11ffd0f',
}

/**
 * update columns of table "github_pull_request_comments"
 */
export enum github_pull_request_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  gitPullRequestCommentId = 'gitPullRequestCommentId',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_pull_request_review_comments"
 */
export enum github_pull_request_review_comments_constraint {
  PK_3682193b858407028d1e4655aaa = 'PK_3682193b858407028d1e4655aaa',
  UQ_5586575542249fc31e95fa36780 = 'UQ_5586575542249fc31e95fa36780',
  UQ_7f545fa77a281b7e214a94fe0db = 'UQ_7f545fa77a281b7e214a94fe0db',
  UQ_e480d42546d3023aa673c441b1a = 'UQ_e480d42546d3023aa673c441b1a',
  UQ_f11344deee5034b1c94ac57ecc0 = 'UQ_f11344deee5034b1c94ac57ecc0',
}

/**
 * update columns of table "github_pull_request_review_comments"
 */
export enum github_pull_request_review_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  commitSid = 'commitSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  diffHunk = 'diffHunk',
  discussionCommentId = 'discussionCommentId',
  gitPullRequestReviewCommentId = 'gitPullRequestReviewCommentId',
  id = 'id',
  outdated = 'outdated',
  path = 'path',
  position = 'position',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_pull_request_review_threads"
 */
export enum github_pull_request_review_threads_constraint {
  PK_cdebd98d58d506f3803f84f5861 = 'PK_cdebd98d58d506f3803f84f5861',
  UQ_7bdbf6993ec3199429c6981c25b = 'UQ_7bdbf6993ec3199429c6981c25b',
  UQ_a7a2cb2b3eda851cc28fbb090de = 'UQ_a7a2cb2b3eda851cc28fbb090de',
  UQ_c61b6be61d64d1b416c0b635405 = 'UQ_c61b6be61d64d1b416c0b635405',
}

/**
 * update columns of table "github_pull_request_review_threads"
 */
export enum github_pull_request_review_threads_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitPullRequestReviewThreadsId = 'gitPullRequestReviewThreadsId',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_pull_request_reviews"
 */
export enum github_pull_request_reviews_constraint {
  PK_58d77da3cd072a6f6ace0d63782 = 'PK_58d77da3cd072a6f6ace0d63782',
  UQ_6372b8eec0d71d3ac40235f61db = 'UQ_6372b8eec0d71d3ac40235f61db',
  UQ_e78bba835366f11aa9096896e4d = 'UQ_e78bba835366f11aa9096896e4d',
  UQ_f5850d41daf1f04a4fa3aeb3ba9 = 'UQ_f5850d41daf1f04a4fa3aeb3ba9',
}

/**
 * update columns of table "github_pull_request_reviews"
 */
export enum github_pull_request_reviews_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitPullRequestReviewsId = 'gitPullRequestReviewsId',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_repo_labels"
 */
export enum github_repo_labels_constraint {
  PK_0aaea27e768c465bdaff4ed2d04 = 'PK_0aaea27e768c465bdaff4ed2d04',
  REL_b2c8f7db33ce2eb4dd0edf6567 = 'REL_b2c8f7db33ce2eb4dd0edf6567',
  UQ_7ac4be083ae9a96e0381e479320 = 'UQ_7ac4be083ae9a96e0381e479320',
}

/**
 * update columns of table "github_repo_labels"
 */
export enum github_repo_labels_update_column {
  color = 'color',
  createdAt = 'createdAt',
  default = 'default',
  deletedAt = 'deletedAt',
  description = 'description',
  githubRepoSid = 'githubRepoSid',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  url = 'url',
}

/**
 * unique or primary key constraints on table "github_repo_teams"
 */
export enum github_repo_teams_constraint {
  PK_aeaadeef3c224b6e013d1259d3a = 'PK_aeaadeef3c224b6e013d1259d3a',
  REL_905a2a3f053acf0389711731d2 = 'REL_905a2a3f053acf0389711731d2',
  UQ_808964dff230d198cc7155162a3 = 'UQ_808964dff230d198cc7155162a3',
}

/**
 * update columns of table "github_repo_teams"
 */
export enum github_repo_teams_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  permission = 'permission',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_repos"
 */
export enum github_repos_constraint {
  PK_30cf92866ef493943da404df6a4 = 'PK_30cf92866ef493943da404df6a4',
  UQ_980e72bdc6b2b8cc1b40b558d7e = 'UQ_980e72bdc6b2b8cc1b40b558d7e',
  UQ_f95d39e2c34dddc8d6eea80aa1d = 'UQ_f95d39e2c34dddc8d6eea80aa1d',
}

/**
 * update columns of table "github_repos"
 */
export enum github_repos_update_column {
  accessGithubAccountSids = 'accessGithubAccountSids',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  isArchived = 'isArchived',
  isDisabled = 'isDisabled',
  isFork = 'isFork',
  isLocked = 'isLocked',
  isPrivate = 'isPrivate',
  isTemplate = 'isTemplate',
  issuesEtag = 'issuesEtag',
  issuesLastUpdatedAt = 'issuesLastUpdatedAt',
  name = 'name',
  ownerLogin = 'ownerLogin',
  pullRequestsEtag = 'pullRequestsEtag',
  pullRequestsLastUpdatedAt = 'pullRequestsLastUpdatedAt',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_team_members"
 */
export enum github_team_members_constraint {
  PK_f9e21e48eb19392d7dff945e3ec = 'PK_f9e21e48eb19392d7dff945e3ec',
  REL_e10316eb6152e401e3c8e079c1 = 'REL_e10316eb6152e401e3c8e079c1',
  UQ_2be9c3a11ab99ba334ba9f7be1f = 'UQ_2be9c3a11ab99ba334ba9f7be1f',
}

/**
 * update columns of table "github_team_members"
 */
export enum github_team_members_update_column {
  accountLogin = 'accountLogin',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_teams"
 */
export enum github_teams_constraint {
  PK_a28771f2433e0612e1613712952 = 'PK_a28771f2433e0612e1613712952',
  REL_52a6b56caccc27dbb05218b9bd = 'REL_52a6b56caccc27dbb05218b9bd',
  UQ_be29b12c9a31385c6847150baa7 = 'UQ_be29b12c9a31385c6847150baa7',
}

/**
 * update columns of table "github_teams"
 */
export enum github_teams_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  organizationSid = 'organizationSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "github_tokens"
 */
export enum github_tokens_constraint {
  PK_0954e87a291e2cf59399fcad34f = 'PK_0954e87a291e2cf59399fcad34f',
  UQ_8fd68352639d55c808e18f8548d = 'UQ_8fd68352639d55c808e18f8548d',
}

/**
 * update columns of table "github_tokens"
 */
export enum github_tokens_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  limit = 'limit',
  remaining = 'remaining',
  resetAt = 'resetAt',
  token = 'token',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_applications"
 */
export enum gitlab_applications_constraint {
  PK_dc1c21e81a4932b4c3434e80762 = 'PK_dc1c21e81a4932b4c3434e80762',
  REL_619d58230393ec1ca35ac646f7 = 'REL_619d58230393ec1ca35ac646f7',
  UQ_ed184506bcbe4a9c057c39e1dff = 'UQ_ed184506bcbe4a9c057c39e1dff',
}

/**
 * update columns of table "gitlab_applications"
 */
export enum gitlab_applications_update_column {
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  redirectURIs = 'redirectURIs',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_groups"
 */
export enum gitlab_groups_constraint {
  PK_ea62b0444aa16f7e346c78cbfac = 'PK_ea62b0444aa16f7e346c78cbfac',
  REL_35c2c6fd280f39cd9125b591cf = 'REL_35c2c6fd280f39cd9125b591cf',
  UQ_4768d7a3b54899a3304fc5beac8 = 'UQ_4768d7a3b54899a3304fc5beac8',
  UQ_d733e56e7557d6d99d6e8055053 = 'UQ_d733e56e7557d6d99d6e8055053',
}

/**
 * update columns of table "gitlab_groups"
 */
export enum gitlab_groups_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  login = 'login',
  namespaceSid = 'namespaceSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_issue_comments"
 */
export enum gitlab_issue_comments_constraint {
  PK_6bc5a105de73c0dc906af92f105 = 'PK_6bc5a105de73c0dc906af92f105',
  REL_15b0d276ceff5693620d2c0e3a = 'REL_15b0d276ceff5693620d2c0e3a',
  REL_743d24a5263b5ab7d94605f7b6 = 'REL_743d24a5263b5ab7d94605f7b6',
  UQ_062fc3c7736f5201f86d35d6194 = 'UQ_062fc3c7736f5201f86d35d6194',
}

/**
 * update columns of table "gitlab_issue_comments"
 */
export enum gitlab_issue_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  issueSid = 'issueSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_issue_state"
 */
export enum gitlab_issue_state_constraint {
  PK_980148b6311f65749083325f597 = 'PK_980148b6311f65749083325f597',
}

export enum gitlab_issue_state_enum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

/**
 * update columns of table "gitlab_issue_state"
 */
export enum gitlab_issue_state_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "gitlab_issues"
 */
export enum gitlab_issues_constraint {
  PK_2ba0ff6a23f735dc53e3a9cb06e = 'PK_2ba0ff6a23f735dc53e3a9cb06e',
  REL_406ca787ad8559aa5d165099db = 'REL_406ca787ad8559aa5d165099db',
  REL_b80f14c4217023ac9ddd5b6fbb = 'REL_b80f14c4217023ac9ddd5b6fbb',
  UQ_722705668d66190a3b855dedac4 = 'UQ_722705668d66190a3b855dedac4',
}

/**
 * update columns of table "gitlab_issues"
 */
export enum gitlab_issues_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  dueDate = 'dueDate',
  gitlabAccountAssignedSids = 'gitlabAccountAssignedSids',
  id = 'id',
  number = 'number',
  projectSid = 'projectSid',
  sid = 'sid',
  state = 'state',
  syncEntityId = 'syncEntityId',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_namespaces"
 */
export enum gitlab_namespaces_constraint {
  PK_af28be1bf27ec66b135b6987286 = 'PK_af28be1bf27ec66b135b6987286',
  REL_3be85b9581cdb41cd799c47e8d = 'REL_3be85b9581cdb41cd799c47e8d',
  UQ_30b13d5a593b3811642dd2fba5c = 'UQ_30b13d5a593b3811642dd2fba5c',
  UQ_bf7a5fad1cf3d1fe7186d120491 = 'UQ_bf7a5fad1cf3d1fe7186d120491',
}

/**
 * update columns of table "gitlab_namespaces"
 */
export enum gitlab_namespaces_update_column {
  agencyId = 'agencyId',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  login = 'login',
  parentNamespaceSid = 'parentNamespaceSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_projects"
 */
export enum gitlab_projects_constraint {
  GITLAB_PROJECT_UQ = 'GITLAB_PROJECT_UQ',
  PK_317cc2c186494a266ce1a81d9ae = 'PK_317cc2c186494a266ce1a81d9ae',
  REL_8ce37cc9054e5570862c1846ef = 'REL_8ce37cc9054e5570862c1846ef',
  UQ_93783cd85cc93b02bc89d02d078 = 'UQ_93783cd85cc93b02bc89d02d078',
}

/**
 * update columns of table "gitlab_projects"
 */
export enum gitlab_projects_update_column {
  accessGitlabUserSids = 'accessGitlabUserSids',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  login = 'login',
  namespaceSid = 'namespaceSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_pull_request_comments"
 */
export enum gitlab_pull_request_comments_constraint {
  PK_2dd0c2393a38056eccf8419bc01 = 'PK_2dd0c2393a38056eccf8419bc01',
  REL_63e985263b7c8dfae02ba145ff = 'REL_63e985263b7c8dfae02ba145ff',
  REL_7a493b6bbc26a0e062d095ae68 = 'REL_7a493b6bbc26a0e062d095ae68',
  REL_94e11b6b609c571556f9c2ee15 = 'REL_94e11b6b609c571556f9c2ee15',
  UQ_d78f5b2c8fe58b6bcb6d119794f = 'UQ_d78f5b2c8fe58b6bcb6d119794f',
}

/**
 * update columns of table "gitlab_pull_request_comments"
 */
export enum gitlab_pull_request_comments_update_column {
  authorLogin = 'authorLogin',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  gitPullRequestCommentId = 'gitPullRequestCommentId',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_pull_requests"
 */
export enum gitlab_pull_requests_constraint {
  PK_5c8fe59456ec117b7e4c3535f3c = 'PK_5c8fe59456ec117b7e4c3535f3c',
  REL_6c5b59ae90e2fd334044617249 = 'REL_6c5b59ae90e2fd334044617249',
  UQ_795d03ba237abd0deacbda89e3f = 'UQ_795d03ba237abd0deacbda89e3f',
  UQ_f8d2a5532e16eaa094ae75638b0 = 'UQ_f8d2a5532e16eaa094ae75638b0',
}

/**
 * update columns of table "gitlab_pull_requests"
 */
export enum gitlab_pull_requests_update_column {
  authorLogin = 'authorLogin',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitPullRequestId = 'gitPullRequestId',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_tokens"
 */
export enum gitlab_tokens_constraint {
  PK_49089a6914f8a2e48b5bef6e1c0 = 'PK_49089a6914f8a2e48b5bef6e1c0',
  UQ_c99c9bca5d7c4bf998f3a3b2005 = 'UQ_c99c9bca5d7c4bf998f3a3b2005',
}

/**
 * update columns of table "gitlab_tokens"
 */
export enum gitlab_tokens_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  limit = 'limit',
  remaining = 'remaining',
  resetAt = 'resetAt',
  token = 'token',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "gitlab_users"
 */
export enum gitlab_users_constraint {
  PK_4d99223db54d8f8936ecf13292a = 'PK_4d99223db54d8f8936ecf13292a',
  REL_21abd2c302fbef8d6afdd85197 = 'REL_21abd2c302fbef8d6afdd85197',
  REL_3ef8df05a0daf82c63de968d75 = 'REL_3ef8df05a0daf82c63de968d75',
  UQ_9c3954923308cf82c1116061873 = 'UQ_9c3954923308cf82c1116061873',
  UQ_d40c810e3ca0ccdc7528fb15714 = 'UQ_d40c810e3ca0ccdc7528fb15714',
  UQ_de9ea9318c8f382cc2b8cab79be = 'UQ_de9ea9318c8f382cc2b8cab79be',
  UQ_f89f9f55312cb2eab1b0f9e329a = 'UQ_f89f9f55312cb2eab1b0f9e329a',
}

/**
 * update columns of table "gitlab_users"
 */
export enum gitlab_users_update_column {
  botId = 'botId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gitUserId = 'gitUserId',
  id = 'id',
  login = 'login',
  personalAccessToken = 'personalAccessToken',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "interviewer_status"
 */
export enum interviewer_status_constraint {
  PK_52b9865df984dc923954659fb84 = 'PK_52b9865df984dc923954659fb84',
}

/**
 * update columns of table "interviewer_status"
 */
export enum interviewer_status_update_column {
  type = 'type',
}

/**
 * unique or primary key constraints on table "interviewers"
 */
export enum interviewers_constraint {
  PK_f7a7314e1e7d88e6751ea54a349 = 'PK_f7a7314e1e7d88e6751ea54a349',
}

/**
 * update columns of table "interviewers"
 */
export enum interviewers_update_column {
  appliedAt = 'appliedAt',
  completedAt = 'completedAt',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  reasonsForApplying = 'reasonsForApplying',
  status = 'status',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "jira_accounts"
 */
export enum jira_accounts_constraint {
  PK_dbb18913fc27ef93e5ab7c49e9d = 'PK_dbb18913fc27ef93e5ab7c49e9d',
  REL_81aefd0d88f8d8fb17f63d85b9 = 'REL_81aefd0d88f8d8fb17f63d85b9',
  REL_dc593db8f64194b9cdc33e39d1 = 'REL_dc593db8f64194b9cdc33e39d1',
  UQ_15476c45cd9f7ddf105f6316cc1 = 'UQ_15476c45cd9f7ddf105f6316cc1',
  UQ_c58300be0936b0327c065d9eb85 = 'UQ_c58300be0936b0327c065d9eb85',
}

/**
 * update columns of table "jira_accounts"
 */
export enum jira_accounts_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  email = 'email',
  id = 'id',
  jiraOrgEndpoint = 'jiraOrgEndpoint',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  token = 'token',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_app_installations"
 */
export enum jira_app_installations_constraint {
  PK_4817d0ab15b4b01031621c19930 = 'PK_4817d0ab15b4b01031621c19930',
  REL_bddaddab2b0830845d720cd08b = 'REL_bddaddab2b0830845d720cd08b',
  UQ_68b4cd7f39b708b5db2f15c05ce = 'UQ_68b4cd7f39b708b5db2f15c05ce',
}

/**
 * update columns of table "jira_app_installations"
 */
export enum jira_app_installations_update_column {
  accessToken = 'accessToken',
  accessTokenExpiresAt = 'accessTokenExpiresAt',
  accessTokenType = 'accessTokenType',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  jiraAppId = 'jiraAppId',
  refreshToken = 'refreshToken',
  scopes = 'scopes',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "jira_apps"
 */
export enum jira_apps_constraint {
  PK_f53c035bc8dcdd997e883e67f98 = 'PK_f53c035bc8dcdd997e883e67f98',
  REL_4ee52c7c4a181ace794f1fb798 = 'REL_4ee52c7c4a181ace794f1fb798',
  UQ_1503d779ab2266473ac8a6dc429 = 'UQ_1503d779ab2266473ac8a6dc429',
  UQ_JIRA_CLIENT_ID = 'UQ_JIRA_CLIENT_ID',
  UQ_JIRA_CLIENT_SECRET = 'UQ_JIRA_CLIENT_SECRET',
}

/**
 * update columns of table "jira_apps"
 */
export enum jira_apps_update_column {
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isPublic = 'isPublic',
  name = 'name',
  scopes = 'scopes',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_attachments"
 */
export enum jira_issue_attachments_constraint {
  PK_837c5f85eb9160b3e4185102d7d = 'PK_837c5f85eb9160b3e4185102d7d',
  REL_0fc256f61fdcd7f8864686eade = 'REL_0fc256f61fdcd7f8864686eade',
  UQ_8076556cb45590a879cb9cec4a1 = 'UQ_8076556cb45590a879cb9cec4a1',
}

/**
 * update columns of table "jira_issue_attachments"
 */
export enum jira_issue_attachments_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gcpObjectId = 'gcpObjectId',
  id = 'id',
  issueSid = 'issueSid',
  properties = 'properties',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_changes"
 */
export enum jira_issue_changes_constraint {
  PK_752539f0b5e24e98830ceed9240 = 'PK_752539f0b5e24e98830ceed9240',
}

/**
 * update columns of table "jira_issue_changes"
 */
export enum jira_issue_changes_update_column {
  accessFieldValues = 'accessFieldValues',
  assigneeSid = 'assigneeSid',
  authorSid = 'authorSid',
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  issueTypeSid = 'issueTypeSid',
  key = 'key',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  projectSid = 'projectSid',
  properties = 'properties',
  reasonForChange = 'reasonForChange',
  self = 'self',
  sid = 'sid',
  sqlQuery = 'sqlQuery',
  status = 'status',
  syncEntityId = 'syncEntityId',
  transitions = 'transitions',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_comment"
 */
export enum jira_issue_comment_constraint {
  PK_d8767b52f9d32ca6b487c148b7b = 'PK_d8767b52f9d32ca6b487c148b7b',
  REL_b5d919bd4740a73eea81d64d57 = 'REL_b5d919bd4740a73eea81d64d57',
  UQ_8229bc96828464738e0a5e366ce = 'UQ_8229bc96828464738e0a5e366ce',
  UQ_ca51006408bcd90e02c9cb91579 = 'UQ_ca51006408bcd90e02c9cb91579',
}

/**
 * update columns of table "jira_issue_comment"
 */
export enum jira_issue_comment_update_column {
  authorSid = 'authorSid',
  body = 'body',
  bodyHTML = 'bodyHTML',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  issueSid = 'issueSid',
  properties = 'properties',
  referenceBody = 'referenceBody',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  visibility = 'visibility',
}

/**
 * unique or primary key constraints on table "jira_issue_fields"
 */
export enum jira_issue_fields_constraint {
  PK_d0c44ac7a39a07e1d9205039dfa = 'PK_d0c44ac7a39a07e1d9205039dfa',
  UQ_f55c23c24740849b1b42e6c2437 = 'UQ_f55c23c24740849b1b42e6c2437',
}

/**
 * update columns of table "jira_issue_fields"
 */
export enum jira_issue_fields_update_column {
  createdAt = 'createdAt',
  custom = 'custom',
  deletedAt = 'deletedAt',
  id = 'id',
  key = 'key',
  name = 'name',
  projectSid = 'projectSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_ticket_status_mapping"
 */
export enum jira_issue_ticket_status_mapping_constraint {
  PK_f6ef5ee7e4e240ef7c040e500bc = 'PK_f6ef5ee7e4e240ef7c040e500bc',
  UQ_JIRA_STATUS_MAPPING = 'UQ_JIRA_STATUS_MAPPING',
}

/**
 * update columns of table "jira_issue_ticket_status_mapping"
 */
export enum jira_issue_ticket_status_mapping_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  issueState = 'issueState',
  jiraAccountSid = 'jiraAccountSid',
  jiraProjectSid = 'jiraProjectSid',
  prioritizeUpstream = 'prioritizeUpstream',
  ticketStatus = 'ticketStatus',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_transitions"
 */
export enum jira_issue_transitions_constraint {
  PK_baafdc497af9723b3af5141a5b2 = 'PK_baafdc497af9723b3af5141a5b2',
  REL_d7386e1b196bad8691b08fadca = 'REL_d7386e1b196bad8691b08fadca',
  UQ_69795bac0978bd193bd2e3b49c5 = 'UQ_69795bac0978bd193bd2e3b49c5',
  UQ_JIRA_ISSUE_TRANSITION = 'UQ_JIRA_ISSUE_TRANSITION',
}

/**
 * update columns of table "jira_issue_transitions"
 */
export enum jira_issue_transitions_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  issueSid = 'issueSid',
  name = 'name',
  sid = 'sid',
  status = 'status',
  syncEntityId = 'syncEntityId',
  transitionId = 'transitionId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_type_statuses"
 */
export enum jira_issue_type_statuses_constraint {
  PK_20ca9d429ffc8162a33a4c7522e = 'PK_20ca9d429ffc8162a33a4c7522e',
  REL_37ceaad8af7a4e871c012edff2 = 'REL_37ceaad8af7a4e871c012edff2',
  UQ_4ef3d63e0bc1621f280a0acbf5a = 'UQ_4ef3d63e0bc1621f280a0acbf5a',
}

/**
 * update columns of table "jira_issue_type_statuses"
 */
export enum jira_issue_type_statuses_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  jiraIssueTypeSid = 'jiraIssueTypeSid',
  name = 'name',
  self = 'self',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issue_types"
 */
export enum jira_issue_types_constraint {
  PK_fce9e35f48bd62eec493c431fd1 = 'PK_fce9e35f48bd62eec493c431fd1',
  REL_2dac894ddabff327822006a5cc = 'REL_2dac894ddabff327822006a5cc',
  UQ_c44e348a08cd0cd2dbc37b6126e = 'UQ_c44e348a08cd0cd2dbc37b6126e',
}

/**
 * update columns of table "jira_issue_types"
 */
export enum jira_issue_types_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  name = 'name',
  self = 'self',
  sid = 'sid',
  subtask = 'subtask',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_issues"
 */
export enum jira_issues_constraint {
  PK_d04941d6003fefe5650cb9be876 = 'PK_d04941d6003fefe5650cb9be876',
  REL_2a2d5da33380e5d2cd591088df = 'REL_2a2d5da33380e5d2cd591088df',
  UQ_59b904494094810aac56c0d7271 = 'UQ_59b904494094810aac56c0d7271',
  UQ_d734405210cf85cf13f2edddcfa = 'UQ_d734405210cf85cf13f2edddcfa',
}

/**
 * update columns of table "jira_issues"
 */
export enum jira_issues_update_column {
  accessFieldValues = 'accessFieldValues',
  assigneeSid = 'assigneeSid',
  authorSid = 'authorSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  issueTypeSid = 'issueTypeSid',
  key = 'key',
  projectSid = 'projectSid',
  properties = 'properties',
  self = 'self',
  sid = 'sid',
  status = 'status',
  syncEntityId = 'syncEntityId',
  transitions = 'transitions',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_organizations"
 */
export enum jira_organizations_constraint {
  PK_448b4f1bd6663300451e176ebbe = 'PK_448b4f1bd6663300451e176ebbe',
  REL_3165b5a12050c607799525d618 = 'REL_3165b5a12050c607799525d618',
  UQ_7c4ceb2ea4833a5e5f37cf51cc6 = 'UQ_7c4ceb2ea4833a5e5f37cf51cc6',
  UQ_c2b42b487b52810d8c82c9cf049 = 'UQ_c2b42b487b52810d8c82c9cf049',
  UQ_c32530a9a3cf6c554cc65018778 = 'UQ_c32530a9a3cf6c554cc65018778',
}

/**
 * update columns of table "jira_organizations"
 */
export enum jira_organizations_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endpoint = 'endpoint',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "jira_projects"
 */
export enum jira_projects_constraint {
  PK_5070095b35bd7932c852fb6bce9 = 'PK_5070095b35bd7932c852fb6bce9',
  REL_eecdeb413aa83b679eadbeceae = 'REL_eecdeb413aa83b679eadbeceae',
  UQ_183f697472b55785298434c753f = 'UQ_183f697472b55785298434c753f',
}

/**
 * update columns of table "jira_projects"
 */
export enum jira_projects_update_column {
  accessJiraAccounts = 'accessJiraAccounts',
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  endpoint = 'endpoint',
  id = 'id',
  key = 'key',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "job_intervals"
 */
export enum job_intervals_constraint {
  PK_37d96f6dc9406fa7351efe2a359 = 'PK_37d96f6dc9406fa7351efe2a359',
}

export enum job_intervals_enum {
  day = 'day',
  hr = 'hr',
  min = 'min',
  never = 'never',
  week = 'week',
}

/**
 * update columns of table "job_intervals"
 */
export enum job_intervals_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "legal_entities"
 */
export enum legal_entities_constraint {
  PK_a6cacc1f156a44c94e539476e2e = 'PK_a6cacc1f156a44c94e539476e2e',
}

export enum legal_entities_enum {
  HK = 'HK',
  USA = 'USA',
}

/**
 * update columns of table "legal_entities"
 */
export enum legal_entities_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "linear_accounts"
 */
export enum linear_accounts_constraint {
  PK_60e7b128da9936d86325c9e8f22 = 'PK_60e7b128da9936d86325c9e8f22',
  REL_5358577aa948ca586098d12d61 = 'REL_5358577aa948ca586098d12d61',
  REL_748228eacfcce5be2494d8d396 = 'REL_748228eacfcce5be2494d8d396',
  UQ_8aa2dedd13afa0e8f11f0cd6a0c = 'UQ_8aa2dedd13afa0e8f11f0cd6a0c',
  UQ_9be811a61995edb2f5b6ecff912 = 'UQ_9be811a61995edb2f5b6ecff912',
}

/**
 * update columns of table "linear_accounts"
 */
export enum linear_accounts_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  email = 'email',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  token = 'token',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "linear_app_installations"
 */
export enum linear_app_installations_constraint {
  PK_022ddfab86c01d1b953bb28c3a2 = 'PK_022ddfab86c01d1b953bb28c3a2',
  REL_c34fd8a1b3df561dce5c966ec8 = 'REL_c34fd8a1b3df561dce5c966ec8',
  UQ_88074d6df3fd15454b5f25ac4cc = 'UQ_88074d6df3fd15454b5f25ac4cc',
}

/**
 * update columns of table "linear_app_installations"
 */
export enum linear_app_installations_update_column {
  accessToken = 'accessToken',
  accessTokenType = 'accessTokenType',
  accountSid = 'accountSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  expiresAt = 'expiresAt',
  id = 'id',
  linearAppId = 'linearAppId',
  scopes = 'scopes',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "linear_apps"
 */
export enum linear_apps_constraint {
  PK_e4bb373ffad2008f70cf24fe749 = 'PK_e4bb373ffad2008f70cf24fe749',
  REL_a1fad4b0e0c5835668ba7a0b4b = 'REL_a1fad4b0e0c5835668ba7a0b4b',
  UQ_2936b111e793c5713e10c39fddf = 'UQ_2936b111e793c5713e10c39fddf',
  UQ_84a9193c222d4e5b16367f6cd81 = 'UQ_84a9193c222d4e5b16367f6cd81',
  UQ_d6410e55964aef93596d1f15df7 = 'UQ_d6410e55964aef93596d1f15df7',
}

/**
 * update columns of table "linear_apps"
 */
export enum linear_apps_update_column {
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "linear_comments"
 */
export enum linear_comments_constraint {
  PK_b5dfc360e594f5480a3bdd94fb7 = 'PK_b5dfc360e594f5480a3bdd94fb7',
  REL_b9bc9323ee4f02e9af183c86ce = 'REL_b9bc9323ee4f02e9af183c86ce',
  REL_f1545dc54d3528581d7a7d234e = 'REL_f1545dc54d3528581d7a7d234e',
  UQ_97764f94dcbaa6e7c7ab78b0944 = 'UQ_97764f94dcbaa6e7c7ab78b0944',
}

/**
 * update columns of table "linear_comments"
 */
export enum linear_comments_update_column {
  authorSid = 'authorSid',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  id = 'id',
  issueSid = 'issueSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "linear_issue_changes"
 */
export enum linear_issue_changes_constraint {
  PK_dd754c9244857ba1104b56b43d1 = 'PK_dd754c9244857ba1104b56b43d1',
}

/**
 * update columns of table "linear_issue_changes"
 */
export enum linear_issue_changes_update_column {
  assignedLinearAccounts = 'assignedLinearAccounts',
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  labels = 'labels',
  number = 'number',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  sid = 'sid',
  sqlQuery = 'sqlQuery',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  title = 'title',
  updatedAt = 'updatedAt',
  url = 'url',
}

/**
 * unique or primary key constraints on table "linear_issues"
 */
export enum linear_issues_constraint {
  PK_db40be3dc0a8c5aa1eaf06456d1 = 'PK_db40be3dc0a8c5aa1eaf06456d1',
  REL_c16468540cef6d4f8168691f58 = 'REL_c16468540cef6d4f8168691f58',
  UQ_55e7aa8236c4eb1969a7461b0c6 = 'UQ_55e7aa8236c4eb1969a7461b0c6',
  UQ_89924097d96ba0d15a930f815e6 = 'UQ_89924097d96ba0d15a930f815e6',
}

/**
 * update columns of table "linear_issues"
 */
export enum linear_issues_update_column {
  assignedLinearAccounts = 'assignedLinearAccounts',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  labels = 'labels',
  number = 'number',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  title = 'title',
  updatedAt = 'updatedAt',
  url = 'url',
}

/**
 * unique or primary key constraints on table "linear_teams"
 */
export enum linear_teams_constraint {
  PK_dae92a12142ab7ef7e186510830 = 'PK_dae92a12142ab7ef7e186510830',
  REL_627def7263ccd464d01e42f658 = 'REL_627def7263ccd464d01e42f658',
  UQ_9adf552c9e6558e631f74c3925d = 'UQ_9adf552c9e6558e631f74c3925d',
}

/**
 * update columns of table "linear_teams"
 */
export enum linear_teams_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  key = 'key',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "meeting_user_emails"
 */
export enum meeting_user_emails_constraint {
  PK_5e7b29729e34f618942e35885c9 = 'PK_5e7b29729e34f618942e35885c9',
}

/**
 * update columns of table "meeting_user_emails"
 */
export enum meeting_user_emails_update_column {
  email = 'email',
  meetingId = 'meetingId',
}

/**
 * unique or primary key constraints on table "meetings"
 */
export enum meetings_constraint {
  PK_aa73be861afa77eb4ed31f3ed57 = 'PK_aa73be861afa77eb4ed31f3ed57',
  UQ_MEETING_TIME = 'UQ_MEETING_TIME',
  UQ_c801cca6def3dc9f638928affee = 'UQ_c801cca6def3dc9f638928affee',
}

/**
 * update columns of table "meetings"
 */
export enum meetings_update_column {
  associatedClientId = 'associatedClientId',
  calendlyMeetingId = 'calendlyMeetingId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  scheduledAt = 'scheduledAt',
  updatedAt = 'updatedAt',
  zoomMeetingSid = 'zoomMeetingSid',
}

/**
 * unique or primary key constraints on table "mentor_status"
 */
export enum mentor_status_constraint {
  PK_6ea4e72d4d53ba3c7d53d73d40b = 'PK_6ea4e72d4d53ba3c7d53d73d40b',
}

/**
 * update columns of table "mentor_status"
 */
export enum mentor_status_update_column {
  type = 'type',
}

/**
 * unique or primary key constraints on table "mentors"
 */
export enum mentors_constraint {
  PK_67a614446eab992e4d0580afebf = 'PK_67a614446eab992e4d0580afebf',
}

/**
 * update columns of table "mentors"
 */
export enum mentors_update_column {
  appliedAt = 'appliedAt',
  completedAt = 'completedAt',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  reasonsForApplying = 'reasonsForApplying',
  status = 'status',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "mentorships"
 */
export enum mentorships_constraint {
  PK_57c594aeb4b0aed94a827f82c48 = 'PK_57c594aeb4b0aed94a827f82c48',
}

/**
 * update columns of table "mentorships"
 */
export enum mentorships_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endDate = 'endDate',
  id = 'id',
  menteeUserId = 'menteeUserId',
  mentorUserId = 'mentorUserId',
  startDate = 'startDate',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "notification_sources"
 */
export enum notification_sources_constraint {
  PK_7f26581bf926da61b62dfcadcdb = 'PK_7f26581bf926da61b62dfcadcdb',
}

export enum notification_sources_enum {
  discussions = 'discussions',
  health_checks = 'health_checks',
}

/**
 * update columns of table "notification_sources"
 */
export enum notification_sources_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "notifications"
 */
export enum notifications_constraint {
  PK_6a72c3c0f683f6462415e653c3a = 'PK_6a72c3c0f683f6462415e653c3a',
}

/**
 * update columns of table "notifications"
 */
export enum notifications_update_column {
  appLink = 'appLink',
  body = 'body',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionCommentId = 'discussionCommentId',
  errorId = 'errorId',
  id = 'id',
  isArchived = 'isArchived',
  isSeen = 'isSeen',
  projectId = 'projectId',
  sentAt = 'sentAt',
  sourceId = 'sourceId',
  taskId = 'taskId',
  teamId = 'teamId',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "opportunities"
 */
export enum opportunities_constraint {
  PK_4bd9cd12ddc0ff48a5a97ddebce = 'PK_4bd9cd12ddc0ff48a5a97ddebce',
}

/**
 * update columns of table "opportunities"
 */
export enum opportunities_update_column {
  availableUntil = 'availableUntil',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "opportunity_skill_requirements"
 */
export enum opportunity_skill_requirements_constraint {
  PK_d952fa82a12d6e373464c46a201 = 'PK_d952fa82a12d6e373464c46a201',
}

/**
 * update columns of table "opportunity_skill_requirements"
 */
export enum opportunity_skill_requirements_update_column {
  opportunitiesId = 'opportunitiesId',
  skillsId = 'skillsId',
}

/**
 * unique or primary key constraints on table "optional_technologies"
 */
export enum optional_technologies_constraint {
  PK_39b7ac1812c60faf9b9c9e5249f = 'PK_39b7ac1812c60faf9b9c9e5249f',
}

/**
 * update columns of table "optional_technologies"
 */
export enum optional_technologies_update_column {
  clientJobsId = 'clientJobsId',
  technologiesId = 'technologiesId',
}

/**
 * unique or primary key constraints on table "project_daily_standup"
 */
export enum project_daily_standup_constraint {
  PK_a519705a416a1c8f67c6d0cb7c7 = 'PK_a519705a416a1c8f67c6d0cb7c7',
}

/**
 * update columns of table "project_daily_standup"
 */
export enum project_daily_standup_update_column {
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  errorId = 'errorId',
  id = 'id',
  messageForClient = 'messageForClient',
  postedToClient = 'postedToClient',
  submittedAt = 'submittedAt',
  summarizedInClientDailyStandupId = 'summarizedInClientDailyStandupId',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  summary = 'summary',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "pull_request_estimates"
 */
export enum pull_request_estimates_constraint {
  pull_request_estimates_pkey = 'pull_request_estimates_pkey',
}

/**
 * update columns of table "pull_request_estimates"
 */
export enum pull_request_estimates_update_column {
  estimated_by_id = 'estimated_by_id',
  id = 'id',
  inserted_at = 'inserted_at',
  lower_estimate_in_credits = 'lower_estimate_in_credits',
  pull_request_id = 'pull_request_id',
  reviewed_by_id = 'reviewed_by_id',
  status = 'status',
  updated_at = 'updated_at',
  upper_estimate_in_credits = 'upper_estimate_in_credits',
}

/**
 * unique or primary key constraints on table "pull_requests"
 */
export enum pull_requests_constraint {
  pull_requests_pkey = 'pull_requests_pkey',
  pull_requests_sliced_pull_request_id_index = 'pull_requests_sliced_pull_request_id_index',
}

/**
 * update columns of table "pull_requests"
 */
export enum pull_requests_update_column {
  completed_at = 'completed_at',
  cost_finalized_at = 'cost_finalized_at',
  cost_in_credits = 'cost_in_credits',
  deleted_at = 'deleted_at',
  id = 'id',
  inserted_at = 'inserted_at',
  sliced_pull_request_id = 'sliced_pull_request_id',
  status = 'status',
  ticket_id = 'ticket_id',
  title = 'title',
  updated_at = 'updated_at',
  upstream_pull_request_id = 'upstream_pull_request_id',
}

/**
 * unique or primary key constraints on table "qualified_agencies"
 */
export enum qualified_agencies_constraint {
  PK_0e326f9283cdac352e0e30229c2 = 'PK_0e326f9283cdac352e0e30229c2',
}

/**
 * update columns of table "qualified_agencies"
 */
export enum qualified_agencies_update_column {
  agenciesId = 'agenciesId',
  clientsId = 'clientsId',
}

/**
 * unique or primary key constraints on table "qualified_technologies"
 */
export enum qualified_technologies_constraint {
  PK_b5b74f57dc32f2cc67212b6b3da = 'PK_b5b74f57dc32f2cc67212b6b3da',
}

/**
 * update columns of table "qualified_technologies"
 */
export enum qualified_technologies_update_column {
  clientJobsId = 'clientJobsId',
  technologiesId = 'technologiesId',
}

/**
 * unique or primary key constraints on table "recruiter_agencies"
 */
export enum recruiter_agencies_constraint {
  PK_dd3bc60e37f316e6f2027aa4ffa = 'PK_dd3bc60e37f316e6f2027aa4ffa',
}

/**
 * update columns of table "recruiter_agencies"
 */
export enum recruiter_agencies_update_column {
  agency_id = 'agency_id',
  recruiter_agency_id = 'recruiter_agency_id',
}

/**
 * unique or primary key constraints on table "recruiter.calendly_event_memberships"
 */
export enum recruiter_calendly_event_memberships_constraint {
  PK_0743bed5463c14d77f52cd8bc39 = 'PK_0743bed5463c14d77f52cd8bc39',
  UQ_USER_EVENT = 'UQ_USER_EVENT',
}

/**
 * update columns of table "recruiter.calendly_event_memberships"
 */
export enum recruiter_calendly_event_memberships_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  event = 'event',
  id = 'id',
  name = 'name',
  updatedAt = 'updatedAt',
  user = 'user',
}

/**
 * unique or primary key constraints on table "recruiter.calendly_event_types"
 */
export enum recruiter_calendly_event_types_constraint {
  PK_cdc426bef7cffb3dfeef0d2a73a = 'PK_cdc426bef7cffb3dfeef0d2a73a',
  REL_0f591a7f651b106a74b02a8ddc = 'REL_0f591a7f651b106a74b02a8ddc',
  UQ_b72c3b343e89bdf1821cd558557 = 'UQ_b72c3b343e89bdf1821cd558557',
}

/**
 * update columns of table "recruiter.calendly_event_types"
 */
export enum recruiter_calendly_event_types_update_column {
  active = 'active',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  durationInMins = 'durationInMins',
  id = 'id',
  kind = 'kind',
  name = 'name',
  owner = 'owner',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "recruiter.calendly_events"
 */
export enum recruiter_calendly_events_constraint {
  PK_5dd6b4f8617823ddd84bf87506d = 'PK_5dd6b4f8617823ddd84bf87506d',
  REL_3e71aa435a55f46a55e2339e37 = 'REL_3e71aa435a55f46a55e2339e37',
  UQ_74595f63f3710a91748fdb022bb = 'UQ_74595f63f3710a91748fdb022bb',
}

/**
 * update columns of table "recruiter.calendly_events"
 */
export enum recruiter_calendly_events_update_column {
  cancelName = 'cancelName',
  cancelReason = 'cancelReason',
  canceled = 'canceled',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endTime = 'endTime',
  eventType = 'eventType',
  id = 'id',
  invitee = 'invitee',
  location = 'location',
  locationType = 'locationType',
  name = 'name',
  rescheduled = 'rescheduled',
  sid = 'sid',
  startTime = 'startTime',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  zoomMeeting = 'zoomMeeting',
}

/**
 * unique or primary key constraints on table "recruiter.calendly_webhook_subcriptions"
 */
export enum recruiter_calendly_webhook_subcriptions_constraint {
  PK_7e2f958e2cabac5ecf6258082ec = 'PK_7e2f958e2cabac5ecf6258082ec',
  REL_a4751fd34c755d06ac2ed11361 = 'REL_a4751fd34c755d06ac2ed11361',
  UQ_f4592468b1cedd6cbf2da541983 = 'UQ_f4592468b1cedd6cbf2da541983',
}

/**
 * update columns of table "recruiter.calendly_webhook_subcriptions"
 */
export enum recruiter_calendly_webhook_subcriptions_update_column {
  active = 'active',
  callbackUrl = 'callbackUrl',
  createdAt = 'createdAt',
  creator = 'creator',
  deletedAt = 'deletedAt',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  user = 'user',
}

/**
 * unique or primary key constraints on table "repository_owners"
 */
export enum repository_owners_constraint {
  PK_706421460f15e524f2ed2aeb170 = 'PK_706421460f15e524f2ed2aeb170',
}

/**
 * update columns of table "repository_owners"
 */
export enum repository_owners_update_column {
  endAt = 'endAt',
  gitSliceRepoId = 'gitSliceRepoId',
  id = 'id',
  isShadow = 'isShadow',
  repoOwnerUserId = 'repoOwnerUserId',
  startAt = 'startAt',
}

/**
 * unique or primary key constraints on table "review_question_themes"
 */
export enum review_question_themes_constraint {
  PK_eb0fa9617a0db348c541f90b45c = 'PK_eb0fa9617a0db348c541f90b45c',
}

/**
 * update columns of table "review_question_themes"
 */
export enum review_question_themes_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "review_questions"
 */
export enum review_questions_constraint {
  PK_7a2dcc1b51d65a3f3c5df4d02e7 = 'PK_7a2dcc1b51d65a3f3c5df4d02e7',
}

/**
 * update columns of table "review_questions"
 */
export enum review_questions_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isAdhoc = 'isAdhoc',
  parentQuestionId = 'parentQuestionId',
  question = 'question',
  responseType = 'responseType',
  theme = 'theme',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "review_response_types"
 */
export enum review_response_types_constraint {
  PK_64d45dcdd0118494022af85e5a5 = 'PK_64d45dcdd0118494022af85e5a5',
}

/**
 * update columns of table "review_response_types"
 */
export enum review_response_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "review_responses"
 */
export enum review_responses_constraint {
  PK_22e536591de245e6d51965ea5a0 = 'PK_22e536591de245e6d51965ea5a0',
  UQ_REVIEW_QUESTION_RESPONSES = 'UQ_REVIEW_QUESTION_RESPONSES',
}

/**
 * update columns of table "review_responses"
 */
export enum review_responses_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  questionId = 'questionId',
  ratingResponse = 'ratingResponse',
  reviewId = 'reviewId',
  textResponse = 'textResponse',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "review_type_questions"
 */
export enum review_type_questions_constraint {
  PK_c3aaac0f1e0668fe82767ba8107 = 'PK_c3aaac0f1e0668fe82767ba8107',
}

/**
 * update columns of table "review_type_questions"
 */
export enum review_type_questions_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isOptional = 'isOptional',
  questionId = 'questionId',
  reviewType = 'reviewType',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "review_types"
 */
export enum review_types_constraint {
  PK_fd3025cbb1893bcdc9f309de516 = 'PK_fd3025cbb1893bcdc9f309de516',
}

/**
 * update columns of table "review_types"
 */
export enum review_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "reviews"
 */
export enum reviews_constraint {
  PK_231ae565c273ee700b283f15c1d = 'PK_231ae565c273ee700b283f15c1d',
}

/**
 * update columns of table "reviews"
 */
export enum reviews_update_column {
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  completedAt = 'completedAt',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  reviewTypeId = 'reviewTypeId',
  revieweeLogin = 'revieweeLogin',
  reviewerLogin = 'reviewerLogin',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  urgency = 'urgency',
}

/**
 * unique or primary key constraints on table "rippling_companies"
 */
export enum rippling_companies_constraint {
  PK_fb2de1a997f4fb8f2693bcaf4aa = 'PK_fb2de1a997f4fb8f2693bcaf4aa',
  UQ_0b9780a1c74b6667c3bf71c9643 = 'UQ_0b9780a1c74b6667c3bf71c9643',
  UQ_6bdd91e2c2dfc60fee0be276867 = 'UQ_6bdd91e2c2dfc60fee0be276867',
  UQ_a724852d37e63f6844a6628d77a = 'UQ_a724852d37e63f6844a6628d77a',
  UQ_e18b4cac119a7eec15e31a8ed6d = 'UQ_e18b4cac119a7eec15e31a8ed6d',
}

/**
 * update columns of table "rippling_companies"
 */
export enum rippling_companies_update_column {
  accessToken = 'accessToken',
  address = 'address',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  ein = 'ein',
  id = 'id',
  name = 'name',
  payroll_settings = 'payroll_settings',
  phone = 'phone',
  primaryEmail = 'primaryEmail',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  tax_info = 'tax_info',
  updatedAt = 'updatedAt',
  workLocations = 'workLocations',
}

/**
 * unique or primary key constraints on table "rippling_company_tokens"
 */
export enum rippling_company_tokens_constraint {
  PK_c411be49410a93e600dc33398f3 = 'PK_c411be49410a93e600dc33398f3',
  UQ_030bd494d4312b43e50598b0e1d = 'UQ_030bd494d4312b43e50598b0e1d',
}

/**
 * update columns of table "rippling_company_tokens"
 */
export enum rippling_company_tokens_update_column {
  accessToken = 'accessToken',
  companySid = 'companySid',
}

/**
 * unique or primary key constraints on table "rippling_employees"
 */
export enum rippling_employees_constraint {
  PK_b203d5eaf8a11befc22ba521314 = 'PK_b203d5eaf8a11befc22ba521314',
  UQ_40b1ceab283c4416832a0cd8d97 = 'UQ_40b1ceab283c4416832a0cd8d97',
  UQ_c0d7e27367533b1d4fed64e2d13 = 'UQ_c0d7e27367533b1d4fed64e2d13',
}

/**
 * update columns of table "rippling_employees"
 */
export enum rippling_employees_update_column {
  address = 'address',
  companySid = 'companySid',
  compensationAnnualSalary = 'compensationAnnualSalary',
  createdAt = 'createdAt',
  customFields = 'customFields',
  deletedAt = 'deletedAt',
  department = 'department',
  dob = 'dob',
  employmentType = 'employmentType',
  endDate = 'endDate',
  filingStatus = 'filingStatus',
  firstName = 'firstName',
  flsaStatus = 'flsaStatus',
  id = 'id',
  lastName = 'lastName',
  managerSid = 'managerSid',
  name = 'name',
  personalEmail = 'personalEmail',
  phone = 'phone',
  roleState = 'roleState',
  sid = 'sid',
  ssn = 'ssn',
  startDate = 'startDate',
  syncEntityId = 'syncEntityId',
  title = 'title',
  updatedAt = 'updatedAt',
  workEmail = 'workEmail',
  workLocation = 'workLocation',
}

/**
 * unique or primary key constraints on table "rippling_payroll_earning_types"
 */
export enum rippling_payroll_earning_types_constraint {
  PK_3f3f34fcb283179b28b962a26a6 = 'PK_3f3f34fcb283179b28b962a26a6',
  UQ_49e90476443b36a9ea0d876fd91 = 'UQ_49e90476443b36a9ea0d876fd91',
  UQ_ef90162e117f4cf62f21d83d222 = 'UQ_ef90162e117f4cf62f21d83d222',
}

/**
 * update columns of table "rippling_payroll_earning_types"
 */
export enum rippling_payroll_earning_types_update_column {
  companySid = 'companySid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  earningType = 'earningType',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "rippling_payroll_run_details"
 */
export enum rippling_payroll_run_details_constraint {
  PK_43839cfd90958cddadf58a6de92 = 'PK_43839cfd90958cddadf58a6de92',
  UQ_db639e0d070f6e456827dfea64a = 'UQ_db639e0d070f6e456827dfea64a',
  UQ_e8a24cfab99dddcebafd015848f = 'UQ_e8a24cfab99dddcebafd015848f',
}

/**
 * update columns of table "rippling_payroll_run_details"
 */
export enum rippling_payroll_run_details_update_column {
  companySid = 'companySid',
  createdAt = 'createdAt',
  deductions = 'deductions',
  deletedAt = 'deletedAt',
  employeeSid = 'employeeSid',
  garnishments = 'garnishments',
  grossPay = 'grossPay',
  id = 'id',
  netPay = 'netPay',
  runSid = 'runSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  taxes = 'taxes',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "rippling_payroll_run_earnings"
 */
export enum rippling_payroll_run_earnings_constraint {
  PK_b20f02d7704b262dae0aab4661c = 'PK_b20f02d7704b262dae0aab4661c',
  UQ_011142858f8455b8203b952f457 = 'UQ_011142858f8455b8203b952f457',
  UQ_3811501a43fd1f98b5787d1ab91 = 'UQ_3811501a43fd1f98b5787d1ab91',
}

/**
 * update columns of table "rippling_payroll_run_earnings"
 */
export enum rippling_payroll_run_earnings_update_column {
  amount = 'amount',
  companySid = 'companySid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  employeeSid = 'employeeSid',
  hours = 'hours',
  id = 'id',
  name = 'name',
  payrollRunDetailsSid = 'payrollRunDetailsSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "rippling_payroll_run_states"
 */
export enum rippling_payroll_run_states_constraint {
  PK_4b0d065e9e655b8025f0c95af31 = 'PK_4b0d065e9e655b8025f0c95af31',
}

export enum rippling_payroll_run_states_enum {
  APPROVED = 'APPROVED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PAID = 'PAID',
}

/**
 * update columns of table "rippling_payroll_run_states"
 */
export enum rippling_payroll_run_states_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "rippling_payroll_runs"
 */
export enum rippling_payroll_runs_constraint {
  PK_5a8b44f4d1f5d6bff8ee8810cde = 'PK_5a8b44f4d1f5d6bff8ee8810cde',
  UQ_2120bd635afa7b97a8363cda89f = 'UQ_2120bd635afa7b97a8363cda89f',
  UQ_c0dd51b9583a4f9dc00d1fca82e = 'UQ_c0dd51b9583a4f9dc00d1fca82e',
}

/**
 * update columns of table "rippling_payroll_runs"
 */
export enum rippling_payroll_runs_update_column {
  approvalDeadline = 'approvalDeadline',
  checkDate = 'checkDate',
  companySid = 'companySid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  payPeriodEndDate = 'payPeriodEndDate',
  payPeriodStartDate = 'payPeriodStartDate',
  runStateId = 'runStateId',
  runType = 'runType',
  scheduleSid = 'scheduleSid',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "rippling_payroll_schedules"
 */
export enum rippling_payroll_schedules_constraint {
  PK_a275460d91a9d9994e4728fa86e = 'PK_a275460d91a9d9994e4728fa86e',
  UQ_9ecbee43b20548c7f82e58ce734 = 'UQ_9ecbee43b20548c7f82e58ce734',
  UQ_b670f37f1e878c6b09eedae68c0 = 'UQ_b670f37f1e878c6b09eedae68c0',
}

/**
 * update columns of table "rippling_payroll_schedules"
 */
export enum rippling_payroll_schedules_update_column {
  companySid = 'companySid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  payFrequency = 'payFrequency',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "skill_relationships"
 */
export enum skill_relationships_constraint {
  PK_d27120cf58e1f8ce29116226ee6 = 'PK_d27120cf58e1f8ce29116226ee6',
}

/**
 * update columns of table "skill_relationships"
 */
export enum skill_relationships_update_column {
  childSkill = 'childSkill',
  parentSkill = 'parentSkill',
}

/**
 * unique or primary key constraints on table "skills"
 */
export enum skills_constraint {
  PK_0d3212120f4ecedf90864d7e298 = 'PK_0d3212120f4ecedf90864d7e298',
  UQ_9dd064124ede2c8a0577fede9aa = 'UQ_9dd064124ede2c8a0577fede9aa',
}

/**
 * update columns of table "skills"
 */
export enum skills_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  technologyId = 'technologyId',
  type = 'type',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_app_channels"
 */
export enum slack_app_channels_constraint {
  PK_7326f0c0856fd7271ee8916054a = 'PK_7326f0c0856fd7271ee8916054a',
  REL_3cb582a8ef294a05d0e008ff93 = 'REL_3cb582a8ef294a05d0e008ff93',
  UQ_14060d2b7c991318dd7bb99aed2 = 'UQ_14060d2b7c991318dd7bb99aed2',
}

/**
 * update columns of table "slack_app_channels"
 */
export enum slack_app_channels_update_column {
  appSid = 'appSid',
  channelSid = 'channelSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_app_tokens"
 */
export enum slack_app_tokens_constraint {
  PK_8ca28b6991fe6dba5397702a27c = 'PK_8ca28b6991fe6dba5397702a27c',
}

/**
 * update columns of table "slack_app_tokens"
 */
export enum slack_app_tokens_update_column {
  appSid = 'appSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  expiresAt = 'expiresAt',
  id = 'id',
  teamSid = 'teamSid',
  token = 'token',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_apps"
 */
export enum slack_apps_constraint {
  PK_b9758f6da31b1d8f903b2b98807 = 'PK_b9758f6da31b1d8f903b2b98807',
  REL_f0ebae8c72e446b998737bd7b2 = 'REL_f0ebae8c72e446b998737bd7b2',
  UQ_61f3059d2c2c805c445b06ba773 = 'UQ_61f3059d2c2c805c445b06ba773',
  UQ_7924e217ad08f579b59df5d3b09 = 'UQ_7924e217ad08f579b59df5d3b09',
}

/**
 * update columns of table "slack_apps"
 */
export enum slack_apps_update_column {
  appId = 'appId',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  createdAt = 'createdAt',
  dateOfCreation = 'dateOfCreation',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  sid = 'sid',
  signingSecret = 'signingSecret',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  verificationToken = 'verificationToken',
}

/**
 * unique or primary key constraints on table "slack_channels"
 */
export enum slack_channels_constraint {
  PK_8c5bb05c5944a43d8c07e181c22 = 'PK_8c5bb05c5944a43d8c07e181c22',
  REL_4b7f70c1f142f5b61f8ea7aaa1 = 'REL_4b7f70c1f142f5b61f8ea7aaa1',
  UQ_43bab7900367dc87860b3eeb46b = 'UQ_43bab7900367dc87860b3eeb46b',
  UQ_62f1e6b7b00c422fafd6d2e8f10 = 'UQ_62f1e6b7b00c422fafd6d2e8f10',
}

/**
 * update columns of table "slack_channels"
 */
export enum slack_channels_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  discussionSourceId = 'discussionSourceId',
  id = 'id',
  isChannel = 'isChannel',
  isGeneral = 'isGeneral',
  isOrgShared = 'isOrgShared',
  isPrivate = 'isPrivate',
  name = 'name',
  nameNormalized = 'nameNormalized',
  numMembers = 'numMembers',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_standup_channel_mappings"
 */
export enum slack_standup_channel_mappings_constraint {
  PK_c56927a9e61a5c2417569c1f5d6 = 'PK_c56927a9e61a5c2417569c1f5d6',
  UQ_4f6a27fa67d318ed98618923fa7 = 'UQ_4f6a27fa67d318ed98618923fa7',
}

/**
 * update columns of table "slack_standup_channel_mappings"
 */
export enum slack_standup_channel_mappings_update_column {
  channelSid = 'channelSid',
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  projectId = 'projectId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_teams"
 */
export enum slack_teams_constraint {
  PK_ea7403558b307385c57f981e518 = 'PK_ea7403558b307385c57f981e518',
  REL_5ada82202bb6238e9c1fdf55b4 = 'REL_5ada82202bb6238e9c1fdf55b4',
  UQ_1dbc76bfd0e0a470f43d4be7d0f = 'UQ_1dbc76bfd0e0a470f43d4be7d0f',
}

/**
 * update columns of table "slack_teams"
 */
export enum slack_teams_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  domain = 'domain',
  emailDomain = 'emailDomain',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_user_team_channel_mappings"
 */
export enum slack_user_team_channel_mappings_constraint {
  PK_9d372b5192bc93c9d9ed0e67b1f = 'PK_9d372b5192bc93c9d9ed0e67b1f',
}

/**
 * update columns of table "slack_user_team_channel_mappings"
 */
export enum slack_user_team_channel_mappings_update_column {
  channelSid = 'channelSid',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  teamId = 'teamId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "slack_users"
 */
export enum slack_users_constraint {
  PK_cfe3b34e423c84b54da05ff6ff4 = 'PK_cfe3b34e423c84b54da05ff6ff4',
  REL_74055fd3b0a886c157ba932a33 = 'REL_74055fd3b0a886c157ba932a33',
  UQ_41060f5e973f6287d5ea137c8ed = 'UQ_41060f5e973f6287d5ea137c8ed',
  UQ_5a0d9787d00a8deee90b6ea69f2 = 'UQ_5a0d9787d00a8deee90b6ea69f2',
}

/**
 * update columns of table "slack_users"
 */
export enum slack_users_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  displayName = 'displayName',
  displayNameNormalized = 'displayNameNormalized',
  email = 'email',
  firstName = 'firstName',
  id = 'id',
  isAdmin = 'isAdmin',
  isDeleted = 'isDeleted',
  isOwner = 'isOwner',
  lastName = 'lastName',
  name = 'name',
  nameNormalized = 'nameNormalized',
  phone = 'phone',
  realName = 'realName',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  teamSid = 'teamSid',
  timezone = 'timezone',
  timezoneNormalized = 'timezoneNormalized',
  timezoneOffset = 'timezoneOffset',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "syncable_entities"
 */
export enum syncable_entities_constraint {
  PK_5e981b02f91e5a55d606c84a9ae = 'PK_5e981b02f91e5a55d606c84a9ae',
  UQ_ENTITY_SID = 'UQ_ENTITY_SID',
}

/**
 * update columns of table "syncable_entities"
 */
export enum syncable_entities_update_column {
  createdAt = 'createdAt',
  createdAtSource = 'createdAtSource',
  deletedAt = 'deletedAt',
  etag = 'etag',
  expiresInType = 'expiresInType',
  id = 'id',
  isForked = 'isForked',
  isOutdated = 'isOutdated',
  redundantFields = 'redundantFields',
  shallow = 'shallow',
  shouldStartSyncAt = 'shouldStartSyncAt',
  sid = 'sid',
  sourceUrl = 'sourceUrl',
  syncStartedAt = 'syncStartedAt',
  syncTriggeredAt = 'syncTriggeredAt',
  syncedAt = 'syncedAt',
  tableName = 'tableName',
  updatedAt = 'updatedAt',
  updatedAtSource = 'updatedAtSource',
  weight = 'weight',
}

/**
 * unique or primary key constraints on table "syncable_entity_job_types"
 */
export enum syncable_entity_job_types_constraint {
  PK_bb2dd176c4ee98e1cd53a72937b = 'PK_bb2dd176c4ee98e1cd53a72937b',
}

export enum syncable_entity_job_types_enum {
  syncer = 'syncer',
  webhook = 'webhook',
}

/**
 * update columns of table "syncable_entity_job_types"
 */
export enum syncable_entity_job_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "syncable_entity_syncs"
 */
export enum syncable_entity_syncs_constraint {
  PK_c037ddf98649d414c5b052f5b5e = 'PK_c037ddf98649d414c5b052f5b5e',
  UQ_ACTIVE_SYNCABLE_ENTITY = 'UQ_ACTIVE_SYNCABLE_ENTITY',
}

/**
 * update columns of table "syncable_entity_syncs"
 */
export enum syncable_entity_syncs_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  errorMessage = 'errorMessage',
  expiresAt = 'expiresAt',
  finishedAt = 'finishedAt',
  id = 'id',
  source = 'source',
  startedAt = 'startedAt',
  status = 'status',
  syncEntityId = 'syncEntityId',
  syncEntityJobType = 'syncEntityJobType',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "syncable_entity_types"
 */
export enum syncable_entity_types_constraint {
  PK_314b2ee970f7589d95804112c63 = 'PK_314b2ee970f7589d95804112c63',
}

export enum syncable_entity_types_enum {
  clickup_folders = 'clickup_folders',
  clickup_lists = 'clickup_lists',
  clickup_spaces = 'clickup_spaces',
  clickup_task_comments = 'clickup_task_comments',
  clickup_tasks = 'clickup_tasks',
  gcp_storage_buckets = 'gcp_storage_buckets',
  git_repos = 'git_repos',
  github_accounts = 'github_accounts',
  github_app_installations = 'github_app_installations',
  github_branches = 'github_branches',
  github_comment_reactions = 'github_comment_reactions',
  github_commit_contexts = 'github_commit_contexts',
  github_commits = 'github_commits',
  github_issue_comments = 'github_issue_comments',
  github_issues = 'github_issues',
  github_organizations = 'github_organizations',
  github_owners = 'github_owners',
  github_pull_request_comments = 'github_pull_request_comments',
  github_pull_request_review_comments = 'github_pull_request_review_comments',
  github_pull_request_review_threads = 'github_pull_request_review_threads',
  github_pull_request_reviews = 'github_pull_request_reviews',
  github_pull_requests = 'github_pull_requests',
  github_repos = 'github_repos',
  gitlab_issue_comments = 'gitlab_issue_comments',
  gitlab_issues = 'gitlab_issues',
  gitlab_projects = 'gitlab_projects',
  gitlab_pull_request_comments = 'gitlab_pull_request_comments',
  gitlab_pull_requests = 'gitlab_pull_requests',
  gitlab_users = 'gitlab_users',
  google = 'google',
  jira_accounts = 'jira_accounts',
  jira_issue_comments = 'jira_issue_comments',
  jira_issues = 'jira_issues',
  jira_organizations = 'jira_organizations',
  linear_accounts = 'linear_accounts',
  linear_comments = 'linear_comments',
  linear_issues = 'linear_issues',
  linear_teams = 'linear_teams',
  linkedin = 'linkedin',
  rippling_companies = 'rippling_companies',
  rippling_employees = 'rippling_employees',
  rippling_payroll_earning_types = 'rippling_payroll_earning_types',
  rippling_payroll_run_details = 'rippling_payroll_run_details',
  rippling_payroll_run_earnings = 'rippling_payroll_run_earnings',
  rippling_payroll_runs = 'rippling_payroll_runs',
  rippling_payroll_schedules = 'rippling_payroll_schedules',
  slack_apps = 'slack_apps',
  slack_channels = 'slack_channels',
  zoom_meeting_instances = 'zoom_meeting_instances',
  zoom_meetings = 'zoom_meetings',
  zoom_recording_files = 'zoom_recording_files',
  zoom_recordings = 'zoom_recordings',
  zoom_users = 'zoom_users',
}

/**
 * update columns of table "syncable_entity_types"
 */
export enum syncable_entity_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "task_changes"
 */
export enum task_changes_constraint {
  PK_7b4bcf03a55fdfccc330d70f5da = 'PK_7b4bcf03a55fdfccc330d70f5da',
  UQ_a6485968b1d649b0c82d1aa0843 = 'UQ_a6485968b1d649b0c82d1aa0843',
}

/**
 * update columns of table "task_changes"
 */
export enum task_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  branchName = 'branchName',
  budget = 'budget',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientBlockersId = 'clientBlockersId',
  clientCommitDate = 'clientCommitDate',
  clientCommitMessage = 'clientCommitMessage',
  clientId = 'clientId',
  completedAt = 'completedAt',
  costInCredits = 'costInCredits',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  dependsOnTasksId = 'dependsOnTasksId',
  description = 'description',
  descriptionType = 'descriptionType',
  developerId = 'developerId',
  developerInvoiceId = 'developerInvoiceId',
  discussionSourceId = 'discussionSourceId',
  duplicateOfTaskId = 'duplicateOfTaskId',
  followedByTasksId = 'followedByTasksId',
  gitRepoId = 'gitRepoId',
  id = 'id',
  isBillable = 'isBillable',
  lowerBudget = 'lowerBudget',
  managerId = 'managerId',
  managerInvoiceId = 'managerInvoiceId',
  maxDuplicateLimit = 'maxDuplicateLimit',
  operation = 'operation',
  prLink = 'prLink',
  previousChangeId = 'previousChangeId',
  pullRequestsId = 'pullRequestsId',
  pull_request_id = 'pull_request_id',
  reasonForChange = 'reasonForChange',
  reviewerId = 'reviewerId',
  reviewersId = 'reviewersId',
  slicedPullRequestId = 'slicedPullRequestId',
  specLink = 'specLink',
  sqlQuery = 'sqlQuery',
  startedAt = 'startedAt',
  status = 'status',
  statusUpdateReason = 'statusUpdateReason',
  taskCode = 'taskCode',
  technologiesId = 'technologiesId',
  ticketCode = 'ticketCode',
  timebased = 'timebased',
  title = 'title',
  type = 'type',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "task_client_blockers"
 */
export enum task_client_blockers_constraint {
  PK_f314fcd6eddf7ff5e2d518a12ce = 'PK_f314fcd6eddf7ff5e2d518a12ce',
}

/**
 * update columns of table "task_client_blockers"
 */
export enum task_client_blockers_update_column {
  clientBlockersId = 'clientBlockersId',
  tasksId = 'tasksId',
}

/**
 * unique or primary key constraints on table "task_code_comments"
 */
export enum task_code_comments_constraint {
  PK_c24cd84f0bbecaa854bce025c4f = 'PK_c24cd84f0bbecaa854bce025c4f',
}

/**
 * update columns of table "task_code_comments"
 */
export enum task_code_comments_update_column {
  authorId = 'authorId',
  body = 'body',
  commitId = 'commitId',
  createdAt = 'createdAt',
  diffHunk = 'diffHunk',
  externalCommentUrl = 'externalCommentUrl',
  id = 'id',
  internalCommentUrl = 'internalCommentUrl',
  lastSync = 'lastSync',
  path = 'path',
  shouldPublish = 'shouldPublish',
  shouldSync = 'shouldSync',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "task_credit_approval_changes"
 */
export enum task_credit_approval_changes_constraint {
  PK_a38b32e18ad252bc5288f59a41a = 'PK_a38b32e18ad252bc5288f59a41a',
}

/**
 * update columns of table "task_credit_approval_changes"
 */
export enum task_credit_approval_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reason = 'reason',
  reasonForChange = 'reasonForChange',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  sqlQuery = 'sqlQuery',
  status = 'status',
  taskId = 'taskId',
  ticketCreditApprovalId = 'ticketCreditApprovalId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "task_credit_approval"
 */
export enum task_credit_approval_constraint {
  PK_0195b11109fc1e7ce530608fed7 = 'PK_0195b11109fc1e7ce530608fed7',
}

/**
 * update columns of table "task_credit_approval"
 */
export enum task_credit_approval_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  reason = 'reason',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  status = 'status',
  taskId = 'taskId',
  ticketCreditApprovalId = 'ticketCreditApprovalId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "task_credit_overrides"
 */
export enum task_credit_overrides_constraint {
  PK_bf4651f8a1e5ff92eb86a487321 = 'PK_bf4651f8a1e5ff92eb86a487321',
}

/**
 * update columns of table "task_credit_overrides"
 */
export enum task_credit_overrides_update_column {
  appliedByUserId = 'appliedByUserId',
  costInCredits = 'costInCredits',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  taskId = 'taskId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "task_credit_status"
 */
export enum task_credit_status_constraint {
  PK_471b39998460af6b4514c188cc8 = 'PK_471b39998460af6b4514c188cc8',
}

export enum task_credit_status_enum {
  approved = 'approved',
  auto_approved = 'auto_approved',
  pending = 'pending',
  rejected = 'rejected',
}

/**
 * update columns of table "task_credit_status"
 */
export enum task_credit_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "task_daily_standups"
 */
export enum task_daily_standups_constraint {
  PK_62edf80b503d3d011e049824561 = 'PK_62edf80b503d3d011e049824561',
}

/**
 * update columns of table "task_daily_standups"
 */
export enum task_daily_standups_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  errorMessage = 'errorMessage',
  erroredAt = 'erroredAt',
  id = 'id',
  postedToSlack = 'postedToSlack',
  postedToWorkplace = 'postedToWorkplace',
  submittedAt = 'submittedAt',
  summarizedInTicketDailyStandupId = 'summarizedInTicketDailyStandupId',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  summary = 'summary',
  taskId = 'taskId',
  updatedAt = 'updatedAt',
  updatedETA = 'updatedETA',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "task_dependencies"
 */
export enum task_dependencies_constraint {
  PK_e31de0e173af595a21c4ec8e48b = 'PK_e31de0e173af595a21c4ec8e48b',
}

/**
 * update columns of table "task_dependencies"
 */
export enum task_dependencies_update_column {
  childTaskId = 'childTaskId',
  deletedAt = 'deletedAt',
  id = 'id',
  parentTaskId = 'parentTaskId',
}

/**
 * unique or primary key constraints on table "task_followers"
 */
export enum task_followers_constraint {
  PK_e147d6664e35da3cbf11300047d = 'PK_e147d6664e35da3cbf11300047d',
}

/**
 * update columns of table "task_followers"
 */
export enum task_followers_update_column {
  tasksId_1 = 'tasksId_1',
  tasksId_2 = 'tasksId_2',
}

/**
 * unique or primary key constraints on table "task_reviewers"
 */
export enum task_reviewers_constraint {
  PK_907b0f58778afe6e17250ae416e = 'PK_907b0f58778afe6e17250ae416e',
}

/**
 * update columns of table "task_reviewers"
 */
export enum task_reviewers_update_column {
  developersId = 'developersId',
  tasksId = 'tasksId',
}

/**
 * unique or primary key constraints on table "task_reviews"
 */
export enum task_reviews_constraint {
  PK_0e1b45486945f89aee4136b318f = 'PK_0e1b45486945f89aee4136b318f',
  UQ_1fde3a28184181b8f54d7e88b6f = 'UQ_1fde3a28184181b8f54d7e88b6f',
}

/**
 * unique or primary key constraints on table "task_reviews_status"
 */
export enum task_reviews_status_constraint {
  PK_d829c3835a997717dc8bf8bcf57 = 'PK_d829c3835a997717dc8bf8bcf57',
}

export enum task_reviews_status_enum {
  assigned = 'assigned',
  available = 'available',
  cancelled = 'cancelled',
  failed = 'failed',
  in_progress = 'in_progress',
  passed = 'passed',
}

/**
 * update columns of table "task_reviews_status"
 */
export enum task_reviews_status_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "task_reviews"
 */
export enum task_reviews_update_column {
  approvesTaskId = 'approvesTaskId',
  completedAt = 'completedAt',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  developerId = 'developerId',
  id = 'id',
  managerId = 'managerId',
  managerInvoiceId = 'managerInvoiceId',
  pullRequestReviewId = 'pullRequestReviewId',
  reviewerInvoiceId = 'reviewerInvoiceId',
  startedAt = 'startedAt',
  status = 'status',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "task_status_changes"
 */
export enum task_status_changes_constraint {
  PK_a7aa964dc16c0bf225984f06bdd = 'PK_a7aa964dc16c0bf225984f06bdd',
}

/**
 * update columns of table "task_status_changes"
 */
export enum task_status_changes_update_column {
  automaticallyTransioned = 'automaticallyTransioned',
  createdAt = 'createdAt',
  id = 'id',
  reasonForTransition = 'reasonForTransition',
  status = 'status',
  taskId = 'taskId',
  transitionedAt = 'transitionedAt',
  transitionedByUserId = 'transitionedByUserId',
}

/**
 * unique or primary key constraints on table "task_submissions"
 */
export enum task_submissions_constraint {
  PK_f6a1c74cc8b9347e916d516f2c0 = 'PK_f6a1c74cc8b9347e916d516f2c0',
}

/**
 * update columns of table "task_submissions"
 */
export enum task_submissions_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gcpObjectId = 'gcpObjectId',
  id = 'id',
  processed = 'processed',
  syncError = 'syncError',
  taskId = 'taskId',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "task_technologies"
 */
export enum task_technologies_constraint {
  PK_d093d12bdef63226744f923d35c = 'PK_d093d12bdef63226744f923d35c',
}

/**
 * update columns of table "task_technologies"
 */
export enum task_technologies_update_column {
  tasksId = 'tasksId',
  technologiesId = 'technologiesId',
}

/**
 * unique or primary key constraints on table "task_time_logs"
 */
export enum task_time_logs_constraint {
  PK_785b74bf0fb2dc79de82f73e7b6 = 'PK_785b74bf0fb2dc79de82f73e7b6',
  UQ_27f303bea36720f3e4ebd4dd71c = 'UQ_27f303bea36720f3e4ebd4dd71c',
  UQ_86d84d06e0fffcf20ddc7d807e8 = 'UQ_86d84d06e0fffcf20ddc7d807e8',
}

/**
 * update columns of table "task_time_logs"
 */
export enum task_time_logs_update_column {
  CreatedDateColumn = 'CreatedDateColumn',
  UpdateDateColumn = 'UpdateDateColumn',
  developerId = 'developerId',
  finishedAt = 'finishedAt',
  id = 'id',
  startedAt = 'startedAt',
  taskId = 'taskId',
  timeSpent = 'timeSpent',
  timedoctorTaskId = 'timedoctorTaskId',
}

/**
 * unique or primary key constraints on table "task_work"
 */
export enum task_work_constraint {
  PK_e91316aba5c0fa394f53cd94bf4 = 'PK_e91316aba5c0fa394f53cd94bf4',
}

/**
 * unique or primary key constraints on table "task_work_status"
 */
export enum task_work_status_constraint {
  PK_e5fc9c89aa415ff45d4d6194e2d = 'PK_e5fc9c89aa415ff45d4d6194e2d',
}

export enum task_work_status_enum {
  assigned = 'assigned',
  available = 'available',
  finished = 'finished',
  in_progress = 'in_progress',
}

/**
 * update columns of table "task_work_status"
 */
export enum task_work_status_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "task_work"
 */
export enum task_work_update_column {
  completedAt = 'completedAt',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  developerId = 'developerId',
  id = 'id',
  managerId = 'managerId',
  managerInvoiceId = 'managerInvoiceId',
  resourceURL = 'resourceURL',
  startedAt = 'startedAt',
  status = 'status',
  updatedAt = 'updatedAt',
  workerInvoiceId = 'workerInvoiceId',
}

/**
 * unique or primary key constraints on table "tasks"
 */
export enum tasks_constraint {
  PK_8d12ff38fcc62aaba2cab748772 = 'PK_8d12ff38fcc62aaba2cab748772',
  UQ_050d1f08bba5bc4c4dbafee4417 = 'UQ_050d1f08bba5bc4c4dbafee4417',
  UQ_TASK_CODE_PER_TICKET = 'UQ_TASK_CODE_PER_TICKET',
}

/**
 * unique or primary key constraints on table "tasks_status"
 */
export enum tasks_status_constraint {
  PK_f0ef6c949d1b7400522d71aa5dd = 'PK_f0ef6c949d1b7400522d71aa5dd',
}

export enum tasks_status_enum {
  available = 'available',
  backlog = 'backlog',
  cancelled = 'cancelled',
  client_review = 'client_review',
  errored = 'errored',
  finished = 'finished',
  in_progress = 'in_progress',
  internal_review = 'internal_review',
  needs_changes = 'needs_changes',
  parked = 'parked',
}

/**
 * update columns of table "tasks_status"
 */
export enum tasks_status_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "tasks_type"
 */
export enum tasks_type_constraint {
  PK_029f732cbcf396b164467f4a0fb = 'PK_029f732cbcf396b164467f4a0fb',
}

export enum tasks_type_enum {
  code = 'code',
  learning = 'learning',
  qa = 'qa',
  review = 'review',
  spec = 'spec',
}

/**
 * update columns of table "tasks_type"
 */
export enum tasks_type_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "tasks"
 */
export enum tasks_update_column {
  branchName = 'branchName',
  budget = 'budget',
  clientCommitDate = 'clientCommitDate',
  clientCommitMessage = 'clientCommitMessage',
  clientId = 'clientId',
  completedAt = 'completedAt',
  costInCredits = 'costInCredits',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  description = 'description',
  descriptionType = 'descriptionType',
  developerId = 'developerId',
  developerInvoiceId = 'developerInvoiceId',
  discussionSourceId = 'discussionSourceId',
  duplicateOfTaskId = 'duplicateOfTaskId',
  gitRepoId = 'gitRepoId',
  id = 'id',
  isBillable = 'isBillable',
  lowerBudget = 'lowerBudget',
  managerId = 'managerId',
  managerInvoiceId = 'managerInvoiceId',
  maxDuplicateLimit = 'maxDuplicateLimit',
  prLink = 'prLink',
  pull_request_id = 'pull_request_id',
  reviewerId = 'reviewerId',
  slicedPullRequestId = 'slicedPullRequestId',
  specLink = 'specLink',
  startedAt = 'startedAt',
  status = 'status',
  statusUpdateReason = 'statusUpdateReason',
  taskCode = 'taskCode',
  ticketCode = 'ticketCode',
  timebased = 'timebased',
  title = 'title',
  type = 'type',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "technologies"
 */
export enum technologies_constraint {
  PK_9a97465b79568f00becacdd4e4a = 'PK_9a97465b79568f00becacdd4e4a',
  UQ_46800813f460eb131823371caee = 'UQ_46800813f460eb131823371caee',
}

/**
 * update columns of table "technologies"
 */
export enum technologies_update_column {
  id = 'id',
  name = 'name',
}

/**
 * unique or primary key constraints on table "ticket_attachments"
 */
export enum ticket_attachments_constraint {
  PK_87a3bf2fd454316a4382b84e80d = 'PK_87a3bf2fd454316a4382b84e80d',
}

/**
 * update columns of table "ticket_attachments"
 */
export enum ticket_attachments_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  gcpObjectId = 'gcpObjectId',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_billable_overrides"
 */
export enum ticket_billable_overrides_constraint {
  PK_586f53f60fa09d4ad30d7057d72 = 'PK_586f53f60fa09d4ad30d7057d72',
}

/**
 * update columns of table "ticket_billable_overrides"
 */
export enum ticket_billable_overrides_update_column {
  billableAt = 'billableAt',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  reason = 'reason',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_changes"
 */
export enum ticket_changes_constraint {
  PK_4deb756039cec065a8e2c8f7eed = 'PK_4deb756039cec065a8e2c8f7eed',
}

/**
 * update columns of table "ticket_changes"
 */
export enum ticket_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  cancelledAt = 'cancelledAt',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientBlockersId = 'clientBlockersId',
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  code = 'code',
  completedAt = 'completedAt',
  costInCredits = 'costInCredits',
  createdAt = 'createdAt',
  description = 'description',
  descriptionType = 'descriptionType',
  discount = 'discount',
  discussionSourceId = 'discussionSourceId',
  gitRepoId = 'gitRepoId',
  githubIssueSid = 'githubIssueSid',
  gitlabIssueSid = 'gitlabIssueSid',
  id = 'id',
  invoiceId = 'invoiceId',
  isInternal = 'isInternal',
  isSynced = 'isSynced',
  jiraIssueSid = 'jiraIssueSid',
  linearIssueSid = 'linearIssueSid',
  lowerBudget = 'lowerBudget',
  managerId = 'managerId',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  priority = 'priority',
  reasonForChange = 'reasonForChange',
  reposId = 'reposId',
  sqlQuery = 'sqlQuery',
  startedAt = 'startedAt',
  status = 'status',
  statusUpdateReason = 'statusUpdateReason',
  statusUpdatedAt = 'statusUpdatedAt',
  status_v2 = 'status_v2',
  syncedAt = 'syncedAt',
  ticketLink = 'ticketLink',
  ticketSourceId = 'ticketSourceId',
  title = 'title',
  type = 'type',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "ticket_client_blockers"
 */
export enum ticket_client_blockers_constraint {
  PK_f589a1ee438c15a9e5e5cf01e8d = 'PK_f589a1ee438c15a9e5e5cf01e8d',
}

/**
 * update columns of table "ticket_client_blockers"
 */
export enum ticket_client_blockers_update_column {
  clientBlockersId = 'clientBlockersId',
  ticketsId = 'ticketsId',
}

/**
 * unique or primary key constraints on table "ticket_credit_approval_changes"
 */
export enum ticket_credit_approval_changes_constraint {
  PK_ed68133ebcf3bd5ae551701bc23 = 'PK_ed68133ebcf3bd5ae551701bc23',
}

/**
 * update columns of table "ticket_credit_approval_changes"
 */
export enum ticket_credit_approval_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reason = 'reason',
  reasonForChange = 'reasonForChange',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  sqlQuery = 'sqlQuery',
  status = 'status',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "ticket_credit_approval_details"
 */
export enum ticket_credit_approval_details_constraint {
  PK_300b9091868d2b019d149713024 = 'PK_300b9091868d2b019d149713024',
}

/**
 * update columns of table "ticket_credit_approval_details"
 */
export enum ticket_credit_approval_details_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  taskCreditApprovalId = 'taskCreditApprovalId',
  ticketCreditApprovalId = 'ticketCreditApprovalId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_credit_approvals"
 */
export enum ticket_credit_approvals_constraint {
  PK_13cfee4da26174bfd556e347f75 = 'PK_13cfee4da26174bfd556e347f75',
}

/**
 * update columns of table "ticket_credit_approvals"
 */
export enum ticket_credit_approvals_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  reason = 'reason',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  status = 'status',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

/**
 * unique or primary key constraints on table "ticket_credit_status"
 */
export enum ticket_credit_status_constraint {
  PK_e6dc90d3106ea988620fce0eea9 = 'PK_e6dc90d3106ea988620fce0eea9',
}

export enum ticket_credit_status_enum {
  approved = 'approved',
  auto_approved = 'auto_approved',
  pending = 'pending',
  rejected = 'rejected',
}

/**
 * update columns of table "ticket_credit_status"
 */
export enum ticket_credit_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "ticket_daily_standups"
 */
export enum ticket_daily_standups_constraint {
  PK_e2c56eb9a2625999ace654b54f9 = 'PK_e2c56eb9a2625999ace654b54f9',
}

/**
 * update columns of table "ticket_daily_standups"
 */
export enum ticket_daily_standups_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  message = 'message',
  submittedAt = 'submittedAt',
  summarizedInClientDailyStandupId = 'summarizedInClientDailyStandupId',
  summarizedInProjectDailyStandupId = 'summarizedInProjectDailyStandupId',
  summarizedInUserDailyStandupId = 'summarizedInUserDailyStandupId',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  updatedETA = 'updatedETA',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "ticket_intentions"
 */
export enum ticket_intentions_constraint {
  PK_231834652fae478f64d676bb894 = 'PK_231834652fae478f64d676bb894',
}

/**
 * unique or primary key constraints on table "ticket_intentions_type"
 */
export enum ticket_intentions_type_constraint {
  PK_4559be90a878313e7d73a1200a0 = 'PK_4559be90a878313e7d73a1200a0',
}

/**
 * update columns of table "ticket_intentions_type"
 */
export enum ticket_intentions_type_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "ticket_intentions"
 */
export enum ticket_intentions_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  ticketId = 'ticketId',
  ticketIntentionType = 'ticketIntentionType',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_means"
 */
export enum ticket_means_constraint {
  PK_06fb7f7d8d6a3c41695d219ada6 = 'PK_06fb7f7d8d6a3c41695d219ada6',
}

/**
 * unique or primary key constraints on table "ticket_means_type"
 */
export enum ticket_means_type_constraint {
  PK_cad0bfe60b9f9bc9c0419b9dd77 = 'PK_cad0bfe60b9f9bc9c0419b9dd77',
}

/**
 * update columns of table "ticket_means_type"
 */
export enum ticket_means_type_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "ticket_means"
 */
export enum ticket_means_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  ticketId = 'ticketId',
  ticketMeansType = 'ticketMeansType',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_refund"
 */
export enum ticket_refund_constraint {
  PK_dabd9b7f405802a6a9b97ed511e = 'PK_dabd9b7f405802a6a9b97ed511e',
}

/**
 * update columns of table "ticket_refund"
 */
export enum ticket_refund_update_column {
  costInCredits = 'costInCredits',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  issuedByUserId = 'issuedByUserId',
  reason = 'reason',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_repos"
 */
export enum ticket_repos_constraint {
  PK_821d578187e239e2775e9048bd2 = 'PK_821d578187e239e2775e9048bd2',
}

/**
 * update columns of table "ticket_repos"
 */
export enum ticket_repos_update_column {
  repoId = 'repoId',
  ticketId = 'ticketId',
}

/**
 * unique or primary key constraints on table "ticket_scope_change_request_states"
 */
export enum ticket_scope_change_request_states_constraint {
  PK_4be05f6efbf6ceb51f67edcdccb = 'PK_4be05f6efbf6ceb51f67edcdccb',
}

/**
 * update columns of table "ticket_scope_change_request_states"
 */
export enum ticket_scope_change_request_states_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "ticket_scope_change_requests"
 */
export enum ticket_scope_change_requests_constraint {
  PK_eb8562c73599d91d7784f4f691d = 'PK_eb8562c73599d91d7784f4f691d',
}

/**
 * update columns of table "ticket_scope_change_requests"
 */
export enum ticket_scope_change_requests_update_column {
  appliedAt = 'appliedAt',
  createdAt = 'createdAt',
  createdByUserId = 'createdByUserId',
  deletedAt = 'deletedAt',
  description = 'description',
  id = 'id',
  state = 'state',
  ticketId = 'ticketId',
  title = 'title',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_source_changes"
 */
export enum ticket_source_changes_constraint {
  PK_be9d31755e082126cf1dfbba0c8 = 'PK_be9d31755e082126cf1dfbba0c8',
}

/**
 * update columns of table "ticket_source_changes"
 */
export enum ticket_source_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientId = 'clientId',
  createdAt = 'createdAt',
  creditsToStoryPoints = 'creditsToStoryPoints',
  deletedAt = 'deletedAt',
  gitRepoId = 'gitRepoId',
  githubRepoLabelSid = 'githubRepoLabelSid',
  gitlabAssigneeSid = 'gitlabAssigneeSid',
  gitlabProjectSid = 'gitlabProjectSid',
  id = 'id',
  jiraAssigneeSid = 'jiraAssigneeSid',
  jiraDescriptionFieldId = 'jiraDescriptionFieldId',
  jiraProjectSid = 'jiraProjectSid',
  jiraStoryPointKey = 'jiraStoryPointKey',
  linearTeamSid = 'linearTeamSid',
  operation = 'operation',
  prefix = 'prefix',
  previousChangeId = 'previousChangeId',
  projectName = 'projectName',
  reasonForChange = 'reasonForChange',
  repoId = 'repoId',
  sqlQuery = 'sqlQuery',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_source_pull_strategies"
 */
export enum ticket_source_pull_strategies_constraint {
  PK_7c126b935e81893968029c141df = 'PK_7c126b935e81893968029c141df',
}

/**
 * update columns of table "ticket_source_pull_strategies"
 */
export enum ticket_source_pull_strategies_update_column {
  assignedById = 'assignedById',
  assignee = 'assignee',
  commentById = 'commentById',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  labels = 'labels',
  priority = 'priority',
  ticketSourceId = 'ticketSourceId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_source_pull_strategy_types"
 */
export enum ticket_source_pull_strategy_types_constraint {
  PK_65d234814c7440ce8fb0b9f52ab = 'PK_65d234814c7440ce8fb0b9f52ab',
}

/**
 * update columns of table "ticket_source_pull_strategy_types"
 */
export enum ticket_source_pull_strategy_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "ticket_source_push_strategies"
 */
export enum ticket_source_push_strategies_constraint {
  PK_d58ff85d168891bef17013fda6d = 'PK_d58ff85d168891bef17013fda6d',
}

/**
 * update columns of table "ticket_source_push_strategies"
 */
export enum ticket_source_push_strategies_update_column {
  commentById = 'commentById',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  labels = 'labels',
  ticketSourceId = 'ticketSourceId',
  ticketStatusMap = 'ticketStatusMap',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_source_push_strategy_types"
 */
export enum ticket_source_push_strategy_types_constraint {
  PK_9398928488a48aab7a5dcdf5c8e = 'PK_9398928488a48aab7a5dcdf5c8e',
}

/**
 * update columns of table "ticket_source_push_strategy_types"
 */
export enum ticket_source_push_strategy_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "ticket_sources"
 */
export enum ticket_sources_constraint {
  PK_5d9f76bdb2b389525a272b99209 = 'PK_5d9f76bdb2b389525a272b99209',
  REL_ff739099fbe10dc8e8c8593e7e = 'REL_ff739099fbe10dc8e8c8593e7e',
  UQ_29b4c5d3765a8b7b3af7945dd6e = 'UQ_29b4c5d3765a8b7b3af7945dd6e',
  UQ_TICKET_SOURCE_PREFIX = 'UQ_TICKET_SOURCE_PREFIX',
  UQ_d5c9759ad207c9d67b4990e60f9 = 'UQ_d5c9759ad207c9d67b4990e60f9',
  UQ_ecbd7db11b0e4cb7785fc6b4530 = 'UQ_ecbd7db11b0e4cb7785fc6b4530',
  UQ_ff739099fbe10dc8e8c8593e7eb = 'UQ_ff739099fbe10dc8e8c8593e7eb',
}

/**
 * update columns of table "ticket_sources"
 */
export enum ticket_sources_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  creditsToStoryPoints = 'creditsToStoryPoints',
  deletedAt = 'deletedAt',
  gitRepoId = 'gitRepoId',
  githubRepoLabelSid = 'githubRepoLabelSid',
  gitlabAssigneeSid = 'gitlabAssigneeSid',
  gitlabProjectSid = 'gitlabProjectSid',
  id = 'id',
  jiraAssigneeSid = 'jiraAssigneeSid',
  jiraDescriptionFieldId = 'jiraDescriptionFieldId',
  jiraProjectSid = 'jiraProjectSid',
  jiraStoryPointKey = 'jiraStoryPointKey',
  linearTeamSid = 'linearTeamSid',
  prefix = 'prefix',
  projectName = 'projectName',
  repoId = 'repoId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "ticket_status_changes"
 */
export enum ticket_status_changes_constraint {
  PK_a521549da6f37a3cb77bc4519f2 = 'PK_a521549da6f37a3cb77bc4519f2',
}

/**
 * update columns of table "ticket_status_changes"
 */
export enum ticket_status_changes_update_column {
  automaticallyTransioned = 'automaticallyTransioned',
  createdAt = 'createdAt',
  id = 'id',
  reasonForTransition = 'reasonForTransition',
  status = 'status',
  ticketId = 'ticketId',
  transitionedAt = 'transitionedAt',
  transitionedByUserId = 'transitionedByUserId',
}

/**
 * unique or primary key constraints on table "ticket_status"
 */
export enum ticket_status_constraint {
  PK_3f4f420392bda2dd7ab6e98e92a = 'PK_3f4f420392bda2dd7ab6e98e92a',
}

export enum ticket_status_enum {
  available = 'available',
  backlog = 'backlog',
  blocked = 'blocked',
  cancelled = 'cancelled',
  errored = 'errored',
  finished = 'finished',
  in_progress = 'in_progress',
  partially_blocked = 'partially_blocked',
  partially_under_review = 'partially_under_review',
  under_review = 'under_review',
}

/**
 * update columns of table "ticket_status"
 */
export enum ticket_status_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "tickets"
 */
export enum tickets_constraint {
  PK_343bc942ae261cf7a1377f48fd0 = 'PK_343bc942ae261cf7a1377f48fd0',
  UQ_013dd8562f32b40a5d76287c819 = 'UQ_013dd8562f32b40a5d76287c819',
  UQ_1907c2dc72947998e1a95e5b67d = 'UQ_1907c2dc72947998e1a95e5b67d',
  UQ_3a17387be419fb7f4087680f76d = 'UQ_3a17387be419fb7f4087680f76d',
  UQ_90f8619ba4d35663b3ad5701d88 = 'UQ_90f8619ba4d35663b3ad5701d88',
  UQ_c6e20a830c0f8b571abd331b775 = 'UQ_c6e20a830c0f8b571abd331b775',
  UQ_c7f04cb0c8f1a8a8649e8f628d5 = 'UQ_c7f04cb0c8f1a8a8649e8f628d5',
}

/**
 * unique or primary key constraints on table "tickets_types"
 */
export enum tickets_types_constraint {
  PK_b830c630ddcf4aa69220f2bcfe1 = 'PK_b830c630ddcf4aa69220f2bcfe1',
}

export enum tickets_types_enum {
  bug = 'bug',
  consult = 'consult',
  feature = 'feature',
  maintain = 'maintain',
}

/**
 * update columns of table "tickets_types"
 */
export enum tickets_types_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "tickets"
 */
export enum tickets_update_column {
  cancelledAt = 'cancelledAt',
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  code = 'code',
  completedAt = 'completedAt',
  costInCredits = 'costInCredits',
  createdAt = 'createdAt',
  description = 'description',
  descriptionType = 'descriptionType',
  discussionSourceId = 'discussionSourceId',
  gitRepoId = 'gitRepoId',
  githubIssueSid = 'githubIssueSid',
  gitlabIssueSid = 'gitlabIssueSid',
  id = 'id',
  invoiceId = 'invoiceId',
  isInternal = 'isInternal',
  isSynced = 'isSynced',
  jiraIssueSid = 'jiraIssueSid',
  linearIssueSid = 'linearIssueSid',
  lowerBudget = 'lowerBudget',
  managerId = 'managerId',
  priority = 'priority',
  startedAt = 'startedAt',
  status = 'status',
  statusUpdateReason = 'statusUpdateReason',
  statusUpdatedAt = 'statusUpdatedAt',
  status_v2 = 'status_v2',
  syncedAt = 'syncedAt',
  ticketLink = 'ticketLink',
  ticketSourceId = 'ticketSourceId',
  title = 'title',
  type = 'type',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "user_billable_hours"
 */
export enum user_billable_hours_constraint {
  PK_7a41ce342a945989d3e646fc018 = 'PK_7a41ce342a945989d3e646fc018',
}

/**
 * update columns of table "user_billable_hours"
 */
export enum user_billable_hours_update_column {
  creditsPerHour = 'creditsPerHour',
  endDate = 'endDate',
  id = 'id',
  startDate = 'startDate',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "user_changes"
 */
export enum user_changes_constraint {
  PK_d1c7d1d135b29c96c6447aa9d44 = 'PK_d1c7d1d135b29c96c6447aa9d44',
}

/**
 * update columns of table "user_changes"
 */
export enum user_changes_update_column {
  adminUserId = 'adminUserId',
  agencyUserId = 'agencyUserId',
  auth0Sid = 'auth0Sid',
  automaticallyChanged = 'automaticallyChanged',
  avatarUrl = 'avatarUrl',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  clientUserId = 'clientUserId',
  createdAt = 'createdAt',
  dateOfBirth = 'dateOfBirth',
  defaultEmail = 'defaultEmail',
  deletedAt = 'deletedAt',
  developerId = 'developerId',
  firstName = 'firstName',
  id = 'id',
  intercomUserId = 'intercomUserId',
  lastName = 'lastName',
  login = 'login',
  name = 'name',
  nickname = 'nickname',
  operation = 'operation',
  passwordHash = 'passwordHash',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  ripplingEmployeeId = 'ripplingEmployeeId',
  sqlQuery = 'sqlQuery',
  telephone = 'telephone',
  timezone = 'timezone',
  unverifiedEmail = 'unverifiedEmail',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "user_coefficients"
 */
export enum user_coefficients_constraint {
  PK_97c5dacbcba45c48be0c6faee14 = 'PK_97c5dacbcba45c48be0c6faee14',
}

/**
 * update columns of table "user_coefficients"
 */
export enum user_coefficients_update_column {
  coefficient = 'coefficient',
  createdAt = 'createdAt',
  endDate = 'endDate',
  id = 'id',
  startDate = 'startDate',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_contract_types"
 */
export enum user_contract_types_constraint {
  PK_24f1b9c6228778935393f86ab5f = 'PK_24f1b9c6228778935393f86ab5f',
}

export enum user_contract_types_enum {
  advisor_contract = 'advisor_contract',
  associate_contract = 'associate_contract',
  core_contract = 'core_contract',
  crispr_developer_contract = 'crispr_developer_contract',
  full_time_developer_contract = 'full_time_developer_contract',
  hack_week_contract = 'hack_week_contract',
  intern_contract = 'intern_contract',
  other = 'other',
  part_time_developer_contract = 'part_time_developer_contract',
  tpm_contract = 'tpm_contract',
}

/**
 * update columns of table "user_contract_types"
 */
export enum user_contract_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_contracts"
 */
export enum user_contracts_constraint {
  PK_064bcba7cf7716121d842eaty5y = 'PK_064bcba7cf7716121d842eaty5y',
}

/**
 * update columns of table "user_contracts"
 */
export enum user_contracts_update_column {
  amountPerWeek = 'amountPerWeek',
  contractSendError = 'contractSendError',
  contractSentAt = 'contractSentAt',
  contractType = 'contractType',
  defaultCoefficient = 'defaultCoefficient',
  endDate = 'endDate',
  gracePeriodEndDate = 'gracePeriodEndDate',
  hoursPerWeek = 'hoursPerWeek',
  id = 'id',
  startDate = 'startDate',
  title = 'title',
  url = 'url',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "user_culture_screens"
 */
export enum user_culture_screens_constraint {
  PK_5865f0d934e3da543fa2877819e = 'PK_5865f0d934e3da543fa2877819e',
}

/**
 * update columns of table "user_culture_screens"
 */
export enum user_culture_screens_update_column {
  attendedAt = 'attendedAt',
  availableFrom = 'availableFrom',
  availableWeeklyCapacity = 'availableWeeklyCapacity',
  careerGoalsScore = 'careerGoalsScore',
  communicationScore = 'communicationScore',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  determinedByUserId = 'determinedByUserId',
  expectedHourlySalary = 'expectedHourlySalary',
  expectedMonthlySalary = 'expectedMonthlySalary',
  id = 'id',
  notes = 'notes',
  recordingLink = 'recordingLink',
  remoteWorkScore = 'remoteWorkScore',
  scheduledAt = 'scheduledAt',
  updatedAt = 'updatedAt',
  userId = 'userId',
  workAttitudeScore = 'workAttitudeScore',
}

/**
 * unique or primary key constraints on table "user_daily_earnings"
 */
export enum user_daily_earnings_constraint {
  PK_0642d025869b5a9adca22f84c70 = 'PK_0642d025869b5a9adca22f84c70',
}

/**
 * update columns of table "user_daily_earnings"
 */
export enum user_daily_earnings_update_column {
  createdAt = 'createdAt',
  date = 'date',
  deletedAt = 'deletedAt',
  hoursWorkedOnDay = 'hoursWorkedOnDay',
  id = 'id',
  overrideHoursWorkedOnDay = 'overrideHoursWorkedOnDay',
  updatedAt = 'updatedAt',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "user_daily_facts_structure"
 */
export enum user_daily_facts_structure_constraint {
  PK_1bd580fbba916db9515c282ef9e = 'PK_1bd580fbba916db9515c282ef9e',
}

/**
 * update columns of table "user_daily_facts_structure"
 */
export enum user_daily_facts_structure_update_column {
  hoursLogged = 'hoursLogged',
  hoursLoggedOnOther = 'hoursLoggedOnOther',
  hoursRequired = 'hoursRequired',
  leaveId = 'leaveId',
  leaveIsApproved = 'leaveIsApproved',
  occurredAt = 'occurredAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_daily_plan_activities"
 */
export enum user_daily_plan_activities_constraint {
  user_daily_plan_activities_pkey = 'user_daily_plan_activities_pkey',
}

/**
 * update columns of table "user_daily_plan_activities"
 */
export enum user_daily_plan_activities_update_column {
  activity_id = 'activity_id',
  id = 'id',
  user_daily_plan_id = 'user_daily_plan_id',
}

/**
 * unique or primary key constraints on table "user_daily_plan_tasks"
 */
export enum user_daily_plan_tasks_constraint {
  user_daily_plan_tasks_pkey = 'user_daily_plan_tasks_pkey',
}

/**
 * update columns of table "user_daily_plan_tasks"
 */
export enum user_daily_plan_tasks_update_column {
  id = 'id',
  task_id = 'task_id',
  user_daily_plan_id = 'user_daily_plan_id',
}

/**
 * unique or primary key constraints on table "user_daily_plans"
 */
export enum user_daily_plans_constraint {
  user_daily_plans_pkey = 'user_daily_plans_pkey',
}

/**
 * update columns of table "user_daily_plans"
 */
export enum user_daily_plans_update_column {
  id = 'id',
  inserted_at = 'inserted_at',
  submitted_at = 'submitted_at',
  updated_at = 'updated_at',
  user_id = 'user_id',
}

/**
 * unique or primary key constraints on table "user_daily_retrospectives"
 */
export enum user_daily_retrospectives_constraint {
  user_daily_retrospectives_pkey = 'user_daily_retrospectives_pkey',
}

/**
 * update columns of table "user_daily_retrospectives"
 */
export enum user_daily_retrospectives_update_column {
  id = 'id',
  inserted_at = 'inserted_at',
  submitted_at = 'submitted_at',
  updated_at = 'updated_at',
  user_daily_plan_id = 'user_daily_plan_id',
  user_id = 'user_id',
}

/**
 * unique or primary key constraints on table "user_daily_standups"
 */
export enum user_daily_standups_constraint {
  PK_49f95492e9d0de21cd74f5a1768 = 'PK_49f95492e9d0de21cd74f5a1768',
}

/**
 * update columns of table "user_daily_standups"
 */
export enum user_daily_standups_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  errorId = 'errorId',
  errorMessage = 'errorMessage',
  erroredAt = 'erroredAt',
  id = 'id',
  messageForTeams = 'messageForTeams',
  postedToSlack = 'postedToSlack',
  postedToWorkplace = 'postedToWorkplace',
  submittedAt = 'submittedAt',
  summary = 'summary',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_emails"
 */
export enum user_emails_constraint {
  PK_6594597afde633cfeab9a806e4f = 'PK_6594597afde633cfeab9a806e4f',
}

/**
 * update columns of table "user_emails"
 */
export enum user_emails_update_column {
  email = 'email',
  userId = 'userId',
  verified = 'verified',
}

/**
 * unique or primary key constraints on table "user_event_source_types"
 */
export enum user_event_source_types_constraint {
  PK_33f0a1d94d0b84d40bfced7c90b = 'PK_33f0a1d94d0b84d40bfced7c90b',
}

export enum user_event_source_types_enum {
  browser_extension = 'browser_extension',
  dashboard = 'dashboard',
  vscode_extension = 'vscode_extension',
}

/**
 * update columns of table "user_event_source_types"
 */
export enum user_event_source_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_event_types"
 */
export enum user_event_types_constraint {
  PK_34ca774aaa70b570a88724f24da = 'PK_34ca774aaa70b570a88724f24da',
}

export enum user_event_types_enum {
  activity_pause = 'activity_pause',
  activity_resume = 'activity_resume',
  app_use = 'app_use',
  browse_url = 'browse_url',
  end_day = 'end_day',
  file_edit = 'file_edit',
  project_pause = 'project_pause',
  project_resume = 'project_resume',
  start_day = 'start_day',
  task_pause = 'task_pause',
  task_resume = 'task_resume',
  ticket_pause = 'ticket_pause',
  ticket_resume = 'ticket_resume',
}

/**
 * update columns of table "user_event_types"
 */
export enum user_event_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_events"
 */
export enum user_events_constraint {
  PK_22f49067e87f2c8a3fff76543d1 = 'PK_22f49067e87f2c8a3fff76543d1',
}

/**
 * update columns of table "user_events"
 */
export enum user_events_update_column {
  appName = 'appName',
  browserUrl = 'browserUrl',
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  duration = 'duration',
  eventSource = 'eventSource',
  eventType = 'eventType',
  filePath = 'filePath',
  gitBranchRef = 'gitBranchRef',
  gitCommitHash = 'gitCommitHash',
  gitOwner = 'gitOwner',
  gitRelativeFilePath = 'gitRelativeFilePath',
  gitRepoName = 'gitRepoName',
  gitSourceId = 'gitSourceId',
  id = 'id',
  isProcessed = 'isProcessed',
  occurredAt = 'occurredAt',
  pollInterval = 'pollInterval',
  taskId = 'taskId',
  ticketId = 'ticketId',
  title = 'title',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_facts_structure"
 */
export enum user_facts_structure_constraint {
  PK_3fb2feb375fd1663983c18663d2 = 'PK_3fb2feb375fd1663983c18663d2',
}

/**
 * update columns of table "user_facts_structure"
 */
export enum user_facts_structure_update_column {
  paidLeavesEarned = 'paidLeavesEarned',
  paidLeavesUtilized = 'paidLeavesUtilized',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_hackweek_screens"
 */
export enum user_hackweek_screens_constraint {
  PK_152463039407db127c77ef73e5c = 'PK_152463039407db127c77ef73e5c',
}

/**
 * unique or primary key constraints on table "user_hackweek_screens_result"
 */
export enum user_hackweek_screens_result_constraint {
  PK_f34789b49f16b454d13d678f6b7 = 'PK_f34789b49f16b454d13d678f6b7',
}

/**
 * update columns of table "user_hackweek_screens_result"
 */
export enum user_hackweek_screens_result_update_column {
  description = 'description',
  type = 'type',
}

/**
 * update columns of table "user_hackweek_screens"
 */
export enum user_hackweek_screens_update_column {
  communicationScore = 'communicationScore',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  mentoredById = 'mentoredById',
  notes = 'notes',
  result = 'result',
  startAt = 'startAt',
  teamWorkScore = 'teamWorkScore',
  techSkillsScore = 'techSkillsScore',
  updatedAt = 'updatedAt',
  userId = 'userId',
  workOutputScore = 'workOutputScore',
}

/**
 * unique or primary key constraints on table "user_invoices"
 */
export enum user_invoices_constraint {
  PK_cedd26761ec7d6a0772be9f8289 = 'PK_cedd26761ec7d6a0772be9f8289',
  UQ_USER_INVOICE_DUE_AT = 'UQ_USER_INVOICE_DUE_AT',
}

/**
 * update columns of table "user_invoices"
 */
export enum user_invoices_update_column {
  adjustedCostInUSD = 'adjustedCostInUSD',
  costInUSD = 'costInUSD',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  dueAt = 'dueAt',
  endAt = 'endAt',
  id = 'id',
  invoiceCode = 'invoiceCode',
  paidByAgencyInvoiceId = 'paidByAgencyInvoiceId',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_leave_types"
 */
export enum user_leave_types_constraint {
  PK_574da046ba0762f432335e5e066 = 'PK_574da046ba0762f432335e5e066',
}

export enum user_leave_types_enum {
  emergency_leave = 'emergency_leave',
  paid_leave = 'paid_leave',
  public_holiday = 'public_holiday',
  sick_leave = 'sick_leave',
  unpaid_leave = 'unpaid_leave',
}

/**
 * update columns of table "user_leave_types"
 */
export enum user_leave_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_leaves"
 */
export enum user_leaves_constraint {
  PK_976206f5ab434b02badd34fbfe4 = 'PK_976206f5ab434b02badd34fbfe4',
}

/**
 * update columns of table "user_leaves"
 */
export enum user_leaves_update_column {
  approvedById = 'approvedById',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  isApproved = 'isApproved',
  leaveReason = 'leaveReason',
  leaveTypeId = 'leaveTypeId',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_onboarded_repos"
 */
export enum user_onboarded_repos_constraint {
  PK_8ba14f82badaac977ded334a04f = 'PK_8ba14f82badaac977ded334a04f',
  REL_21322bd0c5039ce884822fad5d = 'REL_21322bd0c5039ce884822fad5d',
}

/**
 * update columns of table "user_onboarded_repos"
 */
export enum user_onboarded_repos_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  login = 'login',
  repoId = 'repoId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "user_opportunity_interests"
 */
export enum user_opportunity_interests_constraint {
  PK_6dc8d96d4f445dd2988862ec89d = 'PK_6dc8d96d4f445dd2988862ec89d',
}

/**
 * update columns of table "user_opportunity_interests"
 */
export enum user_opportunity_interests_update_column {
  opportunitiesId = 'opportunitiesId',
  usersId = 'usersId',
}

/**
 * unique or primary key constraints on table "user_payment_status"
 */
export enum user_payment_status_constraint {
  PK_c33d515e570af32c25f63e1b79f = 'PK_c33d515e570af32c25f63e1b79f',
}

export enum user_payment_status_enum {
  failed = 'failed',
  in_progress = 'in_progress',
  pending = 'pending',
  successful = 'successful',
}

/**
 * update columns of table "user_payment_status"
 */
export enum user_payment_status_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_payment_types"
 */
export enum user_payment_types_constraint {
  PK_c723f67490f86674c428bec1c2d = 'PK_c723f67490f86674c428bec1c2d',
}

export enum user_payment_types_enum {
  bank_hk_hsb = 'bank_hk_hsb',
  bank_hk_hsbc = 'bank_hk_hsbc',
  bank_pk_hbl = 'bank_pk_hbl',
  bank_us_frb = 'bank_us_frb',
  brex = 'brex',
  payoneer = 'payoneer',
  paypal = 'paypal',
  rippling = 'rippling',
  transferwise = 'transferwise',
  transferwise_hk = 'transferwise_hk',
}

/**
 * update columns of table "user_payment_types"
 */
export enum user_payment_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_payments"
 */
export enum user_payments_constraint {
  PK_d4cd725874e6403f6a8f774cdad = 'PK_d4cd725874e6403f6a8f774cdad',
  UQ_0d9ce2f90f8d58a69814e41b6e3 = 'UQ_0d9ce2f90f8d58a69814e41b6e3',
  UQ_893093c647efa86a0b961eeb986 = 'UQ_893093c647efa86a0b961eeb986',
}

/**
 * update columns of table "user_payments"
 */
export enum user_payments_update_column {
  amountInUSD = 'amountInUSD',
  channelTransactionId = 'channelTransactionId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  finishedAt = 'finishedAt',
  id = 'id',
  paymentType = 'paymentType',
  ripplingPayrollRunDetailsSid = 'ripplingPayrollRunDetailsSid',
  status = 'status',
  updatedAt = 'updatedAt',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "user_profiles"
 */
export enum user_profiles_constraint {
  PK_1ec6662219f4605723f1e41b6cb = 'PK_1ec6662219f4605723f1e41b6cb',
  REL_22e6b541f0a8776acee24a3e66 = 'REL_22e6b541f0a8776acee24a3e66',
}

/**
 * update columns of table "user_profiles"
 */
export enum user_profiles_update_column {
  accessToken = 'accessToken',
  accessTokenExpiresAt = 'accessTokenExpiresAt',
  avatarUrl = 'avatarUrl',
  createdAt = 'createdAt',
  defaultEmail = 'defaultEmail',
  defaultEmailVerified = 'defaultEmailVerified',
  deletedAt = 'deletedAt',
  firstName = 'firstName',
  id = 'id',
  lastName = 'lastName',
  login = 'login',
  name = 'name',
  password = 'password',
  personalAccessToken = 'personalAccessToken',
  rateLimitLeft = 'rateLimitLeft',
  rateLimitResetsAt = 'rateLimitResetsAt',
  refreshToken = 'refreshToken',
  syncSid = 'syncSid',
  syncTableName = 'syncTableName',
  type = 'type',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_sessions"
 */
export enum user_sessions_constraint {
  PK_e93e031a5fed190d4789b6bfd83 = 'PK_e93e031a5fed190d4789b6bfd83',
  UQ_234f6793f43e07b7b977778eb7c = 'UQ_234f6793f43e07b7b977778eb7c',
  UQ_cd183bcb9ffe40bd858ed6b6b87 = 'UQ_cd183bcb9ffe40bd858ed6b6b87',
}

/**
 * update columns of table "user_sessions"
 */
export enum user_sessions_update_column {
  accessToken = 'accessToken',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  expiresAt = 'expiresAt',
  id = 'id',
  sessionToken = 'sessionToken',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_skills"
 */
export enum user_skills_constraint {
  PK_060bea7fd45868588324719de3c = 'PK_060bea7fd45868588324719de3c',
}

/**
 * update columns of table "user_skills"
 */
export enum user_skills_update_column {
  aggregatedMonthsOfExperience = 'aggregatedMonthsOfExperience',
  competencyId = 'competencyId',
  lastUsedDate = 'lastUsedDate',
  skillId = 'skillId',
  startDate = 'startDate',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_team_clients"
 */
export enum user_team_clients_constraint {
  PK_3aaa51e91b483e5426842fde957 = 'PK_3aaa51e91b483e5426842fde957',
  UQ_USER_TEAM_CLIENTS = 'UQ_USER_TEAM_CLIENTS',
}

/**
 * update columns of table "user_team_clients"
 */
export enum user_team_clients_update_column {
  clientId = 'clientId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  isOwned = 'isOwned',
  managerId = 'managerId',
  projectName = 'projectName',
  updatedAt = 'updatedAt',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "user_team_leads"
 */
export enum user_team_leads_constraint {
  PK_387b4c3b48b290aad7004899f6a = 'PK_387b4c3b48b290aad7004899f6a',
}

/**
 * update columns of table "user_team_leads"
 */
export enum user_team_leads_update_column {
  endAt = 'endAt',
  id = 'id',
  isShadow = 'isShadow',
  startAt = 'startAt',
  teamLeadUserId = 'teamLeadUserId',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "user_team_member_changes"
 */
export enum user_team_member_changes_constraint {
  PK_0a7d0ed8685b93c6744b25445f9 = 'PK_0a7d0ed8685b93c6744b25445f9',
}

/**
 * update columns of table "user_team_member_changes"
 */
export enum user_team_member_changes_update_column {
  automaticallyChanged = 'automaticallyChanged',
  changeCreatedAt = 'changeCreatedAt',
  changeId = 'changeId',
  changedByUserId = 'changedByUserId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  operation = 'operation',
  previousChangeId = 'previousChangeId',
  reasonForChange = 'reasonForChange',
  role = 'role',
  sqlQuery = 'sqlQuery',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
  userId = 'userId',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "user_team_members"
 */
export enum user_team_members_constraint {
  PK_d9627a651fa3d35f8264f9ca204 = 'PK_d9627a651fa3d35f8264f9ca204',
}

/**
 * update columns of table "user_team_members"
 */
export enum user_team_members_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  role = 'role',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
  userId = 'userId',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "user_team_roles"
 */
export enum user_team_roles_constraint {
  PK_9adc6cc0b81b7e7327fa54e02d8 = 'PK_9adc6cc0b81b7e7327fa54e02d8',
}

/**
 * update columns of table "user_team_roles"
 */
export enum user_team_roles_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_team_standups"
 */
export enum user_team_standups_constraint {
  PK_661ba08e290dccea718b34dc39c = 'PK_661ba08e290dccea718b34dc39c',
}

/**
 * update columns of table "user_team_standups"
 */
export enum user_team_standups_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  standupTime = 'standupTime',
  startAt = 'startAt',
  updatedAt = 'updatedAt',
  userTeamId = 'userTeamId',
}

/**
 * unique or primary key constraints on table "user_teams"
 */
export enum user_teams_constraint {
  PK_f7e0532d285aa51d49450cf3dc1 = 'PK_f7e0532d285aa51d49450cf3dc1',
}

/**
 * update columns of table "user_teams"
 */
export enum user_teams_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  googleGroupEmail = 'googleGroupEmail',
  id = 'id',
  isPublic = 'isPublic',
  parentTeamId = 'parentTeamId',
  standupTime = 'standupTime',
  teamLeadUserId = 'teamLeadUserId',
  teamName = 'teamName',
  updatedAt = 'updatedAt',
  workspaceGroupSid = 'workspaceGroupSid',
}

/**
 * unique or primary key constraints on table "user_tech_screens"
 */
export enum user_tech_screens_constraint {
  PK_e312e00bf7e42dc47799f90ca5b = 'PK_e312e00bf7e42dc47799f90ca5b',
}

/**
 * update columns of table "user_tech_screens"
 */
export enum user_tech_screens_update_column {
  attendedAt = 'attendedAt',
  communicationScore = 'communicationScore',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  interviewedById = 'interviewedById',
  notes = 'notes',
  passedTechScreen = 'passedTechScreen',
  recordingLink = 'recordingLink',
  scheduledAt = 'scheduledAt',
  techSkillsScore = 'techSkillsScore',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_transaction_types"
 */
export enum user_transaction_types_constraint {
  PK_ef5a746837f4f747bea60743eb8 = 'PK_ef5a746837f4f747bea60743eb8',
}

export enum user_transaction_types_enum {
  bonus = 'bonus',
  csm_profit_share = 'csm_profit_share',
  dev_profit_share = 'dev_profit_share',
  gitstart_saving = 'gitstart_saving',
  loan_issued = 'loan_issued',
  loan_repaid = 'loan_repaid',
  mentorship_bonus = 'mentorship_bonus',
  reimbursement = 'reimbursement',
  salary_overpay = 'salary_overpay',
  setup_credit = 'setup_credit',
  team_lead_profit_share = 'team_lead_profit_share',
}

/**
 * update columns of table "user_transaction_types"
 */
export enum user_transaction_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "user_transactions"
 */
export enum user_transactions_constraint {
  PK_21325240e8a1f55f22a6f35df4f = 'PK_21325240e8a1f55f22a6f35df4f',
}

/**
 * update columns of table "user_transactions"
 */
export enum user_transactions_update_column {
  amountInUSD = 'amountInUSD',
  approved = 'approved',
  approvedBy = 'approvedBy',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  occurredAt = 'occurredAt',
  transactionTypeId = 'transactionTypeId',
  updatedAt = 'updatedAt',
  userLogin = 'userLogin',
}

/**
 * unique or primary key constraints on table "user_verification_requests"
 */
export enum user_verification_requests_constraint {
  PK_97df06e71782295585ce10b9801 = 'PK_97df06e71782295585ce10b9801',
  UQ_414756803100297d7c2bc557b26 = 'UQ_414756803100297d7c2bc557b26',
}

/**
 * update columns of table "user_verification_requests"
 */
export enum user_verification_requests_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  expiresAt = 'expiresAt',
  id = 'id',
  identifier = 'identifier',
  token = 'token',
  updatedAt = 'updatedAt',
  userId = 'userId',
}

/**
 * unique or primary key constraints on table "user_work_logs"
 */
export enum user_work_logs_constraint {
  PK_63ebe792e2faf96740d825edf51 = 'PK_63ebe792e2faf96740d825edf51',
}

/**
 * update columns of table "user_work_logs"
 */
export enum user_work_logs_update_column {
  activity_id = 'activity_id',
  approvalStatus = 'approvalStatus',
  approvedBy = 'approvedBy',
  billableToClient = 'billableToClient',
  clientId = 'clientId',
  clientProjectId = 'clientProjectId',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endAt = 'endAt',
  id = 'id',
  meetingType = 'meetingType',
  source = 'source',
  startAt = 'startAt',
  status = 'status',
  taskId = 'taskId',
  technologyId = 'technologyId',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  userId = 'userId',
  workDescription = 'workDescription',
  workType = 'workType',
}

/**
 * unique or primary key constraints on table "users"
 */
export enum users_constraint {
  PK_a3ffb1c0c8416b9fc6f907b7433 = 'PK_a3ffb1c0c8416b9fc6f907b7433',
  UQ_2d443082eccd5198f95f2a36e2c = 'UQ_2d443082eccd5198f95f2a36e2c',
  UQ_3afb62555b874925fb7352eca43 = 'UQ_3afb62555b874925fb7352eca43',
  UQ_53a495e5b24279804db49702cc1 = 'UQ_53a495e5b24279804db49702cc1',
  UQ_9e487924df94a4241b487b2b52e = 'UQ_9e487924df94a4241b487b2b52e',
  UQ_aacbcbfc16077f6b485951adfb4 = 'UQ_aacbcbfc16077f6b485951adfb4',
  UQ_f53d1c88763af9aa36c01b2a801 = 'UQ_f53d1c88763af9aa36c01b2a801',
}

/**
 * update columns of table "users"
 */
export enum users_update_column {
  auth0Sid = 'auth0Sid',
  avatarUrl = 'avatarUrl',
  createdAt = 'createdAt',
  dateOfBirth = 'dateOfBirth',
  defaultEmail = 'defaultEmail',
  deletedAt = 'deletedAt',
  firstName = 'firstName',
  id = 'id',
  intercomUserId = 'intercomUserId',
  lastName = 'lastName',
  login = 'login',
  name = 'name',
  nickname = 'nickname',
  passwordHash = 'passwordHash',
  ripplingEmployeeId = 'ripplingEmployeeId',
  telephone = 'telephone',
  timezone = 'timezone',
  unverifiedEmail = 'unverifiedEmail',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "webhook_event_errors"
 */
export enum webhook_event_errors_constraint {
  PK_2f502e75f715ff0caa0aba0f266 = 'PK_2f502e75f715ff0caa0aba0f266',
}

/**
 * update columns of table "webhook_event_errors"
 */
export enum webhook_event_errors_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  expiresAt = 'expiresAt',
  id = 'id',
  mergeError = 'mergeError',
  occurredAt = 'occurredAt',
  updatedAt = 'updatedAt',
  webhook_event_id = 'webhook_event_id',
}

/**
 * unique or primary key constraints on table "webhook_events"
 */
export enum webhook_events_constraint {
  PK_4cba37e6a0acb5e1fc49c34ebfd = 'PK_4cba37e6a0acb5e1fc49c34ebfd',
  UQ_3b11847e2899ff00fdae06d257f = 'UQ_3b11847e2899ff00fdae06d257f',
}

/**
 * update columns of table "webhook_events"
 */
export enum webhook_events_update_column {
  createdAt = 'createdAt',
  data = 'data',
  deletedAt = 'deletedAt',
  eventSubType = 'eventSubType',
  eventType = 'eventType',
  headers = 'headers',
  id = 'id',
  isIgnored = 'isIgnored',
  mergedAt = 'mergedAt',
  occurredAt = 'occurredAt',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  webhookId = 'webhookId',
}

/**
 * unique or primary key constraints on table "webhooks"
 */
export enum webhooks_constraint {
  PK_9e8795cfc899ab7bdaa831e8527 = 'PK_9e8795cfc899ab7bdaa831e8527',
  REL_e98f2c1f62d6ace8ef7ee884b7 = 'REL_e98f2c1f62d6ace8ef7ee884b7',
}

/**
 * update columns of table "webhooks"
 */
export enum webhooks_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  eventSubTypeJSONPath = 'eventSubTypeJSONPath',
  eventTypeJSONPath = 'eventTypeJSONPath',
  id = 'id',
  isActive = 'isActive',
  secret = 'secret',
  source = 'source',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  zoomAppId = 'zoomAppId',
}

/**
 * unique or primary key constraints on table "work_log_approval_status_types"
 */
export enum work_log_approval_status_types_constraint {
  PK_ddcaa2259fe04334df8baffc57b = 'PK_ddcaa2259fe04334df8baffc57b',
}

export enum work_log_approval_status_types_enum {
  approved = 'approved',
  auto = 'auto',
  rejected = 'rejected',
  under_review = 'under_review',
}

/**
 * update columns of table "work_log_approval_status_types"
 */
export enum work_log_approval_status_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "work_log_meeting_types"
 */
export enum work_log_meeting_types_constraint {
  PK_b17e8f3612d573e5a019b2e3ba1 = 'PK_b17e8f3612d573e5a019b2e3ba1',
}

export enum work_log_meeting_types_enum {
  all_hands = 'all_hands',
  client_call = 'client_call',
  daily_standup = 'daily_standup',
  team_retrospective = 'team_retrospective',
  weekly_demos = 'weekly_demos',
}

/**
 * update columns of table "work_log_meeting_types"
 */
export enum work_log_meeting_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "work_log_status_types"
 */
export enum work_log_status_types_constraint {
  PK_ed9ae066f47a0cb4e30de53236a = 'PK_ed9ae066f47a0cb4e30de53236a',
}

export enum work_log_status_types_enum {
  confirmed = 'confirmed',
  locked = 'locked',
  needs_confirmation = 'needs_confirmation',
}

/**
 * update columns of table "work_log_status_types"
 */
export enum work_log_status_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "work_log_types"
 */
export enum work_log_types_constraint {
  PK_93079faaa71d1917d8e4b47b514 = 'PK_93079faaa71d1917d8e4b47b514',
}

export enum work_log_types_enum {
  activity = 'activity',
  client = 'client',
  client_billed = 'client_billed',
  client_project = 'client_project',
  learning = 'learning',
  meeting = 'meeting',
  other = 'other',
  task = 'task',
  ticket = 'ticket',
}

/**
 * update columns of table "work_log_types"
 */
export enum work_log_types_update_column {
  description = 'description',
  id = 'id',
}

/**
 * unique or primary key constraints on table "work_machine_os"
 */
export enum work_machine_os_constraint {
  PK_47cdb2428a55d28885c570745aa = 'PK_47cdb2428a55d28885c570745aa',
}

/**
 * update columns of table "work_machine_os"
 */
export enum work_machine_os_update_column {
  description = 'description',
  type = 'type',
}

/**
 * unique or primary key constraints on table "zoom_app"
 */
export enum zoom_app_constraint {
  PK_e5cacc1942b3f1f488f86169092 = 'PK_e5cacc1942b3f1f488f86169092',
  REL_a7b0f562cc81c833eb5e481951 = 'REL_a7b0f562cc81c833eb5e481951',
  UQ_81b29181b1d5160eada1adf8fe0 = 'UQ_81b29181b1d5160eada1adf8fe0',
}

/**
 * update columns of table "zoom_app"
 */
export enum zoom_app_update_column {
  authorizationToken = 'authorizationToken',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  name = 'name',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "zoom_meeting_instance_participants"
 */
export enum zoom_meeting_instance_participants_constraint {
  PK_9a72a6a3be8f823ebcc1181ed4f = 'PK_9a72a6a3be8f823ebcc1181ed4f',
  UQ_82d0c00d7dfc83a7b6b78caba3d = 'UQ_82d0c00d7dfc83a7b6b78caba3d',
  UQ_9da28ac99338028400055b75ef1 = 'UQ_9da28ac99338028400055b75ef1',
}

/**
 * update columns of table "zoom_meeting_instance_participants"
 */
export enum zoom_meeting_instance_participants_update_column {
  id = 'id',
  joinTime = 'joinTime',
  leaveTime = 'leaveTime',
  participantId = 'participantId',
  zoomMeetingInstanceSid = 'zoomMeetingInstanceSid',
  zoomUserSid = 'zoomUserSid',
}

/**
 * unique or primary key constraints on table "zoom_meeting_instances"
 */
export enum zoom_meeting_instances_constraint {
  PK_46e5099e55adfe858d12418f4b7 = 'PK_46e5099e55adfe858d12418f4b7',
  UQ_7f7c8678bd8ab78180d0d254364 = 'UQ_7f7c8678bd8ab78180d0d254364',
  UQ_8e6d0d9d2ba58cd3c977435d815 = 'UQ_8e6d0d9d2ba58cd3c977435d815',
}

/**
 * update columns of table "zoom_meeting_instances"
 */
export enum zoom_meeting_instances_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  endTime = 'endTime',
  id = 'id',
  sid = 'sid',
  startTime = 'startTime',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  zoomMeetingSid = 'zoomMeetingSid',
}

/**
 * unique or primary key constraints on table "zoom_meeting_occurences"
 */
export enum zoom_meeting_occurences_constraint {
  PK_aeea827f698546b523de45c85e3 = 'PK_aeea827f698546b523de45c85e3',
}

/**
 * update columns of table "zoom_meeting_occurences"
 */
export enum zoom_meeting_occurences_update_column {
  occurenceSid = 'occurenceSid',
  startTime = 'startTime',
  zoomMeetingSid = 'zoomMeetingSid',
}

/**
 * unique or primary key constraints on table "zoom_meeting_recurrences"
 */
export enum zoom_meeting_recurrences_constraint {
  PK_4bddd9e2e2132050502ebb87070 = 'PK_4bddd9e2e2132050502ebb87070',
  UQ_4bddd9e2e2132050502ebb87070 = 'UQ_4bddd9e2e2132050502ebb87070',
}

/**
 * update columns of table "zoom_meeting_recurrences"
 */
export enum zoom_meeting_recurrences_update_column {
  recurrenceEndDateTime = 'recurrenceEndDateTime',
  recurrenceEndTimes = 'recurrenceEndTimes',
  recurrenceMonthlyDay = 'recurrenceMonthlyDay',
  recurrenceMonthlyWeek = 'recurrenceMonthlyWeek',
  recurrenceMonthlyWeekDay = 'recurrenceMonthlyWeekDay',
  recurrenceRepeatInterval = 'recurrenceRepeatInterval',
  recurrenceType = 'recurrenceType',
  recurrenceWeeklyDays = 'recurrenceWeeklyDays',
  zoomMeetingSid = 'zoomMeetingSid',
}

/**
 * unique or primary key constraints on table "zoom_meetings"
 */
export enum zoom_meetings_constraint {
  PK_797522517ec01b068be1849260b = 'PK_797522517ec01b068be1849260b',
  UQ_2a4eeea7da39e8badd5bf859a10 = 'UQ_2a4eeea7da39e8badd5bf859a10',
  UQ_551080ba3b0a65d8b3f5d8c99e6 = 'UQ_551080ba3b0a65d8b3f5d8c99e6',
}

/**
 * update columns of table "zoom_meetings"
 */
export enum zoom_meetings_update_column {
  agenda = 'agenda',
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  duration = 'duration',
  id = 'id',
  joinUrl = 'joinUrl',
  registration_url = 'registration_url',
  sid = 'sid',
  startTime = 'startTime',
  syncEntityId = 'syncEntityId',
  topic = 'topic',
  type = 'type',
  updatedAt = 'updatedAt',
  zoomHostSid = 'zoomHostSid',
}

/**
 * unique or primary key constraints on table "zoom_recording_files"
 */
export enum zoom_recording_files_constraint {
  PK_e4a9c04dfb0aab09f063be911b2 = 'PK_e4a9c04dfb0aab09f063be911b2',
  UQ_d8f6fb9795bdd0eba074fa98015 = 'UQ_d8f6fb9795bdd0eba074fa98015',
  UQ_f788176c97b5971f688e39ad41e = 'UQ_f788176c97b5971f688e39ad41e',
}

/**
 * update columns of table "zoom_recording_files"
 */
export enum zoom_recording_files_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  downloadUrl = 'downloadUrl',
  endTime = 'endTime',
  fileSize = 'fileSize',
  fileType = 'fileType',
  id = 'id',
  playUrl = 'playUrl',
  recordingType = 'recordingType',
  sid = 'sid',
  startTime = 'startTime',
  status = 'status',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  zoomMeetingSid = 'zoomMeetingSid',
  zoomRecordingSid = 'zoomRecordingSid',
}

/**
 * unique or primary key constraints on table "zoom_recordings"
 */
export enum zoom_recordings_constraint {
  PK_4c634c36ea3b1fdb72b1f067b36 = 'PK_4c634c36ea3b1fdb72b1f067b36',
  UQ_202fec4de6454ba79dd4328222e = 'UQ_202fec4de6454ba79dd4328222e',
  UQ_a31be2e1a32d790af701a37ea66 = 'UQ_a31be2e1a32d790af701a37ea66',
}

/**
 * update columns of table "zoom_recordings"
 */
export enum zoom_recordings_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  downloadToken = 'downloadToken',
  duration = 'duration',
  id = 'id',
  recordingCount = 'recordingCount',
  shareUrl = 'shareUrl',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  totalSize = 'totalSize',
  updatedAt = 'updatedAt',
  zoomMeetingInstanceSid = 'zoomMeetingInstanceSid',
  zoomMeetingSid = 'zoomMeetingSid',
}

/**
 * unique or primary key constraints on table "zoom_users"
 */
export enum zoom_users_constraint {
  PK_7b9a328c14db60e95171767561c = 'PK_7b9a328c14db60e95171767561c',
  REL_ddd269ee617750607f0cbef65b = 'REL_ddd269ee617750607f0cbef65b',
  UQ_bcf20424ecb89aea51520bdc215 = 'UQ_bcf20424ecb89aea51520bdc215',
  UQ_e0dc7bf71fb8f63442cd44e23a2 = 'UQ_e0dc7bf71fb8f63442cd44e23a2',
}

/**
 * update columns of table "zoom_users"
 */
export enum zoom_users_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  email = 'email',
  firstName = 'firstName',
  id = 'id',
  lastName = 'lastName',
  pmi = 'pmi',
  sid = 'sid',
  syncEntityId = 'syncEntityId',
  updatedAt = 'updatedAt',
  use_pmi = 'use_pmi',
  userType = 'userType',
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'.
 */
export interface Float_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'.
 */
export interface _text_comparison_exp {
  _eq?: hasura__text | null;
  _gt?: hasura__text | null;
  _gte?: hasura__text | null;
  _in?: hasura__text[] | null;
  _is_null?: boolean | null;
  _lt?: hasura__text | null;
  _lte?: hasura__text | null;
  _neq?: hasura__text | null;
  _nin?: hasura__text[] | null;
}

/**
 * input type for inserting array relation for remote table "activities"
 */
export interface activities_arr_rel_insert_input {
  data: activities_insert_input[];
  on_conflict?: activities_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "activities". All fields are combined with a logical 'AND'.
 */
export interface activities_bool_exp {
  _and?: activities_bool_exp[] | null;
  _not?: activities_bool_exp | null;
  _or?: activities_bool_exp[] | null;
  assigned_user_id?: bigint_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  id?: bigint_comparison_exp | null;
  inserted_at?: timestamptz_comparison_exp | null;
  instance_id?: bigint_comparison_exp | null;
  related_entity?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_id?: bigint_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_daily_plan_activities?: user_daily_plan_activities_bool_exp | null;
}

/**
 * input type for inserting data into table "activities"
 */
export interface activities_insert_input {
  assigned_user_id?: hasura_bigint | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  id?: hasura_bigint | null;
  inserted_at?: hasura_timestamptz | null;
  instance_id?: hasura_bigint | null;
  related_entity?: string | null;
  status?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_id?: hasura_bigint | null;
  title?: string | null;
  updated_at?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_daily_plan_activities?: user_daily_plan_activities_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "activities"
 */
export interface activities_obj_rel_insert_input {
  data: activities_insert_input;
  on_conflict?: activities_on_conflict | null;
}

/**
 * on_conflict condition type for table "activities"
 */
export interface activities_on_conflict {
  constraint: activities_constraint;
  update_columns: activities_update_column[];
  where?: activities_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "admin"
 */
export interface admin_arr_rel_insert_input {
  data: admin_insert_input[];
  on_conflict?: admin_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "admin". All fields are combined with a logical 'AND'.
 */
export interface admin_bool_exp {
  _and?: admin_bool_exp[] | null;
  _not?: admin_bool_exp | null;
  _or?: admin_bool_exp[] | null;
  id?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userById?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
  user_changes?: user_changes_bool_exp | null;
}

/**
 * input type for inserting data into table "admin"
 */
export interface admin_insert_input {
  id?: string | null;
  user?: users_obj_rel_insert_input | null;
  userById?: users_obj_rel_insert_input | null;
  userId?: number | null;
  userLogin?: string | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "admin"
 */
export interface admin_obj_rel_insert_input {
  data: admin_insert_input;
  on_conflict?: admin_on_conflict | null;
}

/**
 * on_conflict condition type for table "admin"
 */
export interface admin_on_conflict {
  constraint: admin_constraint;
  update_columns: admin_update_column[];
  where?: admin_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "agencies". All fields are combined with a logical 'AND'.
 */
export interface agencies_bool_exp {
  _and?: agencies_bool_exp[] | null;
  _not?: agencies_bool_exp | null;
  _or?: agencies_bool_exp[] | null;
  addressAddress?: jsonb_comparison_exp | null;
  agency_invoices?: agency_invoices_bool_exp | null;
  agency_payments?: agency_payments_bool_exp | null;
  agency_users?: agency_users_bool_exp | null;
  client_changes?: client_changes_bool_exp | null;
  clients?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  developer_changes?: developer_changes_bool_exp | null;
  developers?: developers_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  github_owners?: github_owners_bool_exp | null;
  gitlab_namespaces?: gitlab_namespaces_bool_exp | null;
  id?: String_comparison_exp | null;
  legalName?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  qualified_agencies?: qualified_agencies_bool_exp | null;
  recruiterAgenciesByRecruiterAgencyId?: recruiter_agencies_bool_exp | null;
  recruiter_agencies?: recruiter_agencies_bool_exp | null;
  sid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userDomains?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "agencies"
 */
export interface agencies_insert_input {
  addressAddress?: hasura_jsonb | null;
  agency_invoices?: agency_invoices_arr_rel_insert_input | null;
  agency_payments?: agency_payments_arr_rel_insert_input | null;
  agency_users?: agency_users_arr_rel_insert_input | null;
  client_changes?: client_changes_arr_rel_insert_input | null;
  clients?: clients_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  developer_changes?: developer_changes_arr_rel_insert_input | null;
  developers?: developers_arr_rel_insert_input | null;
  git_users?: git_users_arr_rel_insert_input | null;
  github_accounts?: github_accounts_arr_rel_insert_input | null;
  github_owners?: github_owners_arr_rel_insert_input | null;
  gitlab_namespaces?: gitlab_namespaces_arr_rel_insert_input | null;
  id?: string | null;
  legalName?: string | null;
  name?: string | null;
  qualified_agencies?: qualified_agencies_arr_rel_insert_input | null;
  recruiterAgenciesByRecruiterAgencyId?: recruiter_agencies_arr_rel_insert_input | null;
  recruiter_agencies?: recruiter_agencies_arr_rel_insert_input | null;
  sid?: string | null;
  updatedAt?: hasura_timestamptz | null;
  userDomains?: string | null;
}

/**
 * input type for inserting object relation for remote table "agencies"
 */
export interface agencies_obj_rel_insert_input {
  data: agencies_insert_input;
  on_conflict?: agencies_on_conflict | null;
}

/**
 * on_conflict condition type for table "agencies"
 */
export interface agencies_on_conflict {
  constraint: agencies_constraint;
  update_columns: agencies_update_column[];
  where?: agencies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "agency_invoices"
 */
export interface agency_invoices_arr_rel_insert_input {
  data: agency_invoices_insert_input[];
  on_conflict?: agency_invoices_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "agency_invoices". All fields are combined with a logical 'AND'.
 */
export interface agency_invoices_bool_exp {
  _and?: agency_invoices_bool_exp[] | null;
  _not?: agency_invoices_bool_exp | null;
  _or?: agency_invoices_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_invoices?: user_invoices_bool_exp | null;
}

/**
 * input type for inserting data into table "agency_invoices"
 */
export interface agency_invoices_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dueAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_invoices?: user_invoices_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "agency_invoices"
 */
export interface agency_invoices_obj_rel_insert_input {
  data: agency_invoices_insert_input;
  on_conflict?: agency_invoices_on_conflict | null;
}

/**
 * on_conflict condition type for table "agency_invoices"
 */
export interface agency_invoices_on_conflict {
  constraint: agency_invoices_constraint;
  update_columns: agency_invoices_update_column[];
  where?: agency_invoices_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "agency_payment_types". All fields are combined with a logical 'AND'.
 */
export interface agency_payment_types_bool_exp {
  _and?: agency_payment_types_bool_exp[] | null;
  _not?: agency_payment_types_bool_exp | null;
  _or?: agency_payment_types_bool_exp[] | null;
  agency_payments?: agency_payments_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "agency_payment_types"
 */
export interface agency_payment_types_insert_input {
  agency_payments?: agency_payments_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "agency_payment_types"
 */
export interface agency_payment_types_obj_rel_insert_input {
  data: agency_payment_types_insert_input;
  on_conflict?: agency_payment_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "agency_payment_types"
 */
export interface agency_payment_types_on_conflict {
  constraint: agency_payment_types_constraint;
  update_columns: agency_payment_types_update_column[];
  where?: agency_payment_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "agency_payments"
 */
export interface agency_payments_arr_rel_insert_input {
  data: agency_payments_insert_input[];
  on_conflict?: agency_payments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "agency_payments". All fields are combined with a logical 'AND'.
 */
export interface agency_payments_bool_exp {
  _and?: agency_payments_bool_exp[] | null;
  _not?: agency_payments_bool_exp | null;
  _or?: agency_payments_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  agency_payment_type?: agency_payment_types_bool_exp | null;
  agency_payments_status?: agency_payments_status_bool_exp | null;
  amountInUSD?: float8_comparison_exp | null;
  channelTransactionId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  paymentType?: String_comparison_exp | null;
  status?: agency_payments_status_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "agency_payments"
 */
export interface agency_payments_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  agency_payment_type?: agency_payment_types_obj_rel_insert_input | null;
  agency_payments_status?: agency_payments_status_obj_rel_insert_input | null;
  amountInUSD?: hasura_float8 | null;
  channelTransactionId?: string | null;
  createdAt?: hasura_timestamptz | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  paymentType?: string | null;
  status?: agency_payments_status_enum | null;
}

/**
 * on_conflict condition type for table "agency_payments"
 */
export interface agency_payments_on_conflict {
  constraint: agency_payments_constraint;
  update_columns: agency_payments_update_column[];
  where?: agency_payments_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "agency_payments_status". All fields are combined with a logical 'AND'.
 */
export interface agency_payments_status_bool_exp {
  _and?: agency_payments_status_bool_exp[] | null;
  _not?: agency_payments_status_bool_exp | null;
  _or?: agency_payments_status_bool_exp[] | null;
  agency_payments?: agency_payments_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "agency_payments_status_enum". All fields are combined with logical 'AND'.
 */
export interface agency_payments_status_enum_comparison_exp {
  _eq?: agency_payments_status_enum | null;
  _in?: agency_payments_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: agency_payments_status_enum | null;
  _nin?: agency_payments_status_enum[] | null;
}

/**
 * input type for inserting data into table "agency_payments_status"
 */
export interface agency_payments_status_insert_input {
  agency_payments?: agency_payments_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "agency_payments_status"
 */
export interface agency_payments_status_obj_rel_insert_input {
  data: agency_payments_status_insert_input;
  on_conflict?: agency_payments_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "agency_payments_status"
 */
export interface agency_payments_status_on_conflict {
  constraint: agency_payments_status_constraint;
  update_columns: agency_payments_status_update_column[];
  where?: agency_payments_status_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "agency_user_roles". All fields are combined with a logical 'AND'.
 */
export interface agency_user_roles_bool_exp {
  _and?: agency_user_roles_bool_exp[] | null;
  _not?: agency_user_roles_bool_exp | null;
  _or?: agency_user_roles_bool_exp[] | null;
  agency_users?: agency_users_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "agency_user_roles_enum". All fields are combined with logical 'AND'.
 */
export interface agency_user_roles_enum_comparison_exp {
  _eq?: agency_user_roles_enum | null;
  _in?: agency_user_roles_enum[] | null;
  _is_null?: boolean | null;
  _neq?: agency_user_roles_enum | null;
  _nin?: agency_user_roles_enum[] | null;
}

/**
 * input type for inserting data into table "agency_user_roles"
 */
export interface agency_user_roles_insert_input {
  agency_users?: agency_users_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "agency_user_roles"
 */
export interface agency_user_roles_obj_rel_insert_input {
  data: agency_user_roles_insert_input;
  on_conflict?: agency_user_roles_on_conflict | null;
}

/**
 * on_conflict condition type for table "agency_user_roles"
 */
export interface agency_user_roles_on_conflict {
  constraint: agency_user_roles_constraint;
  update_columns: agency_user_roles_update_column[];
  where?: agency_user_roles_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "agency_users"
 */
export interface agency_users_arr_rel_insert_input {
  data: agency_users_insert_input[];
  on_conflict?: agency_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "agency_users". All fields are combined with a logical 'AND'.
 */
export interface agency_users_bool_exp {
  _and?: agency_users_bool_exp[] | null;
  _not?: agency_users_bool_exp | null;
  _or?: agency_users_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  agency_user_role?: agency_user_roles_bool_exp | null;
  id?: String_comparison_exp | null;
  role?: agency_user_roles_enum_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_changes?: user_changes_bool_exp | null;
}

/**
 * input type for inserting data into table "agency_users"
 */
export interface agency_users_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  agency_user_role?: agency_user_roles_obj_rel_insert_input | null;
  id?: string | null;
  role?: agency_user_roles_enum | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "agency_users"
 */
export interface agency_users_obj_rel_insert_input {
  data: agency_users_insert_input;
  on_conflict?: agency_users_on_conflict | null;
}

/**
 * on_conflict condition type for table "agency_users"
 */
export interface agency_users_on_conflict {
  constraint: agency_users_constraint;
  update_columns: agency_users_update_column[];
  where?: agency_users_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: hasura_bigint | null;
  _gt?: hasura_bigint | null;
  _gte?: hasura_bigint | null;
  _in?: hasura_bigint[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_bigint | null;
  _lte?: hasura_bigint | null;
  _neq?: hasura_bigint | null;
  _nin?: hasura_bigint[] | null;
}

/**
 * input type for inserting array relation for remote table "blockers"
 */
export interface blockers_arr_rel_insert_input {
  data: blockers_insert_input[];
  on_conflict?: blockers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "blockers". All fields are combined with a logical 'AND'.
 */
export interface blockers_bool_exp {
  _and?: blockers_bool_exp[] | null;
  _not?: blockers_bool_exp | null;
  _or?: blockers_bool_exp[] | null;
  id?: bigint_comparison_exp | null;
  inserted_at?: timestamptz_comparison_exp | null;
  pull_request?: pull_requests_bool_exp | null;
  pull_request_estimate?: pull_request_estimates_bool_exp | null;
  pull_request_estimate_id?: bigint_comparison_exp | null;
  pull_request_id?: bigint_comparison_exp | null;
  resolved_at?: timestamptz_comparison_exp | null;
  resolved_by_user_id?: bigint_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_id?: bigint_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "blockers"
 */
export interface blockers_insert_input {
  id?: hasura_bigint | null;
  inserted_at?: hasura_timestamptz | null;
  pull_request?: pull_requests_obj_rel_insert_input | null;
  pull_request_estimate?: pull_request_estimates_obj_rel_insert_input | null;
  pull_request_estimate_id?: hasura_bigint | null;
  pull_request_id?: hasura_bigint | null;
  resolved_at?: hasura_timestamptz | null;
  resolved_by_user_id?: hasura_bigint | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_id?: hasura_bigint | null;
  type?: string | null;
  updated_at?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "blockers"
 */
export interface blockers_on_conflict {
  constraint: blockers_constraint;
  update_columns: blockers_update_column[];
  where?: blockers_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "bonus_types". All fields are combined with a logical 'AND'.
 */
export interface bonus_types_bool_exp {
  _and?: bonus_types_bool_exp[] | null;
  _not?: bonus_types_bool_exp | null;
  _or?: bonus_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  developer_bonus_earnings?: developer_bonus_earning_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "bonus_types_enum". All fields are combined with logical 'AND'.
 */
export interface bonus_types_enum_comparison_exp {
  _eq?: bonus_types_enum | null;
  _in?: bonus_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: bonus_types_enum | null;
  _nin?: bonus_types_enum[] | null;
}

/**
 * input type for inserting data into table "bonus_types"
 */
export interface bonus_types_insert_input {
  description?: string | null;
  developer_bonus_earnings?: developer_bonus_earning_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "bonus_types"
 */
export interface bonus_types_obj_rel_insert_input {
  data: bonus_types_insert_input;
  on_conflict?: bonus_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "bonus_types"
 */
export interface bonus_types_on_conflict {
  constraint: bonus_types_constraint;
  update_columns: bonus_types_update_column[];
  where?: bonus_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "bot_platform_types". All fields are combined with a logical 'AND'.
 */
export interface bot_platform_types_bool_exp {
  _and?: bot_platform_types_bool_exp[] | null;
  _not?: bot_platform_types_bool_exp | null;
  _or?: bot_platform_types_bool_exp[] | null;
  bots?: bots_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "bot_platform_types_enum". All fields are combined with logical 'AND'.
 */
export interface bot_platform_types_enum_comparison_exp {
  _eq?: bot_platform_types_enum | null;
  _in?: bot_platform_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: bot_platform_types_enum | null;
  _nin?: bot_platform_types_enum[] | null;
}

/**
 * input type for inserting data into table "bot_platform_types"
 */
export interface bot_platform_types_insert_input {
  bots?: bots_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "bot_platform_types"
 */
export interface bot_platform_types_obj_rel_insert_input {
  data: bot_platform_types_insert_input;
  on_conflict?: bot_platform_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "bot_platform_types"
 */
export interface bot_platform_types_on_conflict {
  constraint: bot_platform_types_constraint;
  update_columns: bot_platform_types_update_column[];
  where?: bot_platform_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "bots"
 */
export interface bots_arr_rel_insert_input {
  data: bots_insert_input[];
  on_conflict?: bots_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "bots". All fields are combined with a logical 'AND'.
 */
export interface bots_bool_exp {
  _and?: bots_bool_exp[] | null;
  _not?: bots_bool_exp | null;
  _or?: bots_bool_exp[] | null;
  bot_platform_type?: bot_platform_types_bool_exp | null;
  clientBotId?: Int_comparison_exp | null;
  client_bot?: client_bots_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  githubAccountSid?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  gitlabUserById?: gitlab_users_bool_exp | null;
  gitlabUserSid?: String_comparison_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  gitlab_users?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  jiraAccountSid?: String_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  linearAccountSid?: String_comparison_exp | null;
  linear_account?: linear_accounts_bool_exp | null;
  platformId?: bot_platform_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "bots"
 */
export interface bots_insert_input {
  bot_platform_type?: bot_platform_types_obj_rel_insert_input | null;
  clientBotId?: number | null;
  client_bot?: client_bots_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  githubAccountSid?: string | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  gitlabUserById?: gitlab_users_obj_rel_insert_input | null;
  gitlabUserSid?: string | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  gitlab_users?: gitlab_users_arr_rel_insert_input | null;
  id?: number | null;
  jiraAccountSid?: string | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  linearAccountSid?: string | null;
  linear_account?: linear_accounts_obj_rel_insert_input | null;
  platformId?: bot_platform_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "bots"
 */
export interface bots_obj_rel_insert_input {
  data: bots_insert_input;
  on_conflict?: bots_on_conflict | null;
}

/**
 * on_conflict condition type for table "bots"
 */
export interface bots_on_conflict {
  constraint: bots_constraint;
  update_columns: bots_update_column[];
  where?: bots_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "candidate_stage". All fields are combined with a logical 'AND'.
 */
export interface candidate_stage_bool_exp {
  _and?: candidate_stage_bool_exp[] | null;
  _not?: candidate_stage_bool_exp | null;
  _or?: candidate_stage_bool_exp[] | null;
  developer_changes?: developer_changes_bool_exp | null;
  developers?: developers_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "candidate_stage_enum". All fields are combined with logical 'AND'.
 */
export interface candidate_stage_enum_comparison_exp {
  _eq?: candidate_stage_enum | null;
  _in?: candidate_stage_enum[] | null;
  _is_null?: boolean | null;
  _neq?: candidate_stage_enum | null;
  _nin?: candidate_stage_enum[] | null;
}

/**
 * input type for inserting data into table "candidate_stage"
 */
export interface candidate_stage_insert_input {
  developer_changes?: developer_changes_arr_rel_insert_input | null;
  developers?: developers_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "candidate_stage"
 */
export interface candidate_stage_obj_rel_insert_input {
  data: candidate_stage_insert_input;
  on_conflict?: candidate_stage_on_conflict | null;
}

/**
 * on_conflict condition type for table "candidate_stage"
 */
export interface candidate_stage_on_conflict {
  constraint: candidate_stage_constraint;
  update_columns: candidate_stage_update_column[];
  where?: candidate_stage_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "career_ladder_levels". All fields are combined with a logical 'AND'.
 */
export interface career_ladder_levels_bool_exp {
  _and?: career_ladder_levels_bool_exp[] | null;
  _not?: career_ladder_levels_bool_exp | null;
  _or?: career_ladder_levels_bool_exp[] | null;
  description?: String_comparison_exp | null;
  developer_changes?: developer_changes_bool_exp | null;
  developers?: developers_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "career_ladder_levels"
 */
export interface career_ladder_levels_insert_input {
  description?: string | null;
  developer_changes?: developer_changes_arr_rel_insert_input | null;
  developers?: developers_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "career_ladder_levels"
 */
export interface career_ladder_levels_obj_rel_insert_input {
  data: career_ladder_levels_insert_input;
  on_conflict?: career_ladder_levels_on_conflict | null;
}

/**
 * on_conflict condition type for table "career_ladder_levels"
 */
export interface career_ladder_levels_on_conflict {
  constraint: career_ladder_levels_constraint;
  update_columns: career_ladder_levels_update_column[];
  where?: career_ladder_levels_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_apps"
 */
export interface clickup_apps_arr_rel_insert_input {
  data: clickup_apps_insert_input[];
  on_conflict?: clickup_apps_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_apps". All fields are combined with a logical 'AND'.
 */
export interface clickup_apps_bool_exp {
  _and?: clickup_apps_bool_exp[] | null;
  _not?: clickup_apps_bool_exp | null;
  _or?: clickup_apps_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  apiToken?: String_comparison_exp | null;
  authCode?: String_comparison_exp | null;
  clickup_team?: clickup_teams_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientSecret?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhookSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_apps"
 */
export interface clickup_apps_insert_input {
  accessToken?: string | null;
  apiToken?: string | null;
  authCode?: string | null;
  clickup_team?: clickup_teams_obj_rel_insert_input | null;
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
  webhookSid?: string | null;
}

/**
 * on_conflict condition type for table "clickup_apps"
 */
export interface clickup_apps_on_conflict {
  constraint: clickup_apps_constraint;
  update_columns: clickup_apps_update_column[];
  where?: clickup_apps_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_folders"
 */
export interface clickup_folders_arr_rel_insert_input {
  data: clickup_folders_insert_input[];
  on_conflict?: clickup_folders_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_folders". All fields are combined with a logical 'AND'.
 */
export interface clickup_folders_bool_exp {
  _and?: clickup_folders_bool_exp[] | null;
  _not?: clickup_folders_bool_exp | null;
  _or?: clickup_folders_bool_exp[] | null;
  archive?: Boolean_comparison_exp | null;
  clickup_lists?: clickup_lists_bool_exp | null;
  clickup_space?: clickup_spaces_bool_exp | null;
  clickup_team?: clickup_teams_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  hidden?: Boolean_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  orderindex?: Int_comparison_exp | null;
  ovverrideStatuses?: Boolean_comparison_exp | null;
  sid?: String_comparison_exp | null;
  spaceSid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  taskCount?: String_comparison_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_folders"
 */
export interface clickup_folders_insert_input {
  archive?: boolean | null;
  clickup_lists?: clickup_lists_arr_rel_insert_input | null;
  clickup_space?: clickup_spaces_obj_rel_insert_input | null;
  clickup_team?: clickup_teams_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  hidden?: boolean | null;
  id?: number | null;
  name?: string | null;
  orderindex?: number | null;
  ovverrideStatuses?: boolean | null;
  sid?: string | null;
  spaceSid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  taskCount?: string | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "clickup_folders"
 */
export interface clickup_folders_obj_rel_insert_input {
  data: clickup_folders_insert_input;
  on_conflict?: clickup_folders_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_folders"
 */
export interface clickup_folders_on_conflict {
  constraint: clickup_folders_constraint;
  update_columns: clickup_folders_update_column[];
  where?: clickup_folders_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_lists"
 */
export interface clickup_lists_arr_rel_insert_input {
  data: clickup_lists_insert_input[];
  on_conflict?: clickup_lists_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_lists". All fields are combined with a logical 'AND'.
 */
export interface clickup_lists_bool_exp {
  _and?: clickup_lists_bool_exp[] | null;
  _not?: clickup_lists_bool_exp | null;
  _or?: clickup_lists_bool_exp[] | null;
  archive?: Boolean_comparison_exp | null;
  assignee?: String_comparison_exp | null;
  clickup_folder?: clickup_folders_bool_exp | null;
  clickup_space?: clickup_spaces_bool_exp | null;
  clickup_tasks?: clickup_tasks_bool_exp | null;
  clickup_team?: clickup_teams_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dueDate?: timestamptz_comparison_exp | null;
  folderSid?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  orderindex?: Int_comparison_exp | null;
  override_statuses?: Boolean_comparison_exp | null;
  priority?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  spaceSid?: String_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  taskCount?: Int_comparison_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_lists"
 */
export interface clickup_lists_insert_input {
  archive?: boolean | null;
  assignee?: string | null;
  clickup_folder?: clickup_folders_obj_rel_insert_input | null;
  clickup_space?: clickup_spaces_obj_rel_insert_input | null;
  clickup_tasks?: clickup_tasks_arr_rel_insert_input | null;
  clickup_team?: clickup_teams_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dueDate?: hasura_timestamptz | null;
  folderSid?: string | null;
  id?: number | null;
  name?: string | null;
  orderindex?: number | null;
  override_statuses?: boolean | null;
  priority?: string | null;
  sid?: string | null;
  spaceSid?: string | null;
  startDate?: hasura_timestamptz | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  taskCount?: number | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "clickup_lists"
 */
export interface clickup_lists_obj_rel_insert_input {
  data: clickup_lists_insert_input;
  on_conflict?: clickup_lists_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_lists"
 */
export interface clickup_lists_on_conflict {
  constraint: clickup_lists_constraint;
  update_columns: clickup_lists_update_column[];
  where?: clickup_lists_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_spaces"
 */
export interface clickup_spaces_arr_rel_insert_input {
  data: clickup_spaces_insert_input[];
  on_conflict?: clickup_spaces_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_spaces". All fields are combined with a logical 'AND'.
 */
export interface clickup_spaces_bool_exp {
  _and?: clickup_spaces_bool_exp[] | null;
  _not?: clickup_spaces_bool_exp | null;
  _or?: clickup_spaces_bool_exp[] | null;
  archive?: Boolean_comparison_exp | null;
  clickup_folders?: clickup_folders_bool_exp | null;
  clickup_lists?: clickup_lists_bool_exp | null;
  clickup_team?: clickup_teams_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  features?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  multipleAssignees?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  private?: Boolean_comparison_exp | null;
  sid?: String_comparison_exp | null;
  spaceStatuses?: jsonb_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_spaces"
 */
export interface clickup_spaces_insert_input {
  archive?: boolean | null;
  clickup_folders?: clickup_folders_arr_rel_insert_input | null;
  clickup_lists?: clickup_lists_arr_rel_insert_input | null;
  clickup_team?: clickup_teams_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  features?: string | null;
  id?: number | null;
  multipleAssignees?: boolean | null;
  name?: string | null;
  private?: boolean | null;
  sid?: string | null;
  spaceStatuses?: hasura_jsonb | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "clickup_spaces"
 */
export interface clickup_spaces_obj_rel_insert_input {
  data: clickup_spaces_insert_input;
  on_conflict?: clickup_spaces_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_spaces"
 */
export interface clickup_spaces_on_conflict {
  constraint: clickup_spaces_constraint;
  update_columns: clickup_spaces_update_column[];
  where?: clickup_spaces_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_task_comments"
 */
export interface clickup_task_comments_arr_rel_insert_input {
  data: clickup_task_comments_insert_input[];
  on_conflict?: clickup_task_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_task_comments". All fields are combined with a logical 'AND'.
 */
export interface clickup_task_comments_bool_exp {
  _and?: clickup_task_comments_bool_exp[] | null;
  _not?: clickup_task_comments_bool_exp | null;
  _or?: clickup_task_comments_bool_exp[] | null;
  clickup_task?: clickup_tasks_bool_exp | null;
  clickup_user?: clickup_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  taskSid?: String_comparison_exp | null;
  textContent?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_task_comments"
 */
export interface clickup_task_comments_insert_input {
  clickup_task?: clickup_tasks_obj_rel_insert_input | null;
  clickup_user?: clickup_users_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  taskSid?: string | null;
  textContent?: string | null;
  updatedAt?: hasura_timestamptz | null;
  userSid?: string | null;
}

/**
 * on_conflict condition type for table "clickup_task_comments"
 */
export interface clickup_task_comments_on_conflict {
  constraint: clickup_task_comments_constraint;
  update_columns: clickup_task_comments_update_column[];
  where?: clickup_task_comments_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_task_status_types". All fields are combined with a logical 'AND'.
 */
export interface clickup_task_status_types_bool_exp {
  _and?: clickup_task_status_types_bool_exp[] | null;
  _not?: clickup_task_status_types_bool_exp | null;
  _or?: clickup_task_status_types_bool_exp[] | null;
  clickup_tasks?: clickup_tasks_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_task_status_types"
 */
export interface clickup_task_status_types_insert_input {
  clickup_tasks?: clickup_tasks_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "clickup_task_status_types"
 */
export interface clickup_task_status_types_obj_rel_insert_input {
  data: clickup_task_status_types_insert_input;
  on_conflict?: clickup_task_status_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_task_status_types"
 */
export interface clickup_task_status_types_on_conflict {
  constraint: clickup_task_status_types_constraint;
  update_columns: clickup_task_status_types_update_column[];
  where?: clickup_task_status_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_tasks"
 */
export interface clickup_tasks_arr_rel_insert_input {
  data: clickup_tasks_insert_input[];
  on_conflict?: clickup_tasks_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_tasks". All fields are combined with a logical 'AND'.
 */
export interface clickup_tasks_bool_exp {
  _and?: clickup_tasks_bool_exp[] | null;
  _not?: clickup_tasks_bool_exp | null;
  _or?: clickup_tasks_bool_exp[] | null;
  archive?: Boolean_comparison_exp | null;
  clickupTaskAssignedSids?: jsonb_comparison_exp | null;
  clickupTaskWatechedSids?: jsonb_comparison_exp | null;
  clickup_list?: clickup_lists_bool_exp | null;
  clickup_task_comments?: clickup_task_comments_bool_exp | null;
  clickup_task_status_type?: clickup_task_status_types_bool_exp | null;
  clickup_user?: clickup_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creatorSid?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  listSid?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  priority?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  tags?: _text_comparison_exp | null;
  taskStatusType?: String_comparison_exp | null;
  textContent?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_tasks"
 */
export interface clickup_tasks_insert_input {
  archive?: boolean | null;
  clickupTaskAssignedSids?: hasura_jsonb | null;
  clickupTaskWatechedSids?: hasura_jsonb | null;
  clickup_list?: clickup_lists_obj_rel_insert_input | null;
  clickup_task_comments?: clickup_task_comments_arr_rel_insert_input | null;
  clickup_task_status_type?: clickup_task_status_types_obj_rel_insert_input | null;
  clickup_user?: clickup_users_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creatorSid?: string | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  listSid?: string | null;
  name?: string | null;
  priority?: string | null;
  sid?: string | null;
  status?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  tags?: hasura__text | null;
  taskStatusType?: string | null;
  textContent?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "clickup_tasks"
 */
export interface clickup_tasks_obj_rel_insert_input {
  data: clickup_tasks_insert_input;
  on_conflict?: clickup_tasks_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_tasks"
 */
export interface clickup_tasks_on_conflict {
  constraint: clickup_tasks_constraint;
  update_columns: clickup_tasks_update_column[];
  where?: clickup_tasks_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_teams"
 */
export interface clickup_teams_arr_rel_insert_input {
  data: clickup_teams_insert_input[];
  on_conflict?: clickup_teams_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_teams". All fields are combined with a logical 'AND'.
 */
export interface clickup_teams_bool_exp {
  _and?: clickup_teams_bool_exp[] | null;
  _not?: clickup_teams_bool_exp | null;
  _or?: clickup_teams_bool_exp[] | null;
  accessClickupMembers?: jsonb_comparison_exp | null;
  avatar?: String_comparison_exp | null;
  clickup_apps?: clickup_apps_bool_exp | null;
  clickup_folders?: clickup_folders_bool_exp | null;
  clickup_lists?: clickup_lists_bool_exp | null;
  clickup_spaces?: clickup_spaces_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_teams"
 */
export interface clickup_teams_insert_input {
  accessClickupMembers?: hasura_jsonb | null;
  avatar?: string | null;
  clickup_apps?: clickup_apps_arr_rel_insert_input | null;
  clickup_folders?: clickup_folders_arr_rel_insert_input | null;
  clickup_lists?: clickup_lists_arr_rel_insert_input | null;
  clickup_spaces?: clickup_spaces_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "clickup_teams"
 */
export interface clickup_teams_obj_rel_insert_input {
  data: clickup_teams_insert_input;
  on_conflict?: clickup_teams_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_teams"
 */
export interface clickup_teams_on_conflict {
  constraint: clickup_teams_constraint;
  update_columns: clickup_teams_update_column[];
  where?: clickup_teams_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clickup_users"
 */
export interface clickup_users_arr_rel_insert_input {
  data: clickup_users_insert_input[];
  on_conflict?: clickup_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clickup_users". All fields are combined with a logical 'AND'.
 */
export interface clickup_users_bool_exp {
  _and?: clickup_users_bool_exp[] | null;
  _not?: clickup_users_bool_exp | null;
  _or?: clickup_users_bool_exp[] | null;
  clickup_task_comments?: clickup_task_comments_bool_exp | null;
  clickup_tasks?: clickup_tasks_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  initials?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  profilePicture?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "clickup_users"
 */
export interface clickup_users_insert_input {
  clickup_task_comments?: clickup_task_comments_arr_rel_insert_input | null;
  clickup_tasks?: clickup_tasks_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  email?: string | null;
  id?: number | null;
  initials?: string | null;
  name?: string | null;
  profilePicture?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "clickup_users"
 */
export interface clickup_users_obj_rel_insert_input {
  data: clickup_users_insert_input;
  on_conflict?: clickup_users_on_conflict | null;
}

/**
 * on_conflict condition type for table "clickup_users"
 */
export interface clickup_users_on_conflict {
  constraint: clickup_users_constraint;
  update_columns: clickup_users_update_column[];
  where?: clickup_users_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_arr_rel_insert_input {
  data: client_billing_aggregated_structure_insert_input[];
  on_conflict?: client_billing_aggregated_structure_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_billing_aggregated_structure". All fields are combined with a logical 'AND'.
 */
export interface client_billing_aggregated_structure_bool_exp {
  _and?: client_billing_aggregated_structure_bool_exp[] | null;
  _not?: client_billing_aggregated_structure_bool_exp | null;
  _or?: client_billing_aggregated_structure_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientInvoiceId?: Int_comparison_exp | null;
  clientTransactionId?: Int_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  occuredAt?: date_comparison_exp | null;
  status?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientInvoiceId?: number | null;
  clientTransactionId?: number | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  client_transaction?: client_transactions_obj_rel_insert_input | null;
  costInCredits?: number | null;
  occuredAt?: hasura_date | null;
  status?: string | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_obj_rel_insert_input {
  data: client_billing_aggregated_structure_insert_input;
  on_conflict?: client_billing_aggregated_structure_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_on_conflict {
  constraint: client_billing_aggregated_structure_constraint;
  update_columns: client_billing_aggregated_structure_update_column[];
  where?: client_billing_aggregated_structure_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_blocker_status". All fields are combined with a logical 'AND'.
 */
export interface client_blocker_status_bool_exp {
  _and?: client_blocker_status_bool_exp[] | null;
  _not?: client_blocker_status_bool_exp | null;
  _or?: client_blocker_status_bool_exp[] | null;
  client_blockers?: client_blockers_bool_exp | null;
  description?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_blocker_status_enum". All fields are combined with logical 'AND'.
 */
export interface client_blocker_status_enum_comparison_exp {
  _eq?: client_blocker_status_enum | null;
  _in?: client_blocker_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_blocker_status_enum | null;
  _nin?: client_blocker_status_enum[] | null;
}

/**
 * input type for inserting data into table "client_blocker_status"
 */
export interface client_blocker_status_insert_input {
  client_blockers?: client_blockers_arr_rel_insert_input | null;
  description?: string | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_blocker_status"
 */
export interface client_blocker_status_obj_rel_insert_input {
  data: client_blocker_status_insert_input;
  on_conflict?: client_blocker_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_blocker_status"
 */
export interface client_blocker_status_on_conflict {
  constraint: client_blocker_status_constraint;
  update_columns: client_blocker_status_update_column[];
  where?: client_blocker_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_blockers"
 */
export interface client_blockers_arr_rel_insert_input {
  data: client_blockers_insert_input[];
  on_conflict?: client_blockers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_blockers". All fields are combined with a logical 'AND'.
 */
export interface client_blockers_bool_exp {
  _and?: client_blockers_bool_exp[] | null;
  _not?: client_blockers_bool_exp | null;
  _or?: client_blockers_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_blocker_status?: client_blocker_status_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  details?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  projectId?: Int_comparison_exp | null;
  resolvedById?: Int_comparison_exp | null;
  status?: client_blocker_status_enum_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_client_blockers?: task_client_blockers_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_client_blockers?: ticket_client_blockers_bool_exp | null;
  unblockDetails?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_blockers"
 */
export interface client_blockers_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_blocker_status?: client_blocker_status_obj_rel_insert_input | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  details?: string | null;
  id?: number | null;
  projectId?: number | null;
  resolvedById?: number | null;
  status?: client_blocker_status_enum | null;
  summary?: string | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_client_blockers?: task_client_blockers_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_client_blockers?: ticket_client_blockers_arr_rel_insert_input | null;
  unblockDetails?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "client_blockers"
 */
export interface client_blockers_obj_rel_insert_input {
  data: client_blockers_insert_input;
  on_conflict?: client_blockers_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_blockers"
 */
export interface client_blockers_on_conflict {
  constraint: client_blockers_constraint;
  update_columns: client_blockers_update_column[];
  where?: client_blockers_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_bot_types". All fields are combined with a logical 'AND'.
 */
export interface client_bot_types_bool_exp {
  _and?: client_bot_types_bool_exp[] | null;
  _not?: client_bot_types_bool_exp | null;
  _or?: client_bot_types_bool_exp[] | null;
  client_bots?: client_bots_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_bot_types_enum". All fields are combined with logical 'AND'.
 */
export interface client_bot_types_enum_comparison_exp {
  _eq?: client_bot_types_enum | null;
  _in?: client_bot_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_bot_types_enum | null;
  _nin?: client_bot_types_enum[] | null;
}

/**
 * input type for inserting data into table "client_bot_types"
 */
export interface client_bot_types_insert_input {
  client_bots?: client_bots_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_bot_types"
 */
export interface client_bot_types_obj_rel_insert_input {
  data: client_bot_types_insert_input;
  on_conflict?: client_bot_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_bot_types"
 */
export interface client_bot_types_on_conflict {
  constraint: client_bot_types_constraint;
  update_columns: client_bot_types_update_column[];
  where?: client_bot_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_bots"
 */
export interface client_bots_arr_rel_insert_input {
  data: client_bots_insert_input[];
  on_conflict?: client_bots_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_bots". All fields are combined with a logical 'AND'.
 */
export interface client_bots_bool_exp {
  _and?: client_bots_bool_exp[] | null;
  _not?: client_bots_bool_exp | null;
  _or?: client_bots_bool_exp[] | null;
  accessEndpoint?: String_comparison_exp | null;
  bots?: bots_bool_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_bot_type?: client_bot_types_bool_exp | null;
  id?: Int_comparison_exp | null;
  password?: String_comparison_exp | null;
  token?: String_comparison_exp | null;
  typeId?: client_bot_types_enum_comparison_exp | null;
  username?: String_comparison_exp | null;
  verified?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_bots"
 */
export interface client_bots_insert_input {
  accessEndpoint?: string | null;
  bots?: bots_arr_rel_insert_input | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_bot_type?: client_bot_types_obj_rel_insert_input | null;
  id?: number | null;
  password?: string | null;
  token?: string | null;
  typeId?: client_bot_types_enum | null;
  username?: string | null;
  verified?: boolean | null;
}

/**
 * input type for inserting object relation for remote table "client_bots"
 */
export interface client_bots_obj_rel_insert_input {
  data: client_bots_insert_input;
  on_conflict?: client_bots_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_bots"
 */
export interface client_bots_on_conflict {
  constraint: client_bots_constraint;
  update_columns: client_bots_update_column[];
  where?: client_bots_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_changes"
 */
export interface client_changes_arr_rel_insert_input {
  data: client_changes_insert_input[];
  on_conflict?: client_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_changes". All fields are combined with a logical 'AND'.
 */
export interface client_changes_bool_exp {
  _and?: client_changes_bool_exp[] | null;
  _not?: client_changes_bool_exp | null;
  _or?: client_changes_bool_exp[] | null;
  addressAddress?: jsonb_comparison_exp | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  assignedEntityName?: legal_entities_enum_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  billingEmail?: String_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  churnedAt?: timestamptz_comparison_exp | null;
  client?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditBatchSize?: Int_comparison_exp | null;
  defaultCreditBudget?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: String_comparison_exp | null;
  intercomCompanyId?: String_comparison_exp | null;
  legalName?: String_comparison_exp | null;
  legal_entity?: legal_entities_bool_exp | null;
  logoUrl?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  onBoardedAt?: timestamptz_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  primaryPhoneNumber?: String_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  stripeCustomerId?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userDomains?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_changes"
 */
export interface client_changes_insert_input {
  addressAddress?: hasura_jsonb | null;
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  assignedEntityName?: legal_entities_enum | null;
  automaticallyChanged?: boolean | null;
  billingEmail?: string | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  churnedAt?: hasura_timestamptz | null;
  client?: clients_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creditBatchSize?: number | null;
  defaultCreditBudget?: number | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: string | null;
  intercomCompanyId?: string | null;
  legalName?: string | null;
  legal_entity?: legal_entities_obj_rel_insert_input | null;
  logoUrl?: string | null;
  name?: string | null;
  onBoardedAt?: hasura_timestamptz | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  primaryPhoneNumber?: string | null;
  reasonForChange?: string | null;
  sqlQuery?: string | null;
  stripeCustomerId?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userDomains?: string | null;
}

/**
 * on_conflict condition type for table "client_changes"
 */
export interface client_changes_on_conflict {
  constraint: client_changes_constraint;
  update_columns: client_changes_update_column[];
  where?: client_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_daily_standups"
 */
export interface client_daily_standups_arr_rel_insert_input {
  data: client_daily_standups_insert_input[];
  on_conflict?: client_daily_standups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface client_daily_standups_bool_exp {
  _and?: client_daily_standups_bool_exp[] | null;
  _not?: client_daily_standups_bool_exp | null;
  _or?: client_daily_standups_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  errorMessage?: String_comparison_exp | null;
  erroredAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  messageForClient?: String_comparison_exp | null;
  postedToClient?: Boolean_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  summary?: String_comparison_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * input type for inserting data into table "client_daily_standups"
 */
export interface client_daily_standups_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  errorMessage?: string | null;
  erroredAt?: hasura_timestamptz | null;
  id?: number | null;
  messageForClient?: string | null;
  postedToClient?: boolean | null;
  project_daily_standups?: project_daily_standup_arr_rel_insert_input | null;
  submittedAt?: string | null;
  summarizedInUserDailyStandupId?: number | null;
  summary?: string | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_daily_standup?: user_daily_standups_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "client_daily_standups"
 */
export interface client_daily_standups_obj_rel_insert_input {
  data: client_daily_standups_insert_input;
  on_conflict?: client_daily_standups_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_daily_standups"
 */
export interface client_daily_standups_on_conflict {
  constraint: client_daily_standups_constraint;
  update_columns: client_daily_standups_update_column[];
  where?: client_daily_standups_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_feature_flags"
 */
export interface client_feature_flags_arr_rel_insert_input {
  data: client_feature_flags_insert_input[];
  on_conflict?: client_feature_flags_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_feature_flags". All fields are combined with a logical 'AND'.
 */
export interface client_feature_flags_bool_exp {
  _and?: client_feature_flags_bool_exp[] | null;
  _not?: client_feature_flags_bool_exp | null;
  _or?: client_feature_flags_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  featureFlagId?: Int_comparison_exp | null;
  feature_flag?: feature_flags_bool_exp | null;
  id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_feature_flags"
 */
export interface client_feature_flags_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  featureFlagId?: number | null;
  feature_flag?: feature_flags_obj_rel_insert_input | null;
  id?: number | null;
}

/**
 * on_conflict condition type for table "client_feature_flags"
 */
export interface client_feature_flags_on_conflict {
  constraint: client_feature_flags_constraint;
  update_columns: client_feature_flags_update_column[];
  where?: client_feature_flags_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_invoice_changes"
 */
export interface client_invoice_changes_arr_rel_insert_input {
  data: client_invoice_changes_insert_input[];
  on_conflict?: client_invoice_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_invoice_changes". All fields are combined with a logical 'AND'.
 */
export interface client_invoice_changes_bool_exp {
  _and?: client_invoice_changes_bool_exp[] | null;
  _not?: client_invoice_changes_bool_exp | null;
  _or?: client_invoice_changes_bool_exp[] | null;
  associatedTransactionId?: Int_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  isTopUp?: Boolean_comparison_exp | null;
  maxBudgetInCredits?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_invoice_changes"
 */
export interface client_invoice_changes_insert_input {
  associatedTransactionId?: number | null;
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  client_transaction?: client_transactions_obj_rel_insert_input | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  creditPricingPlanId?: number | null;
  credit_pricing_plan?: credit_pricing_plans_obj_rel_insert_input | null;
  deletedAt?: hasura_timestamptz | null;
  dueAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  isTopUp?: boolean | null;
  maxBudgetInCredits?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  sqlQuery?: string | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "client_invoice_changes"
 */
export interface client_invoice_changes_on_conflict {
  constraint: client_invoice_changes_constraint;
  update_columns: client_invoice_changes_update_column[];
  where?: client_invoice_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_arr_rel_insert_input {
  data: client_invoice_time_user_totals_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "client_invoice_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface client_invoice_time_user_totals_bool_exp {
  _and?: client_invoice_time_user_totals_bool_exp[] | null;
  _not?: client_invoice_time_user_totals_bool_exp | null;
  _or?: client_invoice_time_user_totals_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  invoiceDueAt?: timestamptz_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  invoiceDueAt?: hasura_timestamptz | null;
  invoiceId?: number | null;
  totalOrganizedTimeInSecs?: hasura_bigint | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting array relation for remote table "client_invoices"
 */
export interface client_invoices_arr_rel_insert_input {
  data: client_invoices_insert_input[];
  on_conflict?: client_invoices_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_invoices". All fields are combined with a logical 'AND'.
 */
export interface client_invoices_bool_exp {
  _and?: client_invoices_bool_exp[] | null;
  _not?: client_invoices_bool_exp | null;
  _or?: client_invoices_bool_exp[] | null;
  associatedTransactionId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_invoice_balance?: client_invoices_with_balance_bool_exp | null;
  client_invoice_changes?: client_invoice_changes_bool_exp | null;
  client_invoice_time_user_totals?: client_invoice_time_user_totals_bool_exp | null;
  client_tickets?: client_tickets_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  isTopUp?: Boolean_comparison_exp | null;
  maxBudgetInCredits?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_invoices"
 */
export interface client_invoices_insert_input {
  associatedTransactionId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_obj_rel_insert_input | null;
  client_invoice_balance?: client_invoices_with_balance_obj_rel_insert_input | null;
  client_invoice_changes?: client_invoice_changes_arr_rel_insert_input | null;
  client_invoice_time_user_totals?: client_invoice_time_user_totals_arr_rel_insert_input | null;
  client_tickets?: client_tickets_arr_rel_insert_input | null;
  client_transaction?: client_transactions_obj_rel_insert_input | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  creditPricingPlanId?: number | null;
  credit_pricing_plan?: credit_pricing_plans_obj_rel_insert_input | null;
  deletedAt?: hasura_timestamptz | null;
  dueAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  isTopUp?: boolean | null;
  maxBudgetInCredits?: number | null;
  startAt?: hasura_timestamptz | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "client_invoices"
 */
export interface client_invoices_obj_rel_insert_input {
  data: client_invoices_insert_input;
  on_conflict?: client_invoices_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_invoices"
 */
export interface client_invoices_on_conflict {
  constraint: client_invoices_constraint;
  update_columns: client_invoices_update_column[];
  where?: client_invoices_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_arr_rel_insert_input {
  data: client_invoices_with_balance_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "client_invoices_with_balance". All fields are combined with a logical 'AND'.
 */
export interface client_invoices_with_balance_bool_exp {
  _and?: client_invoices_with_balance_bool_exp[] | null;
  _not?: client_invoices_with_balance_bool_exp | null;
  _or?: client_invoices_with_balance_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_tickets?: client_tickets_bool_exp | null;
  consumed_balance_in_credits?: bigint_comparison_exp | null;
  consumed_balance_in_credits_undiscounted?: bigint_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  currrent_paid_balance_in_usd?: bigint_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  maxBudgetInCredits?: Int_comparison_exp | null;
  previous_balance_in_credits?: bigint_comparison_exp | null;
  previous_balance_in_usd?: bigint_comparison_exp | null;
  previous_consumed_balance_in_credits?: bigint_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  client_tickets?: client_tickets_arr_rel_insert_input | null;
  consumed_balance_in_credits?: hasura_bigint | null;
  consumed_balance_in_credits_undiscounted?: hasura_bigint | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  currrent_paid_balance_in_usd?: hasura_bigint | null;
  dueAt?: hasura_timestamptz | null;
  id?: number | null;
  maxBudgetInCredits?: number | null;
  previous_balance_in_credits?: hasura_bigint | null;
  previous_balance_in_usd?: hasura_bigint | null;
  previous_consumed_balance_in_credits?: hasura_bigint | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_obj_rel_insert_input {
  data: client_invoices_with_balance_insert_input;
}

/**
 * input type for inserting array relation for remote table "client_jobs"
 */
export interface client_jobs_arr_rel_insert_input {
  data: client_jobs_insert_input[];
  on_conflict?: client_jobs_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_jobs". All fields are combined with a logical 'AND'.
 */
export interface client_jobs_bool_exp {
  _and?: client_jobs_bool_exp[] | null;
  _not?: client_jobs_bool_exp | null;
  _or?: client_jobs_bool_exp[] | null;
  canSponsorVisa?: Boolean_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  earliestStartDate?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  isRemote?: Boolean_comparison_exp | null;
  linkToJobDescription?: String_comparison_exp | null;
  maxAnnualSalary?: Int_comparison_exp | null;
  minQualifiedRank?: Int_comparison_exp | null;
  optional_technologies?: optional_technologies_bool_exp | null;
  qualifiedCities?: String_comparison_exp | null;
  qualifiedCountries?: String_comparison_exp | null;
  qualifiedTimezoneOverlap?: Int_comparison_exp | null;
  qualified_technologies?: qualified_technologies_bool_exp | null;
  totalPlacements?: Int_comparison_exp | null;
  workTimeZone?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_jobs"
 */
export interface client_jobs_insert_input {
  canSponsorVisa?: boolean | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  earliestStartDate?: hasura_timestamptz | null;
  id?: hasura_uuid | null;
  isRemote?: boolean | null;
  linkToJobDescription?: string | null;
  maxAnnualSalary?: number | null;
  minQualifiedRank?: number | null;
  optional_technologies?: optional_technologies_arr_rel_insert_input | null;
  qualifiedCities?: string | null;
  qualifiedCountries?: string | null;
  qualifiedTimezoneOverlap?: number | null;
  qualified_technologies?: qualified_technologies_arr_rel_insert_input | null;
  totalPlacements?: number | null;
  workTimeZone?: number | null;
}

/**
 * input type for inserting object relation for remote table "client_jobs"
 */
export interface client_jobs_obj_rel_insert_input {
  data: client_jobs_insert_input;
  on_conflict?: client_jobs_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_jobs"
 */
export interface client_jobs_on_conflict {
  constraint: client_jobs_constraint;
  update_columns: client_jobs_update_column[];
  where?: client_jobs_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_managers_view"
 */
export interface client_managers_view_arr_rel_insert_input {
  data: client_managers_view_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "client_managers_view". All fields are combined with a logical 'AND'.
 */
export interface client_managers_view_bool_exp {
  _and?: client_managers_view_bool_exp[] | null;
  _not?: client_managers_view_bool_exp | null;
  _or?: client_managers_view_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  isActive?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_managers_view"
 */
export interface client_managers_view_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  isActive?: string | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting array relation for remote table "client_pricing_plans"
 */
export interface client_pricing_plans_arr_rel_insert_input {
  data: client_pricing_plans_insert_input[];
  on_conflict?: client_pricing_plans_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface client_pricing_plans_bool_exp {
  _and?: client_pricing_plans_bool_exp[] | null;
  _not?: client_pricing_plans_bool_exp | null;
  _or?: client_pricing_plans_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_pricing_plans"
 */
export interface client_pricing_plans_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  creditPricingPlanId?: number | null;
  credit_pricing_plan?: credit_pricing_plans_obj_rel_insert_input | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "client_pricing_plans"
 */
export interface client_pricing_plans_on_conflict {
  constraint: client_pricing_plans_constraint;
  update_columns: client_pricing_plans_update_column[];
  where?: client_pricing_plans_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_project_changes"
 */
export interface client_project_changes_arr_rel_insert_input {
  data: client_project_changes_insert_input[];
  on_conflict?: client_project_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_changes". All fields are combined with a logical 'AND'.
 */
export interface client_project_changes_bool_exp {
  _and?: client_project_changes_bool_exp[] | null;
  _not?: client_project_changes_bool_exp | null;
  _or?: client_project_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  internalSlackChannelSid?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  repositoriesId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
  type?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByChangedbyuserid?: users_bool_exp | null;
  user_team_id?: bigint_comparison_exp | null;
  usersId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_project_changes"
 */
export interface client_project_changes_insert_input {
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  id?: number | null;
  internalSlackChannelSid?: string | null;
  name?: string | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  repositoriesId?: number | null;
  sqlQuery?: string | null;
  technologiesId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
  type?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByChangedbyuserid?: users_obj_rel_insert_input | null;
  user_team_id?: hasura_bigint | null;
  usersId?: number | null;
}

/**
 * on_conflict condition type for table "client_project_changes"
 */
export interface client_project_changes_on_conflict {
  constraint: client_project_changes_constraint;
  update_columns: client_project_changes_update_column[];
  where?: client_project_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_project_managers"
 */
export interface client_project_managers_arr_rel_insert_input {
  data: client_project_managers_insert_input[];
  on_conflict?: client_project_managers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_managers". All fields are combined with a logical 'AND'.
 */
export interface client_project_managers_bool_exp {
  _and?: client_project_managers_bool_exp[] | null;
  _not?: client_project_managers_bool_exp | null;
  _or?: client_project_managers_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isShadow?: Boolean_comparison_exp | null;
  projectManagerUserId?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_project_managers"
 */
export interface client_project_managers_insert_input {
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  isShadow?: boolean | null;
  projectManagerUserId?: number | null;
  startAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "client_project_managers"
 */
export interface client_project_managers_on_conflict {
  constraint: client_project_managers_constraint;
  update_columns: client_project_managers_update_column[];
  where?: client_project_managers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_arr_rel_insert_input {
  data: client_project_pricing_plans_insert_input[];
  on_conflict?: client_project_pricing_plans_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface client_project_pricing_plans_bool_exp {
  _and?: client_project_pricing_plans_bool_exp[] | null;
  _not?: client_project_pricing_plans_bool_exp | null;
  _or?: client_project_pricing_plans_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_insert_input {
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creditPricingPlanId?: number | null;
  credit_pricing_plan?: credit_pricing_plans_obj_rel_insert_input | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_on_conflict {
  constraint: client_project_pricing_plans_constraint;
  update_columns: client_project_pricing_plans_update_column[];
  where?: client_project_pricing_plans_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_project_repositories"
 */
export interface client_project_repositories_arr_rel_insert_input {
  data: client_project_repositories_insert_input[];
  on_conflict?: client_project_repositories_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_repositories". All fields are combined with a logical 'AND'.
 */
export interface client_project_repositories_bool_exp {
  _and?: client_project_repositories_bool_exp[] | null;
  _not?: client_project_repositories_bool_exp | null;
  _or?: client_project_repositories_bool_exp[] | null;
  clientProjectsId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  gitReposId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
}

/**
 * input type for inserting data into table "client_project_repositories"
 */
export interface client_project_repositories_insert_input {
  clientProjectsId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  gitReposId?: number | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "client_project_repositories"
 */
export interface client_project_repositories_on_conflict {
  constraint: client_project_repositories_constraint;
  update_columns: client_project_repositories_update_column[];
  where?: client_project_repositories_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_project_technologies"
 */
export interface client_project_technologies_arr_rel_insert_input {
  data: client_project_technologies_insert_input[];
  on_conflict?: client_project_technologies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_technologies". All fields are combined with a logical 'AND'.
 */
export interface client_project_technologies_bool_exp {
  _and?: client_project_technologies_bool_exp[] | null;
  _not?: client_project_technologies_bool_exp | null;
  _or?: client_project_technologies_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  projectId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
  technologyId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_project_technologies"
 */
export interface client_project_technologies_insert_input {
  client_project?: client_projects_obj_rel_insert_input | null;
  projectId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
  technologyId?: number | null;
}

/**
 * on_conflict condition type for table "client_project_technologies"
 */
export interface client_project_technologies_on_conflict {
  constraint: client_project_technologies_constraint;
  update_columns: client_project_technologies_update_column[];
  where?: client_project_technologies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_project_users"
 */
export interface client_project_users_arr_rel_insert_input {
  data: client_project_users_insert_input[];
  on_conflict?: client_project_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_users". All fields are combined with a logical 'AND'.
 */
export interface client_project_users_bool_exp {
  _and?: client_project_users_bool_exp[] | null;
  _not?: client_project_users_bool_exp | null;
  _or?: client_project_users_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  projects?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  users?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_project_users"
 */
export interface client_project_users_insert_input {
  client_project?: client_projects_obj_rel_insert_input | null;
  projects?: number | null;
  user?: users_obj_rel_insert_input | null;
  users?: number | null;
}

/**
 * on_conflict condition type for table "client_project_users"
 */
export interface client_project_users_on_conflict {
  constraint: client_project_users_constraint;
  update_columns: client_project_users_update_column[];
  where?: client_project_users_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_projects"
 */
export interface client_projects_arr_rel_insert_input {
  data: client_projects_insert_input[];
  on_conflict?: client_projects_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_projects". All fields are combined with a logical 'AND'.
 */
export interface client_projects_bool_exp {
  _and?: client_projects_bool_exp[] | null;
  _not?: client_projects_bool_exp | null;
  _or?: client_projects_bool_exp[] | null;
  activities?: activities_bool_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_project_changes?: client_project_changes_bool_exp | null;
  client_project_managers?: client_project_managers_bool_exp | null;
  client_project_pricing_plans?: client_project_pricing_plans_bool_exp | null;
  client_project_repositories?: client_project_repositories_bool_exp | null;
  client_project_technologies?: client_project_technologies_bool_exp | null;
  client_project_users?: client_project_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  git_repo_slice_changes?: git_repo_slice_changes_bool_exp | null;
  git_repo_slices?: git_repo_slices_bool_exp | null;
  id?: Int_comparison_exp | null;
  internalSlackChannelSid?: String_comparison_exp | null;
  jira_projects?: jira_projects_bool_exp | null;
  name?: String_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  project_managers?: project_managers_bool_exp | null;
  reviews?: reviews_bool_exp | null;
  slack_channel?: slack_channels_bool_exp | null;
  slack_standup_channel_mapping?: slack_standup_channel_mappings_bool_exp | null;
  slack_standup_channel_mappings?: slack_standup_channel_mappings_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
  user_team?: user_teams_bool_exp | null;
  user_team_client?: user_team_clients_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_team_id?: bigint_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "client_projects"
 */
export interface client_projects_insert_input {
  activities?: activities_arr_rel_insert_input | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_blockers?: client_blockers_arr_rel_insert_input | null;
  client_project_changes?: client_project_changes_arr_rel_insert_input | null;
  client_project_managers?: client_project_managers_arr_rel_insert_input | null;
  client_project_pricing_plans?: client_project_pricing_plans_arr_rel_insert_input | null;
  client_project_repositories?: client_project_repositories_arr_rel_insert_input | null;
  client_project_technologies?: client_project_technologies_arr_rel_insert_input | null;
  client_project_users?: client_project_users_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  git_repo_slice_changes?: git_repo_slice_changes_arr_rel_insert_input | null;
  git_repo_slices?: git_repo_slices_arr_rel_insert_input | null;
  id?: number | null;
  internalSlackChannelSid?: string | null;
  jira_projects?: jira_projects_arr_rel_insert_input | null;
  name?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
  project_daily_standups?: project_daily_standup_arr_rel_insert_input | null;
  project_managers?: project_managers_arr_rel_insert_input | null;
  reviews?: reviews_arr_rel_insert_input | null;
  slack_channel?: slack_channels_obj_rel_insert_input | null;
  slack_standup_channel_mapping?: slack_standup_channel_mappings_obj_rel_insert_input | null;
  slack_standup_channel_mappings?: slack_standup_channel_mappings_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  type?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_team?: user_teams_obj_rel_insert_input | null;
  user_team_client?: user_team_clients_obj_rel_insert_input | null;
  user_team_clients?: user_team_clients_arr_rel_insert_input | null;
  user_team_id?: hasura_bigint | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "client_projects"
 */
export interface client_projects_obj_rel_insert_input {
  data: client_projects_insert_input;
  on_conflict?: client_projects_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_projects"
 */
export interface client_projects_on_conflict {
  constraint: client_projects_constraint;
  update_columns: client_projects_update_column[];
  where?: client_projects_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface client_standups_sla_view_bool_exp {
  _and?: client_standups_sla_view_bool_exp[] | null;
  _not?: client_standups_sla_view_bool_exp | null;
  _or?: client_standups_sla_view_bool_exp[] | null;
  assigneeId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_standups_sla_view"
 */
export interface client_standups_sla_view_insert_input {
  assigneeId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  dueAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "client_standups_sla_view"
 */
export interface client_standups_sla_view_obj_rel_insert_input {
  data: client_standups_sla_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "client_tickets"
 */
export interface client_tickets_arr_rel_insert_input {
  data: client_tickets_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "client_tickets". All fields are combined with a logical 'AND'.
 */
export interface client_tickets_bool_exp {
  _and?: client_tickets_bool_exp[] | null;
  _not?: client_tickets_bool_exp | null;
  _or?: client_tickets_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  code?: String_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discount?: Int_comparison_exp | null;
  discountedCostInCredits?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketLink?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_tickets"
 */
export interface client_tickets_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  code?: string | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  discount?: number | null;
  discountedCostInCredits?: number | null;
  id?: number | null;
  invoiceId?: number | null;
  status?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketLink?: string | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * Boolean expression to filter rows from the table "client_transaction_status". All fields are combined with a logical 'AND'.
 */
export interface client_transaction_status_bool_exp {
  _and?: client_transaction_status_bool_exp[] | null;
  _not?: client_transaction_status_bool_exp | null;
  _or?: client_transaction_status_bool_exp[] | null;
  client_transactions?: client_transactions_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_transaction_status_enum". All fields are combined with logical 'AND'.
 */
export interface client_transaction_status_enum_comparison_exp {
  _eq?: client_transaction_status_enum | null;
  _in?: client_transaction_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_transaction_status_enum | null;
  _nin?: client_transaction_status_enum[] | null;
}

/**
 * input type for inserting data into table "client_transaction_status"
 */
export interface client_transaction_status_insert_input {
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_transaction_status"
 */
export interface client_transaction_status_obj_rel_insert_input {
  data: client_transaction_status_insert_input;
  on_conflict?: client_transaction_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_transaction_status"
 */
export interface client_transaction_status_on_conflict {
  constraint: client_transaction_status_constraint;
  update_columns: client_transaction_status_update_column[];
  where?: client_transaction_status_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_transaction_types". All fields are combined with a logical 'AND'.
 */
export interface client_transaction_types_bool_exp {
  _and?: client_transaction_types_bool_exp[] | null;
  _not?: client_transaction_types_bool_exp | null;
  _or?: client_transaction_types_bool_exp[] | null;
  client_transactions?: client_transactions_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_transaction_types_enum". All fields are combined with logical 'AND'.
 */
export interface client_transaction_types_enum_comparison_exp {
  _eq?: client_transaction_types_enum | null;
  _in?: client_transaction_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_transaction_types_enum | null;
  _nin?: client_transaction_types_enum[] | null;
}

/**
 * input type for inserting data into table "client_transaction_types"
 */
export interface client_transaction_types_insert_input {
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_transaction_types"
 */
export interface client_transaction_types_obj_rel_insert_input {
  data: client_transaction_types_insert_input;
  on_conflict?: client_transaction_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_transaction_types"
 */
export interface client_transaction_types_on_conflict {
  constraint: client_transaction_types_constraint;
  update_columns: client_transaction_types_update_column[];
  where?: client_transaction_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_transactions"
 */
export interface client_transactions_arr_rel_insert_input {
  data: client_transactions_insert_input[];
  on_conflict?: client_transactions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_transactions". All fields are combined with a logical 'AND'.
 */
export interface client_transactions_bool_exp {
  _and?: client_transactions_bool_exp[] | null;
  _not?: client_transactions_bool_exp | null;
  _or?: client_transactions_bool_exp[] | null;
  channelTransactionId?: String_comparison_exp | null;
  channelType?: client_transaction_types_enum_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_invoice_changes?: client_invoice_changes_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_transaction_status?: client_transaction_status_bool_exp | null;
  client_transaction_type?: client_transaction_types_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  initiatedByUserId?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  status?: client_transaction_status_enum_comparison_exp | null;
  stripeIntentSecret?: String_comparison_exp | null;
  transactedAt?: timestamptz_comparison_exp | null;
  transactionError?: String_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_transactions"
 */
export interface client_transactions_insert_input {
  channelTransactionId?: string | null;
  channelType?: client_transaction_types_enum | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_obj_rel_insert_input | null;
  client_invoice_changes?: client_invoice_changes_arr_rel_insert_input | null;
  client_invoices?: client_invoices_arr_rel_insert_input | null;
  client_transaction_status?: client_transaction_status_obj_rel_insert_input | null;
  client_transaction_type?: client_transaction_types_obj_rel_insert_input | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  creditPricingPlanId?: number | null;
  credit_pricing_plan?: credit_pricing_plans_obj_rel_insert_input | null;
  dueAt?: hasura_timestamptz | null;
  id?: number | null;
  initiatedByUserId?: number | null;
  invoiceCode?: string | null;
  status?: client_transaction_status_enum | null;
  stripeIntentSecret?: string | null;
  transactedAt?: hasura_timestamptz | null;
  transactionError?: string | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "client_transactions"
 */
export interface client_transactions_obj_rel_insert_input {
  data: client_transactions_insert_input;
  on_conflict?: client_transactions_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_transactions"
 */
export interface client_transactions_on_conflict {
  constraint: client_transactions_constraint;
  update_columns: client_transactions_update_column[];
  where?: client_transactions_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_user_positions". All fields are combined with a logical 'AND'.
 */
export interface client_user_positions_bool_exp {
  _and?: client_user_positions_bool_exp[] | null;
  _not?: client_user_positions_bool_exp | null;
  _or?: client_user_positions_bool_exp[] | null;
  client_users?: client_users_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_user_positions"
 */
export interface client_user_positions_insert_input {
  client_users?: client_users_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_user_positions"
 */
export interface client_user_positions_obj_rel_insert_input {
  data: client_user_positions_insert_input;
  on_conflict?: client_user_positions_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_user_positions"
 */
export interface client_user_positions_on_conflict {
  constraint: client_user_positions_constraint;
  update_columns: client_user_positions_update_column[];
  where?: client_user_positions_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "client_user_roles". All fields are combined with a logical 'AND'.
 */
export interface client_user_roles_bool_exp {
  _and?: client_user_roles_bool_exp[] | null;
  _not?: client_user_roles_bool_exp | null;
  _or?: client_user_roles_bool_exp[] | null;
  client_users?: client_users_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_user_roles_enum". All fields are combined with logical 'AND'.
 */
export interface client_user_roles_enum_comparison_exp {
  _eq?: client_user_roles_enum | null;
  _in?: client_user_roles_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_user_roles_enum | null;
  _nin?: client_user_roles_enum[] | null;
}

/**
 * input type for inserting data into table "client_user_roles"
 */
export interface client_user_roles_insert_input {
  client_users?: client_users_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "client_user_roles"
 */
export interface client_user_roles_obj_rel_insert_input {
  data: client_user_roles_insert_input;
  on_conflict?: client_user_roles_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_user_roles"
 */
export interface client_user_roles_on_conflict {
  constraint: client_user_roles_constraint;
  update_columns: client_user_roles_update_column[];
  where?: client_user_roles_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "client_users"
 */
export interface client_users_arr_rel_insert_input {
  data: client_users_insert_input[];
  on_conflict?: client_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "client_users". All fields are combined with a logical 'AND'.
 */
export interface client_users_bool_exp {
  _and?: client_users_bool_exp[] | null;
  _not?: client_users_bool_exp | null;
  _or?: client_users_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_user_position?: client_user_positions_bool_exp | null;
  client_user_role?: client_user_roles_bool_exp | null;
  id?: uuid_comparison_exp | null;
  position?: String_comparison_exp | null;
  role?: client_user_roles_enum_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_changes?: user_changes_bool_exp | null;
}

/**
 * input type for inserting data into table "client_users"
 */
export interface client_users_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_user_position?: client_user_positions_obj_rel_insert_input | null;
  client_user_role?: client_user_roles_obj_rel_insert_input | null;
  id?: hasura_uuid | null;
  position?: string | null;
  role?: client_user_roles_enum | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "client_users"
 */
export interface client_users_obj_rel_insert_input {
  data: client_users_insert_input;
  on_conflict?: client_users_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_users"
 */
export interface client_users_on_conflict {
  constraint: client_users_constraint;
  update_columns: client_users_update_column[];
  where?: client_users_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "clients"
 */
export interface clients_arr_rel_insert_input {
  data: clients_insert_input[];
  on_conflict?: clients_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'.
 */
export interface clients_bool_exp {
  _and?: clients_bool_exp[] | null;
  _not?: clients_bool_exp | null;
  _or?: clients_bool_exp[] | null;
  addressAddress?: jsonb_comparison_exp | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  assignedEntityName?: legal_entities_enum_comparison_exp | null;
  billingEmail?: String_comparison_exp | null;
  churnedAt?: timestamptz_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_bots?: client_bots_bool_exp | null;
  client_changes?: client_changes_bool_exp | null;
  client_daily_standups?: client_daily_standups_bool_exp | null;
  client_invoice_changes?: client_invoice_changes_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_invoices_with_balance?: client_invoices_with_balance_bool_exp | null;
  client_jobs?: client_jobs_bool_exp | null;
  client_managers_view?: client_managers_view_bool_exp | null;
  client_pricing_plans?: client_pricing_plans_bool_exp | null;
  client_project_changes?: client_project_changes_bool_exp | null;
  client_projects?: client_projects_bool_exp | null;
  client_standups_sla?: client_standups_sla_view_bool_exp | null;
  client_tickets?: client_tickets_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  client_users?: client_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditBatchSize?: Int_comparison_exp | null;
  defaultCreditBudget?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  feature_flags?: client_feature_flags_bool_exp | null;
  git_organizations?: git_organizations_bool_exp | null;
  git_repo_changes?: git_repo_changes_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  github_owners?: github_owners_bool_exp | null;
  gitlab_namespaces?: gitlab_namespaces_bool_exp | null;
  id?: String_comparison_exp | null;
  intercomCompanyId?: String_comparison_exp | null;
  jira_app_installations?: jira_app_installations_bool_exp | null;
  jira_organization?: jira_organizations_bool_exp | null;
  jira_organizations?: jira_organizations_bool_exp | null;
  legalName?: String_comparison_exp | null;
  legal_entity?: legal_entities_bool_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  linear_teams?: linear_teams_bool_exp | null;
  logoUrl?: String_comparison_exp | null;
  meetings?: meetings_bool_exp | null;
  name?: String_comparison_exp | null;
  onBoardedAt?: timestamptz_comparison_exp | null;
  primaryPhoneNumber?: String_comparison_exp | null;
  project_managers?: project_managers_bool_exp | null;
  qualified_agencies?: qualified_agencies_bool_exp | null;
  reviews?: reviews_bool_exp | null;
  slack_standup_channel_mappings?: slack_standup_channel_mappings_bool_exp | null;
  slack_teams?: slack_teams_bool_exp | null;
  stripeCustomerId?: String_comparison_exp | null;
  task_changes?: task_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userDomains?: String_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "clients"
 */
export interface clients_insert_input {
  addressAddress?: hasura_jsonb | null;
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  assignedEntityName?: legal_entities_enum | null;
  billingEmail?: string | null;
  churnedAt?: hasura_timestamptz | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_arr_rel_insert_input | null;
  client_blockers?: client_blockers_arr_rel_insert_input | null;
  client_bots?: client_bots_arr_rel_insert_input | null;
  client_changes?: client_changes_arr_rel_insert_input | null;
  client_daily_standups?: client_daily_standups_arr_rel_insert_input | null;
  client_invoice_changes?: client_invoice_changes_arr_rel_insert_input | null;
  client_invoices?: client_invoices_arr_rel_insert_input | null;
  client_invoices_with_balance?: client_invoices_with_balance_arr_rel_insert_input | null;
  client_jobs?: client_jobs_arr_rel_insert_input | null;
  client_managers_view?: client_managers_view_arr_rel_insert_input | null;
  client_pricing_plans?: client_pricing_plans_arr_rel_insert_input | null;
  client_project_changes?: client_project_changes_arr_rel_insert_input | null;
  client_projects?: client_projects_arr_rel_insert_input | null;
  client_standups_sla?: client_standups_sla_view_obj_rel_insert_input | null;
  client_tickets?: client_tickets_arr_rel_insert_input | null;
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  client_users?: client_users_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creditBatchSize?: number | null;
  defaultCreditBudget?: number | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  feature_flags?: client_feature_flags_arr_rel_insert_input | null;
  git_organizations?: git_organizations_arr_rel_insert_input | null;
  git_repo_changes?: git_repo_changes_arr_rel_insert_input | null;
  git_repos?: git_repos_arr_rel_insert_input | null;
  github_owners?: github_owners_arr_rel_insert_input | null;
  gitlab_namespaces?: gitlab_namespaces_arr_rel_insert_input | null;
  id?: string | null;
  intercomCompanyId?: string | null;
  jira_app_installations?: jira_app_installations_arr_rel_insert_input | null;
  jira_organization?: jira_organizations_obj_rel_insert_input | null;
  jira_organizations?: jira_organizations_arr_rel_insert_input | null;
  legalName?: string | null;
  legal_entity?: legal_entities_obj_rel_insert_input | null;
  linear_team?: linear_teams_obj_rel_insert_input | null;
  linear_teams?: linear_teams_arr_rel_insert_input | null;
  logoUrl?: string | null;
  meetings?: meetings_arr_rel_insert_input | null;
  name?: string | null;
  onBoardedAt?: hasura_timestamptz | null;
  primaryPhoneNumber?: string | null;
  project_managers?: project_managers_arr_rel_insert_input | null;
  qualified_agencies?: qualified_agencies_arr_rel_insert_input | null;
  reviews?: reviews_arr_rel_insert_input | null;
  slack_standup_channel_mappings?: slack_standup_channel_mappings_arr_rel_insert_input | null;
  slack_teams?: slack_teams_arr_rel_insert_input | null;
  stripeCustomerId?: string | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  userDomains?: string | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_team_clients?: user_team_clients_arr_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "clients"
 */
export interface clients_obj_rel_insert_input {
  data: clients_insert_input;
  on_conflict?: clients_on_conflict | null;
}

/**
 * on_conflict condition type for table "clients"
 */
export interface clients_on_conflict {
  constraint: clients_constraint;
  update_columns: clients_update_column[];
  where?: clients_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "competency_types". All fields are combined with a logical 'AND'.
 */
export interface competency_types_bool_exp {
  _and?: competency_types_bool_exp[] | null;
  _not?: competency_types_bool_exp | null;
  _or?: competency_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_skills?: user_skills_bool_exp | null;
}

/**
 * input type for inserting data into table "competency_types"
 */
export interface competency_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_skills?: user_skills_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "competency_types"
 */
export interface competency_types_obj_rel_insert_input {
  data: competency_types_insert_input;
  on_conflict?: competency_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "competency_types"
 */
export interface competency_types_on_conflict {
  constraint: competency_types_constraint;
  update_columns: competency_types_update_column[];
  where?: competency_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "credit_pricing_plan_payment_types". All fields are combined with a logical 'AND'.
 */
export interface credit_pricing_plan_payment_types_bool_exp {
  _and?: credit_pricing_plan_payment_types_bool_exp[] | null;
  _not?: credit_pricing_plan_payment_types_bool_exp | null;
  _or?: credit_pricing_plan_payment_types_bool_exp[] | null;
  credit_pricing_plans?: credit_pricing_plans_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "credit_pricing_plan_payment_types_enum". All fields are combined with logical 'AND'.
 */
export interface credit_pricing_plan_payment_types_enum_comparison_exp {
  _eq?: credit_pricing_plan_payment_types_enum | null;
  _in?: credit_pricing_plan_payment_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: credit_pricing_plan_payment_types_enum | null;
  _nin?: credit_pricing_plan_payment_types_enum[] | null;
}

/**
 * input type for inserting data into table "credit_pricing_plan_payment_types"
 */
export interface credit_pricing_plan_payment_types_insert_input {
  credit_pricing_plans?: credit_pricing_plans_arr_rel_insert_input | null;
  description?: string | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "credit_pricing_plan_payment_types"
 */
export interface credit_pricing_plan_payment_types_obj_rel_insert_input {
  data: credit_pricing_plan_payment_types_insert_input;
  on_conflict?: credit_pricing_plan_payment_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "credit_pricing_plan_payment_types"
 */
export interface credit_pricing_plan_payment_types_on_conflict {
  constraint: credit_pricing_plan_payment_types_constraint;
  update_columns: credit_pricing_plan_payment_types_update_column[];
  where?: credit_pricing_plan_payment_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "credit_pricing_plans"
 */
export interface credit_pricing_plans_arr_rel_insert_input {
  data: credit_pricing_plans_insert_input[];
  on_conflict?: credit_pricing_plans_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "credit_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface credit_pricing_plans_bool_exp {
  _and?: credit_pricing_plans_bool_exp[] | null;
  _not?: credit_pricing_plans_bool_exp | null;
  _or?: credit_pricing_plans_bool_exp[] | null;
  client_invoice_changes?: client_invoice_changes_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_pricing_plans?: client_pricing_plans_bool_exp | null;
  client_project_pricing_plans?: client_project_pricing_plans_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  costPerCredit?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  credit_pricing_plan_payment_type?: credit_pricing_plan_payment_types_bool_exp | null;
  creditsPerMidLevelHour?: Int_comparison_exp | null;
  defaultSLA?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discontinuedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isCustomPlan?: Boolean_comparison_exp | null;
  maxFreeSupportHoursPerWeek?: Int_comparison_exp | null;
  minCreditUsagePerWeek?: Int_comparison_exp | null;
  minimumCreditPurchase?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  paymentTypeId?: credit_pricing_plan_payment_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "credit_pricing_plans"
 */
export interface credit_pricing_plans_insert_input {
  client_invoice_changes?: client_invoice_changes_arr_rel_insert_input | null;
  client_invoices?: client_invoices_arr_rel_insert_input | null;
  client_pricing_plans?: client_pricing_plans_arr_rel_insert_input | null;
  client_project_pricing_plans?: client_project_pricing_plans_arr_rel_insert_input | null;
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  costPerCredit?: number | null;
  createdAt?: hasura_timestamptz | null;
  credit_pricing_plan_payment_type?: credit_pricing_plan_payment_types_obj_rel_insert_input | null;
  creditsPerMidLevelHour?: number | null;
  defaultSLA?: number | null;
  deletedAt?: hasura_timestamptz | null;
  discontinuedAt?: hasura_timestamptz | null;
  id?: number | null;
  isCustomPlan?: boolean | null;
  maxFreeSupportHoursPerWeek?: number | null;
  minCreditUsagePerWeek?: number | null;
  minimumCreditPurchase?: number | null;
  name?: string | null;
  paymentTypeId?: credit_pricing_plan_payment_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "credit_pricing_plans"
 */
export interface credit_pricing_plans_obj_rel_insert_input {
  data: credit_pricing_plans_insert_input;
  on_conflict?: credit_pricing_plans_on_conflict | null;
}

/**
 * on_conflict condition type for table "credit_pricing_plans"
 */
export interface credit_pricing_plans_on_conflict {
  constraint: credit_pricing_plans_constraint;
  update_columns: credit_pricing_plans_update_column[];
  where?: credit_pricing_plans_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: hasura_date | null;
  _gt?: hasura_date | null;
  _gte?: hasura_date | null;
  _in?: hasura_date[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_date | null;
  _lte?: hasura_date | null;
  _neq?: hasura_date | null;
  _nin?: hasura_date[] | null;
}

/**
 * Boolean expression to filter rows from the table "description_types". All fields are combined with a logical 'AND'.
 */
export interface description_types_bool_exp {
  _and?: description_types_bool_exp[] | null;
  _not?: description_types_bool_exp | null;
  _or?: description_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_changes?: task_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "description_types_enum". All fields are combined with logical 'AND'.
 */
export interface description_types_enum_comparison_exp {
  _eq?: description_types_enum | null;
  _in?: description_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: description_types_enum | null;
  _nin?: description_types_enum[] | null;
}

/**
 * input type for inserting data into table "description_types"
 */
export interface description_types_insert_input {
  description?: string | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "description_types"
 */
export interface description_types_obj_rel_insert_input {
  data: description_types_insert_input;
  on_conflict?: description_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "description_types"
 */
export interface description_types_on_conflict {
  constraint: description_types_constraint;
  update_columns: description_types_update_column[];
  where?: description_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "developer_bonus_earning"
 */
export interface developer_bonus_earning_arr_rel_insert_input {
  data: developer_bonus_earning_insert_input[];
  on_conflict?: developer_bonus_earning_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "developer_bonus_earning". All fields are combined with a logical 'AND'.
 */
export interface developer_bonus_earning_bool_exp {
  _and?: developer_bonus_earning_bool_exp[] | null;
  _not?: developer_bonus_earning_bool_exp | null;
  _or?: developer_bonus_earning_bool_exp[] | null;
  amountInUSD?: float8_comparison_exp | null;
  bonusType?: bonus_types_enum_comparison_exp | null;
  bonus_type?: bonus_types_bool_exp | null;
  developer?: developers_bool_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userInvoiceId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
}

/**
 * input type for inserting data into table "developer_bonus_earning"
 */
export interface developer_bonus_earning_insert_input {
  amountInUSD?: hasura_float8 | null;
  bonusType?: bonus_types_enum | null;
  bonus_type?: bonus_types_obj_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  endDate?: hasura_timestamptz | null;
  id?: number | null;
  startDate?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userInvoiceId?: number | null;
  userLogin?: string | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "developer_bonus_earning"
 */
export interface developer_bonus_earning_on_conflict {
  constraint: developer_bonus_earning_constraint;
  update_columns: developer_bonus_earning_update_column[];
  where?: developer_bonus_earning_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "developer_changes"
 */
export interface developer_changes_arr_rel_insert_input {
  data: developer_changes_insert_input[];
  on_conflict?: developer_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "developer_changes". All fields are combined with a logical 'AND'.
 */
export interface developer_changes_bool_exp {
  _and?: developer_changes_bool_exp[] | null;
  _not?: developer_changes_bool_exp | null;
  _or?: developer_changes_bool_exp[] | null;
  addedToAirtableAt?: timestamptz_comparison_exp | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  appliedAt?: timestamptz_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  bio?: String_comparison_exp | null;
  canRelocate?: Boolean_comparison_exp | null;
  candidate_stage?: candidate_stage_bool_exp | null;
  career_ladder_level?: career_ladder_levels_bool_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  city?: String_comparison_exp | null;
  codeSampleUrl?: String_comparison_exp | null;
  cohort?: String_comparison_exp | null;
  country?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  culture_screen_feedback?: String_comparison_exp | null;
  currentMonthlySalary?: Int_comparison_exp | null;
  cv?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  earliestStartDate?: timestamptz_comparison_exp | null;
  failedToAddToAirtableAt?: timestamptz_comparison_exp | null;
  githubId?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  isActiveStudent?: Boolean_comparison_exp | null;
  isActivelyEmployed?: Boolean_comparison_exp | null;
  levelId?: String_comparison_exp | null;
  linkedInUrl?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  minAnnualSalary?: Int_comparison_exp | null;
  noticePeriod?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  previousWorkExp?: String_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  source?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  stage?: candidate_stage_enum_comparison_exp | null;
  targetCurrency?: String_comparison_exp | null;
  tech_screen_feedback?: String_comparison_exp | null;
  timezone?: Int_comparison_exp | null;
  transferwiseId?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByChangedbyuserid?: users_bool_exp | null;
  userById?: users_bool_exp | null;
  workMachineOS?: String_comparison_exp | null;
  work_machine_o?: work_machine_os_bool_exp | null;
  yearsOfExperience?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "developer_changes"
 */
export interface developer_changes_insert_input {
  addedToAirtableAt?: hasura_timestamptz | null;
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  appliedAt?: hasura_timestamptz | null;
  automaticallyChanged?: boolean | null;
  bio?: string | null;
  canRelocate?: boolean | null;
  candidate_stage?: candidate_stage_obj_rel_insert_input | null;
  career_ladder_level?: career_ladder_levels_obj_rel_insert_input | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  city?: string | null;
  codeSampleUrl?: string | null;
  cohort?: string | null;
  country?: string | null;
  createdAt?: hasura_timestamptz | null;
  culture_screen_feedback?: string | null;
  currentMonthlySalary?: number | null;
  cv?: string | null;
  deletedAt?: hasura_timestamptz | null;
  earliestStartDate?: hasura_timestamptz | null;
  failedToAddToAirtableAt?: hasura_timestamptz | null;
  githubId?: string | null;
  id?: string | null;
  isActiveStudent?: boolean | null;
  isActivelyEmployed?: boolean | null;
  levelId?: string | null;
  linkedInUrl?: string | null;
  login?: string | null;
  minAnnualSalary?: number | null;
  noticePeriod?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  previousWorkExp?: string | null;
  reasonForChange?: string | null;
  source?: string | null;
  sqlQuery?: string | null;
  stage?: candidate_stage_enum | null;
  targetCurrency?: string | null;
  tech_screen_feedback?: string | null;
  timezone?: number | null;
  transferwiseId?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByChangedbyuserid?: users_obj_rel_insert_input | null;
  userById?: users_obj_rel_insert_input | null;
  workMachineOS?: string | null;
  work_machine_o?: work_machine_os_obj_rel_insert_input | null;
  yearsOfExperience?: number | null;
}

/**
 * on_conflict condition type for table "developer_changes"
 */
export interface developer_changes_on_conflict {
  constraint: developer_changes_constraint;
  update_columns: developer_changes_update_column[];
  where?: developer_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "developer_daily_log"
 */
export interface developer_daily_log_arr_rel_insert_input {
  data: developer_daily_log_insert_input[];
  on_conflict?: developer_daily_log_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "developer_daily_log". All fields are combined with a logical 'AND'.
 */
export interface developer_daily_log_bool_exp {
  _and?: developer_daily_log_bool_exp[] | null;
  _not?: developer_daily_log_bool_exp | null;
  _or?: developer_daily_log_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  developer?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  numberOfCommits?: Int_comparison_exp | null;
  timeLogged?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  workDate?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "developer_daily_log"
 */
export interface developer_daily_log_insert_input {
  createdAt?: hasura_timestamptz | null;
  developer?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  id?: number | null;
  numberOfCommits?: number | null;
  timeLogged?: number | null;
  updatedAt?: hasura_timestamptz | null;
  workDate?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "developer_daily_log"
 */
export interface developer_daily_log_on_conflict {
  constraint: developer_daily_log_constraint;
  update_columns: developer_daily_log_update_column[];
  where?: developer_daily_log_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "developer_weekly_earning"
 */
export interface developer_weekly_earning_arr_rel_insert_input {
  data: developer_weekly_earning_insert_input[];
  on_conflict?: developer_weekly_earning_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "developer_weekly_earning". All fields are combined with a logical 'AND'.
 */
export interface developer_weekly_earning_bool_exp {
  _and?: developer_weekly_earning_bool_exp[] | null;
  _not?: developer_weekly_earning_bool_exp | null;
  _or?: developer_weekly_earning_bool_exp[] | null;
  amountEarnedWithTasks?: float8_comparison_exp | null;
  developer?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  minimumAmountEarned?: float8_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "developer_weekly_earning"
 */
export interface developer_weekly_earning_insert_input {
  amountEarnedWithTasks?: hasura_float8 | null;
  developer?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  endDate?: hasura_timestamptz | null;
  id?: number | null;
  minimumAmountEarned?: hasura_float8 | null;
  startDate?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
}

/**
 * on_conflict condition type for table "developer_weekly_earning"
 */
export interface developer_weekly_earning_on_conflict {
  constraint: developer_weekly_earning_constraint;
  update_columns: developer_weekly_earning_update_column[];
  where?: developer_weekly_earning_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "developers_airtable_view". All fields are combined with a logical 'AND'.
 */
export interface developers_airtable_view_bool_exp {
  _and?: developers_airtable_view_bool_exp[] | null;
  _not?: developers_airtable_view_bool_exp | null;
  _or?: developers_airtable_view_bool_exp[] | null;
  addedToAirtableAt?: timestamptz_comparison_exp | null;
  appliedAt?: timestamptz_comparison_exp | null;
  developerId?: String_comparison_exp | null;
  failedToAddToAirtableAt?: timestamptz_comparison_exp | null;
  failedToAddToAirtableAt_offset?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "developers_airtable_view"
 */
export interface developers_airtable_view_insert_input {
  addedToAirtableAt?: hasura_timestamptz | null;
  appliedAt?: hasura_timestamptz | null;
  developerId?: string | null;
  failedToAddToAirtableAt?: hasura_timestamptz | null;
  failedToAddToAirtableAt_offset?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "developers_airtable_view"
 */
export interface developers_airtable_view_obj_rel_insert_input {
  data: developers_airtable_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "developers"
 */
export interface developers_arr_rel_insert_input {
  data: developers_insert_input[];
  on_conflict?: developers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "developers". All fields are combined with a logical 'AND'.
 */
export interface developers_bool_exp {
  _and?: developers_bool_exp[] | null;
  _not?: developers_bool_exp | null;
  _or?: developers_bool_exp[] | null;
  addedToAirtableAt?: timestamptz_comparison_exp | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  appliedAt?: timestamptz_comparison_exp | null;
  bio?: String_comparison_exp | null;
  canRelocate?: Boolean_comparison_exp | null;
  candidate_stage?: candidate_stage_bool_exp | null;
  career_ladder_level?: career_ladder_levels_bool_exp | null;
  city?: String_comparison_exp | null;
  codeSampleUrl?: String_comparison_exp | null;
  cohort?: String_comparison_exp | null;
  country?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  culture_screen_feedback?: String_comparison_exp | null;
  currentMonthlySalary?: Int_comparison_exp | null;
  cv?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_developers?: developers_developer_view_bool_exp | null;
  developer_airtable_view?: developers_airtable_view_bool_exp | null;
  developer_bonus_earnings?: developer_bonus_earning_bool_exp | null;
  developer_contracts?: user_contracts_bool_exp | null;
  developer_daily_logs?: developer_daily_log_bool_exp | null;
  developer_payments?: user_payments_bool_exp | null;
  developer_weekly_earnings?: developer_weekly_earning_bool_exp | null;
  earliestStartDate?: timestamptz_comparison_exp | null;
  failedToAddToAirtableAt?: timestamptz_comparison_exp | null;
  githubId?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  isActiveStudent?: Boolean_comparison_exp | null;
  isActivelyEmployed?: Boolean_comparison_exp | null;
  levelId?: String_comparison_exp | null;
  linkedInUrl?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  minAnnualSalary?: Int_comparison_exp | null;
  noticePeriod?: Int_comparison_exp | null;
  previousWorkExp?: String_comparison_exp | null;
  source?: String_comparison_exp | null;
  stage?: candidate_stage_enum_comparison_exp | null;
  targetCurrency?: String_comparison_exp | null;
  taskChangesByDeveloperid?: task_changes_bool_exp | null;
  taskChangesByReviewerid?: task_changes_bool_exp | null;
  taskChangesByReviewersid?: task_changes_bool_exp | null;
  taskReviewsByDeveloperid?: task_reviews_bool_exp | null;
  taskWorksByDeveloperid?: task_work_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_reviewers?: task_reviewers_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  task_time_log?: task_time_logs_bool_exp | null;
  task_time_logs?: task_time_logs_bool_exp | null;
  task_works?: task_work_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  tasksByDeveloperid?: tasks_bool_exp | null;
  tasksByManagerid?: tasks_bool_exp | null;
  tech_screen_feedback?: String_comparison_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  timezone?: Int_comparison_exp | null;
  transferwiseId?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_changes?: user_changes_bool_exp | null;
  workMachineOS?: String_comparison_exp | null;
  work_machine_os?: work_machine_os_bool_exp | null;
  yearsOfExperience?: Int_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "developers_developer_view". All fields are combined with a logical 'AND'.
 */
export interface developers_developer_view_bool_exp {
  _and?: developers_developer_view_bool_exp[] | null;
  _not?: developers_developer_view_bool_exp | null;
  _or?: developers_developer_view_bool_exp[] | null;
  candidateId?: uuid_comparison_exp | null;
  cohort?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  stage?: String_comparison_exp | null;
  targetCurrency?: String_comparison_exp | null;
  transferwiseId?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "developers_developer_view"
 */
export interface developers_developer_view_insert_input {
  candidateId?: hasura_uuid | null;
  cohort?: string | null;
  id?: string | null;
  stage?: string | null;
  targetCurrency?: string | null;
  transferwiseId?: string | null;
}

/**
 * input type for inserting object relation for remote table "developers_developer_view"
 */
export interface developers_developer_view_obj_rel_insert_input {
  data: developers_developer_view_insert_input;
}

/**
 * input type for inserting data into table "developers"
 */
export interface developers_insert_input {
  addedToAirtableAt?: hasura_timestamptz | null;
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  appliedAt?: hasura_timestamptz | null;
  bio?: string | null;
  canRelocate?: boolean | null;
  candidate_stage?: candidate_stage_obj_rel_insert_input | null;
  career_ladder_level?: career_ladder_levels_obj_rel_insert_input | null;
  city?: string | null;
  codeSampleUrl?: string | null;
  cohort?: string | null;
  country?: string | null;
  createdAt?: hasura_timestamptz | null;
  culture_screen_feedback?: string | null;
  currentMonthlySalary?: number | null;
  cv?: string | null;
  deletedAt?: hasura_timestamptz | null;
  dev_developers?: developers_developer_view_obj_rel_insert_input | null;
  developer_airtable_view?: developers_airtable_view_obj_rel_insert_input | null;
  developer_bonus_earnings?: developer_bonus_earning_arr_rel_insert_input | null;
  developer_contracts?: user_contracts_arr_rel_insert_input | null;
  developer_daily_logs?: developer_daily_log_arr_rel_insert_input | null;
  developer_payments?: user_payments_arr_rel_insert_input | null;
  developer_weekly_earnings?: developer_weekly_earning_arr_rel_insert_input | null;
  earliestStartDate?: hasura_timestamptz | null;
  failedToAddToAirtableAt?: hasura_timestamptz | null;
  githubId?: string | null;
  id?: string | null;
  isActiveStudent?: boolean | null;
  isActivelyEmployed?: boolean | null;
  levelId?: string | null;
  linkedInUrl?: string | null;
  login?: string | null;
  minAnnualSalary?: number | null;
  noticePeriod?: number | null;
  previousWorkExp?: string | null;
  source?: string | null;
  stage?: candidate_stage_enum | null;
  targetCurrency?: string | null;
  taskChangesByDeveloperid?: task_changes_arr_rel_insert_input | null;
  taskChangesByReviewerid?: task_changes_arr_rel_insert_input | null;
  taskChangesByReviewersid?: task_changes_arr_rel_insert_input | null;
  taskReviewsByDeveloperid?: task_reviews_arr_rel_insert_input | null;
  taskWorksByDeveloperid?: task_work_arr_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_reviewers?: task_reviewers_arr_rel_insert_input | null;
  task_reviews?: task_reviews_arr_rel_insert_input | null;
  task_time_log?: task_time_logs_obj_rel_insert_input | null;
  task_time_logs?: task_time_logs_arr_rel_insert_input | null;
  task_works?: task_work_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  tasksByDeveloperid?: tasks_arr_rel_insert_input | null;
  tasksByManagerid?: tasks_arr_rel_insert_input | null;
  tech_screen_feedback?: string | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  timezone?: number | null;
  transferwiseId?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
  workMachineOS?: string | null;
  work_machine_os?: work_machine_os_obj_rel_insert_input | null;
  yearsOfExperience?: number | null;
}

/**
 * input type for inserting object relation for remote table "developers"
 */
export interface developers_obj_rel_insert_input {
  data: developers_insert_input;
  on_conflict?: developers_on_conflict | null;
}

/**
 * on_conflict condition type for table "developers"
 */
export interface developers_on_conflict {
  constraint: developers_constraint;
  update_columns: developers_update_column[];
  where?: developers_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comment_body_types". All fields are combined with a logical 'AND'.
 */
export interface discussion_comment_body_types_bool_exp {
  _and?: discussion_comment_body_types_bool_exp[] | null;
  _not?: discussion_comment_body_types_bool_exp | null;
  _or?: discussion_comment_body_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "discussion_comment_body_types_enum". All fields are combined with logical 'AND'.
 */
export interface discussion_comment_body_types_enum_comparison_exp {
  _eq?: discussion_comment_body_types_enum | null;
  _in?: discussion_comment_body_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: discussion_comment_body_types_enum | null;
  _nin?: discussion_comment_body_types_enum[] | null;
}

/**
 * input type for inserting data into table "discussion_comment_body_types"
 */
export interface discussion_comment_body_types_insert_input {
  description?: string | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "discussion_comment_body_types"
 */
export interface discussion_comment_body_types_obj_rel_insert_input {
  data: discussion_comment_body_types_insert_input;
  on_conflict?: discussion_comment_body_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "discussion_comment_body_types"
 */
export interface discussion_comment_body_types_on_conflict {
  constraint: discussion_comment_body_types_constraint;
  update_columns: discussion_comment_body_types_update_column[];
  where?: discussion_comment_body_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comment_read_types". All fields are combined with a logical 'AND'.
 */
export interface discussion_comment_read_types_bool_exp {
  _and?: discussion_comment_read_types_bool_exp[] | null;
  _not?: discussion_comment_read_types_bool_exp | null;
  _or?: discussion_comment_read_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  discussion_comment_reads?: discussion_comment_reads_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "discussion_comment_read_types_enum". All fields are combined with logical 'AND'.
 */
export interface discussion_comment_read_types_enum_comparison_exp {
  _eq?: discussion_comment_read_types_enum | null;
  _in?: discussion_comment_read_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: discussion_comment_read_types_enum | null;
  _nin?: discussion_comment_read_types_enum[] | null;
}

/**
 * input type for inserting data into table "discussion_comment_read_types"
 */
export interface discussion_comment_read_types_insert_input {
  description?: string | null;
  discussion_comment_reads?: discussion_comment_reads_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "discussion_comment_read_types"
 */
export interface discussion_comment_read_types_obj_rel_insert_input {
  data: discussion_comment_read_types_insert_input;
  on_conflict?: discussion_comment_read_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "discussion_comment_read_types"
 */
export interface discussion_comment_read_types_on_conflict {
  constraint: discussion_comment_read_types_constraint;
  update_columns: discussion_comment_read_types_update_column[];
  where?: discussion_comment_read_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "discussion_comment_reads"
 */
export interface discussion_comment_reads_arr_rel_insert_input {
  data: discussion_comment_reads_insert_input[];
  on_conflict?: discussion_comment_reads_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comment_reads". All fields are combined with a logical 'AND'.
 */
export interface discussion_comment_reads_bool_exp {
  _and?: discussion_comment_reads_bool_exp[] | null;
  _not?: discussion_comment_reads_bool_exp | null;
  _or?: discussion_comment_reads_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  discussion_comment_read_type?: discussion_comment_read_types_bool_exp | null;
  id?: Int_comparison_exp | null;
  readAt?: timestamptz_comparison_exp | null;
  readByUser?: users_bool_exp | null;
  readByUserId?: Int_comparison_exp | null;
  readTypeId?: discussion_comment_read_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "discussion_comment_reads"
 */
export interface discussion_comment_reads_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  discussion_comment_read_type?: discussion_comment_read_types_obj_rel_insert_input | null;
  id?: number | null;
  readAt?: hasura_timestamptz | null;
  readByUser?: users_obj_rel_insert_input | null;
  readByUserId?: number | null;
  readTypeId?: discussion_comment_read_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "discussion_comment_reads"
 */
export interface discussion_comment_reads_on_conflict {
  constraint: discussion_comment_reads_constraint;
  update_columns: discussion_comment_reads_update_column[];
  where?: discussion_comment_reads_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "discussion_comments"
 */
export interface discussion_comments_arr_rel_insert_input {
  data: discussion_comments_insert_input[];
  on_conflict?: discussion_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_comments". All fields are combined with a logical 'AND'.
 */
export interface discussion_comments_bool_exp {
  _and?: discussion_comments_bool_exp[] | null;
  _not?: discussion_comments_bool_exp | null;
  _or?: discussion_comments_bool_exp[] | null;
  authorAvatarUrl?: String_comparison_exp | null;
  authorFirstName?: String_comparison_exp | null;
  authorLastName?: String_comparison_exp | null;
  authorSid?: String_comparison_exp | null;
  authorTableName?: String_comparison_exp | null;
  authorUser?: users_bool_exp | null;
  authorUserId?: Int_comparison_exp | null;
  author_syncable_entity?: syncable_entities_bool_exp | null;
  child_comments?: discussion_comments_bool_exp | null;
  clickup_task_comments?: clickup_task_comments_bool_exp | null;
  commentedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  diffHunk?: String_comparison_exp | null;
  discussionName?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_comment_body_type?: discussion_comment_body_types_bool_exp | null;
  discussion_comment_reads?: discussion_comment_reads_bool_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  error?: errors_bool_exp | null;
  errorId?: Int_comparison_exp | null;
  git_commit_comment?: git_commit_comments_bool_exp | null;
  git_commit_comments?: git_commit_comments_bool_exp | null;
  git_pull_request_comment?: git_pull_request_comments_bool_exp | null;
  git_pull_request_comments?: git_pull_request_comments_bool_exp | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  github_commit_comment?: github_commit_comments_bool_exp | null;
  github_commit_comments?: github_commit_comments_bool_exp | null;
  github_issue_comment?: github_issue_comments_bool_exp | null;
  github_issue_comments?: github_issue_comments_bool_exp | null;
  github_pull_request_comment?: github_pull_request_comments_bool_exp | null;
  github_pull_request_comments?: github_pull_request_comments_bool_exp | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_bool_exp | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_bool_exp | null;
  gitlab_issue_comments?: gitlab_issue_comments_bool_exp | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  isInternalForClient?: Boolean_comparison_exp | null;
  isResolved?: Boolean_comparison_exp | null;
  jira_issue_comment?: jira_issue_comment_bool_exp | null;
  jira_issue_comments?: jira_issue_comment_bool_exp | null;
  linear_comments?: linear_comments_bool_exp | null;
  notifications?: notifications_bool_exp | null;
  notifiedToRelevantParties?: Boolean_comparison_exp | null;
  originalPosition?: Int_comparison_exp | null;
  outdated?: Boolean_comparison_exp | null;
  parentCommentId?: Int_comparison_exp | null;
  parent_comment?: discussion_comments_bool_exp | null;
  path?: String_comparison_exp | null;
  position?: Int_comparison_exp | null;
  rawBodyText?: String_comparison_exp | null;
  rawBodyTypeId?: discussion_comment_body_types_enum_comparison_exp | null;
  relatedToTicketId?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "discussion_comments"
 */
export interface discussion_comments_insert_input {
  authorSid?: string | null;
  authorTableName?: string | null;
  authorUser?: users_obj_rel_insert_input | null;
  authorUserId?: number | null;
  author_syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  child_comments?: discussion_comments_arr_rel_insert_input | null;
  clickup_task_comments?: clickup_task_comments_arr_rel_insert_input | null;
  commentedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  diffHunk?: string | null;
  discussionSourceId?: number | null;
  discussion_comment_body_type?: discussion_comment_body_types_obj_rel_insert_input | null;
  discussion_comment_reads?: discussion_comment_reads_arr_rel_insert_input | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  error?: errors_obj_rel_insert_input | null;
  errorId?: number | null;
  git_commit_comment?: git_commit_comments_obj_rel_insert_input | null;
  git_commit_comments?: git_commit_comments_arr_rel_insert_input | null;
  git_pull_request_comment?: git_pull_request_comments_obj_rel_insert_input | null;
  git_pull_request_comments?: git_pull_request_comments_arr_rel_insert_input | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_obj_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  github_commit_comment?: github_commit_comments_obj_rel_insert_input | null;
  github_commit_comments?: github_commit_comments_arr_rel_insert_input | null;
  github_issue_comment?: github_issue_comments_obj_rel_insert_input | null;
  github_issue_comments?: github_issue_comments_arr_rel_insert_input | null;
  github_pull_request_comment?: github_pull_request_comments_obj_rel_insert_input | null;
  github_pull_request_comments?: github_pull_request_comments_arr_rel_insert_input | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_obj_rel_insert_input | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_arr_rel_insert_input | null;
  gitlab_issue_comments?: gitlab_issue_comments_arr_rel_insert_input | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_arr_rel_insert_input | null;
  id?: number | null;
  isInternal?: boolean | null;
  isResolved?: boolean | null;
  jira_issue_comment?: jira_issue_comment_obj_rel_insert_input | null;
  jira_issue_comments?: jira_issue_comment_arr_rel_insert_input | null;
  linear_comments?: linear_comments_arr_rel_insert_input | null;
  notifications?: notifications_arr_rel_insert_input | null;
  notifiedToRelevantParties?: boolean | null;
  originalPosition?: number | null;
  outdated?: boolean | null;
  parentCommentId?: number | null;
  parent_comment?: discussion_comments_obj_rel_insert_input | null;
  path?: string | null;
  position?: number | null;
  rawBodyText?: string | null;
  rawBodyTypeId?: discussion_comment_body_types_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "discussion_comments"
 */
export interface discussion_comments_obj_rel_insert_input {
  data: discussion_comments_insert_input;
  on_conflict?: discussion_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "discussion_comments"
 */
export interface discussion_comments_on_conflict {
  constraint: discussion_comments_constraint;
  update_columns: discussion_comments_update_column[];
  where?: discussion_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "discussion_sources"
 */
export interface discussion_sources_arr_rel_insert_input {
  data: discussion_sources_insert_input[];
  on_conflict?: discussion_sources_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "discussion_sources". All fields are combined with a logical 'AND'.
 */
export interface discussion_sources_bool_exp {
  _and?: discussion_sources_bool_exp[] | null;
  _not?: discussion_sources_bool_exp | null;
  _or?: discussion_sources_bool_exp[] | null;
  clickup_task?: clickup_tasks_bool_exp | null;
  clickup_tasks?: clickup_tasks_bool_exp | null;
  client?: clients_bool_exp | null;
  client_changes?: client_changes_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  client_project_changes?: client_project_changes_bool_exp | null;
  client_projects?: client_projects_bool_exp | null;
  clients?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  github_issues?: github_issues_bool_exp | null;
  gitlab_issue?: gitlab_issues_bool_exp | null;
  gitlab_issues?: gitlab_issues_bool_exp | null;
  id?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issues?: jira_issues_bool_exp | null;
  linear_issue?: linear_issues_bool_exp | null;
  linear_issue_changes?: linear_issue_changes_bool_exp | null;
  linear_issues?: linear_issues_bool_exp | null;
  resolvedAt?: timestamptz_comparison_exp | null;
  slack_channel?: slack_channels_bool_exp | null;
  slack_channels?: slack_channels_bool_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task?: tasks_bool_exp | null;
  taskByTaskCode?: tasks_bool_exp | null;
  taskCode?: String_comparison_exp | null;
  taskTicketCode?: String_comparison_exp | null;
  task_change?: task_changes_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketByTicketcode?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  ticket_change?: ticket_changes_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "discussion_sources"
 */
export interface discussion_sources_insert_input {
  clickup_task?: clickup_tasks_obj_rel_insert_input | null;
  clickup_tasks?: clickup_tasks_arr_rel_insert_input | null;
  client?: clients_obj_rel_insert_input | null;
  client_changes?: client_changes_arr_rel_insert_input | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  client_project_changes?: client_project_changes_arr_rel_insert_input | null;
  client_projects?: client_projects_arr_rel_insert_input | null;
  clients?: clients_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_request_review?: git_pull_request_reviews_obj_rel_insert_input | null;
  git_pull_request_reviews?: git_pull_request_reviews_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  github_issues?: github_issues_arr_rel_insert_input | null;
  gitlab_issue?: gitlab_issues_obj_rel_insert_input | null;
  gitlab_issues?: gitlab_issues_arr_rel_insert_input | null;
  id?: number | null;
  isInternal?: boolean | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issues?: jira_issues_arr_rel_insert_input | null;
  linear_issue?: linear_issues_obj_rel_insert_input | null;
  linear_issue_changes?: linear_issue_changes_arr_rel_insert_input | null;
  linear_issues?: linear_issues_arr_rel_insert_input | null;
  resolvedAt?: hasura_timestamptz | null;
  slack_channel?: slack_channels_obj_rel_insert_input | null;
  slack_channels?: slack_channels_arr_rel_insert_input | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  task?: tasks_obj_rel_insert_input | null;
  taskByTaskCode?: tasks_obj_rel_insert_input | null;
  taskCode?: string | null;
  taskTicketCode?: string | null;
  task_change?: task_changes_obj_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketByTicketcode?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  ticket_change?: ticket_changes_obj_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "discussion_sources"
 */
export interface discussion_sources_obj_rel_insert_input {
  data: discussion_sources_insert_input;
  on_conflict?: discussion_sources_on_conflict | null;
}

/**
 * on_conflict condition type for table "discussion_sources"
 */
export interface discussion_sources_on_conflict {
  constraint: discussion_sources_constraint;
  update_columns: discussion_sources_update_column[];
  where?: discussion_sources_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_arr_rel_insert_input {
  data: discussions_related_to_tickets_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "discussions_related_to_tickets". All fields are combined with a logical 'AND'.
 */
export interface discussions_related_to_tickets_bool_exp {
  _and?: discussions_related_to_tickets_bool_exp[] | null;
  _not?: discussions_related_to_tickets_bool_exp | null;
  _or?: discussions_related_to_tickets_bool_exp[] | null;
  discussionName?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  source?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "discussions_related_to_tickets"
 */
export interface discussions_related_to_tickets_insert_input {
  discussionName?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  source?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
}

/**
 * Boolean expression to filter rows from the table "errors". All fields are combined with a logical 'AND'.
 */
export interface errors_bool_exp {
  _and?: errors_bool_exp[] | null;
  _not?: errors_bool_exp | null;
  _or?: errors_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  error?: jsonb_comparison_exp | null;
  errorMessage?: String_comparison_exp | null;
  erroredAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  project_daily_standup?: project_daily_standup_bool_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  sentryErrorId?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
  user_daily_standups?: user_daily_standups_bool_exp | null;
}

/**
 * input type for inserting data into table "errors"
 */
export interface errors_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  error?: hasura_jsonb | null;
  errorMessage?: string | null;
  erroredAt?: hasura_timestamptz | null;
  id?: number | null;
  notifications?: notifications_arr_rel_insert_input | null;
  project_daily_standup?: project_daily_standup_obj_rel_insert_input | null;
  project_daily_standups?: project_daily_standup_arr_rel_insert_input | null;
  sentryErrorId?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_daily_standup?: user_daily_standups_obj_rel_insert_input | null;
  user_daily_standups?: user_daily_standups_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "errors"
 */
export interface errors_obj_rel_insert_input {
  data: errors_insert_input;
  on_conflict?: errors_on_conflict | null;
}

/**
 * on_conflict condition type for table "errors"
 */
export interface errors_on_conflict {
  constraint: errors_constraint;
  update_columns: errors_update_column[];
  where?: errors_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "feature_flags". All fields are combined with a logical 'AND'.
 */
export interface feature_flags_bool_exp {
  _and?: feature_flags_bool_exp[] | null;
  _not?: feature_flags_bool_exp | null;
  _or?: feature_flags_bool_exp[] | null;
  client_feature_flags?: client_feature_flags_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "feature_flags"
 */
export interface feature_flags_insert_input {
  client_feature_flags?: client_feature_flags_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  id?: number | null;
  name?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "feature_flags"
 */
export interface feature_flags_obj_rel_insert_input {
  data: feature_flags_insert_input;
  on_conflict?: feature_flags_on_conflict | null;
}

/**
 * on_conflict condition type for table "feature_flags"
 */
export interface feature_flags_on_conflict {
  constraint: feature_flags_constraint;
  update_columns: feature_flags_update_column[];
  where?: feature_flags_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'.
 */
export interface float8_comparison_exp {
  _eq?: hasura_float8 | null;
  _gt?: hasura_float8 | null;
  _gte?: hasura_float8 | null;
  _in?: hasura_float8[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_float8 | null;
  _lte?: hasura_float8 | null;
  _neq?: hasura_float8 | null;
  _nin?: hasura_float8[] | null;
}

/**
 * input type for inserting array relation for remote table "further_reviews"
 */
export interface further_reviews_arr_rel_insert_input {
  data: further_reviews_insert_input[];
  on_conflict?: further_reviews_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "further_reviews". All fields are combined with a logical 'AND'.
 */
export interface further_reviews_bool_exp {
  _and?: further_reviews_bool_exp[] | null;
  _not?: further_reviews_bool_exp | null;
  _or?: further_reviews_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  taskReviewsId?: Int_comparison_exp | null;
  task_review?: task_reviews_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "further_reviews"
 */
export interface further_reviews_insert_input {
  task?: tasks_obj_rel_insert_input | null;
  taskReviewsId?: number | null;
  task_review?: task_reviews_obj_rel_insert_input | null;
  tasksId?: number | null;
}

/**
 * on_conflict condition type for table "further_reviews"
 */
export interface further_reviews_on_conflict {
  constraint: further_reviews_constraint;
  update_columns: further_reviews_update_column[];
  where?: further_reviews_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gcp_storage_buckets"
 */
export interface gcp_storage_buckets_arr_rel_insert_input {
  data: gcp_storage_buckets_insert_input[];
  on_conflict?: gcp_storage_buckets_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gcp_storage_buckets". All fields are combined with a logical 'AND'.
 */
export interface gcp_storage_buckets_bool_exp {
  _and?: gcp_storage_buckets_bool_exp[] | null;
  _not?: gcp_storage_buckets_bool_exp | null;
  _or?: gcp_storage_buckets_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gcp_storage_objects?: gcp_storage_objects_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gcp_storage_buckets"
 */
export interface gcp_storage_buckets_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gcp_storage_objects?: gcp_storage_objects_arr_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gcp_storage_buckets"
 */
export interface gcp_storage_buckets_obj_rel_insert_input {
  data: gcp_storage_buckets_insert_input;
  on_conflict?: gcp_storage_buckets_on_conflict | null;
}

/**
 * on_conflict condition type for table "gcp_storage_buckets"
 */
export interface gcp_storage_buckets_on_conflict {
  constraint: gcp_storage_buckets_constraint;
  update_columns: gcp_storage_buckets_update_column[];
  where?: gcp_storage_buckets_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gcp_storage_objects"
 */
export interface gcp_storage_objects_arr_rel_insert_input {
  data: gcp_storage_objects_insert_input[];
  on_conflict?: gcp_storage_objects_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gcp_storage_objects". All fields are combined with a logical 'AND'.
 */
export interface gcp_storage_objects_bool_exp {
  _and?: gcp_storage_objects_bool_exp[] | null;
  _not?: gcp_storage_objects_bool_exp | null;
  _or?: gcp_storage_objects_bool_exp[] | null;
  authoredAt?: timestamptz_comparison_exp | null;
  bucketId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creatorId?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  extension?: String_comparison_exp | null;
  filename?: String_comparison_exp | null;
  gcp_storage_bucket?: gcp_storage_buckets_bool_exp | null;
  id?: Int_comparison_exp | null;
  jira_issue_attachments?: jira_issue_attachments_bool_exp | null;
  mimetype?: String_comparison_exp | null;
  originalFileName?: String_comparison_exp | null;
  readLink?: String_comparison_exp | null;
  sha1?: String_comparison_exp | null;
  sha256?: String_comparison_exp | null;
  sha512?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sizeInBytes?: Int_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task_submissions?: task_submissions_bool_exp | null;
  ticket_attachments?: ticket_attachments_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  uploadLink?: String_comparison_exp | null;
  uploadLinkExpiresAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "gcp_storage_objects"
 */
export interface gcp_storage_objects_insert_input {
  authoredAt?: hasura_timestamptz | null;
  bucketId?: number | null;
  createdAt?: hasura_timestamptz | null;
  creatorId?: number | null;
  deletedAt?: hasura_timestamptz | null;
  extension?: string | null;
  filename?: string | null;
  gcp_storage_bucket?: gcp_storage_buckets_obj_rel_insert_input | null;
  id?: number | null;
  jira_issue_attachments?: jira_issue_attachments_arr_rel_insert_input | null;
  mimetype?: string | null;
  originalFileName?: string | null;
  readLink?: string | null;
  sha1?: string | null;
  sha256?: string | null;
  sha512?: string | null;
  sid?: string | null;
  sizeInBytes?: number | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  task_submissions?: task_submissions_arr_rel_insert_input | null;
  ticket_attachments?: ticket_attachments_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  uploadLink?: string | null;
  uploadLinkExpiresAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "gcp_storage_objects"
 */
export interface gcp_storage_objects_obj_rel_insert_input {
  data: gcp_storage_objects_insert_input;
  on_conflict?: gcp_storage_objects_on_conflict | null;
}

/**
 * on_conflict condition type for table "gcp_storage_objects"
 */
export interface gcp_storage_objects_on_conflict {
  constraint: gcp_storage_objects_constraint;
  update_columns: gcp_storage_objects_update_column[];
  where?: gcp_storage_objects_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_branch_commits"
 */
export interface git_branch_commits_arr_rel_insert_input {
  data: git_branch_commits_insert_input[];
  on_conflict?: git_branch_commits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_branch_commits". All fields are combined with a logical 'AND'.
 */
export interface git_branch_commits_bool_exp {
  _and?: git_branch_commits_bool_exp[] | null;
  _not?: git_branch_commits_bool_exp | null;
  _or?: git_branch_commits_bool_exp[] | null;
  branchId?: Int_comparison_exp | null;
  commitId?: Int_comparison_exp | null;
  git_branch?: git_branches_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
}

/**
 * input type for inserting data into table "git_branch_commits"
 */
export interface git_branch_commits_insert_input {
  branchId?: number | null;
  commitId?: number | null;
  git_branch?: git_branches_obj_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "git_branch_commits"
 */
export interface git_branch_commits_on_conflict {
  constraint: git_branch_commits_constraint;
  update_columns: git_branch_commits_update_column[];
  where?: git_branch_commits_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_branch_refs"
 */
export interface git_branch_refs_arr_rel_insert_input {
  data: git_branch_refs_insert_input[];
  on_conflict?: git_branch_refs_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_branch_refs". All fields are combined with a logical 'AND'.
 */
export interface git_branch_refs_bool_exp {
  _and?: git_branch_refs_bool_exp[] | null;
  _not?: git_branch_refs_bool_exp | null;
  _or?: git_branch_refs_bool_exp[] | null;
  branchName?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitPullRequestChangesByTargetbranchrefTargetrepoid?: git_pull_request_changes_bool_exp | null;
  gitRepoSliceChangesBySlicedfromrepoidSlicedfrombranchname?: git_repo_slice_changes_bool_exp | null;
  git_branches?: git_branches_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_requests_with_this_base?: git_pull_requests_bool_exp | null;
  git_pull_requests_with_this_target?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_repo_slice_changes?: git_repo_slice_changes_bool_exp | null;
  git_repo_slices_from_this_branch?: git_repo_slices_bool_exp | null;
  git_repo_slices_to_this_branch?: git_repo_slices_bool_exp | null;
  headCommit?: String_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_branch_refs"
 */
export interface git_branch_refs_insert_input {
  branchName?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitPullRequestChangesByTargetbranchrefTargetrepoid?: git_pull_request_changes_arr_rel_insert_input | null;
  gitRepoSliceChangesBySlicedfromrepoidSlicedfrombranchname?: git_repo_slice_changes_arr_rel_insert_input | null;
  git_branches?: git_branches_arr_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_requests_with_this_base?: git_pull_requests_arr_rel_insert_input | null;
  git_pull_requests_with_this_target?: git_pull_requests_arr_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_repo_slice_changes?: git_repo_slice_changes_arr_rel_insert_input | null;
  git_repo_slices_from_this_branch?: git_repo_slices_arr_rel_insert_input | null;
  git_repo_slices_to_this_branch?: git_repo_slices_arr_rel_insert_input | null;
  headCommit?: string | null;
  repoId?: number | null;
  syncedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_branch_refs"
 */
export interface git_branch_refs_obj_rel_insert_input {
  data: git_branch_refs_insert_input;
  on_conflict?: git_branch_refs_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_branch_refs"
 */
export interface git_branch_refs_on_conflict {
  constraint: git_branch_refs_constraint;
  update_columns: git_branch_refs_update_column[];
  where?: git_branch_refs_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_branches"
 */
export interface git_branches_arr_rel_insert_input {
  data: git_branches_insert_input[];
  on_conflict?: git_branches_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_branches". All fields are combined with a logical 'AND'.
 */
export interface git_branches_bool_exp {
  _and?: git_branches_bool_exp[] | null;
  _not?: git_branches_bool_exp | null;
  _or?: git_branches_bool_exp[] | null;
  branchRef?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_branch_commits?: git_branch_commits_bool_exp | null;
  git_branch_ref?: git_branch_refs_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  github_branch?: github_branches_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  headCommitId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_branches"
 */
export interface git_branches_insert_input {
  branchRef?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitRepoId?: number | null;
  git_branch_commits?: git_branch_commits_arr_rel_insert_input | null;
  git_branch_ref?: git_branch_refs_obj_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  github_branch?: github_branches_obj_rel_insert_input | null;
  github_branches?: github_branches_arr_rel_insert_input | null;
  headCommitId?: string | null;
  id?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_branches"
 */
export interface git_branches_obj_rel_insert_input {
  data: git_branches_insert_input;
  on_conflict?: git_branches_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_branches"
 */
export interface git_branches_on_conflict {
  constraint: git_branches_constraint;
  update_columns: git_branches_update_column[];
  where?: git_branches_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_comment_reactions"
 */
export interface git_comment_reactions_arr_rel_insert_input {
  data: git_comment_reactions_insert_input[];
  on_conflict?: git_comment_reactions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_comment_reactions". All fields are combined with a logical 'AND'.
 */
export interface git_comment_reactions_bool_exp {
  _and?: git_comment_reactions_bool_exp[] | null;
  _not?: git_comment_reactions_bool_exp | null;
  _or?: git_comment_reactions_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  content?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_comment?: git_pull_request_comments_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_comment_reaction?: github_comment_reactions_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  id?: Int_comparison_exp | null;
  pullRequestCommentId?: Int_comparison_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  pullRequestReviewCommentId?: Int_comparison_exp | null;
  pullRequestReviewId?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_comment_reactions"
 */
export interface git_comment_reactions_insert_input {
  authorId?: number | null;
  content?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_comment?: git_pull_request_comments_obj_rel_insert_input | null;
  git_pull_request_review?: git_pull_request_reviews_obj_rel_insert_input | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_comment_reaction?: github_comment_reactions_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  id?: number | null;
  pullRequestCommentId?: number | null;
  pullRequestId?: number | null;
  pullRequestReviewCommentId?: number | null;
  pullRequestReviewId?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_comment_reactions"
 */
export interface git_comment_reactions_obj_rel_insert_input {
  data: git_comment_reactions_insert_input;
  on_conflict?: git_comment_reactions_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_comment_reactions"
 */
export interface git_comment_reactions_on_conflict {
  constraint: git_comment_reactions_constraint;
  update_columns: git_comment_reactions_update_column[];
  where?: git_comment_reactions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_commit_comments"
 */
export interface git_commit_comments_arr_rel_insert_input {
  data: git_commit_comments_insert_input[];
  on_conflict?: git_commit_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_comments". All fields are combined with a logical 'AND'.
 */
export interface git_commit_comments_bool_exp {
  _and?: git_commit_comments_bool_exp[] | null;
  _not?: git_commit_comments_bool_exp | null;
  _or?: git_commit_comments_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  commitId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_commit_comment?: github_commit_comments_bool_exp | null;
  github_commit_comments?: github_commit_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  position?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_commit_comments"
 */
export interface git_commit_comments_insert_input {
  authorId?: number | null;
  body?: string | null;
  commitId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_commit_comment?: github_commit_comments_obj_rel_insert_input | null;
  github_commit_comments?: github_commit_comments_arr_rel_insert_input | null;
  id?: number | null;
  position?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_commit_comments"
 */
export interface git_commit_comments_obj_rel_insert_input {
  data: git_commit_comments_insert_input;
  on_conflict?: git_commit_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_commit_comments"
 */
export interface git_commit_comments_on_conflict {
  constraint: git_commit_comments_constraint;
  update_columns: git_commit_comments_update_column[];
  where?: git_commit_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_commit_contexts"
 */
export interface git_commit_contexts_arr_rel_insert_input {
  data: git_commit_contexts_insert_input[];
  on_conflict?: git_commit_contexts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_contexts". All fields are combined with a logical 'AND'.
 */
export interface git_commit_contexts_bool_exp {
  _and?: git_commit_contexts_bool_exp[] | null;
  _not?: git_commit_contexts_bool_exp | null;
  _or?: git_commit_contexts_bool_exp[] | null;
  avatarUrl?: String_comparison_exp | null;
  commitId?: Int_comparison_exp | null;
  context?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_commit_status?: git_commit_status_bool_exp | null;
  github_commit_context?: github_commit_contexts_bool_exp | null;
  github_commit_contexts?: github_commit_contexts_bool_exp | null;
  id?: Int_comparison_exp | null;
  status?: git_commit_status_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  targetUrl?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_commit_contexts"
 */
export interface git_commit_contexts_insert_input {
  avatarUrl?: string | null;
  commitId?: number | null;
  context?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_commit_status?: git_commit_status_obj_rel_insert_input | null;
  github_commit_context?: github_commit_contexts_obj_rel_insert_input | null;
  github_commit_contexts?: github_commit_contexts_arr_rel_insert_input | null;
  id?: number | null;
  status?: git_commit_status_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  targetUrl?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_commit_contexts"
 */
export interface git_commit_contexts_obj_rel_insert_input {
  data: git_commit_contexts_insert_input;
  on_conflict?: git_commit_contexts_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_commit_contexts"
 */
export interface git_commit_contexts_on_conflict {
  constraint: git_commit_contexts_constraint;
  update_columns: git_commit_contexts_update_column[];
  where?: git_commit_contexts_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_developer_view". All fields are combined with a logical 'AND'.
 */
export interface git_commit_developer_view_bool_exp {
  _and?: git_commit_developer_view_bool_exp[] | null;
  _not?: git_commit_developer_view_bool_exp | null;
  _or?: git_commit_developer_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_commit_developer_view"
 */
export interface git_commit_developer_view_insert_input {
  id?: number | null;
  syncSid?: string | null;
}

/**
 * input type for inserting object relation for remote table "git_commit_developer_view"
 */
export interface git_commit_developer_view_obj_rel_insert_input {
  data: git_commit_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "git_commit_relationships"
 */
export interface git_commit_relationships_arr_rel_insert_input {
  data: git_commit_relationships_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "git_commit_relationships". All fields are combined with a logical 'AND'.
 */
export interface git_commit_relationships_bool_exp {
  _and?: git_commit_relationships_bool_exp[] | null;
  _not?: git_commit_relationships_bool_exp | null;
  _or?: git_commit_relationships_bool_exp[] | null;
  childCommitId?: String_comparison_exp | null;
  gitCommitByChildcommitid?: git_commits_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  parentCommitId?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_commit_relationships"
 */
export interface git_commit_relationships_insert_input {
  childCommitId?: string | null;
  gitCommitByChildcommitid?: git_commits_obj_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  parentCommitId?: string | null;
}

/**
 * input type for inserting array relation for remote table "git_commit_slices"
 */
export interface git_commit_slices_arr_rel_insert_input {
  data: git_commit_slices_insert_input[];
  on_conflict?: git_commit_slices_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_slices". All fields are combined with a logical 'AND'.
 */
export interface git_commit_slices_bool_exp {
  _and?: git_commit_slices_bool_exp[] | null;
  _not?: git_commit_slices_bool_exp | null;
  _or?: git_commit_slices_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_commit_origin?: git_commits_bool_exp | null;
  git_commit_target?: git_commits_bool_exp | null;
  git_repo_slice?: git_repo_slices_bool_exp | null;
  git_repo_slice_changes?: git_repo_slice_changes_bool_exp | null;
  git_repo_slices?: git_repo_slices_bool_exp | null;
  git_slice_pull?: git_slice_pulls_bool_exp | null;
  git_slice_pulls?: git_slice_pulls_bool_exp | null;
  git_slice_push?: git_slice_pushes_bool_exp | null;
  git_slice_pushes?: git_slice_pushes_bool_exp | null;
  id?: Int_comparison_exp | null;
  originCommitId?: Int_comparison_exp | null;
  slicedAt?: timestamptz_comparison_exp | null;
  targetCommitId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_commit_slices"
 */
export interface git_commit_slices_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_commit_origin?: git_commits_obj_rel_insert_input | null;
  git_commit_target?: git_commits_obj_rel_insert_input | null;
  git_repo_slice?: git_repo_slices_obj_rel_insert_input | null;
  git_repo_slice_changes?: git_repo_slice_changes_arr_rel_insert_input | null;
  git_repo_slices?: git_repo_slices_arr_rel_insert_input | null;
  git_slice_pull?: git_slice_pulls_obj_rel_insert_input | null;
  git_slice_pulls?: git_slice_pulls_arr_rel_insert_input | null;
  git_slice_push?: git_slice_pushes_obj_rel_insert_input | null;
  git_slice_pushes?: git_slice_pushes_arr_rel_insert_input | null;
  id?: number | null;
  originCommitId?: number | null;
  slicedAt?: hasura_timestamptz | null;
  targetCommitId?: number | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_commit_slices"
 */
export interface git_commit_slices_obj_rel_insert_input {
  data: git_commit_slices_insert_input;
  on_conflict?: git_commit_slices_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_commit_slices"
 */
export interface git_commit_slices_on_conflict {
  constraint: git_commit_slices_constraint;
  update_columns: git_commit_slices_update_column[];
  where?: git_commit_slices_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_commit_status". All fields are combined with a logical 'AND'.
 */
export interface git_commit_status_bool_exp {
  _and?: git_commit_status_bool_exp[] | null;
  _not?: git_commit_status_bool_exp | null;
  _or?: git_commit_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  git_commit_contexts?: git_commit_contexts_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "git_commit_status_enum". All fields are combined with logical 'AND'.
 */
export interface git_commit_status_enum_comparison_exp {
  _eq?: git_commit_status_enum | null;
  _in?: git_commit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_commit_status_enum | null;
  _nin?: git_commit_status_enum[] | null;
}

/**
 * input type for inserting data into table "git_commit_status"
 */
export interface git_commit_status_insert_input {
  description?: string | null;
  git_commit_contexts?: git_commit_contexts_arr_rel_insert_input | null;
  git_commits?: git_commits_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "git_commit_status"
 */
export interface git_commit_status_obj_rel_insert_input {
  data: git_commit_status_insert_input;
  on_conflict?: git_commit_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_commit_status"
 */
export interface git_commit_status_on_conflict {
  constraint: git_commit_status_constraint;
  update_columns: git_commit_status_update_column[];
  where?: git_commit_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_commits"
 */
export interface git_commits_arr_rel_insert_input {
  data: git_commits_insert_input[];
  on_conflict?: git_commits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_commits". All fields are combined with a logical 'AND'.
 */
export interface git_commits_bool_exp {
  _and?: git_commits_bool_exp[] | null;
  _not?: git_commits_bool_exp | null;
  _or?: git_commits_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  committedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_git_commit?: git_commit_developer_view_bool_exp | null;
  gitCommitRelationshipsByParentcommitid?: git_commit_relationships_bool_exp | null;
  gitCommitSliceById?: git_commit_slices_bool_exp | null;
  gitCommitSlicesByTargetcommitid?: git_commit_slices_bool_exp | null;
  gitPullRequestChangesByTargetheadcommit?: git_pull_request_changes_bool_exp | null;
  gitPullRequestReviewCommentsByOriginalcommitid?: git_pull_request_review_comments_bool_exp | null;
  gitPullRequestsByTargetheadcommit?: git_pull_requests_bool_exp | null;
  git_branch_commits?: git_branch_commits_bool_exp | null;
  git_branch_refs?: git_branch_refs_bool_exp | null;
  git_branches?: git_branches_bool_exp | null;
  git_commit_comments?: git_commit_comments_bool_exp | null;
  git_commit_contexts?: git_commit_contexts_bool_exp | null;
  git_commit_relationships?: git_commit_relationships_bool_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_commit_slices?: git_commit_slices_bool_exp | null;
  git_commit_status?: git_commit_status_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  github_commits?: github_commits_bool_exp | null;
  id?: Int_comparison_exp | null;
  message?: String_comparison_exp | null;
  oid?: String_comparison_exp | null;
  parentCommits?: jsonb_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  shallow?: Boolean_comparison_exp | null;
  status?: git_commit_status_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  task_code_comments?: task_code_comments_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_commits"
 */
export interface git_commits_insert_input {
  authorId?: number | null;
  committedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dev_git_commit?: git_commit_developer_view_obj_rel_insert_input | null;
  gitCommitRelationshipsByParentcommitid?: git_commit_relationships_arr_rel_insert_input | null;
  gitCommitSliceById?: git_commit_slices_obj_rel_insert_input | null;
  gitCommitSlicesByTargetcommitid?: git_commit_slices_arr_rel_insert_input | null;
  gitPullRequestChangesByTargetheadcommit?: git_pull_request_changes_arr_rel_insert_input | null;
  gitPullRequestReviewCommentsByOriginalcommitid?: git_pull_request_review_comments_arr_rel_insert_input | null;
  gitPullRequestsByTargetheadcommit?: git_pull_requests_arr_rel_insert_input | null;
  git_branch_commits?: git_branch_commits_arr_rel_insert_input | null;
  git_branch_refs?: git_branch_refs_arr_rel_insert_input | null;
  git_branches?: git_branches_arr_rel_insert_input | null;
  git_commit_comments?: git_commit_comments_arr_rel_insert_input | null;
  git_commit_contexts?: git_commit_contexts_arr_rel_insert_input | null;
  git_commit_relationships?: git_commit_relationships_arr_rel_insert_input | null;
  git_commit_slice?: git_commit_slices_obj_rel_insert_input | null;
  git_commit_slices?: git_commit_slices_arr_rel_insert_input | null;
  git_commit_status?: git_commit_status_obj_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  github_commits?: github_commits_arr_rel_insert_input | null;
  id?: number | null;
  message?: string | null;
  oid?: string | null;
  parentCommits?: hasura_jsonb | null;
  repoId?: number | null;
  shallow?: boolean | null;
  status?: git_commit_status_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  task_code_comments?: task_code_comments_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_commits"
 */
export interface git_commits_obj_rel_insert_input {
  data: git_commits_insert_input;
  on_conflict?: git_commits_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_commits"
 */
export interface git_commits_on_conflict {
  constraint: git_commits_constraint;
  update_columns: git_commits_update_column[];
  where?: git_commits_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_organizations"
 */
export interface git_organizations_arr_rel_insert_input {
  data: git_organizations_insert_input[];
  on_conflict?: git_organizations_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_organizations". All fields are combined with a logical 'AND'.
 */
export interface git_organizations_bool_exp {
  _and?: git_organizations_bool_exp[] | null;
  _not?: git_organizations_bool_exp | null;
  _or?: git_organizations_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_repo_changes?: git_repo_changes_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_organizations?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_organizations"
 */
export interface git_organizations_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_repo_changes?: git_repo_changes_arr_rel_insert_input | null;
  git_repos?: git_repos_arr_rel_insert_input | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  github_organizations?: github_organizations_arr_rel_insert_input | null;
  id?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_organizations"
 */
export interface git_organizations_obj_rel_insert_input {
  data: git_organizations_insert_input;
  on_conflict?: git_organizations_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_organizations"
 */
export interface git_organizations_on_conflict {
  constraint: git_organizations_constraint;
  update_columns: git_organizations_update_column[];
  where?: git_organizations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_arr_rel_insert_input {
  data: git_pull_request_assignees_insert_input[];
  on_conflict?: git_pull_request_assignees_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_assignees". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_assignees_bool_exp {
  _and?: git_pull_request_assignees_bool_exp[] | null;
  _not?: git_pull_request_assignees_bool_exp | null;
  _or?: git_pull_request_assignees_bool_exp[] | null;
  assigneeUserId?: Int_comparison_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  pullRequestId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_insert_input {
  assigneeUserId?: number | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  pullRequestId?: number | null;
}

/**
 * on_conflict condition type for table "git_pull_request_assignees"
 */
export interface git_pull_request_assignees_on_conflict {
  constraint: git_pull_request_assignees_constraint;
  update_columns: git_pull_request_assignees_update_column[];
  where?: git_pull_request_assignees_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_changes"
 */
export interface git_pull_request_changes_arr_rel_insert_input {
  data: git_pull_request_changes_insert_input[];
  on_conflict?: git_pull_request_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_changes". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_changes_bool_exp {
  _and?: git_pull_request_changes_bool_exp[] | null;
  _not?: git_pull_request_changes_bool_exp | null;
  _or?: git_pull_request_changes_bool_exp[] | null;
  assignedGitUsers?: jsonb_comparison_exp | null;
  assignedToReviewGitUsers?: jsonb_comparison_exp | null;
  authorId?: Int_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  baseBranchRef?: String_comparison_exp | null;
  baseHeadCommit?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  gitBranchRefByTargetbranchrefTargetrepoid?: git_branch_refs_bool_exp | null;
  gitCommitByBaseheadcommit?: git_commits_bool_exp | null;
  git_branch_ref?: git_branch_refs_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_pull_request_state?: git_pull_request_state_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  isDraft?: Boolean_comparison_exp | null;
  isMerged?: Boolean_comparison_exp | null;
  mergeable?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  number?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  state?: git_pull_request_state_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  targetBranchRef?: String_comparison_exp | null;
  targetHeadCommit?: String_comparison_exp | null;
  targetRepoId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_changes"
 */
export interface git_pull_request_changes_insert_input {
  assignedGitUsers?: hasura_jsonb | null;
  assignedToReviewGitUsers?: hasura_jsonb | null;
  authorId?: number | null;
  automaticallyChanged?: boolean | null;
  baseBranchRef?: string | null;
  baseHeadCommit?: string | null;
  body?: string | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  gitBranchRefByTargetbranchrefTargetrepoid?: git_branch_refs_obj_rel_insert_input | null;
  gitCommitByBaseheadcommit?: git_commits_obj_rel_insert_input | null;
  git_branch_ref?: git_branch_refs_obj_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_pull_request_state?: git_pull_request_state_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  id?: number | null;
  isDraft?: boolean | null;
  isMerged?: boolean | null;
  mergeable?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  number?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  repoId?: number | null;
  sqlQuery?: string | null;
  state?: git_pull_request_state_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  targetBranchRef?: string | null;
  targetHeadCommit?: string | null;
  targetRepoId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "git_pull_request_changes"
 */
export interface git_pull_request_changes_on_conflict {
  constraint: git_pull_request_changes_constraint;
  update_columns: git_pull_request_changes_update_column[];
  where?: git_pull_request_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_comments"
 */
export interface git_pull_request_comments_arr_rel_insert_input {
  data: git_pull_request_comments_insert_input[];
  on_conflict?: git_pull_request_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_comments". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_comments_bool_exp {
  _and?: git_pull_request_comments_bool_exp[] | null;
  _not?: git_pull_request_comments_bool_exp | null;
  _or?: git_pull_request_comments_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  git_comment_reactions?: git_comment_reactions_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_pull_request_comment?: github_pull_request_comments_bool_exp | null;
  github_pull_request_comments?: github_pull_request_comments_bool_exp | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_comments"
 */
export interface git_pull_request_comments_insert_input {
  authorId?: number | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  git_comment_reactions?: git_comment_reactions_arr_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_pull_request_comment?: github_pull_request_comments_obj_rel_insert_input | null;
  github_pull_request_comments?: github_pull_request_comments_arr_rel_insert_input | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_arr_rel_insert_input | null;
  id?: number | null;
  pullRequestId?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_comments"
 */
export interface git_pull_request_comments_obj_rel_insert_input {
  data: git_pull_request_comments_insert_input;
  on_conflict?: git_pull_request_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_comments"
 */
export interface git_pull_request_comments_on_conflict {
  constraint: git_pull_request_comments_constraint;
  update_columns: git_pull_request_comments_update_column[];
  where?: git_pull_request_comments_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_developer_view". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_developer_view_bool_exp {
  _and?: git_pull_request_developer_view_bool_exp[] | null;
  _not?: git_pull_request_developer_view_bool_exp | null;
  _or?: git_pull_request_developer_view_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isDraft?: Boolean_comparison_exp | null;
  isMerged?: Boolean_comparison_exp | null;
  mergeable?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  state?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_developer_view"
 */
export interface git_pull_request_developer_view_insert_input {
  authorId?: number | null;
  body?: string | null;
  id?: number | null;
  isDraft?: boolean | null;
  isMerged?: boolean | null;
  mergeable?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  repoId?: number | null;
  state?: string | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_developer_view"
 */
export interface git_pull_request_developer_view_obj_rel_insert_input {
  data: git_pull_request_developer_view_insert_input;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_review_comment_state". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_review_comment_state_bool_exp {
  _and?: git_pull_request_review_comment_state_bool_exp[] | null;
  _not?: git_pull_request_review_comment_state_bool_exp | null;
  _or?: git_pull_request_review_comment_state_bool_exp[] | null;
  description?: String_comparison_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "git_pull_request_review_comment_state_enum". All fields are combined with logical 'AND'.
 */
export interface git_pull_request_review_comment_state_enum_comparison_exp {
  _eq?: git_pull_request_review_comment_state_enum | null;
  _in?: git_pull_request_review_comment_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_pull_request_review_comment_state_enum | null;
  _nin?: git_pull_request_review_comment_state_enum[] | null;
}

/**
 * input type for inserting data into table "git_pull_request_review_comment_state"
 */
export interface git_pull_request_review_comment_state_insert_input {
  description?: string | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_review_comment_state"
 */
export interface git_pull_request_review_comment_state_obj_rel_insert_input {
  data: git_pull_request_review_comment_state_insert_input;
  on_conflict?: git_pull_request_review_comment_state_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_review_comment_state"
 */
export interface git_pull_request_review_comment_state_on_conflict {
  constraint: git_pull_request_review_comment_state_constraint;
  update_columns: git_pull_request_review_comment_state_update_column[];
  where?: git_pull_request_review_comment_state_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_review_comments"
 */
export interface git_pull_request_review_comments_arr_rel_insert_input {
  data: git_pull_request_review_comments_insert_input[];
  on_conflict?: git_pull_request_review_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_review_comments". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_review_comments_bool_exp {
  _and?: git_pull_request_review_comments_bool_exp[] | null;
  _not?: git_pull_request_review_comments_bool_exp | null;
  _or?: git_pull_request_review_comments_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  commitId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  diffHunk?: String_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  gitCommitByCommitid?: git_commits_bool_exp | null;
  git_comment_reactions?: git_comment_reactions_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_comment_state?: git_pull_request_review_comment_state_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_thread?: git_pull_request_review_threads_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_bool_exp | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  originalCommitId?: Int_comparison_exp | null;
  originalPosition?: Int_comparison_exp | null;
  outdated?: Boolean_comparison_exp | null;
  path?: String_comparison_exp | null;
  position?: Int_comparison_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  pullRequestReviewId?: Int_comparison_exp | null;
  pullRequestReviewThreadId?: Int_comparison_exp | null;
  replyToCommentId?: Int_comparison_exp | null;
  state?: git_pull_request_review_comment_state_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_review_comments"
 */
export interface git_pull_request_review_comments_insert_input {
  authorId?: number | null;
  body?: string | null;
  commitId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  diffHunk?: string | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  gitCommitByCommitid?: git_commits_obj_rel_insert_input | null;
  git_comment_reactions?: git_comment_reactions_arr_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_review?: git_pull_request_reviews_obj_rel_insert_input | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_obj_rel_insert_input | null;
  git_pull_request_review_comment_state?: git_pull_request_review_comment_state_obj_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  git_pull_request_review_thread?: git_pull_request_review_threads_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_obj_rel_insert_input | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_arr_rel_insert_input | null;
  id?: number | null;
  originalCommitId?: number | null;
  originalPosition?: number | null;
  outdated?: boolean | null;
  path?: string | null;
  position?: number | null;
  pullRequestId?: number | null;
  pullRequestReviewId?: number | null;
  pullRequestReviewThreadId?: number | null;
  replyToCommentId?: number | null;
  state?: git_pull_request_review_comment_state_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_review_comments"
 */
export interface git_pull_request_review_comments_obj_rel_insert_input {
  data: git_pull_request_review_comments_insert_input;
  on_conflict?: git_pull_request_review_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_review_comments"
 */
export interface git_pull_request_review_comments_on_conflict {
  constraint: git_pull_request_review_comments_constraint;
  update_columns: git_pull_request_review_comments_update_column[];
  where?: git_pull_request_review_comments_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_review_state". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_review_state_bool_exp {
  _and?: git_pull_request_review_state_bool_exp[] | null;
  _not?: git_pull_request_review_state_bool_exp | null;
  _or?: git_pull_request_review_state_bool_exp[] | null;
  description?: String_comparison_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "git_pull_request_review_state_enum". All fields are combined with logical 'AND'.
 */
export interface git_pull_request_review_state_enum_comparison_exp {
  _eq?: git_pull_request_review_state_enum | null;
  _in?: git_pull_request_review_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_pull_request_review_state_enum | null;
  _nin?: git_pull_request_review_state_enum[] | null;
}

/**
 * input type for inserting data into table "git_pull_request_review_state"
 */
export interface git_pull_request_review_state_insert_input {
  description?: string | null;
  git_pull_request_reviews?: git_pull_request_reviews_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_review_state"
 */
export interface git_pull_request_review_state_obj_rel_insert_input {
  data: git_pull_request_review_state_insert_input;
  on_conflict?: git_pull_request_review_state_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_review_state"
 */
export interface git_pull_request_review_state_on_conflict {
  constraint: git_pull_request_review_state_constraint;
  update_columns: git_pull_request_review_state_update_column[];
  where?: git_pull_request_review_state_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_review_threads"
 */
export interface git_pull_request_review_threads_arr_rel_insert_input {
  data: git_pull_request_review_threads_insert_input[];
  on_conflict?: git_pull_request_review_threads_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_review_threads". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_review_threads_bool_exp {
  _and?: git_pull_request_review_threads_bool_exp[] | null;
  _not?: git_pull_request_review_threads_bool_exp | null;
  _or?: git_pull_request_review_threads_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  github_pull_request_review_thread?: github_pull_request_review_threads_bool_exp | null;
  github_pull_request_review_threads?: github_pull_request_review_threads_bool_exp | null;
  id?: Int_comparison_exp | null;
  isResolved?: Boolean_comparison_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_review_threads"
 */
export interface git_pull_request_review_threads_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  github_pull_request_review_thread?: github_pull_request_review_threads_obj_rel_insert_input | null;
  github_pull_request_review_threads?: github_pull_request_review_threads_arr_rel_insert_input | null;
  id?: number | null;
  isResolved?: boolean | null;
  pullRequestId?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_review_threads"
 */
export interface git_pull_request_review_threads_obj_rel_insert_input {
  data: git_pull_request_review_threads_insert_input;
  on_conflict?: git_pull_request_review_threads_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_review_threads"
 */
export interface git_pull_request_review_threads_on_conflict {
  constraint: git_pull_request_review_threads_constraint;
  update_columns: git_pull_request_review_threads_update_column[];
  where?: git_pull_request_review_threads_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_arr_rel_insert_input {
  data: git_pull_request_reviewers_insert_input[];
  on_conflict?: git_pull_request_reviewers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_reviewers". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_reviewers_bool_exp {
  _and?: git_pull_request_reviewers_bool_exp[] | null;
  _not?: git_pull_request_reviewers_bool_exp | null;
  _or?: git_pull_request_reviewers_bool_exp[] | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  reviewerUserId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_insert_input {
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  pullRequestId?: number | null;
  reviewerUserId?: number | null;
}

/**
 * on_conflict condition type for table "git_pull_request_reviewers"
 */
export interface git_pull_request_reviewers_on_conflict {
  constraint: git_pull_request_reviewers_constraint;
  update_columns: git_pull_request_reviewers_update_column[];
  where?: git_pull_request_reviewers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_arr_rel_insert_input {
  data: git_pull_request_reviews_insert_input[];
  on_conflict?: git_pull_request_reviews_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_reviews". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_reviews_bool_exp {
  _and?: git_pull_request_reviews_bool_exp[] | null;
  _not?: git_pull_request_reviews_bool_exp | null;
  _or?: git_pull_request_reviews_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  git_comment_reactions?: git_comment_reactions_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_state?: git_pull_request_review_state_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_pull_request_review?: github_pull_request_reviews_bool_exp | null;
  github_pull_request_reviews?: github_pull_request_reviews_bool_exp | null;
  id?: Int_comparison_exp | null;
  pullRequestId?: Int_comparison_exp | null;
  state?: git_pull_request_review_state_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  taskReviewId?: Int_comparison_exp | null;
  task_review?: task_reviews_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_insert_input {
  authorId?: number | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  git_comment_reactions?: git_comment_reactions_arr_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  git_pull_request_review_state?: git_pull_request_review_state_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_pull_request_review?: github_pull_request_reviews_obj_rel_insert_input | null;
  github_pull_request_reviews?: github_pull_request_reviews_arr_rel_insert_input | null;
  id?: number | null;
  pullRequestId?: number | null;
  state?: git_pull_request_review_state_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  taskReviewId?: number | null;
  task_review?: task_reviews_obj_rel_insert_input | null;
  task_reviews?: task_reviews_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_obj_rel_insert_input {
  data: git_pull_request_reviews_insert_input;
  on_conflict?: git_pull_request_reviews_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_reviews"
 */
export interface git_pull_request_reviews_on_conflict {
  constraint: git_pull_request_reviews_constraint;
  update_columns: git_pull_request_reviews_update_column[];
  where?: git_pull_request_reviews_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_request_slice"
 */
export interface git_pull_request_slice_arr_rel_insert_input {
  data: git_pull_request_slice_insert_input[];
  on_conflict?: git_pull_request_slice_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_slice". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_slice_bool_exp {
  _and?: git_pull_request_slice_bool_exp[] | null;
  _not?: git_pull_request_slice_bool_exp | null;
  _or?: git_pull_request_slice_bool_exp[] | null;
  basePullRequestId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_pull_request_by_base_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_by_pushed_pull_request?: git_pull_requests_bool_exp | null;
  git_repo_slice?: git_repo_slices_bool_exp | null;
  git_slice_pushes?: git_slice_pushes_bool_exp | null;
  id?: Int_comparison_exp | null;
  pushedPullRequestId?: Int_comparison_exp | null;
  repoSliceId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_request_slice"
 */
export interface git_pull_request_slice_insert_input {
  basePullRequestId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_pull_request_by_base_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_by_pushed_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_repo_slice?: git_repo_slices_obj_rel_insert_input | null;
  git_slice_pushes?: git_slice_pushes_arr_rel_insert_input | null;
  id?: number | null;
  pushedPullRequestId?: number | null;
  repoSliceId?: number | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_slice"
 */
export interface git_pull_request_slice_obj_rel_insert_input {
  data: git_pull_request_slice_insert_input;
  on_conflict?: git_pull_request_slice_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_slice"
 */
export interface git_pull_request_slice_on_conflict {
  constraint: git_pull_request_slice_constraint;
  update_columns: git_pull_request_slice_update_column[];
  where?: git_pull_request_slice_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_request_state". All fields are combined with a logical 'AND'.
 */
export interface git_pull_request_state_bool_exp {
  _and?: git_pull_request_state_bool_exp[] | null;
  _not?: git_pull_request_state_bool_exp | null;
  _or?: git_pull_request_state_bool_exp[] | null;
  description?: String_comparison_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "git_pull_request_state_enum". All fields are combined with logical 'AND'.
 */
export interface git_pull_request_state_enum_comparison_exp {
  _eq?: git_pull_request_state_enum | null;
  _in?: git_pull_request_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_pull_request_state_enum | null;
  _nin?: git_pull_request_state_enum[] | null;
}

/**
 * input type for inserting data into table "git_pull_request_state"
 */
export interface git_pull_request_state_insert_input {
  description?: string | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_request_state"
 */
export interface git_pull_request_state_obj_rel_insert_input {
  data: git_pull_request_state_insert_input;
  on_conflict?: git_pull_request_state_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_request_state"
 */
export interface git_pull_request_state_on_conflict {
  constraint: git_pull_request_state_constraint;
  update_columns: git_pull_request_state_update_column[];
  where?: git_pull_request_state_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_pull_requests"
 */
export interface git_pull_requests_arr_rel_insert_input {
  data: git_pull_requests_insert_input[];
  on_conflict?: git_pull_requests_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_pull_requests". All fields are combined with a logical 'AND'.
 */
export interface git_pull_requests_bool_exp {
  _and?: git_pull_requests_bool_exp[] | null;
  _not?: git_pull_requests_bool_exp | null;
  _or?: git_pull_requests_bool_exp[] | null;
  assignedGitUsers?: jsonb_comparison_exp | null;
  assignedToReviewGitUsers?: jsonb_comparison_exp | null;
  authorId?: Int_comparison_exp | null;
  baseBranchRef?: String_comparison_exp | null;
  baseHeadCommit?: String_comparison_exp | null;
  base_branch_ref?: git_branch_refs_bool_exp | null;
  base_head_commit?: git_commits_bool_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_git_pull_request?: git_pull_request_developer_view_bool_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  gitPullRequestSliceByPushedpullrequestid?: git_pull_request_slice_bool_exp | null;
  git_comment_reactions?: git_comment_reactions_bool_exp | null;
  git_pull_request_assignees?: git_pull_request_assignees_bool_exp | null;
  git_pull_request_comments?: git_pull_request_comments_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_threads?: git_pull_request_review_threads_bool_exp | null;
  git_pull_request_reviewers?: git_pull_request_reviewers_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_slice?: git_pull_request_slice_bool_exp | null;
  git_pull_request_state?: git_pull_request_state_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  gitlab_pull_request?: gitlab_pull_requests_bool_exp | null;
  gitlab_pull_requests?: gitlab_pull_requests_bool_exp | null;
  id?: Int_comparison_exp | null;
  isDraft?: Boolean_comparison_exp | null;
  isMerged?: Boolean_comparison_exp | null;
  mergeable?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  number?: Int_comparison_exp | null;
  pullRequestsByUpstreamPullRequestId?: pull_requests_bool_exp | null;
  pull_requests?: pull_requests_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  state?: git_pull_request_state_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  targetBranchRef?: String_comparison_exp | null;
  targetHeadCommit?: String_comparison_exp | null;
  targetRepoId?: Int_comparison_exp | null;
  target_branch_ref?: git_branch_refs_bool_exp | null;
  target_head_commit?: git_commits_bool_exp | null;
  taskChangesBySlicedpullrequestid?: task_changes_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_pull_requests"
 */
export interface git_pull_requests_insert_input {
  assignedGitUsers?: hasura_jsonb | null;
  assignedToReviewGitUsers?: hasura_jsonb | null;
  authorId?: number | null;
  baseBranchRef?: string | null;
  baseHeadCommit?: string | null;
  base_branch_ref?: git_branch_refs_obj_rel_insert_input | null;
  base_head_commit?: git_commits_obj_rel_insert_input | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dev_git_pull_request?: git_pull_request_developer_view_obj_rel_insert_input | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  gitPullRequestSliceByPushedpullrequestid?: git_pull_request_slice_arr_rel_insert_input | null;
  git_comment_reactions?: git_comment_reactions_arr_rel_insert_input | null;
  git_pull_request_assignees?: git_pull_request_assignees_arr_rel_insert_input | null;
  git_pull_request_comments?: git_pull_request_comments_arr_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  git_pull_request_review_threads?: git_pull_request_review_threads_arr_rel_insert_input | null;
  git_pull_request_reviewers?: git_pull_request_reviewers_arr_rel_insert_input | null;
  git_pull_request_reviews?: git_pull_request_reviews_arr_rel_insert_input | null;
  git_pull_request_slice?: git_pull_request_slice_arr_rel_insert_input | null;
  git_pull_request_state?: git_pull_request_state_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  gitlab_pull_request?: gitlab_pull_requests_obj_rel_insert_input | null;
  gitlab_pull_requests?: gitlab_pull_requests_arr_rel_insert_input | null;
  id?: number | null;
  isDraft?: boolean | null;
  isMerged?: boolean | null;
  mergeable?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  number?: number | null;
  pullRequestsByUpstreamPullRequestId?: pull_requests_arr_rel_insert_input | null;
  pull_requests?: pull_requests_arr_rel_insert_input | null;
  repoId?: number | null;
  state?: git_pull_request_state_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  targetBranchRef?: string | null;
  targetHeadCommit?: string | null;
  targetRepoId?: number | null;
  target_branch_ref?: git_branch_refs_obj_rel_insert_input | null;
  target_head_commit?: git_commits_obj_rel_insert_input | null;
  taskChangesBySlicedpullrequestid?: task_changes_arr_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_pull_requests"
 */
export interface git_pull_requests_obj_rel_insert_input {
  data: git_pull_requests_insert_input;
  on_conflict?: git_pull_requests_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_pull_requests"
 */
export interface git_pull_requests_on_conflict {
  constraint: git_pull_requests_constraint;
  update_columns: git_pull_requests_update_column[];
  where?: git_pull_requests_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_repo_changes"
 */
export interface git_repo_changes_arr_rel_insert_input {
  data: git_repo_changes_insert_input[];
  on_conflict?: git_repo_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_repo_changes". All fields are combined with a logical 'AND'.
 */
export interface git_repo_changes_bool_exp {
  _and?: git_repo_changes_bool_exp[] | null;
  _not?: git_repo_changes_bool_exp | null;
  _or?: git_repo_changes_bool_exp[] | null;
  accessGitUserIds?: jsonb_comparison_exp | null;
  authUrl?: String_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_source?: git_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isDisabled?: Boolean_comparison_exp | null;
  isFork?: Boolean_comparison_exp | null;
  isLocked?: Boolean_comparison_exp | null;
  isPrivate?: Boolean_comparison_exp | null;
  isTemplate?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  operation?: String_comparison_exp | null;
  organizationId?: Int_comparison_exp | null;
  owner?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  source?: git_sources_enum_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "git_repo_changes"
 */
export interface git_repo_changes_insert_input {
  accessGitUserIds?: hasura_jsonb | null;
  authUrl?: string | null;
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  git_organization?: git_organizations_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_source?: git_sources_obj_rel_insert_input | null;
  id?: number | null;
  isArchived?: boolean | null;
  isDisabled?: boolean | null;
  isFork?: boolean | null;
  isLocked?: boolean | null;
  isPrivate?: boolean | null;
  isTemplate?: boolean | null;
  name?: string | null;
  operation?: string | null;
  organizationId?: number | null;
  owner?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  source?: git_sources_enum | null;
  sqlQuery?: string | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "git_repo_changes"
 */
export interface git_repo_changes_on_conflict {
  constraint: git_repo_changes_constraint;
  update_columns: git_repo_changes_update_column[];
  where?: git_repo_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_repo_slice_changes"
 */
export interface git_repo_slice_changes_arr_rel_insert_input {
  data: git_repo_slice_changes_insert_input[];
  on_conflict?: git_repo_slice_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_repo_slice_changes". All fields are combined with a logical 'AND'.
 */
export interface git_repo_slice_changes_bool_exp {
  _and?: git_repo_slice_changes_bool_exp[] | null;
  _not?: git_repo_slice_changes_bool_exp | null;
  _or?: git_repo_slice_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitBranchRefBySlicedfromrepoidSlicedfrombranchname?: git_branch_refs_bool_exp | null;
  git_branch_ref?: git_branch_refs_bool_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  handledByAction?: Boolean_comparison_exp | null;
  id?: Int_comparison_exp | null;
  ignoredPaths?: _text_comparison_exp | null;
  latestCommitSliceId?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  pullIntervalInHr?: Int_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  slicedFolders?: _text_comparison_exp | null;
  slicedFromBranchName?: String_comparison_exp | null;
  slicedFromRepoId?: Int_comparison_exp | null;
  slicedToBranchName?: String_comparison_exp | null;
  slicedToRepoId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "git_repo_slice_changes"
 */
export interface git_repo_slice_changes_insert_input {
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitBranchRefBySlicedfromrepoidSlicedfrombranchname?: git_branch_refs_obj_rel_insert_input | null;
  git_branch_ref?: git_branch_refs_obj_rel_insert_input | null;
  git_commit_slice?: git_commit_slices_obj_rel_insert_input | null;
  handledByAction?: boolean | null;
  id?: number | null;
  ignoredPaths?: hasura__text | null;
  latestCommitSliceId?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  pullIntervalInHr?: number | null;
  reasonForChange?: string | null;
  slicedFolders?: hasura__text | null;
  slicedFromBranchName?: string | null;
  slicedFromRepoId?: number | null;
  slicedToBranchName?: string | null;
  slicedToRepoId?: number | null;
  sqlQuery?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "git_repo_slice_changes"
 */
export interface git_repo_slice_changes_on_conflict {
  constraint: git_repo_slice_changes_constraint;
  update_columns: git_repo_slice_changes_update_column[];
  where?: git_repo_slice_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_repo_slices"
 */
export interface git_repo_slices_arr_rel_insert_input {
  data: git_repo_slices_insert_input[];
  on_conflict?: git_repo_slices_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_repo_slices". All fields are combined with a logical 'AND'.
 */
export interface git_repo_slices_bool_exp {
  _and?: git_repo_slices_bool_exp[] | null;
  _not?: git_repo_slices_bool_exp | null;
  _or?: git_repo_slices_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_branch_ref_sliced_from?: git_branch_refs_bool_exp | null;
  git_branch_ref_sliced_to?: git_branch_refs_bool_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_pull_request_slice?: git_pull_request_slice_bool_exp | null;
  git_repo_sliced_from?: git_repos_bool_exp | null;
  git_repo_sliced_to?: git_repos_bool_exp | null;
  git_slice_pulls?: git_slice_pulls_bool_exp | null;
  handledByAction?: Boolean_comparison_exp | null;
  id?: Int_comparison_exp | null;
  ignoredPaths?: _text_comparison_exp | null;
  latestCommitSliceId?: Int_comparison_exp | null;
  pullIntervalInHr?: Int_comparison_exp | null;
  repository_owners?: repository_owners_bool_exp | null;
  slicedFolders?: _text_comparison_exp | null;
  slicedFromBranchName?: String_comparison_exp | null;
  slicedFromRepoId?: Int_comparison_exp | null;
  slicedToBranchName?: String_comparison_exp | null;
  slicedToRepoId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_repo_slices"
 */
export interface git_repo_slices_insert_input {
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_branch_ref_sliced_from?: git_branch_refs_obj_rel_insert_input | null;
  git_branch_ref_sliced_to?: git_branch_refs_obj_rel_insert_input | null;
  git_commit_slice?: git_commit_slices_obj_rel_insert_input | null;
  git_pull_request_slice?: git_pull_request_slice_arr_rel_insert_input | null;
  git_repo_sliced_from?: git_repos_obj_rel_insert_input | null;
  git_repo_sliced_to?: git_repos_obj_rel_insert_input | null;
  git_slice_pulls?: git_slice_pulls_arr_rel_insert_input | null;
  handledByAction?: boolean | null;
  id?: number | null;
  ignoredPaths?: hasura__text | null;
  latestCommitSliceId?: number | null;
  pullIntervalInHr?: number | null;
  repository_owners?: repository_owners_arr_rel_insert_input | null;
  slicedFolders?: hasura__text | null;
  slicedFromBranchName?: string | null;
  slicedFromRepoId?: number | null;
  slicedToBranchName?: string | null;
  slicedToRepoId?: number | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_repo_slices"
 */
export interface git_repo_slices_obj_rel_insert_input {
  data: git_repo_slices_insert_input;
  on_conflict?: git_repo_slices_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_repo_slices"
 */
export interface git_repo_slices_on_conflict {
  constraint: git_repo_slices_constraint;
  update_columns: git_repo_slices_update_column[];
  where?: git_repo_slices_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_repo_users"
 */
export interface git_repo_users_arr_rel_insert_input {
  data: git_repo_users_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "git_repo_users". All fields are combined with a logical 'AND'.
 */
export interface git_repo_users_bool_exp {
  _and?: git_repo_users_bool_exp[] | null;
  _not?: git_repo_users_bool_exp | null;
  _or?: git_repo_users_bool_exp[] | null;
  git_repo?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_repo_users"
 */
export interface git_repo_users_insert_input {
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  repoId?: number | null;
  userId?: number | null;
}

/**
 * input type for inserting array relation for remote table "git_repos"
 */
export interface git_repos_arr_rel_insert_input {
  data: git_repos_insert_input[];
  on_conflict?: git_repos_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_repos". All fields are combined with a logical 'AND'.
 */
export interface git_repos_bool_exp {
  _and?: git_repos_bool_exp[] | null;
  _not?: git_repos_bool_exp | null;
  _or?: git_repos_bool_exp[] | null;
  accessGitUserIds?: jsonb_comparison_exp | null;
  authUrl?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project_changes?: client_project_changes_bool_exp | null;
  client_project_repositories?: client_project_repositories_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  git_branch_refs?: git_branch_refs_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo_changes?: git_repo_changes_bool_exp | null;
  git_repo_sliced_to?: git_repo_slices_bool_exp | null;
  git_repo_users?: git_repo_users_bool_exp | null;
  git_repos_sliced_from?: git_repo_slices_bool_exp | null;
  git_source?: git_sources_bool_exp | null;
  github_repo_by_sid?: github_repos_bool_exp | null;
  gitlab_project_by_sid?: gitlab_projects_bool_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isDisabled?: Boolean_comparison_exp | null;
  isFork?: Boolean_comparison_exp | null;
  isLocked?: Boolean_comparison_exp | null;
  isPrivate?: Boolean_comparison_exp | null;
  isTemplate?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  organizationId?: Int_comparison_exp | null;
  owner?: String_comparison_exp | null;
  source?: git_sources_enum_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticketChangesByGitrepoid?: ticket_changes_bool_exp | null;
  ticketSourceChangesByGitrepoid?: ticket_source_changes_bool_exp | null;
  ticketSourcesByGitrepoid?: ticket_sources_bool_exp | null;
  ticketSourcesByRepoid?: ticket_sources_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_repos?: ticket_repos_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_onboarded_repos?: user_onboarded_repos_bool_exp | null;
}

/**
 * input type for inserting data into table "git_repos"
 */
export interface git_repos_insert_input {
  accessGitUserIds?: hasura_jsonb | null;
  authUrl?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_project_changes?: client_project_changes_arr_rel_insert_input | null;
  client_project_repositories?: client_project_repositories_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  git_branch_refs?: git_branch_refs_arr_rel_insert_input | null;
  git_commits?: git_commits_arr_rel_insert_input | null;
  git_organization?: git_organizations_obj_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  git_repo_changes?: git_repo_changes_arr_rel_insert_input | null;
  git_repo_sliced_to?: git_repo_slices_obj_rel_insert_input | null;
  git_repo_users?: git_repo_users_arr_rel_insert_input | null;
  git_repos_sliced_from?: git_repo_slices_arr_rel_insert_input | null;
  git_source?: git_sources_obj_rel_insert_input | null;
  github_repo_by_sid?: github_repos_obj_rel_insert_input | null;
  gitlab_project_by_sid?: gitlab_projects_obj_rel_insert_input | null;
  id?: number | null;
  isArchived?: boolean | null;
  isDisabled?: boolean | null;
  isFork?: boolean | null;
  isLocked?: boolean | null;
  isPrivate?: boolean | null;
  isTemplate?: boolean | null;
  name?: string | null;
  organizationId?: number | null;
  owner?: string | null;
  source?: git_sources_enum | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticketChangesByGitrepoid?: ticket_changes_arr_rel_insert_input | null;
  ticketSourceChangesByGitrepoid?: ticket_source_changes_arr_rel_insert_input | null;
  ticketSourcesByGitrepoid?: ticket_sources_arr_rel_insert_input | null;
  ticketSourcesByRepoid?: ticket_sources_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_repos?: ticket_repos_arr_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_obj_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user_onboarded_repos?: user_onboarded_repos_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "git_repos"
 */
export interface git_repos_obj_rel_insert_input {
  data: git_repos_insert_input;
  on_conflict?: git_repos_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_repos"
 */
export interface git_repos_on_conflict {
  constraint: git_repos_constraint;
  update_columns: git_repos_update_column[];
  where?: git_repos_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_slice_pulls"
 */
export interface git_slice_pulls_arr_rel_insert_input {
  data: git_slice_pulls_insert_input[];
  on_conflict?: git_slice_pulls_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_slice_pulls". All fields are combined with a logical 'AND'.
 */
export interface git_slice_pulls_bool_exp {
  _and?: git_slice_pulls_bool_exp[] | null;
  _not?: git_slice_pulls_bool_exp | null;
  _or?: git_slice_pulls_bool_exp[] | null;
  commitSliceId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  error?: jsonb_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_repo_slice?: git_repo_slices_bool_exp | null;
  id?: Int_comparison_exp | null;
  logs?: String_comparison_exp | null;
  repoSliceId?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_slice_pulls"
 */
export interface git_slice_pulls_insert_input {
  commitSliceId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  error?: hasura_jsonb | null;
  finishedAt?: hasura_timestamptz | null;
  git_commit_slice?: git_commit_slices_obj_rel_insert_input | null;
  git_repo_slice?: git_repo_slices_obj_rel_insert_input | null;
  id?: number | null;
  logs?: string | null;
  repoSliceId?: number | null;
  startedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_slice_pulls"
 */
export interface git_slice_pulls_obj_rel_insert_input {
  data: git_slice_pulls_insert_input;
  on_conflict?: git_slice_pulls_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_slice_pulls"
 */
export interface git_slice_pulls_on_conflict {
  constraint: git_slice_pulls_constraint;
  update_columns: git_slice_pulls_update_column[];
  where?: git_slice_pulls_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "git_slice_pushes"
 */
export interface git_slice_pushes_arr_rel_insert_input {
  data: git_slice_pushes_insert_input[];
  on_conflict?: git_slice_pushes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_slice_pushes". All fields are combined with a logical 'AND'.
 */
export interface git_slice_pushes_bool_exp {
  _and?: git_slice_pushes_bool_exp[] | null;
  _not?: git_slice_pushes_bool_exp | null;
  _or?: git_slice_pushes_bool_exp[] | null;
  commitSliceId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  error?: jsonb_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  git_commit_slice?: git_commit_slices_bool_exp | null;
  git_pull_request_slice?: git_pull_request_slice_bool_exp | null;
  id?: Int_comparison_exp | null;
  logs?: String_comparison_exp | null;
  pullRequestSliceId?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_slice_pushes"
 */
export interface git_slice_pushes_insert_input {
  commitSliceId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  error?: hasura_jsonb | null;
  finishedAt?: hasura_timestamptz | null;
  git_commit_slice?: git_commit_slices_obj_rel_insert_input | null;
  git_pull_request_slice?: git_pull_request_slice_obj_rel_insert_input | null;
  id?: number | null;
  logs?: string | null;
  pullRequestSliceId?: number | null;
  startedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_slice_pushes"
 */
export interface git_slice_pushes_obj_rel_insert_input {
  data: git_slice_pushes_insert_input;
  on_conflict?: git_slice_pushes_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_slice_pushes"
 */
export interface git_slice_pushes_on_conflict {
  constraint: git_slice_pushes_constraint;
  update_columns: git_slice_pushes_update_column[];
  where?: git_slice_pushes_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_sources". All fields are combined with a logical 'AND'.
 */
export interface git_sources_bool_exp {
  _and?: git_sources_bool_exp[] | null;
  _not?: git_sources_bool_exp | null;
  _or?: git_sources_bool_exp[] | null;
  description?: String_comparison_exp | null;
  git_repo_changes?: git_repo_changes_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  id?: String_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "git_sources_enum". All fields are combined with logical 'AND'.
 */
export interface git_sources_enum_comparison_exp {
  _eq?: git_sources_enum | null;
  _in?: git_sources_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_sources_enum | null;
  _nin?: git_sources_enum[] | null;
}

/**
 * input type for inserting data into table "git_sources"
 */
export interface git_sources_insert_input {
  description?: string | null;
  git_repo_changes?: git_repo_changes_arr_rel_insert_input | null;
  git_repos?: git_repos_arr_rel_insert_input | null;
  id?: string | null;
  user_events?: user_events_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "git_sources"
 */
export interface git_sources_obj_rel_insert_input {
  data: git_sources_insert_input;
  on_conflict?: git_sources_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_sources"
 */
export interface git_sources_on_conflict {
  constraint: git_sources_constraint;
  update_columns: git_sources_update_column[];
  where?: git_sources_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "git_user_developer_view". All fields are combined with a logical 'AND'.
 */
export interface git_user_developer_view_bool_exp {
  _and?: git_user_developer_view_bool_exp[] | null;
  _not?: git_user_developer_view_bool_exp | null;
  _or?: git_user_developer_view_bool_exp[] | null;
  agencyId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  password?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_user_developer_view"
 */
export interface git_user_developer_view_insert_input {
  agencyId?: string | null;
  createdAt?: hasura_timestamptz | null;
  email?: string | null;
  id?: number | null;
  name?: string | null;
  password?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "git_user_developer_view"
 */
export interface git_user_developer_view_obj_rel_insert_input {
  data: git_user_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "git_users"
 */
export interface git_users_arr_rel_insert_input {
  data: git_users_insert_input[];
  on_conflict?: git_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "git_users". All fields are combined with a logical 'AND'.
 */
export interface git_users_bool_exp {
  _and?: git_users_bool_exp[] | null;
  _not?: git_users_bool_exp | null;
  _or?: git_users_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  avatarUrl?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_git_users?: git_user_developer_view_bool_exp | null;
  email?: String_comparison_exp | null;
  git_comment_reactions?: git_comment_reactions_bool_exp | null;
  git_commit_comments?: git_commit_comments_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_pull_request_assignees?: git_pull_request_assignees_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_request_comments?: git_pull_request_comments_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_reviewers?: git_pull_request_reviewers_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo_users?: git_repo_users_bool_exp | null;
  githubAccountByGithubaccountlogin?: github_accounts_bool_exp | null;
  githubAccountLogin?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  gitlabUserByGitlabuserlogin?: gitlab_users_bool_exp | null;
  gitlabUserLogin?: String_comparison_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  gitlab_users?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  password?: String_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "git_users"
 */
export interface git_users_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  avatarUrl?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dev_git_users?: git_user_developer_view_obj_rel_insert_input | null;
  email?: string | null;
  git_comment_reactions?: git_comment_reactions_arr_rel_insert_input | null;
  git_commit_comments?: git_commit_comments_arr_rel_insert_input | null;
  git_commits?: git_commits_arr_rel_insert_input | null;
  git_pull_request_assignees?: git_pull_request_assignees_arr_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_request_comments?: git_pull_request_comments_arr_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  git_pull_request_reviewers?: git_pull_request_reviewers_arr_rel_insert_input | null;
  git_pull_request_reviews?: git_pull_request_reviews_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  git_repo_users?: git_repo_users_arr_rel_insert_input | null;
  githubAccountByGithubaccountlogin?: github_accounts_obj_rel_insert_input | null;
  githubAccountLogin?: string | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_accounts?: github_accounts_arr_rel_insert_input | null;
  gitlabUserByGitlabuserlogin?: gitlab_users_obj_rel_insert_input | null;
  gitlabUserLogin?: string | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  gitlab_users?: gitlab_users_arr_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  password?: string | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "git_users"
 */
export interface git_users_obj_rel_insert_input {
  data: git_users_insert_input;
  on_conflict?: git_users_on_conflict | null;
}

/**
 * on_conflict condition type for table "git_users"
 */
export interface git_users_on_conflict {
  constraint: git_users_constraint;
  update_columns: git_users_update_column[];
  where?: git_users_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_accounts"
 */
export interface github_accounts_arr_rel_insert_input {
  data: github_accounts_insert_input[];
  on_conflict?: github_accounts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_accounts". All fields are combined with a logical 'AND'.
 */
export interface github_accounts_bool_exp {
  _and?: github_accounts_bool_exp[] | null;
  _not?: github_accounts_bool_exp | null;
  _or?: github_accounts_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  avatarUrl?: String_comparison_exp | null;
  bot?: bots_bool_exp | null;
  bots?: bots_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  gitUserByLogin?: git_users_bool_exp | null;
  gitUserId?: Int_comparison_exp | null;
  git_user?: git_users_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  github_app_installations?: github_app_installations_bool_exp | null;
  github_apps?: github_apps_bool_exp | null;
  github_code_comments?: github_code_comments_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  github_comments?: github_comments_bool_exp | null;
  github_commit_comments?: github_commit_comments_bool_exp | null;
  github_commits?: github_commits_bool_exp | null;
  github_issue_assignees?: github_issue_assignees_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  github_issue_comments?: github_issue_comments_bool_exp | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_bool_exp | null;
  github_issues?: github_issues_bool_exp | null;
  github_organization_members?: github_organization_members_bool_exp | null;
  github_pull_request_comments?: github_pull_request_comments_bool_exp | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_bool_exp | null;
  github_repo_users?: github_repo_users_bool_exp | null;
  github_team_members?: github_team_members_bool_exp | null;
  github_token?: github_tokens_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  password?: String_comparison_exp | null;
  personalAccessToken?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "github_accounts"
 */
export interface github_accounts_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  avatarUrl?: string | null;
  bot?: bots_obj_rel_insert_input | null;
  bots?: bots_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  email?: string | null;
  gitUserByLogin?: git_users_obj_rel_insert_input | null;
  gitUserId?: number | null;
  git_user?: git_users_obj_rel_insert_input | null;
  git_users?: git_users_arr_rel_insert_input | null;
  github_app_installations?: github_app_installations_arr_rel_insert_input | null;
  github_apps?: github_apps_arr_rel_insert_input | null;
  github_code_comments?: github_code_comments_arr_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  github_comments?: github_comments_arr_rel_insert_input | null;
  github_commit_comments?: github_commit_comments_arr_rel_insert_input | null;
  github_commits?: github_commits_arr_rel_insert_input | null;
  github_issue_assignees?: github_issue_assignees_arr_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  github_issue_comments?: github_issue_comments_arr_rel_insert_input | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_arr_rel_insert_input | null;
  github_issues?: github_issues_arr_rel_insert_input | null;
  github_organization_members?: github_organization_members_arr_rel_insert_input | null;
  github_pull_request_comments?: github_pull_request_comments_arr_rel_insert_input | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_arr_rel_insert_input | null;
  github_repo_users?: github_repo_users_arr_rel_insert_input | null;
  github_team_members?: github_team_members_arr_rel_insert_input | null;
  github_token?: github_tokens_obj_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  name?: string | null;
  password?: string | null;
  personalAccessToken?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "github_accounts"
 */
export interface github_accounts_obj_rel_insert_input {
  data: github_accounts_insert_input;
  on_conflict?: github_accounts_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_accounts"
 */
export interface github_accounts_on_conflict {
  constraint: github_accounts_constraint;
  update_columns: github_accounts_update_column[];
  where?: github_accounts_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_app_installations"
 */
export interface github_app_installations_arr_rel_insert_input {
  data: github_app_installations_insert_input[];
  on_conflict?: github_app_installations_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_app_installations". All fields are combined with a logical 'AND'.
 */
export interface github_app_installations_bool_exp {
  _and?: github_app_installations_bool_exp[] | null;
  _not?: github_app_installations_bool_exp | null;
  _or?: github_app_installations_bool_exp[] | null;
  accountSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  githubAppSid?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_app?: github_apps_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  organizationSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_app_installations"
 */
export interface github_app_installations_insert_input {
  accountSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  githubAppSid?: string | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_app?: github_apps_obj_rel_insert_input | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  id?: number | null;
  organizationSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_app_installations"
 */
export interface github_app_installations_obj_rel_insert_input {
  data: github_app_installations_insert_input;
  on_conflict?: github_app_installations_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_app_installations"
 */
export interface github_app_installations_on_conflict {
  constraint: github_app_installations_constraint;
  update_columns: github_app_installations_update_column[];
  where?: github_app_installations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_apps"
 */
export interface github_apps_arr_rel_insert_input {
  data: github_apps_insert_input[];
  on_conflict?: github_apps_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_apps". All fields are combined with a logical 'AND'.
 */
export interface github_apps_bool_exp {
  _and?: github_apps_bool_exp[] | null;
  _not?: github_apps_bool_exp | null;
  _or?: github_apps_bool_exp[] | null;
  adminAccountSid?: String_comparison_exp | null;
  admingOrganizationSid?: String_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  clientSecret?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_app_installations?: github_app_installations_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  oAuthKey?: String_comparison_exp | null;
  oAuthSecret?: String_comparison_exp | null;
  privateKey?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_apps"
 */
export interface github_apps_insert_input {
  adminAccountSid?: string | null;
  admingOrganizationSid?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_app_installations?: github_app_installations_arr_rel_insert_input | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  oAuthKey?: string | null;
  oAuthSecret?: string | null;
  privateKey?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_apps"
 */
export interface github_apps_obj_rel_insert_input {
  data: github_apps_insert_input;
  on_conflict?: github_apps_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_apps"
 */
export interface github_apps_on_conflict {
  constraint: github_apps_constraint;
  update_columns: github_apps_update_column[];
  where?: github_apps_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_branch_commits"
 */
export interface github_branch_commits_arr_rel_insert_input {
  data: github_branch_commits_insert_input[];
  on_conflict?: github_branch_commits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_branch_commits". All fields are combined with a logical 'AND'.
 */
export interface github_branch_commits_bool_exp {
  _and?: github_branch_commits_bool_exp[] | null;
  _not?: github_branch_commits_bool_exp | null;
  _or?: github_branch_commits_bool_exp[] | null;
  branchSid?: String_comparison_exp | null;
  commitSid?: String_comparison_exp | null;
  github_branch?: github_branches_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
}

/**
 * input type for inserting data into table "github_branch_commits"
 */
export interface github_branch_commits_insert_input {
  branchSid?: string | null;
  commitSid?: string | null;
  github_branch?: github_branches_obj_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "github_branch_commits"
 */
export interface github_branch_commits_on_conflict {
  constraint: github_branch_commits_constraint;
  update_columns: github_branch_commits_update_column[];
  where?: github_branch_commits_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_branches"
 */
export interface github_branches_arr_rel_insert_input {
  data: github_branches_insert_input[];
  on_conflict?: github_branches_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_branches". All fields are combined with a logical 'AND'.
 */
export interface github_branches_bool_exp {
  _and?: github_branches_bool_exp[] | null;
  _not?: github_branches_bool_exp | null;
  _or?: github_branches_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitBranchId?: Int_comparison_exp | null;
  git_branch?: git_branches_bool_exp | null;
  github_branch_commits?: github_branch_commits_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  headCommitSid?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  oid?: String_comparison_exp | null;
  repoSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_branches"
 */
export interface github_branches_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitBranchId?: number | null;
  git_branch?: git_branches_obj_rel_insert_input | null;
  github_branch_commits?: github_branch_commits_arr_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  headCommitSid?: string | null;
  id?: number | null;
  name?: string | null;
  oid?: string | null;
  repoSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_branches"
 */
export interface github_branches_obj_rel_insert_input {
  data: github_branches_insert_input;
  on_conflict?: github_branches_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_branches"
 */
export interface github_branches_on_conflict {
  constraint: github_branches_constraint;
  update_columns: github_branches_update_column[];
  where?: github_branches_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_code_comments"
 */
export interface github_code_comments_arr_rel_insert_input {
  data: github_code_comments_insert_input[];
  on_conflict?: github_code_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_code_comments". All fields are combined with a logical 'AND'.
 */
export interface github_code_comments_bool_exp {
  _and?: github_code_comments_bool_exp[] | null;
  _not?: github_code_comments_bool_exp | null;
  _or?: github_code_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  commitSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  diffHunk?: String_comparison_exp | null;
  expiresInType?: job_intervals_enum_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  id?: Int_comparison_exp | null;
  isForked?: Boolean_comparison_exp | null;
  isOutdated?: Boolean_comparison_exp | null;
  job_interval?: job_intervals_bool_exp | null;
  path?: String_comparison_exp | null;
  pullRequestSid?: String_comparison_exp | null;
  redundantFields?: jsonb_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncError?: jsonb_comparison_exp | null;
  syncStartedAt?: timestamptz_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_code_comments"
 */
export interface github_code_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  commitSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  diffHunk?: string | null;
  expiresInType?: job_intervals_enum | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  id?: number | null;
  isForked?: boolean | null;
  isOutdated?: boolean | null;
  job_interval?: job_intervals_obj_rel_insert_input | null;
  path?: string | null;
  pullRequestSid?: string | null;
  redundantFields?: hasura_jsonb | null;
  sid?: string | null;
  syncError?: hasura_jsonb | null;
  syncStartedAt?: hasura_timestamptz | null;
  syncedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "github_code_comments"
 */
export interface github_code_comments_on_conflict {
  constraint: github_code_comments_constraint;
  update_columns: github_code_comments_update_column[];
  where?: github_code_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_comment_reactions"
 */
export interface github_comment_reactions_arr_rel_insert_input {
  data: github_comment_reactions_insert_input[];
  on_conflict?: github_comment_reactions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_comment_reactions". All fields are combined with a logical 'AND'.
 */
export interface github_comment_reactions_bool_exp {
  _and?: github_comment_reactions_bool_exp[] | null;
  _not?: github_comment_reactions_bool_exp | null;
  _or?: github_comment_reactions_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  content?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitCommentReactionId?: Int_comparison_exp | null;
  git_comment_reaction?: git_comment_reactions_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  github_issue_comment?: github_issue_comments_bool_exp | null;
  github_pull_request_comment?: github_pull_request_comments_bool_exp | null;
  github_pull_request_review?: github_pull_request_reviews_bool_exp | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueCommentSid?: String_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  pullRequestCommentSid?: String_comparison_exp | null;
  pullRequestReviewCommentSid?: String_comparison_exp | null;
  pullRequestReviewSid?: String_comparison_exp | null;
  pullRequestSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_comment_reactions"
 */
export interface github_comment_reactions_insert_input {
  authorLogin?: string | null;
  content?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitCommentReactionId?: number | null;
  git_comment_reaction?: git_comment_reactions_obj_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  github_issue_comment?: github_issue_comments_obj_rel_insert_input | null;
  github_pull_request_comment?: github_pull_request_comments_obj_rel_insert_input | null;
  github_pull_request_review?: github_pull_request_reviews_obj_rel_insert_input | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_obj_rel_insert_input | null;
  id?: number | null;
  issueCommentSid?: string | null;
  issueSid?: string | null;
  pullRequestCommentSid?: string | null;
  pullRequestReviewCommentSid?: string | null;
  pullRequestReviewSid?: string | null;
  pullRequestSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_comment_reactions"
 */
export interface github_comment_reactions_obj_rel_insert_input {
  data: github_comment_reactions_insert_input;
  on_conflict?: github_comment_reactions_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_comment_reactions"
 */
export interface github_comment_reactions_on_conflict {
  constraint: github_comment_reactions_constraint;
  update_columns: github_comment_reactions_update_column[];
  where?: github_comment_reactions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_comments"
 */
export interface github_comments_arr_rel_insert_input {
  data: github_comments_insert_input[];
  on_conflict?: github_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_comments". All fields are combined with a logical 'AND'.
 */
export interface github_comments_bool_exp {
  _and?: github_comments_bool_exp[] | null;
  _not?: github_comments_bool_exp | null;
  _or?: github_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  expiresInType?: job_intervals_enum_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  id?: Int_comparison_exp | null;
  isForked?: Boolean_comparison_exp | null;
  isOutdated?: Boolean_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  job_interval?: job_intervals_bool_exp | null;
  pullRequestSid?: String_comparison_exp | null;
  redundantFields?: jsonb_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncError?: jsonb_comparison_exp | null;
  syncStartedAt?: timestamptz_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_comments"
 */
export interface github_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  expiresInType?: job_intervals_enum | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  id?: number | null;
  isForked?: boolean | null;
  isOutdated?: boolean | null;
  issueSid?: string | null;
  job_interval?: job_intervals_obj_rel_insert_input | null;
  pullRequestSid?: string | null;
  redundantFields?: hasura_jsonb | null;
  sid?: string | null;
  syncError?: hasura_jsonb | null;
  syncStartedAt?: hasura_timestamptz | null;
  syncedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "github_comments"
 */
export interface github_comments_on_conflict {
  constraint: github_comments_constraint;
  update_columns: github_comments_update_column[];
  where?: github_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_commit_comments"
 */
export interface github_commit_comments_arr_rel_insert_input {
  data: github_commit_comments_insert_input[];
  on_conflict?: github_commit_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_commit_comments". All fields are combined with a logical 'AND'.
 */
export interface github_commit_comments_bool_exp {
  _and?: github_commit_comments_bool_exp[] | null;
  _not?: github_commit_comments_bool_exp | null;
  _or?: github_commit_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  commitSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  gitCommitCommentId?: Int_comparison_exp | null;
  git_commit_comment?: git_commit_comments_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  id?: Int_comparison_exp | null;
  position?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_commit_comments"
 */
export interface github_commit_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  commitSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  gitCommitCommentId?: number | null;
  git_commit_comment?: git_commit_comments_obj_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  id?: number | null;
  position?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_commit_comments"
 */
export interface github_commit_comments_obj_rel_insert_input {
  data: github_commit_comments_insert_input;
  on_conflict?: github_commit_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_commit_comments"
 */
export interface github_commit_comments_on_conflict {
  constraint: github_commit_comments_constraint;
  update_columns: github_commit_comments_update_column[];
  where?: github_commit_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_commit_contexts"
 */
export interface github_commit_contexts_arr_rel_insert_input {
  data: github_commit_contexts_insert_input[];
  on_conflict?: github_commit_contexts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_commit_contexts". All fields are combined with a logical 'AND'.
 */
export interface github_commit_contexts_bool_exp {
  _and?: github_commit_contexts_bool_exp[] | null;
  _not?: github_commit_contexts_bool_exp | null;
  _or?: github_commit_contexts_bool_exp[] | null;
  avatarUrl?: String_comparison_exp | null;
  commitSid?: String_comparison_exp | null;
  context?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  gitCommitContextId?: Int_comparison_exp | null;
  git_commit_context?: git_commit_contexts_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  github_commit_status?: github_commit_status_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  status?: github_commit_status_enum_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  targetUrl?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_commit_contexts"
 */
export interface github_commit_contexts_insert_input {
  avatarUrl?: string | null;
  commitSid?: string | null;
  context?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  gitCommitContextId?: number | null;
  git_commit_context?: git_commit_contexts_obj_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  github_commit_status?: github_commit_status_obj_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  status?: github_commit_status_enum | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  targetUrl?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_commit_contexts"
 */
export interface github_commit_contexts_obj_rel_insert_input {
  data: github_commit_contexts_insert_input;
  on_conflict?: github_commit_contexts_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_commit_contexts"
 */
export interface github_commit_contexts_on_conflict {
  constraint: github_commit_contexts_constraint;
  update_columns: github_commit_contexts_update_column[];
  where?: github_commit_contexts_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "github_commit_status". All fields are combined with a logical 'AND'.
 */
export interface github_commit_status_bool_exp {
  _and?: github_commit_status_bool_exp[] | null;
  _not?: github_commit_status_bool_exp | null;
  _or?: github_commit_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  github_commit_contexts?: github_commit_contexts_bool_exp | null;
  github_commits?: github_commits_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "github_commit_status_enum". All fields are combined with logical 'AND'.
 */
export interface github_commit_status_enum_comparison_exp {
  _eq?: github_commit_status_enum | null;
  _in?: github_commit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: github_commit_status_enum | null;
  _nin?: github_commit_status_enum[] | null;
}

/**
 * input type for inserting data into table "github_commit_status"
 */
export interface github_commit_status_insert_input {
  description?: string | null;
  github_commit_contexts?: github_commit_contexts_arr_rel_insert_input | null;
  github_commits?: github_commits_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "github_commit_status"
 */
export interface github_commit_status_obj_rel_insert_input {
  data: github_commit_status_insert_input;
  on_conflict?: github_commit_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_commit_status"
 */
export interface github_commit_status_on_conflict {
  constraint: github_commit_status_constraint;
  update_columns: github_commit_status_update_column[];
  where?: github_commit_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_commits"
 */
export interface github_commits_arr_rel_insert_input {
  data: github_commits_insert_input[];
  on_conflict?: github_commits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_commits". All fields are combined with a logical 'AND'.
 */
export interface github_commits_bool_exp {
  _and?: github_commits_bool_exp[] | null;
  _not?: github_commits_bool_exp | null;
  _or?: github_commits_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitCommitId?: Int_comparison_exp | null;
  git_commit?: git_commits_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_branch_commits?: github_branch_commits_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  github_code_comments?: github_code_comments_bool_exp | null;
  github_commit_comments?: github_commit_comments_bool_exp | null;
  github_commit_contexts?: github_commit_contexts_bool_exp | null;
  github_commit_status?: github_commit_status_bool_exp | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  oid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  status?: github_commit_status_enum_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_commits"
 */
export interface github_commits_insert_input {
  authorLogin?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitCommitId?: number | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_branch_commits?: github_branch_commits_arr_rel_insert_input | null;
  github_branches?: github_branches_arr_rel_insert_input | null;
  github_code_comments?: github_code_comments_arr_rel_insert_input | null;
  github_commit_comments?: github_commit_comments_arr_rel_insert_input | null;
  github_commit_contexts?: github_commit_contexts_arr_rel_insert_input | null;
  github_commit_status?: github_commit_status_obj_rel_insert_input | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_arr_rel_insert_input | null;
  id?: number | null;
  oid?: string | null;
  sid?: string | null;
  status?: github_commit_status_enum | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_commits"
 */
export interface github_commits_obj_rel_insert_input {
  data: github_commits_insert_input;
  on_conflict?: github_commits_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_commits"
 */
export interface github_commits_on_conflict {
  constraint: github_commits_constraint;
  update_columns: github_commits_update_column[];
  where?: github_commits_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_issue_assignees"
 */
export interface github_issue_assignees_arr_rel_insert_input {
  data: github_issue_assignees_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "github_issue_assignees". All fields are combined with a logical 'AND'.
 */
export interface github_issue_assignees_bool_exp {
  _and?: github_issue_assignees_bool_exp[] | null;
  _not?: github_issue_assignees_bool_exp | null;
  _or?: github_issue_assignees_bool_exp[] | null;
  accountLogin?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  issueSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_issue_assignees"
 */
export interface github_issue_assignees_insert_input {
  accountLogin?: string | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  issueSid?: string | null;
}

/**
 * input type for inserting array relation for remote table "github_issue_changes"
 */
export interface github_issue_changes_arr_rel_insert_input {
  data: github_issue_changes_insert_input[];
  on_conflict?: github_issue_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_issue_changes". All fields are combined with a logical 'AND'.
 */
export interface github_issue_changes_bool_exp {
  _and?: github_issue_changes_bool_exp[] | null;
  _not?: github_issue_changes_bool_exp | null;
  _or?: github_issue_changes_bool_exp[] | null;
  associatedRepoLabels?: jsonb_comparison_exp | null;
  associatedTicketId?: Int_comparison_exp | null;
  authorLogin?: String_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  body?: String_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  githubAccountAssignedSids?: jsonb_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  github_issue_state?: github_issue_state_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  number?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  repoSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  state?: github_issue_state_enum_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "github_issue_changes"
 */
export interface github_issue_changes_insert_input {
  associatedRepoLabels?: hasura_jsonb | null;
  associatedTicketId?: number | null;
  authorLogin?: string | null;
  automaticallyChanged?: boolean | null;
  body?: string | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  githubAccountAssignedSids?: hasura_jsonb | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  github_issue_state?: github_issue_state_obj_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  id?: number | null;
  number?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  repoSid?: string | null;
  sid?: string | null;
  sqlQuery?: string | null;
  state?: github_issue_state_enum | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "github_issue_changes"
 */
export interface github_issue_changes_on_conflict {
  constraint: github_issue_changes_constraint;
  update_columns: github_issue_changes_update_column[];
  where?: github_issue_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_issue_comments"
 */
export interface github_issue_comments_arr_rel_insert_input {
  data: github_issue_comments_insert_input[];
  on_conflict?: github_issue_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_issue_comments". All fields are combined with a logical 'AND'.
 */
export interface github_issue_comments_bool_exp {
  _and?: github_issue_comments_bool_exp[] | null;
  _not?: github_issue_comments_bool_exp | null;
  _or?: github_issue_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_issue_comments"
 */
export interface github_issue_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  id?: number | null;
  issueSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_issue_comments"
 */
export interface github_issue_comments_obj_rel_insert_input {
  data: github_issue_comments_insert_input;
  on_conflict?: github_issue_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_issue_comments"
 */
export interface github_issue_comments_on_conflict {
  constraint: github_issue_comments_constraint;
  update_columns: github_issue_comments_update_column[];
  where?: github_issue_comments_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "github_issue_state". All fields are combined with a logical 'AND'.
 */
export interface github_issue_state_bool_exp {
  _and?: github_issue_state_bool_exp[] | null;
  _not?: github_issue_state_bool_exp | null;
  _or?: github_issue_state_bool_exp[] | null;
  description?: String_comparison_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_bool_exp | null;
  github_issues?: github_issues_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "github_issue_state_enum". All fields are combined with logical 'AND'.
 */
export interface github_issue_state_enum_comparison_exp {
  _eq?: github_issue_state_enum | null;
  _in?: github_issue_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: github_issue_state_enum | null;
  _nin?: github_issue_state_enum[] | null;
}

/**
 * input type for inserting data into table "github_issue_state"
 */
export interface github_issue_state_insert_input {
  description?: string | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_arr_rel_insert_input | null;
  github_issues?: github_issues_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "github_issue_state"
 */
export interface github_issue_state_obj_rel_insert_input {
  data: github_issue_state_insert_input;
  on_conflict?: github_issue_state_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_issue_state"
 */
export interface github_issue_state_on_conflict {
  constraint: github_issue_state_constraint;
  update_columns: github_issue_state_update_column[];
  where?: github_issue_state_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_issue_ticket_status_mapping"
 */
export interface github_issue_ticket_status_mapping_arr_rel_insert_input {
  data: github_issue_ticket_status_mapping_insert_input[];
  on_conflict?: github_issue_ticket_status_mapping_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_issue_ticket_status_mapping". All fields are combined with a logical 'AND'.
 */
export interface github_issue_ticket_status_mapping_bool_exp {
  _and?: github_issue_ticket_status_mapping_bool_exp[] | null;
  _not?: github_issue_ticket_status_mapping_bool_exp | null;
  _or?: github_issue_ticket_status_mapping_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  githubAccountSid?: String_comparison_exp | null;
  githubRepoSid?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_issue_state?: github_issue_state_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  github_repo_label?: github_repo_labels_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueState?: github_issue_state_enum_comparison_exp | null;
  labelId?: Int_comparison_exp | null;
  prioritizeUpstream?: Boolean_comparison_exp | null;
  ticketStatus?: ticket_status_enum_comparison_exp | null;
  ticket_status?: ticket_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_issue_ticket_status_mapping"
 */
export interface github_issue_ticket_status_mapping_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  githubAccountSid?: string | null;
  githubRepoSid?: string | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_issue_state?: github_issue_state_obj_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  github_repo_label?: github_repo_labels_obj_rel_insert_input | null;
  id?: number | null;
  issueState?: github_issue_state_enum | null;
  labelId?: number | null;
  prioritizeUpstream?: boolean | null;
  ticketStatus?: ticket_status_enum | null;
  ticket_status?: ticket_status_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "github_issue_ticket_status_mapping"
 */
export interface github_issue_ticket_status_mapping_on_conflict {
  constraint: github_issue_ticket_status_mapping_constraint;
  update_columns: github_issue_ticket_status_mapping_update_column[];
  where?: github_issue_ticket_status_mapping_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_issues"
 */
export interface github_issues_arr_rel_insert_input {
  data: github_issues_insert_input[];
  on_conflict?: github_issues_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_issues". All fields are combined with a logical 'AND'.
 */
export interface github_issues_bool_exp {
  _and?: github_issues_bool_exp[] | null;
  _not?: github_issues_bool_exp | null;
  _or?: github_issues_bool_exp[] | null;
  associatedRepoLabels?: jsonb_comparison_exp | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  githubAccountAssignedSids?: jsonb_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  github_comments?: github_comments_bool_exp | null;
  github_issue_assignees?: github_issue_assignees_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  github_issue_comments?: github_issue_comments_bool_exp | null;
  github_issue_state?: github_issue_state_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  number?: Int_comparison_exp | null;
  repoSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  state?: github_issue_state_enum_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_issues"
 */
export interface github_issues_insert_input {
  associatedRepoLabels?: hasura_jsonb | null;
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  githubAccountAssignedSids?: hasura_jsonb | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  github_comments?: github_comments_arr_rel_insert_input | null;
  github_issue_assignees?: github_issue_assignees_arr_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  github_issue_comments?: github_issue_comments_arr_rel_insert_input | null;
  github_issue_state?: github_issue_state_obj_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  id?: number | null;
  number?: number | null;
  repoSid?: string | null;
  sid?: string | null;
  state?: github_issue_state_enum | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_issues"
 */
export interface github_issues_obj_rel_insert_input {
  data: github_issues_insert_input;
  on_conflict?: github_issues_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_issues"
 */
export interface github_issues_on_conflict {
  constraint: github_issues_constraint;
  update_columns: github_issues_update_column[];
  where?: github_issues_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_organization_members"
 */
export interface github_organization_members_arr_rel_insert_input {
  data: github_organization_members_insert_input[];
  on_conflict?: github_organization_members_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_organization_members". All fields are combined with a logical 'AND'.
 */
export interface github_organization_members_bool_exp {
  _and?: github_organization_members_bool_exp[] | null;
  _not?: github_organization_members_bool_exp | null;
  _or?: github_organization_members_bool_exp[] | null;
  accountLogin?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  organizationSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_organization_members"
 */
export interface github_organization_members_insert_input {
  accountLogin?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  id?: number | null;
  organizationSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "github_organization_members"
 */
export interface github_organization_members_on_conflict {
  constraint: github_organization_members_constraint;
  update_columns: github_organization_members_update_column[];
  where?: github_organization_members_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_organizations"
 */
export interface github_organizations_arr_rel_insert_input {
  data: github_organizations_insert_input[];
  on_conflict?: github_organizations_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_organizations". All fields are combined with a logical 'AND'.
 */
export interface github_organizations_bool_exp {
  _and?: github_organizations_bool_exp[] | null;
  _not?: github_organizations_bool_exp | null;
  _or?: github_organizations_bool_exp[] | null;
  avatarUrl?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitOrganizationId?: Int_comparison_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  github_app_installations?: github_app_installations_bool_exp | null;
  github_apps?: github_apps_bool_exp | null;
  github_organization_members?: github_organization_members_bool_exp | null;
  github_owner?: github_owners_bool_exp | null;
  github_teams?: github_teams_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_organizations"
 */
export interface github_organizations_insert_input {
  avatarUrl?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitOrganizationId?: number | null;
  git_organization?: git_organizations_obj_rel_insert_input | null;
  github_app_installations?: github_app_installations_arr_rel_insert_input | null;
  github_apps?: github_apps_arr_rel_insert_input | null;
  github_organization_members?: github_organization_members_arr_rel_insert_input | null;
  github_owner?: github_owners_obj_rel_insert_input | null;
  github_teams?: github_teams_arr_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_organizations"
 */
export interface github_organizations_obj_rel_insert_input {
  data: github_organizations_insert_input;
  on_conflict?: github_organizations_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_organizations"
 */
export interface github_organizations_on_conflict {
  constraint: github_organizations_constraint;
  update_columns: github_organizations_update_column[];
  where?: github_organizations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_owners"
 */
export interface github_owners_arr_rel_insert_input {
  data: github_owners_insert_input[];
  on_conflict?: github_owners_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_owners". All fields are combined with a logical 'AND'.
 */
export interface github_owners_bool_exp {
  _and?: github_owners_bool_exp[] | null;
  _not?: github_owners_bool_exp | null;
  _or?: github_owners_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  avatarUrl?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_organizations?: github_organizations_bool_exp | null;
  github_repos?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_owners"
 */
export interface github_owners_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  avatarUrl?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  github_organizations?: github_organizations_arr_rel_insert_input | null;
  github_repos?: github_repos_arr_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_owners"
 */
export interface github_owners_obj_rel_insert_input {
  data: github_owners_insert_input;
  on_conflict?: github_owners_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_owners"
 */
export interface github_owners_on_conflict {
  constraint: github_owners_constraint;
  update_columns: github_owners_update_column[];
  where?: github_owners_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_pull_request_comments"
 */
export interface github_pull_request_comments_arr_rel_insert_input {
  data: github_pull_request_comments_insert_input[];
  on_conflict?: github_pull_request_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_pull_request_comments". All fields are combined with a logical 'AND'.
 */
export interface github_pull_request_comments_bool_exp {
  _and?: github_pull_request_comments_bool_exp[] | null;
  _not?: github_pull_request_comments_bool_exp | null;
  _or?: github_pull_request_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  gitPullRequestCommentId?: Int_comparison_exp | null;
  git_pull_request_comment?: git_pull_request_comments_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_pull_request_comments"
 */
export interface github_pull_request_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  gitPullRequestCommentId?: number | null;
  git_pull_request_comment?: git_pull_request_comments_obj_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_pull_request_comments"
 */
export interface github_pull_request_comments_obj_rel_insert_input {
  data: github_pull_request_comments_insert_input;
  on_conflict?: github_pull_request_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_pull_request_comments"
 */
export interface github_pull_request_comments_on_conflict {
  constraint: github_pull_request_comments_constraint;
  update_columns: github_pull_request_comments_update_column[];
  where?: github_pull_request_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_pull_request_review_comments"
 */
export interface github_pull_request_review_comments_arr_rel_insert_input {
  data: github_pull_request_review_comments_insert_input[];
  on_conflict?: github_pull_request_review_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_pull_request_review_comments". All fields are combined with a logical 'AND'.
 */
export interface github_pull_request_review_comments_bool_exp {
  _and?: github_pull_request_review_comments_bool_exp[] | null;
  _not?: github_pull_request_review_comments_bool_exp | null;
  _or?: github_pull_request_review_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  commitSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  diffHunk?: String_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  gitPullRequestReviewCommentId?: Int_comparison_exp | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  id?: Int_comparison_exp | null;
  outdated?: Boolean_comparison_exp | null;
  path?: String_comparison_exp | null;
  position?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_pull_request_review_comments"
 */
export interface github_pull_request_review_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  commitSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  diffHunk?: string | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  gitPullRequestReviewCommentId?: number | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_obj_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  id?: number | null;
  outdated?: boolean | null;
  path?: string | null;
  position?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_pull_request_review_comments"
 */
export interface github_pull_request_review_comments_obj_rel_insert_input {
  data: github_pull_request_review_comments_insert_input;
  on_conflict?: github_pull_request_review_comments_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_pull_request_review_comments"
 */
export interface github_pull_request_review_comments_on_conflict {
  constraint: github_pull_request_review_comments_constraint;
  update_columns: github_pull_request_review_comments_update_column[];
  where?: github_pull_request_review_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_pull_request_review_threads"
 */
export interface github_pull_request_review_threads_arr_rel_insert_input {
  data: github_pull_request_review_threads_insert_input[];
  on_conflict?: github_pull_request_review_threads_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_pull_request_review_threads". All fields are combined with a logical 'AND'.
 */
export interface github_pull_request_review_threads_bool_exp {
  _and?: github_pull_request_review_threads_bool_exp[] | null;
  _not?: github_pull_request_review_threads_bool_exp | null;
  _or?: github_pull_request_review_threads_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitPullRequestReviewThreadsId?: Int_comparison_exp | null;
  git_pull_request_review_thread?: git_pull_request_review_threads_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_pull_request_review_threads"
 */
export interface github_pull_request_review_threads_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitPullRequestReviewThreadsId?: number | null;
  git_pull_request_review_thread?: git_pull_request_review_threads_obj_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_pull_request_review_threads"
 */
export interface github_pull_request_review_threads_obj_rel_insert_input {
  data: github_pull_request_review_threads_insert_input;
  on_conflict?: github_pull_request_review_threads_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_pull_request_review_threads"
 */
export interface github_pull_request_review_threads_on_conflict {
  constraint: github_pull_request_review_threads_constraint;
  update_columns: github_pull_request_review_threads_update_column[];
  where?: github_pull_request_review_threads_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_pull_request_reviews"
 */
export interface github_pull_request_reviews_arr_rel_insert_input {
  data: github_pull_request_reviews_insert_input[];
  on_conflict?: github_pull_request_reviews_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_pull_request_reviews". All fields are combined with a logical 'AND'.
 */
export interface github_pull_request_reviews_bool_exp {
  _and?: github_pull_request_reviews_bool_exp[] | null;
  _not?: github_pull_request_reviews_bool_exp | null;
  _or?: github_pull_request_reviews_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitPullRequestReviewsId?: Int_comparison_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_pull_request_reviews"
 */
export interface github_pull_request_reviews_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitPullRequestReviewsId?: number | null;
  git_pull_request_review?: git_pull_request_reviews_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_pull_request_reviews"
 */
export interface github_pull_request_reviews_obj_rel_insert_input {
  data: github_pull_request_reviews_insert_input;
  on_conflict?: github_pull_request_reviews_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_pull_request_reviews"
 */
export interface github_pull_request_reviews_on_conflict {
  constraint: github_pull_request_reviews_constraint;
  update_columns: github_pull_request_reviews_update_column[];
  where?: github_pull_request_reviews_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "github_repo_developer_view". All fields are combined with a logical 'AND'.
 */
export interface github_repo_developer_view_bool_exp {
  _and?: github_repo_developer_view_bool_exp[] | null;
  _not?: github_repo_developer_view_bool_exp | null;
  _or?: github_repo_developer_view_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  github_repos?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isDisabled?: Boolean_comparison_exp | null;
  isFork?: Boolean_comparison_exp | null;
  isLocked?: Boolean_comparison_exp | null;
  isPrivate?: Boolean_comparison_exp | null;
  isTemplate?: Boolean_comparison_exp | null;
  ownerLogin?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_repo_developer_view"
 */
export interface github_repo_developer_view_insert_input {
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  gitRepoId?: number | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  github_repos?: github_repos_obj_rel_insert_input | null;
  id?: number | null;
  isArchived?: boolean | null;
  isDisabled?: boolean | null;
  isFork?: boolean | null;
  isLocked?: boolean | null;
  isPrivate?: boolean | null;
  isTemplate?: boolean | null;
  ownerLogin?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_repo_developer_view"
 */
export interface github_repo_developer_view_obj_rel_insert_input {
  data: github_repo_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "github_repo_labels"
 */
export interface github_repo_labels_arr_rel_insert_input {
  data: github_repo_labels_insert_input[];
  on_conflict?: github_repo_labels_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_repo_labels". All fields are combined with a logical 'AND'.
 */
export interface github_repo_labels_bool_exp {
  _and?: github_repo_labels_bool_exp[] | null;
  _not?: github_repo_labels_bool_exp | null;
  _or?: github_repo_labels_bool_exp[] | null;
  color?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  default?: Boolean_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  githubRepoSid?: String_comparison_exp | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_repo_labels"
 */
export interface github_repo_labels_insert_input {
  color?: string | null;
  createdAt?: hasura_timestamptz | null;
  default?: boolean | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  githubRepoSid?: string | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_arr_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  url?: string | null;
}

/**
 * input type for inserting object relation for remote table "github_repo_labels"
 */
export interface github_repo_labels_obj_rel_insert_input {
  data: github_repo_labels_insert_input;
  on_conflict?: github_repo_labels_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_repo_labels"
 */
export interface github_repo_labels_on_conflict {
  constraint: github_repo_labels_constraint;
  update_columns: github_repo_labels_update_column[];
  where?: github_repo_labels_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_repo_teams"
 */
export interface github_repo_teams_arr_rel_insert_input {
  data: github_repo_teams_insert_input[];
  on_conflict?: github_repo_teams_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_repo_teams". All fields are combined with a logical 'AND'.
 */
export interface github_repo_teams_bool_exp {
  _and?: github_repo_teams_bool_exp[] | null;
  _not?: github_repo_teams_bool_exp | null;
  _or?: github_repo_teams_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_team?: github_teams_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  permission?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_repo_teams"
 */
export interface github_repo_teams_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_team?: github_teams_obj_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  permission?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "github_repo_teams"
 */
export interface github_repo_teams_on_conflict {
  constraint: github_repo_teams_constraint;
  update_columns: github_repo_teams_update_column[];
  where?: github_repo_teams_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_repo_users"
 */
export interface github_repo_users_arr_rel_insert_input {
  data: github_repo_users_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "github_repo_users". All fields are combined with a logical 'AND'.
 */
export interface github_repo_users_bool_exp {
  _and?: github_repo_users_bool_exp[] | null;
  _not?: github_repo_users_bool_exp | null;
  _or?: github_repo_users_bool_exp[] | null;
  accountLogin?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  permission?: String_comparison_exp | null;
  repoSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_repo_users"
 */
export interface github_repo_users_insert_input {
  accountLogin?: string | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  permission?: string | null;
  repoSid?: string | null;
}

/**
 * input type for inserting array relation for remote table "github_repos"
 */
export interface github_repos_arr_rel_insert_input {
  data: github_repos_insert_input[];
  on_conflict?: github_repos_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_repos". All fields are combined with a logical 'AND'.
 */
export interface github_repos_bool_exp {
  _and?: github_repos_bool_exp[] | null;
  _not?: github_repos_bool_exp | null;
  _or?: github_repos_bool_exp[] | null;
  accessGithubAccountSids?: jsonb_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  dev_github_repos?: github_repo_developer_view_bool_exp | null;
  git_repo_by_sid?: git_repos_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_bool_exp | null;
  github_issues?: github_issues_bool_exp | null;
  github_owner?: github_owners_bool_exp | null;
  github_repo_labels?: github_repo_labels_bool_exp | null;
  github_repo_users?: github_repo_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isDisabled?: Boolean_comparison_exp | null;
  isFork?: Boolean_comparison_exp | null;
  isLocked?: Boolean_comparison_exp | null;
  isPrivate?: Boolean_comparison_exp | null;
  isTemplate?: Boolean_comparison_exp | null;
  issuesEtag?: String_comparison_exp | null;
  issuesLastUpdatedAt?: timestamptz_comparison_exp | null;
  name?: String_comparison_exp | null;
  ownerLogin?: String_comparison_exp | null;
  pullRequestsEtag?: String_comparison_exp | null;
  pullRequestsLastUpdatedAt?: timestamptz_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_repos"
 */
export interface github_repos_insert_input {
  accessGithubAccountSids?: hasura_jsonb | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  dev_github_repos?: github_repo_developer_view_obj_rel_insert_input | null;
  git_repo_by_sid?: git_repos_obj_rel_insert_input | null;
  github_branches?: github_branches_arr_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_arr_rel_insert_input | null;
  github_issues?: github_issues_arr_rel_insert_input | null;
  github_owner?: github_owners_obj_rel_insert_input | null;
  github_repo_labels?: github_repo_labels_arr_rel_insert_input | null;
  github_repo_users?: github_repo_users_arr_rel_insert_input | null;
  id?: number | null;
  isArchived?: boolean | null;
  isDisabled?: boolean | null;
  isFork?: boolean | null;
  isLocked?: boolean | null;
  isPrivate?: boolean | null;
  isTemplate?: boolean | null;
  issuesEtag?: string | null;
  issuesLastUpdatedAt?: hasura_timestamptz | null;
  name?: string | null;
  ownerLogin?: string | null;
  pullRequestsEtag?: string | null;
  pullRequestsLastUpdatedAt?: hasura_timestamptz | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_repos"
 */
export interface github_repos_obj_rel_insert_input {
  data: github_repos_insert_input;
  on_conflict?: github_repos_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_repos"
 */
export interface github_repos_on_conflict {
  constraint: github_repos_constraint;
  update_columns: github_repos_update_column[];
  where?: github_repos_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_team_members"
 */
export interface github_team_members_arr_rel_insert_input {
  data: github_team_members_insert_input[];
  on_conflict?: github_team_members_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_team_members". All fields are combined with a logical 'AND'.
 */
export interface github_team_members_bool_exp {
  _and?: github_team_members_bool_exp[] | null;
  _not?: github_team_members_bool_exp | null;
  _or?: github_team_members_bool_exp[] | null;
  accountLogin?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_team?: github_teams_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_team_members"
 */
export interface github_team_members_insert_input {
  accountLogin?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_team?: github_teams_obj_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "github_team_members"
 */
export interface github_team_members_on_conflict {
  constraint: github_team_members_constraint;
  update_columns: github_team_members_update_column[];
  where?: github_team_members_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "github_teams"
 */
export interface github_teams_arr_rel_insert_input {
  data: github_teams_insert_input[];
  on_conflict?: github_teams_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "github_teams". All fields are combined with a logical 'AND'.
 */
export interface github_teams_bool_exp {
  _and?: github_teams_bool_exp[] | null;
  _not?: github_teams_bool_exp | null;
  _or?: github_teams_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_repo_teams?: github_repo_teams_bool_exp | null;
  github_team_members?: github_team_members_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  organizationSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_teams"
 */
export interface github_teams_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  github_repo_teams?: github_repo_teams_arr_rel_insert_input | null;
  github_team_members?: github_team_members_arr_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  organizationSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_teams"
 */
export interface github_teams_obj_rel_insert_input {
  data: github_teams_insert_input;
  on_conflict?: github_teams_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_teams"
 */
export interface github_teams_on_conflict {
  constraint: github_teams_constraint;
  update_columns: github_teams_update_column[];
  where?: github_teams_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "github_tokens". All fields are combined with a logical 'AND'.
 */
export interface github_tokens_bool_exp {
  _and?: github_tokens_bool_exp[] | null;
  _not?: github_tokens_bool_exp | null;
  _or?: github_tokens_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  id?: Int_comparison_exp | null;
  limit?: Int_comparison_exp | null;
  remaining?: Int_comparison_exp | null;
  resetAt?: timestamptz_comparison_exp | null;
  token?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "github_tokens"
 */
export interface github_tokens_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_accounts?: github_accounts_arr_rel_insert_input | null;
  id?: number | null;
  limit?: number | null;
  remaining?: number | null;
  resetAt?: hasura_timestamptz | null;
  token?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "github_tokens"
 */
export interface github_tokens_obj_rel_insert_input {
  data: github_tokens_insert_input;
  on_conflict?: github_tokens_on_conflict | null;
}

/**
 * on_conflict condition type for table "github_tokens"
 */
export interface github_tokens_on_conflict {
  constraint: github_tokens_constraint;
  update_columns: github_tokens_update_column[];
  where?: github_tokens_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_applications"
 */
export interface gitlab_applications_arr_rel_insert_input {
  data: gitlab_applications_insert_input[];
  on_conflict?: gitlab_applications_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_applications". All fields are combined with a logical 'AND'.
 */
export interface gitlab_applications_bool_exp {
  _and?: gitlab_applications_bool_exp[] | null;
  _not?: gitlab_applications_bool_exp | null;
  _or?: gitlab_applications_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  clientSecret?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  redirectURIs?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_applications"
 */
export interface gitlab_applications_insert_input {
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  redirectURIs?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "gitlab_applications"
 */
export interface gitlab_applications_on_conflict {
  constraint: gitlab_applications_constraint;
  update_columns: gitlab_applications_update_column[];
  where?: gitlab_applications_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_groups"
 */
export interface gitlab_groups_arr_rel_insert_input {
  data: gitlab_groups_insert_input[];
  on_conflict?: gitlab_groups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_groups". All fields are combined with a logical 'AND'.
 */
export interface gitlab_groups_bool_exp {
  _and?: gitlab_groups_bool_exp[] | null;
  _not?: gitlab_groups_bool_exp | null;
  _or?: gitlab_groups_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitlab_namespace?: gitlab_namespaces_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  namespaceSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_groups"
 */
export interface gitlab_groups_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitlab_namespace?: gitlab_namespaces_obj_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  namespaceSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "gitlab_groups"
 */
export interface gitlab_groups_on_conflict {
  constraint: gitlab_groups_constraint;
  update_columns: gitlab_groups_update_column[];
  where?: gitlab_groups_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_issue_comments"
 */
export interface gitlab_issue_comments_arr_rel_insert_input {
  data: gitlab_issue_comments_insert_input[];
  on_conflict?: gitlab_issue_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_issue_comments". All fields are combined with a logical 'AND'.
 */
export interface gitlab_issue_comments_bool_exp {
  _and?: gitlab_issue_comments_bool_exp[] | null;
  _not?: gitlab_issue_comments_bool_exp | null;
  _or?: gitlab_issue_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  gitlab_issue?: gitlab_issues_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_issue_comments"
 */
export interface gitlab_issue_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  gitlab_issue?: gitlab_issues_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  id?: number | null;
  issueSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "gitlab_issue_comments"
 */
export interface gitlab_issue_comments_on_conflict {
  constraint: gitlab_issue_comments_constraint;
  update_columns: gitlab_issue_comments_update_column[];
  where?: gitlab_issue_comments_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_issue_state". All fields are combined with a logical 'AND'.
 */
export interface gitlab_issue_state_bool_exp {
  _and?: gitlab_issue_state_bool_exp[] | null;
  _not?: gitlab_issue_state_bool_exp | null;
  _or?: gitlab_issue_state_bool_exp[] | null;
  description?: String_comparison_exp | null;
  gitlab_issues?: gitlab_issues_bool_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "gitlab_issue_state_enum". All fields are combined with logical 'AND'.
 */
export interface gitlab_issue_state_enum_comparison_exp {
  _eq?: gitlab_issue_state_enum | null;
  _in?: gitlab_issue_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: gitlab_issue_state_enum | null;
  _nin?: gitlab_issue_state_enum[] | null;
}

/**
 * input type for inserting data into table "gitlab_issue_state"
 */
export interface gitlab_issue_state_insert_input {
  description?: string | null;
  gitlab_issues?: gitlab_issues_arr_rel_insert_input | null;
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_issue_state"
 */
export interface gitlab_issue_state_obj_rel_insert_input {
  data: gitlab_issue_state_insert_input;
  on_conflict?: gitlab_issue_state_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_issue_state"
 */
export interface gitlab_issue_state_on_conflict {
  constraint: gitlab_issue_state_constraint;
  update_columns: gitlab_issue_state_update_column[];
  where?: gitlab_issue_state_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_issues"
 */
export interface gitlab_issues_arr_rel_insert_input {
  data: gitlab_issues_insert_input[];
  on_conflict?: gitlab_issues_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_issues". All fields are combined with a logical 'AND'.
 */
export interface gitlab_issues_bool_exp {
  _and?: gitlab_issues_bool_exp[] | null;
  _not?: gitlab_issues_bool_exp | null;
  _or?: gitlab_issues_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  dueDate?: timestamptz_comparison_exp | null;
  gitlabAccountAssignedSids?: jsonb_comparison_exp | null;
  gitlab_issue_comments?: gitlab_issue_comments_bool_exp | null;
  gitlab_issue_state?: gitlab_issue_state_bool_exp | null;
  gitlab_project?: gitlab_projects_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  number?: Int_comparison_exp | null;
  projectSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  state?: gitlab_issue_state_enum_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_issues"
 */
export interface gitlab_issues_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  dueDate?: hasura_timestamptz | null;
  gitlabAccountAssignedSids?: hasura_jsonb | null;
  gitlab_issue_comments?: gitlab_issue_comments_arr_rel_insert_input | null;
  gitlab_issue_state?: gitlab_issue_state_obj_rel_insert_input | null;
  gitlab_project?: gitlab_projects_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  id?: number | null;
  number?: number | null;
  projectSid?: string | null;
  sid?: string | null;
  state?: gitlab_issue_state_enum | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_issues"
 */
export interface gitlab_issues_obj_rel_insert_input {
  data: gitlab_issues_insert_input;
  on_conflict?: gitlab_issues_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_issues"
 */
export interface gitlab_issues_on_conflict {
  constraint: gitlab_issues_constraint;
  update_columns: gitlab_issues_update_column[];
  where?: gitlab_issues_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_namespaces"
 */
export interface gitlab_namespaces_arr_rel_insert_input {
  data: gitlab_namespaces_insert_input[];
  on_conflict?: gitlab_namespaces_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_namespaces". All fields are combined with a logical 'AND'.
 */
export interface gitlab_namespaces_bool_exp {
  _and?: gitlab_namespaces_bool_exp[] | null;
  _not?: gitlab_namespaces_bool_exp | null;
  _or?: gitlab_namespaces_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyId?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitlab_groups?: gitlab_groups_bool_exp | null;
  gitlab_namespace?: gitlab_namespaces_bool_exp | null;
  gitlab_namespaces?: gitlab_namespaces_bool_exp | null;
  gitlab_projects?: gitlab_projects_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  parentNamespaceSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_namespaces"
 */
export interface gitlab_namespaces_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyId?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitlab_groups?: gitlab_groups_arr_rel_insert_input | null;
  gitlab_namespace?: gitlab_namespaces_obj_rel_insert_input | null;
  gitlab_namespaces?: gitlab_namespaces_arr_rel_insert_input | null;
  gitlab_projects?: gitlab_projects_arr_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  parentNamespaceSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_namespaces"
 */
export interface gitlab_namespaces_obj_rel_insert_input {
  data: gitlab_namespaces_insert_input;
  on_conflict?: gitlab_namespaces_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_namespaces"
 */
export interface gitlab_namespaces_on_conflict {
  constraint: gitlab_namespaces_constraint;
  update_columns: gitlab_namespaces_update_column[];
  where?: gitlab_namespaces_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_project_users"
 */
export interface gitlab_project_users_arr_rel_insert_input {
  data: gitlab_project_users_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "gitlab_project_users". All fields are combined with a logical 'AND'.
 */
export interface gitlab_project_users_bool_exp {
  _and?: gitlab_project_users_bool_exp[] | null;
  _not?: gitlab_project_users_bool_exp | null;
  _or?: gitlab_project_users_bool_exp[] | null;
  accountLogin?: String_comparison_exp | null;
  gitlab_project?: gitlab_projects_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  permission?: String_comparison_exp | null;
  projectSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_project_users"
 */
export interface gitlab_project_users_insert_input {
  accountLogin?: string | null;
  gitlab_project?: gitlab_projects_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  permission?: string | null;
  projectSid?: string | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_projects"
 */
export interface gitlab_projects_arr_rel_insert_input {
  data: gitlab_projects_insert_input[];
  on_conflict?: gitlab_projects_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_projects". All fields are combined with a logical 'AND'.
 */
export interface gitlab_projects_bool_exp {
  _and?: gitlab_projects_bool_exp[] | null;
  _not?: gitlab_projects_bool_exp | null;
  _or?: gitlab_projects_bool_exp[] | null;
  accessGitlabUserSids?: jsonb_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_repo_by_sid?: git_repos_bool_exp | null;
  gitlab_issues?: gitlab_issues_bool_exp | null;
  gitlab_namespace?: gitlab_namespaces_bool_exp | null;
  gitlab_project_users?: gitlab_project_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  namespaceSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_projects"
 */
export interface gitlab_projects_insert_input {
  accessGitlabUserSids?: hasura_jsonb | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_repo_by_sid?: git_repos_obj_rel_insert_input | null;
  gitlab_issues?: gitlab_issues_arr_rel_insert_input | null;
  gitlab_namespace?: gitlab_namespaces_obj_rel_insert_input | null;
  gitlab_project_users?: gitlab_project_users_arr_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  namespaceSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_projects"
 */
export interface gitlab_projects_obj_rel_insert_input {
  data: gitlab_projects_insert_input;
  on_conflict?: gitlab_projects_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_projects"
 */
export interface gitlab_projects_on_conflict {
  constraint: gitlab_projects_constraint;
  update_columns: gitlab_projects_update_column[];
  where?: gitlab_projects_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_pull_request_comments"
 */
export interface gitlab_pull_request_comments_arr_rel_insert_input {
  data: gitlab_pull_request_comments_insert_input[];
  on_conflict?: gitlab_pull_request_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_pull_request_comments". All fields are combined with a logical 'AND'.
 */
export interface gitlab_pull_request_comments_bool_exp {
  _and?: gitlab_pull_request_comments_bool_exp[] | null;
  _not?: gitlab_pull_request_comments_bool_exp | null;
  _or?: gitlab_pull_request_comments_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  gitPullRequestCommentId?: Int_comparison_exp | null;
  git_pull_request_comment?: git_pull_request_comments_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_pull_request_comments"
 */
export interface gitlab_pull_request_comments_insert_input {
  authorLogin?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  gitPullRequestCommentId?: number | null;
  git_pull_request_comment?: git_pull_request_comments_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "gitlab_pull_request_comments"
 */
export interface gitlab_pull_request_comments_on_conflict {
  constraint: gitlab_pull_request_comments_constraint;
  update_columns: gitlab_pull_request_comments_update_column[];
  where?: gitlab_pull_request_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_pull_requests"
 */
export interface gitlab_pull_requests_arr_rel_insert_input {
  data: gitlab_pull_requests_insert_input[];
  on_conflict?: gitlab_pull_requests_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_pull_requests". All fields are combined with a logical 'AND'.
 */
export interface gitlab_pull_requests_bool_exp {
  _and?: gitlab_pull_requests_bool_exp[] | null;
  _not?: gitlab_pull_requests_bool_exp | null;
  _or?: gitlab_pull_requests_bool_exp[] | null;
  authorLogin?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitPullRequestId?: Int_comparison_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_pull_requests"
 */
export interface gitlab_pull_requests_insert_input {
  authorLogin?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitPullRequestId?: number | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_pull_requests"
 */
export interface gitlab_pull_requests_obj_rel_insert_input {
  data: gitlab_pull_requests_insert_input;
  on_conflict?: gitlab_pull_requests_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_pull_requests"
 */
export interface gitlab_pull_requests_on_conflict {
  constraint: gitlab_pull_requests_constraint;
  update_columns: gitlab_pull_requests_update_column[];
  where?: gitlab_pull_requests_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_tokens". All fields are combined with a logical 'AND'.
 */
export interface gitlab_tokens_bool_exp {
  _and?: gitlab_tokens_bool_exp[] | null;
  _not?: gitlab_tokens_bool_exp | null;
  _or?: gitlab_tokens_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  gitlab_users?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  limit?: Int_comparison_exp | null;
  remaining?: Int_comparison_exp | null;
  resetAt?: timestamptz_comparison_exp | null;
  token?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_tokens"
 */
export interface gitlab_tokens_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  gitlab_users?: gitlab_users_arr_rel_insert_input | null;
  id?: number | null;
  limit?: number | null;
  remaining?: number | null;
  resetAt?: hasura_timestamptz | null;
  token?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_tokens"
 */
export interface gitlab_tokens_obj_rel_insert_input {
  data: gitlab_tokens_insert_input;
  on_conflict?: gitlab_tokens_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_tokens"
 */
export interface gitlab_tokens_on_conflict {
  constraint: gitlab_tokens_constraint;
  update_columns: gitlab_tokens_update_column[];
  where?: gitlab_tokens_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "gitlab_users"
 */
export interface gitlab_users_arr_rel_insert_input {
  data: gitlab_users_insert_input[];
  on_conflict?: gitlab_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "gitlab_users". All fields are combined with a logical 'AND'.
 */
export interface gitlab_users_bool_exp {
  _and?: gitlab_users_bool_exp[] | null;
  _not?: gitlab_users_bool_exp | null;
  _or?: gitlab_users_bool_exp[] | null;
  bot?: bots_bool_exp | null;
  botByBotid?: bots_bool_exp | null;
  botId?: Int_comparison_exp | null;
  bots?: bots_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitUserByLogin?: git_users_bool_exp | null;
  gitUserId?: Int_comparison_exp | null;
  git_user?: git_users_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  gitlab_issue_comments?: gitlab_issue_comments_bool_exp | null;
  gitlab_issues?: gitlab_issues_bool_exp | null;
  gitlab_project_users?: gitlab_project_users_bool_exp | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_bool_exp | null;
  gitlab_pull_requests?: gitlab_pull_requests_bool_exp | null;
  gitlab_token?: gitlab_tokens_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  personalAccessToken?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "gitlab_users"
 */
export interface gitlab_users_insert_input {
  bot?: bots_obj_rel_insert_input | null;
  botByBotid?: bots_obj_rel_insert_input | null;
  botId?: number | null;
  bots?: bots_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gitUserByLogin?: git_users_obj_rel_insert_input | null;
  gitUserId?: number | null;
  git_user?: git_users_obj_rel_insert_input | null;
  git_users?: git_users_arr_rel_insert_input | null;
  gitlab_issue_comments?: gitlab_issue_comments_arr_rel_insert_input | null;
  gitlab_issues?: gitlab_issues_arr_rel_insert_input | null;
  gitlab_project_users?: gitlab_project_users_arr_rel_insert_input | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_arr_rel_insert_input | null;
  gitlab_pull_requests?: gitlab_pull_requests_arr_rel_insert_input | null;
  gitlab_token?: gitlab_tokens_obj_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  personalAccessToken?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "gitlab_users"
 */
export interface gitlab_users_obj_rel_insert_input {
  data: gitlab_users_insert_input;
  on_conflict?: gitlab_users_on_conflict | null;
}

/**
 * on_conflict condition type for table "gitlab_users"
 */
export interface gitlab_users_on_conflict {
  constraint: gitlab_users_constraint;
  update_columns: gitlab_users_update_column[];
  where?: gitlab_users_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "interviewer_status". All fields are combined with a logical 'AND'.
 */
export interface interviewer_status_bool_exp {
  _and?: interviewer_status_bool_exp[] | null;
  _not?: interviewer_status_bool_exp | null;
  _or?: interviewer_status_bool_exp[] | null;
  interviewers?: interviewers_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "interviewer_status"
 */
export interface interviewer_status_insert_input {
  interviewers?: interviewers_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "interviewer_status"
 */
export interface interviewer_status_obj_rel_insert_input {
  data: interviewer_status_insert_input;
  on_conflict?: interviewer_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "interviewer_status"
 */
export interface interviewer_status_on_conflict {
  constraint: interviewer_status_constraint;
  update_columns: interviewer_status_update_column[];
  where?: interviewer_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "interviewers"
 */
export interface interviewers_arr_rel_insert_input {
  data: interviewers_insert_input[];
  on_conflict?: interviewers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "interviewers". All fields are combined with a logical 'AND'.
 */
export interface interviewers_bool_exp {
  _and?: interviewers_bool_exp[] | null;
  _not?: interviewers_bool_exp | null;
  _or?: interviewers_bool_exp[] | null;
  appliedAt?: timestamptz_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  interviewer_status?: interviewer_status_bool_exp | null;
  reasonsForApplying?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "interviewers"
 */
export interface interviewers_insert_input {
  appliedAt?: hasura_timestamptz | null;
  completedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  interviewer_status?: interviewer_status_obj_rel_insert_input | null;
  reasonsForApplying?: string | null;
  status?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "interviewers"
 */
export interface interviewers_on_conflict {
  constraint: interviewers_constraint;
  update_columns: interviewers_update_column[];
  where?: interviewers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_account_projects"
 */
export interface jira_account_projects_arr_rel_insert_input {
  data: jira_account_projects_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "jira_account_projects". All fields are combined with a logical 'AND'.
 */
export interface jira_account_projects_bool_exp {
  _and?: jira_account_projects_bool_exp[] | null;
  _not?: jira_account_projects_bool_exp | null;
  _or?: jira_account_projects_bool_exp[] | null;
  authors?: String_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  projects?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_account_projects"
 */
export interface jira_account_projects_insert_input {
  authors?: string | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  projects?: string | null;
}

/**
 * input type for inserting array relation for remote table "jira_accounts"
 */
export interface jira_accounts_arr_rel_insert_input {
  data: jira_accounts_insert_input[];
  on_conflict?: jira_accounts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_accounts". All fields are combined with a logical 'AND'.
 */
export interface jira_accounts_bool_exp {
  _and?: jira_accounts_bool_exp[] | null;
  _not?: jira_accounts_bool_exp | null;
  _or?: jira_accounts_bool_exp[] | null;
  bot?: bots_bool_exp | null;
  bots?: bots_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jiraIssueChangesByAssigneesid?: jira_issue_changes_bool_exp | null;
  jiraIssuesByAuthorsid?: jira_issues_bool_exp | null;
  jiraOrgEndpoint?: String_comparison_exp | null;
  jira_account_projects?: jira_account_projects_bool_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issue_comments?: jira_issue_comment_bool_exp | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_bool_exp | null;
  jira_issues?: jira_issues_bool_exp | null;
  jira_organization?: jira_organizations_bool_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  token?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "jira_accounts"
 */
export interface jira_accounts_insert_input {
  bot?: bots_obj_rel_insert_input | null;
  bots?: bots_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  email?: string | null;
  id?: number | null;
  jiraIssueChangesByAssigneesid?: jira_issue_changes_arr_rel_insert_input | null;
  jiraIssuesByAuthorsid?: jira_issues_arr_rel_insert_input | null;
  jiraOrgEndpoint?: string | null;
  jira_account_projects?: jira_account_projects_arr_rel_insert_input | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issue_comments?: jira_issue_comment_arr_rel_insert_input | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_arr_rel_insert_input | null;
  jira_issues?: jira_issues_arr_rel_insert_input | null;
  jira_organization?: jira_organizations_obj_rel_insert_input | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  token?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "jira_accounts"
 */
export interface jira_accounts_obj_rel_insert_input {
  data: jira_accounts_insert_input;
  on_conflict?: jira_accounts_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_accounts"
 */
export interface jira_accounts_on_conflict {
  constraint: jira_accounts_constraint;
  update_columns: jira_accounts_update_column[];
  where?: jira_accounts_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_app_installations"
 */
export interface jira_app_installations_arr_rel_insert_input {
  data: jira_app_installations_insert_input[];
  on_conflict?: jira_app_installations_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_app_installations". All fields are combined with a logical 'AND'.
 */
export interface jira_app_installations_bool_exp {
  _and?: jira_app_installations_bool_exp[] | null;
  _not?: jira_app_installations_bool_exp | null;
  _or?: jira_app_installations_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  accessTokenExpiresAt?: timestamptz_comparison_exp | null;
  accessTokenType?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jiraAppId?: Int_comparison_exp | null;
  jira_app?: jira_apps_bool_exp | null;
  refreshToken?: String_comparison_exp | null;
  scopes?: jsonb_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_app_installations"
 */
export interface jira_app_installations_insert_input {
  accessToken?: string | null;
  accessTokenExpiresAt?: hasura_timestamptz | null;
  accessTokenType?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  jiraAppId?: number | null;
  jira_app?: jira_apps_obj_rel_insert_input | null;
  refreshToken?: string | null;
  scopes?: hasura_jsonb | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "jira_app_installations"
 */
export interface jira_app_installations_on_conflict {
  constraint: jira_app_installations_constraint;
  update_columns: jira_app_installations_update_column[];
  where?: jira_app_installations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_apps"
 */
export interface jira_apps_arr_rel_insert_input {
  data: jira_apps_insert_input[];
  on_conflict?: jira_apps_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_apps". All fields are combined with a logical 'AND'.
 */
export interface jira_apps_bool_exp {
  _and?: jira_apps_bool_exp[] | null;
  _not?: jira_apps_bool_exp | null;
  _or?: jira_apps_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  clientSecret?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isPublic?: Boolean_comparison_exp | null;
  jira_app_installations?: jira_app_installations_bool_exp | null;
  name?: String_comparison_exp | null;
  scopes?: jsonb_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_apps"
 */
export interface jira_apps_insert_input {
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  isPublic?: boolean | null;
  jira_app_installations?: jira_app_installations_arr_rel_insert_input | null;
  name?: string | null;
  scopes?: hasura_jsonb | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_apps"
 */
export interface jira_apps_obj_rel_insert_input {
  data: jira_apps_insert_input;
  on_conflict?: jira_apps_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_apps"
 */
export interface jira_apps_on_conflict {
  constraint: jira_apps_constraint;
  update_columns: jira_apps_update_column[];
  where?: jira_apps_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_attachments"
 */
export interface jira_issue_attachments_arr_rel_insert_input {
  data: jira_issue_attachments_insert_input[];
  on_conflict?: jira_issue_attachments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_attachments". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_attachments_bool_exp {
  _and?: jira_issue_attachments_bool_exp[] | null;
  _not?: jira_issue_attachments_bool_exp | null;
  _or?: jira_issue_attachments_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gcpObjectId?: String_comparison_exp | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  properties?: jsonb_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_attachments"
 */
export interface jira_issue_attachments_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gcpObjectId?: string | null;
  gcp_storage_object?: gcp_storage_objects_obj_rel_insert_input | null;
  id?: number | null;
  issueSid?: string | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  properties?: hasura_jsonb | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "jira_issue_attachments"
 */
export interface jira_issue_attachments_on_conflict {
  constraint: jira_issue_attachments_constraint;
  update_columns: jira_issue_attachments_update_column[];
  where?: jira_issue_attachments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_changes"
 */
export interface jira_issue_changes_arr_rel_insert_input {
  data: jira_issue_changes_insert_input[];
  on_conflict?: jira_issue_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_changes". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_changes_bool_exp {
  _and?: jira_issue_changes_bool_exp[] | null;
  _not?: jira_issue_changes_bool_exp | null;
  _or?: jira_issue_changes_bool_exp[] | null;
  accessFieldValues?: jsonb_comparison_exp | null;
  assigneeSid?: String_comparison_exp | null;
  authorSid?: String_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueTypeSid?: String_comparison_exp | null;
  jiraAccountByAssigneesid?: jira_accounts_bool_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  jira_issue_type?: jira_issue_types_bool_exp | null;
  jira_issue_type_status?: jira_issue_type_statuses_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  key?: String_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  projectSid?: String_comparison_exp | null;
  properties?: jsonb_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  self?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  transitions?: jsonb_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_changes"
 */
export interface jira_issue_changes_insert_input {
  accessFieldValues?: hasura_jsonb | null;
  assigneeSid?: string | null;
  authorSid?: string | null;
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  issueTypeSid?: string | null;
  jiraAccountByAssigneesid?: jira_accounts_obj_rel_insert_input | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  jira_issue_type?: jira_issue_types_obj_rel_insert_input | null;
  jira_issue_type_status?: jira_issue_type_statuses_obj_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  key?: string | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  projectSid?: string | null;
  properties?: hasura_jsonb | null;
  reasonForChange?: string | null;
  self?: string | null;
  sid?: string | null;
  sqlQuery?: string | null;
  status?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  transitions?: hasura_jsonb | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "jira_issue_changes"
 */
export interface jira_issue_changes_on_conflict {
  constraint: jira_issue_changes_constraint;
  update_columns: jira_issue_changes_update_column[];
  where?: jira_issue_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_comment"
 */
export interface jira_issue_comment_arr_rel_insert_input {
  data: jira_issue_comment_insert_input[];
  on_conflict?: jira_issue_comment_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_comment". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_comment_bool_exp {
  _and?: jira_issue_comment_bool_exp[] | null;
  _not?: jira_issue_comment_bool_exp | null;
  _or?: jira_issue_comment_bool_exp[] | null;
  authorSid?: String_comparison_exp | null;
  body?: jsonb_comparison_exp | null;
  bodyHTML?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  properties?: jsonb_comparison_exp | null;
  referenceBody?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  visibility?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_comment"
 */
export interface jira_issue_comment_insert_input {
  authorSid?: string | null;
  body?: hasura_jsonb | null;
  bodyHTML?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  id?: number | null;
  issueSid?: string | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  properties?: hasura_jsonb | null;
  referenceBody?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  visibility?: boolean | null;
}

/**
 * input type for inserting object relation for remote table "jira_issue_comment"
 */
export interface jira_issue_comment_obj_rel_insert_input {
  data: jira_issue_comment_insert_input;
  on_conflict?: jira_issue_comment_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_issue_comment"
 */
export interface jira_issue_comment_on_conflict {
  constraint: jira_issue_comment_constraint;
  update_columns: jira_issue_comment_update_column[];
  where?: jira_issue_comment_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_field_values"
 */
export interface jira_issue_field_values_arr_rel_insert_input {
  data: jira_issue_field_values_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "jira_issue_field_values". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_field_values_bool_exp {
  _and?: jira_issue_field_values_bool_exp[] | null;
  _not?: jira_issue_field_values_bool_exp | null;
  _or?: jira_issue_field_values_bool_exp[] | null;
  createdAt?: String_comparison_exp | null;
  deletedAt?: String_comparison_exp | null;
  fieldKey?: String_comparison_exp | null;
  fieldProjectSid?: String_comparison_exp | null;
  fieldValue?: String_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  jiraIssueFieldByFieldkeyFieldprojectsid?: jira_issue_fields_bool_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  jira_issue_field?: jira_issue_fields_bool_exp | null;
  updatedAt?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_field_values"
 */
export interface jira_issue_field_values_insert_input {
  createdAt?: string | null;
  deletedAt?: string | null;
  fieldKey?: string | null;
  fieldProjectSid?: string | null;
  fieldValue?: string | null;
  issueSid?: string | null;
  jiraIssueFieldByFieldkeyFieldprojectsid?: jira_issue_fields_obj_rel_insert_input | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  jira_issue_field?: jira_issue_fields_obj_rel_insert_input | null;
  updatedAt?: string | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_fields"
 */
export interface jira_issue_fields_arr_rel_insert_input {
  data: jira_issue_fields_insert_input[];
  on_conflict?: jira_issue_fields_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_fields". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_fields_bool_exp {
  _and?: jira_issue_fields_bool_exp[] | null;
  _not?: jira_issue_fields_bool_exp | null;
  _or?: jira_issue_fields_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  custom?: Boolean_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  jiraIssueFieldValuesByFieldkeyFieldprojectsid?: jira_issue_field_values_bool_exp | null;
  jira_issue_field_values?: jira_issue_field_values_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  projectSid?: String_comparison_exp | null;
  ticketSourceById?: ticket_sources_bool_exp | null;
  ticketSourceChangesByJiradescriptionfieldid?: ticket_source_changes_bool_exp | null;
  ticketSourcesByJiradescriptionfieldid?: ticket_sources_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_fields"
 */
export interface jira_issue_fields_insert_input {
  createdAt?: hasura_timestamptz | null;
  custom?: boolean | null;
  deletedAt?: hasura_timestamptz | null;
  id?: hasura_uuid | null;
  jiraIssueFieldValuesByFieldkeyFieldprojectsid?: jira_issue_field_values_arr_rel_insert_input | null;
  jira_issue_field_values?: jira_issue_field_values_arr_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  key?: string | null;
  name?: string | null;
  projectSid?: string | null;
  ticketSourceById?: ticket_sources_obj_rel_insert_input | null;
  ticketSourceChangesByJiradescriptionfieldid?: ticket_source_changes_arr_rel_insert_input | null;
  ticketSourcesByJiradescriptionfieldid?: ticket_sources_arr_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_issue_fields"
 */
export interface jira_issue_fields_obj_rel_insert_input {
  data: jira_issue_fields_insert_input;
  on_conflict?: jira_issue_fields_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_issue_fields"
 */
export interface jira_issue_fields_on_conflict {
  constraint: jira_issue_fields_constraint;
  update_columns: jira_issue_fields_update_column[];
  where?: jira_issue_fields_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_ticket_status_mapping"
 */
export interface jira_issue_ticket_status_mapping_arr_rel_insert_input {
  data: jira_issue_ticket_status_mapping_insert_input[];
  on_conflict?: jira_issue_ticket_status_mapping_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_ticket_status_mapping". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_ticket_status_mapping_bool_exp {
  _and?: jira_issue_ticket_status_mapping_bool_exp[] | null;
  _not?: jira_issue_ticket_status_mapping_bool_exp | null;
  _or?: jira_issue_ticket_status_mapping_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  issueState?: String_comparison_exp | null;
  jiraAccountSid?: String_comparison_exp | null;
  jiraProjectSid?: String_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_issue_type_status?: jira_issue_type_statuses_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  prioritizeUpstream?: Boolean_comparison_exp | null;
  ticketStatus?: ticket_status_enum_comparison_exp | null;
  ticket_status?: ticket_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_ticket_status_mapping"
 */
export interface jira_issue_ticket_status_mapping_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  issueState?: string | null;
  jiraAccountSid?: string | null;
  jiraProjectSid?: string | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_issue_type_status?: jira_issue_type_statuses_obj_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  prioritizeUpstream?: boolean | null;
  ticketStatus?: ticket_status_enum | null;
  ticket_status?: ticket_status_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "jira_issue_ticket_status_mapping"
 */
export interface jira_issue_ticket_status_mapping_on_conflict {
  constraint: jira_issue_ticket_status_mapping_constraint;
  update_columns: jira_issue_ticket_status_mapping_update_column[];
  where?: jira_issue_ticket_status_mapping_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_transitions"
 */
export interface jira_issue_transitions_arr_rel_insert_input {
  data: jira_issue_transitions_insert_input[];
  on_conflict?: jira_issue_transitions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_transitions". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_transitions_bool_exp {
  _and?: jira_issue_transitions_bool_exp[] | null;
  _not?: jira_issue_transitions_bool_exp | null;
  _or?: jira_issue_transitions_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  jira_issue_type_status?: jira_issue_type_statuses_bool_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  transitionId?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_transitions"
 */
export interface jira_issue_transitions_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  issueSid?: string | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  jira_issue_type_status?: jira_issue_type_statuses_obj_rel_insert_input | null;
  name?: string | null;
  sid?: string | null;
  status?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  transitionId?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "jira_issue_transitions"
 */
export interface jira_issue_transitions_on_conflict {
  constraint: jira_issue_transitions_constraint;
  update_columns: jira_issue_transitions_update_column[];
  where?: jira_issue_transitions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_type_statuses"
 */
export interface jira_issue_type_statuses_arr_rel_insert_input {
  data: jira_issue_type_statuses_insert_input[];
  on_conflict?: jira_issue_type_statuses_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_type_statuses". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_type_statuses_bool_exp {
  _and?: jira_issue_type_statuses_bool_exp[] | null;
  _not?: jira_issue_type_statuses_bool_exp | null;
  _or?: jira_issue_type_statuses_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jiraIssueTypeSid?: String_comparison_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_bool_exp | null;
  jira_issue_transitions?: jira_issue_transitions_bool_exp | null;
  jira_issue_type?: jira_issue_types_bool_exp | null;
  jira_issues?: jira_issues_bool_exp | null;
  name?: String_comparison_exp | null;
  self?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_type_statuses"
 */
export interface jira_issue_type_statuses_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  id?: number | null;
  jiraIssueTypeSid?: string | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_arr_rel_insert_input | null;
  jira_issue_transitions?: jira_issue_transitions_arr_rel_insert_input | null;
  jira_issue_type?: jira_issue_types_obj_rel_insert_input | null;
  jira_issues?: jira_issues_arr_rel_insert_input | null;
  name?: string | null;
  self?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_issue_type_statuses"
 */
export interface jira_issue_type_statuses_obj_rel_insert_input {
  data: jira_issue_type_statuses_insert_input;
  on_conflict?: jira_issue_type_statuses_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_issue_type_statuses"
 */
export interface jira_issue_type_statuses_on_conflict {
  constraint: jira_issue_type_statuses_constraint;
  update_columns: jira_issue_type_statuses_update_column[];
  where?: jira_issue_type_statuses_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issue_types"
 */
export interface jira_issue_types_arr_rel_insert_input {
  data: jira_issue_types_insert_input[];
  on_conflict?: jira_issue_types_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issue_types". All fields are combined with a logical 'AND'.
 */
export interface jira_issue_types_bool_exp {
  _and?: jira_issue_types_bool_exp[] | null;
  _not?: jira_issue_types_bool_exp | null;
  _or?: jira_issue_types_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issue_type_statuses?: jira_issue_type_statuses_bool_exp | null;
  jira_issues?: jira_issues_bool_exp | null;
  name?: String_comparison_exp | null;
  self?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  subtask?: Boolean_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issue_types"
 */
export interface jira_issue_types_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  id?: number | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issue_type_statuses?: jira_issue_type_statuses_arr_rel_insert_input | null;
  jira_issues?: jira_issues_arr_rel_insert_input | null;
  name?: string | null;
  self?: string | null;
  sid?: string | null;
  subtask?: boolean | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_issue_types"
 */
export interface jira_issue_types_obj_rel_insert_input {
  data: jira_issue_types_insert_input;
  on_conflict?: jira_issue_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_issue_types"
 */
export interface jira_issue_types_on_conflict {
  constraint: jira_issue_types_constraint;
  update_columns: jira_issue_types_update_column[];
  where?: jira_issue_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_issues"
 */
export interface jira_issues_arr_rel_insert_input {
  data: jira_issues_insert_input[];
  on_conflict?: jira_issues_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issues". All fields are combined with a logical 'AND'.
 */
export interface jira_issues_bool_exp {
  _and?: jira_issues_bool_exp[] | null;
  _not?: jira_issues_bool_exp | null;
  _or?: jira_issues_bool_exp[] | null;
  accessFieldValues?: jsonb_comparison_exp | null;
  assignee?: jira_accounts_bool_exp | null;
  assigneeSid?: String_comparison_exp | null;
  author?: jira_accounts_bool_exp | null;
  authorSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueTypeSid?: String_comparison_exp | null;
  jira_issue_attachments?: jira_issue_attachments_bool_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issue_comments?: jira_issue_comment_bool_exp | null;
  jira_issue_field_values?: jira_issue_field_values_bool_exp | null;
  jira_issue_transitions?: jira_issue_transitions_bool_exp | null;
  jira_issue_type?: jira_issue_types_bool_exp | null;
  jira_issue_type_status?: jira_issue_type_statuses_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  key?: String_comparison_exp | null;
  projectSid?: String_comparison_exp | null;
  properties?: jsonb_comparison_exp | null;
  self?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  transitions?: jsonb_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issues"
 */
export interface jira_issues_insert_input {
  accessFieldValues?: hasura_jsonb | null;
  assignee?: jira_accounts_obj_rel_insert_input | null;
  assigneeSid?: string | null;
  author?: jira_accounts_obj_rel_insert_input | null;
  authorSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  issueTypeSid?: string | null;
  jira_issue_attachments?: jira_issue_attachments_arr_rel_insert_input | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issue_comments?: jira_issue_comment_arr_rel_insert_input | null;
  jira_issue_field_values?: jira_issue_field_values_arr_rel_insert_input | null;
  jira_issue_transitions?: jira_issue_transitions_arr_rel_insert_input | null;
  jira_issue_type?: jira_issue_types_obj_rel_insert_input | null;
  jira_issue_type_status?: jira_issue_type_statuses_obj_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  key?: string | null;
  projectSid?: string | null;
  properties?: hasura_jsonb | null;
  self?: string | null;
  sid?: string | null;
  status?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  transitions?: hasura_jsonb | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_issues"
 */
export interface jira_issues_obj_rel_insert_input {
  data: jira_issues_insert_input;
  on_conflict?: jira_issues_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_issues"
 */
export interface jira_issues_on_conflict {
  constraint: jira_issues_constraint;
  update_columns: jira_issues_update_column[];
  where?: jira_issues_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "jira_issues_parsed". All fields are combined with a logical 'AND'.
 */
export interface jira_issues_parsed_bool_exp {
  _and?: jira_issues_parsed_bool_exp[] | null;
  _not?: jira_issues_parsed_bool_exp | null;
  _or?: jira_issues_parsed_bool_exp[] | null;
  accessFieldValues?: jsonb_comparison_exp | null;
  assigneeSid?: String_comparison_exp | null;
  authorSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  issueTypeSid?: String_comparison_exp | null;
  jiraIssueBotAssignee?: String_comparison_exp | null;
  jira_bot_assignee?: jira_accounts_bool_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  key?: String_comparison_exp | null;
  parsedTicketCode?: String_comparison_exp | null;
  projectSid?: String_comparison_exp | null;
  properties?: jsonb_comparison_exp | null;
  self?: String_comparison_exp | null;
  shouldBeInserted?: Boolean_comparison_exp | null;
  shouldBeUpdated?: Boolean_comparison_exp | null;
  sid?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticketLinkedByCode?: String_comparison_exp | null;
  ticketLinkedBySid?: String_comparison_exp | null;
  ticketSourceId?: Int_comparison_exp | null;
  ticketUpdatedAt?: timestamptz_comparison_exp | null;
  ticket_by_parsed_code?: tickets_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  transitions?: jsonb_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_issues_parsed"
 */
export interface jira_issues_parsed_insert_input {
  accessFieldValues?: hasura_jsonb | null;
  assigneeSid?: string | null;
  authorSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  id?: number | null;
  issueTypeSid?: string | null;
  jiraIssueBotAssignee?: string | null;
  jira_bot_assignee?: jira_accounts_obj_rel_insert_input | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  key?: string | null;
  parsedTicketCode?: string | null;
  projectSid?: string | null;
  properties?: hasura_jsonb | null;
  self?: string | null;
  shouldBeInserted?: boolean | null;
  shouldBeUpdated?: boolean | null;
  sid?: string | null;
  status?: string | null;
  syncEntityId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  ticketId?: number | null;
  ticketLinkedByCode?: string | null;
  ticketLinkedBySid?: string | null;
  ticketSourceId?: number | null;
  ticketUpdatedAt?: hasura_timestamptz | null;
  ticket_by_parsed_code?: tickets_obj_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  transitions?: hasura_jsonb | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_issues_parsed"
 */
export interface jira_issues_parsed_obj_rel_insert_input {
  data: jira_issues_parsed_insert_input;
}

/**
 * input type for inserting array relation for remote table "jira_organizations"
 */
export interface jira_organizations_arr_rel_insert_input {
  data: jira_organizations_insert_input[];
  on_conflict?: jira_organizations_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_organizations". All fields are combined with a logical 'AND'.
 */
export interface jira_organizations_bool_exp {
  _and?: jira_organizations_bool_exp[] | null;
  _not?: jira_organizations_bool_exp | null;
  _or?: jira_organizations_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endpoint?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_accounts?: jira_accounts_bool_exp | null;
  jira_projects?: jira_projects_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_organizations"
 */
export interface jira_organizations_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endpoint?: string | null;
  id?: number | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_accounts?: jira_accounts_arr_rel_insert_input | null;
  jira_projects?: jira_projects_arr_rel_insert_input | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_organizations"
 */
export interface jira_organizations_obj_rel_insert_input {
  data: jira_organizations_insert_input;
  on_conflict?: jira_organizations_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_organizations"
 */
export interface jira_organizations_on_conflict {
  constraint: jira_organizations_constraint;
  update_columns: jira_organizations_update_column[];
  where?: jira_organizations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "jira_projects"
 */
export interface jira_projects_arr_rel_insert_input {
  data: jira_projects_insert_input[];
  on_conflict?: jira_projects_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "jira_projects". All fields are combined with a logical 'AND'.
 */
export interface jira_projects_bool_exp {
  _and?: jira_projects_bool_exp[] | null;
  _not?: jira_projects_bool_exp | null;
  _or?: jira_projects_bool_exp[] | null;
  accessJiraAccounts?: jsonb_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  endpoint?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jira_account_projects?: jira_account_projects_bool_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issue_fields?: jira_issue_fields_bool_exp | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_bool_exp | null;
  jira_issues?: jira_issues_bool_exp | null;
  jira_organization?: jira_organizations_bool_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "jira_projects"
 */
export interface jira_projects_insert_input {
  accessJiraAccounts?: hasura_jsonb | null;
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  endpoint?: string | null;
  id?: number | null;
  jira_account_projects?: jira_account_projects_arr_rel_insert_input | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issue_fields?: jira_issue_fields_arr_rel_insert_input | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_arr_rel_insert_input | null;
  jira_issues?: jira_issues_arr_rel_insert_input | null;
  jira_organization?: jira_organizations_obj_rel_insert_input | null;
  key?: string | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "jira_projects"
 */
export interface jira_projects_obj_rel_insert_input {
  data: jira_projects_insert_input;
  on_conflict?: jira_projects_on_conflict | null;
}

/**
 * on_conflict condition type for table "jira_projects"
 */
export interface jira_projects_on_conflict {
  constraint: jira_projects_constraint;
  update_columns: jira_projects_update_column[];
  where?: jira_projects_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "job_intervals". All fields are combined with a logical 'AND'.
 */
export interface job_intervals_bool_exp {
  _and?: job_intervals_bool_exp[] | null;
  _not?: job_intervals_bool_exp | null;
  _or?: job_intervals_bool_exp[] | null;
  description?: String_comparison_exp | null;
  github_code_comments?: github_code_comments_bool_exp | null;
  github_comments?: github_comments_bool_exp | null;
  syncable_entities?: syncable_entities_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "job_intervals_enum". All fields are combined with logical 'AND'.
 */
export interface job_intervals_enum_comparison_exp {
  _eq?: job_intervals_enum | null;
  _in?: job_intervals_enum[] | null;
  _is_null?: boolean | null;
  _neq?: job_intervals_enum | null;
  _nin?: job_intervals_enum[] | null;
}

/**
 * input type for inserting data into table "job_intervals"
 */
export interface job_intervals_insert_input {
  description?: string | null;
  github_code_comments?: github_code_comments_arr_rel_insert_input | null;
  github_comments?: github_comments_arr_rel_insert_input | null;
  syncable_entities?: syncable_entities_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "job_intervals"
 */
export interface job_intervals_obj_rel_insert_input {
  data: job_intervals_insert_input;
  on_conflict?: job_intervals_on_conflict | null;
}

/**
 * on_conflict condition type for table "job_intervals"
 */
export interface job_intervals_on_conflict {
  constraint: job_intervals_constraint;
  update_columns: job_intervals_update_column[];
  where?: job_intervals_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _contained_in?: hasura_jsonb | null;
  _contains?: hasura_jsonb | null;
  _eq?: hasura_jsonb | null;
  _gt?: hasura_jsonb | null;
  _gte?: hasura_jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: hasura_jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_jsonb | null;
  _lte?: hasura_jsonb | null;
  _neq?: hasura_jsonb | null;
  _nin?: hasura_jsonb[] | null;
}

/**
 * Boolean expression to filter rows from the table "legal_entities". All fields are combined with a logical 'AND'.
 */
export interface legal_entities_bool_exp {
  _and?: legal_entities_bool_exp[] | null;
  _not?: legal_entities_bool_exp | null;
  _or?: legal_entities_bool_exp[] | null;
  client_changes?: client_changes_bool_exp | null;
  clients?: clients_bool_exp | null;
  description?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "legal_entities_enum". All fields are combined with logical 'AND'.
 */
export interface legal_entities_enum_comparison_exp {
  _eq?: legal_entities_enum | null;
  _in?: legal_entities_enum[] | null;
  _is_null?: boolean | null;
  _neq?: legal_entities_enum | null;
  _nin?: legal_entities_enum[] | null;
}

/**
 * input type for inserting data into table "legal_entities"
 */
export interface legal_entities_insert_input {
  client_changes?: client_changes_arr_rel_insert_input | null;
  clients?: clients_arr_rel_insert_input | null;
  description?: string | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "legal_entities"
 */
export interface legal_entities_obj_rel_insert_input {
  data: legal_entities_insert_input;
  on_conflict?: legal_entities_on_conflict | null;
}

/**
 * on_conflict condition type for table "legal_entities"
 */
export interface legal_entities_on_conflict {
  constraint: legal_entities_constraint;
  update_columns: legal_entities_update_column[];
  where?: legal_entities_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_accounts"
 */
export interface linear_accounts_arr_rel_insert_input {
  data: linear_accounts_insert_input[];
  on_conflict?: linear_accounts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_accounts". All fields are combined with a logical 'AND'.
 */
export interface linear_accounts_bool_exp {
  _and?: linear_accounts_bool_exp[] | null;
  _not?: linear_accounts_bool_exp | null;
  _or?: linear_accounts_bool_exp[] | null;
  bot?: bots_bool_exp | null;
  bots?: bots_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  linear_app_installations?: linear_app_installations_bool_exp | null;
  linear_comments?: linear_comments_bool_exp | null;
  linear_issue_assignees?: linear_issue_assignees_bool_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  token?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "linear_accounts"
 */
export interface linear_accounts_insert_input {
  bot?: bots_obj_rel_insert_input | null;
  bots?: bots_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  email?: string | null;
  id?: number | null;
  linear_app_installations?: linear_app_installations_arr_rel_insert_input | null;
  linear_comments?: linear_comments_arr_rel_insert_input | null;
  linear_issue_assignees?: linear_issue_assignees_arr_rel_insert_input | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  token?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "linear_accounts"
 */
export interface linear_accounts_obj_rel_insert_input {
  data: linear_accounts_insert_input;
  on_conflict?: linear_accounts_on_conflict | null;
}

/**
 * on_conflict condition type for table "linear_accounts"
 */
export interface linear_accounts_on_conflict {
  constraint: linear_accounts_constraint;
  update_columns: linear_accounts_update_column[];
  where?: linear_accounts_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_app_installations"
 */
export interface linear_app_installations_arr_rel_insert_input {
  data: linear_app_installations_insert_input[];
  on_conflict?: linear_app_installations_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_app_installations". All fields are combined with a logical 'AND'.
 */
export interface linear_app_installations_bool_exp {
  _and?: linear_app_installations_bool_exp[] | null;
  _not?: linear_app_installations_bool_exp | null;
  _or?: linear_app_installations_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  accessTokenType?: String_comparison_exp | null;
  accountSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  expiresAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  linearAppId?: Int_comparison_exp | null;
  linear_account?: linear_accounts_bool_exp | null;
  linear_app?: linear_apps_bool_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  scopes?: jsonb_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "linear_app_installations"
 */
export interface linear_app_installations_insert_input {
  accessToken?: string | null;
  accessTokenType?: string | null;
  accountSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  expiresAt?: hasura_timestamptz | null;
  id?: number | null;
  linearAppId?: number | null;
  linear_account?: linear_accounts_obj_rel_insert_input | null;
  linear_app?: linear_apps_obj_rel_insert_input | null;
  linear_team?: linear_teams_obj_rel_insert_input | null;
  scopes?: hasura_jsonb | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "linear_app_installations"
 */
export interface linear_app_installations_on_conflict {
  constraint: linear_app_installations_constraint;
  update_columns: linear_app_installations_update_column[];
  where?: linear_app_installations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_apps"
 */
export interface linear_apps_arr_rel_insert_input {
  data: linear_apps_insert_input[];
  on_conflict?: linear_apps_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_apps". All fields are combined with a logical 'AND'.
 */
export interface linear_apps_bool_exp {
  _and?: linear_apps_bool_exp[] | null;
  _not?: linear_apps_bool_exp | null;
  _or?: linear_apps_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  clientSecret?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  linear_app_installations?: linear_app_installations_bool_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "linear_apps"
 */
export interface linear_apps_insert_input {
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  linear_app_installations?: linear_app_installations_arr_rel_insert_input | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "linear_apps"
 */
export interface linear_apps_obj_rel_insert_input {
  data: linear_apps_insert_input;
  on_conflict?: linear_apps_on_conflict | null;
}

/**
 * on_conflict condition type for table "linear_apps"
 */
export interface linear_apps_on_conflict {
  constraint: linear_apps_constraint;
  update_columns: linear_apps_update_column[];
  where?: linear_apps_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_comments"
 */
export interface linear_comments_arr_rel_insert_input {
  data: linear_comments_insert_input[];
  on_conflict?: linear_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_comments". All fields are combined with a logical 'AND'.
 */
export interface linear_comments_bool_exp {
  _and?: linear_comments_bool_exp[] | null;
  _not?: linear_comments_bool_exp | null;
  _or?: linear_comments_bool_exp[] | null;
  authorSid?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  id?: Int_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  linear_account?: linear_accounts_bool_exp | null;
  linear_issue?: linear_issues_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "linear_comments"
 */
export interface linear_comments_insert_input {
  authorSid?: string | null;
  body?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  id?: number | null;
  issueSid?: string | null;
  linear_account?: linear_accounts_obj_rel_insert_input | null;
  linear_issue?: linear_issues_obj_rel_insert_input | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "linear_comments"
 */
export interface linear_comments_on_conflict {
  constraint: linear_comments_constraint;
  update_columns: linear_comments_update_column[];
  where?: linear_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_issue_assignees"
 */
export interface linear_issue_assignees_arr_rel_insert_input {
  data: linear_issue_assignees_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "linear_issue_assignees". All fields are combined with a logical 'AND'.
 */
export interface linear_issue_assignees_bool_exp {
  _and?: linear_issue_assignees_bool_exp[] | null;
  _not?: linear_issue_assignees_bool_exp | null;
  _or?: linear_issue_assignees_bool_exp[] | null;
  accountSid?: String_comparison_exp | null;
  issueSid?: String_comparison_exp | null;
  linear_account?: linear_accounts_bool_exp | null;
  linear_issue?: linear_issues_bool_exp | null;
}

/**
 * input type for inserting data into table "linear_issue_assignees"
 */
export interface linear_issue_assignees_insert_input {
  accountSid?: string | null;
  issueSid?: string | null;
  linear_account?: linear_accounts_obj_rel_insert_input | null;
  linear_issue?: linear_issues_obj_rel_insert_input | null;
}

/**
 * input type for inserting array relation for remote table "linear_issue_changes"
 */
export interface linear_issue_changes_arr_rel_insert_input {
  data: linear_issue_changes_insert_input[];
  on_conflict?: linear_issue_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_issue_changes". All fields are combined with a logical 'AND'.
 */
export interface linear_issue_changes_bool_exp {
  _and?: linear_issue_changes_bool_exp[] | null;
  _not?: linear_issue_changes_bool_exp | null;
  _or?: linear_issue_changes_bool_exp[] | null;
  assignedLinearAccounts?: jsonb_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  labels?: _text_comparison_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  number?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "linear_issue_changes"
 */
export interface linear_issue_changes_insert_input {
  assignedLinearAccounts?: hasura_jsonb | null;
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  labels?: hasura__text | null;
  linear_team?: linear_teams_obj_rel_insert_input | null;
  number?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  sid?: string | null;
  sqlQuery?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  url?: string | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "linear_issue_changes"
 */
export interface linear_issue_changes_on_conflict {
  constraint: linear_issue_changes_constraint;
  update_columns: linear_issue_changes_update_column[];
  where?: linear_issue_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_issues"
 */
export interface linear_issues_arr_rel_insert_input {
  data: linear_issues_insert_input[];
  on_conflict?: linear_issues_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_issues". All fields are combined with a logical 'AND'.
 */
export interface linear_issues_bool_exp {
  _and?: linear_issues_bool_exp[] | null;
  _not?: linear_issues_bool_exp | null;
  _or?: linear_issues_bool_exp[] | null;
  assignedLinearAccounts?: jsonb_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  labels?: _text_comparison_exp | null;
  linear_comments?: linear_comments_bool_exp | null;
  linear_issue_assignees?: linear_issue_assignees_bool_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  number?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "linear_issues"
 */
export interface linear_issues_insert_input {
  assignedLinearAccounts?: hasura_jsonb | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  labels?: hasura__text | null;
  linear_comments?: linear_comments_arr_rel_insert_input | null;
  linear_issue_assignees?: linear_issue_assignees_arr_rel_insert_input | null;
  linear_team?: linear_teams_obj_rel_insert_input | null;
  number?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  url?: string | null;
}

/**
 * input type for inserting object relation for remote table "linear_issues"
 */
export interface linear_issues_obj_rel_insert_input {
  data: linear_issues_insert_input;
  on_conflict?: linear_issues_on_conflict | null;
}

/**
 * on_conflict condition type for table "linear_issues"
 */
export interface linear_issues_on_conflict {
  constraint: linear_issues_constraint;
  update_columns: linear_issues_update_column[];
  where?: linear_issues_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "linear_teams"
 */
export interface linear_teams_arr_rel_insert_input {
  data: linear_teams_insert_input[];
  on_conflict?: linear_teams_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "linear_teams". All fields are combined with a logical 'AND'.
 */
export interface linear_teams_bool_exp {
  _and?: linear_teams_bool_exp[] | null;
  _not?: linear_teams_bool_exp | null;
  _or?: linear_teams_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  key?: String_comparison_exp | null;
  linear_app_installations?: linear_app_installations_bool_exp | null;
  linear_issue_changes?: linear_issue_changes_bool_exp | null;
  linear_issues?: linear_issues_bool_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_sources?: ticket_sources_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "linear_teams"
 */
export interface linear_teams_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  key?: string | null;
  linear_app_installations?: linear_app_installations_arr_rel_insert_input | null;
  linear_issue_changes?: linear_issue_changes_arr_rel_insert_input | null;
  linear_issues?: linear_issues_arr_rel_insert_input | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_sources?: ticket_sources_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "linear_teams"
 */
export interface linear_teams_obj_rel_insert_input {
  data: linear_teams_insert_input;
  on_conflict?: linear_teams_on_conflict | null;
}

/**
 * on_conflict condition type for table "linear_teams"
 */
export interface linear_teams_on_conflict {
  constraint: linear_teams_constraint;
  update_columns: linear_teams_update_column[];
  where?: linear_teams_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "meeting_user_emails"
 */
export interface meeting_user_emails_arr_rel_insert_input {
  data: meeting_user_emails_insert_input[];
  on_conflict?: meeting_user_emails_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "meeting_user_emails". All fields are combined with a logical 'AND'.
 */
export interface meeting_user_emails_bool_exp {
  _and?: meeting_user_emails_bool_exp[] | null;
  _not?: meeting_user_emails_bool_exp | null;
  _or?: meeting_user_emails_bool_exp[] | null;
  email?: String_comparison_exp | null;
  meeting?: meetings_bool_exp | null;
  meetingId?: Int_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "meeting_user_emails"
 */
export interface meeting_user_emails_insert_input {
  email?: string | null;
  meeting?: meetings_obj_rel_insert_input | null;
  meetingId?: number | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "meeting_user_emails"
 */
export interface meeting_user_emails_on_conflict {
  constraint: meeting_user_emails_constraint;
  update_columns: meeting_user_emails_update_column[];
  where?: meeting_user_emails_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "meetings"
 */
export interface meetings_arr_rel_insert_input {
  data: meetings_insert_input[];
  on_conflict?: meetings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "meetings". All fields are combined with a logical 'AND'.
 */
export interface meetings_bool_exp {
  _and?: meetings_bool_exp[] | null;
  _not?: meetings_bool_exp | null;
  _or?: meetings_bool_exp[] | null;
  associatedClientId?: String_comparison_exp | null;
  calendlyMeetingId?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  meeting_user_emails?: meeting_user_emails_bool_exp | null;
  scheduledAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
}

/**
 * input type for inserting data into table "meetings"
 */
export interface meetings_insert_input {
  associatedClientId?: string | null;
  calendlyMeetingId?: string | null;
  client?: clients_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  meeting_user_emails?: meeting_user_emails_arr_rel_insert_input | null;
  scheduledAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeetingSid?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "meetings"
 */
export interface meetings_obj_rel_insert_input {
  data: meetings_insert_input;
  on_conflict?: meetings_on_conflict | null;
}

/**
 * on_conflict condition type for table "meetings"
 */
export interface meetings_on_conflict {
  constraint: meetings_constraint;
  update_columns: meetings_update_column[];
  where?: meetings_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "mentor_status". All fields are combined with a logical 'AND'.
 */
export interface mentor_status_bool_exp {
  _and?: mentor_status_bool_exp[] | null;
  _not?: mentor_status_bool_exp | null;
  _or?: mentor_status_bool_exp[] | null;
  mentors?: mentors_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "mentor_status"
 */
export interface mentor_status_insert_input {
  mentors?: mentors_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "mentor_status"
 */
export interface mentor_status_obj_rel_insert_input {
  data: mentor_status_insert_input;
  on_conflict?: mentor_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "mentor_status"
 */
export interface mentor_status_on_conflict {
  constraint: mentor_status_constraint;
  update_columns: mentor_status_update_column[];
  where?: mentor_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "mentors"
 */
export interface mentors_arr_rel_insert_input {
  data: mentors_insert_input[];
  on_conflict?: mentors_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "mentors". All fields are combined with a logical 'AND'.
 */
export interface mentors_bool_exp {
  _and?: mentors_bool_exp[] | null;
  _not?: mentors_bool_exp | null;
  _or?: mentors_bool_exp[] | null;
  appliedAt?: timestamptz_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  mentor_status?: mentor_status_bool_exp | null;
  reasonsForApplying?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "mentors"
 */
export interface mentors_insert_input {
  appliedAt?: hasura_timestamptz | null;
  completedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  mentor_status?: mentor_status_obj_rel_insert_input | null;
  reasonsForApplying?: string | null;
  status?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "mentors"
 */
export interface mentors_on_conflict {
  constraint: mentors_constraint;
  update_columns: mentors_update_column[];
  where?: mentors_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "mentorships"
 */
export interface mentorships_arr_rel_insert_input {
  data: mentorships_insert_input[];
  on_conflict?: mentorships_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "mentorships". All fields are combined with a logical 'AND'.
 */
export interface mentorships_bool_exp {
  _and?: mentorships_bool_exp[] | null;
  _not?: mentorships_bool_exp | null;
  _or?: mentorships_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  mentee?: users_bool_exp | null;
  menteeUserId?: Int_comparison_exp | null;
  mentor?: users_bool_exp | null;
  mentorUserId?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "mentorships"
 */
export interface mentorships_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endDate?: hasura_timestamptz | null;
  id?: number | null;
  mentee?: users_obj_rel_insert_input | null;
  menteeUserId?: number | null;
  mentor?: users_obj_rel_insert_input | null;
  mentorUserId?: number | null;
  startDate?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "mentorships"
 */
export interface mentorships_on_conflict {
  constraint: mentorships_constraint;
  update_columns: mentorships_update_column[];
  where?: mentorships_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "notification_sources". All fields are combined with a logical 'AND'.
 */
export interface notification_sources_bool_exp {
  _and?: notification_sources_bool_exp[] | null;
  _not?: notification_sources_bool_exp | null;
  _or?: notification_sources_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "notification_sources_enum". All fields are combined with logical 'AND'.
 */
export interface notification_sources_enum_comparison_exp {
  _eq?: notification_sources_enum | null;
  _in?: notification_sources_enum[] | null;
  _is_null?: boolean | null;
  _neq?: notification_sources_enum | null;
  _nin?: notification_sources_enum[] | null;
}

/**
 * input type for inserting data into table "notification_sources"
 */
export interface notification_sources_insert_input {
  description?: string | null;
  id?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "notification_sources"
 */
export interface notification_sources_obj_rel_insert_input {
  data: notification_sources_insert_input;
  on_conflict?: notification_sources_on_conflict | null;
}

/**
 * on_conflict condition type for table "notification_sources"
 */
export interface notification_sources_on_conflict {
  constraint: notification_sources_constraint;
  update_columns: notification_sources_update_column[];
  where?: notification_sources_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "notifications"
 */
export interface notifications_arr_rel_insert_input {
  data: notifications_insert_input[];
  on_conflict?: notifications_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'.
 */
export interface notifications_bool_exp {
  _and?: notifications_bool_exp[] | null;
  _not?: notifications_bool_exp | null;
  _or?: notifications_bool_exp[] | null;
  appLink?: String_comparison_exp | null;
  body?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentId?: Int_comparison_exp | null;
  discussion_comment?: discussion_comments_bool_exp | null;
  error?: errors_bool_exp | null;
  errorId?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isArchived?: Boolean_comparison_exp | null;
  isSeen?: Boolean_comparison_exp | null;
  notification_source?: notification_sources_bool_exp | null;
  projectId?: Int_comparison_exp | null;
  sentAt?: timestamptz_comparison_exp | null;
  sourceId?: notification_sources_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  teamId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting data into table "notifications"
 */
export interface notifications_insert_input {
  appLink?: string | null;
  body?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentId?: number | null;
  discussion_comment?: discussion_comments_obj_rel_insert_input | null;
  error?: errors_obj_rel_insert_input | null;
  errorId?: number | null;
  id?: number | null;
  isArchived?: boolean | null;
  isSeen?: boolean | null;
  notification_source?: notification_sources_obj_rel_insert_input | null;
  projectId?: number | null;
  sentAt?: hasura_timestamptz | null;
  sourceId?: notification_sources_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  teamId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "notifications"
 */
export interface notifications_on_conflict {
  constraint: notifications_constraint;
  update_columns: notifications_update_column[];
  where?: notifications_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: hasura_numeric | null;
  _gt?: hasura_numeric | null;
  _gte?: hasura_numeric | null;
  _in?: hasura_numeric[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_numeric | null;
  _lte?: hasura_numeric | null;
  _neq?: hasura_numeric | null;
  _nin?: hasura_numeric[] | null;
}

/**
 * Boolean expression to filter rows from the table "opportunities". All fields are combined with a logical 'AND'.
 */
export interface opportunities_bool_exp {
  _and?: opportunities_bool_exp[] | null;
  _not?: opportunities_bool_exp | null;
  _or?: opportunities_bool_exp[] | null;
  availableUntil?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  opportunity_skill_requirements?: opportunity_skill_requirements_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_opportunity_interests?: user_opportunity_interests_bool_exp | null;
}

/**
 * input type for inserting data into table "opportunities"
 */
export interface opportunities_insert_input {
  availableUntil?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  opportunity_skill_requirements?: opportunity_skill_requirements_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user_opportunity_interests?: user_opportunity_interests_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "opportunities"
 */
export interface opportunities_obj_rel_insert_input {
  data: opportunities_insert_input;
  on_conflict?: opportunities_on_conflict | null;
}

/**
 * on_conflict condition type for table "opportunities"
 */
export interface opportunities_on_conflict {
  constraint: opportunities_constraint;
  update_columns: opportunities_update_column[];
  where?: opportunities_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "opportunity_skill_requirements"
 */
export interface opportunity_skill_requirements_arr_rel_insert_input {
  data: opportunity_skill_requirements_insert_input[];
  on_conflict?: opportunity_skill_requirements_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "opportunity_skill_requirements". All fields are combined with a logical 'AND'.
 */
export interface opportunity_skill_requirements_bool_exp {
  _and?: opportunity_skill_requirements_bool_exp[] | null;
  _not?: opportunity_skill_requirements_bool_exp | null;
  _or?: opportunity_skill_requirements_bool_exp[] | null;
  opportunitiesId?: Int_comparison_exp | null;
  opportunity?: opportunities_bool_exp | null;
  skill?: skills_bool_exp | null;
  skillsId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "opportunity_skill_requirements"
 */
export interface opportunity_skill_requirements_insert_input {
  opportunitiesId?: number | null;
  opportunity?: opportunities_obj_rel_insert_input | null;
  skill?: skills_obj_rel_insert_input | null;
  skillsId?: number | null;
}

/**
 * on_conflict condition type for table "opportunity_skill_requirements"
 */
export interface opportunity_skill_requirements_on_conflict {
  constraint: opportunity_skill_requirements_constraint;
  update_columns: opportunity_skill_requirements_update_column[];
  where?: opportunity_skill_requirements_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "optional_technologies"
 */
export interface optional_technologies_arr_rel_insert_input {
  data: optional_technologies_insert_input[];
  on_conflict?: optional_technologies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "optional_technologies". All fields are combined with a logical 'AND'.
 */
export interface optional_technologies_bool_exp {
  _and?: optional_technologies_bool_exp[] | null;
  _not?: optional_technologies_bool_exp | null;
  _or?: optional_technologies_bool_exp[] | null;
  clientJobsId?: uuid_comparison_exp | null;
  client_job?: client_jobs_bool_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
}

/**
 * input type for inserting data into table "optional_technologies"
 */
export interface optional_technologies_insert_input {
  clientJobsId?: hasura_uuid | null;
  client_job?: client_jobs_obj_rel_insert_input | null;
  technologiesId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "optional_technologies"
 */
export interface optional_technologies_on_conflict {
  constraint: optional_technologies_constraint;
  update_columns: optional_technologies_update_column[];
  where?: optional_technologies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "project_daily_standup"
 */
export interface project_daily_standup_arr_rel_insert_input {
  data: project_daily_standup_insert_input[];
  on_conflict?: project_daily_standup_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "project_daily_standup". All fields are combined with a logical 'AND'.
 */
export interface project_daily_standup_bool_exp {
  _and?: project_daily_standup_bool_exp[] | null;
  _not?: project_daily_standup_bool_exp | null;
  _or?: project_daily_standup_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_daily_standup?: client_daily_standups_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  error?: errors_bool_exp | null;
  errorId?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  messageForClient?: String_comparison_exp | null;
  postedToClient?: Boolean_comparison_exp | null;
  submittedAt?: timestamptz_comparison_exp | null;
  summarizedInClientDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  summary?: String_comparison_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * input type for inserting data into table "project_daily_standup"
 */
export interface project_daily_standup_insert_input {
  clientProjectId?: number | null;
  client_daily_standup?: client_daily_standups_obj_rel_insert_input | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  error?: errors_obj_rel_insert_input | null;
  errorId?: number | null;
  id?: number | null;
  messageForClient?: string | null;
  postedToClient?: boolean | null;
  submittedAt?: hasura_timestamptz | null;
  summarizedInClientDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  summary?: string | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_daily_standup?: user_daily_standups_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "project_daily_standup"
 */
export interface project_daily_standup_obj_rel_insert_input {
  data: project_daily_standup_insert_input;
  on_conflict?: project_daily_standup_on_conflict | null;
}

/**
 * on_conflict condition type for table "project_daily_standup"
 */
export interface project_daily_standup_on_conflict {
  constraint: project_daily_standup_constraint;
  update_columns: project_daily_standup_update_column[];
  where?: project_daily_standup_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "project_managers"
 */
export interface project_managers_arr_rel_insert_input {
  data: project_managers_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "project_managers". All fields are combined with a logical 'AND'.
 */
export interface project_managers_bool_exp {
  _and?: project_managers_bool_exp[] | null;
  _not?: project_managers_bool_exp | null;
  _or?: project_managers_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  isActive?: Boolean_comparison_exp | null;
  projectId?: Int_comparison_exp | null;
  projectName?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userTeamId?: Int_comparison_exp | null;
  userTeamName?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "project_managers"
 */
export interface project_managers_insert_input {
  clientId?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  isActive?: boolean | null;
  projectId?: number | null;
  projectName?: string | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  userTeamId?: number | null;
  userTeamName?: string | null;
}

/**
 * input type for inserting array relation for remote table "pull_request_estimates"
 */
export interface pull_request_estimates_arr_rel_insert_input {
  data: pull_request_estimates_insert_input[];
  on_conflict?: pull_request_estimates_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "pull_request_estimates". All fields are combined with a logical 'AND'.
 */
export interface pull_request_estimates_bool_exp {
  _and?: pull_request_estimates_bool_exp[] | null;
  _not?: pull_request_estimates_bool_exp | null;
  _or?: pull_request_estimates_bool_exp[] | null;
  blockers?: blockers_bool_exp | null;
  estimated_by_id?: bigint_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  inserted_at?: timestamptz_comparison_exp | null;
  lower_estimate_in_credits?: Int_comparison_exp | null;
  pull_request?: pull_requests_bool_exp | null;
  pull_request_id?: bigint_comparison_exp | null;
  reviewed_by_id?: bigint_comparison_exp | null;
  status?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  upper_estimate_in_credits?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userByReviewedById?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "pull_request_estimates"
 */
export interface pull_request_estimates_insert_input {
  blockers?: blockers_arr_rel_insert_input | null;
  estimated_by_id?: hasura_bigint | null;
  id?: hasura_bigint | null;
  inserted_at?: hasura_timestamptz | null;
  lower_estimate_in_credits?: number | null;
  pull_request?: pull_requests_obj_rel_insert_input | null;
  pull_request_id?: hasura_bigint | null;
  reviewed_by_id?: hasura_bigint | null;
  status?: string | null;
  updated_at?: hasura_timestamptz | null;
  upper_estimate_in_credits?: number | null;
  user?: users_obj_rel_insert_input | null;
  userByReviewedById?: users_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "pull_request_estimates"
 */
export interface pull_request_estimates_obj_rel_insert_input {
  data: pull_request_estimates_insert_input;
  on_conflict?: pull_request_estimates_on_conflict | null;
}

/**
 * on_conflict condition type for table "pull_request_estimates"
 */
export interface pull_request_estimates_on_conflict {
  constraint: pull_request_estimates_constraint;
  update_columns: pull_request_estimates_update_column[];
  where?: pull_request_estimates_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "pull_requests"
 */
export interface pull_requests_arr_rel_insert_input {
  data: pull_requests_insert_input[];
  on_conflict?: pull_requests_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "pull_requests". All fields are combined with a logical 'AND'.
 */
export interface pull_requests_bool_exp {
  _and?: pull_requests_bool_exp[] | null;
  _not?: pull_requests_bool_exp | null;
  _or?: pull_requests_bool_exp[] | null;
  blockers?: blockers_bool_exp | null;
  completed_at?: timestamptz_comparison_exp | null;
  cost_finalized_at?: timestamptz_comparison_exp | null;
  cost_in_credits?: Int_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  gitPullRequestByUpstreamPullRequestId?: git_pull_requests_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  id?: bigint_comparison_exp | null;
  inserted_at?: timestamptz_comparison_exp | null;
  pull_request_estimates?: pull_request_estimates_bool_exp | null;
  sliced_pull_request_id?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticket_id?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  upstream_pull_request_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "pull_requests"
 */
export interface pull_requests_insert_input {
  blockers?: blockers_arr_rel_insert_input | null;
  completed_at?: hasura_timestamptz | null;
  cost_finalized_at?: hasura_timestamptz | null;
  cost_in_credits?: number | null;
  deleted_at?: hasura_timestamptz | null;
  gitPullRequestByUpstreamPullRequestId?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  id?: hasura_bigint | null;
  inserted_at?: hasura_timestamptz | null;
  pull_request_estimates?: pull_request_estimates_arr_rel_insert_input | null;
  sliced_pull_request_id?: number | null;
  status?: string | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticket_id?: number | null;
  title?: string | null;
  updated_at?: hasura_timestamptz | null;
  upstream_pull_request_id?: hasura_bigint | null;
}

/**
 * input type for inserting object relation for remote table "pull_requests"
 */
export interface pull_requests_obj_rel_insert_input {
  data: pull_requests_insert_input;
  on_conflict?: pull_requests_on_conflict | null;
}

/**
 * on_conflict condition type for table "pull_requests"
 */
export interface pull_requests_on_conflict {
  constraint: pull_requests_constraint;
  update_columns: pull_requests_update_column[];
  where?: pull_requests_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "qualified_agencies"
 */
export interface qualified_agencies_arr_rel_insert_input {
  data: qualified_agencies_insert_input[];
  on_conflict?: qualified_agencies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "qualified_agencies". All fields are combined with a logical 'AND'.
 */
export interface qualified_agencies_bool_exp {
  _and?: qualified_agencies_bool_exp[] | null;
  _not?: qualified_agencies_bool_exp | null;
  _or?: qualified_agencies_bool_exp[] | null;
  agenciesId?: String_comparison_exp | null;
  agency?: agencies_bool_exp | null;
  client?: clients_bool_exp | null;
  clientsId?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "qualified_agencies"
 */
export interface qualified_agencies_insert_input {
  agenciesId?: string | null;
  agency?: agencies_obj_rel_insert_input | null;
  client?: clients_obj_rel_insert_input | null;
  clientsId?: string | null;
}

/**
 * on_conflict condition type for table "qualified_agencies"
 */
export interface qualified_agencies_on_conflict {
  constraint: qualified_agencies_constraint;
  update_columns: qualified_agencies_update_column[];
  where?: qualified_agencies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "qualified_technologies"
 */
export interface qualified_technologies_arr_rel_insert_input {
  data: qualified_technologies_insert_input[];
  on_conflict?: qualified_technologies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "qualified_technologies". All fields are combined with a logical 'AND'.
 */
export interface qualified_technologies_bool_exp {
  _and?: qualified_technologies_bool_exp[] | null;
  _not?: qualified_technologies_bool_exp | null;
  _or?: qualified_technologies_bool_exp[] | null;
  clientJobsId?: uuid_comparison_exp | null;
  client_job?: client_jobs_bool_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
}

/**
 * input type for inserting data into table "qualified_technologies"
 */
export interface qualified_technologies_insert_input {
  clientJobsId?: hasura_uuid | null;
  client_job?: client_jobs_obj_rel_insert_input | null;
  technologiesId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "qualified_technologies"
 */
export interface qualified_technologies_on_conflict {
  constraint: qualified_technologies_constraint;
  update_columns: qualified_technologies_update_column[];
  where?: qualified_technologies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruiter_agencies"
 */
export interface recruiter_agencies_arr_rel_insert_input {
  data: recruiter_agencies_insert_input[];
  on_conflict?: recruiter_agencies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruiter_agencies". All fields are combined with a logical 'AND'.
 */
export interface recruiter_agencies_bool_exp {
  _and?: recruiter_agencies_bool_exp[] | null;
  _not?: recruiter_agencies_bool_exp | null;
  _or?: recruiter_agencies_bool_exp[] | null;
  agency?: agencies_bool_exp | null;
  agencyByRecruiterAgencyId?: agencies_bool_exp | null;
  agency_id?: String_comparison_exp | null;
  recruiter_agency_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruiter_agencies"
 */
export interface recruiter_agencies_insert_input {
  agency?: agencies_obj_rel_insert_input | null;
  agencyByRecruiterAgencyId?: agencies_obj_rel_insert_input | null;
  agency_id?: string | null;
  recruiter_agency_id?: string | null;
}

/**
 * on_conflict condition type for table "recruiter_agencies"
 */
export interface recruiter_agencies_on_conflict {
  constraint: recruiter_agencies_constraint;
  update_columns: recruiter_agencies_update_column[];
  where?: recruiter_agencies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruiter.calendly_event_memberships"
 */
export interface recruiter_calendly_event_memberships_arr_rel_insert_input {
  data: recruiter_calendly_event_memberships_insert_input[];
  on_conflict?: recruiter_calendly_event_memberships_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruiter.calendly_event_memberships". All fields are combined with a logical 'AND'.
 */
export interface recruiter_calendly_event_memberships_bool_exp {
  _and?: recruiter_calendly_event_memberships_bool_exp[] | null;
  _not?: recruiter_calendly_event_memberships_bool_exp | null;
  _or?: recruiter_calendly_event_memberships_bool_exp[] | null;
  calendly_event?: recruiter_calendly_events_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  event?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruiter.calendly_event_memberships"
 */
export interface recruiter_calendly_event_memberships_insert_input {
  calendly_event?: recruiter_calendly_events_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  event?: string | null;
  id?: number | null;
  name?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: string | null;
}

/**
 * on_conflict condition type for table "recruiter.calendly_event_memberships"
 */
export interface recruiter_calendly_event_memberships_on_conflict {
  constraint: recruiter_calendly_event_memberships_constraint;
  update_columns: recruiter_calendly_event_memberships_update_column[];
  where?: recruiter_calendly_event_memberships_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruiter.calendly_event_types"
 */
export interface recruiter_calendly_event_types_arr_rel_insert_input {
  data: recruiter_calendly_event_types_insert_input[];
  on_conflict?: recruiter_calendly_event_types_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruiter.calendly_event_types". All fields are combined with a logical 'AND'.
 */
export interface recruiter_calendly_event_types_bool_exp {
  _and?: recruiter_calendly_event_types_bool_exp[] | null;
  _not?: recruiter_calendly_event_types_bool_exp | null;
  _or?: recruiter_calendly_event_types_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  calendly_event_type_owner?: user_emails_bool_exp | null;
  calendly_events?: recruiter_calendly_events_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  durationInMins?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  kind?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  owner?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruiter.calendly_event_types"
 */
export interface recruiter_calendly_event_types_insert_input {
  active?: boolean | null;
  calendly_event_type_owner?: user_emails_obj_rel_insert_input | null;
  calendly_events?: recruiter_calendly_events_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  durationInMins?: number | null;
  id?: number | null;
  kind?: string | null;
  name?: string | null;
  owner?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "recruiter.calendly_event_types"
 */
export interface recruiter_calendly_event_types_obj_rel_insert_input {
  data: recruiter_calendly_event_types_insert_input;
  on_conflict?: recruiter_calendly_event_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "recruiter.calendly_event_types"
 */
export interface recruiter_calendly_event_types_on_conflict {
  constraint: recruiter_calendly_event_types_constraint;
  update_columns: recruiter_calendly_event_types_update_column[];
  where?: recruiter_calendly_event_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruiter.calendly_events"
 */
export interface recruiter_calendly_events_arr_rel_insert_input {
  data: recruiter_calendly_events_insert_input[];
  on_conflict?: recruiter_calendly_events_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruiter.calendly_events". All fields are combined with a logical 'AND'.
 */
export interface recruiter_calendly_events_bool_exp {
  _and?: recruiter_calendly_events_bool_exp[] | null;
  _not?: recruiter_calendly_events_bool_exp | null;
  _or?: recruiter_calendly_events_bool_exp[] | null;
  calendly_event_memberships?: recruiter_calendly_event_memberships_bool_exp | null;
  calendly_event_type?: recruiter_calendly_event_types_bool_exp | null;
  cancelName?: String_comparison_exp | null;
  cancelReason?: String_comparison_exp | null;
  canceled?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endTime?: timestamptz_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  event_invitee?: user_emails_bool_exp | null;
  id?: Int_comparison_exp | null;
  invitee?: String_comparison_exp | null;
  location?: String_comparison_exp | null;
  locationType?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  rescheduled?: Boolean_comparison_exp | null;
  sid?: String_comparison_exp | null;
  startTime?: timestamptz_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeeting?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
}

/**
 * input type for inserting data into table "recruiter.calendly_events"
 */
export interface recruiter_calendly_events_insert_input {
  calendly_event_memberships?: recruiter_calendly_event_memberships_arr_rel_insert_input | null;
  calendly_event_type?: recruiter_calendly_event_types_obj_rel_insert_input | null;
  cancelName?: string | null;
  cancelReason?: string | null;
  canceled?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endTime?: hasura_timestamptz | null;
  eventType?: string | null;
  event_invitee?: user_emails_obj_rel_insert_input | null;
  id?: number | null;
  invitee?: string | null;
  location?: string | null;
  locationType?: string | null;
  name?: string | null;
  rescheduled?: boolean | null;
  sid?: string | null;
  startTime?: hasura_timestamptz | null;
  syncEntityId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeeting?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "recruiter.calendly_events"
 */
export interface recruiter_calendly_events_obj_rel_insert_input {
  data: recruiter_calendly_events_insert_input;
  on_conflict?: recruiter_calendly_events_on_conflict | null;
}

/**
 * on_conflict condition type for table "recruiter.calendly_events"
 */
export interface recruiter_calendly_events_on_conflict {
  constraint: recruiter_calendly_events_constraint;
  update_columns: recruiter_calendly_events_update_column[];
  where?: recruiter_calendly_events_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruiter.calendly_webhook_subcriptions"
 */
export interface recruiter_calendly_webhook_subcriptions_arr_rel_insert_input {
  data: recruiter_calendly_webhook_subcriptions_insert_input[];
  on_conflict?: recruiter_calendly_webhook_subcriptions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruiter.calendly_webhook_subcriptions". All fields are combined with a logical 'AND'.
 */
export interface recruiter_calendly_webhook_subcriptions_bool_exp {
  _and?: recruiter_calendly_webhook_subcriptions_bool_exp[] | null;
  _not?: recruiter_calendly_webhook_subcriptions_bool_exp | null;
  _or?: recruiter_calendly_webhook_subcriptions_bool_exp[] | null;
  active?: Boolean_comparison_exp | null;
  callbackUrl?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creator?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruiter.calendly_webhook_subcriptions"
 */
export interface recruiter_calendly_webhook_subcriptions_insert_input {
  active?: boolean | null;
  callbackUrl?: string | null;
  createdAt?: hasura_timestamptz | null;
  creator?: string | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  sid?: string | null;
  syncEntityId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: string | null;
}

/**
 * on_conflict condition type for table "recruiter.calendly_webhook_subcriptions"
 */
export interface recruiter_calendly_webhook_subcriptions_on_conflict {
  constraint: recruiter_calendly_webhook_subcriptions_constraint;
  update_columns: recruiter_calendly_webhook_subcriptions_update_column[];
  where?: recruiter_calendly_webhook_subcriptions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "repository_owners"
 */
export interface repository_owners_arr_rel_insert_input {
  data: repository_owners_insert_input[];
  on_conflict?: repository_owners_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "repository_owners". All fields are combined with a logical 'AND'.
 */
export interface repository_owners_bool_exp {
  _and?: repository_owners_bool_exp[] | null;
  _not?: repository_owners_bool_exp | null;
  _or?: repository_owners_bool_exp[] | null;
  endAt?: timestamptz_comparison_exp | null;
  gitSliceRepoId?: Int_comparison_exp | null;
  git_repo_slice?: git_repo_slices_bool_exp | null;
  id?: Int_comparison_exp | null;
  isShadow?: Boolean_comparison_exp | null;
  repoOwnerUserId?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "repository_owners"
 */
export interface repository_owners_insert_input {
  endAt?: hasura_timestamptz | null;
  gitSliceRepoId?: number | null;
  git_repo_slice?: git_repo_slices_obj_rel_insert_input | null;
  id?: number | null;
  isShadow?: boolean | null;
  repoOwnerUserId?: number | null;
  startAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "repository_owners"
 */
export interface repository_owners_on_conflict {
  constraint: repository_owners_constraint;
  update_columns: repository_owners_update_column[];
  where?: repository_owners_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "review_question_themes". All fields are combined with a logical 'AND'.
 */
export interface review_question_themes_bool_exp {
  _and?: review_question_themes_bool_exp[] | null;
  _not?: review_question_themes_bool_exp | null;
  _or?: review_question_themes_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  review_questions?: review_questions_bool_exp | null;
}

/**
 * input type for inserting data into table "review_question_themes"
 */
export interface review_question_themes_insert_input {
  description?: string | null;
  id?: string | null;
  review_questions?: review_questions_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "review_question_themes"
 */
export interface review_question_themes_obj_rel_insert_input {
  data: review_question_themes_insert_input;
  on_conflict?: review_question_themes_on_conflict | null;
}

/**
 * on_conflict condition type for table "review_question_themes"
 */
export interface review_question_themes_on_conflict {
  constraint: review_question_themes_constraint;
  update_columns: review_question_themes_update_column[];
  where?: review_question_themes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "review_questions"
 */
export interface review_questions_arr_rel_insert_input {
  data: review_questions_insert_input[];
  on_conflict?: review_questions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "review_questions". All fields are combined with a logical 'AND'.
 */
export interface review_questions_bool_exp {
  _and?: review_questions_bool_exp[] | null;
  _not?: review_questions_bool_exp | null;
  _or?: review_questions_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isAdhoc?: Boolean_comparison_exp | null;
  parentQuestionId?: Int_comparison_exp | null;
  question?: String_comparison_exp | null;
  responseType?: String_comparison_exp | null;
  review_question?: review_questions_bool_exp | null;
  review_question_theme?: review_question_themes_bool_exp | null;
  review_questions?: review_questions_bool_exp | null;
  review_response_type?: review_response_types_bool_exp | null;
  review_responses?: review_responses_bool_exp | null;
  review_type_questions?: review_type_questions_bool_exp | null;
  theme?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "review_questions"
 */
export interface review_questions_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  isAdhoc?: boolean | null;
  parentQuestionId?: number | null;
  question?: string | null;
  responseType?: string | null;
  review_question?: review_questions_obj_rel_insert_input | null;
  review_question_theme?: review_question_themes_obj_rel_insert_input | null;
  review_questions?: review_questions_arr_rel_insert_input | null;
  review_response_type?: review_response_types_obj_rel_insert_input | null;
  review_responses?: review_responses_arr_rel_insert_input | null;
  review_type_questions?: review_type_questions_arr_rel_insert_input | null;
  theme?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "review_questions"
 */
export interface review_questions_obj_rel_insert_input {
  data: review_questions_insert_input;
  on_conflict?: review_questions_on_conflict | null;
}

/**
 * on_conflict condition type for table "review_questions"
 */
export interface review_questions_on_conflict {
  constraint: review_questions_constraint;
  update_columns: review_questions_update_column[];
  where?: review_questions_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "review_response_types". All fields are combined with a logical 'AND'.
 */
export interface review_response_types_bool_exp {
  _and?: review_response_types_bool_exp[] | null;
  _not?: review_response_types_bool_exp | null;
  _or?: review_response_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  review_questions?: review_questions_bool_exp | null;
}

/**
 * input type for inserting data into table "review_response_types"
 */
export interface review_response_types_insert_input {
  description?: string | null;
  id?: string | null;
  review_questions?: review_questions_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "review_response_types"
 */
export interface review_response_types_obj_rel_insert_input {
  data: review_response_types_insert_input;
  on_conflict?: review_response_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "review_response_types"
 */
export interface review_response_types_on_conflict {
  constraint: review_response_types_constraint;
  update_columns: review_response_types_update_column[];
  where?: review_response_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "review_responses"
 */
export interface review_responses_arr_rel_insert_input {
  data: review_responses_insert_input[];
  on_conflict?: review_responses_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "review_responses". All fields are combined with a logical 'AND'.
 */
export interface review_responses_bool_exp {
  _and?: review_responses_bool_exp[] | null;
  _not?: review_responses_bool_exp | null;
  _or?: review_responses_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  questionId?: Int_comparison_exp | null;
  ratingResponse?: Int_comparison_exp | null;
  review?: reviews_bool_exp | null;
  reviewId?: Int_comparison_exp | null;
  review_question?: review_questions_bool_exp | null;
  textResponse?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "review_responses"
 */
export interface review_responses_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  questionId?: number | null;
  ratingResponse?: number | null;
  review?: reviews_obj_rel_insert_input | null;
  reviewId?: number | null;
  review_question?: review_questions_obj_rel_insert_input | null;
  textResponse?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "review_responses"
 */
export interface review_responses_on_conflict {
  constraint: review_responses_constraint;
  update_columns: review_responses_update_column[];
  where?: review_responses_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "review_type_questions"
 */
export interface review_type_questions_arr_rel_insert_input {
  data: review_type_questions_insert_input[];
  on_conflict?: review_type_questions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "review_type_questions". All fields are combined with a logical 'AND'.
 */
export interface review_type_questions_bool_exp {
  _and?: review_type_questions_bool_exp[] | null;
  _not?: review_type_questions_bool_exp | null;
  _or?: review_type_questions_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isOptional?: Boolean_comparison_exp | null;
  questionId?: Int_comparison_exp | null;
  reviewType?: String_comparison_exp | null;
  review_question?: review_questions_bool_exp | null;
  review_type?: review_types_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "review_type_questions"
 */
export interface review_type_questions_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  isOptional?: boolean | null;
  questionId?: number | null;
  reviewType?: string | null;
  review_question?: review_questions_obj_rel_insert_input | null;
  review_type?: review_types_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "review_type_questions"
 */
export interface review_type_questions_on_conflict {
  constraint: review_type_questions_constraint;
  update_columns: review_type_questions_update_column[];
  where?: review_type_questions_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "review_types". All fields are combined with a logical 'AND'.
 */
export interface review_types_bool_exp {
  _and?: review_types_bool_exp[] | null;
  _not?: review_types_bool_exp | null;
  _or?: review_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  review_type_questions?: review_type_questions_bool_exp | null;
  reviews?: reviews_bool_exp | null;
}

/**
 * input type for inserting data into table "review_types"
 */
export interface review_types_insert_input {
  description?: string | null;
  id?: string | null;
  review_type_questions?: review_type_questions_arr_rel_insert_input | null;
  reviews?: reviews_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "review_types"
 */
export interface review_types_obj_rel_insert_input {
  data: review_types_insert_input;
  on_conflict?: review_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "review_types"
 */
export interface review_types_on_conflict {
  constraint: review_types_constraint;
  update_columns: review_types_update_column[];
  where?: review_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "reviews"
 */
export interface reviews_arr_rel_insert_input {
  data: reviews_insert_input[];
  on_conflict?: reviews_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "reviews". All fields are combined with a logical 'AND'.
 */
export interface reviews_bool_exp {
  _and?: reviews_bool_exp[] | null;
  _not?: reviews_bool_exp | null;
  _or?: reviews_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  reviewTypeId?: String_comparison_exp | null;
  review_responses?: review_responses_bool_exp | null;
  review_type?: review_types_bool_exp | null;
  revieweeLogin?: String_comparison_exp | null;
  reviewerLogin?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  urgency?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userByRevieweelogin?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "reviews"
 */
export interface reviews_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  completedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  reviewTypeId?: string | null;
  review_responses?: review_responses_arr_rel_insert_input | null;
  review_type?: review_types_obj_rel_insert_input | null;
  revieweeLogin?: string | null;
  reviewerLogin?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  urgency?: number | null;
  user?: users_obj_rel_insert_input | null;
  userByRevieweelogin?: users_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "reviews"
 */
export interface reviews_obj_rel_insert_input {
  data: reviews_insert_input;
  on_conflict?: reviews_on_conflict | null;
}

/**
 * on_conflict condition type for table "reviews"
 */
export interface reviews_on_conflict {
  constraint: reviews_constraint;
  update_columns: reviews_update_column[];
  where?: reviews_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_companies"
 */
export interface rippling_companies_arr_rel_insert_input {
  data: rippling_companies_insert_input[];
  on_conflict?: rippling_companies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_companies". All fields are combined with a logical 'AND'.
 */
export interface rippling_companies_bool_exp {
  _and?: rippling_companies_bool_exp[] | null;
  _not?: rippling_companies_bool_exp | null;
  _or?: rippling_companies_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  address?: jsonb_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  ein?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  payroll_settings?: jsonb_comparison_exp | null;
  phone?: String_comparison_exp | null;
  primaryEmail?: String_comparison_exp | null;
  ripplingCompanyTokenBySid?: rippling_company_tokens_bool_exp | null;
  rippling_company_token?: rippling_company_tokens_bool_exp | null;
  rippling_company_tokens?: rippling_company_tokens_bool_exp | null;
  rippling_employees?: rippling_employees_bool_exp | null;
  rippling_payroll_earning_types?: rippling_payroll_earning_types_bool_exp | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_bool_exp | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_bool_exp | null;
  rippling_payroll_runs?: rippling_payroll_runs_bool_exp | null;
  rippling_payroll_schedules?: rippling_payroll_schedules_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  tax_info?: jsonb_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
  workLocations?: jsonb_comparison_exp | null;
}

/**
 * input type for inserting data into table "rippling_companies"
 */
export interface rippling_companies_insert_input {
  accessToken?: string | null;
  address?: hasura_jsonb | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  ein?: string | null;
  id?: number | null;
  name?: string | null;
  payroll_settings?: hasura_jsonb | null;
  phone?: string | null;
  primaryEmail?: string | null;
  ripplingCompanyTokenBySid?: rippling_company_tokens_obj_rel_insert_input | null;
  rippling_company_token?: rippling_company_tokens_obj_rel_insert_input | null;
  rippling_company_tokens?: rippling_company_tokens_arr_rel_insert_input | null;
  rippling_employees?: rippling_employees_arr_rel_insert_input | null;
  rippling_payroll_earning_types?: rippling_payroll_earning_types_arr_rel_insert_input | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_arr_rel_insert_input | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_arr_rel_insert_input | null;
  rippling_payroll_runs?: rippling_payroll_runs_arr_rel_insert_input | null;
  rippling_payroll_schedules?: rippling_payroll_schedules_arr_rel_insert_input | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  tax_info?: hasura_jsonb | null;
  updatedAt?: hasura_timestamptz | null;
  user_email?: user_emails_obj_rel_insert_input | null;
  workLocations?: hasura_jsonb | null;
}

/**
 * input type for inserting object relation for remote table "rippling_companies"
 */
export interface rippling_companies_obj_rel_insert_input {
  data: rippling_companies_insert_input;
  on_conflict?: rippling_companies_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_companies"
 */
export interface rippling_companies_on_conflict {
  constraint: rippling_companies_constraint;
  update_columns: rippling_companies_update_column[];
  where?: rippling_companies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_company_tokens"
 */
export interface rippling_company_tokens_arr_rel_insert_input {
  data: rippling_company_tokens_insert_input[];
  on_conflict?: rippling_company_tokens_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_company_tokens". All fields are combined with a logical 'AND'.
 */
export interface rippling_company_tokens_bool_exp {
  _and?: rippling_company_tokens_bool_exp[] | null;
  _not?: rippling_company_tokens_bool_exp | null;
  _or?: rippling_company_tokens_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  companySid?: String_comparison_exp | null;
  ripplingCompanyByAccesstoken?: rippling_companies_bool_exp | null;
  rippling_companies?: rippling_companies_bool_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
}

/**
 * input type for inserting data into table "rippling_company_tokens"
 */
export interface rippling_company_tokens_insert_input {
  accessToken?: string | null;
  companySid?: string | null;
  ripplingCompanyByAccesstoken?: rippling_companies_obj_rel_insert_input | null;
  rippling_companies?: rippling_companies_arr_rel_insert_input | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "rippling_company_tokens"
 */
export interface rippling_company_tokens_obj_rel_insert_input {
  data: rippling_company_tokens_insert_input;
  on_conflict?: rippling_company_tokens_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_company_tokens"
 */
export interface rippling_company_tokens_on_conflict {
  constraint: rippling_company_tokens_constraint;
  update_columns: rippling_company_tokens_update_column[];
  where?: rippling_company_tokens_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_employees"
 */
export interface rippling_employees_arr_rel_insert_input {
  data: rippling_employees_insert_input[];
  on_conflict?: rippling_employees_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_employees". All fields are combined with a logical 'AND'.
 */
export interface rippling_employees_bool_exp {
  _and?: rippling_employees_bool_exp[] | null;
  _not?: rippling_employees_bool_exp | null;
  _or?: rippling_employees_bool_exp[] | null;
  address?: jsonb_comparison_exp | null;
  companySid?: String_comparison_exp | null;
  compensationAnnualSalary?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  customFields?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  department?: String_comparison_exp | null;
  dob?: String_comparison_exp | null;
  employmentType?: String_comparison_exp | null;
  endDate?: String_comparison_exp | null;
  filingStatus?: String_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  flsaStatus?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  managerSid?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  personalEmail?: String_comparison_exp | null;
  phone?: String_comparison_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  rippling_employee?: rippling_employees_bool_exp | null;
  rippling_employees?: rippling_employees_bool_exp | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_bool_exp | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_bool_exp | null;
  roleState?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  ssn?: String_comparison_exp | null;
  startDate?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userEmailByWorkemail?: user_emails_bool_exp | null;
  user_changes?: user_changes_bool_exp | null;
  user_email?: user_emails_bool_exp | null;
  users?: users_bool_exp | null;
  workEmail?: String_comparison_exp | null;
  workLocation?: jsonb_comparison_exp | null;
}

/**
 * input type for inserting data into table "rippling_employees"
 */
export interface rippling_employees_insert_input {
  address?: hasura_jsonb | null;
  companySid?: string | null;
  compensationAnnualSalary?: string | null;
  createdAt?: hasura_timestamptz | null;
  customFields?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  department?: string | null;
  dob?: string | null;
  employmentType?: string | null;
  endDate?: string | null;
  filingStatus?: string | null;
  firstName?: string | null;
  flsaStatus?: string | null;
  id?: number | null;
  lastName?: string | null;
  managerSid?: string | null;
  name?: string | null;
  personalEmail?: string | null;
  phone?: string | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  rippling_employee?: rippling_employees_obj_rel_insert_input | null;
  rippling_employees?: rippling_employees_arr_rel_insert_input | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_arr_rel_insert_input | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_arr_rel_insert_input | null;
  roleState?: string | null;
  sid?: string | null;
  ssn?: string | null;
  startDate?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userEmailByWorkemail?: user_emails_obj_rel_insert_input | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
  user_email?: user_emails_obj_rel_insert_input | null;
  users?: users_arr_rel_insert_input | null;
  workEmail?: string | null;
  workLocation?: hasura_jsonb | null;
}

/**
 * input type for inserting object relation for remote table "rippling_employees"
 */
export interface rippling_employees_obj_rel_insert_input {
  data: rippling_employees_insert_input;
  on_conflict?: rippling_employees_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_employees"
 */
export interface rippling_employees_on_conflict {
  constraint: rippling_employees_constraint;
  update_columns: rippling_employees_update_column[];
  where?: rippling_employees_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_payroll_earning_types"
 */
export interface rippling_payroll_earning_types_arr_rel_insert_input {
  data: rippling_payroll_earning_types_insert_input[];
  on_conflict?: rippling_payroll_earning_types_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_payroll_earning_types". All fields are combined with a logical 'AND'.
 */
export interface rippling_payroll_earning_types_bool_exp {
  _and?: rippling_payroll_earning_types_bool_exp[] | null;
  _not?: rippling_payroll_earning_types_bool_exp | null;
  _or?: rippling_payroll_earning_types_bool_exp[] | null;
  companySid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  earningType?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "rippling_payroll_earning_types"
 */
export interface rippling_payroll_earning_types_insert_input {
  companySid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  earningType?: string | null;
  id?: number | null;
  name?: string | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "rippling_payroll_earning_types"
 */
export interface rippling_payroll_earning_types_obj_rel_insert_input {
  data: rippling_payroll_earning_types_insert_input;
  on_conflict?: rippling_payroll_earning_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_payroll_earning_types"
 */
export interface rippling_payroll_earning_types_on_conflict {
  constraint: rippling_payroll_earning_types_constraint;
  update_columns: rippling_payroll_earning_types_update_column[];
  where?: rippling_payroll_earning_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_payroll_run_details"
 */
export interface rippling_payroll_run_details_arr_rel_insert_input {
  data: rippling_payroll_run_details_insert_input[];
  on_conflict?: rippling_payroll_run_details_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_payroll_run_details". All fields are combined with a logical 'AND'.
 */
export interface rippling_payroll_run_details_bool_exp {
  _and?: rippling_payroll_run_details_bool_exp[] | null;
  _not?: rippling_payroll_run_details_bool_exp | null;
  _or?: rippling_payroll_run_details_bool_exp[] | null;
  companySid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deductions?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  employeeSid?: String_comparison_exp | null;
  garnishments?: jsonb_comparison_exp | null;
  grossPay?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  netPay?: String_comparison_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  rippling_employee?: rippling_employees_bool_exp | null;
  rippling_payroll_run?: rippling_payroll_runs_bool_exp | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_bool_exp | null;
  runSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  taxes?: jsonb_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_payment?: user_payments_bool_exp | null;
  user_payments?: user_payments_bool_exp | null;
}

/**
 * input type for inserting data into table "rippling_payroll_run_details"
 */
export interface rippling_payroll_run_details_insert_input {
  companySid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deductions?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  employeeSid?: string | null;
  garnishments?: hasura_jsonb | null;
  grossPay?: string | null;
  id?: number | null;
  netPay?: string | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  rippling_employee?: rippling_employees_obj_rel_insert_input | null;
  rippling_payroll_run?: rippling_payroll_runs_obj_rel_insert_input | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_arr_rel_insert_input | null;
  runSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  taxes?: hasura_jsonb | null;
  updatedAt?: hasura_timestamptz | null;
  user_payment?: user_payments_obj_rel_insert_input | null;
  user_payments?: user_payments_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "rippling_payroll_run_details"
 */
export interface rippling_payroll_run_details_obj_rel_insert_input {
  data: rippling_payroll_run_details_insert_input;
  on_conflict?: rippling_payroll_run_details_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_payroll_run_details"
 */
export interface rippling_payroll_run_details_on_conflict {
  constraint: rippling_payroll_run_details_constraint;
  update_columns: rippling_payroll_run_details_update_column[];
  where?: rippling_payroll_run_details_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_payroll_run_earnings"
 */
export interface rippling_payroll_run_earnings_arr_rel_insert_input {
  data: rippling_payroll_run_earnings_insert_input[];
  on_conflict?: rippling_payroll_run_earnings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_payroll_run_earnings". All fields are combined with a logical 'AND'.
 */
export interface rippling_payroll_run_earnings_bool_exp {
  _and?: rippling_payroll_run_earnings_bool_exp[] | null;
  _not?: rippling_payroll_run_earnings_bool_exp | null;
  _or?: rippling_payroll_run_earnings_bool_exp[] | null;
  amount?: String_comparison_exp | null;
  companySid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  employeeSid?: String_comparison_exp | null;
  hours?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  payrollRunDetailsSid?: String_comparison_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  rippling_employee?: rippling_employees_bool_exp | null;
  rippling_payroll_run_detail?: rippling_payroll_run_details_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "rippling_payroll_run_earnings"
 */
export interface rippling_payroll_run_earnings_insert_input {
  amount?: string | null;
  companySid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  employeeSid?: string | null;
  hours?: string | null;
  id?: number | null;
  name?: string | null;
  payrollRunDetailsSid?: string | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  rippling_employee?: rippling_employees_obj_rel_insert_input | null;
  rippling_payroll_run_detail?: rippling_payroll_run_details_obj_rel_insert_input | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "rippling_payroll_run_earnings"
 */
export interface rippling_payroll_run_earnings_obj_rel_insert_input {
  data: rippling_payroll_run_earnings_insert_input;
  on_conflict?: rippling_payroll_run_earnings_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_payroll_run_earnings"
 */
export interface rippling_payroll_run_earnings_on_conflict {
  constraint: rippling_payroll_run_earnings_constraint;
  update_columns: rippling_payroll_run_earnings_update_column[];
  where?: rippling_payroll_run_earnings_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_payroll_run_states". All fields are combined with a logical 'AND'.
 */
export interface rippling_payroll_run_states_bool_exp {
  _and?: rippling_payroll_run_states_bool_exp[] | null;
  _not?: rippling_payroll_run_states_bool_exp | null;
  _or?: rippling_payroll_run_states_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  rippling_payroll_runs?: rippling_payroll_runs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "rippling_payroll_run_states_enum". All fields are combined with logical 'AND'.
 */
export interface rippling_payroll_run_states_enum_comparison_exp {
  _eq?: rippling_payroll_run_states_enum | null;
  _in?: rippling_payroll_run_states_enum[] | null;
  _is_null?: boolean | null;
  _neq?: rippling_payroll_run_states_enum | null;
  _nin?: rippling_payroll_run_states_enum[] | null;
}

/**
 * input type for inserting data into table "rippling_payroll_run_states"
 */
export interface rippling_payroll_run_states_insert_input {
  description?: string | null;
  id?: string | null;
  rippling_payroll_runs?: rippling_payroll_runs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "rippling_payroll_run_states"
 */
export interface rippling_payroll_run_states_obj_rel_insert_input {
  data: rippling_payroll_run_states_insert_input;
  on_conflict?: rippling_payroll_run_states_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_payroll_run_states"
 */
export interface rippling_payroll_run_states_on_conflict {
  constraint: rippling_payroll_run_states_constraint;
  update_columns: rippling_payroll_run_states_update_column[];
  where?: rippling_payroll_run_states_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_payroll_runs"
 */
export interface rippling_payroll_runs_arr_rel_insert_input {
  data: rippling_payroll_runs_insert_input[];
  on_conflict?: rippling_payroll_runs_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_payroll_runs". All fields are combined with a logical 'AND'.
 */
export interface rippling_payroll_runs_bool_exp {
  _and?: rippling_payroll_runs_bool_exp[] | null;
  _not?: rippling_payroll_runs_bool_exp | null;
  _or?: rippling_payroll_runs_bool_exp[] | null;
  approvalDeadline?: timestamptz_comparison_exp | null;
  checkDate?: timestamptz_comparison_exp | null;
  companySid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  payPeriodEndDate?: timestamptz_comparison_exp | null;
  payPeriodStartDate?: timestamptz_comparison_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_bool_exp | null;
  rippling_payroll_run_state?: rippling_payroll_run_states_bool_exp | null;
  rippling_payroll_schedule?: rippling_payroll_schedules_bool_exp | null;
  runStateId?: rippling_payroll_run_states_enum_comparison_exp | null;
  runType?: String_comparison_exp | null;
  scheduleSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "rippling_payroll_runs"
 */
export interface rippling_payroll_runs_insert_input {
  approvalDeadline?: hasura_timestamptz | null;
  checkDate?: hasura_timestamptz | null;
  companySid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  payPeriodEndDate?: hasura_timestamptz | null;
  payPeriodStartDate?: hasura_timestamptz | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_arr_rel_insert_input | null;
  rippling_payroll_run_state?: rippling_payroll_run_states_obj_rel_insert_input | null;
  rippling_payroll_schedule?: rippling_payroll_schedules_obj_rel_insert_input | null;
  runStateId?: rippling_payroll_run_states_enum | null;
  runType?: string | null;
  scheduleSid?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "rippling_payroll_runs"
 */
export interface rippling_payroll_runs_obj_rel_insert_input {
  data: rippling_payroll_runs_insert_input;
  on_conflict?: rippling_payroll_runs_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_payroll_runs"
 */
export interface rippling_payroll_runs_on_conflict {
  constraint: rippling_payroll_runs_constraint;
  update_columns: rippling_payroll_runs_update_column[];
  where?: rippling_payroll_runs_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "rippling_payroll_schedules"
 */
export interface rippling_payroll_schedules_arr_rel_insert_input {
  data: rippling_payroll_schedules_insert_input[];
  on_conflict?: rippling_payroll_schedules_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "rippling_payroll_schedules". All fields are combined with a logical 'AND'.
 */
export interface rippling_payroll_schedules_bool_exp {
  _and?: rippling_payroll_schedules_bool_exp[] | null;
  _not?: rippling_payroll_schedules_bool_exp | null;
  _or?: rippling_payroll_schedules_bool_exp[] | null;
  companySid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  payFrequency?: String_comparison_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  rippling_payroll_runs?: rippling_payroll_runs_bool_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "rippling_payroll_schedules"
 */
export interface rippling_payroll_schedules_insert_input {
  companySid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  payFrequency?: string | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  rippling_payroll_runs?: rippling_payroll_runs_arr_rel_insert_input | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "rippling_payroll_schedules"
 */
export interface rippling_payroll_schedules_obj_rel_insert_input {
  data: rippling_payroll_schedules_insert_input;
  on_conflict?: rippling_payroll_schedules_on_conflict | null;
}

/**
 * on_conflict condition type for table "rippling_payroll_schedules"
 */
export interface rippling_payroll_schedules_on_conflict {
  constraint: rippling_payroll_schedules_constraint;
  update_columns: rippling_payroll_schedules_update_column[];
  where?: rippling_payroll_schedules_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "skill_relationships"
 */
export interface skill_relationships_arr_rel_insert_input {
  data: skill_relationships_insert_input[];
  on_conflict?: skill_relationships_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "skill_relationships". All fields are combined with a logical 'AND'.
 */
export interface skill_relationships_bool_exp {
  _and?: skill_relationships_bool_exp[] | null;
  _not?: skill_relationships_bool_exp | null;
  _or?: skill_relationships_bool_exp[] | null;
  childSkill?: String_comparison_exp | null;
  parentSkill?: String_comparison_exp | null;
  skill?: skills_bool_exp | null;
  skillByChildskill?: skills_bool_exp | null;
}

/**
 * input type for inserting data into table "skill_relationships"
 */
export interface skill_relationships_insert_input {
  childSkill?: string | null;
  parentSkill?: string | null;
  skill?: skills_obj_rel_insert_input | null;
  skillByChildskill?: skills_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "skill_relationships"
 */
export interface skill_relationships_on_conflict {
  constraint: skill_relationships_constraint;
  update_columns: skill_relationships_update_column[];
  where?: skill_relationships_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "skills"
 */
export interface skills_arr_rel_insert_input {
  data: skills_insert_input[];
  on_conflict?: skills_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "skills". All fields are combined with a logical 'AND'.
 */
export interface skills_bool_exp {
  _and?: skills_bool_exp[] | null;
  _not?: skills_bool_exp | null;
  _or?: skills_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  opportunity_skill_requirements?: opportunity_skill_requirements_bool_exp | null;
  skillRelationshipsByParentskill?: skill_relationships_bool_exp | null;
  skill_relationships?: skill_relationships_bool_exp | null;
  technology?: technologies_bool_exp | null;
  technologyId?: Int_comparison_exp | null;
  type?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_skills?: user_skills_bool_exp | null;
}

/**
 * input type for inserting data into table "skills"
 */
export interface skills_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  opportunity_skill_requirements?: opportunity_skill_requirements_arr_rel_insert_input | null;
  skillRelationshipsByParentskill?: skill_relationships_arr_rel_insert_input | null;
  skill_relationships?: skill_relationships_arr_rel_insert_input | null;
  technology?: technologies_obj_rel_insert_input | null;
  technologyId?: number | null;
  type?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_skills?: user_skills_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "skills"
 */
export interface skills_obj_rel_insert_input {
  data: skills_insert_input;
  on_conflict?: skills_on_conflict | null;
}

/**
 * on_conflict condition type for table "skills"
 */
export interface skills_on_conflict {
  constraint: skills_constraint;
  update_columns: skills_update_column[];
  where?: skills_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_app_channels"
 */
export interface slack_app_channels_arr_rel_insert_input {
  data: slack_app_channels_insert_input[];
  on_conflict?: slack_app_channels_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_app_channels". All fields are combined with a logical 'AND'.
 */
export interface slack_app_channels_bool_exp {
  _and?: slack_app_channels_bool_exp[] | null;
  _not?: slack_app_channels_bool_exp | null;
  _or?: slack_app_channels_bool_exp[] | null;
  appSid?: String_comparison_exp | null;
  channelSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  slack_app?: slack_apps_bool_exp | null;
  slack_channel?: slack_channels_bool_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "slack_app_channels"
 */
export interface slack_app_channels_insert_input {
  appSid?: string | null;
  channelSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  sid?: string | null;
  slack_app?: slack_apps_obj_rel_insert_input | null;
  slack_channel?: slack_channels_obj_rel_insert_input | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "slack_app_channels"
 */
export interface slack_app_channels_on_conflict {
  constraint: slack_app_channels_constraint;
  update_columns: slack_app_channels_update_column[];
  where?: slack_app_channels_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_app_tokens"
 */
export interface slack_app_tokens_arr_rel_insert_input {
  data: slack_app_tokens_insert_input[];
  on_conflict?: slack_app_tokens_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_app_tokens". All fields are combined with a logical 'AND'.
 */
export interface slack_app_tokens_bool_exp {
  _and?: slack_app_tokens_bool_exp[] | null;
  _not?: slack_app_tokens_bool_exp | null;
  _or?: slack_app_tokens_bool_exp[] | null;
  appSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  expiresAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  slack_app?: slack_apps_bool_exp | null;
  slack_team?: slack_teams_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  token?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "slack_app_tokens"
 */
export interface slack_app_tokens_insert_input {
  appSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  expiresAt?: hasura_timestamptz | null;
  id?: number | null;
  slack_app?: slack_apps_obj_rel_insert_input | null;
  slack_team?: slack_teams_obj_rel_insert_input | null;
  teamSid?: string | null;
  token?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "slack_app_tokens"
 */
export interface slack_app_tokens_on_conflict {
  constraint: slack_app_tokens_constraint;
  update_columns: slack_app_tokens_update_column[];
  where?: slack_app_tokens_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_apps"
 */
export interface slack_apps_arr_rel_insert_input {
  data: slack_apps_insert_input[];
  on_conflict?: slack_apps_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_apps". All fields are combined with a logical 'AND'.
 */
export interface slack_apps_bool_exp {
  _and?: slack_apps_bool_exp[] | null;
  _not?: slack_apps_bool_exp | null;
  _or?: slack_apps_bool_exp[] | null;
  appId?: String_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  clientSecret?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dateOfCreation?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  signingSecret?: String_comparison_exp | null;
  slack_app_channels?: slack_app_channels_bool_exp | null;
  slack_app_tokens?: slack_app_tokens_bool_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  verificationToken?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "slack_apps"
 */
export interface slack_apps_insert_input {
  appId?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt?: hasura_timestamptz | null;
  dateOfCreation?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  signingSecret?: string | null;
  slack_app_channels?: slack_app_channels_arr_rel_insert_input | null;
  slack_app_tokens?: slack_app_tokens_arr_rel_insert_input | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  verificationToken?: string | null;
}

/**
 * input type for inserting object relation for remote table "slack_apps"
 */
export interface slack_apps_obj_rel_insert_input {
  data: slack_apps_insert_input;
  on_conflict?: slack_apps_on_conflict | null;
}

/**
 * on_conflict condition type for table "slack_apps"
 */
export interface slack_apps_on_conflict {
  constraint: slack_apps_constraint;
  update_columns: slack_apps_update_column[];
  where?: slack_apps_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_channels"
 */
export interface slack_channels_arr_rel_insert_input {
  data: slack_channels_insert_input[];
  on_conflict?: slack_channels_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_channels". All fields are combined with a logical 'AND'.
 */
export interface slack_channels_bool_exp {
  _and?: slack_channels_bool_exp[] | null;
  _not?: slack_channels_bool_exp | null;
  _or?: slack_channels_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  id?: Int_comparison_exp | null;
  isChannel?: Boolean_comparison_exp | null;
  isGeneral?: Boolean_comparison_exp | null;
  isOrgShared?: Boolean_comparison_exp | null;
  isPrivate?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  nameNormalized?: String_comparison_exp | null;
  numMembers?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  slack_app_channels?: slack_app_channels_bool_exp | null;
  slack_standup_channel_mapping?: slack_standup_channel_mappings_bool_exp | null;
  slack_standup_channel_mappings?: slack_standup_channel_mappings_bool_exp | null;
  slack_team?: slack_teams_bool_exp | null;
  slack_user_team_channel_mappings?: slack_user_team_channel_mappings_bool_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "slack_channels"
 */
export interface slack_channels_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  id?: number | null;
  isChannel?: boolean | null;
  isGeneral?: boolean | null;
  isOrgShared?: boolean | null;
  isPrivate?: boolean | null;
  name?: string | null;
  nameNormalized?: string | null;
  numMembers?: number | null;
  sid?: string | null;
  slack_app_channels?: slack_app_channels_arr_rel_insert_input | null;
  slack_standup_channel_mapping?: slack_standup_channel_mappings_obj_rel_insert_input | null;
  slack_standup_channel_mappings?: slack_standup_channel_mappings_arr_rel_insert_input | null;
  slack_team?: slack_teams_obj_rel_insert_input | null;
  slack_user_team_channel_mappings?: slack_user_team_channel_mappings_arr_rel_insert_input | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "slack_channels"
 */
export interface slack_channels_obj_rel_insert_input {
  data: slack_channels_insert_input;
  on_conflict?: slack_channels_on_conflict | null;
}

/**
 * on_conflict condition type for table "slack_channels"
 */
export interface slack_channels_on_conflict {
  constraint: slack_channels_constraint;
  update_columns: slack_channels_update_column[];
  where?: slack_channels_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_standup_channel_mappings"
 */
export interface slack_standup_channel_mappings_arr_rel_insert_input {
  data: slack_standup_channel_mappings_insert_input[];
  on_conflict?: slack_standup_channel_mappings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_standup_channel_mappings". All fields are combined with a logical 'AND'.
 */
export interface slack_standup_channel_mappings_bool_exp {
  _and?: slack_standup_channel_mappings_bool_exp[] | null;
  _not?: slack_standup_channel_mappings_bool_exp | null;
  _or?: slack_standup_channel_mappings_bool_exp[] | null;
  channelSid?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  projectId?: Int_comparison_exp | null;
  slack_channel?: slack_channels_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "slack_standup_channel_mappings"
 */
export interface slack_standup_channel_mappings_insert_input {
  channelSid?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  projectId?: number | null;
  slack_channel?: slack_channels_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "slack_standup_channel_mappings"
 */
export interface slack_standup_channel_mappings_obj_rel_insert_input {
  data: slack_standup_channel_mappings_insert_input;
  on_conflict?: slack_standup_channel_mappings_on_conflict | null;
}

/**
 * on_conflict condition type for table "slack_standup_channel_mappings"
 */
export interface slack_standup_channel_mappings_on_conflict {
  constraint: slack_standup_channel_mappings_constraint;
  update_columns: slack_standup_channel_mappings_update_column[];
  where?: slack_standup_channel_mappings_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_teams"
 */
export interface slack_teams_arr_rel_insert_input {
  data: slack_teams_insert_input[];
  on_conflict?: slack_teams_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_teams". All fields are combined with a logical 'AND'.
 */
export interface slack_teams_bool_exp {
  _and?: slack_teams_bool_exp[] | null;
  _not?: slack_teams_bool_exp | null;
  _or?: slack_teams_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  domain?: String_comparison_exp | null;
  emailDomain?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  slack_app_tokens?: slack_app_tokens_bool_exp | null;
  slack_channels?: slack_channels_bool_exp | null;
  slack_users?: slack_users_bool_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "slack_teams"
 */
export interface slack_teams_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  domain?: string | null;
  emailDomain?: string | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  slack_app_tokens?: slack_app_tokens_arr_rel_insert_input | null;
  slack_channels?: slack_channels_arr_rel_insert_input | null;
  slack_users?: slack_users_arr_rel_insert_input | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "slack_teams"
 */
export interface slack_teams_obj_rel_insert_input {
  data: slack_teams_insert_input;
  on_conflict?: slack_teams_on_conflict | null;
}

/**
 * on_conflict condition type for table "slack_teams"
 */
export interface slack_teams_on_conflict {
  constraint: slack_teams_constraint;
  update_columns: slack_teams_update_column[];
  where?: slack_teams_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_user_team_channel_mappings"
 */
export interface slack_user_team_channel_mappings_arr_rel_insert_input {
  data: slack_user_team_channel_mappings_insert_input[];
  on_conflict?: slack_user_team_channel_mappings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_user_team_channel_mappings". All fields are combined with a logical 'AND'.
 */
export interface slack_user_team_channel_mappings_bool_exp {
  _and?: slack_user_team_channel_mappings_bool_exp[] | null;
  _not?: slack_user_team_channel_mappings_bool_exp | null;
  _or?: slack_user_team_channel_mappings_bool_exp[] | null;
  channelSid?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  slack_channel?: slack_channels_bool_exp | null;
  teamId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting data into table "slack_user_team_channel_mappings"
 */
export interface slack_user_team_channel_mappings_insert_input {
  channelSid?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  slack_channel?: slack_channels_obj_rel_insert_input | null;
  teamId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user_team?: user_teams_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "slack_user_team_channel_mappings"
 */
export interface slack_user_team_channel_mappings_on_conflict {
  constraint: slack_user_team_channel_mappings_constraint;
  update_columns: slack_user_team_channel_mappings_update_column[];
  where?: slack_user_team_channel_mappings_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "slack_users"
 */
export interface slack_users_arr_rel_insert_input {
  data: slack_users_insert_input[];
  on_conflict?: slack_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "slack_users". All fields are combined with a logical 'AND'.
 */
export interface slack_users_bool_exp {
  _and?: slack_users_bool_exp[] | null;
  _not?: slack_users_bool_exp | null;
  _or?: slack_users_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  displayName?: String_comparison_exp | null;
  displayNameNormalized?: String_comparison_exp | null;
  email?: String_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isAdmin?: Boolean_comparison_exp | null;
  isDeleted?: Boolean_comparison_exp | null;
  isOwner?: Boolean_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  nameNormalized?: String_comparison_exp | null;
  phone?: String_comparison_exp | null;
  realName?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  slack_team?: slack_teams_bool_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  teamSid?: String_comparison_exp | null;
  timezone?: String_comparison_exp | null;
  timezoneNormalized?: String_comparison_exp | null;
  timezoneOffset?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "slack_users"
 */
export interface slack_users_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  displayName?: string | null;
  displayNameNormalized?: string | null;
  email?: string | null;
  firstName?: string | null;
  id?: number | null;
  isAdmin?: boolean | null;
  isDeleted?: boolean | null;
  isOwner?: boolean | null;
  lastName?: string | null;
  name?: string | null;
  nameNormalized?: string | null;
  phone?: string | null;
  realName?: string | null;
  sid?: string | null;
  slack_team?: slack_teams_obj_rel_insert_input | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  teamSid?: string | null;
  timezone?: string | null;
  timezoneNormalized?: string | null;
  timezoneOffset?: number | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "slack_users"
 */
export interface slack_users_on_conflict {
  constraint: slack_users_constraint;
  update_columns: slack_users_update_column[];
  where?: slack_users_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "syncable_entities"
 */
export interface syncable_entities_arr_rel_insert_input {
  data: syncable_entities_insert_input[];
  on_conflict?: syncable_entities_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "syncable_entities". All fields are combined with a logical 'AND'.
 */
export interface syncable_entities_bool_exp {
  _and?: syncable_entities_bool_exp[] | null;
  _not?: syncable_entities_bool_exp | null;
  _or?: syncable_entities_bool_exp[] | null;
  clickup_apps?: clickup_apps_bool_exp | null;
  clickup_folders?: clickup_folders_bool_exp | null;
  clickup_lists?: clickup_lists_bool_exp | null;
  clickup_spaces?: clickup_spaces_bool_exp | null;
  clickup_task_comments?: clickup_task_comments_bool_exp | null;
  clickup_tasks?: clickup_tasks_bool_exp | null;
  clickup_teams?: clickup_teams_bool_exp | null;
  clickup_users?: clickup_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  createdAtSource?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discussionCommentsBySyncsidSynctablename?: discussion_comments_bool_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  discussion_sources?: discussion_sources_bool_exp | null;
  etag?: String_comparison_exp | null;
  expiresInType?: job_intervals_enum_comparison_exp | null;
  gcp_storage_bucket?: gcp_storage_buckets_bool_exp | null;
  gcp_storage_buckets?: gcp_storage_buckets_bool_exp | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  gcp_storage_objects?: gcp_storage_objects_bool_exp | null;
  git_branch?: git_branches_bool_exp | null;
  git_branches?: git_branches_bool_exp | null;
  git_comment_reaction?: git_comment_reactions_bool_exp | null;
  git_comment_reactions?: git_comment_reactions_bool_exp | null;
  git_commit?: git_commits_bool_exp | null;
  git_commit_comment?: git_commit_comments_bool_exp | null;
  git_commit_comments?: git_commit_comments_bool_exp | null;
  git_commit_context?: git_commit_contexts_bool_exp | null;
  git_commit_contexts?: git_commit_contexts_bool_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  git_organizations?: git_organizations_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_request_comment?: git_pull_request_comments_bool_exp | null;
  git_pull_request_comments?: git_pull_request_comments_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_bool_exp | null;
  git_pull_request_review_thread?: git_pull_request_review_threads_bool_exp | null;
  git_pull_request_review_threads?: git_pull_request_review_threads_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_repo_changes?: git_repo_changes_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  github_app?: github_apps_bool_exp | null;
  github_app_installation?: github_app_installations_bool_exp | null;
  github_app_installations?: github_app_installations_bool_exp | null;
  github_apps?: github_apps_bool_exp | null;
  github_branch?: github_branches_bool_exp | null;
  github_branches?: github_branches_bool_exp | null;
  github_comment_reaction?: github_comment_reactions_bool_exp | null;
  github_comment_reactions?: github_comment_reactions_bool_exp | null;
  github_commit?: github_commits_bool_exp | null;
  github_commit_comments?: github_commit_comments_bool_exp | null;
  github_commit_context?: github_commit_contexts_bool_exp | null;
  github_commit_contexts?: github_commit_contexts_bool_exp | null;
  github_commits?: github_commits_bool_exp | null;
  github_issue?: github_issues_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  github_issue_comment?: github_issue_comments_bool_exp | null;
  github_issue_comments?: github_issue_comments_bool_exp | null;
  github_issues?: github_issues_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_organization_members?: github_organization_members_bool_exp | null;
  github_organizations?: github_organizations_bool_exp | null;
  github_owner?: github_owners_bool_exp | null;
  github_owners?: github_owners_bool_exp | null;
  github_pull_request_comment?: github_pull_request_comments_bool_exp | null;
  github_pull_request_comments?: github_pull_request_comments_bool_exp | null;
  github_pull_request_review?: github_pull_request_reviews_bool_exp | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_bool_exp | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_bool_exp | null;
  github_pull_request_review_thread?: github_pull_request_review_threads_bool_exp | null;
  github_pull_request_review_threads?: github_pull_request_review_threads_bool_exp | null;
  github_pull_request_reviews?: github_pull_request_reviews_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  github_repo_labels?: github_repo_labels_bool_exp | null;
  github_repo_teams?: github_repo_teams_bool_exp | null;
  github_repos?: github_repos_bool_exp | null;
  github_team_members?: github_team_members_bool_exp | null;
  github_teams?: github_teams_bool_exp | null;
  gitlab_applications?: gitlab_applications_bool_exp | null;
  gitlab_groups?: gitlab_groups_bool_exp | null;
  gitlab_issue?: gitlab_issues_bool_exp | null;
  gitlab_issue_comments?: gitlab_issue_comments_bool_exp | null;
  gitlab_issues?: gitlab_issues_bool_exp | null;
  gitlab_namespaces?: gitlab_namespaces_bool_exp | null;
  gitlab_project?: gitlab_projects_bool_exp | null;
  gitlab_projects?: gitlab_projects_bool_exp | null;
  gitlab_pull_request?: gitlab_pull_requests_bool_exp | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_bool_exp | null;
  gitlab_pull_requests?: gitlab_pull_requests_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  gitlab_users?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  isForked?: Boolean_comparison_exp | null;
  isOutdated?: Boolean_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_accounts?: jira_accounts_bool_exp | null;
  jira_app_installations?: jira_app_installations_bool_exp | null;
  jira_apps?: jira_apps_bool_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  jira_issue_attachments?: jira_issue_attachments_bool_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  jira_issue_comments?: jira_issue_comment_bool_exp | null;
  jira_issue_transitions?: jira_issue_transitions_bool_exp | null;
  jira_issue_type_statuses?: jira_issue_type_statuses_bool_exp | null;
  jira_issue_types?: jira_issue_types_bool_exp | null;
  jira_issues?: jira_issues_bool_exp | null;
  jira_organizations?: jira_organizations_bool_exp | null;
  jira_projects?: jira_projects_bool_exp | null;
  job_interval?: job_intervals_bool_exp | null;
  linearAccountsBySyncentityid?: linear_accounts_bool_exp | null;
  linearIssuesBySyncentityid?: linear_issues_bool_exp | null;
  linearTeamsBySyncentityid?: linear_teams_bool_exp | null;
  linear_accounts?: linear_accounts_bool_exp | null;
  linear_app_installations?: linear_app_installations_bool_exp | null;
  linear_apps?: linear_apps_bool_exp | null;
  linear_comments?: linear_comments_bool_exp | null;
  linear_issue_changes?: linear_issue_changes_bool_exp | null;
  linear_issues?: linear_issues_bool_exp | null;
  linear_teams?: linear_teams_bool_exp | null;
  redundantFields?: jsonb_comparison_exp | null;
  rippling_companies?: rippling_companies_bool_exp | null;
  rippling_company?: rippling_companies_bool_exp | null;
  rippling_employee?: rippling_employees_bool_exp | null;
  rippling_employees?: rippling_employees_bool_exp | null;
  rippling_payroll_earning_type?: rippling_payroll_earning_types_bool_exp | null;
  rippling_payroll_earning_types?: rippling_payroll_earning_types_bool_exp | null;
  rippling_payroll_run?: rippling_payroll_runs_bool_exp | null;
  rippling_payroll_run_detail?: rippling_payroll_run_details_bool_exp | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_bool_exp | null;
  rippling_payroll_run_earning?: rippling_payroll_run_earnings_bool_exp | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_bool_exp | null;
  rippling_payroll_runs?: rippling_payroll_runs_bool_exp | null;
  rippling_payroll_schedule?: rippling_payroll_schedules_bool_exp | null;
  rippling_payroll_schedules?: rippling_payroll_schedules_bool_exp | null;
  shallow?: Boolean_comparison_exp | null;
  shouldStartSyncAt?: timestamptz_comparison_exp | null;
  sid?: String_comparison_exp | null;
  slackAppsBySyncentityid?: slack_apps_bool_exp | null;
  slack_app_channels?: slack_app_channels_bool_exp | null;
  slack_apps?: slack_apps_bool_exp | null;
  slack_channels?: slack_channels_bool_exp | null;
  slack_teams?: slack_teams_bool_exp | null;
  slack_users?: slack_users_bool_exp | null;
  sourceUrl?: String_comparison_exp | null;
  syncStartedAt?: timestamptz_comparison_exp | null;
  syncTriggeredAt?: timestamptz_comparison_exp | null;
  syncable_entity_outdated_view?: syncable_entities_outdated_bool_exp | null;
  syncable_entity_parsed?: syncable_entities_parsed_bool_exp | null;
  syncable_entity_syncs?: syncable_entity_syncs_bool_exp | null;
  syncable_entity_type?: syncable_entity_types_bool_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  tableName?: syncable_entity_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedAtSource?: timestamptz_comparison_exp | null;
  user_profiles?: user_profiles_bool_exp | null;
  webhook_events?: webhook_events_bool_exp | null;
  webhooks?: webhooks_bool_exp | null;
  weight?: float8_comparison_exp | null;
  zoom_apps?: zoom_app_bool_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
  zoom_meeting_instance?: zoom_meeting_instances_bool_exp | null;
  zoom_meeting_instances?: zoom_meeting_instances_bool_exp | null;
  zoom_meetings?: zoom_meetings_bool_exp | null;
  zoom_recording?: zoom_recordings_bool_exp | null;
  zoom_recording_file?: zoom_recording_files_bool_exp | null;
  zoom_recording_files?: zoom_recording_files_bool_exp | null;
  zoom_recordings?: zoom_recordings_bool_exp | null;
  zoom_user?: zoom_users_bool_exp | null;
  zoom_users?: zoom_users_bool_exp | null;
}

/**
 * input type for inserting data into table "syncable_entities"
 */
export interface syncable_entities_insert_input {
  clickup_apps?: clickup_apps_arr_rel_insert_input | null;
  clickup_folders?: clickup_folders_arr_rel_insert_input | null;
  clickup_lists?: clickup_lists_arr_rel_insert_input | null;
  clickup_spaces?: clickup_spaces_arr_rel_insert_input | null;
  clickup_task_comments?: clickup_task_comments_arr_rel_insert_input | null;
  clickup_tasks?: clickup_tasks_arr_rel_insert_input | null;
  clickup_teams?: clickup_teams_arr_rel_insert_input | null;
  clickup_users?: clickup_users_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  createdAtSource?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  discussionCommentsBySyncsidSynctablename?: discussion_comments_arr_rel_insert_input | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  discussion_sources?: discussion_sources_arr_rel_insert_input | null;
  etag?: string | null;
  expiresInType?: job_intervals_enum | null;
  gcp_storage_bucket?: gcp_storage_buckets_obj_rel_insert_input | null;
  gcp_storage_buckets?: gcp_storage_buckets_arr_rel_insert_input | null;
  gcp_storage_object?: gcp_storage_objects_obj_rel_insert_input | null;
  gcp_storage_objects?: gcp_storage_objects_arr_rel_insert_input | null;
  git_branch?: git_branches_obj_rel_insert_input | null;
  git_branches?: git_branches_arr_rel_insert_input | null;
  git_comment_reaction?: git_comment_reactions_obj_rel_insert_input | null;
  git_comment_reactions?: git_comment_reactions_arr_rel_insert_input | null;
  git_commit?: git_commits_obj_rel_insert_input | null;
  git_commit_comment?: git_commit_comments_obj_rel_insert_input | null;
  git_commit_comments?: git_commit_comments_arr_rel_insert_input | null;
  git_commit_context?: git_commit_contexts_obj_rel_insert_input | null;
  git_commit_contexts?: git_commit_contexts_arr_rel_insert_input | null;
  git_commits?: git_commits_arr_rel_insert_input | null;
  git_organization?: git_organizations_obj_rel_insert_input | null;
  git_organizations?: git_organizations_arr_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_request_comment?: git_pull_request_comments_obj_rel_insert_input | null;
  git_pull_request_comments?: git_pull_request_comments_arr_rel_insert_input | null;
  git_pull_request_review?: git_pull_request_reviews_obj_rel_insert_input | null;
  git_pull_request_review_comment?: git_pull_request_review_comments_obj_rel_insert_input | null;
  git_pull_request_review_comments?: git_pull_request_review_comments_arr_rel_insert_input | null;
  git_pull_request_review_thread?: git_pull_request_review_threads_obj_rel_insert_input | null;
  git_pull_request_review_threads?: git_pull_request_review_threads_arr_rel_insert_input | null;
  git_pull_request_reviews?: git_pull_request_reviews_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  git_repo_changes?: git_repo_changes_arr_rel_insert_input | null;
  git_repos?: git_repos_arr_rel_insert_input | null;
  git_user?: git_users_obj_rel_insert_input | null;
  git_users?: git_users_arr_rel_insert_input | null;
  github_account?: github_accounts_obj_rel_insert_input | null;
  github_accounts?: github_accounts_arr_rel_insert_input | null;
  github_app?: github_apps_obj_rel_insert_input | null;
  github_app_installation?: github_app_installations_obj_rel_insert_input | null;
  github_app_installations?: github_app_installations_arr_rel_insert_input | null;
  github_apps?: github_apps_arr_rel_insert_input | null;
  github_branch?: github_branches_obj_rel_insert_input | null;
  github_branches?: github_branches_arr_rel_insert_input | null;
  github_comment_reaction?: github_comment_reactions_obj_rel_insert_input | null;
  github_comment_reactions?: github_comment_reactions_arr_rel_insert_input | null;
  github_commit?: github_commits_obj_rel_insert_input | null;
  github_commit_comments?: github_commit_comments_arr_rel_insert_input | null;
  github_commit_context?: github_commit_contexts_obj_rel_insert_input | null;
  github_commit_contexts?: github_commit_contexts_arr_rel_insert_input | null;
  github_commits?: github_commits_arr_rel_insert_input | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  github_issue_comment?: github_issue_comments_obj_rel_insert_input | null;
  github_issue_comments?: github_issue_comments_arr_rel_insert_input | null;
  github_issues?: github_issues_arr_rel_insert_input | null;
  github_organization?: github_organizations_obj_rel_insert_input | null;
  github_organization_members?: github_organization_members_arr_rel_insert_input | null;
  github_organizations?: github_organizations_arr_rel_insert_input | null;
  github_owner?: github_owners_obj_rel_insert_input | null;
  github_owners?: github_owners_arr_rel_insert_input | null;
  github_pull_request_comment?: github_pull_request_comments_obj_rel_insert_input | null;
  github_pull_request_comments?: github_pull_request_comments_arr_rel_insert_input | null;
  github_pull_request_review?: github_pull_request_reviews_obj_rel_insert_input | null;
  github_pull_request_review_comment?: github_pull_request_review_comments_obj_rel_insert_input | null;
  github_pull_request_review_comments?: github_pull_request_review_comments_arr_rel_insert_input | null;
  github_pull_request_review_thread?: github_pull_request_review_threads_obj_rel_insert_input | null;
  github_pull_request_review_threads?: github_pull_request_review_threads_arr_rel_insert_input | null;
  github_pull_request_reviews?: github_pull_request_reviews_arr_rel_insert_input | null;
  github_repo?: github_repos_obj_rel_insert_input | null;
  github_repo_labels?: github_repo_labels_arr_rel_insert_input | null;
  github_repo_teams?: github_repo_teams_arr_rel_insert_input | null;
  github_repos?: github_repos_arr_rel_insert_input | null;
  github_team_members?: github_team_members_arr_rel_insert_input | null;
  github_teams?: github_teams_arr_rel_insert_input | null;
  gitlab_applications?: gitlab_applications_arr_rel_insert_input | null;
  gitlab_groups?: gitlab_groups_arr_rel_insert_input | null;
  gitlab_issue?: gitlab_issues_obj_rel_insert_input | null;
  gitlab_issue_comments?: gitlab_issue_comments_arr_rel_insert_input | null;
  gitlab_issues?: gitlab_issues_arr_rel_insert_input | null;
  gitlab_namespaces?: gitlab_namespaces_arr_rel_insert_input | null;
  gitlab_project?: gitlab_projects_obj_rel_insert_input | null;
  gitlab_projects?: gitlab_projects_arr_rel_insert_input | null;
  gitlab_pull_request?: gitlab_pull_requests_obj_rel_insert_input | null;
  gitlab_pull_request_comments?: gitlab_pull_request_comments_arr_rel_insert_input | null;
  gitlab_pull_requests?: gitlab_pull_requests_arr_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  gitlab_users?: gitlab_users_arr_rel_insert_input | null;
  id?: number | null;
  isForked?: boolean | null;
  isOutdated?: boolean | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_accounts?: jira_accounts_arr_rel_insert_input | null;
  jira_app_installations?: jira_app_installations_arr_rel_insert_input | null;
  jira_apps?: jira_apps_arr_rel_insert_input | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  jira_issue_attachments?: jira_issue_attachments_arr_rel_insert_input | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  jira_issue_comments?: jira_issue_comment_arr_rel_insert_input | null;
  jira_issue_transitions?: jira_issue_transitions_arr_rel_insert_input | null;
  jira_issue_type_statuses?: jira_issue_type_statuses_arr_rel_insert_input | null;
  jira_issue_types?: jira_issue_types_arr_rel_insert_input | null;
  jira_issues?: jira_issues_arr_rel_insert_input | null;
  jira_organizations?: jira_organizations_arr_rel_insert_input | null;
  jira_projects?: jira_projects_arr_rel_insert_input | null;
  job_interval?: job_intervals_obj_rel_insert_input | null;
  linearAccountsBySyncentityid?: linear_accounts_arr_rel_insert_input | null;
  linearIssuesBySyncentityid?: linear_issues_arr_rel_insert_input | null;
  linearTeamsBySyncentityid?: linear_teams_arr_rel_insert_input | null;
  linear_accounts?: linear_accounts_obj_rel_insert_input | null;
  linear_app_installations?: linear_app_installations_arr_rel_insert_input | null;
  linear_apps?: linear_apps_arr_rel_insert_input | null;
  linear_comments?: linear_comments_arr_rel_insert_input | null;
  linear_issue_changes?: linear_issue_changes_arr_rel_insert_input | null;
  linear_issues?: linear_issues_obj_rel_insert_input | null;
  linear_teams?: linear_teams_obj_rel_insert_input | null;
  redundantFields?: hasura_jsonb | null;
  rippling_companies?: rippling_companies_arr_rel_insert_input | null;
  rippling_company?: rippling_companies_obj_rel_insert_input | null;
  rippling_employee?: rippling_employees_obj_rel_insert_input | null;
  rippling_employees?: rippling_employees_arr_rel_insert_input | null;
  rippling_payroll_earning_type?: rippling_payroll_earning_types_obj_rel_insert_input | null;
  rippling_payroll_earning_types?: rippling_payroll_earning_types_arr_rel_insert_input | null;
  rippling_payroll_run?: rippling_payroll_runs_obj_rel_insert_input | null;
  rippling_payroll_run_detail?: rippling_payroll_run_details_obj_rel_insert_input | null;
  rippling_payroll_run_details?: rippling_payroll_run_details_arr_rel_insert_input | null;
  rippling_payroll_run_earning?: rippling_payroll_run_earnings_obj_rel_insert_input | null;
  rippling_payroll_run_earnings?: rippling_payroll_run_earnings_arr_rel_insert_input | null;
  rippling_payroll_runs?: rippling_payroll_runs_arr_rel_insert_input | null;
  rippling_payroll_schedule?: rippling_payroll_schedules_obj_rel_insert_input | null;
  rippling_payroll_schedules?: rippling_payroll_schedules_arr_rel_insert_input | null;
  shallow?: boolean | null;
  shouldStartSyncAt?: hasura_timestamptz | null;
  sid?: string | null;
  slackAppsBySyncentityid?: slack_apps_arr_rel_insert_input | null;
  slack_app_channels?: slack_app_channels_arr_rel_insert_input | null;
  slack_apps?: slack_apps_obj_rel_insert_input | null;
  slack_channels?: slack_channels_arr_rel_insert_input | null;
  slack_teams?: slack_teams_arr_rel_insert_input | null;
  slack_users?: slack_users_arr_rel_insert_input | null;
  sourceUrl?: string | null;
  syncStartedAt?: hasura_timestamptz | null;
  syncTriggeredAt?: hasura_timestamptz | null;
  syncable_entity_outdated_view?: syncable_entities_outdated_obj_rel_insert_input | null;
  syncable_entity_parsed?: syncable_entities_parsed_obj_rel_insert_input | null;
  syncable_entity_syncs?: syncable_entity_syncs_arr_rel_insert_input | null;
  syncable_entity_type?: syncable_entity_types_obj_rel_insert_input | null;
  syncedAt?: hasura_timestamptz | null;
  tableName?: syncable_entity_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
  updatedAtSource?: hasura_timestamptz | null;
  user_profiles?: user_profiles_arr_rel_insert_input | null;
  webhook_events?: webhook_events_arr_rel_insert_input | null;
  webhooks?: webhooks_arr_rel_insert_input | null;
  weight?: hasura_float8 | null;
  zoom_apps?: zoom_app_arr_rel_insert_input | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
  zoom_meeting_instance?: zoom_meeting_instances_obj_rel_insert_input | null;
  zoom_meeting_instances?: zoom_meeting_instances_arr_rel_insert_input | null;
  zoom_meetings?: zoom_meetings_arr_rel_insert_input | null;
  zoom_recording?: zoom_recordings_obj_rel_insert_input | null;
  zoom_recording_file?: zoom_recording_files_obj_rel_insert_input | null;
  zoom_recording_files?: zoom_recording_files_arr_rel_insert_input | null;
  zoom_recordings?: zoom_recordings_arr_rel_insert_input | null;
  zoom_user?: zoom_users_obj_rel_insert_input | null;
  zoom_users?: zoom_users_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "syncable_entities"
 */
export interface syncable_entities_obj_rel_insert_input {
  data: syncable_entities_insert_input;
  on_conflict?: syncable_entities_on_conflict | null;
}

/**
 * on_conflict condition type for table "syncable_entities"
 */
export interface syncable_entities_on_conflict {
  constraint: syncable_entities_constraint;
  update_columns: syncable_entities_update_column[];
  where?: syncable_entities_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "syncable_entities_outdated". All fields are combined with a logical 'AND'.
 */
export interface syncable_entities_outdated_bool_exp {
  _and?: syncable_entities_outdated_bool_exp[] | null;
  _not?: syncable_entities_outdated_bool_exp | null;
  _or?: syncable_entities_outdated_bool_exp[] | null;
  active_sync_id?: Int_comparison_exp | null;
  active_sync_started_at?: timestamptz_comparison_exp | null;
  active_sync_status?: String_comparison_exp | null;
  can_sync?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  createdAtSource?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dynamicExpiresInType?: String_comparison_exp | null;
  etag?: String_comparison_exp | null;
  expiresInType?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isForked?: Boolean_comparison_exp | null;
  isOutdated?: Boolean_comparison_exp | null;
  is_expired?: Boolean_comparison_exp | null;
  redundantFields?: jsonb_comparison_exp | null;
  shallow?: Boolean_comparison_exp | null;
  shouldStartSyncAt?: timestamptz_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sourceUrl?: String_comparison_exp | null;
  syncStartedAt?: timestamptz_comparison_exp | null;
  syncTriggeredAt?: timestamptz_comparison_exp | null;
  syncable_entity_syncs?: syncable_entity_syncs_bool_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  tableName?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedAtSource?: timestamptz_comparison_exp | null;
  weight?: float8_comparison_exp | null;
}

/**
 * input type for inserting data into table "syncable_entities_outdated"
 */
export interface syncable_entities_outdated_insert_input {
  active_sync_id?: number | null;
  active_sync_started_at?: hasura_timestamptz | null;
  active_sync_status?: string | null;
  can_sync?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  createdAtSource?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dynamicExpiresInType?: string | null;
  etag?: string | null;
  expiresInType?: string | null;
  id?: number | null;
  isForked?: boolean | null;
  isOutdated?: boolean | null;
  is_expired?: boolean | null;
  redundantFields?: hasura_jsonb | null;
  shallow?: boolean | null;
  shouldStartSyncAt?: hasura_timestamptz | null;
  sid?: string | null;
  sourceUrl?: string | null;
  syncStartedAt?: hasura_timestamptz | null;
  syncTriggeredAt?: hasura_timestamptz | null;
  syncable_entity_syncs?: syncable_entity_syncs_arr_rel_insert_input | null;
  syncedAt?: hasura_timestamptz | null;
  tableName?: string | null;
  updatedAt?: hasura_timestamptz | null;
  updatedAtSource?: hasura_timestamptz | null;
  weight?: hasura_float8 | null;
}

/**
 * input type for inserting object relation for remote table "syncable_entities_outdated"
 */
export interface syncable_entities_outdated_obj_rel_insert_input {
  data: syncable_entities_outdated_insert_input;
}

/**
 * Boolean expression to filter rows from the table "syncable_entities_parsed". All fields are combined with a logical 'AND'.
 */
export interface syncable_entities_parsed_bool_exp {
  _and?: syncable_entities_parsed_bool_exp[] | null;
  _not?: syncable_entities_parsed_bool_exp | null;
  _or?: syncable_entities_parsed_bool_exp[] | null;
  active_sync_id?: Int_comparison_exp | null;
  active_sync_started_at?: timestamptz_comparison_exp | null;
  active_sync_status?: String_comparison_exp | null;
  can_sync?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  createdAtSource?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dynamicExpiresInType?: String_comparison_exp | null;
  etag?: String_comparison_exp | null;
  expiresInType?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isForked?: Boolean_comparison_exp | null;
  isOutdated?: Boolean_comparison_exp | null;
  is_expired?: Boolean_comparison_exp | null;
  redundantFields?: jsonb_comparison_exp | null;
  shallow?: Boolean_comparison_exp | null;
  shouldStartSyncAt?: timestamptz_comparison_exp | null;
  sid?: String_comparison_exp | null;
  sourceUrl?: String_comparison_exp | null;
  syncStartedAt?: timestamptz_comparison_exp | null;
  syncTriggeredAt?: timestamptz_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  syncable_entity_outdated?: syncable_entities_outdated_bool_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  tableName?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedAtSource?: timestamptz_comparison_exp | null;
  weight?: float8_comparison_exp | null;
}

/**
 * input type for inserting data into table "syncable_entities_parsed"
 */
export interface syncable_entities_parsed_insert_input {
  active_sync_id?: number | null;
  active_sync_started_at?: hasura_timestamptz | null;
  active_sync_status?: string | null;
  can_sync?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  createdAtSource?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dynamicExpiresInType?: string | null;
  etag?: string | null;
  expiresInType?: string | null;
  id?: number | null;
  isForked?: boolean | null;
  isOutdated?: boolean | null;
  is_expired?: boolean | null;
  redundantFields?: hasura_jsonb | null;
  shallow?: boolean | null;
  shouldStartSyncAt?: hasura_timestamptz | null;
  sid?: string | null;
  sourceUrl?: string | null;
  syncStartedAt?: hasura_timestamptz | null;
  syncTriggeredAt?: hasura_timestamptz | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  syncable_entity_outdated?: syncable_entities_outdated_obj_rel_insert_input | null;
  syncedAt?: hasura_timestamptz | null;
  tableName?: string | null;
  updatedAt?: hasura_timestamptz | null;
  updatedAtSource?: hasura_timestamptz | null;
  weight?: hasura_float8 | null;
}

/**
 * input type for inserting object relation for remote table "syncable_entities_parsed"
 */
export interface syncable_entities_parsed_obj_rel_insert_input {
  data: syncable_entities_parsed_insert_input;
}

/**
 * Boolean expression to filter rows from the table "syncable_entity_job_types". All fields are combined with a logical 'AND'.
 */
export interface syncable_entity_job_types_bool_exp {
  _and?: syncable_entity_job_types_bool_exp[] | null;
  _not?: syncable_entity_job_types_bool_exp | null;
  _or?: syncable_entity_job_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  syncable_entity_syncs?: syncable_entity_syncs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "syncable_entity_job_types_enum". All fields are combined with logical 'AND'.
 */
export interface syncable_entity_job_types_enum_comparison_exp {
  _eq?: syncable_entity_job_types_enum | null;
  _in?: syncable_entity_job_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: syncable_entity_job_types_enum | null;
  _nin?: syncable_entity_job_types_enum[] | null;
}

/**
 * input type for inserting data into table "syncable_entity_job_types"
 */
export interface syncable_entity_job_types_insert_input {
  description?: string | null;
  id?: string | null;
  syncable_entity_syncs?: syncable_entity_syncs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "syncable_entity_job_types"
 */
export interface syncable_entity_job_types_obj_rel_insert_input {
  data: syncable_entity_job_types_insert_input;
  on_conflict?: syncable_entity_job_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "syncable_entity_job_types"
 */
export interface syncable_entity_job_types_on_conflict {
  constraint: syncable_entity_job_types_constraint;
  update_columns: syncable_entity_job_types_update_column[];
  where?: syncable_entity_job_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "syncable_entity_syncs"
 */
export interface syncable_entity_syncs_arr_rel_insert_input {
  data: syncable_entity_syncs_insert_input[];
  on_conflict?: syncable_entity_syncs_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "syncable_entity_syncs". All fields are combined with a logical 'AND'.
 */
export interface syncable_entity_syncs_bool_exp {
  _and?: syncable_entity_syncs_bool_exp[] | null;
  _not?: syncable_entity_syncs_bool_exp | null;
  _or?: syncable_entity_syncs_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  errorMessage?: jsonb_comparison_exp | null;
  expiresAt?: timestamptz_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  source?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncEntityJobType?: syncable_entity_job_types_enum_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  syncable_entity_job_type?: syncable_entity_job_types_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "syncable_entity_syncs"
 */
export interface syncable_entity_syncs_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  errorMessage?: hasura_jsonb | null;
  expiresAt?: hasura_timestamptz | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  source?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: string | null;
  syncEntityId?: number | null;
  syncEntityJobType?: syncable_entity_job_types_enum | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  syncable_entity_job_type?: syncable_entity_job_types_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "syncable_entity_syncs"
 */
export interface syncable_entity_syncs_on_conflict {
  constraint: syncable_entity_syncs_constraint;
  update_columns: syncable_entity_syncs_update_column[];
  where?: syncable_entity_syncs_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "syncable_entity_types". All fields are combined with a logical 'AND'.
 */
export interface syncable_entity_types_bool_exp {
  _and?: syncable_entity_types_bool_exp[] | null;
  _not?: syncable_entity_types_bool_exp | null;
  _or?: syncable_entity_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  syncable_entities?: syncable_entities_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "syncable_entity_types_enum". All fields are combined with logical 'AND'.
 */
export interface syncable_entity_types_enum_comparison_exp {
  _eq?: syncable_entity_types_enum | null;
  _in?: syncable_entity_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: syncable_entity_types_enum | null;
  _nin?: syncable_entity_types_enum[] | null;
}

/**
 * input type for inserting data into table "syncable_entity_types"
 */
export interface syncable_entity_types_insert_input {
  description?: string | null;
  id?: string | null;
  syncable_entities?: syncable_entities_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "syncable_entity_types"
 */
export interface syncable_entity_types_obj_rel_insert_input {
  data: syncable_entity_types_insert_input;
  on_conflict?: syncable_entity_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "syncable_entity_types"
 */
export interface syncable_entity_types_on_conflict {
  constraint: syncable_entity_types_constraint;
  update_columns: syncable_entity_types_update_column[];
  where?: syncable_entity_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_attributes". All fields are combined with a logical 'AND'.
 */
export interface task_attributes_bool_exp {
  _and?: task_attributes_bool_exp[] | null;
  _not?: task_attributes_bool_exp | null;
  _or?: task_attributes_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  remainingDuplicateLimit?: bigint_comparison_exp | null;
  timeBasedDeveloperCost?: bigint_comparison_exp | null;
  timeSpentByDeveloperInSecs?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_attributes"
 */
export interface task_attributes_insert_input {
  id?: number | null;
  remainingDuplicateLimit?: hasura_bigint | null;
  timeBasedDeveloperCost?: hasura_bigint | null;
  timeSpentByDeveloperInSecs?: hasura_bigint | null;
}

/**
 * input type for inserting object relation for remote table "task_attributes"
 */
export interface task_attributes_obj_rel_insert_input {
  data: task_attributes_insert_input;
}

/**
 * input type for inserting array relation for remote table "task_changes"
 */
export interface task_changes_arr_rel_insert_input {
  data: task_changes_insert_input[];
  on_conflict?: task_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_changes". All fields are combined with a logical 'AND'.
 */
export interface task_changes_bool_exp {
  _and?: task_changes_bool_exp[] | null;
  _not?: task_changes_bool_exp | null;
  _or?: task_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  branchName?: String_comparison_exp | null;
  budget?: Int_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientBlockersId?: Int_comparison_exp | null;
  clientCommitDate?: timestamptz_comparison_exp | null;
  clientCommitMessage?: String_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  client_blocker?: client_blockers_bool_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dependsOnTasksId?: Int_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  developer?: developers_bool_exp | null;
  developerByManagerid?: developers_bool_exp | null;
  developerByReviewerid?: developers_bool_exp | null;
  developerByReviewersid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  developerInvoiceId?: Int_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  duplicateOfTaskId?: Int_comparison_exp | null;
  followedByTasksId?: Int_comparison_exp | null;
  gitPullRequestBySlicedpullrequestid?: git_pull_requests_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isBillable?: Boolean_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  managerId?: String_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  maxDuplicateLimit?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  prLink?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  pullRequestsId?: Int_comparison_exp | null;
  pull_request_id?: bigint_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  reviewerId?: String_comparison_exp | null;
  reviewersId?: String_comparison_exp | null;
  slicedPullRequestId?: Int_comparison_exp | null;
  specLink?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: tasks_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskByDependsontasksid?: tasks_bool_exp | null;
  taskByFollowedbytasksid?: tasks_bool_exp | null;
  taskById?: tasks_bool_exp | null;
  taskCode?: String_comparison_exp | null;
  tasks_status?: tasks_status_bool_exp | null;
  tasks_type?: tasks_type_bool_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  timebased?: Boolean_comparison_exp | null;
  title?: String_comparison_exp | null;
  type?: tasks_type_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userInvoiceByDeveloperinvoiceid?: user_invoices_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_changes_by_status"
 */
export interface task_changes_by_status_arr_rel_insert_input {
  data: task_changes_by_status_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "task_changes_by_status". All fields are combined with a logical 'AND'.
 */
export interface task_changes_by_status_bool_exp {
  _and?: task_changes_by_status_bool_exp[] | null;
  _not?: task_changes_by_status_bool_exp | null;
  _or?: task_changes_by_status_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  transitionEndAt?: timestamptz_comparison_exp | null;
  transitionedAt?: timestamptz_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_changes_by_status"
 */
export interface task_changes_by_status_insert_input {
  clientId?: string | null;
  id?: number | null;
  status?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  transitionEndAt?: hasura_timestamptz | null;
  transitionedAt?: hasura_timestamptz | null;
  type?: string | null;
}

/**
 * input type for inserting data into table "task_changes"
 */
export interface task_changes_insert_input {
  automaticallyChanged?: boolean | null;
  branchName?: string | null;
  budget?: number | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientBlockersId?: number | null;
  clientCommitDate?: hasura_timestamptz | null;
  clientCommitMessage?: string | null;
  clientId?: string | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  dependsOnTasksId?: number | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  description_type?: description_types_obj_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  developerByManagerid?: developers_obj_rel_insert_input | null;
  developerByReviewerid?: developers_obj_rel_insert_input | null;
  developerByReviewersid?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  developerInvoiceId?: number | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  duplicateOfTaskId?: number | null;
  followedByTasksId?: number | null;
  gitPullRequestBySlicedpullrequestid?: git_pull_requests_obj_rel_insert_input | null;
  gitRepoId?: number | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  id?: number | null;
  isBillable?: boolean | null;
  lowerBudget?: number | null;
  managerId?: string | null;
  managerInvoiceId?: number | null;
  maxDuplicateLimit?: number | null;
  operation?: string | null;
  prLink?: string | null;
  previousChangeId?: hasura_uuid | null;
  pullRequestsId?: number | null;
  pull_request_id?: hasura_bigint | null;
  reasonForChange?: string | null;
  reviewerId?: string | null;
  reviewersId?: string | null;
  slicedPullRequestId?: number | null;
  specLink?: string | null;
  sqlQuery?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: tasks_status_enum | null;
  statusUpdateReason?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  taskByDependsontasksid?: tasks_obj_rel_insert_input | null;
  taskByFollowedbytasksid?: tasks_obj_rel_insert_input | null;
  taskById?: tasks_obj_rel_insert_input | null;
  taskCode?: string | null;
  tasks_status?: tasks_status_obj_rel_insert_input | null;
  tasks_type?: tasks_type_obj_rel_insert_input | null;
  technologiesId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  timebased?: boolean | null;
  title?: string | null;
  type?: tasks_type_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  user?: users_obj_rel_insert_input | null;
  userInvoiceByDeveloperinvoiceid?: user_invoices_obj_rel_insert_input | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "task_changes"
 */
export interface task_changes_obj_rel_insert_input {
  data: task_changes_insert_input;
  on_conflict?: task_changes_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_changes"
 */
export interface task_changes_on_conflict {
  constraint: task_changes_constraint;
  update_columns: task_changes_update_column[];
  where?: task_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_client_blockers"
 */
export interface task_client_blockers_arr_rel_insert_input {
  data: task_client_blockers_insert_input[];
  on_conflict?: task_client_blockers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_client_blockers". All fields are combined with a logical 'AND'.
 */
export interface task_client_blockers_bool_exp {
  _and?: task_client_blockers_bool_exp[] | null;
  _not?: task_client_blockers_bool_exp | null;
  _or?: task_client_blockers_bool_exp[] | null;
  clientBlockersId?: Int_comparison_exp | null;
  client_blocker?: client_blockers_bool_exp | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_client_blockers"
 */
export interface task_client_blockers_insert_input {
  clientBlockersId?: number | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  task?: tasks_obj_rel_insert_input | null;
  tasksId?: number | null;
}

/**
 * on_conflict condition type for table "task_client_blockers"
 */
export interface task_client_blockers_on_conflict {
  constraint: task_client_blockers_constraint;
  update_columns: task_client_blockers_update_column[];
  where?: task_client_blockers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_code_comments"
 */
export interface task_code_comments_arr_rel_insert_input {
  data: task_code_comments_insert_input[];
  on_conflict?: task_code_comments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_code_comments". All fields are combined with a logical 'AND'.
 */
export interface task_code_comments_bool_exp {
  _and?: task_code_comments_bool_exp[] | null;
  _not?: task_code_comments_bool_exp | null;
  _or?: task_code_comments_bool_exp[] | null;
  authorId?: Int_comparison_exp | null;
  body?: String_comparison_exp | null;
  client_repositories_commit?: git_commits_bool_exp | null;
  commitId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  diffHunk?: String_comparison_exp | null;
  externalCommentUrl?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  internalCommentUrl?: String_comparison_exp | null;
  lastSync?: timestamptz_comparison_exp | null;
  path?: String_comparison_exp | null;
  shouldPublish?: Boolean_comparison_exp | null;
  shouldSync?: Boolean_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "task_code_comments"
 */
export interface task_code_comments_insert_input {
  authorId?: number | null;
  body?: string | null;
  client_repositories_commit?: git_commits_obj_rel_insert_input | null;
  commitId?: number | null;
  createdAt?: hasura_timestamptz | null;
  diffHunk?: string | null;
  externalCommentUrl?: string | null;
  id?: hasura_uuid | null;
  internalCommentUrl?: string | null;
  lastSync?: hasura_timestamptz | null;
  path?: string | null;
  shouldPublish?: boolean | null;
  shouldSync?: boolean | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "task_code_comments"
 */
export interface task_code_comments_on_conflict {
  constraint: task_code_comments_constraint;
  update_columns: task_code_comments_update_column[];
  where?: task_code_comments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_credit_approval"
 */
export interface task_credit_approval_arr_rel_insert_input {
  data: task_credit_approval_insert_input[];
  on_conflict?: task_credit_approval_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_credit_approval". All fields are combined with a logical 'AND'.
 */
export interface task_credit_approval_bool_exp {
  _and?: task_credit_approval_bool_exp[] | null;
  _not?: task_credit_approval_bool_exp | null;
  _or?: task_credit_approval_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUser?: users_bool_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUser?: users_bool_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  status?: task_credit_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  task_credit_status?: task_credit_status_bool_exp | null;
  ticketCreditApprovalId?: Int_comparison_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_bool_exp | null;
  ticket_credit_approval_details?: ticket_credit_approval_details_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_credit_approval_changes"
 */
export interface task_credit_approval_changes_arr_rel_insert_input {
  data: task_credit_approval_changes_insert_input[];
  on_conflict?: task_credit_approval_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_credit_approval_changes". All fields are combined with a logical 'AND'.
 */
export interface task_credit_approval_changes_bool_exp {
  _and?: task_credit_approval_changes_bool_exp[] | null;
  _not?: task_credit_approval_changes_bool_exp | null;
  _or?: task_credit_approval_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUser?: users_bool_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reason?: String_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  requestedByUser?: users_bool_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUser?: users_bool_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  status?: task_credit_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  task_credit_status?: task_credit_status_bool_exp | null;
  ticketCreditApprovalId?: Int_comparison_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_credit_approval_changes"
 */
export interface task_credit_approval_changes_insert_input {
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUser?: users_obj_rel_insert_input | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reason?: string | null;
  reasonForChange?: string | null;
  requestedByUser?: users_obj_rel_insert_input | null;
  requestedByUserId?: number | null;
  reviewedByUser?: users_obj_rel_insert_input | null;
  reviewedByUserId?: number | null;
  sqlQuery?: string | null;
  status?: task_credit_status_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  task_credit_status?: task_credit_status_obj_rel_insert_input | null;
  ticketCreditApprovalId?: number | null;
  ticket_credit_approval?: ticket_credit_approvals_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * on_conflict condition type for table "task_credit_approval_changes"
 */
export interface task_credit_approval_changes_on_conflict {
  constraint: task_credit_approval_changes_constraint;
  update_columns: task_credit_approval_changes_update_column[];
  where?: task_credit_approval_changes_bool_exp | null;
}

/**
 * input type for inserting data into table "task_credit_approval"
 */
export interface task_credit_approval_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUser?: users_obj_rel_insert_input | null;
  requestedByUserId?: number | null;
  reviewedByUser?: users_obj_rel_insert_input | null;
  reviewedByUserId?: number | null;
  status?: task_credit_status_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  task_credit_status?: task_credit_status_obj_rel_insert_input | null;
  ticketCreditApprovalId?: number | null;
  ticket_credit_approval?: ticket_credit_approvals_obj_rel_insert_input | null;
  ticket_credit_approval_details?: ticket_credit_approval_details_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * input type for inserting object relation for remote table "task_credit_approval"
 */
export interface task_credit_approval_obj_rel_insert_input {
  data: task_credit_approval_insert_input;
  on_conflict?: task_credit_approval_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_credit_approval"
 */
export interface task_credit_approval_on_conflict {
  constraint: task_credit_approval_constraint;
  update_columns: task_credit_approval_update_column[];
  where?: task_credit_approval_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_credit_overrides"
 */
export interface task_credit_overrides_arr_rel_insert_input {
  data: task_credit_overrides_insert_input[];
  on_conflict?: task_credit_overrides_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_credit_overrides". All fields are combined with a logical 'AND'.
 */
export interface task_credit_overrides_bool_exp {
  _and?: task_credit_overrides_bool_exp[] | null;
  _not?: task_credit_overrides_bool_exp | null;
  _or?: task_credit_overrides_bool_exp[] | null;
  appliedByUserId?: Int_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "task_credit_overrides"
 */
export interface task_credit_overrides_insert_input {
  appliedByUserId?: number | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  id?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "task_credit_overrides"
 */
export interface task_credit_overrides_on_conflict {
  constraint: task_credit_overrides_constraint;
  update_columns: task_credit_overrides_update_column[];
  where?: task_credit_overrides_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_credit_status". All fields are combined with a logical 'AND'.
 */
export interface task_credit_status_bool_exp {
  _and?: task_credit_status_bool_exp[] | null;
  _not?: task_credit_status_bool_exp | null;
  _or?: task_credit_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  task_credit_approval_changes?: task_credit_approval_changes_bool_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "task_credit_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_credit_status_enum_comparison_exp {
  _eq?: task_credit_status_enum | null;
  _in?: task_credit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_credit_status_enum | null;
  _nin?: task_credit_status_enum[] | null;
}

/**
 * input type for inserting data into table "task_credit_status"
 */
export interface task_credit_status_insert_input {
  description?: string | null;
  id?: string | null;
  task_credit_approval_changes?: task_credit_approval_changes_arr_rel_insert_input | null;
  task_credit_approvals?: task_credit_approval_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "task_credit_status"
 */
export interface task_credit_status_obj_rel_insert_input {
  data: task_credit_status_insert_input;
  on_conflict?: task_credit_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_credit_status"
 */
export interface task_credit_status_on_conflict {
  constraint: task_credit_status_constraint;
  update_columns: task_credit_status_update_column[];
  where?: task_credit_status_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_daily_standup_client_view". All fields are combined with a logical 'AND'.
 */
export interface task_daily_standup_client_view_bool_exp {
  _and?: task_daily_standup_client_view_bool_exp[] | null;
  _not?: task_daily_standup_client_view_bool_exp | null;
  _or?: task_daily_standup_client_view_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  summarizedInTicketDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  task_daily_standup?: task_daily_standups_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_daily_standup_client_view"
 */
export interface task_daily_standup_client_view_insert_input {
  createdAt?: hasura_timestamptz | null;
  id?: number | null;
  summarizedInTicketDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  task_daily_standup?: task_daily_standups_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting object relation for remote table "task_daily_standup_client_view"
 */
export interface task_daily_standup_client_view_obj_rel_insert_input {
  data: task_daily_standup_client_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "task_daily_standups"
 */
export interface task_daily_standups_arr_rel_insert_input {
  data: task_daily_standups_insert_input[];
  on_conflict?: task_daily_standups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface task_daily_standups_bool_exp {
  _and?: task_daily_standups_bool_exp[] | null;
  _not?: task_daily_standups_bool_exp | null;
  _or?: task_daily_standups_bool_exp[] | null;
  client_task_daily_standup?: task_daily_standup_client_view_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_task_daily_standups?: task_daily_standups_bool_exp | null;
  errorMessage?: String_comparison_exp | null;
  erroredAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  postedToSlack?: Boolean_comparison_exp | null;
  postedToWorkplace?: Boolean_comparison_exp | null;
  submittedAt?: String_comparison_exp | null;
  summarizedInTicketDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket_daily_standup?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedETA?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * input type for inserting data into table "task_daily_standups"
 */
export interface task_daily_standups_insert_input {
  client_task_daily_standup?: task_daily_standup_client_view_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  dev_task_daily_standups?: task_daily_standups_obj_rel_insert_input | null;
  errorMessage?: string | null;
  erroredAt?: hasura_timestamptz | null;
  id?: number | null;
  postedToSlack?: boolean | null;
  postedToWorkplace?: boolean | null;
  submittedAt?: string | null;
  summarizedInTicketDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  summary?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  ticket_daily_standup?: ticket_daily_standups_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  updatedETA?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_daily_standup?: user_daily_standups_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "task_daily_standups"
 */
export interface task_daily_standups_obj_rel_insert_input {
  data: task_daily_standups_insert_input;
  on_conflict?: task_daily_standups_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_daily_standups"
 */
export interface task_daily_standups_on_conflict {
  constraint: task_daily_standups_constraint;
  update_columns: task_daily_standups_update_column[];
  where?: task_daily_standups_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_dependencies"
 */
export interface task_dependencies_arr_rel_insert_input {
  data: task_dependencies_insert_input[];
  on_conflict?: task_dependencies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_dependencies". All fields are combined with a logical 'AND'.
 */
export interface task_dependencies_bool_exp {
  _and?: task_dependencies_bool_exp[] | null;
  _not?: task_dependencies_bool_exp | null;
  _or?: task_dependencies_bool_exp[] | null;
  childTaskId?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  parentTaskId?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskByChildTaskId?: tasks_bool_exp | null;
}

/**
 * input type for inserting data into table "task_dependencies"
 */
export interface task_dependencies_insert_input {
  childTaskId?: number | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  parentTaskId?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskByChildTaskId?: tasks_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "task_dependencies"
 */
export interface task_dependencies_on_conflict {
  constraint: task_dependencies_constraint;
  update_columns: task_dependencies_update_column[];
  where?: task_dependencies_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_developer_view". All fields are combined with a logical 'AND'.
 */
export interface task_developer_view_bool_exp {
  _and?: task_developer_view_bool_exp[] | null;
  _not?: task_developer_view_bool_exp | null;
  _or?: task_developer_view_bool_exp[] | null;
  developerInvoiceId?: Int_comparison_exp | null;
  duplicateOfTaskId?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  maxDuplicateLimit?: Int_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
}

/**
 * input type for inserting data into table "task_developer_view"
 */
export interface task_developer_view_insert_input {
  developerInvoiceId?: number | null;
  duplicateOfTaskId?: number | null;
  id?: number | null;
  managerInvoiceId?: number | null;
  maxDuplicateLimit?: number | null;
  tasks?: tasks_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "task_developer_view"
 */
export interface task_developer_view_obj_rel_insert_input {
  data: task_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "task_followers"
 */
export interface task_followers_arr_rel_insert_input {
  data: task_followers_insert_input[];
  on_conflict?: task_followers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_followers". All fields are combined with a logical 'AND'.
 */
export interface task_followers_bool_exp {
  _and?: task_followers_bool_exp[] | null;
  _not?: task_followers_bool_exp | null;
  _or?: task_followers_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  taskByTasksid2?: tasks_bool_exp | null;
  tasksId_1?: Int_comparison_exp | null;
  tasksId_2?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_followers"
 */
export interface task_followers_insert_input {
  task?: tasks_obj_rel_insert_input | null;
  taskByTasksid2?: tasks_obj_rel_insert_input | null;
  tasksId_1?: number | null;
  tasksId_2?: number | null;
}

/**
 * on_conflict condition type for table "task_followers"
 */
export interface task_followers_on_conflict {
  constraint: task_followers_constraint;
  update_columns: task_followers_update_column[];
  where?: task_followers_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_review_developer_view". All fields are combined with a logical 'AND'.
 */
export interface task_review_developer_view_bool_exp {
  _and?: task_review_developer_view_bool_exp[] | null;
  _not?: task_review_developer_view_bool_exp | null;
  _or?: task_review_developer_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  reviewerInvoiceId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_review_developer_view"
 */
export interface task_review_developer_view_insert_input {
  id?: number | null;
  managerInvoiceId?: number | null;
  reviewerInvoiceId?: number | null;
}

/**
 * input type for inserting object relation for remote table "task_review_developer_view"
 */
export interface task_review_developer_view_obj_rel_insert_input {
  data: task_review_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "task_reviewers"
 */
export interface task_reviewers_arr_rel_insert_input {
  data: task_reviewers_insert_input[];
  on_conflict?: task_reviewers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviewers". All fields are combined with a logical 'AND'.
 */
export interface task_reviewers_bool_exp {
  _and?: task_reviewers_bool_exp[] | null;
  _not?: task_reviewers_bool_exp | null;
  _or?: task_reviewers_bool_exp[] | null;
  developer?: developers_bool_exp | null;
  developersId?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_reviewers"
 */
export interface task_reviewers_insert_input {
  developer?: developers_obj_rel_insert_input | null;
  developersId?: string | null;
  task?: tasks_obj_rel_insert_input | null;
  tasksId?: number | null;
}

/**
 * on_conflict condition type for table "task_reviewers"
 */
export interface task_reviewers_on_conflict {
  constraint: task_reviewers_constraint;
  update_columns: task_reviewers_update_column[];
  where?: task_reviewers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_reviews"
 */
export interface task_reviews_arr_rel_insert_input {
  data: task_reviews_insert_input[];
  on_conflict?: task_reviews_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviews". All fields are combined with a logical 'AND'.
 */
export interface task_reviews_bool_exp {
  _and?: task_reviews_bool_exp[] | null;
  _not?: task_reviews_bool_exp | null;
  _or?: task_reviews_bool_exp[] | null;
  approvesTaskId?: Int_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dev_task_reviews?: task_review_developer_view_bool_exp | null;
  developer?: developers_bool_exp | null;
  developerByDeveloperid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  further_reviews?: further_reviews_bool_exp | null;
  git_pull_request_review?: git_pull_request_reviews_bool_exp | null;
  git_pull_request_reviews?: git_pull_request_reviews_bool_exp | null;
  id?: Int_comparison_exp | null;
  managerId?: String_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  pullRequestReviewId?: Int_comparison_exp | null;
  reviewerInvoiceId?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: task_reviews_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  task_reviews_status?: task_reviews_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userInvoiceByReviewerinvoiceid?: user_invoices_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
}

/**
 * input type for inserting data into table "task_reviews"
 */
export interface task_reviews_insert_input {
  approvesTaskId?: number | null;
  completedAt?: hasura_timestamptz | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  dev_task_reviews?: task_review_developer_view_obj_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  developerByDeveloperid?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  further_reviews?: further_reviews_arr_rel_insert_input | null;
  git_pull_request_review?: git_pull_request_reviews_obj_rel_insert_input | null;
  git_pull_request_reviews?: git_pull_request_reviews_arr_rel_insert_input | null;
  id?: number | null;
  managerId?: string | null;
  managerInvoiceId?: number | null;
  pullRequestReviewId?: number | null;
  reviewerInvoiceId?: number | null;
  startedAt?: hasura_timestamptz | null;
  status?: task_reviews_status_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  task_reviews_status?: task_reviews_status_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  userInvoiceByReviewerinvoiceid?: user_invoices_obj_rel_insert_input | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "task_reviews"
 */
export interface task_reviews_obj_rel_insert_input {
  data: task_reviews_insert_input;
  on_conflict?: task_reviews_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_reviews"
 */
export interface task_reviews_on_conflict {
  constraint: task_reviews_constraint;
  update_columns: task_reviews_update_column[];
  where?: task_reviews_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviews_status". All fields are combined with a logical 'AND'.
 */
export interface task_reviews_status_bool_exp {
  _and?: task_reviews_status_bool_exp[] | null;
  _not?: task_reviews_status_bool_exp | null;
  _or?: task_reviews_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "task_reviews_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_reviews_status_enum_comparison_exp {
  _eq?: task_reviews_status_enum | null;
  _in?: task_reviews_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_reviews_status_enum | null;
  _nin?: task_reviews_status_enum[] | null;
}

/**
 * input type for inserting data into table "task_reviews_status"
 */
export interface task_reviews_status_insert_input {
  description?: string | null;
  task_reviews?: task_reviews_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "task_reviews_status"
 */
export interface task_reviews_status_obj_rel_insert_input {
  data: task_reviews_status_insert_input;
  on_conflict?: task_reviews_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_reviews_status"
 */
export interface task_reviews_status_on_conflict {
  constraint: task_reviews_status_constraint;
  update_columns: task_reviews_status_update_column[];
  where?: task_reviews_status_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface task_standups_sla_view_bool_exp {
  _and?: task_standups_sla_view_bool_exp[] | null;
  _not?: task_standups_sla_view_bool_exp | null;
  _or?: task_standups_sla_view_bool_exp[] | null;
  dueAt?: timestamptz_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_standups_sla_view"
 */
export interface task_standups_sla_view_insert_input {
  dueAt?: hasura_timestamptz | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
}

/**
 * input type for inserting object relation for remote table "task_standups_sla_view"
 */
export interface task_standups_sla_view_obj_rel_insert_input {
  data: task_standups_sla_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "task_status_changes"
 */
export interface task_status_changes_arr_rel_insert_input {
  data: task_status_changes_insert_input[];
  on_conflict?: task_status_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_status_changes". All fields are combined with a logical 'AND'.
 */
export interface task_status_changes_bool_exp {
  _and?: task_status_changes_bool_exp[] | null;
  _not?: task_status_changes_bool_exp | null;
  _or?: task_status_changes_bool_exp[] | null;
  automaticallyTransioned?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  reasonForTransition?: String_comparison_exp | null;
  status?: tasks_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  tasks_status?: tasks_status_bool_exp | null;
  transitionedAt?: timestamptz_comparison_exp | null;
  transitionedByUserId?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "task_status_changes"
 */
export interface task_status_changes_insert_input {
  automaticallyTransioned?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  id?: hasura_uuid | null;
  reasonForTransition?: string | null;
  status?: tasks_status_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  tasks_status?: tasks_status_obj_rel_insert_input | null;
  transitionedAt?: hasura_timestamptz | null;
  transitionedByUserId?: number | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "task_status_changes"
 */
export interface task_status_changes_on_conflict {
  constraint: task_status_changes_constraint;
  update_columns: task_status_changes_update_column[];
  where?: task_status_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_submissions"
 */
export interface task_submissions_arr_rel_insert_input {
  data: task_submissions_insert_input[];
  on_conflict?: task_submissions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_submissions". All fields are combined with a logical 'AND'.
 */
export interface task_submissions_bool_exp {
  _and?: task_submissions_bool_exp[] | null;
  _not?: task_submissions_bool_exp | null;
  _or?: task_submissions_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gcpObjectId?: String_comparison_exp | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  id?: Int_comparison_exp | null;
  processed?: Boolean_comparison_exp | null;
  syncError?: jsonb_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_submissions"
 */
export interface task_submissions_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gcpObjectId?: string | null;
  gcp_storage_object?: gcp_storage_objects_obj_rel_insert_input | null;
  id?: number | null;
  processed?: boolean | null;
  syncError?: hasura_jsonb | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "task_submissions"
 */
export interface task_submissions_on_conflict {
  constraint: task_submissions_constraint;
  update_columns: task_submissions_update_column[];
  where?: task_submissions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_technologies"
 */
export interface task_technologies_arr_rel_insert_input {
  data: task_technologies_insert_input[];
  on_conflict?: task_technologies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_technologies". All fields are combined with a logical 'AND'.
 */
export interface task_technologies_bool_exp {
  _and?: task_technologies_bool_exp[] | null;
  _not?: task_technologies_bool_exp | null;
  _or?: task_technologies_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
}

/**
 * input type for inserting data into table "task_technologies"
 */
export interface task_technologies_insert_input {
  task?: tasks_obj_rel_insert_input | null;
  tasksId?: number | null;
  technologiesId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "task_technologies"
 */
export interface task_technologies_on_conflict {
  constraint: task_technologies_constraint;
  update_columns: task_technologies_update_column[];
  where?: task_technologies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_time_logs"
 */
export interface task_time_logs_arr_rel_insert_input {
  data: task_time_logs_insert_input[];
  on_conflict?: task_time_logs_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_time_logs". All fields are combined with a logical 'AND'.
 */
export interface task_time_logs_bool_exp {
  CreatedDateColumn?: timestamptz_comparison_exp | null;
  UpdateDateColumn?: timestamptz_comparison_exp | null;
  _and?: task_time_logs_bool_exp[] | null;
  _not?: task_time_logs_bool_exp | null;
  _or?: task_time_logs_bool_exp[] | null;
  developer?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  timeSpent?: Int_comparison_exp | null;
  timedoctorTaskId?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_time_logs"
 */
export interface task_time_logs_insert_input {
  CreatedDateColumn?: hasura_timestamptz | null;
  UpdateDateColumn?: hasura_timestamptz | null;
  developer?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  startedAt?: hasura_timestamptz | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  timeSpent?: number | null;
  timedoctorTaskId?: string | null;
}

/**
 * input type for inserting object relation for remote table "task_time_logs"
 */
export interface task_time_logs_obj_rel_insert_input {
  data: task_time_logs_insert_input;
  on_conflict?: task_time_logs_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_time_logs"
 */
export interface task_time_logs_on_conflict {
  constraint: task_time_logs_constraint;
  update_columns: task_time_logs_update_column[];
  where?: task_time_logs_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "task_time_user_totals"
 */
export interface task_time_user_totals_arr_rel_insert_input {
  data: task_time_user_totals_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "task_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface task_time_user_totals_bool_exp {
  _and?: task_time_user_totals_bool_exp[] | null;
  _not?: task_time_user_totals_bool_exp | null;
  _or?: task_time_user_totals_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_time_user_totals"
 */
export interface task_time_user_totals_insert_input {
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  totalOrganizedTimeInSecs?: hasura_bigint | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting array relation for remote table "task_work"
 */
export interface task_work_arr_rel_insert_input {
  data: task_work_insert_input[];
  on_conflict?: task_work_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "task_work". All fields are combined with a logical 'AND'.
 */
export interface task_work_bool_exp {
  _and?: task_work_bool_exp[] | null;
  _not?: task_work_bool_exp | null;
  _or?: task_work_bool_exp[] | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  developer?: developers_bool_exp | null;
  developerByManagerid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  managerId?: String_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  resourceURL?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: task_work_status_enum_comparison_exp | null;
  task_work_status?: task_work_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userInvoiceByManagerinvoiceid?: user_invoices_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  workerInvoiceId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_work"
 */
export interface task_work_insert_input {
  completedAt?: hasura_timestamptz | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  developer?: developers_obj_rel_insert_input | null;
  developerByManagerid?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  id?: number | null;
  managerId?: string | null;
  managerInvoiceId?: number | null;
  resourceURL?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: task_work_status_enum | null;
  task_work_status?: task_work_status_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  userInvoiceByManagerinvoiceid?: user_invoices_obj_rel_insert_input | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
  workerInvoiceId?: number | null;
}

/**
 * input type for inserting object relation for remote table "task_work"
 */
export interface task_work_obj_rel_insert_input {
  data: task_work_insert_input;
  on_conflict?: task_work_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_work"
 */
export interface task_work_on_conflict {
  constraint: task_work_constraint;
  update_columns: task_work_update_column[];
  where?: task_work_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_work_status". All fields are combined with a logical 'AND'.
 */
export interface task_work_status_bool_exp {
  _and?: task_work_status_bool_exp[] | null;
  _not?: task_work_status_bool_exp | null;
  _or?: task_work_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_works?: task_work_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "task_work_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_work_status_enum_comparison_exp {
  _eq?: task_work_status_enum | null;
  _in?: task_work_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_work_status_enum | null;
  _nin?: task_work_status_enum[] | null;
}

/**
 * input type for inserting data into table "task_work_status"
 */
export interface task_work_status_insert_input {
  description?: string | null;
  task_works?: task_work_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "task_work_status"
 */
export interface task_work_status_obj_rel_insert_input {
  data: task_work_status_insert_input;
  on_conflict?: task_work_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "task_work_status"
 */
export interface task_work_status_on_conflict {
  constraint: task_work_status_constraint;
  update_columns: task_work_status_update_column[];
  where?: task_work_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "tasks"
 */
export interface tasks_arr_rel_insert_input {
  data: tasks_insert_input[];
  on_conflict?: tasks_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'.
 */
export interface tasks_bool_exp {
  _and?: tasks_bool_exp[] | null;
  _not?: tasks_bool_exp | null;
  _or?: tasks_bool_exp[] | null;
  branchName?: String_comparison_exp | null;
  budget?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientCommitDate?: timestamptz_comparison_exp | null;
  clientCommitMessage?: String_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  dev_task?: task_developer_view_bool_exp | null;
  developerByDeveloperid?: developers_bool_exp | null;
  developerByManagerid?: developers_bool_exp | null;
  developerByReviewerid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  developerInvoiceId?: Int_comparison_exp | null;
  discussionSourceByTicketcodeTaskcode?: discussion_sources_bool_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussionSourcesByTaskcodeTaskticketcode?: discussion_sources_bool_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  discussion_sources?: discussion_sources_bool_exp | null;
  discussions_related?: discussions_related_to_tickets_bool_exp | null;
  duplicateOfTask?: tasks_bool_exp | null;
  duplicateOfTaskId?: Int_comparison_exp | null;
  duplicatedTasks?: tasks_bool_exp | null;
  further_reviews?: further_reviews_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_commits?: git_commits_bool_exp | null;
  git_pull_request?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isBillable?: Boolean_comparison_exp | null;
  isParked?: Boolean_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  manager?: users_bool_exp | null;
  managerId?: String_comparison_exp | null;
  managerInvoiceId?: Int_comparison_exp | null;
  maxDuplicateLimit?: Int_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  prLink?: String_comparison_exp | null;
  pull_request?: pull_requests_bool_exp | null;
  pull_request_id?: bigint_comparison_exp | null;
  reviewerId?: String_comparison_exp | null;
  slicedPullRequestId?: Int_comparison_exp | null;
  specLink?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: tasks_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  taskChangesByDependsontasksid?: task_changes_bool_exp | null;
  taskChangesByDuplicateoftaskid?: task_changes_bool_exp | null;
  taskChangesByFollowedbytasksid?: task_changes_bool_exp | null;
  taskCode?: String_comparison_exp | null;
  taskDependenciesByChildTaskId?: task_dependencies_bool_exp | null;
  taskFollowersByTasksid2?: task_followers_bool_exp | null;
  task_attributes?: task_attributes_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_changes_by_status?: task_changes_by_status_bool_exp | null;
  task_client_blockers?: task_client_blockers_bool_exp | null;
  task_credit_approval_changes?: task_credit_approval_changes_bool_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
  task_credit_overrides?: task_credit_overrides_bool_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  task_dependencies?: task_dependencies_bool_exp | null;
  task_followers?: task_followers_bool_exp | null;
  task_reviewers?: task_reviewers_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  task_standups_sla?: task_standups_sla_view_bool_exp | null;
  task_status?: tasks_status_bool_exp | null;
  task_status_changes?: task_status_changes_bool_exp | null;
  task_submissions?: task_submissions_bool_exp | null;
  task_technologies?: task_technologies_bool_exp | null;
  task_time_log?: task_time_logs_bool_exp | null;
  task_time_logs?: task_time_logs_bool_exp | null;
  task_time_user_totals?: task_time_user_totals_bool_exp | null;
  task_type?: tasks_type_bool_exp | null;
  tasks_status?: tasks_status_bool_exp | null;
  tasks_type?: tasks_type_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  timebased?: Boolean_comparison_exp | null;
  title?: String_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  type?: tasks_type_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  userInvoiceByDeveloperinvoiceid?: user_invoices_bool_exp | null;
  user_daily_plan_tasks?: user_daily_plan_tasks_bool_exp | null;
  user_events?: user_events_bool_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "tasks"
 */
export interface tasks_insert_input {
  branchName?: string | null;
  budget?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientCommitDate?: hasura_timestamptz | null;
  clientCommitMessage?: string | null;
  clientId?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  description_type?: description_types_obj_rel_insert_input | null;
  dev_task?: task_developer_view_obj_rel_insert_input | null;
  developerByDeveloperid?: developers_obj_rel_insert_input | null;
  developerByManagerid?: developers_obj_rel_insert_input | null;
  developerByReviewerid?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  developerInvoiceId?: number | null;
  discussionSourceByTicketcodeTaskcode?: discussion_sources_obj_rel_insert_input | null;
  discussionSourceId?: number | null;
  discussionSourcesByTaskcodeTaskticketcode?: discussion_sources_arr_rel_insert_input | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  discussion_sources?: discussion_sources_arr_rel_insert_input | null;
  discussions_related?: discussions_related_to_tickets_arr_rel_insert_input | null;
  duplicateOfTask?: tasks_obj_rel_insert_input | null;
  duplicateOfTaskId?: number | null;
  duplicatedTasks?: tasks_arr_rel_insert_input | null;
  further_reviews?: further_reviews_arr_rel_insert_input | null;
  gitRepoId?: number | null;
  git_commits?: git_commits_arr_rel_insert_input | null;
  git_pull_request?: git_pull_requests_obj_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  id?: number | null;
  isBillable?: boolean | null;
  lowerBudget?: number | null;
  manager?: users_obj_rel_insert_input | null;
  managerId?: string | null;
  managerInvoiceId?: number | null;
  maxDuplicateLimit?: number | null;
  notifications?: notifications_arr_rel_insert_input | null;
  prLink?: string | null;
  pull_request?: pull_requests_obj_rel_insert_input | null;
  pull_request_id?: hasura_bigint | null;
  reviewerId?: string | null;
  slicedPullRequestId?: number | null;
  specLink?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: tasks_status_enum | null;
  statusUpdateReason?: string | null;
  taskChangesByDependsontasksid?: task_changes_arr_rel_insert_input | null;
  taskChangesByDuplicateoftaskid?: task_changes_arr_rel_insert_input | null;
  taskChangesByFollowedbytasksid?: task_changes_arr_rel_insert_input | null;
  taskCode?: string | null;
  taskDependenciesByChildTaskId?: task_dependencies_arr_rel_insert_input | null;
  taskFollowersByTasksid2?: task_followers_arr_rel_insert_input | null;
  task_attributes?: task_attributes_obj_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_changes_by_status?: task_changes_by_status_arr_rel_insert_input | null;
  task_client_blockers?: task_client_blockers_arr_rel_insert_input | null;
  task_credit_approval_changes?: task_credit_approval_changes_arr_rel_insert_input | null;
  task_credit_approvals?: task_credit_approval_arr_rel_insert_input | null;
  task_credit_overrides?: task_credit_overrides_arr_rel_insert_input | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  task_dependencies?: task_dependencies_arr_rel_insert_input | null;
  task_followers?: task_followers_arr_rel_insert_input | null;
  task_reviewers?: task_reviewers_arr_rel_insert_input | null;
  task_reviews?: task_reviews_arr_rel_insert_input | null;
  task_standups_sla?: task_standups_sla_view_obj_rel_insert_input | null;
  task_status?: tasks_status_obj_rel_insert_input | null;
  task_status_changes?: task_status_changes_arr_rel_insert_input | null;
  task_submissions?: task_submissions_arr_rel_insert_input | null;
  task_technologies?: task_technologies_arr_rel_insert_input | null;
  task_time_log?: task_time_logs_obj_rel_insert_input | null;
  task_time_logs?: task_time_logs_arr_rel_insert_input | null;
  task_time_user_totals?: task_time_user_totals_arr_rel_insert_input | null;
  task_type?: tasks_type_obj_rel_insert_input | null;
  tasks_status?: tasks_status_obj_rel_insert_input | null;
  tasks_type?: tasks_type_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  timebased?: boolean | null;
  title?: string | null;
  type?: tasks_type_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  userInvoiceByDeveloperinvoiceid?: user_invoices_obj_rel_insert_input | null;
  user_daily_plan_tasks?: user_daily_plan_tasks_arr_rel_insert_input | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "tasks"
 */
export interface tasks_obj_rel_insert_input {
  data: tasks_insert_input;
  on_conflict?: tasks_on_conflict | null;
}

/**
 * on_conflict condition type for table "tasks"
 */
export interface tasks_on_conflict {
  constraint: tasks_constraint;
  update_columns: tasks_update_column[];
  where?: tasks_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "tasks_status". All fields are combined with a logical 'AND'.
 */
export interface tasks_status_bool_exp {
  _and?: tasks_status_bool_exp[] | null;
  _not?: tasks_status_bool_exp | null;
  _or?: tasks_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_status_changes?: task_status_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "tasks_status_enum". All fields are combined with logical 'AND'.
 */
export interface tasks_status_enum_comparison_exp {
  _eq?: tasks_status_enum | null;
  _in?: tasks_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tasks_status_enum | null;
  _nin?: tasks_status_enum[] | null;
}

/**
 * input type for inserting data into table "tasks_status"
 */
export interface tasks_status_insert_input {
  description?: string | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_status_changes?: task_status_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "tasks_status"
 */
export interface tasks_status_obj_rel_insert_input {
  data: tasks_status_insert_input;
  on_conflict?: tasks_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "tasks_status"
 */
export interface tasks_status_on_conflict {
  constraint: tasks_status_constraint;
  update_columns: tasks_status_update_column[];
  where?: tasks_status_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "tasks_type". All fields are combined with a logical 'AND'.
 */
export interface tasks_type_bool_exp {
  _and?: tasks_type_bool_exp[] | null;
  _not?: tasks_type_bool_exp | null;
  _or?: tasks_type_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_changes?: task_changes_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "tasks_type_enum". All fields are combined with logical 'AND'.
 */
export interface tasks_type_enum_comparison_exp {
  _eq?: tasks_type_enum | null;
  _in?: tasks_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tasks_type_enum | null;
  _nin?: tasks_type_enum[] | null;
}

/**
 * input type for inserting data into table "tasks_type"
 */
export interface tasks_type_insert_input {
  description?: string | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "tasks_type"
 */
export interface tasks_type_obj_rel_insert_input {
  data: tasks_type_insert_input;
  on_conflict?: tasks_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "tasks_type"
 */
export interface tasks_type_on_conflict {
  constraint: tasks_type_constraint;
  update_columns: tasks_type_update_column[];
  where?: tasks_type_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "technologies". All fields are combined with a logical 'AND'.
 */
export interface technologies_bool_exp {
  _and?: technologies_bool_exp[] | null;
  _not?: technologies_bool_exp | null;
  _or?: technologies_bool_exp[] | null;
  client_project_changes?: client_project_changes_bool_exp | null;
  client_project_technologies?: client_project_technologies_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  optional_technologies?: optional_technologies_bool_exp | null;
  qualified_technologies?: qualified_technologies_bool_exp | null;
  skills?: skills_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_technologies?: task_technologies_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "technologies"
 */
export interface technologies_insert_input {
  client_project_changes?: client_project_changes_arr_rel_insert_input | null;
  client_project_technologies?: client_project_technologies_arr_rel_insert_input | null;
  id?: number | null;
  name?: string | null;
  optional_technologies?: optional_technologies_arr_rel_insert_input | null;
  qualified_technologies?: qualified_technologies_arr_rel_insert_input | null;
  skills?: skills_arr_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_technologies?: task_technologies_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "technologies"
 */
export interface technologies_obj_rel_insert_input {
  data: technologies_insert_input;
  on_conflict?: technologies_on_conflict | null;
}

/**
 * on_conflict condition type for table "technologies"
 */
export interface technologies_on_conflict {
  constraint: technologies_constraint;
  update_columns: technologies_update_column[];
  where?: technologies_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_attachments"
 */
export interface ticket_attachments_arr_rel_insert_input {
  data: ticket_attachments_insert_input[];
  on_conflict?: ticket_attachments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_attachments". All fields are combined with a logical 'AND'.
 */
export interface ticket_attachments_bool_exp {
  _and?: ticket_attachments_bool_exp[] | null;
  _not?: ticket_attachments_bool_exp | null;
  _or?: ticket_attachments_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gcpObjectId?: String_comparison_exp | null;
  gcp_storage_object?: gcp_storage_objects_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_attachments"
 */
export interface ticket_attachments_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  gcpObjectId?: string | null;
  gcp_storage_object?: gcp_storage_objects_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "ticket_attachments"
 */
export interface ticket_attachments_on_conflict {
  constraint: ticket_attachments_constraint;
  update_columns: ticket_attachments_update_column[];
  where?: ticket_attachments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_arr_rel_insert_input {
  data: ticket_billable_overrides_insert_input[];
  on_conflict?: ticket_billable_overrides_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_billable_overrides". All fields are combined with a logical 'AND'.
 */
export interface ticket_billable_overrides_bool_exp {
  _and?: ticket_billable_overrides_bool_exp[] | null;
  _not?: ticket_billable_overrides_bool_exp | null;
  _or?: ticket_billable_overrides_bool_exp[] | null;
  billableAt?: timestamptz_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_insert_input {
  billableAt?: hasura_timestamptz | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  reason?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "ticket_billable_overrides"
 */
export interface ticket_billable_overrides_on_conflict {
  constraint: ticket_billable_overrides_constraint;
  update_columns: ticket_billable_overrides_update_column[];
  where?: ticket_billable_overrides_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_changes"
 */
export interface ticket_changes_arr_rel_insert_input {
  data: ticket_changes_insert_input[];
  on_conflict?: ticket_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_changes". All fields are combined with a logical 'AND'.
 */
export interface ticket_changes_bool_exp {
  _and?: ticket_changes_bool_exp[] | null;
  _not?: ticket_changes_bool_exp | null;
  _or?: ticket_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  cancelledAt?: timestamptz_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientBlockersId?: Int_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_blocker?: client_blockers_bool_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  code?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  developer?: developers_bool_exp | null;
  discount?: Int_comparison_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  gitRepoByGitrepoid?: git_repos_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  githubIssueSid?: String_comparison_exp | null;
  github_issue?: github_issues_bool_exp | null;
  gitlabIssueSid?: String_comparison_exp | null;
  gitlab_issue?: gitlab_issues_bool_exp | null;
  id?: Int_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  isSynced?: Boolean_comparison_exp | null;
  jiraIssueSid?: String_comparison_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  linearIssueSid?: String_comparison_exp | null;
  linear_issue?: linear_issues_bool_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  managerId?: String_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  priority?: Int_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  reposId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: ticket_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  statusUpdatedAt?: timestamptz_comparison_exp | null;
  status_v2?: String_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketLink?: String_comparison_exp | null;
  ticketSourceId?: Int_comparison_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_status?: ticket_status_bool_exp | null;
  tickets_type?: tickets_types_bool_exp | null;
  title?: String_comparison_exp | null;
  type?: tickets_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_arr_rel_insert_input {
  data: ticket_changes_by_status_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "ticket_changes_by_status". All fields are combined with a logical 'AND'.
 */
export interface ticket_changes_by_status_bool_exp {
  _and?: ticket_changes_by_status_bool_exp[] | null;
  _not?: ticket_changes_by_status_bool_exp | null;
  _or?: ticket_changes_by_status_bool_exp[] | null;
  clientId?: String_comparison_exp | null;
  code?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  transitionEndAt?: timestamptz_comparison_exp | null;
  transitionedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_changes_by_status"
 */
export interface ticket_changes_by_status_insert_input {
  clientId?: string | null;
  code?: string | null;
  id?: number | null;
  status?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  transitionEndAt?: hasura_timestamptz | null;
  transitionedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting data into table "ticket_changes"
 */
export interface ticket_changes_insert_input {
  automaticallyChanged?: boolean | null;
  cancelledAt?: hasura_timestamptz | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientBlockersId?: number | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  code?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  description_type?: description_types_obj_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  discount?: number | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  gitRepoByGitrepoid?: git_repos_obj_rel_insert_input | null;
  gitRepoId?: number | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  githubIssueSid?: string | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  gitlabIssueSid?: string | null;
  gitlab_issue?: gitlab_issues_obj_rel_insert_input | null;
  id?: number | null;
  invoiceId?: number | null;
  isInternal?: boolean | null;
  isSynced?: boolean | null;
  jiraIssueSid?: string | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  linearIssueSid?: string | null;
  linear_issue?: linear_issues_obj_rel_insert_input | null;
  lowerBudget?: number | null;
  managerId?: string | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  priority?: number | null;
  reasonForChange?: string | null;
  reposId?: number | null;
  sqlQuery?: string | null;
  startedAt?: hasura_timestamptz | null;
  status?: ticket_status_enum | null;
  statusUpdateReason?: string | null;
  statusUpdatedAt?: hasura_timestamptz | null;
  status_v2?: string | null;
  syncedAt?: hasura_timestamptz | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketLink?: string | null;
  ticketSourceId?: number | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_status?: ticket_status_obj_rel_insert_input | null;
  tickets_type?: tickets_types_obj_rel_insert_input | null;
  title?: string | null;
  type?: tickets_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  user?: users_obj_rel_insert_input | null;
  userTeamId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "ticket_changes"
 */
export interface ticket_changes_obj_rel_insert_input {
  data: ticket_changes_insert_input;
  on_conflict?: ticket_changes_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_changes"
 */
export interface ticket_changes_on_conflict {
  constraint: ticket_changes_constraint;
  update_columns: ticket_changes_update_column[];
  where?: ticket_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_client_blockers"
 */
export interface ticket_client_blockers_arr_rel_insert_input {
  data: ticket_client_blockers_insert_input[];
  on_conflict?: ticket_client_blockers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_client_blockers". All fields are combined with a logical 'AND'.
 */
export interface ticket_client_blockers_bool_exp {
  _and?: ticket_client_blockers_bool_exp[] | null;
  _not?: ticket_client_blockers_bool_exp | null;
  _or?: ticket_client_blockers_bool_exp[] | null;
  clientBlockersId?: Int_comparison_exp | null;
  client_blocker?: client_blockers_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketsId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_client_blockers"
 */
export interface ticket_client_blockers_insert_input {
  clientBlockersId?: number | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketsId?: number | null;
}

/**
 * on_conflict condition type for table "ticket_client_blockers"
 */
export interface ticket_client_blockers_on_conflict {
  constraint: ticket_client_blockers_constraint;
  update_columns: ticket_client_blockers_update_column[];
  where?: ticket_client_blockers_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_client_view". All fields are combined with a logical 'AND'.
 */
export interface ticket_client_view_bool_exp {
  _and?: ticket_client_view_bool_exp[] | null;
  _not?: ticket_client_view_bool_exp | null;
  _or?: ticket_client_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_client_view"
 */
export interface ticket_client_view_insert_input {
  id?: number | null;
  lowerBudget?: number | null;
  tickets?: tickets_obj_rel_insert_input | null;
  upperBudget?: number | null;
}

/**
 * input type for inserting object relation for remote table "ticket_client_view"
 */
export interface ticket_client_view_obj_rel_insert_input {
  data: ticket_client_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "ticket_credit_approval_changes"
 */
export interface ticket_credit_approval_changes_arr_rel_insert_input {
  data: ticket_credit_approval_changes_insert_input[];
  on_conflict?: ticket_credit_approval_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approval_changes". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approval_changes_bool_exp {
  _and?: ticket_credit_approval_changes_bool_exp[] | null;
  _not?: ticket_credit_approval_changes_bool_exp | null;
  _or?: ticket_credit_approval_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reason?: String_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  status?: ticket_credit_status_enum_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticket_credit_status?: ticket_credit_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userByRequestedbyuserid?: users_bool_exp | null;
  userByReviewedbyuserid?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_credit_approval_changes"
 */
export interface ticket_credit_approval_changes_insert_input {
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reason?: string | null;
  reasonForChange?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  sqlQuery?: string | null;
  status?: ticket_credit_status_enum | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  ticket_credit_status?: ticket_credit_status_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  user?: users_obj_rel_insert_input | null;
  userByRequestedbyuserid?: users_obj_rel_insert_input | null;
  userByReviewedbyuserid?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "ticket_credit_approval_changes"
 */
export interface ticket_credit_approval_changes_on_conflict {
  constraint: ticket_credit_approval_changes_constraint;
  update_columns: ticket_credit_approval_changes_update_column[];
  where?: ticket_credit_approval_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_credit_approval_details"
 */
export interface ticket_credit_approval_details_arr_rel_insert_input {
  data: ticket_credit_approval_details_insert_input[];
  on_conflict?: ticket_credit_approval_details_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approval_details". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approval_details_bool_exp {
  _and?: ticket_credit_approval_details_bool_exp[] | null;
  _not?: ticket_credit_approval_details_bool_exp | null;
  _or?: ticket_credit_approval_details_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  taskCreditApprovalId?: Int_comparison_exp | null;
  task_credit_approval?: task_credit_approval_bool_exp | null;
  ticketCreditApprovalId?: Int_comparison_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_credit_approval_details"
 */
export interface ticket_credit_approval_details_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  taskCreditApprovalId?: number | null;
  task_credit_approval?: task_credit_approval_obj_rel_insert_input | null;
  ticketCreditApprovalId?: number | null;
  ticket_credit_approval?: ticket_credit_approvals_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "ticket_credit_approval_details"
 */
export interface ticket_credit_approval_details_on_conflict {
  constraint: ticket_credit_approval_details_constraint;
  update_columns: ticket_credit_approval_details_update_column[];
  where?: ticket_credit_approval_details_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_arr_rel_insert_input {
  data: ticket_credit_approvals_insert_input[];
  on_conflict?: ticket_credit_approvals_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approvals". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approvals_bool_exp {
  _and?: ticket_credit_approvals_bool_exp[] | null;
  _not?: ticket_credit_approvals_bool_exp | null;
  _or?: ticket_credit_approvals_bool_exp[] | null;
  applicant?: users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  reviewer?: users_bool_exp | null;
  status?: ticket_credit_status_enum_comparison_exp | null;
  task_credit_approval_changes?: task_credit_approval_changes_bool_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticket_credit_approval_details?: ticket_credit_approval_details_bool_exp | null;
  ticket_credit_status?: ticket_credit_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_insert_input {
  applicant?: users_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  reviewer?: users_obj_rel_insert_input | null;
  status?: ticket_credit_status_enum | null;
  task_credit_approval_changes?: task_credit_approval_changes_arr_rel_insert_input | null;
  task_credit_approvals?: task_credit_approval_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  ticket_credit_approval_details?: ticket_credit_approval_details_arr_rel_insert_input | null;
  ticket_credit_status?: ticket_credit_status_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * input type for inserting object relation for remote table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_obj_rel_insert_input {
  data: ticket_credit_approvals_insert_input;
  on_conflict?: ticket_credit_approvals_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_on_conflict {
  constraint: ticket_credit_approvals_constraint;
  update_columns: ticket_credit_approvals_update_column[];
  where?: ticket_credit_approvals_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approvals_parsed". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approvals_parsed_bool_exp {
  _and?: ticket_credit_approvals_parsed_bool_exp[] | null;
  _not?: ticket_credit_approvals_parsed_bool_exp | null;
  _or?: ticket_credit_approvals_parsed_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  need_approval?: Boolean_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_credit_approvals_parsed"
 */
export interface ticket_credit_approvals_parsed_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  need_approval?: boolean | null;
  reason?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  status?: string | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * input type for inserting object relation for remote table "ticket_credit_approvals_parsed"
 */
export interface ticket_credit_approvals_parsed_obj_rel_insert_input {
  data: ticket_credit_approvals_parsed_insert_input;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_status". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_status_bool_exp {
  _and?: ticket_credit_status_bool_exp[] | null;
  _not?: ticket_credit_status_bool_exp | null;
  _or?: ticket_credit_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  ticket_credit_approval_changes?: ticket_credit_approval_changes_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "ticket_credit_status_enum". All fields are combined with logical 'AND'.
 */
export interface ticket_credit_status_enum_comparison_exp {
  _eq?: ticket_credit_status_enum | null;
  _in?: ticket_credit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: ticket_credit_status_enum | null;
  _nin?: ticket_credit_status_enum[] | null;
}

/**
 * input type for inserting data into table "ticket_credit_status"
 */
export interface ticket_credit_status_insert_input {
  description?: string | null;
  id?: string | null;
  ticket_credit_approval_changes?: ticket_credit_approval_changes_arr_rel_insert_input | null;
  ticket_credit_approvals?: ticket_credit_approvals_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "ticket_credit_status"
 */
export interface ticket_credit_status_obj_rel_insert_input {
  data: ticket_credit_status_insert_input;
  on_conflict?: ticket_credit_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_credit_status"
 */
export interface ticket_credit_status_on_conflict {
  constraint: ticket_credit_status_constraint;
  update_columns: ticket_credit_status_update_column[];
  where?: ticket_credit_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_daily_standups"
 */
export interface ticket_daily_standups_arr_rel_insert_input {
  data: ticket_daily_standups_insert_input[];
  on_conflict?: ticket_daily_standups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface ticket_daily_standups_bool_exp {
  _and?: ticket_daily_standups_bool_exp[] | null;
  _not?: ticket_daily_standups_bool_exp | null;
  _or?: ticket_daily_standups_bool_exp[] | null;
  client_daily_standup?: client_daily_standups_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  message?: String_comparison_exp | null;
  project_daily_standup?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  summarizedInClientDailyStandupId?: Int_comparison_exp | null;
  summarizedInProjectDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedETA?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_daily_standups"
 */
export interface ticket_daily_standups_insert_input {
  client_daily_standup?: client_daily_standups_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  message?: string | null;
  project_daily_standup?: project_daily_standup_obj_rel_insert_input | null;
  submittedAt?: string | null;
  summarizedInClientDailyStandupId?: number | null;
  summarizedInProjectDailyStandupId?: number | null;
  summarizedInUserDailyStandupId?: number | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  updatedETA?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_daily_standup?: user_daily_standups_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "ticket_daily_standups"
 */
export interface ticket_daily_standups_obj_rel_insert_input {
  data: ticket_daily_standups_insert_input;
  on_conflict?: ticket_daily_standups_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_daily_standups"
 */
export interface ticket_daily_standups_on_conflict {
  constraint: ticket_daily_standups_constraint;
  update_columns: ticket_daily_standups_update_column[];
  where?: ticket_daily_standups_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_developer_view". All fields are combined with a logical 'AND'.
 */
export interface ticket_developer_view_bool_exp {
  _and?: ticket_developer_view_bool_exp[] | null;
  _not?: ticket_developer_view_bool_exp | null;
  _or?: ticket_developer_view_bool_exp[] | null;
  githubIssueSid?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  jiraIssueSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_developer_view"
 */
export interface ticket_developer_view_insert_input {
  githubIssueSid?: string | null;
  id?: number | null;
  jiraIssueSid?: string | null;
}

/**
 * input type for inserting object relation for remote table "ticket_developer_view"
 */
export interface ticket_developer_view_obj_rel_insert_input {
  data: ticket_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "ticket_intentions"
 */
export interface ticket_intentions_arr_rel_insert_input {
  data: ticket_intentions_insert_input[];
  on_conflict?: ticket_intentions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_intentions". All fields are combined with a logical 'AND'.
 */
export interface ticket_intentions_bool_exp {
  _and?: ticket_intentions_bool_exp[] | null;
  _not?: ticket_intentions_bool_exp | null;
  _or?: ticket_intentions_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticketIntentionType?: String_comparison_exp | null;
  tickets_intentions_type?: ticket_intentions_type_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_intentions"
 */
export interface ticket_intentions_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  ticketIntentionType?: string | null;
  tickets_intentions_type?: ticket_intentions_type_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "ticket_intentions"
 */
export interface ticket_intentions_on_conflict {
  constraint: ticket_intentions_constraint;
  update_columns: ticket_intentions_update_column[];
  where?: ticket_intentions_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_intentions_type". All fields are combined with a logical 'AND'.
 */
export interface ticket_intentions_type_bool_exp {
  _and?: ticket_intentions_type_bool_exp[] | null;
  _not?: ticket_intentions_type_bool_exp | null;
  _or?: ticket_intentions_type_bool_exp[] | null;
  description?: String_comparison_exp | null;
  ticket_intentions?: ticket_intentions_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_intentions_type"
 */
export interface ticket_intentions_type_insert_input {
  description?: string | null;
  ticket_intentions?: ticket_intentions_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "ticket_intentions_type"
 */
export interface ticket_intentions_type_obj_rel_insert_input {
  data: ticket_intentions_type_insert_input;
  on_conflict?: ticket_intentions_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_intentions_type"
 */
export interface ticket_intentions_type_on_conflict {
  constraint: ticket_intentions_type_constraint;
  update_columns: ticket_intentions_type_update_column[];
  where?: ticket_intentions_type_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_means"
 */
export interface ticket_means_arr_rel_insert_input {
  data: ticket_means_insert_input[];
  on_conflict?: ticket_means_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_means". All fields are combined with a logical 'AND'.
 */
export interface ticket_means_bool_exp {
  _and?: ticket_means_bool_exp[] | null;
  _not?: ticket_means_bool_exp | null;
  _or?: ticket_means_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticketMeansType?: String_comparison_exp | null;
  ticket_means_type?: ticket_means_type_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_means"
 */
export interface ticket_means_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  ticketMeansType?: string | null;
  ticket_means_type?: ticket_means_type_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "ticket_means"
 */
export interface ticket_means_on_conflict {
  constraint: ticket_means_constraint;
  update_columns: ticket_means_update_column[];
  where?: ticket_means_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_means_type". All fields are combined with a logical 'AND'.
 */
export interface ticket_means_type_bool_exp {
  _and?: ticket_means_type_bool_exp[] | null;
  _not?: ticket_means_type_bool_exp | null;
  _or?: ticket_means_type_bool_exp[] | null;
  description?: String_comparison_exp | null;
  ticket_means?: ticket_means_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_means_type"
 */
export interface ticket_means_type_insert_input {
  description?: string | null;
  ticket_means?: ticket_means_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "ticket_means_type"
 */
export interface ticket_means_type_obj_rel_insert_input {
  data: ticket_means_type_insert_input;
  on_conflict?: ticket_means_type_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_means_type"
 */
export interface ticket_means_type_on_conflict {
  constraint: ticket_means_type_constraint;
  update_columns: ticket_means_type_update_column[];
  where?: ticket_means_type_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_refund"
 */
export interface ticket_refund_arr_rel_insert_input {
  data: ticket_refund_insert_input[];
  on_conflict?: ticket_refund_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_refund". All fields are combined with a logical 'AND'.
 */
export interface ticket_refund_bool_exp {
  _and?: ticket_refund_bool_exp[] | null;
  _not?: ticket_refund_bool_exp | null;
  _or?: ticket_refund_bool_exp[] | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  issuedByUserId?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_refund"
 */
export interface ticket_refund_insert_input {
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  issuedByUserId?: number | null;
  reason?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "ticket_refund"
 */
export interface ticket_refund_on_conflict {
  constraint: ticket_refund_constraint;
  update_columns: ticket_refund_update_column[];
  where?: ticket_refund_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_repos"
 */
export interface ticket_repos_arr_rel_insert_input {
  data: ticket_repos_insert_input[];
  on_conflict?: ticket_repos_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_repos". All fields are combined with a logical 'AND'.
 */
export interface ticket_repos_bool_exp {
  _and?: ticket_repos_bool_exp[] | null;
  _not?: ticket_repos_bool_exp | null;
  _or?: ticket_repos_bool_exp[] | null;
  git_repo?: git_repos_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_repos"
 */
export interface ticket_repos_insert_input {
  git_repo?: git_repos_obj_rel_insert_input | null;
  repoId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
}

/**
 * on_conflict condition type for table "ticket_repos"
 */
export interface ticket_repos_on_conflict {
  constraint: ticket_repos_constraint;
  update_columns: ticket_repos_update_column[];
  where?: ticket_repos_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_scope_change_request_states". All fields are combined with a logical 'AND'.
 */
export interface ticket_scope_change_request_states_bool_exp {
  _and?: ticket_scope_change_request_states_bool_exp[] | null;
  _not?: ticket_scope_change_request_states_bool_exp | null;
  _or?: ticket_scope_change_request_states_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  ticket_scope_change_requests?: ticket_scope_change_requests_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_scope_change_request_states"
 */
export interface ticket_scope_change_request_states_insert_input {
  description?: string | null;
  id?: string | null;
  ticket_scope_change_requests?: ticket_scope_change_requests_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "ticket_scope_change_request_states"
 */
export interface ticket_scope_change_request_states_obj_rel_insert_input {
  data: ticket_scope_change_request_states_insert_input;
  on_conflict?: ticket_scope_change_request_states_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_scope_change_request_states"
 */
export interface ticket_scope_change_request_states_on_conflict {
  constraint: ticket_scope_change_request_states_constraint;
  update_columns: ticket_scope_change_request_states_update_column[];
  where?: ticket_scope_change_request_states_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_scope_change_requests"
 */
export interface ticket_scope_change_requests_arr_rel_insert_input {
  data: ticket_scope_change_requests_insert_input[];
  on_conflict?: ticket_scope_change_requests_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_scope_change_requests". All fields are combined with a logical 'AND'.
 */
export interface ticket_scope_change_requests_bool_exp {
  _and?: ticket_scope_change_requests_bool_exp[] | null;
  _not?: ticket_scope_change_requests_bool_exp | null;
  _or?: ticket_scope_change_requests_bool_exp[] | null;
  appliedAt?: timestamptz_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  createdByUserId?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  state?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticket_scope_change_request_state?: ticket_scope_change_request_states_bool_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_scope_change_requests"
 */
export interface ticket_scope_change_requests_insert_input {
  appliedAt?: hasura_timestamptz | null;
  createdAt?: hasura_timestamptz | null;
  createdByUserId?: number | null;
  deletedAt?: hasura_timestamptz | null;
  description?: string | null;
  id?: number | null;
  state?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  ticket_scope_change_request_state?: ticket_scope_change_request_states_obj_rel_insert_input | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "ticket_scope_change_requests"
 */
export interface ticket_scope_change_requests_on_conflict {
  constraint: ticket_scope_change_requests_constraint;
  update_columns: ticket_scope_change_requests_update_column[];
  where?: ticket_scope_change_requests_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_source_changes"
 */
export interface ticket_source_changes_arr_rel_insert_input {
  data: ticket_source_changes_insert_input[];
  on_conflict?: ticket_source_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_source_changes". All fields are combined with a logical 'AND'.
 */
export interface ticket_source_changes_bool_exp {
  _and?: ticket_source_changes_bool_exp[] | null;
  _not?: ticket_source_changes_bool_exp | null;
  _or?: ticket_source_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditsToStoryPoints?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitRepoByGitrepoid?: git_repos_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  githubRepoLabelSid?: String_comparison_exp | null;
  github_repo_label?: github_repo_labels_bool_exp | null;
  gitlabAssigneeSid?: String_comparison_exp | null;
  gitlabProjectSid?: String_comparison_exp | null;
  gitlab_project?: gitlab_projects_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  jiraAssigneeSid?: String_comparison_exp | null;
  jiraDescriptionFieldId?: uuid_comparison_exp | null;
  jiraIssueFieldByJiradescriptionfieldid?: jira_issue_fields_bool_exp | null;
  jiraProjectSid?: String_comparison_exp | null;
  jiraStoryPointKey?: uuid_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_issue_field?: jira_issue_fields_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  linearTeamSid?: String_comparison_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  operation?: String_comparison_exp | null;
  prefix?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  projectName?: String_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_source_changes"
 */
export interface ticket_source_changes_insert_input {
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creditsToStoryPoints?: number | null;
  deletedAt?: hasura_timestamptz | null;
  gitRepoByGitrepoid?: git_repos_obj_rel_insert_input | null;
  gitRepoId?: number | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  githubRepoLabelSid?: string | null;
  github_repo_label?: github_repo_labels_obj_rel_insert_input | null;
  gitlabAssigneeSid?: string | null;
  gitlabProjectSid?: string | null;
  gitlab_project?: gitlab_projects_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  id?: number | null;
  jiraAssigneeSid?: string | null;
  jiraDescriptionFieldId?: hasura_uuid | null;
  jiraIssueFieldByJiradescriptionfieldid?: jira_issue_fields_obj_rel_insert_input | null;
  jiraProjectSid?: string | null;
  jiraStoryPointKey?: hasura_uuid | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_issue_field?: jira_issue_fields_obj_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  linearTeamSid?: string | null;
  linear_team?: linear_teams_obj_rel_insert_input | null;
  operation?: string | null;
  prefix?: string | null;
  previousChangeId?: hasura_uuid | null;
  projectName?: string | null;
  reasonForChange?: string | null;
  repoId?: number | null;
  sqlQuery?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "ticket_source_changes"
 */
export interface ticket_source_changes_on_conflict {
  constraint: ticket_source_changes_constraint;
  update_columns: ticket_source_changes_update_column[];
  where?: ticket_source_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_source_pull_strategies"
 */
export interface ticket_source_pull_strategies_arr_rel_insert_input {
  data: ticket_source_pull_strategies_insert_input[];
  on_conflict?: ticket_source_pull_strategies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_source_pull_strategies". All fields are combined with a logical 'AND'.
 */
export interface ticket_source_pull_strategies_bool_exp {
  _and?: ticket_source_pull_strategies_bool_exp[] | null;
  _not?: ticket_source_pull_strategies_bool_exp | null;
  _or?: ticket_source_pull_strategies_bool_exp[] | null;
  assignedById?: String_comparison_exp | null;
  assignee?: String_comparison_exp | null;
  commentById?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  labels?: _text_comparison_exp | null;
  priority?: jsonb_comparison_exp | null;
  ticketSourceId?: Int_comparison_exp | null;
  ticketSourcePullStrategyTypeByCommentbyid?: ticket_source_pull_strategy_types_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_source_pull_strategy_type?: ticket_source_pull_strategy_types_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_source_pull_strategies"
 */
export interface ticket_source_pull_strategies_insert_input {
  assignedById?: string | null;
  assignee?: string | null;
  commentById?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  labels?: hasura__text | null;
  priority?: hasura_jsonb | null;
  ticketSourceId?: number | null;
  ticketSourcePullStrategyTypeByCommentbyid?: ticket_source_pull_strategy_types_obj_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_source_pull_strategy_type?: ticket_source_pull_strategy_types_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "ticket_source_pull_strategies"
 */
export interface ticket_source_pull_strategies_on_conflict {
  constraint: ticket_source_pull_strategies_constraint;
  update_columns: ticket_source_pull_strategies_update_column[];
  where?: ticket_source_pull_strategies_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_source_pull_strategy_types". All fields are combined with a logical 'AND'.
 */
export interface ticket_source_pull_strategy_types_bool_exp {
  _and?: ticket_source_pull_strategy_types_bool_exp[] | null;
  _not?: ticket_source_pull_strategy_types_bool_exp | null;
  _or?: ticket_source_pull_strategy_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  ticketSourcePullStrategiesByCommentbyid?: ticket_source_pull_strategies_bool_exp | null;
  ticket_source_pull_strategies?: ticket_source_pull_strategies_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_source_pull_strategy_types"
 */
export interface ticket_source_pull_strategy_types_insert_input {
  description?: string | null;
  id?: string | null;
  ticketSourcePullStrategiesByCommentbyid?: ticket_source_pull_strategies_arr_rel_insert_input | null;
  ticket_source_pull_strategies?: ticket_source_pull_strategies_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "ticket_source_pull_strategy_types"
 */
export interface ticket_source_pull_strategy_types_obj_rel_insert_input {
  data: ticket_source_pull_strategy_types_insert_input;
  on_conflict?: ticket_source_pull_strategy_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_source_pull_strategy_types"
 */
export interface ticket_source_pull_strategy_types_on_conflict {
  constraint: ticket_source_pull_strategy_types_constraint;
  update_columns: ticket_source_pull_strategy_types_update_column[];
  where?: ticket_source_pull_strategy_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_source_push_strategies"
 */
export interface ticket_source_push_strategies_arr_rel_insert_input {
  data: ticket_source_push_strategies_insert_input[];
  on_conflict?: ticket_source_push_strategies_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_source_push_strategies". All fields are combined with a logical 'AND'.
 */
export interface ticket_source_push_strategies_bool_exp {
  _and?: ticket_source_push_strategies_bool_exp[] | null;
  _not?: ticket_source_push_strategies_bool_exp | null;
  _or?: ticket_source_push_strategies_bool_exp[] | null;
  commentById?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  labels?: _text_comparison_exp | null;
  ticketSourceId?: Int_comparison_exp | null;
  ticketStatusMap?: jsonb_comparison_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_source_push_strategy_type?: ticket_source_push_strategy_types_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_source_push_strategies"
 */
export interface ticket_source_push_strategies_insert_input {
  commentById?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  labels?: hasura__text | null;
  ticketSourceId?: number | null;
  ticketStatusMap?: hasura_jsonb | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_source_push_strategy_type?: ticket_source_push_strategy_types_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * on_conflict condition type for table "ticket_source_push_strategies"
 */
export interface ticket_source_push_strategies_on_conflict {
  constraint: ticket_source_push_strategies_constraint;
  update_columns: ticket_source_push_strategies_update_column[];
  where?: ticket_source_push_strategies_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_source_push_strategy_types". All fields are combined with a logical 'AND'.
 */
export interface ticket_source_push_strategy_types_bool_exp {
  _and?: ticket_source_push_strategy_types_bool_exp[] | null;
  _not?: ticket_source_push_strategy_types_bool_exp | null;
  _or?: ticket_source_push_strategy_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  ticket_source_push_strategies?: ticket_source_push_strategies_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_source_push_strategy_types"
 */
export interface ticket_source_push_strategy_types_insert_input {
  description?: string | null;
  id?: string | null;
  ticket_source_push_strategies?: ticket_source_push_strategies_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "ticket_source_push_strategy_types"
 */
export interface ticket_source_push_strategy_types_obj_rel_insert_input {
  data: ticket_source_push_strategy_types_insert_input;
  on_conflict?: ticket_source_push_strategy_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_source_push_strategy_types"
 */
export interface ticket_source_push_strategy_types_on_conflict {
  constraint: ticket_source_push_strategy_types_constraint;
  update_columns: ticket_source_push_strategy_types_update_column[];
  where?: ticket_source_push_strategy_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_sources"
 */
export interface ticket_sources_arr_rel_insert_input {
  data: ticket_sources_insert_input[];
  on_conflict?: ticket_sources_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_sources". All fields are combined with a logical 'AND'.
 */
export interface ticket_sources_bool_exp {
  _and?: ticket_sources_bool_exp[] | null;
  _not?: ticket_sources_bool_exp | null;
  _or?: ticket_sources_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditsToStoryPoints?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  gitRepoByGitrepoid?: git_repos_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  githubRepoLabelSid?: String_comparison_exp | null;
  github_repo_label?: github_repo_labels_bool_exp | null;
  gitlabAssigneeSid?: String_comparison_exp | null;
  gitlabProjectSid?: String_comparison_exp | null;
  gitlab_project?: gitlab_projects_bool_exp | null;
  gitlab_user?: gitlab_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  jiraAssigneeSid?: String_comparison_exp | null;
  jiraDescriptionFieldId?: uuid_comparison_exp | null;
  jiraIssueFieldByJiradescriptionfieldid?: jira_issue_fields_bool_exp | null;
  jiraIssueFieldByJirastorypointkey?: jira_issue_fields_bool_exp | null;
  jiraProjectSid?: String_comparison_exp | null;
  jiraStoryPointKey?: uuid_comparison_exp | null;
  jira_account?: jira_accounts_bool_exp | null;
  jira_issue_field?: jira_issue_fields_bool_exp | null;
  jira_project?: jira_projects_bool_exp | null;
  linearTeamSid?: String_comparison_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  prefix?: String_comparison_exp | null;
  projectName?: String_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_source_pull_strategies?: ticket_source_pull_strategies_bool_exp | null;
  ticket_source_push_strategies?: ticket_source_push_strategies_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_sources"
 */
export interface ticket_sources_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  creditsToStoryPoints?: number | null;
  deletedAt?: hasura_timestamptz | null;
  gitRepoByGitrepoid?: git_repos_obj_rel_insert_input | null;
  gitRepoId?: number | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  githubRepoLabelSid?: string | null;
  github_repo_label?: github_repo_labels_obj_rel_insert_input | null;
  gitlabAssigneeSid?: string | null;
  gitlabProjectSid?: string | null;
  gitlab_project?: gitlab_projects_obj_rel_insert_input | null;
  gitlab_user?: gitlab_users_obj_rel_insert_input | null;
  id?: number | null;
  jiraAssigneeSid?: string | null;
  jiraDescriptionFieldId?: hasura_uuid | null;
  jiraIssueFieldByJiradescriptionfieldid?: jira_issue_fields_obj_rel_insert_input | null;
  jiraIssueFieldByJirastorypointkey?: jira_issue_fields_obj_rel_insert_input | null;
  jiraProjectSid?: string | null;
  jiraStoryPointKey?: hasura_uuid | null;
  jira_account?: jira_accounts_obj_rel_insert_input | null;
  jira_issue_field?: jira_issue_fields_obj_rel_insert_input | null;
  jira_project?: jira_projects_obj_rel_insert_input | null;
  linearTeamSid?: string | null;
  linear_team?: linear_teams_obj_rel_insert_input | null;
  prefix?: string | null;
  projectName?: string | null;
  repoId?: number | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_source_pull_strategies?: ticket_source_pull_strategies_arr_rel_insert_input | null;
  ticket_source_push_strategies?: ticket_source_push_strategies_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "ticket_sources"
 */
export interface ticket_sources_obj_rel_insert_input {
  data: ticket_sources_insert_input;
  on_conflict?: ticket_sources_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_sources"
 */
export interface ticket_sources_on_conflict {
  constraint: ticket_sources_constraint;
  update_columns: ticket_sources_update_column[];
  where?: ticket_sources_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface ticket_standups_sla_view_bool_exp {
  _and?: ticket_standups_sla_view_bool_exp[] | null;
  _not?: ticket_standups_sla_view_bool_exp | null;
  _or?: ticket_standups_sla_view_bool_exp[] | null;
  dueAt?: timestamptz_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_standups_sla_view"
 */
export interface ticket_standups_sla_view_insert_input {
  dueAt?: hasura_timestamptz | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
}

/**
 * input type for inserting object relation for remote table "ticket_standups_sla_view"
 */
export interface ticket_standups_sla_view_obj_rel_insert_input {
  data: ticket_standups_sla_view_insert_input;
}

/**
 * Boolean expression to filter rows from the table "ticket_status". All fields are combined with a logical 'AND'.
 */
export interface ticket_status_bool_exp {
  _and?: ticket_status_bool_exp[] | null;
  _not?: ticket_status_bool_exp | null;
  _or?: ticket_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_bool_exp | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_status_changes?: ticket_status_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_status_changes"
 */
export interface ticket_status_changes_arr_rel_insert_input {
  data: ticket_status_changes_insert_input[];
  on_conflict?: ticket_status_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_status_changes". All fields are combined with a logical 'AND'.
 */
export interface ticket_status_changes_bool_exp {
  _and?: ticket_status_changes_bool_exp[] | null;
  _not?: ticket_status_changes_bool_exp | null;
  _or?: ticket_status_changes_bool_exp[] | null;
  automaticallyTransioned?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  reasonForTransition?: String_comparison_exp | null;
  status?: ticket_status_enum_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  ticket_status?: ticket_status_bool_exp | null;
  transitionedAt?: timestamptz_comparison_exp | null;
  transitionedByUserId?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_status_changes"
 */
export interface ticket_status_changes_insert_input {
  automaticallyTransioned?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  id?: hasura_uuid | null;
  reasonForTransition?: string | null;
  status?: ticket_status_enum | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  ticket_status?: ticket_status_obj_rel_insert_input | null;
  transitionedAt?: hasura_timestamptz | null;
  transitionedByUserId?: number | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "ticket_status_changes"
 */
export interface ticket_status_changes_on_conflict {
  constraint: ticket_status_changes_constraint;
  update_columns: ticket_status_changes_update_column[];
  where?: ticket_status_changes_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "ticket_status_enum". All fields are combined with logical 'AND'.
 */
export interface ticket_status_enum_comparison_exp {
  _eq?: ticket_status_enum | null;
  _in?: ticket_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: ticket_status_enum | null;
  _nin?: ticket_status_enum[] | null;
}

/**
 * input type for inserting data into table "ticket_status"
 */
export interface ticket_status_insert_input {
  description?: string | null;
  github_issue_ticket_status_mappings?: github_issue_ticket_status_mapping_arr_rel_insert_input | null;
  jira_issue_ticket_status_mappings?: jira_issue_ticket_status_mapping_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_status_changes?: ticket_status_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "ticket_status"
 */
export interface ticket_status_obj_rel_insert_input {
  data: ticket_status_insert_input;
  on_conflict?: ticket_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_status"
 */
export interface ticket_status_on_conflict {
  constraint: ticket_status_constraint;
  update_columns: ticket_status_update_column[];
  where?: ticket_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_arr_rel_insert_input {
  data: ticket_time_user_totals_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "ticket_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface ticket_time_user_totals_bool_exp {
  _and?: ticket_time_user_totals_bool_exp[] | null;
  _not?: ticket_time_user_totals_bool_exp | null;
  _or?: ticket_time_user_totals_bool_exp[] | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_insert_input {
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  totalOrganizedTimeInSecs?: hasura_bigint | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting array relation for remote table "tickets"
 */
export interface tickets_arr_rel_insert_input {
  data: tickets_insert_input[];
  on_conflict?: tickets_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "tickets". All fields are combined with a logical 'AND'.
 */
export interface tickets_bool_exp {
  _and?: tickets_bool_exp[] | null;
  _not?: tickets_bool_exp | null;
  _or?: tickets_bool_exp[] | null;
  activities?: activities_bool_exp | null;
  billableAt?: timestamptz_comparison_exp | null;
  billableCostInCredits?: Int_comparison_exp | null;
  blockers?: blockers_bool_exp | null;
  cancelledAt?: timestamptz_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  client_tickets?: ticket_client_view_bool_exp | null;
  code?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  dev_tickets?: ticket_developer_view_bool_exp | null;
  developer?: developers_bool_exp | null;
  discussionSourceByCode?: discussion_sources_bool_exp | null;
  discussionSourceId?: Int_comparison_exp | null;
  discussion_source?: discussion_sources_bool_exp | null;
  discussion_sources?: discussion_sources_bool_exp | null;
  discussions_related?: discussions_related_to_tickets_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_pull_requests?: git_pull_requests_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  githubIssueSid?: String_comparison_exp | null;
  github_issue?: github_issues_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  gitlabIssueSid?: String_comparison_exp | null;
  gitlab_issue?: gitlab_issues_bool_exp | null;
  id?: Int_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  isSynced?: Boolean_comparison_exp | null;
  jiraIssueSid?: String_comparison_exp | null;
  jira_issue?: jira_issues_bool_exp | null;
  jira_issues_parsed_linked_by_code?: jira_issues_parsed_bool_exp | null;
  linearIssueSid?: String_comparison_exp | null;
  linear_issue?: linear_issues_bool_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  manager?: users_bool_exp | null;
  managerId?: String_comparison_exp | null;
  needsApproval?: Boolean_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  priority?: Int_comparison_exp | null;
  pull_requests?: pull_requests_bool_exp | null;
  reviews?: reviews_bool_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: ticket_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  statusUpdatedAt?: timestamptz_comparison_exp | null;
  status_v2?: String_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_changes_by_status?: task_changes_by_status_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  ticketLink?: String_comparison_exp | null;
  ticketSourceId?: Int_comparison_exp | null;
  ticket_attachments?: ticket_attachments_bool_exp | null;
  ticket_billable_overrides?: ticket_billable_overrides_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_changes_by_status?: ticket_changes_by_status_bool_exp | null;
  ticket_client_blockers?: ticket_client_blockers_bool_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_parsed_bool_exp | null;
  ticket_credit_approval_changes?: ticket_credit_approval_changes_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  ticket_intentions?: ticket_intentions_bool_exp | null;
  ticket_means?: ticket_means_bool_exp | null;
  ticket_refunds?: ticket_refund_bool_exp | null;
  ticket_repos?: ticket_repos_bool_exp | null;
  ticket_scope_change_requests?: ticket_scope_change_requests_bool_exp | null;
  ticket_source?: ticket_sources_bool_exp | null;
  ticket_standups_sla?: ticket_standups_sla_view_bool_exp | null;
  ticket_status?: ticket_status_bool_exp | null;
  ticket_status_changes?: ticket_status_changes_bool_exp | null;
  ticket_time_user_totals?: ticket_time_user_totals_bool_exp | null;
  tickets_type?: tickets_types_bool_exp | null;
  title?: String_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  type?: tickets_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
  user_team?: user_teams_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "tickets"
 */
export interface tickets_insert_input {
  activities?: activities_arr_rel_insert_input | null;
  blockers?: blockers_arr_rel_insert_input | null;
  cancelledAt?: hasura_timestamptz | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  client_tickets?: ticket_client_view_obj_rel_insert_input | null;
  code?: string | null;
  completedAt?: hasura_timestamptz | null;
  costInCredits?: number | null;
  createdAt?: hasura_timestamptz | null;
  description?: string | null;
  descriptionType?: description_types_enum | null;
  description_type?: description_types_obj_rel_insert_input | null;
  dev_tickets?: ticket_developer_view_obj_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  discussionSourceByCode?: discussion_sources_obj_rel_insert_input | null;
  discussionSourceId?: number | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  discussion_sources?: discussion_sources_arr_rel_insert_input | null;
  discussions_related?: discussions_related_to_tickets_arr_rel_insert_input | null;
  gitRepoId?: number | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_pull_requests?: git_pull_requests_arr_rel_insert_input | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  githubIssueSid?: string | null;
  github_issue?: github_issues_obj_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  gitlabIssueSid?: string | null;
  gitlab_issue?: gitlab_issues_obj_rel_insert_input | null;
  id?: number | null;
  invoiceId?: number | null;
  isInternal?: boolean | null;
  isSynced?: boolean | null;
  jiraIssueSid?: string | null;
  jira_issue?: jira_issues_obj_rel_insert_input | null;
  jira_issues_parsed_linked_by_code?: jira_issues_parsed_obj_rel_insert_input | null;
  linearIssueSid?: string | null;
  linear_issue?: linear_issues_obj_rel_insert_input | null;
  lowerBudget?: number | null;
  manager?: users_obj_rel_insert_input | null;
  managerId?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
  priority?: number | null;
  pull_requests?: pull_requests_arr_rel_insert_input | null;
  reviews?: reviews_arr_rel_insert_input | null;
  startedAt?: hasura_timestamptz | null;
  status?: ticket_status_enum | null;
  statusUpdateReason?: string | null;
  statusUpdatedAt?: hasura_timestamptz | null;
  status_v2?: string | null;
  syncedAt?: hasura_timestamptz | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_changes_by_status?: task_changes_by_status_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  ticketLink?: string | null;
  ticketSourceId?: number | null;
  ticket_attachments?: ticket_attachments_arr_rel_insert_input | null;
  ticket_billable_overrides?: ticket_billable_overrides_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_changes_by_status?: ticket_changes_by_status_arr_rel_insert_input | null;
  ticket_client_blockers?: ticket_client_blockers_arr_rel_insert_input | null;
  ticket_credit_approval?: ticket_credit_approvals_parsed_obj_rel_insert_input | null;
  ticket_credit_approval_changes?: ticket_credit_approval_changes_arr_rel_insert_input | null;
  ticket_credit_approvals?: ticket_credit_approvals_arr_rel_insert_input | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  ticket_intentions?: ticket_intentions_arr_rel_insert_input | null;
  ticket_means?: ticket_means_arr_rel_insert_input | null;
  ticket_refunds?: ticket_refund_arr_rel_insert_input | null;
  ticket_repos?: ticket_repos_arr_rel_insert_input | null;
  ticket_scope_change_requests?: ticket_scope_change_requests_arr_rel_insert_input | null;
  ticket_source?: ticket_sources_obj_rel_insert_input | null;
  ticket_standups_sla?: ticket_standups_sla_view_obj_rel_insert_input | null;
  ticket_status?: ticket_status_obj_rel_insert_input | null;
  ticket_status_changes?: ticket_status_changes_arr_rel_insert_input | null;
  ticket_time_user_totals?: ticket_time_user_totals_arr_rel_insert_input | null;
  tickets_type?: tickets_types_obj_rel_insert_input | null;
  title?: string | null;
  type?: tickets_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
  userTeamId?: number | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_team?: user_teams_obj_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "tickets"
 */
export interface tickets_obj_rel_insert_input {
  data: tickets_insert_input;
  on_conflict?: tickets_on_conflict | null;
}

/**
 * on_conflict condition type for table "tickets"
 */
export interface tickets_on_conflict {
  constraint: tickets_constraint;
  update_columns: tickets_update_column[];
  where?: tickets_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "tickets_types". All fields are combined with a logical 'AND'.
 */
export interface tickets_types_bool_exp {
  _and?: tickets_types_bool_exp[] | null;
  _not?: tickets_types_bool_exp | null;
  _or?: tickets_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "tickets_types_enum". All fields are combined with logical 'AND'.
 */
export interface tickets_types_enum_comparison_exp {
  _eq?: tickets_types_enum | null;
  _in?: tickets_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tickets_types_enum | null;
  _nin?: tickets_types_enum[] | null;
}

/**
 * input type for inserting data into table "tickets_types"
 */
export interface tickets_types_insert_input {
  description?: string | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "tickets_types"
 */
export interface tickets_types_obj_rel_insert_input {
  data: tickets_types_insert_input;
  on_conflict?: tickets_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "tickets_types"
 */
export interface tickets_types_on_conflict {
  constraint: tickets_types_constraint;
  update_columns: tickets_types_update_column[];
  where?: tickets_types_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: hasura_timestamptz | null;
  _gt?: hasura_timestamptz | null;
  _gte?: hasura_timestamptz | null;
  _in?: hasura_timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_timestamptz | null;
  _lte?: hasura_timestamptz | null;
  _neq?: hasura_timestamptz | null;
  _nin?: hasura_timestamptz[] | null;
}

/**
 * Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'.
 */
export interface timetz_comparison_exp {
  _eq?: hasura_timetz | null;
  _gt?: hasura_timetz | null;
  _gte?: hasura_timetz | null;
  _in?: hasura_timetz[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_timetz | null;
  _lte?: hasura_timetz | null;
  _neq?: hasura_timetz | null;
  _nin?: hasura_timetz[] | null;
}

/**
 * input type for inserting array relation for remote table "user_billable_hours"
 */
export interface user_billable_hours_arr_rel_insert_input {
  data: user_billable_hours_insert_input[];
  on_conflict?: user_billable_hours_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_billable_hours". All fields are combined with a logical 'AND'.
 */
export interface user_billable_hours_bool_exp {
  _and?: user_billable_hours_bool_exp[] | null;
  _not?: user_billable_hours_bool_exp | null;
  _or?: user_billable_hours_bool_exp[] | null;
  creditsPerHour?: Int_comparison_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_billable_hours"
 */
export interface user_billable_hours_insert_input {
  creditsPerHour?: number | null;
  endDate?: hasura_timestamptz | null;
  id?: number | null;
  startDate?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
}

/**
 * on_conflict condition type for table "user_billable_hours"
 */
export interface user_billable_hours_on_conflict {
  constraint: user_billable_hours_constraint;
  update_columns: user_billable_hours_update_column[];
  where?: user_billable_hours_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_changes"
 */
export interface user_changes_arr_rel_insert_input {
  data: user_changes_insert_input[];
  on_conflict?: user_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_changes". All fields are combined with a logical 'AND'.
 */
export interface user_changes_bool_exp {
  _and?: user_changes_bool_exp[] | null;
  _not?: user_changes_bool_exp | null;
  _or?: user_changes_bool_exp[] | null;
  admin?: admin_bool_exp | null;
  adminUserId?: String_comparison_exp | null;
  agencyUserId?: String_comparison_exp | null;
  agency_user?: agency_users_bool_exp | null;
  auth0Sid?: String_comparison_exp | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  avatarUrl?: String_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  clientUserId?: uuid_comparison_exp | null;
  client_user?: client_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dateOfBirth?: date_comparison_exp | null;
  defaultEmail?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  developer?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  intercomUserId?: String_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  nickname?: String_comparison_exp | null;
  operation?: String_comparison_exp | null;
  passwordHash?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  ripplingEmployeeId?: Int_comparison_exp | null;
  rippling_employee?: rippling_employees_bool_exp | null;
  sqlQuery?: String_comparison_exp | null;
  telephone?: String_comparison_exp | null;
  timezone?: String_comparison_exp | null;
  unverifiedEmail?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userById?: users_bool_exp | null;
  userEmailByDefaultemail?: user_emails_bool_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "user_changes"
 */
export interface user_changes_insert_input {
  admin?: admin_obj_rel_insert_input | null;
  adminUserId?: string | null;
  agencyUserId?: string | null;
  agency_user?: agency_users_obj_rel_insert_input | null;
  auth0Sid?: string | null;
  automaticallyChanged?: boolean | null;
  avatarUrl?: string | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  clientUserId?: hasura_uuid | null;
  client_user?: client_users_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  dateOfBirth?: hasura_date | null;
  defaultEmail?: string | null;
  deletedAt?: hasura_timestamptz | null;
  developer?: developers_obj_rel_insert_input | null;
  developerId?: string | null;
  firstName?: string | null;
  id?: number | null;
  intercomUserId?: string | null;
  lastName?: string | null;
  login?: string | null;
  name?: string | null;
  nickname?: string | null;
  operation?: string | null;
  passwordHash?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  ripplingEmployeeId?: number | null;
  rippling_employee?: rippling_employees_obj_rel_insert_input | null;
  sqlQuery?: string | null;
  telephone?: string | null;
  timezone?: string | null;
  unverifiedEmail?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userById?: users_obj_rel_insert_input | null;
  userEmailByDefaultemail?: user_emails_obj_rel_insert_input | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_changes"
 */
export interface user_changes_on_conflict {
  constraint: user_changes_constraint;
  update_columns: user_changes_update_column[];
  where?: user_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_coefficients"
 */
export interface user_coefficients_arr_rel_insert_input {
  data: user_coefficients_insert_input[];
  on_conflict?: user_coefficients_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_coefficients". All fields are combined with a logical 'AND'.
 */
export interface user_coefficients_bool_exp {
  _and?: user_coefficients_bool_exp[] | null;
  _not?: user_coefficients_bool_exp | null;
  _or?: user_coefficients_bool_exp[] | null;
  coefficient?: numeric_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_coefficients"
 */
export interface user_coefficients_insert_input {
  coefficient?: hasura_numeric | null;
  createdAt?: hasura_timestamptz | null;
  endDate?: hasura_timestamptz | null;
  id?: number | null;
  startDate?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "user_coefficients"
 */
export interface user_coefficients_on_conflict {
  constraint: user_coefficients_constraint;
  update_columns: user_coefficients_update_column[];
  where?: user_coefficients_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_contract_types". All fields are combined with a logical 'AND'.
 */
export interface user_contract_types_bool_exp {
  _and?: user_contract_types_bool_exp[] | null;
  _not?: user_contract_types_bool_exp | null;
  _or?: user_contract_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_contracts?: user_contracts_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_contract_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_contract_types_enum_comparison_exp {
  _eq?: user_contract_types_enum | null;
  _in?: user_contract_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_contract_types_enum | null;
  _nin?: user_contract_types_enum[] | null;
}

/**
 * input type for inserting data into table "user_contract_types"
 */
export interface user_contract_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_contracts?: user_contracts_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_contract_types"
 */
export interface user_contract_types_obj_rel_insert_input {
  data: user_contract_types_insert_input;
  on_conflict?: user_contract_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_contract_types"
 */
export interface user_contract_types_on_conflict {
  constraint: user_contract_types_constraint;
  update_columns: user_contract_types_update_column[];
  where?: user_contract_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_contracts"
 */
export interface user_contracts_arr_rel_insert_input {
  data: user_contracts_insert_input[];
  on_conflict?: user_contracts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_contracts". All fields are combined with a logical 'AND'.
 */
export interface user_contracts_bool_exp {
  _and?: user_contracts_bool_exp[] | null;
  _not?: user_contracts_bool_exp | null;
  _or?: user_contracts_bool_exp[] | null;
  amountPerWeek?: Int_comparison_exp | null;
  contractSendError?: String_comparison_exp | null;
  contractSentAt?: timestamptz_comparison_exp | null;
  contractType?: user_contract_types_enum_comparison_exp | null;
  defaultCoefficient?: numeric_comparison_exp | null;
  developer?: developers_bool_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  gracePeriodEndDate?: timestamptz_comparison_exp | null;
  hoursPerWeek?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  title?: String_comparison_exp | null;
  url?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
  user_contract_type?: user_contract_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_contracts"
 */
export interface user_contracts_insert_input {
  amountPerWeek?: number | null;
  contractSendError?: string | null;
  contractSentAt?: hasura_timestamptz | null;
  contractType?: user_contract_types_enum | null;
  defaultCoefficient?: hasura_numeric | null;
  developer?: developers_obj_rel_insert_input | null;
  endDate?: hasura_timestamptz | null;
  gracePeriodEndDate?: hasura_timestamptz | null;
  hoursPerWeek?: number | null;
  id?: number | null;
  startDate?: hasura_timestamptz | null;
  title?: string | null;
  url?: string | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
  user_contract_type?: user_contract_types_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_contracts"
 */
export interface user_contracts_on_conflict {
  constraint: user_contracts_constraint;
  update_columns: user_contracts_update_column[];
  where?: user_contracts_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_culture_screens"
 */
export interface user_culture_screens_arr_rel_insert_input {
  data: user_culture_screens_insert_input[];
  on_conflict?: user_culture_screens_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_culture_screens". All fields are combined with a logical 'AND'.
 */
export interface user_culture_screens_bool_exp {
  _and?: user_culture_screens_bool_exp[] | null;
  _not?: user_culture_screens_bool_exp | null;
  _or?: user_culture_screens_bool_exp[] | null;
  attendedAt?: timestamptz_comparison_exp | null;
  availableFrom?: timestamptz_comparison_exp | null;
  availableWeeklyCapacity?: Int_comparison_exp | null;
  careerGoalsScore?: Int_comparison_exp | null;
  communicationScore?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  determinedByUser?: users_bool_exp | null;
  determinedByUserId?: Int_comparison_exp | null;
  expectedHourlySalary?: Int_comparison_exp | null;
  expectedMonthlySalary?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  notes?: String_comparison_exp | null;
  recordingLink?: String_comparison_exp | null;
  remoteWorkScore?: Int_comparison_exp | null;
  scheduledAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  workAttitudeScore?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_culture_screens"
 */
export interface user_culture_screens_insert_input {
  attendedAt?: hasura_timestamptz | null;
  availableFrom?: hasura_timestamptz | null;
  availableWeeklyCapacity?: number | null;
  careerGoalsScore?: number | null;
  communicationScore?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  determinedByUser?: users_obj_rel_insert_input | null;
  determinedByUserId?: number | null;
  expectedHourlySalary?: number | null;
  expectedMonthlySalary?: number | null;
  id?: number | null;
  notes?: string | null;
  recordingLink?: string | null;
  remoteWorkScore?: number | null;
  scheduledAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  workAttitudeScore?: number | null;
}

/**
 * on_conflict condition type for table "user_culture_screens"
 */
export interface user_culture_screens_on_conflict {
  constraint: user_culture_screens_constraint;
  update_columns: user_culture_screens_update_column[];
  where?: user_culture_screens_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_earnings"
 */
export interface user_daily_earnings_arr_rel_insert_input {
  data: user_daily_earnings_insert_input[];
  on_conflict?: user_daily_earnings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_earnings". All fields are combined with a logical 'AND'.
 */
export interface user_daily_earnings_bool_exp {
  _and?: user_daily_earnings_bool_exp[] | null;
  _not?: user_daily_earnings_bool_exp | null;
  _or?: user_daily_earnings_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  date?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  hoursWorkedOnDay?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  overrideHoursWorkedOnDay?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_daily_earnings"
 */
export interface user_daily_earnings_insert_input {
  createdAt?: hasura_timestamptz | null;
  date?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  hoursWorkedOnDay?: number | null;
  id?: number | null;
  overrideHoursWorkedOnDay?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
}

/**
 * on_conflict condition type for table "user_daily_earnings"
 */
export interface user_daily_earnings_on_conflict {
  constraint: user_daily_earnings_constraint;
  update_columns: user_daily_earnings_update_column[];
  where?: user_daily_earnings_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_arr_rel_insert_input {
  data: user_daily_facts_structure_insert_input[];
  on_conflict?: user_daily_facts_structure_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_facts_structure". All fields are combined with a logical 'AND'.
 */
export interface user_daily_facts_structure_bool_exp {
  _and?: user_daily_facts_structure_bool_exp[] | null;
  _not?: user_daily_facts_structure_bool_exp | null;
  _or?: user_daily_facts_structure_bool_exp[] | null;
  hoursLogged?: Int_comparison_exp | null;
  hoursLoggedOnOther?: Int_comparison_exp | null;
  hoursRequired?: Int_comparison_exp | null;
  leaveId?: Int_comparison_exp | null;
  leaveIsApproved?: Boolean_comparison_exp | null;
  occurredAt?: date_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_leave?: user_leaves_bool_exp | null;
}

/**
 * input type for inserting data into table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_insert_input {
  hoursLogged?: number | null;
  hoursLoggedOnOther?: number | null;
  hoursRequired?: number | null;
  leaveId?: number | null;
  leaveIsApproved?: boolean | null;
  occurredAt?: hasura_date | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_leave?: user_leaves_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_daily_facts_structure"
 */
export interface user_daily_facts_structure_on_conflict {
  constraint: user_daily_facts_structure_constraint;
  update_columns: user_daily_facts_structure_update_column[];
  where?: user_daily_facts_structure_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_plan_activities"
 */
export interface user_daily_plan_activities_arr_rel_insert_input {
  data: user_daily_plan_activities_insert_input[];
  on_conflict?: user_daily_plan_activities_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_plan_activities". All fields are combined with a logical 'AND'.
 */
export interface user_daily_plan_activities_bool_exp {
  _and?: user_daily_plan_activities_bool_exp[] | null;
  _not?: user_daily_plan_activities_bool_exp | null;
  _or?: user_daily_plan_activities_bool_exp[] | null;
  activity?: activities_bool_exp | null;
  activity_id?: bigint_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  user_daily_plan?: user_daily_plans_bool_exp | null;
  user_daily_plan_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_daily_plan_activities"
 */
export interface user_daily_plan_activities_insert_input {
  activity?: activities_obj_rel_insert_input | null;
  activity_id?: hasura_bigint | null;
  id?: hasura_bigint | null;
  user_daily_plan?: user_daily_plans_obj_rel_insert_input | null;
  user_daily_plan_id?: hasura_bigint | null;
}

/**
 * on_conflict condition type for table "user_daily_plan_activities"
 */
export interface user_daily_plan_activities_on_conflict {
  constraint: user_daily_plan_activities_constraint;
  update_columns: user_daily_plan_activities_update_column[];
  where?: user_daily_plan_activities_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_plan_tasks"
 */
export interface user_daily_plan_tasks_arr_rel_insert_input {
  data: user_daily_plan_tasks_insert_input[];
  on_conflict?: user_daily_plan_tasks_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_plan_tasks". All fields are combined with a logical 'AND'.
 */
export interface user_daily_plan_tasks_bool_exp {
  _and?: user_daily_plan_tasks_bool_exp[] | null;
  _not?: user_daily_plan_tasks_bool_exp | null;
  _or?: user_daily_plan_tasks_bool_exp[] | null;
  id?: bigint_comparison_exp | null;
  task?: tasks_bool_exp | null;
  task_id?: bigint_comparison_exp | null;
  user_daily_plan?: user_daily_plans_bool_exp | null;
  user_daily_plan_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_daily_plan_tasks"
 */
export interface user_daily_plan_tasks_insert_input {
  id?: hasura_bigint | null;
  task?: tasks_obj_rel_insert_input | null;
  task_id?: hasura_bigint | null;
  user_daily_plan?: user_daily_plans_obj_rel_insert_input | null;
  user_daily_plan_id?: hasura_bigint | null;
}

/**
 * on_conflict condition type for table "user_daily_plan_tasks"
 */
export interface user_daily_plan_tasks_on_conflict {
  constraint: user_daily_plan_tasks_constraint;
  update_columns: user_daily_plan_tasks_update_column[];
  where?: user_daily_plan_tasks_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_plans"
 */
export interface user_daily_plans_arr_rel_insert_input {
  data: user_daily_plans_insert_input[];
  on_conflict?: user_daily_plans_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_plans". All fields are combined with a logical 'AND'.
 */
export interface user_daily_plans_bool_exp {
  _and?: user_daily_plans_bool_exp[] | null;
  _not?: user_daily_plans_bool_exp | null;
  _or?: user_daily_plans_bool_exp[] | null;
  id?: bigint_comparison_exp | null;
  inserted_at?: timestamptz_comparison_exp | null;
  submitted_at?: timestamptz_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_daily_plan_activities?: user_daily_plan_activities_bool_exp | null;
  user_daily_plan_tasks?: user_daily_plan_tasks_bool_exp | null;
  user_daily_retrospectives?: user_daily_retrospectives_bool_exp | null;
  user_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_daily_plans"
 */
export interface user_daily_plans_insert_input {
  id?: hasura_bigint | null;
  inserted_at?: hasura_timestamptz | null;
  submitted_at?: hasura_timestamptz | null;
  updated_at?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_daily_plan_activities?: user_daily_plan_activities_arr_rel_insert_input | null;
  user_daily_plan_tasks?: user_daily_plan_tasks_arr_rel_insert_input | null;
  user_daily_retrospectives?: user_daily_retrospectives_arr_rel_insert_input | null;
  user_id?: number | null;
}

/**
 * input type for inserting object relation for remote table "user_daily_plans"
 */
export interface user_daily_plans_obj_rel_insert_input {
  data: user_daily_plans_insert_input;
  on_conflict?: user_daily_plans_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_daily_plans"
 */
export interface user_daily_plans_on_conflict {
  constraint: user_daily_plans_constraint;
  update_columns: user_daily_plans_update_column[];
  where?: user_daily_plans_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_retrospectives"
 */
export interface user_daily_retrospectives_arr_rel_insert_input {
  data: user_daily_retrospectives_insert_input[];
  on_conflict?: user_daily_retrospectives_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_retrospectives". All fields are combined with a logical 'AND'.
 */
export interface user_daily_retrospectives_bool_exp {
  _and?: user_daily_retrospectives_bool_exp[] | null;
  _not?: user_daily_retrospectives_bool_exp | null;
  _or?: user_daily_retrospectives_bool_exp[] | null;
  id?: bigint_comparison_exp | null;
  inserted_at?: timestamptz_comparison_exp | null;
  submitted_at?: timestamptz_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_daily_plan?: user_daily_plans_bool_exp | null;
  user_daily_plan_id?: bigint_comparison_exp | null;
  user_id?: bigint_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_daily_retrospectives"
 */
export interface user_daily_retrospectives_insert_input {
  id?: hasura_bigint | null;
  inserted_at?: hasura_timestamptz | null;
  submitted_at?: hasura_timestamptz | null;
  updated_at?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_daily_plan?: user_daily_plans_obj_rel_insert_input | null;
  user_daily_plan_id?: hasura_bigint | null;
  user_id?: hasura_bigint | null;
}

/**
 * on_conflict condition type for table "user_daily_retrospectives"
 */
export interface user_daily_retrospectives_on_conflict {
  constraint: user_daily_retrospectives_constraint;
  update_columns: user_daily_retrospectives_update_column[];
  where?: user_daily_retrospectives_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_daily_standups"
 */
export interface user_daily_standups_arr_rel_insert_input {
  data: user_daily_standups_insert_input[];
  on_conflict?: user_daily_standups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface user_daily_standups_bool_exp {
  _and?: user_daily_standups_bool_exp[] | null;
  _not?: user_daily_standups_bool_exp | null;
  _or?: user_daily_standups_bool_exp[] | null;
  client_daily_standups?: client_daily_standups_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  error?: errors_bool_exp | null;
  errorId?: Int_comparison_exp | null;
  errorMessage?: String_comparison_exp | null;
  erroredAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  messageForTeams?: String_comparison_exp | null;
  postedToSlack?: Boolean_comparison_exp | null;
  postedToWorkplace?: Boolean_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_daily_standups"
 */
export interface user_daily_standups_insert_input {
  client_daily_standups?: client_daily_standups_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  error?: errors_obj_rel_insert_input | null;
  errorId?: number | null;
  errorMessage?: string | null;
  erroredAt?: hasura_timestamptz | null;
  id?: number | null;
  messageForTeams?: string | null;
  postedToSlack?: boolean | null;
  postedToWorkplace?: boolean | null;
  project_daily_standups?: project_daily_standup_arr_rel_insert_input | null;
  submittedAt?: string | null;
  summary?: string | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting object relation for remote table "user_daily_standups"
 */
export interface user_daily_standups_obj_rel_insert_input {
  data: user_daily_standups_insert_input;
  on_conflict?: user_daily_standups_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_daily_standups"
 */
export interface user_daily_standups_on_conflict {
  constraint: user_daily_standups_constraint;
  update_columns: user_daily_standups_update_column[];
  where?: user_daily_standups_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_developer_view". All fields are combined with a logical 'AND'.
 */
export interface user_developer_view_bool_exp {
  _and?: user_developer_view_bool_exp[] | null;
  _not?: user_developer_view_bool_exp | null;
  _or?: user_developer_view_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  passwordHash?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_developer_view"
 */
export interface user_developer_view_insert_input {
  id?: number | null;
  passwordHash?: string | null;
}

/**
 * input type for inserting object relation for remote table "user_developer_view"
 */
export interface user_developer_view_obj_rel_insert_input {
  data: user_developer_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "user_emails"
 */
export interface user_emails_arr_rel_insert_input {
  data: user_emails_insert_input[];
  on_conflict?: user_emails_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_emails". All fields are combined with a logical 'AND'.
 */
export interface user_emails_bool_exp {
  _and?: user_emails_bool_exp[] | null;
  _not?: user_emails_bool_exp | null;
  _or?: user_emails_bool_exp[] | null;
  calendlyWebhookSubcriptionsByUser?: recruiter_calendly_webhook_subcriptions_bool_exp | null;
  calendly_event_memberships?: recruiter_calendly_event_memberships_bool_exp | null;
  calendly_event_types?: recruiter_calendly_event_types_bool_exp | null;
  calendly_events?: recruiter_calendly_events_bool_exp | null;
  calendly_webhook_subcriptions?: recruiter_calendly_webhook_subcriptions_bool_exp | null;
  email?: String_comparison_exp | null;
  git_users?: git_users_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  jira_accounts?: jira_accounts_bool_exp | null;
  linear_account?: linear_accounts_bool_exp | null;
  linear_accounts?: linear_accounts_bool_exp | null;
  meeting_user_emails?: meeting_user_emails_bool_exp | null;
  ripplingEmployeesByWorkemail?: rippling_employees_bool_exp | null;
  rippling_companies?: rippling_companies_bool_exp | null;
  rippling_employees?: rippling_employees_bool_exp | null;
  slack_users?: slack_users_bool_exp | null;
  user?: users_bool_exp | null;
  userByEmail?: users_bool_exp | null;
  userChangesByDefaultemail?: user_changes_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_changes?: user_changes_bool_exp | null;
  user_profiles?: user_profiles_bool_exp | null;
  users?: users_bool_exp | null;
  usersByUnverifiedemail?: users_bool_exp | null;
  verified?: Boolean_comparison_exp | null;
  zoom_users?: zoom_users_bool_exp | null;
}

/**
 * input type for inserting data into table "user_emails"
 */
export interface user_emails_insert_input {
  calendlyWebhookSubcriptionsByUser?: recruiter_calendly_webhook_subcriptions_arr_rel_insert_input | null;
  calendly_event_memberships?: recruiter_calendly_event_memberships_arr_rel_insert_input | null;
  calendly_event_types?: recruiter_calendly_event_types_arr_rel_insert_input | null;
  calendly_events?: recruiter_calendly_events_arr_rel_insert_input | null;
  calendly_webhook_subcriptions?: recruiter_calendly_webhook_subcriptions_arr_rel_insert_input | null;
  email?: string | null;
  git_users?: git_users_arr_rel_insert_input | null;
  github_accounts?: github_accounts_arr_rel_insert_input | null;
  jira_accounts?: jira_accounts_arr_rel_insert_input | null;
  linear_account?: linear_accounts_obj_rel_insert_input | null;
  linear_accounts?: linear_accounts_arr_rel_insert_input | null;
  meeting_user_emails?: meeting_user_emails_arr_rel_insert_input | null;
  ripplingEmployeesByWorkemail?: rippling_employees_arr_rel_insert_input | null;
  rippling_companies?: rippling_companies_arr_rel_insert_input | null;
  rippling_employees?: rippling_employees_arr_rel_insert_input | null;
  slack_users?: slack_users_arr_rel_insert_input | null;
  user?: users_obj_rel_insert_input | null;
  userByEmail?: users_obj_rel_insert_input | null;
  userChangesByDefaultemail?: user_changes_arr_rel_insert_input | null;
  userId?: number | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
  user_profiles?: user_profiles_arr_rel_insert_input | null;
  users?: users_arr_rel_insert_input | null;
  usersByUnverifiedemail?: users_arr_rel_insert_input | null;
  verified?: boolean | null;
  zoom_users?: zoom_users_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_emails"
 */
export interface user_emails_obj_rel_insert_input {
  data: user_emails_insert_input;
  on_conflict?: user_emails_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_emails"
 */
export interface user_emails_on_conflict {
  constraint: user_emails_constraint;
  update_columns: user_emails_update_column[];
  where?: user_emails_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_event_source_types". All fields are combined with a logical 'AND'.
 */
export interface user_event_source_types_bool_exp {
  _and?: user_event_source_types_bool_exp[] | null;
  _not?: user_event_source_types_bool_exp | null;
  _or?: user_event_source_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_event_source_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_event_source_types_enum_comparison_exp {
  _eq?: user_event_source_types_enum | null;
  _in?: user_event_source_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_event_source_types_enum | null;
  _nin?: user_event_source_types_enum[] | null;
}

/**
 * input type for inserting data into table "user_event_source_types"
 */
export interface user_event_source_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_events?: user_events_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_event_source_types"
 */
export interface user_event_source_types_obj_rel_insert_input {
  data: user_event_source_types_insert_input;
  on_conflict?: user_event_source_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_event_source_types"
 */
export interface user_event_source_types_on_conflict {
  constraint: user_event_source_types_constraint;
  update_columns: user_event_source_types_update_column[];
  where?: user_event_source_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_event_types". All fields are combined with a logical 'AND'.
 */
export interface user_event_types_bool_exp {
  _and?: user_event_types_bool_exp[] | null;
  _not?: user_event_types_bool_exp | null;
  _or?: user_event_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_events?: user_events_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_event_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_event_types_enum_comparison_exp {
  _eq?: user_event_types_enum | null;
  _in?: user_event_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_event_types_enum | null;
  _nin?: user_event_types_enum[] | null;
}

/**
 * input type for inserting data into table "user_event_types"
 */
export interface user_event_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_events?: user_events_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_event_types"
 */
export interface user_event_types_obj_rel_insert_input {
  data: user_event_types_insert_input;
  on_conflict?: user_event_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_event_types"
 */
export interface user_event_types_on_conflict {
  constraint: user_event_types_constraint;
  update_columns: user_event_types_update_column[];
  where?: user_event_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_events"
 */
export interface user_events_arr_rel_insert_input {
  data: user_events_insert_input[];
  on_conflict?: user_events_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_events". All fields are combined with a logical 'AND'.
 */
export interface user_events_bool_exp {
  _and?: user_events_bool_exp[] | null;
  _not?: user_events_bool_exp | null;
  _or?: user_events_bool_exp[] | null;
  appName?: String_comparison_exp | null;
  browserUrl?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  duration?: Int_comparison_exp | null;
  eventSource?: user_event_source_types_enum_comparison_exp | null;
  eventType?: user_event_types_enum_comparison_exp | null;
  event_type?: user_event_types_bool_exp | null;
  filePath?: String_comparison_exp | null;
  gitBranchRef?: String_comparison_exp | null;
  gitCommitHash?: String_comparison_exp | null;
  gitOwner?: String_comparison_exp | null;
  gitRelativeFilePath?: String_comparison_exp | null;
  gitRepoName?: String_comparison_exp | null;
  gitSourceId?: git_sources_enum_comparison_exp | null;
  git_source?: git_sources_bool_exp | null;
  id?: uuid_comparison_exp | null;
  isProcessed?: Boolean_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  pollInterval?: Int_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_event_source_type?: user_event_source_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_events"
 */
export interface user_events_insert_input {
  appName?: string | null;
  browserUrl?: string | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  duration?: number | null;
  eventSource?: user_event_source_types_enum | null;
  eventType?: user_event_types_enum | null;
  event_type?: user_event_types_obj_rel_insert_input | null;
  filePath?: string | null;
  gitBranchRef?: string | null;
  gitCommitHash?: string | null;
  gitOwner?: string | null;
  gitRelativeFilePath?: string | null;
  gitRepoName?: string | null;
  gitSourceId?: git_sources_enum | null;
  git_source?: git_sources_obj_rel_insert_input | null;
  id?: hasura_uuid | null;
  isProcessed?: boolean | null;
  occurredAt?: hasura_timestamptz | null;
  pollInterval?: number | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  title?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_event_source_type?: user_event_source_types_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_events"
 */
export interface user_events_on_conflict {
  constraint: user_events_constraint;
  update_columns: user_events_update_column[];
  where?: user_events_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_facts_structure"
 */
export interface user_facts_structure_arr_rel_insert_input {
  data: user_facts_structure_insert_input[];
  on_conflict?: user_facts_structure_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_facts_structure". All fields are combined with a logical 'AND'.
 */
export interface user_facts_structure_bool_exp {
  _and?: user_facts_structure_bool_exp[] | null;
  _not?: user_facts_structure_bool_exp | null;
  _or?: user_facts_structure_bool_exp[] | null;
  paidLeavesEarned?: Int_comparison_exp | null;
  paidLeavesUtilized?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_facts_structure"
 */
export interface user_facts_structure_insert_input {
  paidLeavesEarned?: number | null;
  paidLeavesUtilized?: number | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "user_facts_structure"
 */
export interface user_facts_structure_on_conflict {
  constraint: user_facts_structure_constraint;
  update_columns: user_facts_structure_update_column[];
  where?: user_facts_structure_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_hackweek_screens"
 */
export interface user_hackweek_screens_arr_rel_insert_input {
  data: user_hackweek_screens_insert_input[];
  on_conflict?: user_hackweek_screens_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_hackweek_screens". All fields are combined with a logical 'AND'.
 */
export interface user_hackweek_screens_bool_exp {
  _and?: user_hackweek_screens_bool_exp[] | null;
  _not?: user_hackweek_screens_bool_exp | null;
  _or?: user_hackweek_screens_bool_exp[] | null;
  communicationScore?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  mentoredById?: Int_comparison_exp | null;
  notes?: String_comparison_exp | null;
  result?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  teamWorkScore?: Int_comparison_exp | null;
  techSkillsScore?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByUserid?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_hackweek_screens_result?: user_hackweek_screens_result_bool_exp | null;
  workOutputScore?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_hackweek_screens"
 */
export interface user_hackweek_screens_insert_input {
  communicationScore?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  mentoredById?: number | null;
  notes?: string | null;
  result?: string | null;
  startAt?: hasura_timestamptz | null;
  teamWorkScore?: number | null;
  techSkillsScore?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByUserid?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_hackweek_screens_result?: user_hackweek_screens_result_obj_rel_insert_input | null;
  workOutputScore?: number | null;
}

/**
 * on_conflict condition type for table "user_hackweek_screens"
 */
export interface user_hackweek_screens_on_conflict {
  constraint: user_hackweek_screens_constraint;
  update_columns: user_hackweek_screens_update_column[];
  where?: user_hackweek_screens_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_hackweek_screens_result". All fields are combined with a logical 'AND'.
 */
export interface user_hackweek_screens_result_bool_exp {
  _and?: user_hackweek_screens_result_bool_exp[] | null;
  _not?: user_hackweek_screens_result_bool_exp | null;
  _or?: user_hackweek_screens_result_bool_exp[] | null;
  description?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
  user_hackweek_screens?: user_hackweek_screens_bool_exp | null;
}

/**
 * input type for inserting data into table "user_hackweek_screens_result"
 */
export interface user_hackweek_screens_result_insert_input {
  description?: string | null;
  type?: string | null;
  user_hackweek_screens?: user_hackweek_screens_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_hackweek_screens_result"
 */
export interface user_hackweek_screens_result_obj_rel_insert_input {
  data: user_hackweek_screens_result_insert_input;
  on_conflict?: user_hackweek_screens_result_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_hackweek_screens_result"
 */
export interface user_hackweek_screens_result_on_conflict {
  constraint: user_hackweek_screens_result_constraint;
  update_columns: user_hackweek_screens_result_update_column[];
  where?: user_hackweek_screens_result_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_invoice_balances"
 */
export interface user_invoice_balances_arr_rel_insert_input {
  data: user_invoice_balances_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "user_invoice_balances". All fields are combined with a logical 'AND'.
 */
export interface user_invoice_balances_bool_exp {
  _and?: user_invoice_balances_bool_exp[] | null;
  _not?: user_invoice_balances_bool_exp | null;
  _or?: user_invoice_balances_bool_exp[] | null;
  adjustedCostInUSD?: Int_comparison_exp | null;
  agency_invoice?: agency_invoices_bool_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  current_paid_balance_in_usd?: float8_comparison_exp | null;
  developer_bonus_earnings?: developer_bonus_earning_bool_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  paidByAgencyInvoiceId?: Int_comparison_exp | null;
  previous_invoiced_balance_in_usd?: bigint_comparison_exp | null;
  secs_logged_in_invoice?: bigint_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  task_reviews_as_manager?: task_reviews_bool_exp | null;
  task_reviews_as_reviewer?: task_reviews_bool_exp | null;
  task_works_as_manager?: task_work_bool_exp | null;
  task_works_as_worker?: task_work_bool_exp | null;
  tasks_as_developer?: tasks_bool_exp | null;
  tasks_as_manager?: tasks_bool_exp | null;
  total_user_transactions?: Float_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
  user_time_earnings?: user_time_earnings_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
}

/**
 * input type for inserting data into table "user_invoice_balances"
 */
export interface user_invoice_balances_insert_input {
  adjustedCostInUSD?: number | null;
  agency_invoice?: agency_invoices_obj_rel_insert_input | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  current_paid_balance_in_usd?: hasura_float8 | null;
  developer_bonus_earnings?: developer_bonus_earning_arr_rel_insert_input | null;
  dueAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  paidByAgencyInvoiceId?: number | null;
  previous_invoiced_balance_in_usd?: hasura_bigint | null;
  secs_logged_in_invoice?: hasura_bigint | null;
  startAt?: hasura_timestamptz | null;
  task_reviews_as_manager?: task_reviews_arr_rel_insert_input | null;
  task_reviews_as_reviewer?: task_reviews_arr_rel_insert_input | null;
  task_works_as_manager?: task_work_arr_rel_insert_input | null;
  task_works_as_worker?: task_work_arr_rel_insert_input | null;
  tasks_as_developer?: tasks_arr_rel_insert_input | null;
  tasks_as_manager?: tasks_arr_rel_insert_input | null;
  total_user_transactions?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
  user_time_earnings?: user_time_earnings_arr_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_invoice_balances"
 */
export interface user_invoice_balances_obj_rel_insert_input {
  data: user_invoice_balances_insert_input;
}

/**
 * input type for inserting array relation for remote table "user_invoices"
 */
export interface user_invoices_arr_rel_insert_input {
  data: user_invoices_insert_input[];
  on_conflict?: user_invoices_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_invoices". All fields are combined with a logical 'AND'.
 */
export interface user_invoices_bool_exp {
  _and?: user_invoices_bool_exp[] | null;
  _not?: user_invoices_bool_exp | null;
  _or?: user_invoices_bool_exp[] | null;
  adjustedCostInUSD?: Int_comparison_exp | null;
  agency_invoice?: agency_invoices_bool_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  developer_bonus_earnings?: developer_bonus_earning_bool_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  paidByAgencyInvoiceId?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  taskChangesByManagerinvoiceid?: task_changes_bool_exp | null;
  taskReviewsByManagerinvoiceid?: task_reviews_bool_exp | null;
  taskWorksByManagerinvoiceid?: task_work_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  task_works?: task_work_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  tasksByManagerinvoiceid?: tasks_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_invoice_balance?: user_invoice_balances_bool_exp | null;
  user_time_earnings?: user_time_earnings_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
}

/**
 * input type for inserting data into table "user_invoices"
 */
export interface user_invoices_insert_input {
  adjustedCostInUSD?: number | null;
  agency_invoice?: agency_invoices_obj_rel_insert_input | null;
  costInUSD?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  developer_bonus_earnings?: developer_bonus_earning_arr_rel_insert_input | null;
  dueAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  paidByAgencyInvoiceId?: number | null;
  startAt?: hasura_timestamptz | null;
  taskChangesByManagerinvoiceid?: task_changes_arr_rel_insert_input | null;
  taskReviewsByManagerinvoiceid?: task_reviews_arr_rel_insert_input | null;
  taskWorksByManagerinvoiceid?: task_work_arr_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_reviews?: task_reviews_arr_rel_insert_input | null;
  task_works?: task_work_arr_rel_insert_input | null;
  tasks?: tasks_arr_rel_insert_input | null;
  tasksByManagerinvoiceid?: tasks_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_invoice_balance?: user_invoice_balances_obj_rel_insert_input | null;
  user_time_earnings?: user_time_earnings_arr_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_invoices"
 */
export interface user_invoices_obj_rel_insert_input {
  data: user_invoices_insert_input;
  on_conflict?: user_invoices_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_invoices"
 */
export interface user_invoices_on_conflict {
  constraint: user_invoices_constraint;
  update_columns: user_invoices_update_column[];
  where?: user_invoices_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_leave_types". All fields are combined with a logical 'AND'.
 */
export interface user_leave_types_bool_exp {
  _and?: user_leave_types_bool_exp[] | null;
  _not?: user_leave_types_bool_exp | null;
  _or?: user_leave_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_leaves?: user_leaves_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_leave_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_leave_types_enum_comparison_exp {
  _eq?: user_leave_types_enum | null;
  _in?: user_leave_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_leave_types_enum | null;
  _nin?: user_leave_types_enum[] | null;
}

/**
 * input type for inserting data into table "user_leave_types"
 */
export interface user_leave_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_leaves?: user_leaves_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_leave_types"
 */
export interface user_leave_types_obj_rel_insert_input {
  data: user_leave_types_insert_input;
  on_conflict?: user_leave_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_leave_types"
 */
export interface user_leave_types_on_conflict {
  constraint: user_leave_types_constraint;
  update_columns: user_leave_types_update_column[];
  where?: user_leave_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_leaves"
 */
export interface user_leaves_arr_rel_insert_input {
  data: user_leaves_insert_input[];
  on_conflict?: user_leaves_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_leaves". All fields are combined with a logical 'AND'.
 */
export interface user_leaves_bool_exp {
  _and?: user_leaves_bool_exp[] | null;
  _not?: user_leaves_bool_exp | null;
  _or?: user_leaves_bool_exp[] | null;
  approvedById?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isApproved?: Boolean_comparison_exp | null;
  leaveReason?: String_comparison_exp | null;
  leaveTypeId?: user_leave_types_enum_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByUserid?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_facts_structures?: user_daily_facts_structure_bool_exp | null;
  user_leave_type?: user_leave_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_leaves"
 */
export interface user_leaves_insert_input {
  approvedById?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  isApproved?: boolean | null;
  leaveReason?: string | null;
  leaveTypeId?: user_leave_types_enum | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByUserid?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_daily_facts_structures?: user_daily_facts_structure_arr_rel_insert_input | null;
  user_leave_type?: user_leave_types_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_leaves"
 */
export interface user_leaves_obj_rel_insert_input {
  data: user_leaves_insert_input;
  on_conflict?: user_leaves_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_leaves"
 */
export interface user_leaves_on_conflict {
  constraint: user_leaves_constraint;
  update_columns: user_leaves_update_column[];
  where?: user_leaves_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_onboarded_repos"
 */
export interface user_onboarded_repos_arr_rel_insert_input {
  data: user_onboarded_repos_insert_input[];
  on_conflict?: user_onboarded_repos_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_onboarded_repos". All fields are combined with a logical 'AND'.
 */
export interface user_onboarded_repos_bool_exp {
  _and?: user_onboarded_repos_bool_exp[] | null;
  _not?: user_onboarded_repos_bool_exp | null;
  _or?: user_onboarded_repos_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  repoId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "user_onboarded_repos"
 */
export interface user_onboarded_repos_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  git_repo?: git_repos_obj_rel_insert_input | null;
  id?: number | null;
  login?: string | null;
  repoId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_onboarded_repos"
 */
export interface user_onboarded_repos_on_conflict {
  constraint: user_onboarded_repos_constraint;
  update_columns: user_onboarded_repos_update_column[];
  where?: user_onboarded_repos_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_opportunity_interests"
 */
export interface user_opportunity_interests_arr_rel_insert_input {
  data: user_opportunity_interests_insert_input[];
  on_conflict?: user_opportunity_interests_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_opportunity_interests". All fields are combined with a logical 'AND'.
 */
export interface user_opportunity_interests_bool_exp {
  _and?: user_opportunity_interests_bool_exp[] | null;
  _not?: user_opportunity_interests_bool_exp | null;
  _or?: user_opportunity_interests_bool_exp[] | null;
  opportunitiesId?: Int_comparison_exp | null;
  opportunity?: opportunities_bool_exp | null;
  user?: users_bool_exp | null;
  usersId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_opportunity_interests"
 */
export interface user_opportunity_interests_insert_input {
  opportunitiesId?: number | null;
  opportunity?: opportunities_obj_rel_insert_input | null;
  user?: users_obj_rel_insert_input | null;
  usersId?: number | null;
}

/**
 * on_conflict condition type for table "user_opportunity_interests"
 */
export interface user_opportunity_interests_on_conflict {
  constraint: user_opportunity_interests_constraint;
  update_columns: user_opportunity_interests_update_column[];
  where?: user_opportunity_interests_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_payment_status". All fields are combined with a logical 'AND'.
 */
export interface user_payment_status_bool_exp {
  _and?: user_payment_status_bool_exp[] | null;
  _not?: user_payment_status_bool_exp | null;
  _or?: user_payment_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_payments?: user_payments_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_payment_status_enum". All fields are combined with logical 'AND'.
 */
export interface user_payment_status_enum_comparison_exp {
  _eq?: user_payment_status_enum | null;
  _in?: user_payment_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_payment_status_enum | null;
  _nin?: user_payment_status_enum[] | null;
}

/**
 * input type for inserting data into table "user_payment_status"
 */
export interface user_payment_status_insert_input {
  description?: string | null;
  id?: string | null;
  user_payments?: user_payments_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_payment_status"
 */
export interface user_payment_status_obj_rel_insert_input {
  data: user_payment_status_insert_input;
  on_conflict?: user_payment_status_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_payment_status"
 */
export interface user_payment_status_on_conflict {
  constraint: user_payment_status_constraint;
  update_columns: user_payment_status_update_column[];
  where?: user_payment_status_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_payment_types". All fields are combined with a logical 'AND'.
 */
export interface user_payment_types_bool_exp {
  _and?: user_payment_types_bool_exp[] | null;
  _not?: user_payment_types_bool_exp | null;
  _or?: user_payment_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_payments?: user_payments_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_payment_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_payment_types_enum_comparison_exp {
  _eq?: user_payment_types_enum | null;
  _in?: user_payment_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_payment_types_enum | null;
  _nin?: user_payment_types_enum[] | null;
}

/**
 * input type for inserting data into table "user_payment_types"
 */
export interface user_payment_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_payments?: user_payments_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_payment_types"
 */
export interface user_payment_types_obj_rel_insert_input {
  data: user_payment_types_insert_input;
  on_conflict?: user_payment_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_payment_types"
 */
export interface user_payment_types_on_conflict {
  constraint: user_payment_types_constraint;
  update_columns: user_payment_types_update_column[];
  where?: user_payment_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_payments"
 */
export interface user_payments_arr_rel_insert_input {
  data: user_payments_insert_input[];
  on_conflict?: user_payments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_payments". All fields are combined with a logical 'AND'.
 */
export interface user_payments_bool_exp {
  _and?: user_payments_bool_exp[] | null;
  _not?: user_payments_bool_exp | null;
  _or?: user_payments_bool_exp[] | null;
  amountInUSD?: float8_comparison_exp | null;
  channelTransactionId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  developer?: developers_bool_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  paymentType?: user_payment_types_enum_comparison_exp | null;
  ripplingPayrollRunDetailsSid?: String_comparison_exp | null;
  rippling_payroll_run_detail?: rippling_payroll_run_details_bool_exp | null;
  status?: user_payment_status_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
  user_payment_status?: user_payment_status_bool_exp | null;
  user_payment_type?: user_payment_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_payments"
 */
export interface user_payments_insert_input {
  amountInUSD?: hasura_float8 | null;
  channelTransactionId?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  developer?: developers_obj_rel_insert_input | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  paymentType?: user_payment_types_enum | null;
  ripplingPayrollRunDetailsSid?: string | null;
  rippling_payroll_run_detail?: rippling_payroll_run_details_obj_rel_insert_input | null;
  status?: user_payment_status_enum | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
  user_payment_status?: user_payment_status_obj_rel_insert_input | null;
  user_payment_type?: user_payment_types_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_payments"
 */
export interface user_payments_obj_rel_insert_input {
  data: user_payments_insert_input;
  on_conflict?: user_payments_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_payments"
 */
export interface user_payments_on_conflict {
  constraint: user_payments_constraint;
  update_columns: user_payments_update_column[];
  where?: user_payments_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_profiles"
 */
export interface user_profiles_arr_rel_insert_input {
  data: user_profiles_insert_input[];
  on_conflict?: user_profiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_profiles". All fields are combined with a logical 'AND'.
 */
export interface user_profiles_bool_exp {
  _and?: user_profiles_bool_exp[] | null;
  _not?: user_profiles_bool_exp | null;
  _or?: user_profiles_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  accessTokenExpiresAt?: timestamptz_comparison_exp | null;
  avatarUrl?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  defaultEmail?: String_comparison_exp | null;
  defaultEmailVerified?: Boolean_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  password?: String_comparison_exp | null;
  personalAccessToken?: String_comparison_exp | null;
  rateLimitLeft?: String_comparison_exp | null;
  rateLimitResetsAt?: timestamptz_comparison_exp | null;
  refreshToken?: String_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  type?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * input type for inserting data into table "user_profiles"
 */
export interface user_profiles_insert_input {
  accessToken?: string | null;
  accessTokenExpiresAt?: hasura_timestamptz | null;
  avatarUrl?: string | null;
  createdAt?: hasura_timestamptz | null;
  defaultEmail?: string | null;
  defaultEmailVerified?: boolean | null;
  deletedAt?: hasura_timestamptz | null;
  firstName?: string | null;
  id?: number | null;
  lastName?: string | null;
  login?: string | null;
  name?: string | null;
  password?: string | null;
  personalAccessToken?: string | null;
  rateLimitLeft?: string | null;
  rateLimitResetsAt?: hasura_timestamptz | null;
  refreshToken?: string | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  type?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  user_email?: user_emails_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_profiles"
 */
export interface user_profiles_on_conflict {
  constraint: user_profiles_constraint;
  update_columns: user_profiles_update_column[];
  where?: user_profiles_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_sessions"
 */
export interface user_sessions_arr_rel_insert_input {
  data: user_sessions_insert_input[];
  on_conflict?: user_sessions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_sessions". All fields are combined with a logical 'AND'.
 */
export interface user_sessions_bool_exp {
  _and?: user_sessions_bool_exp[] | null;
  _not?: user_sessions_bool_exp | null;
  _or?: user_sessions_bool_exp[] | null;
  accessToken?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  expiresAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  sessionToken?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_sessions"
 */
export interface user_sessions_insert_input {
  accessToken?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  expiresAt?: hasura_timestamptz | null;
  id?: number | null;
  sessionToken?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "user_sessions"
 */
export interface user_sessions_on_conflict {
  constraint: user_sessions_constraint;
  update_columns: user_sessions_update_column[];
  where?: user_sessions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_skills"
 */
export interface user_skills_arr_rel_insert_input {
  data: user_skills_insert_input[];
  on_conflict?: user_skills_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_skills". All fields are combined with a logical 'AND'.
 */
export interface user_skills_bool_exp {
  _and?: user_skills_bool_exp[] | null;
  _not?: user_skills_bool_exp | null;
  _or?: user_skills_bool_exp[] | null;
  aggregatedMonthsOfExperience?: Int_comparison_exp | null;
  competencyId?: String_comparison_exp | null;
  competency_type?: competency_types_bool_exp | null;
  lastUsedDate?: timestamptz_comparison_exp | null;
  skill?: skills_bool_exp | null;
  skillId?: Int_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_skills"
 */
export interface user_skills_insert_input {
  aggregatedMonthsOfExperience?: number | null;
  competencyId?: string | null;
  competency_type?: competency_types_obj_rel_insert_input | null;
  lastUsedDate?: hasura_timestamptz | null;
  skill?: skills_obj_rel_insert_input | null;
  skillId?: number | null;
  startDate?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "user_skills"
 */
export interface user_skills_on_conflict {
  constraint: user_skills_constraint;
  update_columns: user_skills_update_column[];
  where?: user_skills_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_standups_sla_view". All fields are combined with a logical 'AND'.
 */
export interface user_standups_sla_view_bool_exp {
  _and?: user_standups_sla_view_bool_exp[] | null;
  _not?: user_standups_sla_view_bool_exp | null;
  _or?: user_standups_sla_view_bool_exp[] | null;
  dueAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_standups_sla_view"
 */
export interface user_standups_sla_view_insert_input {
  dueAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * input type for inserting object relation for remote table "user_standups_sla_view"
 */
export interface user_standups_sla_view_obj_rel_insert_input {
  data: user_standups_sla_view_insert_input;
}

/**
 * input type for inserting array relation for remote table "user_team_clients"
 */
export interface user_team_clients_arr_rel_insert_input {
  data: user_team_clients_insert_input[];
  on_conflict?: user_team_clients_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_clients". All fields are combined with a logical 'AND'.
 */
export interface user_team_clients_bool_exp {
  _and?: user_team_clients_bool_exp[] | null;
  _not?: user_team_clients_bool_exp | null;
  _or?: user_team_clients_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isOwned?: Boolean_comparison_exp | null;
  managerId?: Int_comparison_exp | null;
  projectName?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting data into table "user_team_clients"
 */
export interface user_team_clients_insert_input {
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  isOwned?: boolean | null;
  managerId?: number | null;
  projectName?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userTeamId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_team_clients"
 */
export interface user_team_clients_obj_rel_insert_input {
  data: user_team_clients_insert_input;
  on_conflict?: user_team_clients_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_team_clients"
 */
export interface user_team_clients_on_conflict {
  constraint: user_team_clients_constraint;
  update_columns: user_team_clients_update_column[];
  where?: user_team_clients_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_team_leads"
 */
export interface user_team_leads_arr_rel_insert_input {
  data: user_team_leads_insert_input[];
  on_conflict?: user_team_leads_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_leads". All fields are combined with a logical 'AND'.
 */
export interface user_team_leads_bool_exp {
  _and?: user_team_leads_bool_exp[] | null;
  _not?: user_team_leads_bool_exp | null;
  _or?: user_team_leads_bool_exp[] | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isShadow?: Boolean_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  teamLeadUserId?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting data into table "user_team_leads"
 */
export interface user_team_leads_insert_input {
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  isShadow?: boolean | null;
  startAt?: hasura_timestamptz | null;
  teamLeadUserId?: number | null;
  user?: users_obj_rel_insert_input | null;
  userTeamId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_team_leads"
 */
export interface user_team_leads_on_conflict {
  constraint: user_team_leads_constraint;
  update_columns: user_team_leads_update_column[];
  where?: user_team_leads_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_team_member_changes"
 */
export interface user_team_member_changes_arr_rel_insert_input {
  data: user_team_member_changes_insert_input[];
  on_conflict?: user_team_member_changes_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_member_changes". All fields are combined with a logical 'AND'.
 */
export interface user_team_member_changes_bool_exp {
  _and?: user_team_member_changes_bool_exp[] | null;
  _not?: user_team_member_changes_bool_exp | null;
  _or?: user_team_member_changes_bool_exp[] | null;
  automaticallyChanged?: Boolean_comparison_exp | null;
  changeCreatedAt?: timestamptz_comparison_exp | null;
  changeId?: uuid_comparison_exp | null;
  changedByUserId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  operation?: String_comparison_exp | null;
  previousChangeId?: uuid_comparison_exp | null;
  reasonForChange?: String_comparison_exp | null;
  role?: String_comparison_exp | null;
  sqlQuery?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByUserid?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
  user_team_member?: user_team_members_bool_exp | null;
  user_team_role?: user_team_roles_bool_exp | null;
}

/**
 * input type for inserting data into table "user_team_member_changes"
 */
export interface user_team_member_changes_insert_input {
  automaticallyChanged?: boolean | null;
  changeCreatedAt?: hasura_timestamptz | null;
  changeId?: hasura_uuid | null;
  changedByUserId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  operation?: string | null;
  previousChangeId?: hasura_uuid | null;
  reasonForChange?: string | null;
  role?: string | null;
  sqlQuery?: string | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByUserid?: users_obj_rel_insert_input | null;
  userId?: number | null;
  userTeamId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
  user_team_member?: user_team_members_obj_rel_insert_input | null;
  user_team_role?: user_team_roles_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_team_member_changes"
 */
export interface user_team_member_changes_on_conflict {
  constraint: user_team_member_changes_constraint;
  update_columns: user_team_member_changes_update_column[];
  where?: user_team_member_changes_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_team_members"
 */
export interface user_team_members_arr_rel_insert_input {
  data: user_team_members_insert_input[];
  on_conflict?: user_team_members_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_members". All fields are combined with a logical 'AND'.
 */
export interface user_team_members_bool_exp {
  _and?: user_team_members_bool_exp[] | null;
  _not?: user_team_members_bool_exp | null;
  _or?: user_team_members_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
  user_team_member_changes?: user_team_member_changes_bool_exp | null;
  user_team_role?: user_team_roles_bool_exp | null;
}

/**
 * input type for inserting data into table "user_team_members"
 */
export interface user_team_members_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  role?: string | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  userTeamId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
  user_team_member_changes?: user_team_member_changes_arr_rel_insert_input | null;
  user_team_role?: user_team_roles_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_team_members"
 */
export interface user_team_members_obj_rel_insert_input {
  data: user_team_members_insert_input;
  on_conflict?: user_team_members_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_team_members"
 */
export interface user_team_members_on_conflict {
  constraint: user_team_members_constraint;
  update_columns: user_team_members_update_column[];
  where?: user_team_members_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_roles". All fields are combined with a logical 'AND'.
 */
export interface user_team_roles_bool_exp {
  _and?: user_team_roles_bool_exp[] | null;
  _not?: user_team_roles_bool_exp | null;
  _or?: user_team_roles_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_team_member_changes?: user_team_member_changes_bool_exp | null;
  user_team_members?: user_team_members_bool_exp | null;
}

/**
 * input type for inserting data into table "user_team_roles"
 */
export interface user_team_roles_insert_input {
  description?: string | null;
  id?: string | null;
  user_team_member_changes?: user_team_member_changes_arr_rel_insert_input | null;
  user_team_members?: user_team_members_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_team_roles"
 */
export interface user_team_roles_obj_rel_insert_input {
  data: user_team_roles_insert_input;
  on_conflict?: user_team_roles_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_team_roles"
 */
export interface user_team_roles_on_conflict {
  constraint: user_team_roles_constraint;
  update_columns: user_team_roles_update_column[];
  where?: user_team_roles_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_team_standups"
 */
export interface user_team_standups_arr_rel_insert_input {
  data: user_team_standups_insert_input[];
  on_conflict?: user_team_standups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_team_standups". All fields are combined with a logical 'AND'.
 */
export interface user_team_standups_bool_exp {
  _and?: user_team_standups_bool_exp[] | null;
  _not?: user_team_standups_bool_exp | null;
  _or?: user_team_standups_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  standupTime?: timetz_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userTeamId?: Int_comparison_exp | null;
  user_team?: user_teams_bool_exp | null;
}

/**
 * input type for inserting data into table "user_team_standups"
 */
export interface user_team_standups_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  standupTime?: hasura_timetz | null;
  startAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  userTeamId?: number | null;
  user_team?: user_teams_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_team_standups"
 */
export interface user_team_standups_on_conflict {
  constraint: user_team_standups_constraint;
  update_columns: user_team_standups_update_column[];
  where?: user_team_standups_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_teams"
 */
export interface user_teams_arr_rel_insert_input {
  data: user_teams_insert_input[];
  on_conflict?: user_teams_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_teams". All fields are combined with a logical 'AND'.
 */
export interface user_teams_bool_exp {
  _and?: user_teams_bool_exp[] | null;
  _not?: user_teams_bool_exp | null;
  _or?: user_teams_bool_exp[] | null;
  child_user_teams?: user_teams_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  googleGroupEmail?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isPublic?: Boolean_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  parentTeamId?: Int_comparison_exp | null;
  parent_user_team?: user_teams_bool_exp | null;
  slack_user_team_channel_mappings?: slack_user_team_channel_mappings_bool_exp | null;
  standupTime?: timestamptz_comparison_exp | null;
  teamLeadUserId?: Int_comparison_exp | null;
  teamName?: String_comparison_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_team_leads?: user_team_leads_bool_exp | null;
  user_team_member_changes?: user_team_member_changes_bool_exp | null;
  user_team_members?: user_team_members_bool_exp | null;
  user_team_standups?: user_team_standups_bool_exp | null;
  workspaceGroupSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_teams"
 */
export interface user_teams_insert_input {
  child_user_teams?: user_teams_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  googleGroupEmail?: string | null;
  id?: number | null;
  isPublic?: boolean | null;
  notifications?: notifications_arr_rel_insert_input | null;
  parentTeamId?: number | null;
  parent_user_team?: user_teams_obj_rel_insert_input | null;
  slack_user_team_channel_mappings?: slack_user_team_channel_mappings_arr_rel_insert_input | null;
  standupTime?: hasura_timestamptz | null;
  teamLeadUserId?: number | null;
  teamName?: string | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_team_clients?: user_team_clients_arr_rel_insert_input | null;
  user_team_leads?: user_team_leads_arr_rel_insert_input | null;
  user_team_member_changes?: user_team_member_changes_arr_rel_insert_input | null;
  user_team_members?: user_team_members_arr_rel_insert_input | null;
  user_team_standups?: user_team_standups_arr_rel_insert_input | null;
  workspaceGroupSid?: string | null;
}

/**
 * input type for inserting object relation for remote table "user_teams"
 */
export interface user_teams_obj_rel_insert_input {
  data: user_teams_insert_input;
  on_conflict?: user_teams_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_teams"
 */
export interface user_teams_on_conflict {
  constraint: user_teams_constraint;
  update_columns: user_teams_update_column[];
  where?: user_teams_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_tech_screens"
 */
export interface user_tech_screens_arr_rel_insert_input {
  data: user_tech_screens_insert_input[];
  on_conflict?: user_tech_screens_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_tech_screens". All fields are combined with a logical 'AND'.
 */
export interface user_tech_screens_bool_exp {
  _and?: user_tech_screens_bool_exp[] | null;
  _not?: user_tech_screens_bool_exp | null;
  _or?: user_tech_screens_bool_exp[] | null;
  attendedAt?: timestamptz_comparison_exp | null;
  communicationScore?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  interviewedById?: Int_comparison_exp | null;
  notes?: String_comparison_exp | null;
  passedTechScreen?: Boolean_comparison_exp | null;
  recordingLink?: String_comparison_exp | null;
  scheduledAt?: timestamptz_comparison_exp | null;
  techSkillsScore?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByUserid?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_tech_screens"
 */
export interface user_tech_screens_insert_input {
  attendedAt?: hasura_timestamptz | null;
  communicationScore?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  interviewedById?: number | null;
  notes?: string | null;
  passedTechScreen?: boolean | null;
  recordingLink?: string | null;
  scheduledAt?: hasura_timestamptz | null;
  techSkillsScore?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByUserid?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "user_tech_screens"
 */
export interface user_tech_screens_on_conflict {
  constraint: user_tech_screens_constraint;
  update_columns: user_tech_screens_update_column[];
  where?: user_tech_screens_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_time_earnings"
 */
export interface user_time_earnings_arr_rel_insert_input {
  data: user_time_earnings_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "user_time_earnings". All fields are combined with a logical 'AND'.
 */
export interface user_time_earnings_bool_exp {
  _and?: user_time_earnings_bool_exp[] | null;
  _not?: user_time_earnings_bool_exp | null;
  _or?: user_time_earnings_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  earningsinusd?: numeric_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  taskId?: Int_comparison_exp | null;
  taskWorkId?: Int_comparison_exp | null;
  timeDoctorTaskLogSid?: String_comparison_exp | null;
  timeSpentInSecs?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userInvoiceId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
  user_invoice?: user_invoices_bool_exp | null;
}

/**
 * input type for inserting data into table "user_time_earnings"
 */
export interface user_time_earnings_insert_input {
  createdAt?: hasura_timestamptz | null;
  earningsinusd?: hasura_numeric | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  startedAt?: hasura_timestamptz | null;
  taskId?: number | null;
  taskWorkId?: number | null;
  timeDoctorTaskLogSid?: string | null;
  timeSpentInSecs?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userInvoiceId?: number | null;
  userLogin?: string | null;
  user_invoice?: user_invoices_obj_rel_insert_input | null;
}

/**
 * input type for inserting array relation for remote table "user_time_logs_view"
 */
export interface user_time_logs_view_arr_rel_insert_input {
  data: user_time_logs_view_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "user_time_logs_view". All fields are combined with a logical 'AND'.
 */
export interface user_time_logs_view_bool_exp {
  _and?: user_time_logs_view_bool_exp[] | null;
  _not?: user_time_logs_view_bool_exp | null;
  _or?: user_time_logs_view_bool_exp[] | null;
  billableToClient?: Boolean_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  finishedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  taskWorkId?: Int_comparison_exp | null;
  task_work?: task_work_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  timeDoctorTaskLogId?: Int_comparison_exp | null;
  timeDoctorTaskLogSid?: String_comparison_exp | null;
  timeSpentInSecs?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userInvoiceId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_time_logs_view"
 */
export interface user_time_logs_view_insert_input {
  billableToClient?: boolean | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  finishedAt?: hasura_timestamptz | null;
  id?: number | null;
  startedAt?: hasura_timestamptz | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  taskWorkId?: number | null;
  task_work?: task_work_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  timeDoctorTaskLogId?: number | null;
  timeDoctorTaskLogSid?: string | null;
  timeSpentInSecs?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
  userInvoiceId?: number | null;
  userLogin?: string | null;
}

/**
 * Boolean expression to filter rows from the table "user_transaction_types". All fields are combined with a logical 'AND'.
 */
export interface user_transaction_types_bool_exp {
  _and?: user_transaction_types_bool_exp[] | null;
  _not?: user_transaction_types_bool_exp | null;
  _or?: user_transaction_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_transactions?: user_transactions_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "user_transaction_types_enum". All fields are combined with logical 'AND'.
 */
export interface user_transaction_types_enum_comparison_exp {
  _eq?: user_transaction_types_enum | null;
  _in?: user_transaction_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: user_transaction_types_enum | null;
  _nin?: user_transaction_types_enum[] | null;
}

/**
 * input type for inserting data into table "user_transaction_types"
 */
export interface user_transaction_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_transactions?: user_transactions_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "user_transaction_types"
 */
export interface user_transaction_types_obj_rel_insert_input {
  data: user_transaction_types_insert_input;
  on_conflict?: user_transaction_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "user_transaction_types"
 */
export interface user_transaction_types_on_conflict {
  constraint: user_transaction_types_constraint;
  update_columns: user_transaction_types_update_column[];
  where?: user_transaction_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_transactions"
 */
export interface user_transactions_arr_rel_insert_input {
  data: user_transactions_insert_input[];
  on_conflict?: user_transactions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_transactions". All fields are combined with a logical 'AND'.
 */
export interface user_transactions_bool_exp {
  _and?: user_transactions_bool_exp[] | null;
  _not?: user_transactions_bool_exp | null;
  _or?: user_transactions_bool_exp[] | null;
  amountInUSD?: Float_comparison_exp | null;
  approved?: Boolean_comparison_exp | null;
  approvedBy?: String_comparison_exp | null;
  approved_by_user?: users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  transactionTypeId?: user_transaction_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userLogin?: String_comparison_exp | null;
  user_transaction_type?: user_transaction_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_transactions"
 */
export interface user_transactions_insert_input {
  amountInUSD?: number | null;
  approved?: boolean | null;
  approvedBy?: string | null;
  approved_by_user?: users_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  occurredAt?: hasura_timestamptz | null;
  transactionTypeId?: user_transaction_types_enum | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userLogin?: string | null;
  user_transaction_type?: user_transaction_types_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_transactions"
 */
export interface user_transactions_on_conflict {
  constraint: user_transactions_constraint;
  update_columns: user_transactions_update_column[];
  where?: user_transactions_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_verification_requests"
 */
export interface user_verification_requests_arr_rel_insert_input {
  data: user_verification_requests_insert_input[];
  on_conflict?: user_verification_requests_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_verification_requests". All fields are combined with a logical 'AND'.
 */
export interface user_verification_requests_bool_exp {
  _and?: user_verification_requests_bool_exp[] | null;
  _not?: user_verification_requests_bool_exp | null;
  _or?: user_verification_requests_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  expiresAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  identifier?: String_comparison_exp | null;
  token?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_verification_requests"
 */
export interface user_verification_requests_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  expiresAt?: hasura_timestamptz | null;
  id?: number | null;
  identifier?: string | null;
  token?: string | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userId?: number | null;
}

/**
 * on_conflict condition type for table "user_verification_requests"
 */
export interface user_verification_requests_on_conflict {
  constraint: user_verification_requests_constraint;
  update_columns: user_verification_requests_update_column[];
  where?: user_verification_requests_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_work_logs"
 */
export interface user_work_logs_arr_rel_insert_input {
  data: user_work_logs_insert_input[];
  on_conflict?: user_work_logs_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_work_logs". All fields are combined with a logical 'AND'.
 */
export interface user_work_logs_bool_exp {
  _and?: user_work_logs_bool_exp[] | null;
  _not?: user_work_logs_bool_exp | null;
  _or?: user_work_logs_bool_exp[] | null;
  activity_id?: bigint_comparison_exp | null;
  approvalStatus?: work_log_approval_status_types_enum_comparison_exp | null;
  approvedBy?: Int_comparison_exp | null;
  billableToClient?: Boolean_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  meetingType?: work_log_meeting_types_enum_comparison_exp | null;
  source?: String_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  status?: work_log_status_types_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
  technologyId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userByApprovedby?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  workDescription?: String_comparison_exp | null;
  workType?: work_log_types_enum_comparison_exp | null;
  work_log_approval_status_type?: work_log_approval_status_types_bool_exp | null;
  work_log_meeting_type?: work_log_meeting_types_bool_exp | null;
  work_log_status_type?: work_log_status_types_bool_exp | null;
  work_log_type?: work_log_types_bool_exp | null;
}

/**
 * input type for inserting data into table "user_work_logs"
 */
export interface user_work_logs_insert_input {
  activity_id?: hasura_bigint | null;
  approvalStatus?: work_log_approval_status_types_enum | null;
  approvedBy?: number | null;
  billableToClient?: boolean | null;
  client?: clients_obj_rel_insert_input | null;
  clientId?: string | null;
  clientProjectId?: number | null;
  client_project?: client_projects_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  meetingType?: work_log_meeting_types_enum | null;
  source?: string | null;
  startAt?: hasura_timestamptz | null;
  status?: work_log_status_types_enum | null;
  task?: tasks_obj_rel_insert_input | null;
  taskId?: number | null;
  technology?: technologies_obj_rel_insert_input | null;
  technologyId?: number | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  userByApprovedby?: users_obj_rel_insert_input | null;
  userId?: number | null;
  workDescription?: string | null;
  workType?: work_log_types_enum | null;
  work_log_approval_status_type?: work_log_approval_status_types_obj_rel_insert_input | null;
  work_log_meeting_type?: work_log_meeting_types_obj_rel_insert_input | null;
  work_log_status_type?: work_log_status_types_obj_rel_insert_input | null;
  work_log_type?: work_log_types_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "user_work_logs"
 */
export interface user_work_logs_on_conflict {
  constraint: user_work_logs_constraint;
  update_columns: user_work_logs_update_column[];
  where?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "users"
 */
export interface users_arr_rel_insert_input {
  data: users_insert_input[];
  on_conflict?: users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'.
 */
export interface users_bool_exp {
  _and?: users_bool_exp[] | null;
  _not?: users_bool_exp | null;
  _or?: users_bool_exp[] | null;
  activities?: activities_bool_exp | null;
  admin?: admin_bool_exp | null;
  admins?: admin_bool_exp | null;
  adminsByUserid?: admin_bool_exp | null;
  agency_user?: agency_users_bool_exp | null;
  agency_users?: agency_users_bool_exp | null;
  auth0Sid?: String_comparison_exp | null;
  avatarUrl?: String_comparison_exp | null;
  blockers?: blockers_bool_exp | null;
  changedTaskCreditApprovals?: task_credit_approval_changes_bool_exp | null;
  clientProjectChangesByChangedbyuserid?: client_project_changes_bool_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_changes?: client_changes_bool_exp | null;
  client_daily_standups?: client_daily_standups_bool_exp | null;
  client_invoice_changes?: client_invoice_changes_bool_exp | null;
  client_managers_view?: client_managers_view_bool_exp | null;
  client_project_changes?: client_project_changes_bool_exp | null;
  client_project_managers?: client_project_managers_bool_exp | null;
  client_project_users?: client_project_users_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  client_users?: client_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  dateOfBirth?: date_comparison_exp | null;
  defaultEmail?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  dev_users?: user_developer_view_bool_exp | null;
  developer?: developers_bool_exp | null;
  developerChangesById?: developer_changes_bool_exp | null;
  developerChangesByLogin?: developer_changes_bool_exp | null;
  developer_bonus_earnings?: developer_bonus_earning_bool_exp | null;
  developer_changes?: developer_changes_bool_exp | null;
  developer_weekly_earnings?: developer_weekly_earning_bool_exp | null;
  developers?: developers_bool_exp | null;
  discussion_comment_reads?: discussion_comment_reads_bool_exp | null;
  discussion_comments?: discussion_comments_bool_exp | null;
  firstName?: String_comparison_exp | null;
  gcp_storage_objects?: gcp_storage_objects_bool_exp | null;
  git_pull_request_changes?: git_pull_request_changes_bool_exp | null;
  git_repo_changes?: git_repo_changes_bool_exp | null;
  git_repo_slice_changes?: git_repo_slice_changes_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  github_issue_changes?: github_issue_changes_bool_exp | null;
  id?: Int_comparison_exp | null;
  intercomUserId?: String_comparison_exp | null;
  interviewers?: interviewers_bool_exp | null;
  jira_app_installations?: jira_app_installations_bool_exp | null;
  jira_issue_changes?: jira_issue_changes_bool_exp | null;
  lastName?: String_comparison_exp | null;
  linear_issue_changes?: linear_issue_changes_bool_exp | null;
  login?: String_comparison_exp | null;
  mentors?: mentors_bool_exp | null;
  mentorships?: mentorships_bool_exp | null;
  mentorshipsByMentoruserid?: mentorships_bool_exp | null;
  name?: String_comparison_exp | null;
  nickname?: String_comparison_exp | null;
  notifications?: notifications_bool_exp | null;
  passwordHash?: String_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  project_managers?: project_managers_bool_exp | null;
  pullRequestEstimatesByReviewedById?: pull_request_estimates_bool_exp | null;
  pull_request_estimates?: pull_request_estimates_bool_exp | null;
  repository_owners?: repository_owners_bool_exp | null;
  requestedTaskCreditApprovalChanges?: task_credit_approval_changes_bool_exp | null;
  requestedTaskCreditApprovals?: task_credit_approval_bool_exp | null;
  reviewedTaskCreditApprovalChanges?: task_credit_approval_changes_bool_exp | null;
  reviewedTaskCreditApprovals?: task_credit_approval_bool_exp | null;
  reviews?: reviews_bool_exp | null;
  reviewsByRevieweelogin?: reviews_bool_exp | null;
  ripplingEmployeeId?: Int_comparison_exp | null;
  rippling_employee?: rippling_employees_bool_exp | null;
  task_changes?: task_changes_bool_exp | null;
  task_code_comments?: task_code_comments_bool_exp | null;
  task_credit_overrides?: task_credit_overrides_bool_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  task_status_changes?: task_status_changes_bool_exp | null;
  task_submissions?: task_submissions_bool_exp | null;
  telephone?: String_comparison_exp | null;
  ticketCreditApprovalChangesByRequestedbyuserid?: ticket_credit_approval_changes_bool_exp | null;
  ticketCreditApprovalChangesByReviewedbyuserid?: ticket_credit_approval_changes_bool_exp | null;
  ticketCreditApprovalsByReviewedbyuserid?: ticket_credit_approvals_bool_exp | null;
  ticket_billable_overrides?: ticket_billable_overrides_bool_exp | null;
  ticket_changes?: ticket_changes_bool_exp | null;
  ticket_credit_approval_changes?: ticket_credit_approval_changes_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  ticket_refunds?: ticket_refund_bool_exp | null;
  ticket_scope_change_requests?: ticket_scope_change_requests_bool_exp | null;
  ticket_source_changes?: ticket_source_changes_bool_exp | null;
  ticket_status_changes?: ticket_status_changes_bool_exp | null;
  timezone?: String_comparison_exp | null;
  unverifiedEmail?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userChangesById?: user_changes_bool_exp | null;
  userCultureScreensByDeterminedbyuserid?: user_culture_screens_bool_exp | null;
  userEmailByDefaultEmail?: user_emails_bool_exp | null;
  userEmailByUnverifiedemail?: user_emails_bool_exp | null;
  userHackweekScreensByUserid?: user_hackweek_screens_bool_exp | null;
  userLeavesByApprovedByUserId?: user_leaves_bool_exp | null;
  userTeamMemberChangesByUserid?: user_team_member_changes_bool_exp | null;
  userTechScreensByUserid?: user_tech_screens_bool_exp | null;
  userTransactionsByApprovedby?: user_transactions_bool_exp | null;
  userWorkLogsByApprovedby?: user_work_logs_bool_exp | null;
  user_billable_hours?: user_billable_hours_bool_exp | null;
  user_changes?: user_changes_bool_exp | null;
  user_coefficients?: user_coefficients_bool_exp | null;
  user_contracts?: user_contracts_bool_exp | null;
  user_culture_screens?: user_culture_screens_bool_exp | null;
  user_daily_earnings?: user_daily_earnings_bool_exp | null;
  user_daily_facts_structures?: user_daily_facts_structure_bool_exp | null;
  user_daily_plans?: user_daily_plans_bool_exp | null;
  user_daily_retrospectives?: user_daily_retrospectives_bool_exp | null;
  user_daily_standups?: user_daily_standups_bool_exp | null;
  user_emails?: user_emails_bool_exp | null;
  user_events?: user_events_bool_exp | null;
  user_facts_structures?: user_facts_structure_bool_exp | null;
  user_hackweek_screens?: user_hackweek_screens_bool_exp | null;
  user_invoice_balances?: user_invoice_balances_bool_exp | null;
  user_invoices?: user_invoices_bool_exp | null;
  user_leaves?: user_leaves_bool_exp | null;
  user_onboarded_repos?: user_onboarded_repos_bool_exp | null;
  user_opportunity_interests?: user_opportunity_interests_bool_exp | null;
  user_payments?: user_payments_bool_exp | null;
  user_profiles?: user_profiles_bool_exp | null;
  user_sessions?: user_sessions_bool_exp | null;
  user_skills?: user_skills_bool_exp | null;
  user_standups_sla?: user_standups_sla_view_bool_exp | null;
  user_team_clients?: user_team_clients_bool_exp | null;
  user_team_leads?: user_team_leads_bool_exp | null;
  user_team_member_changes?: user_team_member_changes_bool_exp | null;
  user_team_members?: user_team_members_bool_exp | null;
  user_teams?: user_teams_bool_exp | null;
  user_tech_screens?: user_tech_screens_bool_exp | null;
  user_time_earnings?: user_time_earnings_bool_exp | null;
  user_time_logs?: user_time_logs_view_bool_exp | null;
  user_transactions?: user_transactions_bool_exp | null;
  user_verification_requests?: user_verification_requests_bool_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * input type for inserting data into table "users"
 */
export interface users_insert_input {
  activities?: activities_arr_rel_insert_input | null;
  admin?: admin_obj_rel_insert_input | null;
  admins?: admin_arr_rel_insert_input | null;
  adminsByUserid?: admin_arr_rel_insert_input | null;
  agency_user?: agency_users_obj_rel_insert_input | null;
  agency_users?: agency_users_arr_rel_insert_input | null;
  auth0Sid?: string | null;
  avatarUrl?: string | null;
  blockers?: blockers_arr_rel_insert_input | null;
  changedTaskCreditApprovals?: task_credit_approval_changes_arr_rel_insert_input | null;
  clientProjectChangesByChangedbyuserid?: client_project_changes_arr_rel_insert_input | null;
  client_blockers?: client_blockers_arr_rel_insert_input | null;
  client_changes?: client_changes_arr_rel_insert_input | null;
  client_daily_standups?: client_daily_standups_arr_rel_insert_input | null;
  client_invoice_changes?: client_invoice_changes_arr_rel_insert_input | null;
  client_managers_view?: client_managers_view_arr_rel_insert_input | null;
  client_project_changes?: client_project_changes_arr_rel_insert_input | null;
  client_project_managers?: client_project_managers_arr_rel_insert_input | null;
  client_project_users?: client_project_users_arr_rel_insert_input | null;
  client_transactions?: client_transactions_arr_rel_insert_input | null;
  client_users?: client_users_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  dateOfBirth?: hasura_date | null;
  defaultEmail?: string | null;
  deletedAt?: hasura_timestamptz | null;
  dev_users?: user_developer_view_obj_rel_insert_input | null;
  developer?: developers_obj_rel_insert_input | null;
  developerChangesById?: developer_changes_arr_rel_insert_input | null;
  developerChangesByLogin?: developer_changes_arr_rel_insert_input | null;
  developer_bonus_earnings?: developer_bonus_earning_arr_rel_insert_input | null;
  developer_changes?: developer_changes_arr_rel_insert_input | null;
  developer_weekly_earnings?: developer_weekly_earning_arr_rel_insert_input | null;
  developers?: developers_arr_rel_insert_input | null;
  discussion_comment_reads?: discussion_comment_reads_arr_rel_insert_input | null;
  discussion_comments?: discussion_comments_arr_rel_insert_input | null;
  firstName?: string | null;
  gcp_storage_objects?: gcp_storage_objects_arr_rel_insert_input | null;
  git_pull_request_changes?: git_pull_request_changes_arr_rel_insert_input | null;
  git_repo_changes?: git_repo_changes_arr_rel_insert_input | null;
  git_repo_slice_changes?: git_repo_slice_changes_arr_rel_insert_input | null;
  git_users?: git_users_arr_rel_insert_input | null;
  github_issue_changes?: github_issue_changes_arr_rel_insert_input | null;
  id?: number | null;
  intercomUserId?: string | null;
  interviewers?: interviewers_arr_rel_insert_input | null;
  jira_app_installations?: jira_app_installations_arr_rel_insert_input | null;
  jira_issue_changes?: jira_issue_changes_arr_rel_insert_input | null;
  lastName?: string | null;
  linear_issue_changes?: linear_issue_changes_arr_rel_insert_input | null;
  login?: string | null;
  mentors?: mentors_arr_rel_insert_input | null;
  mentorships?: mentorships_arr_rel_insert_input | null;
  mentorshipsByMentoruserid?: mentorships_arr_rel_insert_input | null;
  name?: string | null;
  nickname?: string | null;
  notifications?: notifications_arr_rel_insert_input | null;
  passwordHash?: string | null;
  project_daily_standups?: project_daily_standup_arr_rel_insert_input | null;
  project_managers?: project_managers_arr_rel_insert_input | null;
  pullRequestEstimatesByReviewedById?: pull_request_estimates_arr_rel_insert_input | null;
  pull_request_estimates?: pull_request_estimates_arr_rel_insert_input | null;
  repository_owners?: repository_owners_arr_rel_insert_input | null;
  requestedTaskCreditApprovalChanges?: task_credit_approval_changes_arr_rel_insert_input | null;
  requestedTaskCreditApprovals?: task_credit_approval_arr_rel_insert_input | null;
  reviewedTaskCreditApprovalChanges?: task_credit_approval_changes_arr_rel_insert_input | null;
  reviewedTaskCreditApprovals?: task_credit_approval_arr_rel_insert_input | null;
  reviews?: reviews_arr_rel_insert_input | null;
  reviewsByRevieweelogin?: reviews_arr_rel_insert_input | null;
  ripplingEmployeeId?: number | null;
  rippling_employee?: rippling_employees_obj_rel_insert_input | null;
  task_changes?: task_changes_arr_rel_insert_input | null;
  task_code_comments?: task_code_comments_arr_rel_insert_input | null;
  task_credit_overrides?: task_credit_overrides_arr_rel_insert_input | null;
  task_daily_standups?: task_daily_standups_arr_rel_insert_input | null;
  task_status_changes?: task_status_changes_arr_rel_insert_input | null;
  task_submissions?: task_submissions_arr_rel_insert_input | null;
  telephone?: string | null;
  ticketCreditApprovalChangesByRequestedbyuserid?: ticket_credit_approval_changes_arr_rel_insert_input | null;
  ticketCreditApprovalChangesByReviewedbyuserid?: ticket_credit_approval_changes_arr_rel_insert_input | null;
  ticketCreditApprovalsByReviewedbyuserid?: ticket_credit_approvals_arr_rel_insert_input | null;
  ticket_billable_overrides?: ticket_billable_overrides_arr_rel_insert_input | null;
  ticket_changes?: ticket_changes_arr_rel_insert_input | null;
  ticket_credit_approval_changes?: ticket_credit_approval_changes_arr_rel_insert_input | null;
  ticket_credit_approvals?: ticket_credit_approvals_arr_rel_insert_input | null;
  ticket_daily_standups?: ticket_daily_standups_arr_rel_insert_input | null;
  ticket_refunds?: ticket_refund_arr_rel_insert_input | null;
  ticket_scope_change_requests?: ticket_scope_change_requests_arr_rel_insert_input | null;
  ticket_source_changes?: ticket_source_changes_arr_rel_insert_input | null;
  ticket_status_changes?: ticket_status_changes_arr_rel_insert_input | null;
  timezone?: string | null;
  unverifiedEmail?: string | null;
  updatedAt?: hasura_timestamptz | null;
  userChangesById?: user_changes_arr_rel_insert_input | null;
  userCultureScreensByDeterminedbyuserid?: user_culture_screens_arr_rel_insert_input | null;
  userEmailByDefaultEmail?: user_emails_obj_rel_insert_input | null;
  userEmailByUnverifiedemail?: user_emails_obj_rel_insert_input | null;
  userHackweekScreensByUserid?: user_hackweek_screens_arr_rel_insert_input | null;
  userLeavesByApprovedByUserId?: user_leaves_arr_rel_insert_input | null;
  userTeamMemberChangesByUserid?: user_team_member_changes_arr_rel_insert_input | null;
  userTechScreensByUserid?: user_tech_screens_arr_rel_insert_input | null;
  userTransactionsByApprovedby?: user_transactions_arr_rel_insert_input | null;
  userWorkLogsByApprovedby?: user_work_logs_arr_rel_insert_input | null;
  user_billable_hours?: user_billable_hours_arr_rel_insert_input | null;
  user_changes?: user_changes_arr_rel_insert_input | null;
  user_coefficients?: user_coefficients_arr_rel_insert_input | null;
  user_contracts?: user_contracts_arr_rel_insert_input | null;
  user_culture_screens?: user_culture_screens_arr_rel_insert_input | null;
  user_daily_earnings?: user_daily_earnings_arr_rel_insert_input | null;
  user_daily_facts_structures?: user_daily_facts_structure_arr_rel_insert_input | null;
  user_daily_plans?: user_daily_plans_arr_rel_insert_input | null;
  user_daily_retrospectives?: user_daily_retrospectives_arr_rel_insert_input | null;
  user_daily_standups?: user_daily_standups_arr_rel_insert_input | null;
  user_emails?: user_emails_arr_rel_insert_input | null;
  user_events?: user_events_arr_rel_insert_input | null;
  user_facts_structures?: user_facts_structure_arr_rel_insert_input | null;
  user_hackweek_screens?: user_hackweek_screens_arr_rel_insert_input | null;
  user_invoice_balances?: user_invoice_balances_arr_rel_insert_input | null;
  user_invoices?: user_invoices_arr_rel_insert_input | null;
  user_leaves?: user_leaves_arr_rel_insert_input | null;
  user_onboarded_repos?: user_onboarded_repos_arr_rel_insert_input | null;
  user_opportunity_interests?: user_opportunity_interests_arr_rel_insert_input | null;
  user_payments?: user_payments_arr_rel_insert_input | null;
  user_profiles?: user_profiles_arr_rel_insert_input | null;
  user_sessions?: user_sessions_arr_rel_insert_input | null;
  user_skills?: user_skills_arr_rel_insert_input | null;
  user_standups_sla?: user_standups_sla_view_obj_rel_insert_input | null;
  user_team_clients?: user_team_clients_arr_rel_insert_input | null;
  user_team_leads?: user_team_leads_arr_rel_insert_input | null;
  user_team_member_changes?: user_team_member_changes_arr_rel_insert_input | null;
  user_team_members?: user_team_members_arr_rel_insert_input | null;
  user_teams?: user_teams_arr_rel_insert_input | null;
  user_tech_screens?: user_tech_screens_arr_rel_insert_input | null;
  user_time_earnings?: user_time_earnings_arr_rel_insert_input | null;
  user_time_logs?: user_time_logs_view_arr_rel_insert_input | null;
  user_transactions?: user_transactions_arr_rel_insert_input | null;
  user_verification_requests?: user_verification_requests_arr_rel_insert_input | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "users"
 */
export interface users_obj_rel_insert_input {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null;
}

/**
 * on_conflict condition type for table "users"
 */
export interface users_on_conflict {
  constraint: users_constraint;
  update_columns: users_update_column[];
  where?: users_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: hasura_uuid | null;
  _gt?: hasura_uuid | null;
  _gte?: hasura_uuid | null;
  _in?: hasura_uuid[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_uuid | null;
  _lte?: hasura_uuid | null;
  _neq?: hasura_uuid | null;
  _nin?: hasura_uuid[] | null;
}

/**
 * input type for inserting array relation for remote table "webhook_event_errors"
 */
export interface webhook_event_errors_arr_rel_insert_input {
  data: webhook_event_errors_insert_input[];
  on_conflict?: webhook_event_errors_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "webhook_event_errors". All fields are combined with a logical 'AND'.
 */
export interface webhook_event_errors_bool_exp {
  _and?: webhook_event_errors_bool_exp[] | null;
  _not?: webhook_event_errors_bool_exp | null;
  _or?: webhook_event_errors_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  expiresAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  mergeError?: jsonb_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhook_event?: webhook_events_bool_exp | null;
  webhook_event_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_event_errors"
 */
export interface webhook_event_errors_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  expiresAt?: hasura_timestamptz | null;
  id?: number | null;
  mergeError?: hasura_jsonb | null;
  occurredAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  webhook_event?: webhook_events_obj_rel_insert_input | null;
  webhook_event_id?: number | null;
}

/**
 * on_conflict condition type for table "webhook_event_errors"
 */
export interface webhook_event_errors_on_conflict {
  constraint: webhook_event_errors_constraint;
  update_columns: webhook_event_errors_update_column[];
  where?: webhook_event_errors_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "webhook_events"
 */
export interface webhook_events_arr_rel_insert_input {
  data: webhook_events_insert_input[];
  on_conflict?: webhook_events_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "webhook_events". All fields are combined with a logical 'AND'.
 */
export interface webhook_events_bool_exp {
  _and?: webhook_events_bool_exp[] | null;
  _not?: webhook_events_bool_exp | null;
  _or?: webhook_events_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubType?: String_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  headers?: jsonb_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isIgnored?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhook?: webhooks_bool_exp | null;
  webhookId?: Int_comparison_exp | null;
  webhook_event_errors?: webhook_event_errors_bool_exp | null;
  webhook_event_for_github_issue?: webhook_events_for_github_issues_bool_exp | null;
  webhook_event_for_github_pull_request?: webhook_events_for_github_pull_requests_bool_exp | null;
  webhook_event_for_github_pull_request_review?: webhook_events_for_github_pull_request_reviews_bool_exp | null;
  webhook_event_parsed?: webhook_events_parsed_bool_exp | null;
  webhook_event_to_process?: webhook_events_to_process_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "webhook_events_for_github_issues". All fields are combined with a logical 'AND'.
 */
export interface webhook_events_for_github_issues_bool_exp {
  _and?: webhook_events_for_github_issues_bool_exp[] | null;
  _not?: webhook_events_for_github_issues_bool_exp | null;
  _or?: webhook_events_for_github_issues_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubType?: String_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  githubIssueSid?: String_comparison_exp | null;
  headers?: jsonb_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isIgnored?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  parsedSid?: String_comparison_exp | null;
  parsedTableName?: String_comparison_exp | null;
  parsedUrl?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncableEntityId?: Int_comparison_exp | null;
  syncableEntitySyncedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhookId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_events_for_github_issues"
 */
export interface webhook_events_for_github_issues_insert_input {
  createdAt?: hasura_timestamptz | null;
  data?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubType?: string | null;
  eventType?: string | null;
  githubIssueSid?: string | null;
  headers?: hasura_jsonb | null;
  id?: number | null;
  isIgnored?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  occurredAt?: hasura_timestamptz | null;
  parsedSid?: string | null;
  parsedTableName?: string | null;
  parsedUrl?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncableEntityId?: number | null;
  syncableEntitySyncedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  webhookId?: number | null;
}

/**
 * input type for inserting object relation for remote table "webhook_events_for_github_issues"
 */
export interface webhook_events_for_github_issues_obj_rel_insert_input {
  data: webhook_events_for_github_issues_insert_input;
}

/**
 * Boolean expression to filter rows from the table "webhook_events_for_github_pull_request_reviews". All fields are combined with a logical 'AND'.
 */
export interface webhook_events_for_github_pull_request_reviews_bool_exp {
  _and?: webhook_events_for_github_pull_request_reviews_bool_exp[] | null;
  _not?: webhook_events_for_github_pull_request_reviews_bool_exp | null;
  _or?: webhook_events_for_github_pull_request_reviews_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubType?: String_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  githubPullRequestReviewSid?: String_comparison_exp | null;
  headers?: jsonb_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isIgnored?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  parsedSid?: String_comparison_exp | null;
  parsedTableName?: String_comparison_exp | null;
  parsedUrl?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncableEntityId?: Int_comparison_exp | null;
  syncableEntitySyncedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhookId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_events_for_github_pull_request_reviews"
 */
export interface webhook_events_for_github_pull_request_reviews_insert_input {
  createdAt?: hasura_timestamptz | null;
  data?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubType?: string | null;
  eventType?: string | null;
  githubPullRequestReviewSid?: string | null;
  headers?: hasura_jsonb | null;
  id?: number | null;
  isIgnored?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  occurredAt?: hasura_timestamptz | null;
  parsedSid?: string | null;
  parsedTableName?: string | null;
  parsedUrl?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncableEntityId?: number | null;
  syncableEntitySyncedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  webhookId?: number | null;
}

/**
 * input type for inserting object relation for remote table "webhook_events_for_github_pull_request_reviews"
 */
export interface webhook_events_for_github_pull_request_reviews_obj_rel_insert_input {
  data: webhook_events_for_github_pull_request_reviews_insert_input;
}

/**
 * Boolean expression to filter rows from the table "webhook_events_for_github_pull_requests". All fields are combined with a logical 'AND'.
 */
export interface webhook_events_for_github_pull_requests_bool_exp {
  _and?: webhook_events_for_github_pull_requests_bool_exp[] | null;
  _not?: webhook_events_for_github_pull_requests_bool_exp | null;
  _or?: webhook_events_for_github_pull_requests_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubType?: String_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  githubPullRequestSid?: String_comparison_exp | null;
  headers?: jsonb_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isIgnored?: Boolean_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  parsedSid?: String_comparison_exp | null;
  parsedTableName?: String_comparison_exp | null;
  parsedUrl?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncableEntityId?: Int_comparison_exp | null;
  syncableEntitySyncedAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhookId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_events_for_github_pull_requests"
 */
export interface webhook_events_for_github_pull_requests_insert_input {
  createdAt?: hasura_timestamptz | null;
  data?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubType?: string | null;
  eventType?: string | null;
  githubPullRequestSid?: string | null;
  headers?: hasura_jsonb | null;
  id?: number | null;
  isIgnored?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  occurredAt?: hasura_timestamptz | null;
  parsedSid?: string | null;
  parsedTableName?: string | null;
  parsedUrl?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncableEntityId?: number | null;
  syncableEntitySyncedAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  webhookId?: number | null;
}

/**
 * input type for inserting object relation for remote table "webhook_events_for_github_pull_requests"
 */
export interface webhook_events_for_github_pull_requests_obj_rel_insert_input {
  data: webhook_events_for_github_pull_requests_insert_input;
}

/**
 * input type for inserting data into table "webhook_events"
 */
export interface webhook_events_insert_input {
  createdAt?: hasura_timestamptz | null;
  data?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubType?: string | null;
  eventType?: string | null;
  headers?: hasura_jsonb | null;
  id?: number | null;
  isIgnored?: boolean | null;
  mergedAt?: hasura_timestamptz | null;
  occurredAt?: hasura_timestamptz | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  webhook?: webhooks_obj_rel_insert_input | null;
  webhookId?: number | null;
  webhook_event_errors?: webhook_event_errors_arr_rel_insert_input | null;
  webhook_event_for_github_issue?: webhook_events_for_github_issues_obj_rel_insert_input | null;
  webhook_event_for_github_pull_request?: webhook_events_for_github_pull_requests_obj_rel_insert_input | null;
  webhook_event_for_github_pull_request_review?: webhook_events_for_github_pull_request_reviews_obj_rel_insert_input | null;
  webhook_event_parsed?: webhook_events_parsed_obj_rel_insert_input | null;
  webhook_event_to_process?: webhook_events_to_process_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "webhook_events"
 */
export interface webhook_events_obj_rel_insert_input {
  data: webhook_events_insert_input;
  on_conflict?: webhook_events_on_conflict | null;
}

/**
 * on_conflict condition type for table "webhook_events"
 */
export interface webhook_events_on_conflict {
  constraint: webhook_events_constraint;
  update_columns: webhook_events_update_column[];
  where?: webhook_events_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "webhook_events_parsed"
 */
export interface webhook_events_parsed_arr_rel_insert_input {
  data: webhook_events_parsed_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "webhook_events_parsed". All fields are combined with a logical 'AND'.
 */
export interface webhook_events_parsed_bool_exp {
  _and?: webhook_events_parsed_bool_exp[] | null;
  _not?: webhook_events_parsed_bool_exp | null;
  _or?: webhook_events_parsed_bool_exp[] | null;
  can_process_event?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubType?: String_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  headers?: jsonb_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isIgnored?: Boolean_comparison_exp | null;
  last_error_expires_at?: timestamptz_comparison_exp | null;
  last_error_occurred_at?: timestamptz_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  parsedSid?: String_comparison_exp | null;
  parsedTableName?: String_comparison_exp | null;
  parsedUrl?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncableEntityId?: Int_comparison_exp | null;
  syncableEntitySyncedAt?: timestamptz_comparison_exp | null;
  total_errors?: bigint_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhookId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_events_parsed"
 */
export interface webhook_events_parsed_insert_input {
  can_process_event?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  data?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubType?: string | null;
  eventType?: string | null;
  headers?: hasura_jsonb | null;
  id?: number | null;
  isIgnored?: boolean | null;
  last_error_expires_at?: hasura_timestamptz | null;
  last_error_occurred_at?: hasura_timestamptz | null;
  mergedAt?: hasura_timestamptz | null;
  occurredAt?: hasura_timestamptz | null;
  parsedSid?: string | null;
  parsedTableName?: string | null;
  parsedUrl?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncableEntityId?: number | null;
  syncableEntitySyncedAt?: hasura_timestamptz | null;
  total_errors?: hasura_bigint | null;
  updatedAt?: hasura_timestamptz | null;
  webhookId?: number | null;
}

/**
 * input type for inserting object relation for remote table "webhook_events_parsed"
 */
export interface webhook_events_parsed_obj_rel_insert_input {
  data: webhook_events_parsed_insert_input;
}

/**
 * input type for inserting array relation for remote table "webhook_events_to_process"
 */
export interface webhook_events_to_process_arr_rel_insert_input {
  data: webhook_events_to_process_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "webhook_events_to_process". All fields are combined with a logical 'AND'.
 */
export interface webhook_events_to_process_bool_exp {
  _and?: webhook_events_to_process_bool_exp[] | null;
  _not?: webhook_events_to_process_bool_exp | null;
  _or?: webhook_events_to_process_bool_exp[] | null;
  can_process_event?: Boolean_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubType?: String_comparison_exp | null;
  eventType?: String_comparison_exp | null;
  headers?: jsonb_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isIgnored?: Boolean_comparison_exp | null;
  last_error_expires_at?: timestamptz_comparison_exp | null;
  last_error_occurred_at?: timestamptz_comparison_exp | null;
  mergedAt?: timestamptz_comparison_exp | null;
  occurredAt?: timestamptz_comparison_exp | null;
  parsedSid?: String_comparison_exp | null;
  parsedTableName?: String_comparison_exp | null;
  parsedUrl?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncableEntityId?: Int_comparison_exp | null;
  syncableEntitySyncedAt?: timestamptz_comparison_exp | null;
  total_errors?: bigint_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhookId?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_events_to_process"
 */
export interface webhook_events_to_process_insert_input {
  can_process_event?: boolean | null;
  createdAt?: hasura_timestamptz | null;
  data?: hasura_jsonb | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubType?: string | null;
  eventType?: string | null;
  headers?: hasura_jsonb | null;
  id?: number | null;
  isIgnored?: boolean | null;
  last_error_expires_at?: hasura_timestamptz | null;
  last_error_occurred_at?: hasura_timestamptz | null;
  mergedAt?: hasura_timestamptz | null;
  occurredAt?: hasura_timestamptz | null;
  parsedSid?: string | null;
  parsedTableName?: string | null;
  parsedUrl?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncableEntityId?: number | null;
  syncableEntitySyncedAt?: hasura_timestamptz | null;
  total_errors?: hasura_bigint | null;
  updatedAt?: hasura_timestamptz | null;
  webhookId?: number | null;
}

/**
 * input type for inserting object relation for remote table "webhook_events_to_process"
 */
export interface webhook_events_to_process_obj_rel_insert_input {
  data: webhook_events_to_process_insert_input;
}

/**
 * input type for inserting array relation for remote table "webhooks"
 */
export interface webhooks_arr_rel_insert_input {
  data: webhooks_insert_input[];
  on_conflict?: webhooks_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "webhooks". All fields are combined with a logical 'AND'.
 */
export interface webhooks_bool_exp {
  _and?: webhooks_bool_exp[] | null;
  _not?: webhooks_bool_exp | null;
  _or?: webhooks_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  eventSubTypeJSONPath?: String_comparison_exp | null;
  eventTypeJSONPath?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isActive?: Boolean_comparison_exp | null;
  secret?: String_comparison_exp | null;
  source?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhook_events?: webhook_events_bool_exp | null;
  webhook_events_parsed?: webhook_events_parsed_bool_exp | null;
  webhook_events_to_process?: webhook_events_to_process_bool_exp | null;
  zoomAppId?: Int_comparison_exp | null;
  zoom_app?: zoom_app_bool_exp | null;
}

/**
 * input type for inserting data into table "webhooks"
 */
export interface webhooks_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  eventSubTypeJSONPath?: string | null;
  eventTypeJSONPath?: string | null;
  id?: number | null;
  isActive?: boolean | null;
  secret?: string | null;
  source?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  webhook_events?: webhook_events_arr_rel_insert_input | null;
  webhook_events_parsed?: webhook_events_parsed_arr_rel_insert_input | null;
  webhook_events_to_process?: webhook_events_to_process_arr_rel_insert_input | null;
  zoomAppId?: number | null;
  zoom_app?: zoom_app_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "webhooks"
 */
export interface webhooks_obj_rel_insert_input {
  data: webhooks_insert_input;
  on_conflict?: webhooks_on_conflict | null;
}

/**
 * on_conflict condition type for table "webhooks"
 */
export interface webhooks_on_conflict {
  constraint: webhooks_constraint;
  update_columns: webhooks_update_column[];
  where?: webhooks_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_approval_status_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_approval_status_types_bool_exp {
  _and?: work_log_approval_status_types_bool_exp[] | null;
  _not?: work_log_approval_status_types_bool_exp | null;
  _or?: work_log_approval_status_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_approval_status_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_approval_status_types_enum_comparison_exp {
  _eq?: work_log_approval_status_types_enum | null;
  _in?: work_log_approval_status_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_approval_status_types_enum | null;
  _nin?: work_log_approval_status_types_enum[] | null;
}

/**
 * input type for inserting data into table "work_log_approval_status_types"
 */
export interface work_log_approval_status_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "work_log_approval_status_types"
 */
export interface work_log_approval_status_types_obj_rel_insert_input {
  data: work_log_approval_status_types_insert_input;
  on_conflict?: work_log_approval_status_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "work_log_approval_status_types"
 */
export interface work_log_approval_status_types_on_conflict {
  constraint: work_log_approval_status_types_constraint;
  update_columns: work_log_approval_status_types_update_column[];
  where?: work_log_approval_status_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_meeting_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_meeting_types_bool_exp {
  _and?: work_log_meeting_types_bool_exp[] | null;
  _not?: work_log_meeting_types_bool_exp | null;
  _or?: work_log_meeting_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_meeting_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_meeting_types_enum_comparison_exp {
  _eq?: work_log_meeting_types_enum | null;
  _in?: work_log_meeting_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_meeting_types_enum | null;
  _nin?: work_log_meeting_types_enum[] | null;
}

/**
 * input type for inserting data into table "work_log_meeting_types"
 */
export interface work_log_meeting_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "work_log_meeting_types"
 */
export interface work_log_meeting_types_obj_rel_insert_input {
  data: work_log_meeting_types_insert_input;
  on_conflict?: work_log_meeting_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "work_log_meeting_types"
 */
export interface work_log_meeting_types_on_conflict {
  constraint: work_log_meeting_types_constraint;
  update_columns: work_log_meeting_types_update_column[];
  where?: work_log_meeting_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_status_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_status_types_bool_exp {
  _and?: work_log_status_types_bool_exp[] | null;
  _not?: work_log_status_types_bool_exp | null;
  _or?: work_log_status_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_status_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_status_types_enum_comparison_exp {
  _eq?: work_log_status_types_enum | null;
  _in?: work_log_status_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_status_types_enum | null;
  _nin?: work_log_status_types_enum[] | null;
}

/**
 * input type for inserting data into table "work_log_status_types"
 */
export interface work_log_status_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "work_log_status_types"
 */
export interface work_log_status_types_obj_rel_insert_input {
  data: work_log_status_types_insert_input;
  on_conflict?: work_log_status_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "work_log_status_types"
 */
export interface work_log_status_types_on_conflict {
  constraint: work_log_status_types_constraint;
  update_columns: work_log_status_types_update_column[];
  where?: work_log_status_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "work_log_types". All fields are combined with a logical 'AND'.
 */
export interface work_log_types_bool_exp {
  _and?: work_log_types_bool_exp[] | null;
  _not?: work_log_types_bool_exp | null;
  _or?: work_log_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  user_work_logs?: user_work_logs_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "work_log_types_enum". All fields are combined with logical 'AND'.
 */
export interface work_log_types_enum_comparison_exp {
  _eq?: work_log_types_enum | null;
  _in?: work_log_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: work_log_types_enum | null;
  _nin?: work_log_types_enum[] | null;
}

/**
 * input type for inserting data into table "work_log_types"
 */
export interface work_log_types_insert_input {
  description?: string | null;
  id?: string | null;
  user_work_logs?: user_work_logs_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "work_log_types"
 */
export interface work_log_types_obj_rel_insert_input {
  data: work_log_types_insert_input;
  on_conflict?: work_log_types_on_conflict | null;
}

/**
 * on_conflict condition type for table "work_log_types"
 */
export interface work_log_types_on_conflict {
  constraint: work_log_types_constraint;
  update_columns: work_log_types_update_column[];
  where?: work_log_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "work_machine_os". All fields are combined with a logical 'AND'.
 */
export interface work_machine_os_bool_exp {
  _and?: work_machine_os_bool_exp[] | null;
  _not?: work_machine_os_bool_exp | null;
  _or?: work_machine_os_bool_exp[] | null;
  description?: String_comparison_exp | null;
  developer_changes?: developer_changes_bool_exp | null;
  developers?: developers_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "work_machine_os"
 */
export interface work_machine_os_insert_input {
  description?: string | null;
  developer_changes?: developer_changes_arr_rel_insert_input | null;
  developers?: developers_arr_rel_insert_input | null;
  type?: string | null;
}

/**
 * input type for inserting object relation for remote table "work_machine_os"
 */
export interface work_machine_os_obj_rel_insert_input {
  data: work_machine_os_insert_input;
  on_conflict?: work_machine_os_on_conflict | null;
}

/**
 * on_conflict condition type for table "work_machine_os"
 */
export interface work_machine_os_on_conflict {
  constraint: work_machine_os_constraint;
  update_columns: work_machine_os_update_column[];
  where?: work_machine_os_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_app"
 */
export interface zoom_app_arr_rel_insert_input {
  data: zoom_app_insert_input[];
  on_conflict?: zoom_app_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_app". All fields are combined with a logical 'AND'.
 */
export interface zoom_app_bool_exp {
  _and?: zoom_app_bool_exp[] | null;
  _not?: zoom_app_bool_exp | null;
  _or?: zoom_app_bool_exp[] | null;
  authorizationToken?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  webhooks?: webhooks_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_app"
 */
export interface zoom_app_insert_input {
  authorizationToken?: string | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  name?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  webhooks?: webhooks_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_app"
 */
export interface zoom_app_obj_rel_insert_input {
  data: zoom_app_insert_input;
  on_conflict?: zoom_app_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_app"
 */
export interface zoom_app_on_conflict {
  constraint: zoom_app_constraint;
  update_columns: zoom_app_update_column[];
  where?: zoom_app_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_meeting_instance_participants"
 */
export interface zoom_meeting_instance_participants_arr_rel_insert_input {
  data: zoom_meeting_instance_participants_insert_input[];
  on_conflict?: zoom_meeting_instance_participants_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_meeting_instance_participants". All fields are combined with a logical 'AND'.
 */
export interface zoom_meeting_instance_participants_bool_exp {
  _and?: zoom_meeting_instance_participants_bool_exp[] | null;
  _not?: zoom_meeting_instance_participants_bool_exp | null;
  _or?: zoom_meeting_instance_participants_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  joinTime?: timestamptz_comparison_exp | null;
  leaveTime?: timestamptz_comparison_exp | null;
  participantId?: String_comparison_exp | null;
  zoomMeetingInstanceSid?: String_comparison_exp | null;
  zoomUserSid?: String_comparison_exp | null;
  zoom_meeting_instance?: zoom_meeting_instances_bool_exp | null;
  zoom_user?: zoom_users_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_meeting_instance_participants"
 */
export interface zoom_meeting_instance_participants_insert_input {
  id?: number | null;
  joinTime?: hasura_timestamptz | null;
  leaveTime?: hasura_timestamptz | null;
  participantId?: string | null;
  zoomMeetingInstanceSid?: string | null;
  zoomUserSid?: string | null;
  zoom_meeting_instance?: zoom_meeting_instances_obj_rel_insert_input | null;
  zoom_user?: zoom_users_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "zoom_meeting_instance_participants"
 */
export interface zoom_meeting_instance_participants_on_conflict {
  constraint: zoom_meeting_instance_participants_constraint;
  update_columns: zoom_meeting_instance_participants_update_column[];
  where?: zoom_meeting_instance_participants_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_meeting_instances"
 */
export interface zoom_meeting_instances_arr_rel_insert_input {
  data: zoom_meeting_instances_insert_input[];
  on_conflict?: zoom_meeting_instances_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_meeting_instances". All fields are combined with a logical 'AND'.
 */
export interface zoom_meeting_instances_bool_exp {
  _and?: zoom_meeting_instances_bool_exp[] | null;
  _not?: zoom_meeting_instances_bool_exp | null;
  _or?: zoom_meeting_instances_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endTime?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  sid?: String_comparison_exp | null;
  startTime?: timestamptz_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
  zoom_meeting_instance_participants?: zoom_meeting_instance_participants_bool_exp | null;
  zoom_recordings?: zoom_recordings_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_meeting_instances"
 */
export interface zoom_meeting_instances_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  endTime?: hasura_timestamptz | null;
  id?: number | null;
  sid?: string | null;
  startTime?: hasura_timestamptz | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeetingSid?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
  zoom_meeting_instance_participants?: zoom_meeting_instance_participants_arr_rel_insert_input | null;
  zoom_recordings?: zoom_recordings_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_meeting_instances"
 */
export interface zoom_meeting_instances_obj_rel_insert_input {
  data: zoom_meeting_instances_insert_input;
  on_conflict?: zoom_meeting_instances_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_meeting_instances"
 */
export interface zoom_meeting_instances_on_conflict {
  constraint: zoom_meeting_instances_constraint;
  update_columns: zoom_meeting_instances_update_column[];
  where?: zoom_meeting_instances_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_meeting_occurences"
 */
export interface zoom_meeting_occurences_arr_rel_insert_input {
  data: zoom_meeting_occurences_insert_input[];
  on_conflict?: zoom_meeting_occurences_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_meeting_occurences". All fields are combined with a logical 'AND'.
 */
export interface zoom_meeting_occurences_bool_exp {
  _and?: zoom_meeting_occurences_bool_exp[] | null;
  _not?: zoom_meeting_occurences_bool_exp | null;
  _or?: zoom_meeting_occurences_bool_exp[] | null;
  occurenceSid?: String_comparison_exp | null;
  startTime?: timestamptz_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_meeting_occurences"
 */
export interface zoom_meeting_occurences_insert_input {
  occurenceSid?: string | null;
  startTime?: hasura_timestamptz | null;
  zoomMeetingSid?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
}

/**
 * on_conflict condition type for table "zoom_meeting_occurences"
 */
export interface zoom_meeting_occurences_on_conflict {
  constraint: zoom_meeting_occurences_constraint;
  update_columns: zoom_meeting_occurences_update_column[];
  where?: zoom_meeting_occurences_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_meeting_recurrences". All fields are combined with a logical 'AND'.
 */
export interface zoom_meeting_recurrences_bool_exp {
  _and?: zoom_meeting_recurrences_bool_exp[] | null;
  _not?: zoom_meeting_recurrences_bool_exp | null;
  _or?: zoom_meeting_recurrences_bool_exp[] | null;
  recurrenceEndDateTime?: timestamptz_comparison_exp | null;
  recurrenceEndTimes?: Int_comparison_exp | null;
  recurrenceMonthlyDay?: Int_comparison_exp | null;
  recurrenceMonthlyWeek?: Int_comparison_exp | null;
  recurrenceMonthlyWeekDay?: Int_comparison_exp | null;
  recurrenceRepeatInterval?: Int_comparison_exp | null;
  recurrenceType?: Int_comparison_exp | null;
  recurrenceWeeklyDays?: String_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_meeting_recurrences"
 */
export interface zoom_meeting_recurrences_insert_input {
  recurrenceEndDateTime?: hasura_timestamptz | null;
  recurrenceEndTimes?: number | null;
  recurrenceMonthlyDay?: number | null;
  recurrenceMonthlyWeek?: number | null;
  recurrenceMonthlyWeekDay?: number | null;
  recurrenceRepeatInterval?: number | null;
  recurrenceType?: number | null;
  recurrenceWeeklyDays?: string | null;
  zoomMeetingSid?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_meeting_recurrences"
 */
export interface zoom_meeting_recurrences_obj_rel_insert_input {
  data: zoom_meeting_recurrences_insert_input;
  on_conflict?: zoom_meeting_recurrences_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_meeting_recurrences"
 */
export interface zoom_meeting_recurrences_on_conflict {
  constraint: zoom_meeting_recurrences_constraint;
  update_columns: zoom_meeting_recurrences_update_column[];
  where?: zoom_meeting_recurrences_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_meetings"
 */
export interface zoom_meetings_arr_rel_insert_input {
  data: zoom_meetings_insert_input[];
  on_conflict?: zoom_meetings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_meetings". All fields are combined with a logical 'AND'.
 */
export interface zoom_meetings_bool_exp {
  _and?: zoom_meetings_bool_exp[] | null;
  _not?: zoom_meetings_bool_exp | null;
  _or?: zoom_meetings_bool_exp[] | null;
  agenda?: String_comparison_exp | null;
  calendly_events?: recruiter_calendly_events_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  duration?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  joinUrl?: String_comparison_exp | null;
  meetings?: meetings_bool_exp | null;
  registration_url?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  startTime?: timestamptz_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  topic?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomHostSid?: String_comparison_exp | null;
  zoom_meeting_instances?: zoom_meeting_instances_bool_exp | null;
  zoom_meeting_occurences?: zoom_meeting_occurences_bool_exp | null;
  zoom_meeting_recurrence?: zoom_meeting_recurrences_bool_exp | null;
  zoom_recording_files?: zoom_recording_files_bool_exp | null;
  zoom_recordings?: zoom_recordings_bool_exp | null;
  zoom_user?: zoom_users_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_meetings"
 */
export interface zoom_meetings_insert_input {
  agenda?: string | null;
  calendly_events?: recruiter_calendly_events_arr_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  duration?: number | null;
  id?: number | null;
  joinUrl?: string | null;
  meetings?: meetings_arr_rel_insert_input | null;
  registration_url?: string | null;
  sid?: string | null;
  startTime?: hasura_timestamptz | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  topic?: string | null;
  type?: number | null;
  updatedAt?: hasura_timestamptz | null;
  zoomHostSid?: string | null;
  zoom_meeting_instances?: zoom_meeting_instances_arr_rel_insert_input | null;
  zoom_meeting_occurences?: zoom_meeting_occurences_arr_rel_insert_input | null;
  zoom_meeting_recurrence?: zoom_meeting_recurrences_obj_rel_insert_input | null;
  zoom_recording_files?: zoom_recording_files_arr_rel_insert_input | null;
  zoom_recordings?: zoom_recordings_arr_rel_insert_input | null;
  zoom_user?: zoom_users_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_meetings"
 */
export interface zoom_meetings_obj_rel_insert_input {
  data: zoom_meetings_insert_input;
  on_conflict?: zoom_meetings_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_meetings"
 */
export interface zoom_meetings_on_conflict {
  constraint: zoom_meetings_constraint;
  update_columns: zoom_meetings_update_column[];
  where?: zoom_meetings_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_recording_files"
 */
export interface zoom_recording_files_arr_rel_insert_input {
  data: zoom_recording_files_insert_input[];
  on_conflict?: zoom_recording_files_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_recording_files". All fields are combined with a logical 'AND'.
 */
export interface zoom_recording_files_bool_exp {
  _and?: zoom_recording_files_bool_exp[] | null;
  _not?: zoom_recording_files_bool_exp | null;
  _or?: zoom_recording_files_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  downloadUrl?: String_comparison_exp | null;
  endTime?: timestamptz_comparison_exp | null;
  fileSize?: String_comparison_exp | null;
  fileType?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  playUrl?: String_comparison_exp | null;
  recordingType?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  startTime?: timestamptz_comparison_exp | null;
  status?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
  zoomRecordingSid?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
  zoom_recording?: zoom_recordings_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_recording_files"
 */
export interface zoom_recording_files_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  downloadUrl?: string | null;
  endTime?: hasura_timestamptz | null;
  fileSize?: string | null;
  fileType?: string | null;
  id?: number | null;
  playUrl?: string | null;
  recordingType?: string | null;
  sid?: string | null;
  startTime?: hasura_timestamptz | null;
  status?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeetingSid?: string | null;
  zoomRecordingSid?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
  zoom_recording?: zoom_recordings_obj_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_recording_files"
 */
export interface zoom_recording_files_obj_rel_insert_input {
  data: zoom_recording_files_insert_input;
  on_conflict?: zoom_recording_files_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_recording_files"
 */
export interface zoom_recording_files_on_conflict {
  constraint: zoom_recording_files_constraint;
  update_columns: zoom_recording_files_update_column[];
  where?: zoom_recording_files_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_recordings"
 */
export interface zoom_recordings_arr_rel_insert_input {
  data: zoom_recordings_insert_input[];
  on_conflict?: zoom_recordings_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_recordings". All fields are combined with a logical 'AND'.
 */
export interface zoom_recordings_bool_exp {
  _and?: zoom_recordings_bool_exp[] | null;
  _not?: zoom_recordings_bool_exp | null;
  _or?: zoom_recordings_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  downloadToken?: String_comparison_exp | null;
  duration?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  recordingCount?: String_comparison_exp | null;
  shareUrl?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  totalSize?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeetingInstanceSid?: String_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
  zoom_meeting?: zoom_meetings_bool_exp | null;
  zoom_meeting_instance?: zoom_meeting_instances_bool_exp | null;
  zoom_recording_files?: zoom_recording_files_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_recordings"
 */
export interface zoom_recordings_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  downloadToken?: string | null;
  duration?: number | null;
  id?: number | null;
  recordingCount?: string | null;
  shareUrl?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  totalSize?: string | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeetingInstanceSid?: string | null;
  zoomMeetingSid?: string | null;
  zoom_meeting?: zoom_meetings_obj_rel_insert_input | null;
  zoom_meeting_instance?: zoom_meeting_instances_obj_rel_insert_input | null;
  zoom_recording_files?: zoom_recording_files_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_recordings"
 */
export interface zoom_recordings_obj_rel_insert_input {
  data: zoom_recordings_insert_input;
  on_conflict?: zoom_recordings_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_recordings"
 */
export interface zoom_recordings_on_conflict {
  constraint: zoom_recordings_constraint;
  update_columns: zoom_recordings_update_column[];
  where?: zoom_recordings_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "zoom_users"
 */
export interface zoom_users_arr_rel_insert_input {
  data: zoom_users_insert_input[];
  on_conflict?: zoom_users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "zoom_users". All fields are combined with a logical 'AND'.
 */
export interface zoom_users_bool_exp {
  _and?: zoom_users_bool_exp[] | null;
  _not?: zoom_users_bool_exp | null;
  _or?: zoom_users_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  pmi?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  syncEntityId?: Int_comparison_exp | null;
  syncable_entity?: syncable_entities_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  use_pmi?: Boolean_comparison_exp | null;
  userType?: Int_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
  zoom_meeting_instance_participants?: zoom_meeting_instance_participants_bool_exp | null;
  zoom_meetings?: zoom_meetings_bool_exp | null;
}

/**
 * input type for inserting data into table "zoom_users"
 */
export interface zoom_users_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  email?: string | null;
  firstName?: string | null;
  id?: number | null;
  lastName?: string | null;
  pmi?: string | null;
  sid?: string | null;
  syncEntityId?: number | null;
  syncable_entity?: syncable_entities_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  use_pmi?: boolean | null;
  userType?: number | null;
  user_email?: user_emails_obj_rel_insert_input | null;
  zoom_meeting_instance_participants?: zoom_meeting_instance_participants_arr_rel_insert_input | null;
  zoom_meetings?: zoom_meetings_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "zoom_users"
 */
export interface zoom_users_obj_rel_insert_input {
  data: zoom_users_insert_input;
  on_conflict?: zoom_users_on_conflict | null;
}

/**
 * on_conflict condition type for table "zoom_users"
 */
export interface zoom_users_on_conflict {
  constraint: zoom_users_constraint;
  update_columns: zoom_users_update_column[];
  where?: zoom_users_bool_exp | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
