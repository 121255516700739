import React from 'react';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import { ActionId, ActionImpl } from 'kbar';

export declare type Action = {
  id: ActionId;
  name: string;
  shortcut?: string[];
  keywords?: string;
  path?: string;
  section?: string;
  icon?: string | React.ReactElement | React.ReactNode;
  subtitle?: string;
  perform?: (currentActionImpl: ActionImpl) => any;
  parent?: ActionId;
};

const KEY = 'DEVELOPER_ID';

export const actions: Action[] = [
  {
    id: 'home',
    name: 'Home',
    shortcut: ['h'],
    icon: <HomeIcon />,
    path: `/developers/${KEY}/home`,
    section: 'Navigation',
    subtitle: 'Navigation to root.',
  },
  {
    id: 'my-work',
    name: 'My Work',
    shortcut: ['w'],
    path: `/developers/${KEY}/tasks`,
    icon: <HomeIcon />,
    section: 'Navigation',
    subtitle: 'Navigation to my works page',
  },
];

export function appendDeveloperId(
  actions: Action[],
  developerId: string
): Action[] {
  return actions.map(action => {
    const newPath = action?.path?.replace('DEVELOPER_ID', developerId) || '/';
    const { path, ...restAction } = action;
    return {
      ...restAction,
      perform: () => (window.location.pathname = newPath),
    };
  });
}
