import React, { ReactNode } from 'react';

type Props = {
  flag: string;
  children: ReactNode;
};

export const FeatureFlag: React.FC<Props> = () => {
  return null;
};
