/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type InitialQueryProviderQueryVariables = {
  userId: number;
};
export type InitialQueryProviderQueryResponse = {
  readonly ' $fragmentRefs': FragmentRefs<
    'useFeatureFlagFragment' | 'useReleaseFlagFragment'
  >;
};
export type InitialQueryProviderQuery = {
  readonly response: InitialQueryProviderQueryResponse;
  readonly variables: InitialQueryProviderQueryVariables;
};

/*
query InitialQueryProviderQuery(
  $userId: Int!
) {
  ...useFeatureFlagFragment
  ...useReleaseFlagFragment
}

fragment useFeatureFlagFragment on query_root {
  clients_connection(where: {client_users: {userId: {_eq: $userId}}}) {
    edges {
      node {
        id
        feature_flags {
          feature_flag {
            name
            id
          }
          id
        }
      }
    }
  }
  feature_flags_connection {
    edges {
      node {
        name
        id
      }
    }
  }
}

fragment useReleaseFlagFragment on query_root {
  release_flags_connection {
    edges {
      node {
        name
        id
      }
    }
  }
  release_flags_entities_connection {
    edges {
      node {
        entityId
        entityType
        release_flag {
          name
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'userId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
      v1 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'InitialQueryProviderQuery',
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'useFeatureFlagFragment',
        },
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'useReleaseFlagFragment',
        },
      ],
      type: 'query_root',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'InitialQueryProviderQuery',
      selections: [
        {
          alias: null,
          args: [
            {
              fields: [
                {
                  fields: [
                    {
                      fields: [
                        {
                          kind: 'Variable',
                          name: '_eq',
                          variableName: 'userId',
                        },
                      ],
                      kind: 'ObjectValue',
                      name: 'userId',
                    },
                  ],
                  kind: 'ObjectValue',
                  name: 'client_users',
                },
              ],
              kind: 'ObjectValue',
              name: 'where',
            },
          ],
          concreteType: 'clientsConnection',
          kind: 'LinkedField',
          name: 'clients_connection',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'clientsEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'clients',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v1 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'client_feature_flags',
                      kind: 'LinkedField',
                      name: 'feature_flags',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'feature_flags',
                          kind: 'LinkedField',
                          name: 'feature_flag',
                          plural: false,
                          selections: v2 /*: any*/,
                          storageKey: null,
                        },
                        v1 /*: any*/,
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'feature_flagsConnection',
          kind: 'LinkedField',
          name: 'feature_flags_connection',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'feature_flagsEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'feature_flags',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: v2 /*: any*/,
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'release_flagsConnection',
          kind: 'LinkedField',
          name: 'release_flags_connection',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'release_flagsEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'release_flags',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: v2 /*: any*/,
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'release_flags_entitiesConnection',
          kind: 'LinkedField',
          name: 'release_flags_entities_connection',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'release_flags_entitiesEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'release_flags_entities',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'entityId',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'entityType',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'release_flags',
                      kind: 'LinkedField',
                      name: 'release_flag',
                      plural: false,
                      selections: v2 /*: any*/,
                      storageKey: null,
                    },
                    v1 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '466277cf53bd306a6ef6923284775533',
      id: null,
      metadata: {},
      name: 'InitialQueryProviderQuery',
      operationKind: 'query',
      text:
        'query InitialQueryProviderQuery(\n  $userId: Int!\n) {\n  ...useFeatureFlagFragment\n  ...useReleaseFlagFragment\n}\n\nfragment useFeatureFlagFragment on query_root {\n  clients_connection(where: {client_users: {userId: {_eq: $userId}}}) {\n    edges {\n      node {\n        id\n        feature_flags {\n          feature_flag {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  feature_flags_connection {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment useReleaseFlagFragment on query_root {\n  release_flags_connection {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  release_flags_entities_connection {\n    edges {\n      node {\n        entityId\n        entityType\n        release_flag {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '76eb75ebc18a5c72af845686520d2430';
export default node;
