import HomeIcon from '@material-ui/icons/HomeOutlined';
import { DashboardUserQueryResponse } from 'lib/graphql/relay/__generated__/DashboardUserQuery.graphql';

export default function DeveloperDashboardNavigationConfig(
  developerId: string,
  hasContract: boolean,
  _isCommunity: boolean,
  _mentorships: DashboardUserQueryResponse['user']['edges'][0]['node']['mentorshipsByMentoruserid'],
  developerName?: string | null
) {
  const defaultItems = [
    {
      title: 'Home',
      href: hasContract
        ? `/developers/${developerId}/home`
        : `/developers/${developerId}/tasks`,
      icon: HomeIcon,
    },
  ];

  return {
    subheader: `${developerName || developerId} Developer Portal`,
    items: [...defaultItems],
  };
}
