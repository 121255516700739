// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const {
  publicRuntimeConfig: { ENV },
} = getConfig();

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://ce28a73937124a1ea6fa0ee17dcf9be9@o282856.ingest.sentry.io/1817709',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // release,
  maxBreadcrumbs: 50,
  environment: ENV,
  attachStacktrace: true,
  debug: true,
});
