import gql from 'graphql-tag';

export const TICKET_LIST_FRAGMENT = gql`
  fragment ticketListFragment on tickets {
    ticket_repos {
      git_repo {
        id
        name
      }
    }
  }
`;
