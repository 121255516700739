import { ApolloQueryResult, NetworkStatus } from '@apollo/client';
import { GraphQLError } from 'graphql';
import fetch from 'isomorphic-unfetch';
import { getUserFromToken } from '../auth';

export default function customFetch({
  token,
  secret,
  path,
  host,
}: {
  token?: string;
  secret?: string;
  path: string;
  host: string;
}) {
  const isSecure = !(
    host.indexOf('.local') !== -1 || host.indexOf('localhost') !== -1
  );
  const user = getUserFromToken(token);
  const uri = `${isSecure ? 'https' : 'http'}://${host}${path}`;
  const requestHeaders: HeadersInit = new Headers([
    ['Content-Type', 'application/json'],
    ['credentials', 'same-origin'],
    ['x-gitstart-fetch', 'true'],
  ]);

  if (token && !secret) {
    requestHeaders.set('Authorization', `Bearer ${token}`);
  } else if (secret) {
    requestHeaders.set('x-hasura-admin-secret', secret);
  }

  if (user?.id) {
    requestHeaders.set('x-hasura-user-id', user.id.toString());
  }

  return async <T, U>({
    operationAction,
    variables,
    role: authRole,
  }: {
    operationAction: string;
    variables?: U | null;
    role?: string;
  }): Promise<ApolloQueryResult<T>> => {
    if (authRole) {
      requestHeaders.set('x-hasura-role', authRole);
    }

    const resp = await fetch(uri, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        query: operationAction,
        variables,
      }),
    });

    if (!resp.ok) {
      throw new GraphQLError(resp.statusText);
    }

    const respJson = await resp.json();

    if (respJson?.errors) {
      throw respJson.errors[0];
    }

    return Promise.resolve({
      data: respJson?.data,
      errors: [],
      stale: false,
      loading: false,
      networkStatus: NetworkStatus.ready,
    });
  };
}

export const fetchGraphQLClient = (token: string, host: string) =>
  customFetch({
    token,
    host,
    path: '/v1/graphql',
  });
