import { colors } from '@material-ui/core';
import { DocumentNode } from 'graphql';
import { CLIENT_OVERVIEW_INVOICES } from '@engine/common/graphql/roles/client/generated/CLIENT_OVERVIEW_INVOICES';
import { CLIENT_TIME_USER_TOTALS } from '@engine/common/graphql/roles/client/generated/CLIENT_TIME_USER_TOTALS';
import {
  client_transaction_types_enum,
  ticket_status_enum,
} from '@engine/common/graphql/roles/client/generated/globalTypes';
import { print as convertGraphqlToString } from 'graphql/language/printer';
import { INVOICE_TIME_TO_SURFACE } from '@engine/common/graphql/roles/client/generated/INVOICE_TIME_TO_SURFACE';
import { TASK_TIME_USER_TOTALS } from '@engine/common/graphql/roles/user/generated/TASK_TIME_USER_TOTALS';
import { TICKET_TIME_TO_SURFACE } from '@engine/common/graphql/roles/user/generated/TICKET_TIME_TO_SURFACE';
import { TICKET_TIME_USER_TOTALS } from '@engine/common/graphql/roles/user/generated/TICKET_TIME_USER_TOTALS';
import { useLoading, useSnackbar } from 'lib/hooks';
import _uniq from 'lodash/uniq';
import moment from 'moment';
import Hash from 'object-hash';
import { DependencyList, useEffect, useState } from 'react';
import { fetchClientOverview } from '@engine/common/graphql/roles/client/generated/fetchClientOverview';
import { Context } from 'lib/types';
import { getTaskStatusArray } from 'lib/client/filters';
import { fetchGraphlClient } from './fetch';
import { FetchResult } from '@apollo/client';
import { getTicketTotal } from 'v2/components/domains/Billing/Dashboard/helper';

export type FetchPolicy =
  | 'no-cache'
  | 'cache-first'
  | 'network-only'
  | 'cache-only'
  | 'standby'
  | undefined;

export function useApolloQuery<T, U>(
  opts: {
    ctx: Context;
    query: DocumentNode;
    variables?: U | null;
    role:
      | 'user'
      | 'client'
      | 'developer'
      | 'recruiter'
      | 'worker'
      | 'agency'
      | 'candidate';
    type?: 'backend' | 'hasura';
    subscribe?: boolean;
    setError?: (error: Error | null) => any;
    setLoading?: (val: boolean) => any;
    setData?: (data: Readonly<T> | null) => any;
    fetchPolicy?: FetchPolicy;
    autoRun?: boolean;
    useFetch?: boolean;
  },
  deps: DependencyList = []
): {
  error: Error | null;
  data: Readonly<T> | undefined;
  fetchQuery: (
    vars?: U | undefined,
    argsFetchPolicy?: FetchPolicy
  ) => Promise<Readonly<T> | undefined>;
  loading: boolean;
} {
  const {
    ctx,
    query,
    variables,
    setLoading,
    role,
    subscribe = false,
    type = 'hasura',
    setError,
    setData,
    fetchPolicy = 'no-cache', // change default from 'network-only -> no-cache'
    autoRun = true,
    useFetch = false,
  } = opts;
  const [data, setStateData] = useState<T>();
  const [error, setStateError] = useState<Error | null>(null);
  const [loading, setStateLoading] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();
  const { load } = useLoading();

  async function fetchQuery(
    vars?: U,
    argsFetchPolicy: FetchPolicy = fetchPolicy
  ): Promise<Readonly<T> | undefined> {
    const { graphqlClient, backendClient } = ctx;
    const client = type === 'hasura' ? graphqlClient : backendClient;
    if (!client) {
      return;
    }
    try {
      return await load<T | undefined>(
        async () => {
          setStateLoading(true);
          if (setLoading) {
            setLoading(true);
          }

          const token = ctx.jwtToken;

          if (variables === null || !token) {
            // this means that variables are defined, but not yet available
            // in which case it should continue to load and run again when they
            // change into something not null
            console.log(
              'Returning early for query due to lack of variables or token: ',
              variables,
              token
            );
            return;
          }

          /*
            TODO: Apollo query is really slow when called in nested components
            avoid using when its called > 10 times. Reason is Apollo
            taking too long to read / write from its cache
          */
          let opts;
          if (useFetch) {
            // Can move to components/auth
            opts = await fetchGraphlClient(token)<T, U>({
              operationAction: convertGraphqlToString(query),
              variables: vars || variables,
              role,
            });
          } else {
            opts = await client.query<T, U>({
              query,
              variables: vars || variables,
              context: { role, subscribe },
              fetchPolicy: argsFetchPolicy,
            });
          }

          const { data, errors } = opts;

          if (errors && errors[0]) {
            throw errors[0];
          }

          setStateLoading(false);
          if (setLoading) {
            setLoading(false);
          }

          if (setData) {
            setData(data);
          }

          setStateData(data);

          return data;
        },
        query.loc?.source.body.match(/\w+/g)?.[1] || 'useQuery',
        !!setLoading
      );
    } catch (ex) {
      setStateLoading(false);
      if (setLoading) {
        setLoading(false);
      }

      const error = (ex as Error)?.message
        ? (ex as Error)
        : new Error(JSON.stringify(ex));
      if (setError) {
        setError(error as Error);
      } else {
        showSnackbar({
          variant: 'error',
          message: (error as Error).message,
        });
      }
      setStateError(typeof error.stack === 'string' ? (error as Error) : null);
    }
  }

  useEffect(() => {
    if (autoRun) fetchQuery();
    // have to pass {} to Hash, as it crashes on undefined / null
  }, [!!ctx.graphqlClient, Hash(variables || {}), ...deps]);

  return {
    error,
    data,
    fetchQuery,
    loading,
  };
}

export function useApolloMutation<T, U, R = U>(opts: {
  ctx: Context;
  mutation: DocumentNode;
  defaultVariables?: U;
  role:
    | 'user'
    | 'client'
    | 'developer'
    | 'recruiter'
    | 'worker'
    | 'agency'
    | 'candidate';
  type?: 'hasura' | 'backend';
  setError?: (error: Error) => any;
  setLoading?: (val: boolean) => any;
  setData?: (data: T | null) => any;
  useFetch?: boolean;
  awaitRefetchQueries?: boolean;
  refetchQueries?:
    | Array<
        | string
        | {
            query: DocumentNode;
            variables?: R;
            context: { [key: string]: string };
          }
      >
    | ((
        mutationResult: FetchResult
      ) => Array<string | { query: DocumentNode; variables?: R }>);
}) {
  const {
    ctx,
    mutation,
    defaultVariables,
    role,
    type = 'hasura',
    setError,
    setData,
    setLoading,
    refetchQueries,
    awaitRefetchQueries,
    useFetch,
  } = opts;
  const [data, setStateData] = useState<T>();
  const [error, setStateError] = useState<Error | null>(null);
  const [loading, setStateLoading] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();
  const { load } = useLoading();

  async function issueMutation(vars?: U) {
    const { graphqlClient, backendClient } = ctx;
    const client = type === 'hasura' ? graphqlClient : backendClient;
    if (!client) {
      throw new Error('GraphqlClient not ready');
    }

    try {
      return await load<T | null | undefined>(
        async () => {
          const variables = vars || defaultVariables;
          const token = ctx.jwtToken;
          if (!variables) {
            throw new Error(
              'Please pass either defaultVariables or variables to issueMutation'
            );
          }

          if (!token) {
            throw new Error('Please have a valid token for the operation');
          }
          setStateLoading(true);
          if (setLoading) {
            setLoading(true);
          }

          let opts;
          if (useFetch) {
            // Can move to components/auth
            const jwtToken = token;

            opts = await fetchGraphlClient(jwtToken)<T, U>({
              operationAction: convertGraphqlToString(mutation),
              variables: vars || variables,
              role,
            });
          } else {
            opts = await client.mutate<T, U>({
              mutation,
              variables,
              context: { role },
              refetchQueries,
              awaitRefetchQueries,
            });
          }
          const { data, errors } = opts;
          if (errors && errors[0]) {
            throw errors[0];
          }

          setStateLoading(false);
          if (setLoading) {
            setLoading(false);
          }

          if (!data) {
            return data;
          }

          if (setData) {
            setData(data);
          }

          setStateData(data);

          return data;
        },
        mutation.loc?.source.body.match(/\w+/g)?.[1] || 'useMutation',
        !!setLoading
      );
    } catch (ex) {
      setStateLoading(false);
      if (setLoading) {
        setLoading(false);
      }

      const error = (ex as Error)?.message
        ? (ex as Error)
        : new Error(JSON.stringify(ex));
      if (setError) {
        setError(error as Error);
      } else {
        showSnackbar({
          variant: 'error',
          message: (error as Error).message,
        });
      }
      setStateError(error as Error);
      throw ex;
    }
  }

  return {
    error,
    data,
    loading,
    issueMutation,
  };
}

export function calculateCreditBalance(client: fetchClientOverview['client']) {
  if (!client) return null;

  const totalCreditsConsumed = client.tickets.reduce(
    (sum, ticket) => sum + (getTicketTotal(ticket) || 0),
    0
  );

  const totalCreditsBought = client.client_transactions
    .filter(({ status }) => ['successful'].includes(status))
    .reduce((sum, t) => {
      sum += +t.costInCredits;
      return sum;
    }, 0);
  return Math.round(totalCreditsBought - totalCreditsConsumed);
}

export type SurfacedTimeInfo = {
  totalLinkedTimeInSecs: number;
};

export const surfaceEntityTimeLogged = (
  item_totals:
    | TASK_TIME_USER_TOTALS[]
    | TICKET_TIME_USER_TOTALS[]
    | CLIENT_TIME_USER_TOTALS[]
): SurfacedTimeInfo => {
  let totalLinkedTimeInSecs = 0;
  item_totals.forEach(
    (
      item:
        | TASK_TIME_USER_TOTALS
        | TICKET_TIME_USER_TOTALS
        | CLIENT_TIME_USER_TOTALS
    ) => {
      totalLinkedTimeInSecs += parseInt(item.totalOrganizedTimeInSecs || '0');
    }
  );
  return {
    totalLinkedTimeInSecs,
  };
};

export const surfaceTicketTotalTimeLogged = (
  ticket: TICKET_TIME_TO_SURFACE
): SurfacedTimeInfo => {
  const { ticket_time_user_totals, tasks } = ticket;
  const ticketInfo = surfaceEntityTimeLogged(ticket_time_user_totals);
  const task_time_user_totals = tasks.flatMap(
    task => task.task_time_user_totals
  );
  const tasksInfo = surfaceEntityTimeLogged(task_time_user_totals);
  return {
    totalLinkedTimeInSecs:
      ticketInfo.totalLinkedTimeInSecs + tasksInfo.totalLinkedTimeInSecs,
  };
};

export const surfaceInvoiceTimeLogged = (
  client_invoice: INVOICE_TIME_TO_SURFACE
): SurfacedTimeInfo => {
  const { client_invoice_time_user_totals, tickets } = client_invoice;
  const invoiceTimeInfo = surfaceEntityTimeLogged(
    client_invoice_time_user_totals
  );
  const ticketTimeInfo = tickets.reduce(
    (acc: SurfacedTimeInfo, ticket: TICKET_TIME_TO_SURFACE) => {
      const ticketInfo = surfaceTicketTotalTimeLogged(ticket);
      return {
        totalLinkedTimeInSecs:
          ticketInfo.totalLinkedTimeInSecs + acc.totalLinkedTimeInSecs,
      };
    },
    {
      totalLinkedTimeInSecs: 0,
    }
  );
  return {
    totalLinkedTimeInSecs:
      invoiceTimeInfo.totalLinkedTimeInSecs +
      ticketTimeInfo.totalLinkedTimeInSecs,
  };
};

export const getClientInvoiceStatus = (opts: {
  currrent_paid_balance_in_usd: number | null;
  previous_balance_in_usd: number | null;
  dueDate: string | null;
  costInUSD: number | null;
}) => {
  const {
    currrent_paid_balance_in_usd: currentPaidBalance,
    previous_balance_in_usd: previousBalance,
    dueDate,
    costInUSD,
  } = opts;
  const invoiceBalance = (currentPaidBalance || 0) - (previousBalance || 0);
  if (costInUSD === 0) {
    return 'not-required';
  }
  if (costInUSD) {
    switch (true) {
      case invoiceBalance >= costInUSD:
        return 'paid';
      case invoiceBalance > 0 && invoiceBalance < costInUSD:
        return 'partially-paid';
      case dueDate && moment().isBefore(dueDate) && invoiceBalance <= 0:
        return 'needs-payment-soon';
      case dueDate && moment().isAfter(dueDate) && invoiceBalance <= 0:
        return 'needs-payment';
      default:
        return 'needs-payment-soon';
    }
  }
  return 'needs-payment-soon';
};

export const invoicePendingBalance = (invoice: CLIENT_OVERVIEW_INVOICES) => {
  const { costInUSD, costInCredits } = invoice;

  if (!costInUSD) {
    return {
      cost: 0,
      credit: costInCredits,
    };
  }

  const balanceInUSDTotal =
    +(invoice.client_invoice_balance?.previous_balance_in_usd || 0) +
    invoice.costInUSD -
    +(invoice.client_invoice_balance?.currrent_paid_balance_in_usd || 0);
  const balanceInUSD = Math.min(Math.max(balanceInUSDTotal, 0), costInUSD);
  const balanceInCredits = Math.round(
    (balanceInUSD / costInUSD) * costInCredits
  );

  return {
    credit: balanceInCredits,
    cost: balanceInUSD,
  };
};

export const accumulateInvoiceCostCredit = (
  invoicesToAccumulate: CLIENT_OVERVIEW_INVOICES[]
) =>
  invoicesToAccumulate.reduce(
    (acc, invoice) => {
      const invoiceBalance = invoicePendingBalance(invoice);
      return {
        credit: acc.credit + invoiceBalance.credit,
        cost: acc.cost + invoiceBalance.cost,
      };
    },
    {
      credit: 0,
      cost: 0,
    }
  );
export const renderInvoiceStatusAndColor = (opts: {
  currrent_paid_balance_in_usd: number | null;
  previous_balance_in_usd: number | null;
  dueDate: string | null;
  costInUSD: number | null;
}) => {
  const status = getClientInvoiceStatus(opts);

  switch (status) {
    case 'not-required':
      return { color: colors.grey[600], status: 'Not Required' };
    case 'paid':
      return { color: colors.green[600], status: 'Paid' };
    case 'partially-paid':
      return { color: colors.orange[600], status: 'Partially Paid' };
    case 'needs-payment-soon':
      return { color: colors.grey[600], status: 'Needs Payment' };
    case 'needs-payment':
      return { color: colors.red[600], status: 'Needs Payment' };
    default:
      return { color: colors.grey[600], status: 'Needs Payment' };
  }
};

export const incoporateDollarSign = (value: number) => {
  if (value === 0) {
    return 0;
  }
  if (value > 0) {
    return `$${value.toFixed(2)}`;
  }
  return `-$${(value / -1).toFixed(2)}`;
};

export const formatSecsInHHMMSS = (secs: number) => {
  const pad = (num: number) => `0${num}`.slice(-2);
  let minutes = Math.floor(secs / 60);
  secs %= 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export const formatSecsInHHhMMmSSs = (secs: number) => {
  if (secs === 0) return `0h 0m 0s`;
  const seconds = secs % 60;
  let minutes = Math.floor(secs / 60);
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;
};

export const formatSecsInHours = (secs: number) => {
  return `${Math.round((secs / (60 * 60)) * 100) / 100} hours`;
};

export const getReadableTaskStatusObject = (
  searchString: string | ticket_status_enum | null
) => {
  return (
    getTaskStatusArray.find(
      d =>
        d.status === searchString ||
        d.type === searchString ||
        d.title === searchString
    ) || getTaskStatusArray[0]
  );
};

export type BankProperties = {
  payeeName: string;
  rate: number;
  bankName: string;
  accountNumber?: string;
  swiftCode: string;
  bankAddress: string;
  aba?: string;
  iban?: string;
};

export type BankDetails = {
  countryNickName: 'USA' | 'HONG KONG' | 'EU' | 'UK';
  entityName: string;
  entityAddress: string[];
  currencyCode: '$' | '€' | '‎£';
  currencyCodeDesc: 'USD' | 'HKD' | 'EU' | 'GBP';
  transactionType: client_transaction_types_enum;
  bankProperties: BankProperties;
};

// TODO: Support EU and UK in the future?
export const bankDetails: { [key in 'USA' | 'HK']: BankDetails } = {
  USA: {
    countryNickName: 'USA',
    currencyCode: '$',
    currencyCodeDesc: 'USD',

    entityName: 'Murcul Inc.',
    entityAddress: ['340 S Lemon Ave, Unit 6544', 'Walnut, CA, 91789', 'USA'],
    transactionType: client_transaction_types_enum.us_bank,
    bankProperties: {
      payeeName: 'Murcul Inc.',
      rate: 1,
      bankName: 'Silicon Valley Bank',
      accountNumber: '3302729331',
      swiftCode: 'SVBKUS6S',
      bankAddress: '3003 TASMAN DRIVE, SANTA CLARA,CA 95054, USA',
      aba: '121140399',
    },
  },
  HK: {
    countryNickName: 'HONG KONG',
    currencyCode: '$',
    currencyCodeDesc: 'HKD',
    entityName: 'GitStart Limited',
    entityAddress: [
      'Unit 937, 9/F., Building 19',
      'No. 19 Science Park West Avenue, Hong Kong Science Park',
      'Pak Shek Kok, NT, Hong Kong',
    ],
    transactionType: client_transaction_types_enum.hk_bank,
    bankProperties: {
      payeeName: 'GitStart Limited',
      rate: 7.78,
      bankName: 'Hang Seng Bank Limited',
      accountNumber: '370-413338-883',
      swiftCode: 'HASEHKHH',
      bankAddress: '83 Des Voeux Road Central, Hong Kong',
    },
  },
  // HK: {
  //   countryNickName: 'HONG KONG',
  //   currencyCode: '$',
  //   currencyCodeDesc: 'HKD',
  //   entityName: 'GitStart Limited',
  //   entityAddress: [
  //     'Unit 940, 9/F., Building 19',
  //     'No. 19 Science Park West Avenue, Hong Kong Science Park',
  //     'Pak Shek Kok, NT, Hong Kong',
  //   ],
  //   transactionType: client_transaction_types_enum.hk_bank,
  //   bankProperties: {
  //     payeeName: 'GitStart Limited',
  //     rate: 7.78,
  //     bankName: 'HSBC Hong Kong',
  //     accountNumber: '023-595747-838',
  //     swiftCode: 'HSBCHKHHHKH',
  //     bankAddress: "No. 1 Queen's Road Central, Central, Hong Kong",
  //   },
  // },
  // EU: {
  //   countryNickName: 'EU',
  //   currencyCode: '€',
  //   currencyCodeDesc: 'EU',
  //   transactionType: client_transaction_types_enum.eu_bank,
  //   entityName: 'Murcul Inc.',
  //   entityAddress: ['340 S Lemon Ave, Unit 6544', 'Walnut, CA, 91789', 'USA'],
  //   bankProperties: {
  //     payeeName: 'Murcul Inc',
  //     rate: 0.88,
  //     bankName: 'DEUTSCHE HANDELSBANK AG',
  //     iban: 'DE28 7001 1110 6057 9024 40',
  //     swiftCode: 'DEKTDE7GXXX',
  //     bankAddress: 'Handelsbank Elsenheimer Str. 41 München 80687 Germany',
  //   },
  // },
};

export const getUsageDetailsUrl = (
  usageMonth: hasura_timestamptz | null,
  clientId: string
): string => {
  const year = moment(usageMonth || undefined).format('YYYY');
  const month = moment(usageMonth || undefined).format('M');
  return `/clients/${clientId}/usage/${year}/${month}`;
};
