import gql from 'graphql-tag';

export const CLIENT_STATS_FRAGMENT = gql`
  fragment CLIENT_STATS_FRAGMENT on client_stats {
    id
    name
    un_invoiced_tickets
    needs_credit_approval
    consumed_balance_in_credits
    current_paid_balance_in_credits
    consumed_balance_in_credits_un_invoiced
  }
`;
