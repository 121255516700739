import TextField, { TextFieldProps } from 'ui/TextField';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import React, { ReactNode } from 'react';

export interface AutoCompleteProps<T> {
  options: T[];
  value?: T;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: string | T | (string | T)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => void;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  defaultValue?: T;
  getOptionLabel?: (option: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  renderOption?: (
    option: T,
    state?: AutocompleteRenderOptionState
  ) => ReactNode;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  label?: string;
  multiple?: boolean;
  filterSelectedOptions?: boolean;
  id?: string;
  error?: boolean;
  name?: string;
  size?: 'medium' | 'small';
  openOnFocus?: boolean;
  ref?: ((instance: unknown) => void) | React.RefObject<unknown> | null;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  selectOnFocus?: boolean;
  autoFocus?: boolean;
  freeSolo?: boolean;
  helperText?: string;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  textFieldProps?: TextFieldProps;
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
}

function AutoCompleteInput<T = any>(props: AutoCompleteProps<T>) {
  const {
    value,
    onChange,
    onInputChange,
    options,
    defaultValue,
    getOptionLabel,
    getOptionSelected,
    renderOption,
    disabled,
    className,
    inputClassName,
    label,
    multiple,
    filterSelectedOptions,
    id,
    name,
    error,
    size,
    openOnFocus,
    ref,
    onBlur,
    selectOnFocus,
    autoFocus,
    freeSolo,
    helperText,
    onFocus,
    textFieldProps,
    PaperComponent,
  } = props;
  return (
    <>
      <Autocomplete
        className={className}
        id={id}
        options={options}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        defaultValue={defaultValue}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        disabled={disabled}
        freeSolo={freeSolo}
        multiple={multiple}
        filterSelectedOptions={filterSelectedOptions}
        size={size}
        openOnFocus={openOnFocus || autoFocus}
        ref={ref}
        onBlur={onBlur}
        selectOnFocus={selectOnFocus}
        onFocus={onFocus}
        PaperComponent={PaperComponent}
        renderInput={(params: any) => (
          <TextField
            {...params}
            className={inputClassName}
            label={label}
            name={name}
            error={error}
            autoFocus={autoFocus}
            helperText={helperText}
            {...textFieldProps}
          />
        )}
      />
    </>
  );
}

export default AutoCompleteInput;
