import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { Theme } from 'template/theme';
import ConnectYourGitProjects from './ConnectYourGitProjects';
import FillInCompanyDetails from './FillInCompanyDetails';
import ScheduleOnboardingCall from './ScheduleOnboardingCall';
import { StepperNavigationProps, StepperState } from './StepperNavigations';

type step = {
  label: string;
  completed: boolean;
  Content: ({ onBack, onNext, state }: StepperNavigationProps) => JSX.Element;
};

const initSteps: step[] = [
  {
    label: 'Fill in company details',
    completed: false,
    Content: FillInCompanyDetails,
  },
  {
    label: 'Connect your git projects',
    completed: false,
    Content: ConnectYourGitProjects,
  },
  {
    label: 'Schedule onboarding call',
    completed: false,
    Content: ScheduleOnboardingCall,
  },
];

const useStyles = makeStyles<Theme>(theme => ({
  root: {},
  stepContent: {
    marginBottom: theme.spacing(0.8),
  },
  allSetupWrapper: {
    height: theme.spacing(20),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  allSetup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  allSetupText: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontWeight: 100,
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(-3),
    transform: 'translateX(-100%)',
  },
}));

type Props = {
  open: boolean;
  onClose?: () => void;
  onBack?: () => void;
  onFinish?: ({ clientId }: { clientId?: string }) => void;
  userId?: number;
  start?: number;
  state?: StepperState;
};

const ClientGettingStartedModal = ({
  open = true,
  onClose,
  onBack,
  onFinish,
  start = 0,
  state,
}: Props) => {
  const classes = useStyles();
  const [stepperState, setStepperState] = useState<StepperState>({ ...state });
  const [activeStep, setActiveStep] = useState(start);
  const [steps, setSteps] = useState<step[]>(initSteps);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleBack = () => {
    if (activeStep <= 0) {
      if (onBack) onBack();
    } else if (activeStep > start) {
      setActiveStep(s => s - 1);
    }
  };

  const handleNext = (state?: StepperState) => {
    setStepperState(d => ({
      ...d,
      ...state,
    }));
    setSteps(s => {
      const newSteps = s;
      const newStepsCompleted = newSteps?.[activeStep];
      if (newStepsCompleted) newStepsCompleted.completed = true;
      return newSteps;
    });
    setActiveStep(s => s + 1);
  };

  const handleFinish = () => {
    if (onFinish) onFinish({ clientId: stepperState.companyDetails?.clientId });
    else return onClose && onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogContent>
        {onClose && (
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        {activeStep >= steps.length && (
          <div className={classes.allSetupWrapper}>
            <div className={classes.allSetup}>
              <img
                src="/images/icons/all-setup-icon-big.svg"
                alt="All setup icon"
              />
              <Typography className={classes.allSetupText} color="primary">
                You are all setup!
              </Typography>
              <Button color="primary" onClick={handleFinish}>
                Finish
              </Button>
            </div>
          </div>
        )}
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          connector={<></>}
        >
          {steps.map(({ label, completed, Content }, i) => {
            return (
              <Step key={label} completed={completed || i < start}>
                <StepLabel>{label}</StepLabel>
                <StepContent className={classes.stepContent}>
                  <Content
                    onBack={handleBack}
                    onNext={handleNext}
                    state={stepperState}
                  />
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default ClientGettingStartedModal;
