import { CreditsStats_client$data } from 'lib/graphql/relay/__generated__/CreditsStats_client.graphql';

export const getTicketTotal = (ticket: {
  costInCredits: number | null;
  completedAt: string | null;
  tasks: ReadonlyArray<{
    costInCredits: number | null;
    completedAt: string | null;
    status: string;
  }>;
  ticket_refunds: ReadonlyArray<{
    costInCredits: number;
    createdAt: string;
  }>;
}): number | null => {
  const refundsForTicket = ticket.ticket_refunds.reduce(
    (pv, cv) => pv + cv.costInCredits,
    0
  );

  if (
    ticket.tasks.some(task => task.costInCredits !== null) &&
    ticket.tasks.every(
      task =>
        task.costInCredits !== null ||
        task.completedAt === null ||
        task.status === 'cancelled'
    )
  ) {
    return (
      ticket.tasks.reduce(
        (pv, cv) =>
          pv +
          (cv.status != 'cancelled' && cv.completedAt ? cv.costInCredits! : 0),
        0
      ) - refundsForTicket
    );
  }

  if (ticket.completedAt && ticket.costInCredits !== null) {
    return ticket.costInCredits - refundsForTicket;
  }

  return null;
};

export const getEstimatedCostOfTicketsInProgress = (
  data: CreditsStats_client$data
): number => {
  const estimatedTotalCost = data.tickets
    .filter(ticket =>
      ['in_progress', 'partially_under_review', 'under_review'].includes(
        ticket.status
      )
    )
    .reduce(
      (sum, ticket) =>
        sum +
        (ticket.costInCredits ||
          Math.ceil((ticket.lowerBudget || 0) + (ticket.upperBudget || 0)) / 2),
      0
    );

  return estimatedTotalCost;
};

export const getMonthNameFromDateString = (date: string): string => {
  const dateParsed = new Date(date);
  const dateUTC = new Date(
    dateParsed.getFullYear(),
    dateParsed.getUTCMonth(),
    1
  );
  const monthName = dateUTC.toLocaleString('default', { month: 'long' });

  return monthName;
};

export const getMonthFromDateString = (date: string): number => {
  const dateParsed = new Date(date);
  const month = dateParsed.getUTCMonth();

  return month;
};

export const getFullYearFromDateString = (date: string): number => {
  const dateParsed = new Date(date);
  const year = dateParsed.getFullYear();

  return year;
};
