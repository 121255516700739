import React from 'react';
import moment from 'moment';
import { State } from '..';
import { CallendarCellInfoType } from 'lib/utils';
import { makeStyles, Tooltip } from '@material-ui/core';

type Props = {
  date: string;
  info: CallendarCellInfoType;
  width?: string;
  height?: string;
  userLogin?: string | null;
  isCurrent?: boolean;
  isHeader?: boolean;
};

const defaultProps = {
  width: '48px',
  height: '28px',
};

export type CalendarCellProps = Props;

export const CalendarCell: React.FC<CalendarCellProps> = props => {
  const { date, info, userLogin, isCurrent, isHeader } = props;

  const day = moment(date)
    .format(isHeader ? 'dd' : 'DD')
    .toString();

  const text_color = info.color === State.Nothing ? '#000000' : '#FFFFFF';
  const useStyles = makeStyles(() => ({
    box: {
      backgroundColor: info.color,
      width: props.width,
      height: props.height,
      display: 'flex',
      alignItems: 'center',
      '& a': {
        color: 'inherit', // no blue text
        textDecoration: 'inherit', // no underline
      },
    },
    text: {
      margin: '0 auto',
      color: text_color,
      fontFamily: [
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        // 'sans-serif',
        // '"Apple Color Emoji"',
        // '"Segoe UI Emoji"',
        // '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: isCurrent || isHeader ? 'bold' : 'normal',
    },
  }));
  const classes = useStyles();

  let dateLabel = <p>{day}</p>;
  // TODO: add isAdmin (can check everyone), isTeamLead (can check their team)
  if (userLogin) {
    dateLabel = (
      <a
        href={`/developers/${props.userLogin}/devtime?selected_day=${date}`}
        target="_blank"
        rel="noreferrer" // new tab
      >
        {day}
      </a>
    );
  }

  const tooltipTitle = (
    <div>
      Logged: {info.logged} h
      <br />
      Required: {info.required} h
      {info?.leaveType && (
        <>
          <br />
          Leave Type: {info?.leaveType}
        </>
      )}
    </div>
  );

  return (
    <div className={classes.box}>
      <div className={classes.text}>
        {isHeader ? (
          <div>{dateLabel}</div>
        ) : (
          <Tooltip title={tooltipTitle}>{dateLabel}</Tooltip>
        )}
      </div>
    </div>
  );
};
CalendarCell.defaultProps = defaultProps;

export default CalendarCell;
