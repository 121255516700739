import React from 'react';
import { getRelayId } from 'lib/graphql/relay/utils';
import { DashboardUserQueryResponse } from 'lib/graphql/relay/__generated__/DashboardUserQuery.graphql';

const useIntercom = require('use-intercom-hook');

type Props = {
  userId: number;
  userInfo: DashboardUserQueryResponse['user']['edges'][0]['node'];
  selectedClientUser: any;
  selected: any;
};

const UseIntercom = (props: Props) => {
  const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;
  const user = {
    userId: props.userId,
    name:
      props.userInfo.firstName && props.userInfo.lastName
        ? `${props.userInfo.firstName} ${props.userInfo.lastName}`
        : props.userInfo.nickname,
    email:
      props.userInfo?.defaultEmail || props.userInfo.user_emails?.[0]?.email,
    login: props.userInfo.login,
    phone: props.userInfo.telephone,
    source: props.userInfo?.developer?.source,
    candidate_stage: props.userInfo?.developer?.stage,
    github_id: props.userInfo?.developer?.githubId,
    linkedin_url: props.userInfo?.developer?.linkedInUrl,
    culture_feedback: props.userInfo?.developer?.culture_screen_feedback,
    tech_feedback: props.userInfo?.developer?.tech_screen_feedback,
    cv: props.userInfo?.developer?.cv,
    total_experience: props.userInfo?.developer?.yearsOfExperience,
    positions_applied: props.userInfo?.user_opportunity_interests
      ?.map(interest => interest?.opportunity?.name)
      .join(', '),
    user_skills: props.userInfo?.user_skills
      ?.map(skills => skills.skill.name)
      .join(', '),
  };

  // intercom
  useIntercom(INTERCOM_APP_ID, {
    ...(user && user.email
      ? {
          user_id: user.userId,
          email: user.email,
          name: user.name,
          phone: user.phone,
          source: user.source,
          candidate_stage: user.candidate_stage,
          github_id: user.github_id,
          linkedin_url: user.linkedin_url,
          culture_feedback: user.culture_feedback,
          tech_feedback: user.tech_feedback,
          total_experience: user.total_experience,
          positions_applied: user.positions_applied,
          user_skills: user.user_skills,
          cv: user.cv,
          client_id: getRelayId(props.selectedClientUser?.client.id),
          on_boarded_at: props.selectedClientUser?.client.onBoardedAt,
          client_name: props.selectedClientUser?.client.name,
          client_meeting:
            props.selectedClientUser?.client.meetings[0]?.scheduledAt,
          developer_id:
            props.selected?.type === 'developer'
              ? props.selected.developerId
              : undefined,
          candidate_id:
            props.selected?.type === 'candidate'
              ? props.selected.candidateId
              : undefined,
        }
      : {}),
  });

  // has to return something else other than null or void
  return <></>;
};

export default UseIntercom;
