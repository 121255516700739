import React from 'react';
import { ActionImpl } from 'kbar';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    padding: '12px 16px',
    background: active => (active ? 'rgba(0 0 0 / 0.05)' : 'transparent'),
    borderLeft: active =>
      `2px solid ${active ? 'rgb(28 28 29)' : 'transparent'}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  itemContent: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: 14,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    fontSize: 12,
  },
  shortCuts: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '4px',
  },
  shortCutWrapper: {
    padding: '4px 6px',
    background: 'rgba(0 0 0 / .1)',
    borderRadius: '4px',
    fontSize: 14,
  },
});

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
    }: {
      action: ActionImpl;
      active: boolean;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const classes = useStyles(active);
    return (
      <div ref={ref} className={classes.container}>
        <div className={classes.itemContent}>
          {action.icon}
          <div className={classes.contentWrapper}>
            <div>
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span className={classes.subTitle}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div className={classes.shortCuts}>
            {action.shortcut.map(sc => (
              <kbd key={sc} className={classes.shortCutWrapper}>
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
);

export default ResultItem;
