import React from 'react';
import { Property } from 'csstype';
import { useTheme } from '@material-ui/core';

export type FlexProps = React.DOMAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  direction?: Property.FlexDirection;
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
  gap?: Property.Gap | number;
  wrap?: Property.FlexWrap | boolean;
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const Flex = (props: FlexProps) => {
  const theme = useTheme();
  const { alignItems = 'center', justifyContent = 'center', gap } = props;

  let themedGap: number | null = null;
  if (gap && (typeof gap === 'number' || +gap > 0)) {
    themedGap = theme.spacing(+gap);
  }

  return (
    <div
      {...props}
      style={{
        display: 'flex',
        flexDirection: props.direction,
        alignItems,
        justifyContent,
        gap: themedGap ?? props.gap,
        flexWrap: typeof props.wrap === 'boolean' ? 'wrap' : props.wrap,
        ...(props.fullWidth ? { width: '100%' } : {}),
        ...props.style,
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

export default Flex;
