import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import AutoCompleteInput, {
  AutoCompleteProps,
} from 'components/AutoCompleteInput2';
import { USER_AVATAR_DETAILS } from '@engine/common/graphql/roles/user/generated/USER_AVATAR_DETAILS';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { Avatar } from 'ui';

const useStyles = makeStyles(theme => ({
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export interface AutoDropdownProps<T> extends AutoCompleteProps<T> {
  control: Control;
  name: string;
  defaultValue?: T;
  rules?: RegisterOptions;
  endElement?: React.ReactNode;
}

function AutoDropdown<T = any>({
  control,
  defaultValue,
  name,
  options,
  rules,
  freeSolo,
  endElement,
  ...other
}: AutoDropdownProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }) => (
        <AutoCompleteInput<T>
          options={options}
          value={value}
          {...other}
          onChange={(_, developer) => {
            onChange(developer);
          }}
          PaperComponent={props => (
            <Paper {...props}>
              {props.children}
              {endElement ?? null}
            </Paper>
          )}
          // onInputChange={freeSolo ? (_, inputChange) => {
          //   onChange(inputChange)
          // }: () => {}}
        />
      )}
    />
  );
}

export const UserOptionItem = (option: {
  user: Omit<USER_AVATAR_DETAILS, '__typename'>;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.option} key={option.user.id}>
      <Avatar
        alt="person"
        src={option.user.avatarUrl || undefined}
        name={`${option.user.firstName} ${option.user.lastName}`}
        size="small"
        className={classes.avatar}
      />
      <Typography>
        {option.user.firstName} {option.user.lastName}
      </Typography>
    </div>
  );
};

export default AutoDropdown;
