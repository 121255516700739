import React from 'react';
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';

type Ref =
  | ((instance: HTMLButtonElement | null) => void)
  | React.RefObject<HTMLButtonElement>
  | null;

export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
  buttonClassName?: string;
};

const Button = React.forwardRef(
  (
    {
      variant = 'contained',
      color = 'primary',
      loading,
      buttonClassName,
      ...other
    }: ButtonProps & { loading?: boolean; buttonClassName?: string },
    ref: Ref
  ) => {
    return (
      <MuiButton
        variant={variant}
        color={color}
        ref={ref}
        className={buttonClassName}
        disabled={loading || other.disabled}
        {...other}
      />
    );
  }
);

export default Button;
