import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useAuthContext } from 'components/auth';
import { useApolloMutation } from 'lib/client/utils';
import {
  clients_constraint,
  clients_update_column,
  client_user_roles_enum,
} from '@engine/common/graphql/roles/user/generated/globalTypes';
import {
  upsertClient,
  upsertClientVariables,
} from '@engine/common/graphql/roles/user/generated/upsertClient';
import { UPSERT_CLIENT } from 'lib/graphql/roles/user/mutations';
import { useSnackbar } from 'lib/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'template/theme';
import StepperNavigations, {
  StepperNavigationProps,
} from './StepperNavigations';

const useStyles = makeStyles<Theme>(theme => ({
  root: {},
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type Props = StepperNavigationProps & {};

const FillInCompanyDetails = ({ onBack, onNext, state }: Props) => {
  const { companyName, companyLegalName, companyURL } =
    state?.companyDetails || {};
  const ctx = useAuthContext();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const { register, handleSubmit, errors } = useForm();
  const { issueMutation } = useApolloMutation<
    upsertClient,
    upsertClientVariables
  >({
    ctx,
    role: 'user',
    mutation: UPSERT_CLIENT,
  });

  const onSubmit = async ({
    companyName,
    companyLegalName,
    companyURL,
  }: Record<string, any>) => {
    if (state?.companyDetails?.clientId) onNext(state);
    const userId = ctx.user?.id;
    if (!userId) {
      showSnackbar({
        variant: 'error',
        message: 'Could not get user ID',
      });
      return;
    }
    const obj: upsertClientVariables = {
      userId,
      client: {
        name: companyName,
        legalName: companyLegalName,
        id: companyURL.toLowerCase(),
        client_users: {
          data: [
            {
              userId,
              role: client_user_roles_enum.admin,
            },
          ],
        },
      },
      on_conflict: {
        constraint: clients_constraint.PK_f1ab7cf3a5714dbc6bb4e1c28a4,
        update_columns: [
          clients_update_column.legalName,
          clients_update_column.name,
          clients_update_column.updatedAt,
        ],
      },
    };
    try {
      const result = await issueMutation(obj);

      if (!result) {
        throw new Error('Something went wrong. Try again!');
      } else if (!result.insert_clients?.returning[0]) {
        throw new Error(`Something went wrong. Try again!`);
      }
      onNext({
        companyDetails: {
          companyName,
          companyLegalName,
          companyURL,
          clientId: result.insert_clients.returning[0]?.id,
          clientUserId: result.insert_clients.returning[0]?.client_users[0]?.id,
        },
      });
    } catch (error) {
      showSnackbar({
        variant: 'error',
        message: (error as Error)?.message || 'An error occured',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        label="Company Name"
        name="companyName"
        variant="outlined"
        required
        defaultValue={companyName}
        InputProps={{
          readOnly: Boolean(companyName),
        }}
        inputRef={register({ required: 'Required' })}
        error={errors.companyName}
        helperText={errors.companyName?.message}
        className={classes.inputField}
      />
      <TextField
        fullWidth
        label="Company Legal Name"
        name="companyLegalName"
        variant="outlined"
        required
        defaultValue={companyLegalName}
        InputProps={{
          readOnly: Boolean(companyLegalName),
        }}
        inputRef={register({ required: 'Required' })}
        error={errors.companyLegalName}
        helperText={errors.companyLegalName?.message}
        className={classes.inputField}
      />
      <TextField
        fullWidth
        label="Company URL"
        name="companyURL"
        variant="outlined"
        required
        defaultValue={companyURL}
        inputRef={register({
          required: 'Required',
          validate: {
            noSpace: v => !/\s/.test(v) || 'Space is not allowed',
            noSlash: v => !(v.indexOf('/') > -1) || 'Slash is not allowed',
          },
        })}
        InputProps={{
          readOnly: Boolean(companyURL),
          startAdornment: (
            <InputAdornment position="start">
              app.gitstart.com/clients/
            </InputAdornment>
          ),
        }}
        error={errors.companyURL}
        helperText={errors.companyURL?.message}
        className={classes.inputField}
      />
      <StepperNavigations onBack={onBack} onNext={() => {}} />
    </form>
  );
};

export default FillInCompanyDetails;
