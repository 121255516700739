import {
  AppBar,
  Badge,
  ClickAwayListener,
  colors,
  Hidden,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import Spinner from 'ui/Loader/CircularSpinner';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BugReport from '@material-ui/icons/BugReport';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useAuthContext } from 'components/auth';
import { FeatureFlag } from 'components/FeatureFlag';
import { PaymentContext } from 'components/InvoicePayment/index';
import InvoicePaymentWrapper from 'components/InvoicePayment/InvoicePaymentWrapper';
import NotificationsPopover from 'components/NotificationPopUp';
import { useApolloQuery } from 'lib/client/utils';
import {
  fetchNotifications,
  fetchNotificationsVariables,
} from '@engine/common/graphql/roles/user/generated/fetchNotifications';
import { FETCH_NOTIFICATIONS } from 'lib/graphql/roles/user/queries';
import Link from 'next/link';
import React, {
  MouseEventHandler,
  useContext,
  // useEffect,
  useRef,
  useState,
} from 'react';
import { Theme } from 'template/theme';
import { useAuth } from 'components/auth';
// import { sleep } from 'lib/utils';
import { Button } from 'ui';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    boxShadow: 'none',
    background: `${
      process.env.ENV === 'staging' || process.env.ENV === 'development'
        ? '#6606EF'
        : process.env.ENV === 'production'
        ? 'linear-gradient(90deg, #005BAB 0%, #449DEB 100%)'
        : colors.blue.A400
    }`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuIcon: {
    position: 'fixed',
    left: '5px',
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  bugButton: {
    color: colors.red[600],
  },
  loader: {
    marginLeft: theme.spacing(2),
  },
  signout: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  imageStyle: {
    height: 40,
    [theme.breakpoints.down('xs')]: {
      height: 30,
    },
  },
}));

type Props = {
  className?: string;
  onOpenNavBarMobile: MouseEventHandler;
  fullscreen?: boolean;
  isClient?: boolean;
  clientId?: string;
  showBugButton?: boolean;
  showBuyCreditButton?: boolean;
  userProfile?: {
    firstName?: string | null;
    lastName?: string | null;
    id?: string | null;
  } | null;
  loading?: boolean;
};

const TopBar = (props: Props) => {
  const {
    onOpenNavBarMobile,
    className,
    fullscreen,
    isClient,
    clientId,
    showBugButton,
    showBuyCreditButton,
    userProfile,
    loading,
    ...rest
  } = props;

  const ctx = useAuth(true);

  const classes = useStyles();
  const deviceWidth = useMediaQuery('(max-width:600px)');
  const searchRef = useRef(null);
  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openNotifications, setOpenNotifications] = useState(false);
  const paymentContextValue = useContext(PaymentContext);

  // const { fetchQuery: refreshNotifications, data: notifsData } = useApolloQuery<
  const { data: notifsData } = useApolloQuery<
    fetchNotifications,
    fetchNotificationsVariables
  >({
    ctx,
    role: 'user',
    query: FETCH_NOTIFICATIONS,
    variables: {
      userId: ctx.user?.id || -1,
      limit: 10,
    },
    setLoading: () => {},
  });
  // Note: temp disable as it's pinging the table too much
  // useEffect(() => {
  //   async function fetchNotifications() {
  //     let mounted = true;
  //     while (mounted) {
  //       await refreshNotifications();
  //       await sleep(60 * 1000 * 5); // refresh every five minutes if you are on the page

  //       // ONLY fetch again if the frames are visible
  //       new Promise(resolve => {
  //         requestAnimationFrame(resolve);
  //       });
  //     }
  //     return () => {
  //       mounted = false;
  //     };
  //   }
  //   fetchNotifications();
  // }, []);

  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = (event: any) => {
    setSearchValue((event.target && event.target.value) || '');

    if (event.target && event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const popularSearches = ['Settings', 'Candidates', 'Ticktes'];

  let currentURL = '';
  let browser = '';

  const isBrowser = typeof window !== `undefined`;
  if (isBrowser) {
    currentURL = window.location.href;
    browser = window.navigator.userAgent.replace(';', '');
  }

  const handleCreateBug = (): void => {
    const date = new Date();
    const currentDate = date.toLocaleString('en-US', {
      timeZone: 'America/Dawson',
    });
    window.open(
      `${
        'https://gitstart.atlassian.net/servicedesk/customer/portal/2/group/3/create/43?description=' +
        'Issue+description%3A%0D' +
        '%0A%0D%0A%0D%0ADeveloper+name%3A+'
      }${userProfile?.firstName}%20${userProfile?.lastName}%0ADeveloper+ID%3A+${
        userProfile?.id
      }%0AURL%3A+${currentURL}%0ABrowser+version%3A+${browser}%0ATime%3A+${currentDate}+PDT`,
      '_blank'
    );
  };

  return (
    <>
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          <Link href="/">
            <a className={classes.logo}>
              <img
                alt="Logo"
                src="/images/logos/logo-white-small.png"
                className={classes.imageStyle}
              />
            </a>
          </Link>
          <div className={classes.flexGrow} />
          <Hidden smDown>
            <FeatureFlag flag="search">
              <div className={classes.search} ref={searchRef}>
                <SearchIcon className={classes.searchIcon} />
                <Input
                  className={classes.searchInput}
                  disableUnderline
                  onChange={handleSearchChange}
                  placeholder="Search tasks &amp; hires"
                  value={searchValue}
                />
              </div>
            </FeatureFlag>
            <Popper
              anchorEl={searchRef.current}
              className={classes.searchPopper}
              open={openSearchPopover}
              transition
            >
              <ClickAwayListener onClickAway={handleSearchPopverClose}>
                <Paper className={classes.searchPopperContent} elevation={3}>
                  <List>
                    {popularSearches.map(search => (
                      <ListItem
                        button
                        key={search}
                        onClick={handleSearchPopverClose}
                      >
                        <ListItemIcon>
                          <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary={search} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Hidden>
          {showBuyCreditButton && clientId && paymentContextValue ? (
            <InvoicePaymentWrapper
              verify={paymentContextValue.control}
              clientId={clientId}
            >
              <Button
                className={classes.trialButton}
                onClick={() => {
                  paymentContextValue.activate(null);
                }}
                variant="contained"
              >
                <AttachMoneyIcon />
                Buy Credits
              </Button>
            </InvoicePaymentWrapper>
          ) : null}
          {loading === true ? <Spinner /> : null}
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifsData?.notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {showBugButton && (
            <IconButton color="inherit" onClick={handleCreateBug}>
              <BugReport className={classes.bugButton} />
            </IconButton>
          )}

          <Tooltip
            title={deviceWidth ? 'Sign out' : ''}
            arrow={deviceWidth ?? false}
          >
            <Button
              className={classes.logoutButton}
              variant="text"
              color="inherit"
              onClick={handleLogout}
            >
              <InputIcon className={classes.logoutIcon} />
              <span className={classes.signout}>Sign out</span>
            </Button>
          </Tooltip>
          <Hidden lgUp={!fullscreen}>
            <IconButton
              color="inherit"
              onClick={onOpenNavBarMobile}
              className={classes.menuIcon}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
        <NotificationsPopover
          anchorEl={notificationsRef.current}
          notifications={notifsData?.notifications || []}
          onClose={handleNotificationsClose}
          open={openNotifications}
        />
      </AppBar>
    </>
  );
};

export default TopBar;
