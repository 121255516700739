import HomeIcon from '@material-ui/icons/HomeOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';

export default function AgencyDashboardNavigationConfig(
  agencyId: string,
  isAdmin: boolean,
  agencyName?: string
) {
  return {
    subheader: `${agencyName || agencyId} Agency Portal`,
    items: isAdmin
      ? [
          {
            title: 'Home',
            href: `/agencies/${agencyId}/home`,
            icon: HomeIcon,
          },
          {
            title: 'Analytics',
            href: `/agencies/${agencyId}/analytics`,
            icon: InsertChartOutlinedIcon,
          },
        ]
      : [
          {
            title: 'Home',
            href: `/agencies/${agencyId}/home`,
            icon: HomeIcon,
          },
        ],
  };
}
