import { Base64 } from 'js-base64';

export const getRelayId = (relayId?: string | null) => {
  if (!relayId) {
    return null;
  }

  if (!relayId.length) {
    return relayId;
  }

  try {
    const baseData = Base64.decode(relayId);
    const baseParsedData = JSON.parse(baseData);
    return baseParsedData[baseParsedData.length - 1] || '';
  } catch (ex) {
    console.error('Error parsing relay ID: ', ex);
    return relayId;
  }
};

export { default as isClient } from './isClient';
