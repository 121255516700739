import React from 'react';
import { default as RouterLink } from 'template/utils/link';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import CodeIcon from '@material-ui/icons/Code';
import StoreIcon from '@material-ui/icons/Store';
import gradients from 'template/utils/gradients';
import { Theme } from 'template/theme';
import { fetchNotifications_notifications } from '@engine/common/graphql/roles/user/generated/fetchNotifications';

const useStyles = makeStyles<Theme>(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  avatarBlue: {
    backgroundImage: gradients.blue,
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  avatarOrange: {
    backgroundImage: gradients.orange,
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo,
  },
  arrowForwardIcon: {
    color: theme.palette.link,
  },
}));

type Props = {
  notifications: fetchNotifications_notifications[];
  className: string | undefined;
};

const NotificationList = ({
  notifications,
  className = undefined,
  ...rest
}: Props) => {
  const classes = useStyles();

  const avatars = {
    order: (
      <Avatar className={classes.avatarBlue}>
        <PaymentIcon />
      </Avatar>
    ),
    user: (
      <Avatar className={classes.avatarOrange}>
        <PeopleIcon />
      </Avatar>
    ),
    project: (
      <Avatar className={classes.avatarGreen}>
        <StoreIcon />
      </Avatar>
    ),
    feature: (
      <Avatar className={classes.avatarIndigo}>
        <CodeIcon />
      </Avatar>
    ),
  };

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map(
        (notification, i) =>
          // don't show notifications that are archived
          !notification.isArchived && (
            <ListItem
              className={classes.listItem}
              component={RouterLink}
              divider={i < notifications.length - 1}
              key={notification.id}
              href={notification.appLink}
            >
              <ListItemAvatar>{avatars['feature']}</ListItemAvatar>
              {/* Note: temp hard code the avatar above */}
              <ListItemText
                primary={notification.body}
                primaryTypographyProps={{ variant: 'body1' }}
                secondary={moment(notification.createdAt).fromNow()}
              />
              <ArrowForwardIcon className={classes.arrowForwardIcon} />
            </ListItem>
          )
      )}
    </List>
  );
};

export default NotificationList;
