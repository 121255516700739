import {
  Badge,
  colors,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListSubheader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import clientNavigationConfig from 'components/layouts/Dashboard/ClientDashboard/navigationConfig';
import developerNavigationConfig from 'components/layouts/Dashboard/DeveloperDashboard/navigationConfig';
import agencyNavigationConfig from 'components/layouts/Dashboard/AgencyDashboard/navigationConfig';
import { getRelayId } from 'lib/graphql/relay/utils';
import { DashboardUserQueryResponse } from 'lib/graphql/relay/__generated__/DashboardUserQuery.graphql';
import { useSLAs } from 'lib/stores';
import capitalize from 'lodash/capitalize';
import size from 'lodash/size';
import React, { useState } from 'react';
import { Theme } from 'template/theme';
import RouterLink from 'template/utils/link';
import useRouter from 'template/utils/useRouter';
// import MoreIcon from '@material-ui/icons/MoreVert';
import NavItem from './NavItem';
import { NavigationConfig } from './types';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
}));

function renderNavItems({
  items,
  subheader,
  key,
  pathname,
  depth = 0,
  ...rest
}: {
  items: any;
  depth?: number;
  subheader?: string;
  key?: string | number;
  pathname?: string;
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {items.reduce(
        (acc: any, item: any) =>
          reduceChildRoutes({ acc, item, depth, pathname, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
}: {
  acc: any;
  pathname?: string;
  item: any;
  depth?: number;
}) {
  if (item.items) {
    const open = !!item.href
      ? document.location.pathname?.includes(item.href)
      : false;
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        href={item.href}
        key={item.href ?? item.title}
        label={item.label}
        open={open}
        title={item.title}
        newTab={item.newTab}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        as={item.as}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
        tooltip={item.tooltip}
        newTab={item.newTab}
      />
    );
  }

  return acc;
}

type Props = {
  openMobile: boolean;
  onMobileClose: () => void;
  navigationConfig: NavigationConfig;
  userProfile: DashboardUserQueryResponse['user']['edges'][0]['node'];
  userTeamMembers: DashboardUserQueryResponse['user_teams_connection']['edges'][0]['node'][];
  className?: string;
  fullscreen?: boolean;
};

export const isClient = (
  userInfo: DashboardUserQueryResponse['user']['edges'][0]['node'],
  clientId: string
) => {
  return (
    !userInfo?.developer?.id &&
    !!userInfo?.client_users?.find(
      cu =>
        getRelayId(cu.clientId) === clientId &&
        ['admin', 'user'].includes(cu.role)
    )
  );
};

function NavBar({
  openMobile,
  onMobileClose,
  navigationConfig,
  userProfile,
  userTeamMembers,
  className = undefined,
  ...rest
}: Props) {
  const classes = useStyles();
  const router = useRouter();
  const { slaCounters } = useSLAs();

  const [status, setStatus] = useState<'online' | 'away' | 'busy' | 'offline'>(
    'online'
  );

  const consolidatedNavigationConfigs = userProfile
    ? [
        ...(navigationConfig || []),
        ...(userProfile.developer
          ? [
              developerNavigationConfig(
                getRelayId(userProfile.developer.id),
                size(userProfile?.developer?.developer_contracts) > 0,
                userProfile.agency_user?.agencyId === 'gitstart-community',
                userProfile.mentorshipsByMentoruserid,
                capitalize(userProfile.firstName ?? userProfile.nickname ?? '')
              ),
            ]
          : []),
        ...userProfile.client_users.map(cl =>
          clientNavigationConfig(
            getRelayId(cl.client.id),
            cl.role,
            slaCounters,
            cl.client.name,
            cl.client.client_projects
          )
        ),
        ...(userProfile.agency_user
          ? [
              agencyNavigationConfig(
                getRelayId(userProfile.agency_user.agency.id),
                userProfile.agency_user.role === 'admin',
                userProfile.agency_user.agency.name
              ),
            ]
          : []),
      ]
    : [];

  const handleStatusToggle = () => {
    const statusSeq: {
      online: 'away';
      away: 'busy';
      busy: 'offline';
      offline: 'online';
    } = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online',
    };

    setStatus(prevStatus => statusSeq[prevStatus]);
  };

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {consolidatedNavigationConfigs.map((list, index) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: router.pathname,
            key: list.subheader || index,
          })
        )}
      </nav>
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge || '']: true,
              [classes.onlineBadge || '']: status === 'online',
              [classes.awayBadge || '']: status === 'away',
              [classes.busyBadge || '']: status === 'busy',
              [classes.offlineBadge || '']: status === 'offline',
            }),
          }}
          variant="dot"
        >
          <Avatar
            alt="Person"
            onClick={handleStatusToggle}
            className={classes.avatar}
            src={userProfile?.avatarUrl || ''}
            name={`${userProfile?.firstName} ${userProfile?.lastName}`}
          />
        </Badge>
        <div className={classes.details}>
          <Link
            component={RouterLink}
            href="/settings/general"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {`${userProfile?.firstName} ${userProfile?.lastName}`}
          </Link>
          {/* <Typography variant="body2">{session.user.bio}</Typography> */}
        </div>
        <IconButton className={classes.moreButton} size="small">
          {/* <MoreIcon /> */}
        </IconButton>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

export default NavBar;
