import { Button, Grid, GridProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  base: {
    width: '100%',
    padding: 0,
    textTransform: 'none',
  },
  selected: {
    boxShadow: `0 0 5px 2.5px ${theme.palette.primary.main}`,
    width: '100%',
    padding: 0,
    textTransform: 'none',
  },
}));

type Props = GridProps & {
  selected: boolean;
};

const OptionGrid = ({ children, selected, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <Grid {...rest}>
      <Button className={selected ? classes.selected : classes.base}>
        {children}
      </Button>
    </Grid>
  );
};

export default OptionGrid;
