import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
import { Moment } from 'moment';
import { tasks_type_enum } from '@engine/common/graphql/roles/admin/generated/globalTypes';
import {
  fetchClientTickets,
  fetchClientTickets_tickets,
} from '@engine/common/graphql/roles/client/generated/fetchClientTickets';
import { ticket_status_enum } from '@engine/common/graphql/roles/client/generated/globalTypes';
import { fetchAvailableTasks } from '@engine/common/graphql/roles/developer/generated/fetchAvailableTasks';
import {
  fetchClientTechnologiesTicketsReposStatusTasks_tasks_task_reviewers,
  fetchClientTechnologiesTicketsReposStatusTasks_tasks_task_technologies,
} from '@engine/common/graphql/roles/developer/generated/fetchClientTechnologiesTicketsReposStatusTasks';
import { fetchDeveloperTasks } from '@engine/common/graphql/roles/developer/generated/fetchDeveloperTasks';
import { fetchDeveloperTickets } from '@engine/common/graphql/roles/developer/generated/fetchDeveloperTickets';
import { fetchDevTeamTasks } from '@engine/common/graphql/roles/developer/generated/fetchDevTeamTasks';
import { fetchTeamAvailableTasks } from '@engine/common/graphql/roles/developer/generated/fetchTeamAvailableTasks';
import {
  tasks_bool_exp,
  tasks_status_enum,
  tickets_bool_exp,
} from '@engine/common/graphql/roles/developer/generated/globalTypes';
import {
  fetchTicketDetailsByTicketCode_tickets_tasks_developerByDeveloperid,
  fetchTicketDetailsByTicketCode_tickets_tasks_developerByDeveloperid_user,
  fetchTicketDetailsByTicketCode_tickets_tasks_developerByManagerid,
  fetchTicketDetailsByTicketCode_tickets_tasks_developerByReviewerid,
  fetchTicketDetailsByTicketCode_tickets_tasks_task_reviews_developer,
} from '@engine/common/graphql/roles/user/generated/fetchTicketDetailsByTicketCode';

export interface HolidaysResponse {
  developerId: string | undefined;
  userId: number;
  startDate?: string;
  endDate?: string;

  [key: string]: string | number | undefined;
}
type NullAble<T> = T | null;

export type DeepOptional<T> = {
  [P in keyof T]?: T[P] extends NullAble<object>
    ? DeepOptional<T[P]>
    : T[P] extends string
    ? string
    : T[P];
};

export type StandUpTaskType = {
  taskCode: string;
  taskId: number;
  title: string;
  status: tasks_status_enum;
  summary?: string;
  updatedETA?: Moment;
  taskUserStandupId?: number;
};

export type TaskTechnologies = {
  task_technologies: {
    data: [
      {
        technologiesId: number;
        name: string;
      }
    ];
  };
};

export type ParticipantsDetails = {
  firstName?: string;
  lastName?: string;
  participantType?: string;
};

export type TicketFilterMultiSelectType = {
  label: string;
  options: Array<string> | null;
  name?: string;
};

export interface TasksFilterMultiSelectType {
  label: 'technology';
  options: technology_enum[] | null;
  name?: 'technology';
}

export type FilterForAssignedTasksMultiSelectType = {
  label: string;
  options: Array<string>;
  name?: string;
};

export type TicketFilterType = {
  ticketFilter: tickets_bool_exp;
};

export type DevTaskFilterType = {
  taskFilter: tasks_bool_exp;
};

export type TicketFilterDataType = {
  keyword?: string[];
  status?: string[];
  repo?: string[];
  priority?: string[];
  developer?: string[];
  project?: string[];
  client?: string[];
  technology?: string[];
  page?: string[];
  startDate?: string;
  endDate?: string;
  chartSelectedClients?: string[];
  need_approval?: string | null;
};

export type TaskFilterDataType = {
  keyword?: string[];
  status?: string[];
  client?: string[];
  technology?: string[];
  priority?: string[];
  developer?: string[];
  startDate?: string;
  endDate?: string;
};

export type ClientTicketStateType = {
  clientTickets?: fetchClientTickets | null;
  error?: Error | null;
};

export type DevTicketStateType = {
  devTickets?: fetchDeveloperTickets | null;
  error?: Error | null;
};

export type TaskStateType = {
  devTasks?: fetchDeveloperTasks | fetchDevTeamTasks | null;
  error?: Error | null;
};

export type AvailableTaskStateType = {
  availableTask?: fetchAvailableTasks | null;
  error?: Error | null;
};

export type TeamAvailableTaskStateType = {
  // this should fix available task page erroring
  availableTask?: fetchTeamAvailableTasks | null;
  error?: Error | null;
};

export type ClientDueInvoiceType = {
  clientId: string;
  dueAt: any | null;
  costInUSD: number;
  costInCredits: number;
  id: number;
};

export type ClientTransactionType = {
  id: number;
  costInUSD: number;
  costInCredits: number;
  transactedAt: string;
};

export type FilterStatus = {
  status: ticket_status_enum | tasks_status_enum;
  sequence: number;
};

export interface TicketSequence extends fetchClientTickets_tickets {
  sequence: number | undefined;
}

export type TaskChipsFilterType = {
  keyword?: string[];
  status?: string[];
  repo?: string[];
  developer?: string[];
  project?: string[];
  client?: string[];
  technology?: string[];
  startDate?: string;
  endDate?: string;
};

export enum technology_enum {
  React = 'React',
  JavaScript = 'JavaScript',
  TypeScript = 'TypeScript',
  Python = 'Python',
  'C#' = 'C#',
  'React-Native' = 'React-Native',
  Nodejs = 'Nodejs',
  VueJS = 'VueJS',
  DevOps = 'DevOps',
  Admin = 'Admin',
}

export interface TicketsDevelopersWithRoles
  extends fetchTicketDetailsByTicketCode_tickets_tasks_task_reviews_developer {
  role?: string;
}

export interface TabName {
  value: string;
  label: string;
}

export type ParticipantsArrayType = {
  key: string;
  participantType: string;
  user: fetchTicketDetailsByTicketCode_tickets_tasks_developerByDeveloperid_user;
  __typename: string;
};

export type ParticipantDataType =
  | fetchTicketDetailsByTicketCode_tickets_tasks_developerByDeveloperid
  | fetchTicketDetailsByTicketCode_tickets_tasks_developerByManagerid
  | fetchTicketDetailsByTicketCode_tickets_tasks_developerByReviewerid
  | null;

export type InstallationListType = {
  sid: string;
  orgSid: string;
  login: string;
};

export type ParticipantsMap = {
  [key: string]: ParticipantsArrayType;
};
export interface Window {
  analytics: SegmentAnalytics.AnalyticsJS;
}

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type CreateEditTaskTypes = {
  __typename: 'tasks';
  id: number | null;
  type: tasks_type_enum;
  description: string;
  title: string;
  ticketCode: string | null;
  taskCode: string | null;
  budget: number;
  clientId: string | null;
  managerId: string | null;
  reviewerId: string | null;
  developerId: string | null;
  branchName: string | null;
  isBillable: boolean;
  status: tasks_status_enum;
  clientCommitMessage: string | null;
  gitRepoId: number | null;
  /**
   * An array relationship
   */
  task_reviewers: fetchClientTechnologiesTicketsReposStatusTasks_tasks_task_reviewers[];
  /**
   * An array relationship
   */
  task_technologies: fetchClientTechnologiesTicketsReposStatusTasks_tasks_task_technologies[];
};

export type Token = {
  aud: string;
  email_verified: boolean;
  exp: number;
  ['https://hasura.io/jwt/claims']: {
    'x-hasura-agency-id': null | string;
    'x-hasura-client-id': null | string;
    'x-hasura-allowed-roles': string[];
    'x-hasura-default-role': string;
    'x-hasura-developer-id': null | string;
    'x-hasura-user-id': string;
  };
  iat: number;
  iss: string;
  nonce: string;
  sub: string;
  updated_at: string;
};

export type User = {
  id: number;
  clientId?: string | null;
  agencyId?: string | null;
  developerId?: string | null;
  candidateId?: string | null;
  roles: string[];
  defaultRole: string;
};

export type Context = {
  user: User | null;
  jwtToken: string | null;
  graphqlClient: ApolloClient<NormalizedCacheObject> | null;
  backendClient: ApolloClient<NormalizedCacheObject> | null;
  isLoading: boolean;
  logout: Function;
  loginWithPopup: Function;
  /**
   * Whether the AuthContext is fake or not. This is used to create a mock environment, such as Storybook.
   *
   * If set to `true`, the 'user' is always logged in, and can never logout. All GraphQL requests using `useApolloQuery` and `useApolloMutation` will never resolve (not error).
   *
   * If set to `false`, the AuthContext works as normal.
   */
  isFake?: boolean;
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
