import React, { ReactNode, ErrorInfo } from 'react';
import {
  ErrorBoundary,
  useErrorHandler as errorHandler,
} from 'react-error-boundary';

import {
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { captureException } from 'lib/sentry';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

type ErrorFallbackProp = {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
};
type ErrorBoundaryWithFallbackProps = {
  children: ReactNode;
};

/**
  1. Render fallback - [DONE]
  2. Add reset Recovery functionality - [DONE]
  3. Don't navigate away from the page - [DONE].
  3. Log Error to Sentry - [DONE]
  4. Convert all useQuery/useMutation to use it. See example here:
  @engine/app/src/components/CreateEditTask/CreateEditTaskForm.tsx - [TODO]
 */

function myErrorHandler(error: Error, info: ErrorInfo): void {
  console.info(error, info);
  captureException(error, { errorInfo: info });
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProp) {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  // TODO: Later, Convert the GraphQL errors to human friendly
  // (EG. Email Uniqueness to user already exist)
  return (
    <div role="alert" className={classes.root}>
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        Ooops, something went terribly wrong!
      </Typography>
      <Typography align="center" variant="subtitle2">
        {error
          ? `Got back exception: ${error.message || error}`
          : `You either tried some shady route or you came here by mistake. Whichever
        it is, try using the navigation`}
      </Typography>
      <div className={classes.imageContainer}>
        <img
          alt="Under development"
          className={classes.image}
          src="/template/images/undraw_server_down_s4lk.svg"
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button color="primary" onClick={resetErrorBoundary} variant="outlined">
          Try Again
        </Button>
      </div>
    </div>
  );
}

export default function ErrorBoundaryWithFallback({
  children,
}: ErrorBoundaryWithFallbackProps): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {children}
    </ErrorBoundary>
  );
}

export const useErrorHandler = errorHandler;
