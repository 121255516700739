import { makeStyles } from '@material-ui/core';
import Spinner from 'ui/Loader/CircularSpinner';
import { useAuth, useAuthContext } from 'components/auth';
import { Layout } from 'components/Layout';
import { getRelayId } from 'lib/graphql/relay/utils';
import { DashboardUserQuery as DashboardUserQueryType } from 'lib/graphql/relay/__generated__/DashboardUserQuery.graphql';
import { useLoading } from 'lib/hooks';
import { Router } from 'next/router';
import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { useStyles as useDashboardStyles } from 'template/layouts/Dashboard';
import { Theme } from 'template/theme';
import { ClientDashboard } from './ClientDashboard';
import { DeveloperDashboard } from './DeveloperDashboard';
import { AgencyDashboard } from './AgencyDashboard';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { NavigationConfig } from './types';
import UseIntercom from './UseIntercom';

type SelectedClient = {
  type: 'client';
  clientId: string;
  onPaymentComplete?: () => Promise<any>;
};

type SelectedDeveloper = {
  type: 'developer';
  developerId: string;
};

type SelectedAdmin = {
  type: 'admin';
  adminId: string;
};

type SelectedAgency = {
  type: 'agency';
  agencyId: string;
};

type SelectedCandidate = {
  type: 'candidate';
  candidateId: string;
};

type SelectedTeam = {
  type: 'team';
  teamId: number;
};

export const useStyles = makeStyles((theme: Theme) => ({
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  content: {
    margin: 0,
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    '@media print': {
      padding: 0,
    },
  },
}));
type DashboardProps = {
  title?: string;
  children?: ReactNode;
  navigationConfig?: NavigationConfig;
  fullscreen?: boolean;
  loading?: boolean;

  selected?:
    | SelectedClient
    | SelectedDeveloper
    | SelectedAdmin
    | SelectedCandidate
    | SelectedAgency
    | SelectedTeam;

  clientId?: string;
  developerId?: string;
  candidateId?: string;
  agencyId?: string;
  adminId?: string;
  teamId?: number;
};

export const UserDashboard = (
  props: DashboardProps & {
    userInfoRef: PreloadedQuery<DashboardUserQueryType>;
  }
) => {
  const authCtx = useAuthContext();
  const { loading: isLoading } = useLoading();

  const classes = useDashboardStyles();
  const dashboardClasses = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [isRouteTransitioning, setRouteTransitioning] = useState(false);

  const {
    title,
    children,
    navigationConfig = [],
    clientId,
    loading = isLoading,
    selected,
    userInfoRef,
  } = props;
  const userInfoResp = usePreloadedQuery<DashboardUserQueryType>(
    DashboardUserQuery,
    userInfoRef
  );

  const userInfo = userInfoResp.user.edges[0]?.node || null;
  const user_team_members =
    userInfoResp.user_teams_connection.edges.map(edge => edge.node) || [];

  const userId = getRelayId(userInfo?.id);

  const selectedClientUser = userInfo?.client_users?.find(
    cu =>
      selected?.type === 'client' &&
      getRelayId(cu.client.id) === selected?.clientId.toString()
  );

  // full story
  useEffect(() => {
    if (process.browser && userInfo && (window as any).FS) {
      const displayName =
        userInfo.firstName && userInfo.lastName
          ? `${userInfo.firstName} ${userInfo.lastName}`
          : userInfo.nickname;

      (window as any).FS.identify(userId, {
        displayName,
        email: userInfo?.defaultEmail || userInfo.user_emails?.[0]?.email,

        // Adding custom params. See:
        // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
        client_id_str: getRelayId(selectedClientUser?.client.id),
      });
    }
  }, [userInfo, getRelayId(selectedClientUser?.client?.id)]);

  // segment
  useEffect(() => {
    if (process.browser && userInfo && window) {
      const name =
        userInfo.firstName && userInfo.lastName
          ? `${userInfo.firstName} ${userInfo.lastName}`
          : userInfo.nickname;

      analytics.identify(userId, {
        name,
        email: userInfo?.defaultEmail ?? userInfo?.user_emails?.[0]?.email,
        avatarUrl: userInfo?.avatarUrl,
        login: userInfo.login,
        role: !!getRelayId(userInfo.developer?.id)
          ? 'developer'
          : !!authCtx.user?.clientId
          ? 'client'
          : 'others',
      });
    }
  }, [userInfo, authCtx.user?.clientId]);

  // route transitions to show loading state
  useEffect(() => {
    const handleRouteChangeStart = () => {
      setRouteTransitioning(true);
    };

    const handleRouteChangeEnd = () => {
      setRouteTransitioning(false);
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeEnd);
    Router.events.on('routeChangeError', handleRouteChangeEnd);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeEnd);
      Router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, []);

  // blank screen before user info loads
  if (!userInfo) {
    return (
      <>
        <TopBar
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          fullscreen={props.fullscreen}
          isClient={false}
          userProfile={userInfo}
          loading={props.loading}
        />
        <div className={classes.container}>
          <div className={dashboardClasses.content}>
            {loading || isRouteTransitioning ? <Spinner /> : null}
          </div>
        </div>
      </>
    );
  }

  const navElems = (
    <div className={dashboardClasses.hideOnPrint}>
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
        fullscreen={props.fullscreen}
        isClient={!!authCtx.user?.clientId}
        clientId={selected?.type === 'client' ? selected.clientId : clientId}
        showBugButton={selected?.type !== 'client'}
        userProfile={userInfo}
        showBuyCreditButton={
          selected?.type === 'client' && selectedClientUser?.role === 'admin'
        }
        loading={loading}
      />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        navigationConfig={navigationConfig}
        userProfile={userInfo}
        userTeamMembers={user_team_members}
        fullscreen={props.fullscreen}
      />
      {userInfo &&
      (userInfo.defaultEmail || userInfo.user_emails?.[0]?.email) ? (
        <UseIntercom
          userId={userId}
          userInfo={userInfo}
          selectedClientUser={selectedClientUser}
          selected={selected}
        />
      ) : null}
    </div>
  );

  let childElems: ReactNode | null = null;

  if (selected?.type === 'client' && selectedClientUser?.client) {
    const { id, name, onBoardedAt } = selectedClientUser?.client;
    const { clientId, onPaymentComplete } = selected;
    childElems = (
      <ClientDashboard
        {...{
          client: {
            id: getRelayId(id) || clientId,
            name,
            onBoardedAt: (onBoardedAt as string).toString(),
          },
          user: {
            defaultEmail: userInfo.defaultEmail,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          },
          onPaymentComplete,
        }}
      >
        {navElems}
        {children}
      </ClientDashboard>
    );
  } else if (selected?.type === 'developer') {
    childElems = (
      <DeveloperDashboard developerId={selected.developerId}>
        {navElems}
        {children}
      </DeveloperDashboard>
    );
  } else if (selected?.type === 'agency') {
    childElems = (
      <AgencyDashboard agencyId={selected.agencyId}>
        {navElems}
        {children}
      </AgencyDashboard>
    );
  } else {
    childElems = (
      <>
        {navElems}
        {children}
      </>
    );
  }

  return (
    <Layout title={title}>
      <div className={classes.container}>
        <div className={dashboardClasses.content}>
          <Suspense fallback={<Spinner />}>{childElems}</Suspense>
        </div>
      </div>
    </Layout>
  );
};

export const DashboardUserQuery = graphql`
  query DashboardUserQuery($userId: Int!) {
    user_teams_connection(
      where: {
        _or: [
          {
            user_team_members: {
              _and: [
                { userId: { _eq: $userId } }
                {
                  _or: [
                    { endAt: { _is_null: true } }
                    { endAt: { _gte: "now()" } }
                  ]
                }
              ]
            }
          }
          { isPublic: { _eq: true } }
        ]
      }
    ) {
      edges {
        node {
          id
          teamName
        }
      }
    }

    user: users_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          id
          firstName
          avatarUrl
          lastName
          defaultEmail
          nickname
          telephone
          login
          timezone

          user_skills(where: { aggregatedMonthsOfExperience: { _gte: 1 } }) {
            skill {
              name
            }
          }

          mentorshipsByMentoruserid {
            mentorUserId
            menteeUserId
            mentee {
              id
              login
              firstName
              lastName
              name
            }
          }

          user_opportunity_interests {
            opportunity {
              name
            }
          }

          user_emails {
            email
          }

          admin {
            id
          }

          developer {
            id
            source
            githubId
            linkedInUrl
            yearsOfExperience
            stage
            bio
            cv
            culture_screen_feedback
            tech_screen_feedback

            developer_contracts {
              user {
                id
              }
            }
          }

          client_users {
            id
            role
            clientId
            client {
              id
              name
              onBoardedAt
              meetings {
                scheduledAt
              }
              client_projects(
                where: {
                  _or: [
                    { client_project_users: { user: { id: { _eq: $userId } } } }
                    {
                      client: {
                        client_users: {
                          _and: [
                            { userId: { _eq: $userId } }
                            { role: { _in: [admin, internal] } }
                          ]
                        }
                      }
                    }
                  ]
                }
              ) {
                id
                name
                clientId
              }
            }
          }

          agency_user {
            id
            agencyId
            role
            agency {
              id
              name
            }
          }

          git_users {
            githubAccountLogin
          }
        }
      }
    }
  }
`;

export const DashboardLoading = (props: DashboardProps) => {
  return (
    <TopBar
      onOpenNavBarMobile={() => {}}
      fullscreen={props.fullscreen}
      isClient={false}
      userProfile={null}
      loading={props.loading}
    />
  );
};

export const Dashboard = (props: DashboardProps) => {
  const ctx = useAuth(true);
  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<DashboardUserQueryType>(DashboardUserQuery);

  useEffect(() => {
    if (ctx.user?.id) {
      loadQuery({ userId: ctx.user.id }, { fetchPolicy: 'store-or-network' });
    }
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, ctx.user?.id]);

  // blank screen before user info loads
  if (!queryReference) {
    return (
      <TopBar
        onOpenNavBarMobile={() => {}}
        fullscreen={props.fullscreen}
        isClient={false}
        userProfile={null}
        loading={props.loading}
      />
    );
  }

  return (
    <Suspense
      fallback={
        <TopBar
          onOpenNavBarMobile={() => {}}
          fullscreen={props.fullscreen}
          isClient={false}
          loading={props.loading}
        />
      }
    >
      <UserDashboard {...{ ...props }} userInfoRef={queryReference} />
    </Suspense>
  );
};
