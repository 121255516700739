import gql from 'graphql-tag';

export const CLIENT_INVOICE_FRAGMENT = gql`
  fragment CLIENT_INVOICE_FRAGMENT on client_invoices {
    id
    clientId
    costInUSD
    costInCredits
    dueAt
    maxBudgetInCredits
    client_invoice_balance {
      consumed_balance_in_credits
      currrent_paid_balance_in_usd
      previous_balance_in_credits
      previous_balance_in_usd
      previous_consumed_balance_in_credits
    }
    consumedCredits: client_tickets_aggregate {
      aggregate {
        sum {
          discountedCostInCredits
        }
      }
    }
    startAt
    endAt
  }
`;
export const CLIENT_INVOICE_DETAILS_FRAGMENT = gql`
  fragment CLIENT_INVOICE_DETAILS_FRAGMENT on clients {
    id
    name
    creditBatchSize
    client_projects {
      id
      client_project_pricing_plans(
        where: {
          _or: [{ endAt: { _is_null: true } }, { endAt: { _gte: "now()" } }]
        }
        limit: 1
      ) {
        id
        credit_pricing_plan {
          id
          costPerCredit
        }
      }
    }

    client_transactions(order_by: { transactedAt: asc_nulls_last }) {
      id
      invoiceCode
      status
      channelType
      channelTransactionId
      costInUSD
      costInCredits
      transactedAt
      dueAt
    }

    client_invoices(
      order_by: { dueAt: asc_nulls_last }
      where: { deletedAt: { _is_null: true } }
    ) {
      ...CLIENT_INVOICE_FRAGMENT
    }

    tickets(
      where: {
        _and: [
          { isInternal: { _eq: false } }
          { invoiceId: { _is_null: true } }
          { completedAt: { _is_null: false } }
        ]
      }
      order_by: { completedAt: asc_nulls_last }
    ) {
      id
      code
      costInCredits
      billableCostInCredits
      completedAt
    }
  }

  ${CLIENT_INVOICE_FRAGMENT}
`;
