import { makeStyles } from '@material-ui/core';
import { Avatar } from '..';
import React from 'react';
import { Theme } from '@engine/app/src/template/theme';

type variantType = 'circle' | 'circular' | 'rounded' | 'square';

type Props = {
  variant?: variantType;
  src: string;
  name: string;
  isCurrent?: boolean;
  userLogin?: string | null;
};

const variantDefault: variantType = 'circle';

const defaultProps = {
  variant: variantDefault,
};

export type UserNameProps = Props;

export const UserName: React.FC<UserNameProps> = props => {
  const { src, name, variant, isCurrent } = props;
  const useStyles = makeStyles((theme: Theme) => ({
    option: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& a': {
        color: 'inherit', // no blue
        textDecoration: 'inherit', // no underline
      },
    },
    padding: {
      padding: '5px',
    },
    text: {
      fontFamily: [
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        // 'sans-serif',
        // '"Apple Color Emoji"',
        // '"Segoe UI Emoji"',
        // '"Segoe UI Symbol"',
      ].join(','),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontWeight: isCurrent ? 'bold' : 'normal',
    },
  }));
  const classes = useStyles();

  let nameLabel = <p>{name}</p>;
  // TODO: add isAdmin (can check everyone), isTeamLead (can check their team)
  if (props.userLogin) {
    nameLabel = (
      <a
        href={`/developers/${props.userLogin}/calendar`}
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
    );
  }

  return (
    <div className={classes.option}>
      <Avatar src={src} variant={variant} />
      <div className={classes.padding}></div>
      <div className={classes.text}>{nameLabel}</div>
    </div>
  );
};
UserName.defaultProps = defaultProps;

export default UserName;
