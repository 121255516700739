import {
  Button,
  colors,
  Grid,
  Typography,
  makeStyles,
  Theme,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CardElement, useStripe } from '@stripe/react-stripe-js';
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { useAuth } from 'components/auth';
import { useApolloMutation } from 'lib/client/utils';
import { getStripePaymentMethods } from '@engine/common/graphql/roles/client/generated/getStripePaymentMethods';
import { client_transaction_status_enum } from '@engine/common/graphql/roles/client/generated/globalTypes';
import {
  initiateStripePayment,
  initiateStripePaymentVariables,
} from '@engine/common/graphql/roles/client/generated/initiateStripePayment';
import {
  updateClientTransaction,
  updateClientTransactionVariables,
} from '@engine/common/graphql/roles/client/generated/updateClientTransaction';
import {
  INITIATE_STRIPE_PAYMENT,
  UPDATE_CLIENT_TRANSACTION,
} from 'lib/graphql/roles/client/mutations';
import React, { EventHandler, SyntheticEvent, useState } from 'react';

const useStyles = makeStyles<Theme>(theme => ({
  actions: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  startButton: {
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  paymentMethodList: {
    marginBottom: theme.spacing(4),
  },
}));

type PaymentTriggerProps = {
  setError: (err: Error | null) => void;
  costInUSD: number;
  costInCredits: number;
  invoiceIds: number[];
  stripeCardLatestEvent: StripeCardElementChangeEvent | null;
  stripeCardElem: StripeCardElement | null;
  paymentMethod:
    | getStripePaymentMethods['getStripePaymentMethods']['paymentMethods'][0]
    | null;
  clientId: string;
  onSuccessful: () => void;
  setLoading: (loading: boolean) => void;
};

export const PaymentTrigger: React.FC<PaymentTriggerProps> = (
  props: PaymentTriggerProps
) => {
  const {
    costInUSD,
    costInCredits,
    invoiceIds,
    onSuccessful,
    setError,
    setLoading,
    stripeCardLatestEvent,
    stripeCardElem,
    paymentMethod,
    clientId,
  } = props;

  const [disableBtn, setDisableBtn] = useState(false);
  const classes = useStyles();
  const ctx = useAuth(true);
  const stripe = useStripe();

  const { issueMutation: updateTransaction } = useApolloMutation<
    updateClientTransaction,
    updateClientTransactionVariables
  >({
    ctx,
    role: 'client',
    mutation: UPDATE_CLIENT_TRANSACTION,
    setError: error => {
      setDisableBtn(false);
      setError(error);
    },
    setLoading,
  });

  const { issueMutation: initiateStripePayment } = useApolloMutation<
    initiateStripePayment,
    initiateStripePaymentVariables
  >({
    ctx,
    role: 'client',
    mutation: INITIATE_STRIPE_PAYMENT,
    setError: error => {
      setDisableBtn(false);
      setError(error);
    },
    setLoading,
    defaultVariables: {
      clientId,
      costInUSD,
      costInCredits,
      invoiceIds,
    },
  });

  const makePayment: EventHandler<SyntheticEvent> = async (
    ev: SyntheticEvent
  ) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    ev.preventDefault();

    setLoading(true);
    setDisableBtn(true);
    setError(null);

    const data = await initiateStripePayment({
      clientId,
      costInUSD,
      costInCredits,
      invoiceIds,
      paymentMethodId: paymentMethod?.id,
    });

    const stripePaymentIntentClientSecret =
      data?.initiateStripePayment.stripePaymentIntentClientSecret;

    if (
      !data ||
      data.initiateStripePayment.error ||
      !stripePaymentIntentClientSecret
    ) {
      setError(
        new Error(
          data?.initiateStripePayment.error ||
            'Could not generate a token from the backend to charge card!'
        )
      );
      setLoading(false);
      setDisableBtn(false);
      return;
    }
    const transactionId = data.initiateStripePayment.id;

    const card = stripeCardElem;
    if (!card) {
      const error = new Error(
        'Could not validate ard details. Please try again!'
      );
      if (transactionId) {
        await updateTransaction({
          transactionId,
          transaction: {
            status: client_transaction_status_enum.failed,
            transactionError: error.message,
          },
        });
      }
      setError(error);

      setLoading(false);
      setDisableBtn(false);
      return;
    }

    try {
      const result = await stripe?.confirmCardPayment(
        stripePaymentIntentClientSecret,
        {
          payment_method: paymentMethod
            ? paymentMethod.id
            : {
                card,
                billing_details: {
                  name: `${clientId} credits purchase`,
                },
              },
          setup_future_usage: 'off_session',
        }
      );

      if (result && result?.paymentIntent?.status === 'succeeded') {
        if (transactionId) {
          await updateTransaction({
            transactionId,
            transaction: {
              status: client_transaction_status_enum.in_progress,
            },
          });
        }
        onSuccessful();
        setError(null);
      } else {
        const error = new Error(
          result?.error?.message || 'Payment not successful. Please try again!'
        );
        if (transactionId) {
          await updateTransaction({
            transactionId,
            transaction: {
              status: client_transaction_status_enum.failed,
              transactionError:
                result?.error?.message || 'Lack of response from stripe!',
            },
          });
        }
        setError(error);
      }
    } catch (ex) {
      if (transactionId) {
        await updateTransaction({
          transactionId,
          transaction: {
            status: client_transaction_status_enum.failed,
            transactionError: (ex as Error).message,
          },
        });
      }
      setError(ex as Error);
    }

    setDisableBtn(false);
    setLoading(false);
  };

  return (
    <Button
      disabled={
        ((stripeCardLatestEvent?.error || !stripeCardLatestEvent?.complete) &&
          !paymentMethod) ||
        disableBtn
      }
      className={classes.startButton}
      onClick={makePayment}
      variant="contained"
    >
      {' '}
      Pay
    </Button>
  );
};

export type StripePaymentMethod = getStripePaymentMethods['getStripePaymentMethods']['paymentMethods'][0];

type BareProps =
  | {
      bare?: false;
    }
  | {
      bare: true;

      onStripeCardElem: (elem: StripeCardElement) => void;
      onStripePaymentMethod: (method: StripePaymentMethod | null) => void;
      onStripeCardEvent: (ev: StripeCardElementChangeEvent) => void;
    };

export type StripePaymentCheckoutProps = {
  costInUSD: number;
  costInCredits: number;
  invoiceIds: number[];
  paymentMethods: StripePaymentMethod[];
  clientId: string;

  setError: (err: Error | null) => void;
  onSuccessful: () => void;
  setLoading: (loading: boolean) => void;
} & BareProps;

const StripePaymentCheckout: React.FC<StripePaymentCheckoutProps> = (
  props: StripePaymentCheckoutProps
) => {
  const [
    stripeCardElem,
    setStripeCardElem,
  ] = useState<StripeCardElement | null>(null);
  const [
    paymentMethod,
    setPaymentMethod,
  ] = useState<StripePaymentMethod | null>(props.paymentMethods[0] || null);
  const [
    stripeEvent,
    setStripeEvent,
  ] = useState<StripeCardElementChangeEvent | null>(null);

  const {
    costInUSD,
    costInCredits,
    invoiceIds,
    onSuccessful,
    setError,
    setLoading,
    paymentMethods,
    clientId,
  } = props;

  const classes = useStyles();

  const onPaymentMethod = (method: StripePaymentMethod | null) => {
    setPaymentMethod(method);
    if (props.bare) {
      props.onStripePaymentMethod(method);
    }
  };

  const cardOnChange = (event: StripeCardElementChangeEvent) => {
    console.log('got back event: ', event);

    if (!event.empty) {
      onPaymentMethod(null);
    }

    setStripeEvent(event);
    if (props.bare) {
      props.onStripeCardEvent(event);
    }
  };

  return (
    <>
      {!!paymentMethods.length ? (
        <>
          <div className={classes.paymentMethodList}>
            <Typography align="left" gutterBottom variant="h5">
              Select existing card to pay
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size="medium" />
                  <TableCell size="small">Credit Card Number</TableCell>
                  <TableCell size="small">MM/YY</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentMethods.map(pm => (
                  <TableRow hover key={pm.id}>
                    <TableCell size="medium">
                      <Checkbox
                        checked={paymentMethod?.id === pm.id}
                        color="primary"
                        onChange={(_event, checked) => {
                          if (checked) {
                            onPaymentMethod(pm);
                            if (stripeCardElem) {
                              stripeCardElem.clear();
                            }
                          } else if (paymentMethod?.id === pm.id) {
                            onPaymentMethod(null);
                          }
                        }}
                        value
                      />
                    </TableCell>

                    <TableCell size="medium">
                      {`**** **** **** ${pm.card?.last4}`}
                    </TableCell>

                    <TableCell size="medium">
                      {`${pm.card?.exp_month}/${pm.card?.exp_year}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      ) : null}

      <span
        style={{
          visibility: !!paymentMethod ? 'hidden' : 'visible',
        }}
      >
        <Typography align="left" gutterBottom variant="h5">
          Select a new credit card for payment
        </Typography>
        <CardElement
          onChange={cardOnChange}
          onReady={elem => setStripeCardElem(elem)}
        />
      </span>
      {!props.bare ? (
        <div className={classes.actions}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <PaymentTrigger
                {...{
                  costInUSD,
                  costInCredits,
                  invoiceIds,
                  onSuccessful,
                  setError,
                  setLoading,
                  paymentMethod,
                  clientId,
                  stripeCardElem,
                  stripeCardLatestEvent: stripeEvent,
                }}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default StripePaymentCheckout;
