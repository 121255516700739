import { Card, Grid, makeStyles, Typography } from '@material-ui/core';
import BitbucketIntegration from 'components/integrations/Bitbucket';
import GitHubIntegration from 'components/integrations/GitHubApp';
import GitlabIntegration from 'components/integrations/GitLab';
import { IntegrationType } from 'components/integrations/types';
import { useSnackbar } from 'lib/hooks';
import React, { useState } from 'react';
import OptionGrid from './OptionGrid';
import StepperNavigations, {
  StepperNavigationProps,
} from './StepperNavigations';

const useStyles = makeStyles(() => ({
  root: {},
  OtherButton: {
    height: '100%',
    minHeight: '44px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

type Props = StepperNavigationProps & {};

const InstallOurGitStartAppOnYourRepositories = ({
  onBack,
  onNext,
  state,
}: Props) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const [options, setOptions] = useState(new Set(state?.gitProviderOptions));

  const check = (integrationName: IntegrationType) =>
    options.has(integrationName);

  const handleSuccess = (
    integrationName: IntegrationType,
    message?: string
  ) => {
    setOptions(opt => {
      const newOptions = new Set(opt);
      if (check(integrationName)) newOptions.delete(integrationName);
      else newOptions.add(integrationName);
      return newOptions;
    });

    if (message && message !== '') {
      showSnackbar({
        variant: 'success',
        message,
      });
    }
  };

  const handleNext = () => {
    onNext({
      gitProviderOptions: options,
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <OptionGrid selected={check('GitHub')} item xs={12} md={4}>
          <GitHubIntegration
            onSuccess={handleSuccess}
            onClick={handleSuccess}
          />
        </OptionGrid>
        <OptionGrid selected={check('GitLab')} item xs={12} md={4}>
          <GitlabIntegration onSuccess={handleSuccess} />
        </OptionGrid>
        <OptionGrid selected={check('Bitbucket')} item xs={12} md={4}>
          <BitbucketIntegration onSuccess={handleSuccess} />
        </OptionGrid>
        <OptionGrid selected={check('other')} item xs={12}>
          <Card
            className={classes.OtherButton}
            onClick={() => handleSuccess('other', '')}
          >
            <Typography variant="button">other</Typography>
          </Card>
        </OptionGrid>
      </Grid>
      <StepperNavigations
        onBack={onBack}
        onNext={handleNext}
        disableNext={!options.size}
      />
    </>
  );
};

export default InstallOurGitStartAppOnYourRepositories;
