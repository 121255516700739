import { useUserInfo } from 'lib/stores';
import { isExclusivelyClient, isProd } from 'lib/utils';
import { useState } from 'react';
import Spinner from 'ui/Loader/CircularSpinner';
import Button from 'ui/Button';
import { x } from '@xstyled/emotion';
import Modal from 'components/modals/Modal';
import { client_user_roles_enum } from '@engine/common/graphql/roles/user/generated/globalTypes';

interface NewDashboardRedirectProps {
  children: React.ReactNode;
}

const migratedInstancesArray = [
  'Excel Engine Performance',
  '[500 hours of work] Project: ReadyTravel Kiosk Unit Testing and CI',
  'Coherent-ios-sdk',
  'gitstart-backend',
  'Coherent-connect',
  'Hello Alice Project',
  'Sourcegraph Frontend',
  'Parabol Commercial',
  'First Resonance Development',
  'appsmith-commercial',
  'Savvy Frontend',
  'Instrumental Project',
  'bluemeg e-sign',
];
const teamInstances = new Map([
  [
    'Meteors',
    ['[500 hours of work] Project: ReadyTravel Kiosk Unit Testing and CI'],
  ],
  ['Avengers', ['Coherent-ios-sdk', 'gitstart-backend']],
  ['Flash', ['Coherent-connect']],
  ['Infinity', ['Hello Alice Project']],
  ['Gryffindor', ['Sourcegraph Frontend', 'Parabol Commercial']],
  ['Spartan', ['Excel Engine Performance']],
  ['Wonders', ['First Resonance Development', 'appsmith-commercial']],
  ['Titans', ['Savvy Frontend']],
  [
    'Asgard',
    ['Parabol Commercial', 'Instrumental Project', 'appsmith-commercial'],
  ],
  ['Griffin', ['Instrumental Project', 'gitstart-backend', 'bluemeg e-sign']],
  ['Trophy', ['appsmith-commercial', 'bluemeg e-sign']],
  ['Hustle', ['bluemeg e-sign']],
]);

const migratedInstances = new Set(migratedInstancesArray);

const redirectDev = (ticketCode: string | null) => {
  const extension = isProd() ? 'com' : 'dev';

  if (ticketCode)
    window.location.replace(
      `https://developers.gitstart.${extension}/ticket/${ticketCode}`
    );
  window.location.replace(`https://developers.gitstart.${extension}/home`);
};

// const redirectClient = () => {
// const extension = isProd() ? 'com' : 'dev';

// window.location.replace(`https://client.gitstart.${extension}/home`);
// return <></>;
// };

const dontNeedOldDashboard = (
  userInfo: {
    client_users:
      | {
          clientId: string;
          role: client_user_roles_enum;
        }[]
      | null;
    developer: { id: string } | null;
  } | null
) => {
  // if the user is exclusively a client, don't redirect them yet
  if (isExclusivelyClient(userInfo) === true) {
    return false;
  }

  // if the user is an admin in client users (likely client success team), dont' redirect them yet
  // if the user is in internal in client users (likely client project manager), don't redirect them yet as well
  if (userInfo?.client_users && userInfo.client_users.length > 0) {
    for (const clientUser of userInfo.client_users) {
      if (
        clientUser.role === client_user_roles_enum.admin ||
        clientUser.role === client_user_roles_enum.internal
      ) {
        return false;
      }
    }
  }

  // otherwise, redirect
  return true;
};

export const NewDashboardRedirect = ({
  children,
}: NewDashboardRedirectProps) => {
  const { userInfo, loading, error } = useUserInfo();
  const [dismissedWarning, setDismissedWarning] = useState(false);

  const ready = (userInfo !== null && !loading) || error;
  if (!ready) return <Spinner />;
  if (error) throw error;

  let ticketCode: string | null = null;
  // Get the ticket code to redirect to
  if (
    typeof window !== 'undefined' &&
    window.location.pathname.includes('/tickets/')
  ) {
    ticketCode = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    );
  }

  // TODO: enable this later to redirect clients to new dashboard one by one
  // if (isExclusivelyClient(userInfo)) redirectClient();

  const { user_teams = [] } = userInfo ?? {};
  const userTeamNames = user_teams.map(({ teamName }) => teamName);

  // TODO: enable this later when client success team don't need to use the dashboard anymore
  // const hasNoTeam = userTeamNames.length === 0;
  // if (hasNoTeam && dontNeedOldDashboard(userInfo)) redirectDev();

  const instances = userTeamNames
    .flatMap(name => teamInstances.get(name))
    .filter((entry): entry is string => entry !== undefined);

  const hasNoAssignedInstances = instances.length === 0;
  if (hasNoAssignedInstances && dontNeedOldDashboard(userInfo))
    redirectDev(ticketCode);

  const missingInstanceMigrations = instances.filter(
    instance => !migratedInstances.has(instance)
  );

  const confirmedInstanceMigrations = instances.filter(instance =>
    migratedInstances.has(instance)
  );

  const migratedInstancesString = confirmedInstanceMigrations
    .reduce((acc, v) => acc + ', ' + v, '')
    .slice(2);

  const isFullyMigratedTeam =
    confirmedInstanceMigrations.length > 0 &&
    missingInstanceMigrations.length === 0;

  const isPartiallyMigratedTeam =
    confirmedInstanceMigrations.length > 0 &&
    missingInstanceMigrations.length > 0;

  const showWarning = !dismissedWarning && isPartiallyMigratedTeam;

  if (isFullyMigratedTeam && dontNeedOldDashboard(userInfo))
    redirectDev(ticketCode);

  return (
    <>
      {children}
      {showWarning && (
        <Modal maxWidth="lg" open={true}>
          <x.div w="600px" padding="40px">
            <x.h1
              fontSize="34px"
              lineHeight="38px"
              fontWeight="bold"
              paddingBottom="20px"
            >
              GitStart has a new developer dashboard!
            </x.h1>
            <x.p fontSize="20px" lineHeight="24px" paddingBottom="20px">
              We’re current performing maintenance to move all of your teams
              data but it looks like the data migration is not yet complete.
            </x.p>
            <x.p fontSize="20px" lineHeight="24px">
              Please go to the new dashboard if you're working on any of the
              following:
              <x.strong display="block" marginTop="8px" marginBottom="20px">
                {' '}
                {migratedInstancesString}
              </x.strong>
            </x.p>
            <x.p fontSize="16px">{'(Otherwise, stay here for now!)'}</x.p>
            <x.div
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="28px"
            >
              <Button onClick={() => redirectDev(ticketCode)}>
                Go to the new dashboard
              </Button>
              <x.a
                marginLeft="12px"
                fontSize="16px"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => setDismissedWarning(true)}
              >
                Stay here
              </x.a>
            </x.div>
          </x.div>
        </Modal>
      )}
    </>
  );
};
