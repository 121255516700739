import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CalendarCell } from '..';
import moment from 'moment';
// import { State } from '@engine/app/src/components_relay/TeamCalendarRow';
import { State } from '..';

const FetchColor = (currentDate: string) => {
  let color: State;
  color = moment().isSame(currentDate, 'day') ? State.Today : State.Nothing;

  return color;
};

type Props = {
  initdate: string;
};

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

export type CalendarDateProps = Props;

export const CalendarDate: React.FC<CalendarDateProps> = props => {
  const { initdate } = props;
  const classes = useStyles();

  let date = moment(initdate)
    .subtract(1, 'days')
    .format('YYYY-MM-DD')
    .toString();

  const headers = [];
  for (let i = 0; i < 17; i++) {
    date = moment(date).add(1, 'days').format('YYYY-MM-DD').toString();
    headers.push(
      <CalendarCell
        date={date}
        isHeader={true}
        info={{
          color: FetchColor(date),
          required: 0,
          logged: 0,
          leaveReason: '',
        }}
        key={date}
      />
    );
  }

  return <div className={classes.row}>{headers}</div>;
};

export default CalendarDate;
