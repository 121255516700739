import jwt from 'jsonwebtoken';
import type { Token } from './types';

function verifyToken(decoded: Token | null | undefined) {
  try {
    if (
      !decoded ||
      !decoded.exp ||
      !decoded['https://hasura.io/jwt/claims'] ||
      Date.now() >= decoded.exp * 1000
    ) {
      console.log('bad token: ', JSON.stringify(decoded, null, 4));
      return null;
    }

    return {
      id: +decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'],
      clientId: decoded['https://hasura.io/jwt/claims']['x-hasura-client-id'],
      agencyId: decoded['https://hasura.io/jwt/claims']['x-hasura-agency-id'],
      developerId:
        decoded['https://hasura.io/jwt/claims']['x-hasura-developer-id'],
      roles: decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
      defaultRole:
        decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'],
    };
  } catch (ex) {
    console.error('error parsing JSON token:', ex);
    return null;
  }
}

export const getUserFromToken = (token?: string) => {
  if (!token) {
    console.log('got back empty token ... returning early');
    return null;
  }
  const decoded = jwt.decode(token, { json: true }) as null | Token;
  return verifyToken(decoded);
};
