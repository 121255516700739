import React from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';

type OtherProps = {
  errorMessage?: string | false;
};

export type TextFieldProps = MuiTextFieldProps &
  Required<Pick<MuiTextFieldProps, 'label'>> &
  OtherProps;

export const TextField: React.FC<TextFieldProps> = props => {
  const {
    variant = 'outlined',
    error = !!props.errorMessage,
    helperText = props.errorMessage,
    ...other
  } = props;
  return (
    <MuiTextField
      variant={variant}
      error={error}
      helperText={helperText}
      {...other}
    />
  );
};

export const TextFieldWithoutLabel = (
  props: MuiTextFieldProps & OtherProps
) => {
  const {
    variant = 'outlined',
    error = !!props.errorMessage,
    helperText = props.errorMessage,
    ...rest
  } = props;
  return (
    <MuiTextField
      variant={variant}
      error={error}
      helperText={helperText}
      {...rest}
    />
  );
};

export default TextField;
