import React, { Suspense, useEffect } from 'react';

import { graphql, PreloadedQuery, useQueryLoader } from 'react-relay';

import { InitialQueryProviderQuery } from 'lib/graphql/relay/__generated__/InitialQueryProviderQuery.graphql';
import { useAuth } from 'components/auth';

export const InitialQuery = graphql`
  query InitialQueryProviderQuery($userId: Int!) {
    ...useFeatureFlagFragment
    ...useReleaseFlagFragment
  }
`;

interface InitialQueryProviderProps {}

export const InitialQueryContext = React.createContext<{
  initialQueryRef: PreloadedQuery<InitialQueryProviderQuery> | null;
}>({ initialQueryRef: null });

/**
 * This provider is used to load data before mounting the rest of the components tree.
 * You should use it for things like: settings, feature flags, etc. that influence shape of the UI.
 * Note: this blocks rendering while data is fetched, so ensure that you don't put long running queries here.
 */
export const InitialQueryProvider: React.FC<InitialQueryProviderProps> = ({
  children,
}) => {
  const auth = useAuth(true);
  const userId = auth.user?.id;

  const [
    initialQueryRef,
    loadInitalQuery,
    disposeQuery,
  ] = useQueryLoader<InitialQueryProviderQuery>(InitialQuery);

  useEffect(() => {
    if (userId) {
      loadInitalQuery({ userId });
    }
    return () => {
      disposeQuery();
    };
  }, [loadInitalQuery, disposeQuery, userId]);

  if (initialQueryRef === null || initialQueryRef === undefined) {
    return <></>;
  }

  return (
    <InitialQueryContext.Provider value={{ initialQueryRef: initialQueryRef }}>
      <Suspense fallback={<>Loading...</>}>{children}</Suspense>
    </InitialQueryContext.Provider>
  );
};
