/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum StripePaymentMethodTypes {
  credit_card = 'credit_card',
  us_bank_account = 'us_bank_account',
}

export enum client_blocker_status_enum {
  pending = 'pending',
  resolved = 'resolved',
  review = 'review',
}

/**
 * unique or primary key constraints on table "client_blockers"
 */
export enum client_blockers_constraint {
  PK_6e24a89cf5ae85282634062b5dc = 'PK_6e24a89cf5ae85282634062b5dc',
}

/**
 * update columns of table "client_blockers"
 */
export enum client_blockers_update_column {
  clientId = 'clientId',
  projectId = 'projectId',
  resolvedById = 'resolvedById',
  status = 'status',
  unblockDetails = 'unblockDetails',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "client_invoices"
 */
export enum client_invoices_constraint {
  PK_e710ecb019ac03ca2d3f3905024 = 'PK_e710ecb019ac03ca2d3f3905024',
  UQ_CLIENT_INVOICE_DUE_AT = 'UQ_CLIENT_INVOICE_DUE_AT',
}

/**
 * update columns of table "client_invoices"
 */
export enum client_invoices_update_column {
  associatedTransactionId = 'associatedTransactionId',
}

export enum client_transaction_status_enum {
  cancelled = 'cancelled',
  failed = 'failed',
  in_progress = 'in_progress',
  pending = 'pending',
  successful = 'successful',
}

export enum client_transaction_types_enum {
  bonus = 'bonus',
  eu_bank = 'eu_bank',
  gb_bank = 'gb_bank',
  hk_bank = 'hk_bank',
  paypal = 'paypal',
  stripe = 'stripe',
  transferwise = 'transferwise',
  us_bank = 'us_bank',
}

/**
 * unique or primary key constraints on table "client_transactions"
 */
export enum client_transactions_constraint {
  PK_affbc9a9a22aa18f40b1bf7a9b3 = 'PK_affbc9a9a22aa18f40b1bf7a9b3',
}

/**
 * update columns of table "client_transactions"
 */
export enum client_transactions_update_column {
  channelTransactionId = 'channelTransactionId',
  channelType = 'channelType',
  clientId = 'clientId',
  id = 'id',
  invoiceCode = 'invoiceCode',
  status = 'status',
  transactedAt = 'transactedAt',
  transactionError = 'transactionError',
}

export enum client_user_roles_enum {
  admin = 'admin',
  contractor = 'contractor',
  internal = 'internal',
  user = 'user',
}

export enum credit_pricing_plan_payment_types_enum {
  on_approval = 'on_approval',
  post_limit = 'post_limit',
  post_monthly = 'post_monthly',
  upfront = 'upfront',
}

export enum description_types_enum {
  html = 'html',
  jira = 'jira',
  markdown = 'markdown',
}

/**
 * unique or primary key constraints on table "git_organizations"
 */
export enum git_organizations_constraint {
  PK_33fd98d09e05427408c9f864571 = 'PK_33fd98d09e05427408c9f864571',
  REL_f03c717070bd1dc5d2d2e79e4d = 'REL_f03c717070bd1dc5d2d2e79e4d',
}

/**
 * update columns of table "git_organizations"
 */
export enum git_organizations_update_column {
  clientId = 'clientId',
}

export enum git_sources_enum {
  github = 'github',
  gitlab = 'gitlab',
}

export enum legal_entities_enum {
  HK = 'HK',
  USA = 'USA',
}

/**
 * column ordering options
 */
export enum order_by {
  asc = 'asc',
  asc_nulls_first = 'asc_nulls_first',
  asc_nulls_last = 'asc_nulls_last',
  desc = 'desc',
  desc_nulls_first = 'desc_nulls_first',
  desc_nulls_last = 'desc_nulls_last',
}

/**
 * unique or primary key constraints on table "task_credit_approval"
 */
export enum task_credit_approval_constraint {
  PK_0195b11109fc1e7ce530608fed7 = 'PK_0195b11109fc1e7ce530608fed7',
}

/**
 * update columns of table "task_credit_approval"
 */
export enum task_credit_approval_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  reason = 'reason',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  status = 'status',
  taskId = 'taskId',
  ticketCreditApprovalId = 'ticketCreditApprovalId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

export enum task_credit_status_enum {
  approved = 'approved',
  auto_approved = 'auto_approved',
  pending = 'pending',
  rejected = 'rejected',
}

export enum task_reviews_status_enum {
  assigned = 'assigned',
  available = 'available',
  cancelled = 'cancelled',
  failed = 'failed',
  in_progress = 'in_progress',
  passed = 'passed',
}

export enum tasks_status_enum {
  available = 'available',
  backlog = 'backlog',
  cancelled = 'cancelled',
  client_review = 'client_review',
  errored = 'errored',
  finished = 'finished',
  in_progress = 'in_progress',
  internal_review = 'internal_review',
  needs_changes = 'needs_changes',
  parked = 'parked',
}

export enum tasks_type_enum {
  code = 'code',
  learning = 'learning',
  qa = 'qa',
  review = 'review',
  spec = 'spec',
}

/**
 * unique or primary key constraints on table "ticket_credit_approvals"
 */
export enum ticket_credit_approvals_constraint {
  PK_13cfee4da26174bfd556e347f75 = 'PK_13cfee4da26174bfd556e347f75',
}

/**
 * update columns of table "ticket_credit_approvals"
 */
export enum ticket_credit_approvals_update_column {
  createdAt = 'createdAt',
  deletedAt = 'deletedAt',
  id = 'id',
  lowerBudget = 'lowerBudget',
  reason = 'reason',
  requestedByUserId = 'requestedByUserId',
  reviewedByUserId = 'reviewedByUserId',
  status = 'status',
  ticketId = 'ticketId',
  updatedAt = 'updatedAt',
  upperBudget = 'upperBudget',
}

export enum ticket_credit_status_enum {
  approved = 'approved',
  auto_approved = 'auto_approved',
  pending = 'pending',
  rejected = 'rejected',
}

export enum ticket_status_enum {
  available = 'available',
  backlog = 'backlog',
  blocked = 'blocked',
  cancelled = 'cancelled',
  errored = 'errored',
  finished = 'finished',
  in_progress = 'in_progress',
  partially_blocked = 'partially_blocked',
  partially_under_review = 'partially_under_review',
  under_review = 'under_review',
}

/**
 * unique or primary key constraints on table "tickets"
 */
export enum tickets_constraint {
  PK_343bc942ae261cf7a1377f48fd0 = 'PK_343bc942ae261cf7a1377f48fd0',
  UQ_013dd8562f32b40a5d76287c819 = 'UQ_013dd8562f32b40a5d76287c819',
  UQ_1907c2dc72947998e1a95e5b67d = 'UQ_1907c2dc72947998e1a95e5b67d',
  UQ_3a17387be419fb7f4087680f76d = 'UQ_3a17387be419fb7f4087680f76d',
  UQ_90f8619ba4d35663b3ad5701d88 = 'UQ_90f8619ba4d35663b3ad5701d88',
  UQ_c6e20a830c0f8b571abd331b775 = 'UQ_c6e20a830c0f8b571abd331b775',
  UQ_c7f04cb0c8f1a8a8649e8f628d5 = 'UQ_c7f04cb0c8f1a8a8649e8f628d5',
}

export enum tickets_types_enum {
  bug = 'bug',
  consult = 'consult',
  feature = 'feature',
  maintain = 'maintain',
}

/**
 * placeholder for update columns of table "tickets" (current role has no relevant permissions)
 */
export enum tickets_update_column {
  _PLACEHOLDER = '_PLACEHOLDER',
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'.
 */
export interface _text_comparison_exp {
  _eq?: hasura__text | null;
  _gt?: hasura__text | null;
  _gte?: hasura__text | null;
  _in?: hasura__text[] | null;
  _is_null?: boolean | null;
  _lt?: hasura__text | null;
  _lte?: hasura__text | null;
  _neq?: hasura__text | null;
  _nin?: hasura__text[] | null;
}

/**
 * order by aggregate values of table "admin"
 */
export interface admin_aggregate_order_by {
  avg?: admin_avg_order_by | null;
  count?: order_by | null;
  max?: admin_max_order_by | null;
  min?: admin_min_order_by | null;
  stddev?: admin_stddev_order_by | null;
  stddev_pop?: admin_stddev_pop_order_by | null;
  stddev_samp?: admin_stddev_samp_order_by | null;
  sum?: admin_sum_order_by | null;
  var_pop?: admin_var_pop_order_by | null;
  var_samp?: admin_var_samp_order_by | null;
  variance?: admin_variance_order_by | null;
}

/**
 * order by avg() on columns of table "admin"
 */
export interface admin_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "admin". All fields are combined with a logical 'AND'.
 */
export interface admin_bool_exp {
  _and?: admin_bool_exp[] | null;
  _not?: admin_bool_exp | null;
  _or?: admin_bool_exp[] | null;
  id?: String_comparison_exp | null;
  user?: users_bool_exp | null;
  userById?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  userLogin?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "admin"
 */
export interface admin_max_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by min() on columns of table "admin"
 */
export interface admin_min_order_by {
  id?: order_by | null;
  userId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * Ordering options when selecting data from "admin".
 */
export interface admin_order_by {
  id?: order_by | null;
  user?: users_order_by | null;
  userById?: users_order_by | null;
  userId?: order_by | null;
  userLogin?: order_by | null;
}

/**
 * order by stddev() on columns of table "admin"
 */
export interface admin_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "admin"
 */
export interface admin_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "admin"
 */
export interface admin_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "admin"
 */
export interface admin_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "admin"
 */
export interface admin_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "admin"
 */
export interface admin_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "admin"
 */
export interface admin_variance_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: hasura_bigint | null;
  _gt?: hasura_bigint | null;
  _gte?: hasura_bigint | null;
  _in?: hasura_bigint[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_bigint | null;
  _lte?: hasura_bigint | null;
  _neq?: hasura_bigint | null;
  _nin?: hasura_bigint[] | null;
}

/**
 * order by aggregate values of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_aggregate_order_by {
  avg?: client_billing_aggregated_structure_avg_order_by | null;
  count?: order_by | null;
  max?: client_billing_aggregated_structure_max_order_by | null;
  min?: client_billing_aggregated_structure_min_order_by | null;
  stddev?: client_billing_aggregated_structure_stddev_order_by | null;
  stddev_pop?: client_billing_aggregated_structure_stddev_pop_order_by | null;
  stddev_samp?: client_billing_aggregated_structure_stddev_samp_order_by | null;
  sum?: client_billing_aggregated_structure_sum_order_by | null;
  var_pop?: client_billing_aggregated_structure_var_pop_order_by | null;
  var_samp?: client_billing_aggregated_structure_var_samp_order_by | null;
  variance?: client_billing_aggregated_structure_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_avg_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_billing_aggregated_structure". All fields are combined with a logical 'AND'.
 */
export interface client_billing_aggregated_structure_bool_exp {
  _and?: client_billing_aggregated_structure_bool_exp[] | null;
  _not?: client_billing_aggregated_structure_bool_exp | null;
  _or?: client_billing_aggregated_structure_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientInvoiceId?: Int_comparison_exp | null;
  clientTransactionId?: Int_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  occuredAt?: date_comparison_exp | null;
  status?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_max_order_by {
  clientId?: order_by | null;
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  occuredAt?: order_by | null;
  status?: order_by | null;
  type?: order_by | null;
}

/**
 * order by min() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_min_order_by {
  clientId?: order_by | null;
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  occuredAt?: order_by | null;
  status?: order_by | null;
  type?: order_by | null;
}

/**
 * Ordering options when selecting data from "client_billing_aggregated_structure".
 */
export interface client_billing_aggregated_structure_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  client_invoice?: client_invoices_order_by | null;
  client_transaction?: client_transactions_order_by | null;
  costInCredits?: order_by | null;
  occuredAt?: order_by | null;
  status?: order_by | null;
  type?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_stddev_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_stddev_pop_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_stddev_samp_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by sum() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_sum_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_var_pop_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_var_samp_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * order by variance() on columns of table "client_billing_aggregated_structure"
 */
export interface client_billing_aggregated_structure_variance_order_by {
  clientInvoiceId?: order_by | null;
  clientTransactionId?: order_by | null;
  costInCredits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_blocker_status". All fields are combined with a logical 'AND'.
 */
export interface client_blocker_status_bool_exp {
  _and?: client_blocker_status_bool_exp[] | null;
  _not?: client_blocker_status_bool_exp | null;
  _or?: client_blocker_status_bool_exp[] | null;
  client_blockers?: client_blockers_bool_exp | null;
  description?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_blocker_status_enum". All fields are combined with logical 'AND'.
 */
export interface client_blocker_status_enum_comparison_exp {
  _eq?: client_blocker_status_enum | null;
  _in?: client_blocker_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_blocker_status_enum | null;
  _nin?: client_blocker_status_enum[] | null;
}

/**
 * order by aggregate values of table "client_blockers"
 */
export interface client_blockers_aggregate_order_by {
  avg?: client_blockers_avg_order_by | null;
  count?: order_by | null;
  max?: client_blockers_max_order_by | null;
  min?: client_blockers_min_order_by | null;
  stddev?: client_blockers_stddev_order_by | null;
  stddev_pop?: client_blockers_stddev_pop_order_by | null;
  stddev_samp?: client_blockers_stddev_samp_order_by | null;
  sum?: client_blockers_sum_order_by | null;
  var_pop?: client_blockers_var_pop_order_by | null;
  var_samp?: client_blockers_var_samp_order_by | null;
  variance?: client_blockers_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_blockers"
 */
export interface client_blockers_avg_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_blockers". All fields are combined with a logical 'AND'.
 */
export interface client_blockers_bool_exp {
  _and?: client_blockers_bool_exp[] | null;
  _not?: client_blockers_bool_exp | null;
  _or?: client_blockers_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_blocker_status?: client_blocker_status_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  details?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  projectId?: Int_comparison_exp | null;
  resolvedById?: Int_comparison_exp | null;
  status?: client_blocker_status_enum_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task_client_blockers?: task_client_blockers_bool_exp | null;
  ticket_client_blockers?: ticket_client_blockers_bool_exp | null;
  unblockDetails?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_blockers"
 */
export interface client_blockers_insert_input {
  clientId?: string | null;
  createdAt?: hasura_timestamptz | null;
  details?: string | null;
  id?: number | null;
  projectId?: number | null;
  status?: client_blocker_status_enum | null;
  summary?: string | null;
  ticket_client_blockers?: ticket_client_blockers_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * order by max() on columns of table "client_blockers"
 */
export interface client_blockers_max_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  details?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
  summary?: order_by | null;
  unblockDetails?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_blockers"
 */
export interface client_blockers_min_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  details?: order_by | null;
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
  summary?: order_by | null;
  unblockDetails?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "client_blockers"
 */
export interface client_blockers_obj_rel_insert_input {
  data: client_blockers_insert_input;
  on_conflict?: client_blockers_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_blockers"
 */
export interface client_blockers_on_conflict {
  constraint: client_blockers_constraint;
  update_columns: client_blockers_update_column[];
  where?: client_blockers_bool_exp | null;
}

/**
 * order by stddev() on columns of table "client_blockers"
 */
export interface client_blockers_stddev_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_blockers"
 */
export interface client_blockers_stddev_pop_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_blockers"
 */
export interface client_blockers_stddev_samp_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by sum() on columns of table "client_blockers"
 */
export interface client_blockers_sum_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_blockers"
 */
export interface client_blockers_var_pop_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_blockers"
 */
export interface client_blockers_var_samp_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by variance() on columns of table "client_blockers"
 */
export interface client_blockers_variance_order_by {
  id?: order_by | null;
  projectId?: order_by | null;
  resolvedById?: order_by | null;
}

/**
 * order by aggregate values of table "client_daily_standups"
 */
export interface client_daily_standups_aggregate_order_by {
  avg?: client_daily_standups_avg_order_by | null;
  count?: order_by | null;
  max?: client_daily_standups_max_order_by | null;
  min?: client_daily_standups_min_order_by | null;
  stddev?: client_daily_standups_stddev_order_by | null;
  stddev_pop?: client_daily_standups_stddev_pop_order_by | null;
  stddev_samp?: client_daily_standups_stddev_samp_order_by | null;
  sum?: client_daily_standups_sum_order_by | null;
  var_pop?: client_daily_standups_var_pop_order_by | null;
  var_samp?: client_daily_standups_var_samp_order_by | null;
  variance?: client_daily_standups_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_avg_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface client_daily_standups_bool_exp {
  _and?: client_daily_standups_bool_exp[] | null;
  _not?: client_daily_standups_bool_exp | null;
  _or?: client_daily_standups_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * order by max() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_max_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_min_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_stddev_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_stddev_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_stddev_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_sum_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_var_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_var_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_daily_standups"
 */
export interface client_daily_standups_variance_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_aggregate_order_by {
  avg?: client_invoice_time_user_totals_avg_order_by | null;
  count?: order_by | null;
  max?: client_invoice_time_user_totals_max_order_by | null;
  min?: client_invoice_time_user_totals_min_order_by | null;
  stddev?: client_invoice_time_user_totals_stddev_order_by | null;
  stddev_pop?: client_invoice_time_user_totals_stddev_pop_order_by | null;
  stddev_samp?: client_invoice_time_user_totals_stddev_samp_order_by | null;
  sum?: client_invoice_time_user_totals_sum_order_by | null;
  var_pop?: client_invoice_time_user_totals_var_pop_order_by | null;
  var_samp?: client_invoice_time_user_totals_var_samp_order_by | null;
  variance?: client_invoice_time_user_totals_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_avg_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_invoice_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface client_invoice_time_user_totals_bool_exp {
  _and?: client_invoice_time_user_totals_bool_exp[] | null;
  _not?: client_invoice_time_user_totals_bool_exp | null;
  _or?: client_invoice_time_user_totals_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  invoiceDueAt?: timestamptz_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_max_order_by {
  clientId?: order_by | null;
  invoiceDueAt?: order_by | null;
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_min_order_by {
  clientId?: order_by | null;
  invoiceDueAt?: order_by | null;
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_stddev_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_stddev_pop_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_stddev_samp_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_sum_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_var_pop_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_var_samp_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_invoice_time_user_totals"
 */
export interface client_invoice_time_user_totals_variance_order_by {
  invoiceId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "client_invoices"
 */
export interface client_invoices_aggregate_order_by {
  avg?: client_invoices_avg_order_by | null;
  count?: order_by | null;
  max?: client_invoices_max_order_by | null;
  min?: client_invoices_min_order_by | null;
  stddev?: client_invoices_stddev_order_by | null;
  stddev_pop?: client_invoices_stddev_pop_order_by | null;
  stddev_samp?: client_invoices_stddev_samp_order_by | null;
  sum?: client_invoices_sum_order_by | null;
  var_pop?: client_invoices_var_pop_order_by | null;
  var_samp?: client_invoices_var_samp_order_by | null;
  variance?: client_invoices_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "client_invoices"
 */
export interface client_invoices_arr_rel_insert_input {
  data: client_invoices_insert_input[];
  on_conflict?: client_invoices_on_conflict | null;
}

/**
 * order by avg() on columns of table "client_invoices"
 */
export interface client_invoices_avg_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_invoices". All fields are combined with a logical 'AND'.
 */
export interface client_invoices_bool_exp {
  _and?: client_invoices_bool_exp[] | null;
  _not?: client_invoices_bool_exp | null;
  _or?: client_invoices_bool_exp[] | null;
  associatedTransactionId?: Int_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_invoice_balance?: client_invoices_with_balance_bool_exp | null;
  client_invoice_time_user_totals?: client_invoice_time_user_totals_bool_exp | null;
  client_transaction?: client_transactions_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  maxBudgetInCredits?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "client_invoices"
 */
export interface client_invoices_insert_input {
  associatedTransactionId?: number | null;
  clientId?: string | null;
  client_transaction?: client_transactions_obj_rel_insert_input | null;
  createdAt?: hasura_timestamptz | null;
  dueAt?: hasura_timestamptz | null;
  endAt?: hasura_timestamptz | null;
  id?: number | null;
  invoiceCode?: string | null;
  startAt?: hasura_timestamptz | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * order by max() on columns of table "client_invoices"
 */
export interface client_invoices_max_order_by {
  associatedTransactionId?: order_by | null;
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_invoices"
 */
export interface client_invoices_min_order_by {
  associatedTransactionId?: order_by | null;
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  startAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "client_invoices"
 */
export interface client_invoices_obj_rel_insert_input {
  data: client_invoices_insert_input;
  on_conflict?: client_invoices_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_invoices"
 */
export interface client_invoices_on_conflict {
  constraint: client_invoices_constraint;
  update_columns: client_invoices_update_column[];
  where?: client_invoices_bool_exp | null;
}

/**
 * Ordering options when selecting data from "client_invoices".
 */
export interface client_invoices_order_by {
  associatedTransactionId?: order_by | null;
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_order_by | null;
  client_invoice_balance?: client_invoices_with_balance_order_by | null;
  client_invoice_time_user_totals_aggregate?: client_invoice_time_user_totals_aggregate_order_by | null;
  client_transaction?: client_transactions_order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  credit_pricing_plan?: credit_pricing_plans_order_by | null;
  dueAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  invoiceCode?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  startAt?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_invoices"
 */
export interface client_invoices_stddev_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_invoices"
 */
export interface client_invoices_stddev_pop_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_invoices"
 */
export interface client_invoices_stddev_samp_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by sum() on columns of table "client_invoices"
 */
export interface client_invoices_sum_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_invoices"
 */
export interface client_invoices_var_pop_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_invoices"
 */
export interface client_invoices_var_samp_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by variance() on columns of table "client_invoices"
 */
export interface client_invoices_variance_order_by {
  associatedTransactionId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
}

/**
 * order by aggregate values of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_aggregate_order_by {
  avg?: client_invoices_with_balance_avg_order_by | null;
  count?: order_by | null;
  max?: client_invoices_with_balance_max_order_by | null;
  min?: client_invoices_with_balance_min_order_by | null;
  stddev?: client_invoices_with_balance_stddev_order_by | null;
  stddev_pop?: client_invoices_with_balance_stddev_pop_order_by | null;
  stddev_samp?: client_invoices_with_balance_stddev_samp_order_by | null;
  sum?: client_invoices_with_balance_sum_order_by | null;
  var_pop?: client_invoices_with_balance_var_pop_order_by | null;
  var_samp?: client_invoices_with_balance_var_samp_order_by | null;
  variance?: client_invoices_with_balance_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_avg_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_invoices_with_balance". All fields are combined with a logical 'AND'.
 */
export interface client_invoices_with_balance_bool_exp {
  _and?: client_invoices_with_balance_bool_exp[] | null;
  _not?: client_invoices_with_balance_bool_exp | null;
  _or?: client_invoices_with_balance_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  consumed_balance_in_credits?: bigint_comparison_exp | null;
  consumed_balance_in_credits_undiscounted?: bigint_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  currrent_paid_balance_in_usd?: bigint_comparison_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  maxBudgetInCredits?: Int_comparison_exp | null;
  previous_balance_in_credits?: bigint_comparison_exp | null;
  previous_balance_in_usd?: bigint_comparison_exp | null;
  previous_consumed_balance_in_credits?: bigint_comparison_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_max_order_by {
  clientId?: order_by | null;
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_min_order_by {
  clientId?: order_by | null;
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "client_invoices_with_balance".
 */
export interface client_invoices_with_balance_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_invoice?: client_invoices_order_by | null;
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_stddev_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_stddev_pop_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_stddev_samp_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by sum() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_sum_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_var_pop_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_var_samp_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by variance() on columns of table "client_invoices_with_balance"
 */
export interface client_invoices_with_balance_variance_order_by {
  consumed_balance_in_credits?: order_by | null;
  consumed_balance_in_credits_undiscounted?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  currrent_paid_balance_in_usd?: order_by | null;
  id?: order_by | null;
  maxBudgetInCredits?: order_by | null;
  previous_balance_in_credits?: order_by | null;
  previous_balance_in_usd?: order_by | null;
  previous_consumed_balance_in_credits?: order_by | null;
}

/**
 * order by aggregate values of table "client_jobs"
 */
export interface client_jobs_aggregate_order_by {
  count?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_jobs". All fields are combined with a logical 'AND'.
 */
export interface client_jobs_bool_exp {
  _and?: client_jobs_bool_exp[] | null;
  _not?: client_jobs_bool_exp | null;
  _or?: client_jobs_bool_exp[] | null;
  client?: clients_bool_exp | null;
}

/**
 * order by aggregate values of table "client_pricing_plans"
 */
export interface client_pricing_plans_aggregate_order_by {
  avg?: client_pricing_plans_avg_order_by | null;
  count?: order_by | null;
  max?: client_pricing_plans_max_order_by | null;
  min?: client_pricing_plans_min_order_by | null;
  stddev?: client_pricing_plans_stddev_order_by | null;
  stddev_pop?: client_pricing_plans_stddev_pop_order_by | null;
  stddev_samp?: client_pricing_plans_stddev_samp_order_by | null;
  sum?: client_pricing_plans_sum_order_by | null;
  var_pop?: client_pricing_plans_var_pop_order_by | null;
  var_samp?: client_pricing_plans_var_samp_order_by | null;
  variance?: client_pricing_plans_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_avg_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface client_pricing_plans_bool_exp {
  _and?: client_pricing_plans_bool_exp[] | null;
  _not?: client_pricing_plans_bool_exp | null;
  _or?: client_pricing_plans_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_max_order_by {
  clientId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_min_order_by {
  clientId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_stddev_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_stddev_pop_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_stddev_samp_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_sum_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_var_pop_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_var_samp_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_pricing_plans"
 */
export interface client_pricing_plans_variance_order_by {
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_aggregate_order_by {
  avg?: client_project_pricing_plans_avg_order_by | null;
  count?: order_by | null;
  max?: client_project_pricing_plans_max_order_by | null;
  min?: client_project_pricing_plans_min_order_by | null;
  stddev?: client_project_pricing_plans_stddev_order_by | null;
  stddev_pop?: client_project_pricing_plans_stddev_pop_order_by | null;
  stddev_samp?: client_project_pricing_plans_stddev_samp_order_by | null;
  sum?: client_project_pricing_plans_sum_order_by | null;
  var_pop?: client_project_pricing_plans_var_pop_order_by | null;
  var_samp?: client_project_pricing_plans_var_samp_order_by | null;
  variance?: client_project_pricing_plans_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_avg_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface client_project_pricing_plans_bool_exp {
  _and?: client_project_pricing_plans_bool_exp[] | null;
  _not?: client_project_pricing_plans_bool_exp | null;
  _or?: client_project_pricing_plans_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditPricingPlanId?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  endAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  startAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_max_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  deletedAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_min_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  creditPricingPlanId?: order_by | null;
  deletedAt?: order_by | null;
  endAt?: order_by | null;
  id?: order_by | null;
  startAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_stddev_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_sum_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_var_pop_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_var_samp_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_project_pricing_plans"
 */
export interface client_project_pricing_plans_variance_order_by {
  clientProjectId?: order_by | null;
  creditPricingPlanId?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_project_users"
 */
export interface client_project_users_aggregate_order_by {
  avg?: client_project_users_avg_order_by | null;
  count?: order_by | null;
  max?: client_project_users_max_order_by | null;
  min?: client_project_users_min_order_by | null;
  stddev?: client_project_users_stddev_order_by | null;
  stddev_pop?: client_project_users_stddev_pop_order_by | null;
  stddev_samp?: client_project_users_stddev_samp_order_by | null;
  sum?: client_project_users_sum_order_by | null;
  var_pop?: client_project_users_var_pop_order_by | null;
  var_samp?: client_project_users_var_samp_order_by | null;
  variance?: client_project_users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_project_users"
 */
export interface client_project_users_avg_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_project_users". All fields are combined with a logical 'AND'.
 */
export interface client_project_users_bool_exp {
  _and?: client_project_users_bool_exp[] | null;
  _not?: client_project_users_bool_exp | null;
  _or?: client_project_users_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  projects?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  users?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_project_users"
 */
export interface client_project_users_max_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by min() on columns of table "client_project_users"
 */
export interface client_project_users_min_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_project_users"
 */
export interface client_project_users_stddev_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_project_users"
 */
export interface client_project_users_stddev_pop_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_project_users"
 */
export interface client_project_users_stddev_samp_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by sum() on columns of table "client_project_users"
 */
export interface client_project_users_sum_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_project_users"
 */
export interface client_project_users_var_pop_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_project_users"
 */
export interface client_project_users_var_samp_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by variance() on columns of table "client_project_users"
 */
export interface client_project_users_variance_order_by {
  projects?: order_by | null;
  users?: order_by | null;
}

/**
 * order by aggregate values of table "client_projects"
 */
export interface client_projects_aggregate_order_by {
  avg?: client_projects_avg_order_by | null;
  count?: order_by | null;
  max?: client_projects_max_order_by | null;
  min?: client_projects_min_order_by | null;
  stddev?: client_projects_stddev_order_by | null;
  stddev_pop?: client_projects_stddev_pop_order_by | null;
  stddev_samp?: client_projects_stddev_samp_order_by | null;
  sum?: client_projects_sum_order_by | null;
  var_pop?: client_projects_var_pop_order_by | null;
  var_samp?: client_projects_var_samp_order_by | null;
  variance?: client_projects_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_projects"
 */
export interface client_projects_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_projects". All fields are combined with a logical 'AND'.
 */
export interface client_projects_bool_exp {
  _and?: client_projects_bool_exp[] | null;
  _not?: client_projects_bool_exp | null;
  _or?: client_projects_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_project_pricing_plans?: client_project_pricing_plans_bool_exp | null;
  client_project_users?: client_project_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_repo_slices?: git_repo_slices_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_projects"
 */
export interface client_projects_max_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "client_projects"
 */
export interface client_projects_min_order_by {
  clientId?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Ordering options when selecting data from "client_projects".
 */
export interface client_projects_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_blockers_aggregate?: client_blockers_aggregate_order_by | null;
  client_project_pricing_plans_aggregate?: client_project_pricing_plans_aggregate_order_by | null;
  client_project_users_aggregate?: client_project_users_aggregate_order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  git_repo_slices_aggregate?: git_repo_slices_aggregate_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  project_daily_standups_aggregate?: project_daily_standup_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_projects"
 */
export interface client_projects_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_projects"
 */
export interface client_projects_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_projects"
 */
export interface client_projects_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "client_projects"
 */
export interface client_projects_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_projects"
 */
export interface client_projects_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_projects"
 */
export interface client_projects_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "client_projects"
 */
export interface client_projects_variance_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_transaction_status". All fields are combined with a logical 'AND'.
 */
export interface client_transaction_status_bool_exp {
  _and?: client_transaction_status_bool_exp[] | null;
  _not?: client_transaction_status_bool_exp | null;
  _or?: client_transaction_status_bool_exp[] | null;
  client_transactions?: client_transactions_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_transaction_status_enum". All fields are combined with logical 'AND'.
 */
export interface client_transaction_status_enum_comparison_exp {
  _eq?: client_transaction_status_enum | null;
  _in?: client_transaction_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_transaction_status_enum | null;
  _nin?: client_transaction_status_enum[] | null;
}

/**
 * Ordering options when selecting data from "client_transaction_status".
 */
export interface client_transaction_status_order_by {
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  description?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_transaction_types". All fields are combined with a logical 'AND'.
 */
export interface client_transaction_types_bool_exp {
  _and?: client_transaction_types_bool_exp[] | null;
  _not?: client_transaction_types_bool_exp | null;
  _or?: client_transaction_types_bool_exp[] | null;
  client_transactions?: client_transactions_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "client_transaction_types_enum". All fields are combined with logical 'AND'.
 */
export interface client_transaction_types_enum_comparison_exp {
  _eq?: client_transaction_types_enum | null;
  _in?: client_transaction_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_transaction_types_enum | null;
  _nin?: client_transaction_types_enum[] | null;
}

/**
 * Ordering options when selecting data from "client_transaction_types".
 */
export interface client_transaction_types_order_by {
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  description?: order_by | null;
  id?: order_by | null;
}

/**
 * order by aggregate values of table "client_transactions"
 */
export interface client_transactions_aggregate_order_by {
  avg?: client_transactions_avg_order_by | null;
  count?: order_by | null;
  max?: client_transactions_max_order_by | null;
  min?: client_transactions_min_order_by | null;
  stddev?: client_transactions_stddev_order_by | null;
  stddev_pop?: client_transactions_stddev_pop_order_by | null;
  stddev_samp?: client_transactions_stddev_samp_order_by | null;
  sum?: client_transactions_sum_order_by | null;
  var_pop?: client_transactions_var_pop_order_by | null;
  var_samp?: client_transactions_var_samp_order_by | null;
  variance?: client_transactions_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_transactions"
 */
export interface client_transactions_avg_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_transactions". All fields are combined with a logical 'AND'.
 */
export interface client_transactions_bool_exp {
  _and?: client_transactions_bool_exp[] | null;
  _not?: client_transactions_bool_exp | null;
  _or?: client_transactions_bool_exp[] | null;
  channelTransactionId?: String_comparison_exp | null;
  channelType?: client_transaction_types_enum_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_transaction_status?: client_transaction_status_bool_exp | null;
  client_transaction_type?: client_transaction_types_bool_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  credit_pricing_plan?: credit_pricing_plans_bool_exp | null;
  dueAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  initiatedByUserId?: Int_comparison_exp | null;
  invoiceCode?: String_comparison_exp | null;
  status?: client_transaction_status_enum_comparison_exp | null;
  stripeIntentSecret?: String_comparison_exp | null;
  transactedAt?: timestamptz_comparison_exp | null;
  transactionError?: String_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "client_transactions"
 */
export interface client_transactions_insert_input {
  channelTransactionId?: string | null;
  channelType?: client_transaction_types_enum | null;
  clientId?: string | null;
  client_invoices?: client_invoices_arr_rel_insert_input | null;
  costInCredits?: number | null;
  costInUSD?: number | null;
  id?: number | null;
  invoiceCode?: string | null;
  status?: client_transaction_status_enum | null;
  stripeIntentSecret?: string | null;
  transactedAt?: hasura_timestamptz | null;
  transactionError?: string | null;
}

/**
 * order by max() on columns of table "client_transactions"
 */
export interface client_transactions_max_order_by {
  channelTransactionId?: order_by | null;
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
  invoiceCode?: order_by | null;
  stripeIntentSecret?: order_by | null;
  transactedAt?: order_by | null;
  transactionError?: order_by | null;
}

/**
 * order by min() on columns of table "client_transactions"
 */
export interface client_transactions_min_order_by {
  channelTransactionId?: order_by | null;
  clientId?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
  invoiceCode?: order_by | null;
  stripeIntentSecret?: order_by | null;
  transactedAt?: order_by | null;
  transactionError?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "client_transactions"
 */
export interface client_transactions_obj_rel_insert_input {
  data: client_transactions_insert_input;
  on_conflict?: client_transactions_on_conflict | null;
}

/**
 * on_conflict condition type for table "client_transactions"
 */
export interface client_transactions_on_conflict {
  constraint: client_transactions_constraint;
  update_columns: client_transactions_update_column[];
  where?: client_transactions_bool_exp | null;
}

/**
 * Ordering options when selecting data from "client_transactions".
 */
export interface client_transactions_order_by {
  channelTransactionId?: order_by | null;
  channelType?: order_by | null;
  client?: clients_order_by | null;
  clientId?: order_by | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_order_by | null;
  client_invoices_aggregate?: client_invoices_aggregate_order_by | null;
  client_transaction_status?: client_transaction_status_order_by | null;
  client_transaction_type?: client_transaction_types_order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  credit_pricing_plan?: credit_pricing_plans_order_by | null;
  dueAt?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
  invoiceCode?: order_by | null;
  status?: order_by | null;
  stripeIntentSecret?: order_by | null;
  transactedAt?: order_by | null;
  transactionError?: order_by | null;
  user?: users_order_by | null;
}

/**
 * input type for updating data in table "client_transactions"
 */
export interface client_transactions_set_input {
  channelTransactionId?: string | null;
  channelType?: client_transaction_types_enum | null;
  clientId?: string | null;
  id?: number | null;
  invoiceCode?: string | null;
  status?: client_transaction_status_enum | null;
  transactedAt?: hasura_timestamptz | null;
  transactionError?: string | null;
}

/**
 * order by stddev() on columns of table "client_transactions"
 */
export interface client_transactions_stddev_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_transactions"
 */
export interface client_transactions_stddev_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_transactions"
 */
export interface client_transactions_stddev_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_transactions"
 */
export interface client_transactions_sum_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_transactions"
 */
export interface client_transactions_var_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_transactions"
 */
export interface client_transactions_var_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_transactions"
 */
export interface client_transactions_variance_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
  initiatedByUserId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "client_user_roles_enum". All fields are combined with logical 'AND'.
 */
export interface client_user_roles_enum_comparison_exp {
  _eq?: client_user_roles_enum | null;
  _in?: client_user_roles_enum[] | null;
  _is_null?: boolean | null;
  _neq?: client_user_roles_enum | null;
  _nin?: client_user_roles_enum[] | null;
}

/**
 * order by aggregate values of table "client_users"
 */
export interface client_users_aggregate_order_by {
  avg?: client_users_avg_order_by | null;
  count?: order_by | null;
  max?: client_users_max_order_by | null;
  min?: client_users_min_order_by | null;
  stddev?: client_users_stddev_order_by | null;
  stddev_pop?: client_users_stddev_pop_order_by | null;
  stddev_samp?: client_users_stddev_samp_order_by | null;
  sum?: client_users_sum_order_by | null;
  var_pop?: client_users_var_pop_order_by | null;
  var_samp?: client_users_var_samp_order_by | null;
  variance?: client_users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "client_users"
 */
export interface client_users_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "client_users". All fields are combined with a logical 'AND'.
 */
export interface client_users_bool_exp {
  _and?: client_users_bool_exp[] | null;
  _not?: client_users_bool_exp | null;
  _or?: client_users_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  role?: client_user_roles_enum_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "client_users"
 */
export interface client_users_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "client_users"
 */
export interface client_users_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "client_users"
 */
export interface client_users_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "client_users"
 */
export interface client_users_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "client_users"
 */
export interface client_users_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "client_users"
 */
export interface client_users_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "client_users"
 */
export interface client_users_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "client_users"
 */
export interface client_users_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "client_users"
 */
export interface client_users_variance_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'.
 */
export interface clients_bool_exp {
  _and?: clients_bool_exp[] | null;
  _not?: clients_bool_exp | null;
  _or?: clients_bool_exp[] | null;
  addressAddress?: jsonb_comparison_exp | null;
  agencyId?: String_comparison_exp | null;
  assignedEntityName?: legal_entities_enum_comparison_exp | null;
  billingEmail?: String_comparison_exp | null;
  churnedAt?: timestamptz_comparison_exp | null;
  client_billing_aggregated_structure?: client_billing_aggregated_structure_bool_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_daily_standups?: client_daily_standups_bool_exp | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_invoices_with_balance?: client_invoices_with_balance_bool_exp | null;
  client_jobs?: client_jobs_bool_exp | null;
  client_pricing_plans?: client_pricing_plans_bool_exp | null;
  client_projects?: client_projects_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  client_users?: client_users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditBatchSize?: Int_comparison_exp | null;
  defaultCreditBudget?: Int_comparison_exp | null;
  git_organizations?: git_organizations_bool_exp | null;
  git_repos?: git_repos_bool_exp | null;
  id?: String_comparison_exp | null;
  intercomCompanyId?: String_comparison_exp | null;
  legalName?: String_comparison_exp | null;
  linear_team?: linear_teams_bool_exp | null;
  linear_teams?: linear_teams_bool_exp | null;
  logoUrl?: String_comparison_exp | null;
  meetings?: meetings_bool_exp | null;
  name?: String_comparison_exp | null;
  onBoardedAt?: timestamptz_comparison_exp | null;
  primaryPhoneNumber?: String_comparison_exp | null;
  stripeCustomerId?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  userDomains?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "clients".
 */
export interface clients_order_by {
  addressAddress?: order_by | null;
  agencyId?: order_by | null;
  assignedEntityName?: order_by | null;
  billingEmail?: order_by | null;
  churnedAt?: order_by | null;
  client_billing_aggregated_structure_aggregate?: client_billing_aggregated_structure_aggregate_order_by | null;
  client_blockers_aggregate?: client_blockers_aggregate_order_by | null;
  client_daily_standups_aggregate?: client_daily_standups_aggregate_order_by | null;
  client_invoices_aggregate?: client_invoices_aggregate_order_by | null;
  client_invoices_with_balance_aggregate?: client_invoices_with_balance_aggregate_order_by | null;
  client_jobs_aggregate?: client_jobs_aggregate_order_by | null;
  client_pricing_plans_aggregate?: client_pricing_plans_aggregate_order_by | null;
  client_projects_aggregate?: client_projects_aggregate_order_by | null;
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  client_users_aggregate?: client_users_aggregate_order_by | null;
  createdAt?: order_by | null;
  creditBatchSize?: order_by | null;
  defaultCreditBudget?: order_by | null;
  git_organizations_aggregate?: git_organizations_aggregate_order_by | null;
  git_repos_aggregate?: git_repos_aggregate_order_by | null;
  id?: order_by | null;
  intercomCompanyId?: order_by | null;
  legalName?: order_by | null;
  linear_team?: linear_teams_order_by | null;
  linear_teams_aggregate?: linear_teams_aggregate_order_by | null;
  logoUrl?: order_by | null;
  meetings_aggregate?: meetings_aggregate_order_by | null;
  name?: order_by | null;
  onBoardedAt?: order_by | null;
  primaryPhoneNumber?: order_by | null;
  stripeCustomerId?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  updatedAt?: order_by | null;
  userDomains?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "credit_pricing_plan_payment_types_enum". All fields are combined with logical 'AND'.
 */
export interface credit_pricing_plan_payment_types_enum_comparison_exp {
  _eq?: credit_pricing_plan_payment_types_enum | null;
  _in?: credit_pricing_plan_payment_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: credit_pricing_plan_payment_types_enum | null;
  _nin?: credit_pricing_plan_payment_types_enum[] | null;
}

/**
 * Boolean expression to filter rows from the table "credit_pricing_plans". All fields are combined with a logical 'AND'.
 */
export interface credit_pricing_plans_bool_exp {
  _and?: credit_pricing_plans_bool_exp[] | null;
  _not?: credit_pricing_plans_bool_exp | null;
  _or?: credit_pricing_plans_bool_exp[] | null;
  client_invoices?: client_invoices_bool_exp | null;
  client_pricing_plans?: client_pricing_plans_bool_exp | null;
  client_project_pricing_plans?: client_project_pricing_plans_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  costPerCredit?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  creditsPerMidLevelHour?: Int_comparison_exp | null;
  defaultSLA?: Int_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  discontinuedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  isCustomPlan?: Boolean_comparison_exp | null;
  maxFreeSupportHoursPerWeek?: Int_comparison_exp | null;
  minCreditUsagePerWeek?: Int_comparison_exp | null;
  minimumCreditPurchase?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  paymentTypeId?: credit_pricing_plan_payment_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "credit_pricing_plans".
 */
export interface credit_pricing_plans_order_by {
  client_invoices_aggregate?: client_invoices_aggregate_order_by | null;
  client_pricing_plans_aggregate?: client_pricing_plans_aggregate_order_by | null;
  client_project_pricing_plans_aggregate?: client_project_pricing_plans_aggregate_order_by | null;
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  costPerCredit?: order_by | null;
  createdAt?: order_by | null;
  creditsPerMidLevelHour?: order_by | null;
  defaultSLA?: order_by | null;
  deletedAt?: order_by | null;
  discontinuedAt?: order_by | null;
  id?: order_by | null;
  isCustomPlan?: order_by | null;
  maxFreeSupportHoursPerWeek?: order_by | null;
  minCreditUsagePerWeek?: order_by | null;
  minimumCreditPurchase?: order_by | null;
  name?: order_by | null;
  paymentTypeId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: hasura_date | null;
  _gt?: hasura_date | null;
  _gte?: hasura_date | null;
  _in?: hasura_date[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_date | null;
  _lte?: hasura_date | null;
  _neq?: hasura_date | null;
  _nin?: hasura_date[] | null;
}

/**
 * Boolean expression to filter rows from the table "description_types". All fields are combined with a logical 'AND'.
 */
export interface description_types_bool_exp {
  _and?: description_types_bool_exp[] | null;
  _not?: description_types_bool_exp | null;
  _or?: description_types_bool_exp[] | null;
  description?: String_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "description_types_enum". All fields are combined with logical 'AND'.
 */
export interface description_types_enum_comparison_exp {
  _eq?: description_types_enum | null;
  _in?: description_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: description_types_enum | null;
  _nin?: description_types_enum[] | null;
}

/**
 * Ordering options when selecting data from "description_types".
 */
export interface description_types_order_by {
  description?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  type?: order_by | null;
}

/**
 * order by aggregate values of table "developers"
 */
export interface developers_aggregate_order_by {
  count?: order_by | null;
  max?: developers_max_order_by | null;
  min?: developers_min_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "developers". All fields are combined with a logical 'AND'.
 */
export interface developers_bool_exp {
  _and?: developers_bool_exp[] | null;
  _not?: developers_bool_exp | null;
  _or?: developers_bool_exp[] | null;
  id?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  taskReviewsByDeveloperid?: task_reviews_bool_exp | null;
  task_reviewers?: task_reviewers_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  tasks?: tasks_bool_exp | null;
  tasksByDeveloperid?: tasks_bool_exp | null;
  tasksByManagerid?: tasks_bool_exp | null;
  tickets?: tickets_bool_exp | null;
  user?: users_bool_exp | null;
}

/**
 * order by max() on columns of table "developers"
 */
export interface developers_max_order_by {
  id?: order_by | null;
  login?: order_by | null;
}

/**
 * order by min() on columns of table "developers"
 */
export interface developers_min_order_by {
  id?: order_by | null;
  login?: order_by | null;
}

/**
 * Ordering options when selecting data from "developers".
 */
export interface developers_order_by {
  id?: order_by | null;
  login?: order_by | null;
  taskReviewsByDeveloperid_aggregate?: task_reviews_aggregate_order_by | null;
  task_reviewers_aggregate?: task_reviewers_aggregate_order_by | null;
  task_reviews_aggregate?: task_reviews_aggregate_order_by | null;
  tasksByDeveloperid_aggregate?: tasks_aggregate_order_by | null;
  tasksByManagerid_aggregate?: tasks_aggregate_order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
  user?: users_order_by | null;
}

/**
 * input type for inserting array relation for remote table "discussion_sources"
 */
export interface discussion_sources_arr_rel_insert_input {
  data: discussion_sources_insert_input[];
}

/**
 * input type for inserting data into table "discussion_sources"
 */
export interface discussion_sources_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  isInternal?: boolean | null;
  resolvedAt?: hasura_timestamptz | null;
  syncSid?: string | null;
  syncTableName?: string | null;
  taskCode?: string | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketByTicketcode?: tickets_obj_rel_insert_input | null;
  ticketCode?: string | null;
  tickets?: tickets_arr_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "discussion_sources"
 */
export interface discussion_sources_obj_rel_insert_input {
  data: discussion_sources_insert_input;
}

/**
 * order by aggregate values of table "git_branch_refs"
 */
export interface git_branch_refs_aggregate_order_by {
  avg?: git_branch_refs_avg_order_by | null;
  count?: order_by | null;
  max?: git_branch_refs_max_order_by | null;
  min?: git_branch_refs_min_order_by | null;
  stddev?: git_branch_refs_stddev_order_by | null;
  stddev_pop?: git_branch_refs_stddev_pop_order_by | null;
  stddev_samp?: git_branch_refs_stddev_samp_order_by | null;
  sum?: git_branch_refs_sum_order_by | null;
  var_pop?: git_branch_refs_var_pop_order_by | null;
  var_samp?: git_branch_refs_var_samp_order_by | null;
  variance?: git_branch_refs_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_avg_order_by {
  repoId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_branch_refs". All fields are combined with a logical 'AND'.
 */
export interface git_branch_refs_bool_exp {
  _and?: git_branch_refs_bool_exp[] | null;
  _not?: git_branch_refs_bool_exp | null;
  _or?: git_branch_refs_bool_exp[] | null;
  branchName?: String_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  git_repo_slices_from_this_branch?: git_repo_slices_bool_exp | null;
  git_repo_slices_to_this_branch?: git_repo_slices_bool_exp | null;
  repoId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_max_order_by {
  branchName?: order_by | null;
  repoId?: order_by | null;
}

/**
 * order by min() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_min_order_by {
  branchName?: order_by | null;
  repoId?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_branch_refs".
 */
export interface git_branch_refs_order_by {
  branchName?: order_by | null;
  git_repo?: git_repos_order_by | null;
  git_repo_slices_from_this_branch_aggregate?: git_repo_slices_aggregate_order_by | null;
  git_repo_slices_to_this_branch_aggregate?: git_repo_slices_aggregate_order_by | null;
  repoId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_stddev_order_by {
  repoId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_stddev_pop_order_by {
  repoId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_stddev_samp_order_by {
  repoId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_sum_order_by {
  repoId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_var_pop_order_by {
  repoId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_var_samp_order_by {
  repoId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_branch_refs"
 */
export interface git_branch_refs_variance_order_by {
  repoId?: order_by | null;
}

/**
 * order by aggregate values of table "git_organizations"
 */
export interface git_organizations_aggregate_order_by {
  avg?: git_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: git_organizations_max_order_by | null;
  min?: git_organizations_min_order_by | null;
  stddev?: git_organizations_stddev_order_by | null;
  stddev_pop?: git_organizations_stddev_pop_order_by | null;
  stddev_samp?: git_organizations_stddev_samp_order_by | null;
  sum?: git_organizations_sum_order_by | null;
  var_pop?: git_organizations_var_pop_order_by | null;
  var_samp?: git_organizations_var_samp_order_by | null;
  variance?: git_organizations_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_organizations"
 */
export interface git_organizations_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_organizations". All fields are combined with a logical 'AND'.
 */
export interface git_organizations_bool_exp {
  _and?: git_organizations_bool_exp[] | null;
  _not?: git_organizations_bool_exp | null;
  _or?: git_organizations_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  git_repos?: git_repos_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  github_organizations?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  syncSid?: String_comparison_exp | null;
  syncTableName?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "git_organizations"
 */
export interface git_organizations_insert_input {
  clientId?: string | null;
  id?: number | null;
  syncSid?: string | null;
  syncTableName?: string | null;
}

/**
 * order by max() on columns of table "git_organizations"
 */
export interface git_organizations_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
}

/**
 * order by min() on columns of table "git_organizations"
 */
export interface git_organizations_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
}

/**
 * on_conflict condition type for table "git_organizations"
 */
export interface git_organizations_on_conflict {
  constraint: git_organizations_constraint;
  update_columns: git_organizations_update_column[];
  where?: git_organizations_bool_exp | null;
}

/**
 * Ordering options when selecting data from "git_organizations".
 */
export interface git_organizations_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  git_repos_aggregate?: git_repos_aggregate_order_by | null;
  github_organization?: github_organizations_order_by | null;
  github_organizations_aggregate?: github_organizations_aggregate_order_by | null;
  id?: order_by | null;
  syncSid?: order_by | null;
  syncTableName?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_organizations"
 */
export interface git_organizations_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_organizations"
 */
export interface git_organizations_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_organizations"
 */
export interface git_organizations_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "git_organizations"
 */
export interface git_organizations_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_organizations"
 */
export interface git_organizations_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_organizations"
 */
export interface git_organizations_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "git_organizations"
 */
export interface git_organizations_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "git_repo_slices"
 */
export interface git_repo_slices_aggregate_order_by {
  avg?: git_repo_slices_avg_order_by | null;
  count?: order_by | null;
  max?: git_repo_slices_max_order_by | null;
  min?: git_repo_slices_min_order_by | null;
  stddev?: git_repo_slices_stddev_order_by | null;
  stddev_pop?: git_repo_slices_stddev_pop_order_by | null;
  stddev_samp?: git_repo_slices_stddev_samp_order_by | null;
  sum?: git_repo_slices_sum_order_by | null;
  var_pop?: git_repo_slices_var_pop_order_by | null;
  var_samp?: git_repo_slices_var_samp_order_by | null;
  variance?: git_repo_slices_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_avg_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_repo_slices". All fields are combined with a logical 'AND'.
 */
export interface git_repo_slices_bool_exp {
  _and?: git_repo_slices_bool_exp[] | null;
  _not?: git_repo_slices_bool_exp | null;
  _or?: git_repo_slices_bool_exp[] | null;
  client_project?: client_projects_bool_exp | null;
  git_branch_ref_sliced_from?: git_branch_refs_bool_exp | null;
  git_branch_ref_sliced_to?: git_branch_refs_bool_exp | null;
  git_repo_sliced_from?: git_repos_bool_exp | null;
  git_repo_sliced_to?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  ignoredPaths?: _text_comparison_exp | null;
  slicedFolders?: _text_comparison_exp | null;
  slicedFromBranchName?: String_comparison_exp | null;
  slicedFromRepoId?: Int_comparison_exp | null;
  slicedToBranchName?: String_comparison_exp | null;
  slicedToRepoId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_max_order_by {
  id?: order_by | null;
  slicedFromBranchName?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToBranchName?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by min() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_min_order_by {
  id?: order_by | null;
  slicedFromBranchName?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToBranchName?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_repo_slices".
 */
export interface git_repo_slices_order_by {
  client_project?: client_projects_order_by | null;
  git_branch_ref_sliced_from?: git_branch_refs_order_by | null;
  git_branch_ref_sliced_to?: git_branch_refs_order_by | null;
  git_repo_sliced_from?: git_repos_order_by | null;
  git_repo_sliced_to?: git_repos_order_by | null;
  id?: order_by | null;
  ignoredPaths?: order_by | null;
  slicedFolders?: order_by | null;
  slicedFromBranchName?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToBranchName?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_stddev_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_stddev_pop_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_stddev_samp_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_sum_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_var_pop_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_var_samp_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_repo_slices"
 */
export interface git_repo_slices_variance_order_by {
  id?: order_by | null;
  slicedFromRepoId?: order_by | null;
  slicedToRepoId?: order_by | null;
}

/**
 * order by aggregate values of table "git_repos"
 */
export interface git_repos_aggregate_order_by {
  avg?: git_repos_avg_order_by | null;
  count?: order_by | null;
  max?: git_repos_max_order_by | null;
  min?: git_repos_min_order_by | null;
  stddev?: git_repos_stddev_order_by | null;
  stddev_pop?: git_repos_stddev_pop_order_by | null;
  stddev_samp?: git_repos_stddev_samp_order_by | null;
  sum?: git_repos_sum_order_by | null;
  var_pop?: git_repos_var_pop_order_by | null;
  var_samp?: git_repos_var_samp_order_by | null;
  variance?: git_repos_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_repos"
 */
export interface git_repos_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_repos". All fields are combined with a logical 'AND'.
 */
export interface git_repos_bool_exp {
  _and?: git_repos_bool_exp[] | null;
  _not?: git_repos_bool_exp | null;
  _or?: git_repos_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  git_branch_refs?: git_branch_refs_bool_exp | null;
  git_organization?: git_organizations_bool_exp | null;
  git_repo_sliced_to?: git_repo_slices_bool_exp | null;
  git_repos_sliced_from?: git_repo_slices_bool_exp | null;
  github_repo_by_sid?: github_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  owner?: String_comparison_exp | null;
  source?: git_sources_enum_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  ticket_repos?: ticket_repos_bool_exp | null;
  tickets?: tickets_bool_exp | null;
}

/**
 * order by max() on columns of table "git_repos"
 */
export interface git_repos_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  owner?: order_by | null;
}

/**
 * order by min() on columns of table "git_repos"
 */
export interface git_repos_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  owner?: order_by | null;
}

/**
 * Ordering options when selecting data from "git_repos".
 */
export interface git_repos_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  git_branch_refs_aggregate?: git_branch_refs_aggregate_order_by | null;
  git_organization?: git_organizations_order_by | null;
  git_repo_sliced_to?: git_repo_slices_order_by | null;
  git_repos_sliced_from_aggregate?: git_repo_slices_aggregate_order_by | null;
  github_repo_by_sid?: github_repos_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  owner?: order_by | null;
  source?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticket_repos_aggregate?: ticket_repos_aggregate_order_by | null;
  tickets_aggregate?: tickets_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "git_repos"
 */
export interface git_repos_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_repos"
 */
export interface git_repos_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_repos"
 */
export interface git_repos_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "git_repos"
 */
export interface git_repos_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_repos"
 */
export interface git_repos_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_repos"
 */
export interface git_repos_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "git_repos"
 */
export interface git_repos_variance_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "git_sources_enum". All fields are combined with logical 'AND'.
 */
export interface git_sources_enum_comparison_exp {
  _eq?: git_sources_enum | null;
  _in?: git_sources_enum[] | null;
  _is_null?: boolean | null;
  _neq?: git_sources_enum | null;
  _nin?: git_sources_enum[] | null;
}

/**
 * order by aggregate values of table "git_users"
 */
export interface git_users_aggregate_order_by {
  avg?: git_users_avg_order_by | null;
  count?: order_by | null;
  max?: git_users_max_order_by | null;
  min?: git_users_min_order_by | null;
  stddev?: git_users_stddev_order_by | null;
  stddev_pop?: git_users_stddev_pop_order_by | null;
  stddev_samp?: git_users_stddev_samp_order_by | null;
  sum?: git_users_sum_order_by | null;
  var_pop?: git_users_var_pop_order_by | null;
  var_samp?: git_users_var_samp_order_by | null;
  variance?: git_users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "git_users"
 */
export interface git_users_avg_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "git_users". All fields are combined with a logical 'AND'.
 */
export interface git_users_bool_exp {
  _and?: git_users_bool_exp[] | null;
  _not?: git_users_bool_exp | null;
  _or?: git_users_bool_exp[] | null;
  githubAccountByGithubaccountlogin?: github_accounts_bool_exp | null;
  githubAccountLogin?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  id?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * order by max() on columns of table "git_users"
 */
export interface git_users_max_order_by {
  githubAccountLogin?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "git_users"
 */
export interface git_users_min_order_by {
  githubAccountLogin?: order_by | null;
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "git_users"
 */
export interface git_users_stddev_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "git_users"
 */
export interface git_users_stddev_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "git_users"
 */
export interface git_users_stddev_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "git_users"
 */
export interface git_users_sum_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "git_users"
 */
export interface git_users_var_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "git_users"
 */
export interface git_users_var_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "git_users"
 */
export interface git_users_variance_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "github_accounts"
 */
export interface github_accounts_aggregate_order_by {
  avg?: github_accounts_avg_order_by | null;
  count?: order_by | null;
  max?: github_accounts_max_order_by | null;
  min?: github_accounts_min_order_by | null;
  stddev?: github_accounts_stddev_order_by | null;
  stddev_pop?: github_accounts_stddev_pop_order_by | null;
  stddev_samp?: github_accounts_stddev_samp_order_by | null;
  sum?: github_accounts_sum_order_by | null;
  var_pop?: github_accounts_var_pop_order_by | null;
  var_samp?: github_accounts_var_samp_order_by | null;
  variance?: github_accounts_variance_order_by | null;
}

/**
 * order by avg() on columns of table "github_accounts"
 */
export interface github_accounts_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_accounts". All fields are combined with a logical 'AND'.
 */
export interface github_accounts_bool_exp {
  _and?: github_accounts_bool_exp[] | null;
  _not?: github_accounts_bool_exp | null;
  _or?: github_accounts_bool_exp[] | null;
  gitUserByLogin?: git_users_bool_exp | null;
  git_user?: git_users_bool_exp | null;
  git_users?: git_users_bool_exp | null;
  github_app_installations?: github_app_installations_bool_exp | null;
  github_repo_users?: github_repo_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
  user_email?: user_emails_bool_exp | null;
}

/**
 * order by max() on columns of table "github_accounts"
 */
export interface github_accounts_max_order_by {
  id?: order_by | null;
  login?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by min() on columns of table "github_accounts"
 */
export interface github_accounts_min_order_by {
  id?: order_by | null;
  login?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by stddev() on columns of table "github_accounts"
 */
export interface github_accounts_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "github_accounts"
 */
export interface github_accounts_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "github_accounts"
 */
export interface github_accounts_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "github_accounts"
 */
export interface github_accounts_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "github_accounts"
 */
export interface github_accounts_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "github_accounts"
 */
export interface github_accounts_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "github_accounts"
 */
export interface github_accounts_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "github_app_installations"
 */
export interface github_app_installations_aggregate_order_by {
  avg?: github_app_installations_avg_order_by | null;
  count?: order_by | null;
  max?: github_app_installations_max_order_by | null;
  min?: github_app_installations_min_order_by | null;
  stddev?: github_app_installations_stddev_order_by | null;
  stddev_pop?: github_app_installations_stddev_pop_order_by | null;
  stddev_samp?: github_app_installations_stddev_samp_order_by | null;
  sum?: github_app_installations_sum_order_by | null;
  var_pop?: github_app_installations_var_pop_order_by | null;
  var_samp?: github_app_installations_var_samp_order_by | null;
  variance?: github_app_installations_variance_order_by | null;
}

/**
 * order by avg() on columns of table "github_app_installations"
 */
export interface github_app_installations_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_app_installations". All fields are combined with a logical 'AND'.
 */
export interface github_app_installations_bool_exp {
  _and?: github_app_installations_bool_exp[] | null;
  _not?: github_app_installations_bool_exp | null;
  _or?: github_app_installations_bool_exp[] | null;
  accountSid?: String_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_organization?: github_organizations_bool_exp | null;
  id?: Int_comparison_exp | null;
  organizationSid?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "github_app_installations"
 */
export interface github_app_installations_max_order_by {
  accountSid?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  organizationSid?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by min() on columns of table "github_app_installations"
 */
export interface github_app_installations_min_order_by {
  accountSid?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  organizationSid?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by stddev() on columns of table "github_app_installations"
 */
export interface github_app_installations_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "github_app_installations"
 */
export interface github_app_installations_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "github_app_installations"
 */
export interface github_app_installations_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "github_app_installations"
 */
export interface github_app_installations_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "github_app_installations"
 */
export interface github_app_installations_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "github_app_installations"
 */
export interface github_app_installations_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "github_app_installations"
 */
export interface github_app_installations_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "github_organizations"
 */
export interface github_organizations_aggregate_order_by {
  avg?: github_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: github_organizations_max_order_by | null;
  min?: github_organizations_min_order_by | null;
  stddev?: github_organizations_stddev_order_by | null;
  stddev_pop?: github_organizations_stddev_pop_order_by | null;
  stddev_samp?: github_organizations_stddev_samp_order_by | null;
  sum?: github_organizations_sum_order_by | null;
  var_pop?: github_organizations_var_pop_order_by | null;
  var_samp?: github_organizations_var_samp_order_by | null;
  variance?: github_organizations_variance_order_by | null;
}

/**
 * order by avg() on columns of table "github_organizations"
 */
export interface github_organizations_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_organizations". All fields are combined with a logical 'AND'.
 */
export interface github_organizations_bool_exp {
  _and?: github_organizations_bool_exp[] | null;
  _not?: github_organizations_bool_exp | null;
  _or?: github_organizations_bool_exp[] | null;
  git_organization?: git_organizations_bool_exp | null;
  github_app_installations?: github_app_installations_bool_exp | null;
  id?: Int_comparison_exp | null;
  login?: String_comparison_exp | null;
  sid?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "github_organizations"
 */
export interface github_organizations_max_order_by {
  id?: order_by | null;
  login?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by min() on columns of table "github_organizations"
 */
export interface github_organizations_min_order_by {
  id?: order_by | null;
  login?: order_by | null;
  sid?: order_by | null;
}

/**
 * Ordering options when selecting data from "github_organizations".
 */
export interface github_organizations_order_by {
  git_organization?: git_organizations_order_by | null;
  github_app_installations_aggregate?: github_app_installations_aggregate_order_by | null;
  id?: order_by | null;
  login?: order_by | null;
  sid?: order_by | null;
}

/**
 * order by stddev() on columns of table "github_organizations"
 */
export interface github_organizations_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "github_organizations"
 */
export interface github_organizations_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "github_organizations"
 */
export interface github_organizations_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "github_organizations"
 */
export interface github_organizations_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "github_organizations"
 */
export interface github_organizations_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "github_organizations"
 */
export interface github_organizations_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "github_organizations"
 */
export interface github_organizations_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "github_repo_users"
 */
export interface github_repo_users_aggregate_order_by {
  count?: order_by | null;
  max?: github_repo_users_max_order_by | null;
  min?: github_repo_users_min_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_repo_users". All fields are combined with a logical 'AND'.
 */
export interface github_repo_users_bool_exp {
  _and?: github_repo_users_bool_exp[] | null;
  _not?: github_repo_users_bool_exp | null;
  _or?: github_repo_users_bool_exp[] | null;
  accountLogin?: String_comparison_exp | null;
  github_account?: github_accounts_bool_exp | null;
  github_repo?: github_repos_bool_exp | null;
  permission?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "github_repo_users"
 */
export interface github_repo_users_max_order_by {
  accountLogin?: order_by | null;
  permission?: order_by | null;
}

/**
 * order by min() on columns of table "github_repo_users"
 */
export interface github_repo_users_min_order_by {
  accountLogin?: order_by | null;
  permission?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "github_repos". All fields are combined with a logical 'AND'.
 */
export interface github_repos_bool_exp {
  _and?: github_repos_bool_exp[] | null;
  _not?: github_repos_bool_exp | null;
  _or?: github_repos_bool_exp[] | null;
  deletedAt?: timestamptz_comparison_exp | null;
  git_repo_by_sid?: git_repos_bool_exp | null;
  github_repo_users?: github_repo_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  ownerLogin?: String_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "github_repos".
 */
export interface github_repos_order_by {
  deletedAt?: order_by | null;
  git_repo_by_sid?: git_repos_order_by | null;
  github_repo_users_aggregate?: github_repo_users_aggregate_order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  ownerLogin?: order_by | null;
}

export interface insertGitSourceInput {
  clientId: string;
  ignorePaths?: string[] | null;
  sliceFolders?: string[] | null;
  sliceFromBranchName: string;
  sliceFromRepoId: number;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _contained_in?: hasura_jsonb | null;
  _contains?: hasura_jsonb | null;
  _eq?: hasura_jsonb | null;
  _gt?: hasura_jsonb | null;
  _gte?: hasura_jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: hasura_jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_jsonb | null;
  _lte?: hasura_jsonb | null;
  _neq?: hasura_jsonb | null;
  _nin?: hasura_jsonb[] | null;
}

/**
 * Boolean expression to compare columns of type "legal_entities_enum". All fields are combined with logical 'AND'.
 */
export interface legal_entities_enum_comparison_exp {
  _eq?: legal_entities_enum | null;
  _in?: legal_entities_enum[] | null;
  _is_null?: boolean | null;
  _neq?: legal_entities_enum | null;
  _nin?: legal_entities_enum[] | null;
}

/**
 * order by aggregate values of table "linear_teams"
 */
export interface linear_teams_aggregate_order_by {
  avg?: linear_teams_avg_order_by | null;
  count?: order_by | null;
  max?: linear_teams_max_order_by | null;
  min?: linear_teams_min_order_by | null;
  stddev?: linear_teams_stddev_order_by | null;
  stddev_pop?: linear_teams_stddev_pop_order_by | null;
  stddev_samp?: linear_teams_stddev_samp_order_by | null;
  sum?: linear_teams_sum_order_by | null;
  var_pop?: linear_teams_var_pop_order_by | null;
  var_samp?: linear_teams_var_samp_order_by | null;
  variance?: linear_teams_variance_order_by | null;
}

/**
 * order by avg() on columns of table "linear_teams"
 */
export interface linear_teams_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "linear_teams". All fields are combined with a logical 'AND'.
 */
export interface linear_teams_bool_exp {
  _and?: linear_teams_bool_exp[] | null;
  _not?: linear_teams_bool_exp | null;
  _or?: linear_teams_bool_exp[] | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
}

/**
 * order by max() on columns of table "linear_teams"
 */
export interface linear_teams_max_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
}

/**
 * order by min() on columns of table "linear_teams"
 */
export interface linear_teams_min_order_by {
  clientId?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
}

/**
 * Ordering options when selecting data from "linear_teams".
 */
export interface linear_teams_order_by {
  client?: clients_order_by | null;
  clientId?: order_by | null;
  id?: order_by | null;
  key?: order_by | null;
  name?: order_by | null;
}

/**
 * order by stddev() on columns of table "linear_teams"
 */
export interface linear_teams_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "linear_teams"
 */
export interface linear_teams_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "linear_teams"
 */
export interface linear_teams_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "linear_teams"
 */
export interface linear_teams_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "linear_teams"
 */
export interface linear_teams_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "linear_teams"
 */
export interface linear_teams_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "linear_teams"
 */
export interface linear_teams_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "meetings"
 */
export interface meetings_aggregate_order_by {
  avg?: meetings_avg_order_by | null;
  count?: order_by | null;
  max?: meetings_max_order_by | null;
  min?: meetings_min_order_by | null;
  stddev?: meetings_stddev_order_by | null;
  stddev_pop?: meetings_stddev_pop_order_by | null;
  stddev_samp?: meetings_stddev_samp_order_by | null;
  sum?: meetings_sum_order_by | null;
  var_pop?: meetings_var_pop_order_by | null;
  var_samp?: meetings_var_samp_order_by | null;
  variance?: meetings_variance_order_by | null;
}

/**
 * order by avg() on columns of table "meetings"
 */
export interface meetings_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "meetings". All fields are combined with a logical 'AND'.
 */
export interface meetings_bool_exp {
  _and?: meetings_bool_exp[] | null;
  _not?: meetings_bool_exp | null;
  _or?: meetings_bool_exp[] | null;
  associatedClientId?: String_comparison_exp | null;
  calendlyMeetingId?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  scheduledAt?: timestamptz_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  zoomMeetingSid?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "meetings"
 */
export interface meetings_insert_input {
  associatedClientId?: string | null;
  calendlyMeetingId?: string | null;
  createdAt?: hasura_timestamptz | null;
  id?: number | null;
  scheduledAt?: hasura_timestamptz | null;
  updatedAt?: hasura_timestamptz | null;
  zoomMeetingSid?: string | null;
}

/**
 * order by max() on columns of table "meetings"
 */
export interface meetings_max_order_by {
  associatedClientId?: order_by | null;
  calendlyMeetingId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  scheduledAt?: order_by | null;
  updatedAt?: order_by | null;
  zoomMeetingSid?: order_by | null;
}

/**
 * order by min() on columns of table "meetings"
 */
export interface meetings_min_order_by {
  associatedClientId?: order_by | null;
  calendlyMeetingId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  scheduledAt?: order_by | null;
  updatedAt?: order_by | null;
  zoomMeetingSid?: order_by | null;
}

/**
 * order by stddev() on columns of table "meetings"
 */
export interface meetings_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "meetings"
 */
export interface meetings_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "meetings"
 */
export interface meetings_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "meetings"
 */
export interface meetings_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "meetings"
 */
export interface meetings_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "meetings"
 */
export interface meetings_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "meetings"
 */
export interface meetings_variance_order_by {
  id?: order_by | null;
}

/**
 * order by aggregate values of table "project_daily_standup"
 */
export interface project_daily_standup_aggregate_order_by {
  avg?: project_daily_standup_avg_order_by | null;
  count?: order_by | null;
  max?: project_daily_standup_max_order_by | null;
  min?: project_daily_standup_min_order_by | null;
  stddev?: project_daily_standup_stddev_order_by | null;
  stddev_pop?: project_daily_standup_stddev_pop_order_by | null;
  stddev_samp?: project_daily_standup_stddev_samp_order_by | null;
  sum?: project_daily_standup_sum_order_by | null;
  var_pop?: project_daily_standup_var_pop_order_by | null;
  var_samp?: project_daily_standup_var_samp_order_by | null;
  variance?: project_daily_standup_variance_order_by | null;
}

/**
 * order by avg() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_avg_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "project_daily_standup". All fields are combined with a logical 'AND'.
 */
export interface project_daily_standup_bool_exp {
  _and?: project_daily_standup_bool_exp[] | null;
  _not?: project_daily_standup_bool_exp | null;
  _or?: project_daily_standup_bool_exp[] | null;
  clientProjectId?: Int_comparison_exp | null;
  client_daily_standup?: client_daily_standups_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  messageForClient?: String_comparison_exp | null;
  postedToClient?: Boolean_comparison_exp | null;
  submittedAt?: timestamptz_comparison_exp | null;
  summarizedInClientDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  summary?: String_comparison_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * order by max() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_max_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  messageForClient?: order_by | null;
  submittedAt?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_min_order_by {
  clientProjectId?: order_by | null;
  createdAt?: order_by | null;
  id?: order_by | null;
  messageForClient?: order_by | null;
  submittedAt?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_stddev_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_sum_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_var_pop_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_var_samp_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "project_daily_standup"
 */
export interface project_daily_standup_variance_order_by {
  clientProjectId?: order_by | null;
  id?: order_by | null;
  summarizedInClientDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_client_blockers". All fields are combined with a logical 'AND'.
 */
export interface task_client_blockers_bool_exp {
  _and?: task_client_blockers_bool_exp[] | null;
  _not?: task_client_blockers_bool_exp | null;
  _or?: task_client_blockers_bool_exp[] | null;
  client_blocker?: client_blockers_bool_exp | null;
  task?: tasks_bool_exp | null;
}

/**
 * order by aggregate values of table "task_credit_approval"
 */
export interface task_credit_approval_aggregate_order_by {
  avg?: task_credit_approval_avg_order_by | null;
  count?: order_by | null;
  max?: task_credit_approval_max_order_by | null;
  min?: task_credit_approval_min_order_by | null;
  stddev?: task_credit_approval_stddev_order_by | null;
  stddev_pop?: task_credit_approval_stddev_pop_order_by | null;
  stddev_samp?: task_credit_approval_stddev_samp_order_by | null;
  sum?: task_credit_approval_sum_order_by | null;
  var_pop?: task_credit_approval_var_pop_order_by | null;
  var_samp?: task_credit_approval_var_samp_order_by | null;
  variance?: task_credit_approval_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "task_credit_approval"
 */
export interface task_credit_approval_arr_rel_insert_input {
  data: task_credit_approval_insert_input[];
  on_conflict?: task_credit_approval_on_conflict | null;
}

/**
 * order by avg() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_avg_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_credit_approval". All fields are combined with a logical 'AND'.
 */
export interface task_credit_approval_bool_exp {
  _and?: task_credit_approval_bool_exp[] | null;
  _not?: task_credit_approval_bool_exp | null;
  _or?: task_credit_approval_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUser?: users_bool_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUser?: users_bool_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  status?: task_credit_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticketCreditApprovalId?: Int_comparison_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "task_credit_approval"
 */
export interface task_credit_approval_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  status?: task_credit_status_enum | null;
  taskId?: number | null;
  ticketCreditApprovalId?: number | null;
  ticket_credit_approval?: ticket_credit_approvals_obj_rel_insert_input | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * order by max() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * on_conflict condition type for table "task_credit_approval"
 */
export interface task_credit_approval_on_conflict {
  constraint: task_credit_approval_constraint;
  update_columns: task_credit_approval_update_column[];
  where?: task_credit_approval_bool_exp | null;
}

/**
 * order by stddev() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_stddev_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_stddev_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_stddev_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_sum_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_var_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_var_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "task_credit_approval"
 */
export interface task_credit_approval_variance_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  taskId?: order_by | null;
  ticketCreditApprovalId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "task_credit_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_credit_status_enum_comparison_exp {
  _eq?: task_credit_status_enum | null;
  _in?: task_credit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_credit_status_enum | null;
  _nin?: task_credit_status_enum[] | null;
}

/**
 * order by aggregate values of table "task_daily_standups"
 */
export interface task_daily_standups_aggregate_order_by {
  avg?: task_daily_standups_avg_order_by | null;
  count?: order_by | null;
  max?: task_daily_standups_max_order_by | null;
  min?: task_daily_standups_min_order_by | null;
  stddev?: task_daily_standups_stddev_order_by | null;
  stddev_pop?: task_daily_standups_stddev_pop_order_by | null;
  stddev_samp?: task_daily_standups_stddev_samp_order_by | null;
  sum?: task_daily_standups_sum_order_by | null;
  var_pop?: task_daily_standups_var_pop_order_by | null;
  var_samp?: task_daily_standups_var_samp_order_by | null;
  variance?: task_daily_standups_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_avg_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface task_daily_standups_bool_exp {
  _and?: task_daily_standups_bool_exp[] | null;
  _not?: task_daily_standups_bool_exp | null;
  _or?: task_daily_standups_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  dev_task_daily_standups?: task_daily_standups_bool_exp | null;
  id?: Int_comparison_exp | null;
  submittedAt?: String_comparison_exp | null;
  summarizedInTicketDailyStandupId?: Int_comparison_exp | null;
  summarizedInUserDailyStandupId?: Int_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  ticket_daily_standup?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedETA?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * order by max() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_max_order_by {
  createdAt?: order_by | null;
  id?: order_by | null;
  submittedAt?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  summary?: order_by | null;
  taskId?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_min_order_by {
  createdAt?: order_by | null;
  id?: order_by | null;
  submittedAt?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  summary?: order_by | null;
  taskId?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_stddev_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_stddev_pop_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_stddev_samp_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_sum_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_var_pop_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_var_samp_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_daily_standups"
 */
export interface task_daily_standups_variance_order_by {
  id?: order_by | null;
  summarizedInTicketDailyStandupId?: order_by | null;
  summarizedInUserDailyStandupId?: order_by | null;
  taskId?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "task_reviewers"
 */
export interface task_reviewers_aggregate_order_by {
  avg?: task_reviewers_avg_order_by | null;
  count?: order_by | null;
  max?: task_reviewers_max_order_by | null;
  min?: task_reviewers_min_order_by | null;
  stddev?: task_reviewers_stddev_order_by | null;
  stddev_pop?: task_reviewers_stddev_pop_order_by | null;
  stddev_samp?: task_reviewers_stddev_samp_order_by | null;
  sum?: task_reviewers_sum_order_by | null;
  var_pop?: task_reviewers_var_pop_order_by | null;
  var_samp?: task_reviewers_var_samp_order_by | null;
  variance?: task_reviewers_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_reviewers"
 */
export interface task_reviewers_avg_order_by {
  tasksId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviewers". All fields are combined with a logical 'AND'.
 */
export interface task_reviewers_bool_exp {
  _and?: task_reviewers_bool_exp[] | null;
  _not?: task_reviewers_bool_exp | null;
  _or?: task_reviewers_bool_exp[] | null;
  developer?: developers_bool_exp | null;
  developersId?: String_comparison_exp | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "task_reviewers"
 */
export interface task_reviewers_max_order_by {
  developersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by min() on columns of table "task_reviewers"
 */
export interface task_reviewers_min_order_by {
  developersId?: order_by | null;
  tasksId?: order_by | null;
}

/**
 * order by stddev() on columns of table "task_reviewers"
 */
export interface task_reviewers_stddev_order_by {
  tasksId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_reviewers"
 */
export interface task_reviewers_stddev_pop_order_by {
  tasksId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_reviewers"
 */
export interface task_reviewers_stddev_samp_order_by {
  tasksId?: order_by | null;
}

/**
 * order by sum() on columns of table "task_reviewers"
 */
export interface task_reviewers_sum_order_by {
  tasksId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_reviewers"
 */
export interface task_reviewers_var_pop_order_by {
  tasksId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_reviewers"
 */
export interface task_reviewers_var_samp_order_by {
  tasksId?: order_by | null;
}

/**
 * order by variance() on columns of table "task_reviewers"
 */
export interface task_reviewers_variance_order_by {
  tasksId?: order_by | null;
}

/**
 * order by aggregate values of table "task_reviews"
 */
export interface task_reviews_aggregate_order_by {
  avg?: task_reviews_avg_order_by | null;
  count?: order_by | null;
  max?: task_reviews_max_order_by | null;
  min?: task_reviews_min_order_by | null;
  stddev?: task_reviews_stddev_order_by | null;
  stddev_pop?: task_reviews_stddev_pop_order_by | null;
  stddev_samp?: task_reviews_stddev_samp_order_by | null;
  sum?: task_reviews_sum_order_by | null;
  var_pop?: task_reviews_var_pop_order_by | null;
  var_samp?: task_reviews_var_samp_order_by | null;
  variance?: task_reviews_variance_order_by | null;
}

/**
 * order by avg() on columns of table "task_reviews"
 */
export interface task_reviews_avg_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviews". All fields are combined with a logical 'AND'.
 */
export interface task_reviews_bool_exp {
  _and?: task_reviews_bool_exp[] | null;
  _not?: task_reviews_bool_exp | null;
  _or?: task_reviews_bool_exp[] | null;
  approvesTaskId?: Int_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  developer?: developers_bool_exp | null;
  developerByDeveloperid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  managerId?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: task_reviews_status_enum_comparison_exp | null;
  task?: tasks_bool_exp | null;
  task_reviews_status?: task_reviews_status_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * order by max() on columns of table "task_reviews"
 */
export interface task_reviews_max_order_by {
  approvesTaskId?: order_by | null;
  completedAt?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  developerId?: order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "task_reviews"
 */
export interface task_reviews_min_order_by {
  approvesTaskId?: order_by | null;
  completedAt?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  developerId?: order_by | null;
  id?: order_by | null;
  managerId?: order_by | null;
  startedAt?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_reviews_status". All fields are combined with a logical 'AND'.
 */
export interface task_reviews_status_bool_exp {
  _and?: task_reviews_status_bool_exp[] | null;
  _not?: task_reviews_status_bool_exp | null;
  _or?: task_reviews_status_bool_exp[] | null;
  description?: String_comparison_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "task_reviews_status_enum". All fields are combined with logical 'AND'.
 */
export interface task_reviews_status_enum_comparison_exp {
  _eq?: task_reviews_status_enum | null;
  _in?: task_reviews_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: task_reviews_status_enum | null;
  _nin?: task_reviews_status_enum[] | null;
}

/**
 * order by stddev() on columns of table "task_reviews"
 */
export interface task_reviews_stddev_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "task_reviews"
 */
export interface task_reviews_stddev_pop_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "task_reviews"
 */
export interface task_reviews_stddev_samp_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "task_reviews"
 */
export interface task_reviews_sum_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "task_reviews"
 */
export interface task_reviews_var_pop_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "task_reviews"
 */
export interface task_reviews_var_samp_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "task_reviews"
 */
export interface task_reviews_variance_order_by {
  approvesTaskId?: order_by | null;
  costInUSD?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "task_technologies". All fields are combined with a logical 'AND'.
 */
export interface task_technologies_bool_exp {
  _and?: task_technologies_bool_exp[] | null;
  _not?: task_technologies_bool_exp | null;
  _or?: task_technologies_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  tasksId?: Int_comparison_exp | null;
  technologiesId?: Int_comparison_exp | null;
  technology?: technologies_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "task_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface task_time_user_totals_bool_exp {
  _and?: task_time_user_totals_bool_exp[] | null;
  _not?: task_time_user_totals_bool_exp | null;
  _or?: task_time_user_totals_bool_exp[] | null;
  task?: tasks_bool_exp | null;
  taskId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by aggregate values of table "tasks"
 */
export interface tasks_aggregate_order_by {
  avg?: tasks_avg_order_by | null;
  count?: order_by | null;
  max?: tasks_max_order_by | null;
  min?: tasks_min_order_by | null;
  stddev?: tasks_stddev_order_by | null;
  stddev_pop?: tasks_stddev_pop_order_by | null;
  stddev_samp?: tasks_stddev_samp_order_by | null;
  sum?: tasks_sum_order_by | null;
  var_pop?: tasks_var_pop_order_by | null;
  var_samp?: tasks_var_samp_order_by | null;
  variance?: tasks_variance_order_by | null;
}

/**
 * order by avg() on columns of table "tasks"
 */
export interface tasks_avg_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'.
 */
export interface tasks_bool_exp {
  _and?: tasks_bool_exp[] | null;
  _not?: tasks_bool_exp | null;
  _or?: tasks_bool_exp[] | null;
  branchName?: String_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientCommitDate?: timestamptz_comparison_exp | null;
  clientCommitMessage?: String_comparison_exp | null;
  clientId?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  costInUSD?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  developerByDeveloperid?: developers_bool_exp | null;
  developerByManagerid?: developers_bool_exp | null;
  developerByReviewerid?: developers_bool_exp | null;
  developerId?: String_comparison_exp | null;
  duplicateOfTask?: tasks_bool_exp | null;
  duplicatedTasks?: tasks_bool_exp | null;
  gitRepoId?: Int_comparison_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  isBillable?: Boolean_comparison_exp | null;
  isParked?: Boolean_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  manager?: users_bool_exp | null;
  managerId?: String_comparison_exp | null;
  prLink?: String_comparison_exp | null;
  reviewerId?: String_comparison_exp | null;
  specLink?: String_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: tasks_status_enum_comparison_exp | null;
  taskCode?: String_comparison_exp | null;
  task_client_blockers?: task_client_blockers_bool_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  task_reviewers?: task_reviewers_bool_exp | null;
  task_reviews?: task_reviews_bool_exp | null;
  task_status?: tasks_status_bool_exp | null;
  task_technologies?: task_technologies_bool_exp | null;
  task_time_user_totals?: task_time_user_totals_bool_exp | null;
  task_type?: tasks_type_bool_exp | null;
  tasks_status?: tasks_status_bool_exp | null;
  tasks_type?: tasks_type_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketCode?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
  type?: tasks_type_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "tasks"
 */
export interface tasks_max_order_by {
  branchName?: order_by | null;
  clientCommitDate?: order_by | null;
  clientCommitMessage?: order_by | null;
  clientId?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  developerId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  prLink?: order_by | null;
  reviewerId?: order_by | null;
  specLink?: order_by | null;
  startedAt?: order_by | null;
  taskCode?: order_by | null;
  ticketCode?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "tasks"
 */
export interface tasks_min_order_by {
  branchName?: order_by | null;
  clientCommitDate?: order_by | null;
  clientCommitMessage?: order_by | null;
  clientId?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  developerId?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  prLink?: order_by | null;
  reviewerId?: order_by | null;
  specLink?: order_by | null;
  startedAt?: order_by | null;
  taskCode?: order_by | null;
  ticketCode?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tasks_status". All fields are combined with a logical 'AND'.
 */
export interface tasks_status_bool_exp {
  _and?: tasks_status_bool_exp[] | null;
  _not?: tasks_status_bool_exp | null;
  _or?: tasks_status_bool_exp[] | null;
  tasks?: tasks_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "tasks_status_enum". All fields are combined with logical 'AND'.
 */
export interface tasks_status_enum_comparison_exp {
  _eq?: tasks_status_enum | null;
  _in?: tasks_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tasks_status_enum | null;
  _nin?: tasks_status_enum[] | null;
}

/**
 * order by stddev() on columns of table "tasks"
 */
export interface tasks_stddev_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "tasks"
 */
export interface tasks_stddev_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "tasks"
 */
export interface tasks_stddev_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "tasks"
 */
export interface tasks_sum_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tasks_type". All fields are combined with a logical 'AND'.
 */
export interface tasks_type_bool_exp {
  _and?: tasks_type_bool_exp[] | null;
  _not?: tasks_type_bool_exp | null;
  _or?: tasks_type_bool_exp[] | null;
  tasks?: tasks_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "tasks_type_enum". All fields are combined with logical 'AND'.
 */
export interface tasks_type_enum_comparison_exp {
  _eq?: tasks_type_enum | null;
  _in?: tasks_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tasks_type_enum | null;
  _nin?: tasks_type_enum[] | null;
}

/**
 * order by var_pop() on columns of table "tasks"
 */
export interface tasks_var_pop_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "tasks"
 */
export interface tasks_var_samp_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "tasks"
 */
export interface tasks_variance_order_by {
  costInCredits?: order_by | null;
  costInUSD?: order_by | null;
  gitRepoId?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "technologies". All fields are combined with a logical 'AND'.
 */
export interface technologies_bool_exp {
  _and?: technologies_bool_exp[] | null;
  _not?: technologies_bool_exp | null;
  _or?: technologies_bool_exp[] | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  task_technologies?: task_technologies_bool_exp | null;
}

/**
 * order by aggregate values of table "ticket_client_blockers"
 */
export interface ticket_client_blockers_aggregate_order_by {
  count?: order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_client_blockers"
 */
export interface ticket_client_blockers_arr_rel_insert_input {
  data: ticket_client_blockers_insert_input[];
}

/**
 * Boolean expression to filter rows from the table "ticket_client_blockers". All fields are combined with a logical 'AND'.
 */
export interface ticket_client_blockers_bool_exp {
  _and?: ticket_client_blockers_bool_exp[] | null;
  _not?: ticket_client_blockers_bool_exp | null;
  _or?: ticket_client_blockers_bool_exp[] | null;
  client_blocker?: client_blockers_bool_exp | null;
  ticket?: tickets_bool_exp | null;
}

/**
 * input type for inserting data into table "ticket_client_blockers"
 */
export interface ticket_client_blockers_insert_input {
  clientBlockersId?: number | null;
  client_blocker?: client_blockers_obj_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketsId?: number | null;
}

/**
 * order by aggregate values of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_aggregate_order_by {
  avg?: ticket_credit_approvals_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_credit_approvals_max_order_by | null;
  min?: ticket_credit_approvals_min_order_by | null;
  stddev?: ticket_credit_approvals_stddev_order_by | null;
  stddev_pop?: ticket_credit_approvals_stddev_pop_order_by | null;
  stddev_samp?: ticket_credit_approvals_stddev_samp_order_by | null;
  sum?: ticket_credit_approvals_sum_order_by | null;
  var_pop?: ticket_credit_approvals_var_pop_order_by | null;
  var_samp?: ticket_credit_approvals_var_samp_order_by | null;
  variance?: ticket_credit_approvals_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_arr_rel_insert_input {
  data: ticket_credit_approvals_insert_input[];
  on_conflict?: ticket_credit_approvals_on_conflict | null;
}

/**
 * order by avg() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_avg_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approvals". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approvals_bool_exp {
  _and?: ticket_credit_approvals_bool_exp[] | null;
  _not?: ticket_credit_approvals_bool_exp | null;
  _or?: ticket_credit_approvals_bool_exp[] | null;
  applicant?: users_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  reviewer?: users_bool_exp | null;
  status?: ticket_credit_status_enum_comparison_exp | null;
  task_credit_approvals?: task_credit_approval_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_insert_input {
  createdAt?: hasura_timestamptz | null;
  deletedAt?: hasura_timestamptz | null;
  id?: number | null;
  lowerBudget?: number | null;
  reason?: string | null;
  requestedByUserId?: number | null;
  reviewedByUserId?: number | null;
  status?: ticket_credit_status_enum | null;
  task_credit_approvals?: task_credit_approval_arr_rel_insert_input | null;
  ticket?: tickets_obj_rel_insert_input | null;
  ticketId?: number | null;
  updatedAt?: hasura_timestamptz | null;
  upperBudget?: number | null;
}

/**
 * order by max() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_max_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_min_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_obj_rel_insert_input {
  data: ticket_credit_approvals_insert_input;
  on_conflict?: ticket_credit_approvals_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_on_conflict {
  constraint: ticket_credit_approvals_constraint;
  update_columns: ticket_credit_approvals_update_column[];
  where?: ticket_credit_approvals_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_credit_approvals_parsed". All fields are combined with a logical 'AND'.
 */
export interface ticket_credit_approvals_parsed_bool_exp {
  _and?: ticket_credit_approvals_parsed_bool_exp[] | null;
  _not?: ticket_credit_approvals_parsed_bool_exp | null;
  _or?: ticket_credit_approvals_parsed_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  need_approval?: Boolean_comparison_exp | null;
  reason?: String_comparison_exp | null;
  requestedByUserId?: Int_comparison_exp | null;
  reviewedByUserId?: Int_comparison_exp | null;
  status?: String_comparison_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * Ordering options when selecting data from "ticket_credit_approvals_parsed".
 */
export interface ticket_credit_approvals_parsed_order_by {
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  lowerBudget?: order_by | null;
  need_approval?: order_by | null;
  reason?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  status?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_stddev_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_stddev_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_stddev_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_sum_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_var_pop_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_var_samp_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_credit_approvals"
 */
export interface ticket_credit_approvals_variance_order_by {
  id?: order_by | null;
  lowerBudget?: order_by | null;
  requestedByUserId?: order_by | null;
  reviewedByUserId?: order_by | null;
  ticketId?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "ticket_credit_status_enum". All fields are combined with logical 'AND'.
 */
export interface ticket_credit_status_enum_comparison_exp {
  _eq?: ticket_credit_status_enum | null;
  _in?: ticket_credit_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: ticket_credit_status_enum | null;
  _nin?: ticket_credit_status_enum[] | null;
}

/**
 * order by aggregate values of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_aggregate_order_by {
  count?: order_by | null;
  max?: ticket_daily_standups_max_order_by | null;
  min?: ticket_daily_standups_min_order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface ticket_daily_standups_bool_exp {
  _and?: ticket_daily_standups_bool_exp[] | null;
  _not?: ticket_daily_standups_bool_exp | null;
  _or?: ticket_daily_standups_bool_exp[] | null;
  client_daily_standup?: client_daily_standups_bool_exp | null;
  message?: String_comparison_exp | null;
  project_daily_standup?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  ticket?: tickets_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  updatedETA?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_daily_standup?: user_daily_standups_bool_exp | null;
}

/**
 * order by max() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_max_order_by {
  message?: order_by | null;
  submittedAt?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_daily_standups"
 */
export interface ticket_daily_standups_min_order_by {
  message?: order_by | null;
  submittedAt?: order_by | null;
  updatedAt?: order_by | null;
  updatedETA?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_refund"
 */
export interface ticket_refund_aggregate_order_by {
  avg?: ticket_refund_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_refund_max_order_by | null;
  min?: ticket_refund_min_order_by | null;
  stddev?: ticket_refund_stddev_order_by | null;
  stddev_pop?: ticket_refund_stddev_pop_order_by | null;
  stddev_samp?: ticket_refund_stddev_samp_order_by | null;
  sum?: ticket_refund_sum_order_by | null;
  var_pop?: ticket_refund_var_pop_order_by | null;
  var_samp?: ticket_refund_var_samp_order_by | null;
  variance?: ticket_refund_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_refund"
 */
export interface ticket_refund_avg_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_refund". All fields are combined with a logical 'AND'.
 */
export interface ticket_refund_bool_exp {
  _and?: ticket_refund_bool_exp[] | null;
  _not?: ticket_refund_bool_exp | null;
  _or?: ticket_refund_bool_exp[] | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deletedAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  issuedByUserId?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * order by max() on columns of table "ticket_refund"
 */
export interface ticket_refund_max_order_by {
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  reason?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_refund"
 */
export interface ticket_refund_min_order_by {
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  deletedAt?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  reason?: order_by | null;
  ticketId?: order_by | null;
  updatedAt?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_refund"
 */
export interface ticket_refund_stddev_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_refund"
 */
export interface ticket_refund_stddev_pop_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_refund"
 */
export interface ticket_refund_stddev_samp_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_refund"
 */
export interface ticket_refund_sum_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_refund"
 */
export interface ticket_refund_var_pop_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_refund"
 */
export interface ticket_refund_var_samp_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_refund"
 */
export interface ticket_refund_variance_order_by {
  costInCredits?: order_by | null;
  id?: order_by | null;
  issuedByUserId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by aggregate values of table "ticket_repos"
 */
export interface ticket_repos_aggregate_order_by {
  avg?: ticket_repos_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_repos_max_order_by | null;
  min?: ticket_repos_min_order_by | null;
  stddev?: ticket_repos_stddev_order_by | null;
  stddev_pop?: ticket_repos_stddev_pop_order_by | null;
  stddev_samp?: ticket_repos_stddev_samp_order_by | null;
  sum?: ticket_repos_sum_order_by | null;
  var_pop?: ticket_repos_var_pop_order_by | null;
  var_samp?: ticket_repos_var_samp_order_by | null;
  variance?: ticket_repos_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_repos"
 */
export interface ticket_repos_avg_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_repos". All fields are combined with a logical 'AND'.
 */
export interface ticket_repos_bool_exp {
  _and?: ticket_repos_bool_exp[] | null;
  _not?: ticket_repos_bool_exp | null;
  _or?: ticket_repos_bool_exp[] | null;
  git_repo?: git_repos_bool_exp | null;
  repoId?: Int_comparison_exp | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "ticket_repos"
 */
export interface ticket_repos_max_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_repos"
 */
export interface ticket_repos_min_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_repos"
 */
export interface ticket_repos_stddev_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_repos"
 */
export interface ticket_repos_stddev_pop_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_repos"
 */
export interface ticket_repos_stddev_samp_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_repos"
 */
export interface ticket_repos_sum_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_repos"
 */
export interface ticket_repos_var_pop_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_repos"
 */
export interface ticket_repos_var_samp_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_repos"
 */
export interface ticket_repos_variance_order_by {
  repoId?: order_by | null;
  ticketId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "ticket_status_enum". All fields are combined with logical 'AND'.
 */
export interface ticket_status_enum_comparison_exp {
  _eq?: ticket_status_enum | null;
  _in?: ticket_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: ticket_status_enum | null;
  _nin?: ticket_status_enum[] | null;
}

/**
 * order by aggregate values of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_aggregate_order_by {
  avg?: ticket_time_user_totals_avg_order_by | null;
  count?: order_by | null;
  max?: ticket_time_user_totals_max_order_by | null;
  min?: ticket_time_user_totals_min_order_by | null;
  stddev?: ticket_time_user_totals_stddev_order_by | null;
  stddev_pop?: ticket_time_user_totals_stddev_pop_order_by | null;
  stddev_samp?: ticket_time_user_totals_stddev_samp_order_by | null;
  sum?: ticket_time_user_totals_sum_order_by | null;
  var_pop?: ticket_time_user_totals_var_pop_order_by | null;
  var_samp?: ticket_time_user_totals_var_samp_order_by | null;
  variance?: ticket_time_user_totals_variance_order_by | null;
}

/**
 * order by avg() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_avg_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "ticket_time_user_totals". All fields are combined with a logical 'AND'.
 */
export interface ticket_time_user_totals_bool_exp {
  _and?: ticket_time_user_totals_bool_exp[] | null;
  _not?: ticket_time_user_totals_bool_exp | null;
  _or?: ticket_time_user_totals_bool_exp[] | null;
  ticket?: tickets_bool_exp | null;
  ticketId?: Int_comparison_exp | null;
  totalOrganizedTimeInSecs?: bigint_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_max_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_min_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_stddev_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_stddev_pop_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_stddev_samp_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_sum_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_var_pop_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_var_samp_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "ticket_time_user_totals"
 */
export interface ticket_time_user_totals_variance_order_by {
  ticketId?: order_by | null;
  totalOrganizedTimeInSecs?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "tickets"
 */
export interface tickets_aggregate_order_by {
  avg?: tickets_avg_order_by | null;
  count?: order_by | null;
  max?: tickets_max_order_by | null;
  min?: tickets_min_order_by | null;
  stddev?: tickets_stddev_order_by | null;
  stddev_pop?: tickets_stddev_pop_order_by | null;
  stddev_samp?: tickets_stddev_samp_order_by | null;
  sum?: tickets_sum_order_by | null;
  var_pop?: tickets_var_pop_order_by | null;
  var_samp?: tickets_var_samp_order_by | null;
  variance?: tickets_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "tickets"
 */
export interface tickets_arr_rel_insert_input {
  data: tickets_insert_input[];
  on_conflict?: tickets_on_conflict | null;
}

/**
 * order by avg() on columns of table "tickets"
 */
export interface tickets_avg_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "tickets". All fields are combined with a logical 'AND'.
 */
export interface tickets_bool_exp {
  _and?: tickets_bool_exp[] | null;
  _not?: tickets_bool_exp | null;
  _or?: tickets_bool_exp[] | null;
  billableCostInCredits?: Int_comparison_exp | null;
  cancelledAt?: timestamptz_comparison_exp | null;
  client?: clients_bool_exp | null;
  clientId?: String_comparison_exp | null;
  clientProjectId?: Int_comparison_exp | null;
  client_invoice?: client_invoices_bool_exp | null;
  client_project?: client_projects_bool_exp | null;
  code?: String_comparison_exp | null;
  completedAt?: timestamptz_comparison_exp | null;
  costInCredits?: Int_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  descriptionType?: description_types_enum_comparison_exp | null;
  description_type?: description_types_bool_exp | null;
  developer?: developers_bool_exp | null;
  git_repo?: git_repos_bool_exp | null;
  id?: Int_comparison_exp | null;
  invoiceId?: Int_comparison_exp | null;
  isInternal?: Boolean_comparison_exp | null;
  isSynced?: Boolean_comparison_exp | null;
  lowerBudget?: Int_comparison_exp | null;
  manager?: users_bool_exp | null;
  managerId?: String_comparison_exp | null;
  needsApproval?: Boolean_comparison_exp | null;
  startedAt?: timestamptz_comparison_exp | null;
  status?: ticket_status_enum_comparison_exp | null;
  statusUpdateReason?: String_comparison_exp | null;
  statusUpdatedAt?: timestamptz_comparison_exp | null;
  syncedAt?: timestamptz_comparison_exp | null;
  tasks?: tasks_bool_exp | null;
  ticketLink?: String_comparison_exp | null;
  ticket_client_blockers?: ticket_client_blockers_bool_exp | null;
  ticket_credit_approval?: ticket_credit_approvals_parsed_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  ticket_refunds?: ticket_refund_bool_exp | null;
  ticket_repos?: ticket_repos_bool_exp | null;
  ticket_time_user_totals?: ticket_time_user_totals_bool_exp | null;
  title?: String_comparison_exp | null;
  type?: tickets_types_enum_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  upperBudget?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "tickets"
 */
export interface tickets_insert_input {
  client_invoice?: client_invoices_obj_rel_insert_input | null;
  discussionSourceByCode?: discussion_sources_obj_rel_insert_input | null;
  discussion_source?: discussion_sources_obj_rel_insert_input | null;
  discussion_sources?: discussion_sources_arr_rel_insert_input | null;
  ticket_client_blockers?: ticket_client_blockers_arr_rel_insert_input | null;
  ticket_credit_approvals?: ticket_credit_approvals_arr_rel_insert_input | null;
}

/**
 * order by max() on columns of table "tickets"
 */
export interface tickets_max_order_by {
  cancelledAt?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  code?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  startedAt?: order_by | null;
  statusUpdateReason?: order_by | null;
  statusUpdatedAt?: order_by | null;
  syncedAt?: order_by | null;
  ticketLink?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by min() on columns of table "tickets"
 */
export interface tickets_min_order_by {
  cancelledAt?: order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  code?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  managerId?: order_by | null;
  startedAt?: order_by | null;
  statusUpdateReason?: order_by | null;
  statusUpdatedAt?: order_by | null;
  syncedAt?: order_by | null;
  ticketLink?: order_by | null;
  title?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "tickets"
 */
export interface tickets_obj_rel_insert_input {
  data: tickets_insert_input;
  on_conflict?: tickets_on_conflict | null;
}

/**
 * on_conflict condition type for table "tickets"
 */
export interface tickets_on_conflict {
  constraint: tickets_constraint;
  update_columns: tickets_update_column[];
  where?: tickets_bool_exp | null;
}

/**
 * Ordering options when selecting data from "tickets".
 */
export interface tickets_order_by {
  billableCostInCredits?: order_by | null;
  cancelledAt?: order_by | null;
  client?: clients_order_by | null;
  clientId?: order_by | null;
  clientProjectId?: order_by | null;
  client_invoice?: client_invoices_order_by | null;
  client_project?: client_projects_order_by | null;
  code?: order_by | null;
  completedAt?: order_by | null;
  costInCredits?: order_by | null;
  createdAt?: order_by | null;
  description?: order_by | null;
  descriptionType?: order_by | null;
  description_type?: description_types_order_by | null;
  developer?: developers_order_by | null;
  git_repo?: git_repos_order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  isInternal?: order_by | null;
  isSynced?: order_by | null;
  lowerBudget?: order_by | null;
  manager?: users_order_by | null;
  managerId?: order_by | null;
  needsApproval?: order_by | null;
  startedAt?: order_by | null;
  status?: order_by | null;
  statusUpdateReason?: order_by | null;
  statusUpdatedAt?: order_by | null;
  syncedAt?: order_by | null;
  tasks_aggregate?: tasks_aggregate_order_by | null;
  ticketLink?: order_by | null;
  ticket_client_blockers_aggregate?: ticket_client_blockers_aggregate_order_by | null;
  ticket_credit_approval?: ticket_credit_approvals_parsed_order_by | null;
  ticket_credit_approvals_aggregate?: ticket_credit_approvals_aggregate_order_by | null;
  ticket_daily_standups_aggregate?: ticket_daily_standups_aggregate_order_by | null;
  ticket_refunds_aggregate?: ticket_refund_aggregate_order_by | null;
  ticket_repos_aggregate?: ticket_repos_aggregate_order_by | null;
  ticket_time_user_totals_aggregate?: ticket_time_user_totals_aggregate_order_by | null;
  title?: order_by | null;
  type?: order_by | null;
  updatedAt?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev() on columns of table "tickets"
 */
export interface tickets_stddev_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "tickets"
 */
export interface tickets_stddev_pop_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "tickets"
 */
export interface tickets_stddev_samp_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by sum() on columns of table "tickets"
 */
export interface tickets_sum_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "tickets_types_enum". All fields are combined with logical 'AND'.
 */
export interface tickets_types_enum_comparison_exp {
  _eq?: tickets_types_enum | null;
  _in?: tickets_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: tickets_types_enum | null;
  _nin?: tickets_types_enum[] | null;
}

/**
 * order by var_pop() on columns of table "tickets"
 */
export interface tickets_var_pop_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by var_samp() on columns of table "tickets"
 */
export interface tickets_var_samp_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * order by variance() on columns of table "tickets"
 */
export interface tickets_variance_order_by {
  clientProjectId?: order_by | null;
  costInCredits?: order_by | null;
  id?: order_by | null;
  invoiceId?: order_by | null;
  lowerBudget?: order_by | null;
  upperBudget?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: hasura_timestamptz | null;
  _gt?: hasura_timestamptz | null;
  _gte?: hasura_timestamptz | null;
  _in?: hasura_timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_timestamptz | null;
  _lte?: hasura_timestamptz | null;
  _neq?: hasura_timestamptz | null;
  _nin?: hasura_timestamptz[] | null;
}

/**
 * order by aggregate values of table "user_daily_standups"
 */
export interface user_daily_standups_aggregate_order_by {
  avg?: user_daily_standups_avg_order_by | null;
  count?: order_by | null;
  max?: user_daily_standups_max_order_by | null;
  min?: user_daily_standups_min_order_by | null;
  stddev?: user_daily_standups_stddev_order_by | null;
  stddev_pop?: user_daily_standups_stddev_pop_order_by | null;
  stddev_samp?: user_daily_standups_stddev_samp_order_by | null;
  sum?: user_daily_standups_sum_order_by | null;
  var_pop?: user_daily_standups_var_pop_order_by | null;
  var_samp?: user_daily_standups_var_samp_order_by | null;
  variance?: user_daily_standups_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_avg_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_daily_standups". All fields are combined with a logical 'AND'.
 */
export interface user_daily_standups_bool_exp {
  _and?: user_daily_standups_bool_exp[] | null;
  _not?: user_daily_standups_bool_exp | null;
  _or?: user_daily_standups_bool_exp[] | null;
  client_daily_standups?: client_daily_standups_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  submittedAt?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
}

/**
 * order by max() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_max_order_by {
  createdAt?: order_by | null;
  id?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_min_order_by {
  createdAt?: order_by | null;
  id?: order_by | null;
  submittedAt?: order_by | null;
  summary?: order_by | null;
  updatedAt?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_stddev_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_stddev_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_stddev_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_sum_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_var_pop_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_var_samp_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_daily_standups"
 */
export interface user_daily_standups_variance_order_by {
  id?: order_by | null;
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "user_emails"
 */
export interface user_emails_aggregate_order_by {
  avg?: user_emails_avg_order_by | null;
  count?: order_by | null;
  max?: user_emails_max_order_by | null;
  min?: user_emails_min_order_by | null;
  stddev?: user_emails_stddev_order_by | null;
  stddev_pop?: user_emails_stddev_pop_order_by | null;
  stddev_samp?: user_emails_stddev_samp_order_by | null;
  sum?: user_emails_sum_order_by | null;
  var_pop?: user_emails_var_pop_order_by | null;
  var_samp?: user_emails_var_samp_order_by | null;
  variance?: user_emails_variance_order_by | null;
}

/**
 * order by avg() on columns of table "user_emails"
 */
export interface user_emails_avg_order_by {
  userId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "user_emails". All fields are combined with a logical 'AND'.
 */
export interface user_emails_bool_exp {
  _and?: user_emails_bool_exp[] | null;
  _not?: user_emails_bool_exp | null;
  _or?: user_emails_bool_exp[] | null;
  git_users?: git_users_bool_exp | null;
  github_accounts?: github_accounts_bool_exp | null;
  user?: users_bool_exp | null;
  userByEmail?: users_bool_exp | null;
  userId?: Int_comparison_exp | null;
  users?: users_bool_exp | null;
  usersByUnverifiedemail?: users_bool_exp | null;
}

/**
 * order by max() on columns of table "user_emails"
 */
export interface user_emails_max_order_by {
  userId?: order_by | null;
}

/**
 * order by min() on columns of table "user_emails"
 */
export interface user_emails_min_order_by {
  userId?: order_by | null;
}

/**
 * Ordering options when selecting data from "user_emails".
 */
export interface user_emails_order_by {
  git_users_aggregate?: git_users_aggregate_order_by | null;
  github_accounts_aggregate?: github_accounts_aggregate_order_by | null;
  user?: users_order_by | null;
  userByEmail?: users_order_by | null;
  userId?: order_by | null;
  usersByUnverifiedemail_aggregate?: users_aggregate_order_by | null;
  users_aggregate?: users_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "user_emails"
 */
export interface user_emails_stddev_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "user_emails"
 */
export interface user_emails_stddev_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "user_emails"
 */
export interface user_emails_stddev_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by sum() on columns of table "user_emails"
 */
export interface user_emails_sum_order_by {
  userId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "user_emails"
 */
export interface user_emails_var_pop_order_by {
  userId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "user_emails"
 */
export interface user_emails_var_samp_order_by {
  userId?: order_by | null;
}

/**
 * order by variance() on columns of table "user_emails"
 */
export interface user_emails_variance_order_by {
  userId?: order_by | null;
}

/**
 * order by aggregate values of table "users"
 */
export interface users_aggregate_order_by {
  avg?: users_avg_order_by | null;
  count?: order_by | null;
  max?: users_max_order_by | null;
  min?: users_min_order_by | null;
  stddev?: users_stddev_order_by | null;
  stddev_pop?: users_stddev_pop_order_by | null;
  stddev_samp?: users_stddev_samp_order_by | null;
  sum?: users_sum_order_by | null;
  var_pop?: users_var_pop_order_by | null;
  var_samp?: users_var_samp_order_by | null;
  variance?: users_variance_order_by | null;
}

/**
 * order by avg() on columns of table "users"
 */
export interface users_avg_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'.
 */
export interface users_bool_exp {
  _and?: users_bool_exp[] | null;
  _not?: users_bool_exp | null;
  _or?: users_bool_exp[] | null;
  admin?: admin_bool_exp | null;
  admins?: admin_bool_exp | null;
  adminsByUserid?: admin_bool_exp | null;
  avatarUrl?: String_comparison_exp | null;
  client_blockers?: client_blockers_bool_exp | null;
  client_daily_standups?: client_daily_standups_bool_exp | null;
  client_project_users?: client_project_users_bool_exp | null;
  client_transactions?: client_transactions_bool_exp | null;
  client_users?: client_users_bool_exp | null;
  defaultEmail?: String_comparison_exp | null;
  developer?: developers_bool_exp | null;
  developers?: developers_bool_exp | null;
  firstName?: String_comparison_exp | null;
  git_users?: git_users_bool_exp | null;
  id?: Int_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  login?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  passwordHash?: String_comparison_exp | null;
  project_daily_standups?: project_daily_standup_bool_exp | null;
  requestedTaskCreditApprovals?: task_credit_approval_bool_exp | null;
  reviewedTaskCreditApprovals?: task_credit_approval_bool_exp | null;
  ripplingEmployeeId?: Int_comparison_exp | null;
  task_daily_standups?: task_daily_standups_bool_exp | null;
  telephone?: String_comparison_exp | null;
  ticketCreditApprovalsByReviewedbyuserid?: ticket_credit_approvals_bool_exp | null;
  ticket_credit_approvals?: ticket_credit_approvals_bool_exp | null;
  ticket_daily_standups?: ticket_daily_standups_bool_exp | null;
  ticket_refunds?: ticket_refund_bool_exp | null;
  userEmailByDefaultEmail?: user_emails_bool_exp | null;
  userEmailByUnverifiedemail?: user_emails_bool_exp | null;
  user_daily_standups?: user_daily_standups_bool_exp | null;
  user_emails?: user_emails_bool_exp | null;
}

/**
 * order by max() on columns of table "users"
 */
export interface users_max_order_by {
  avatarUrl?: order_by | null;
  defaultEmail?: order_by | null;
  firstName?: order_by | null;
  id?: order_by | null;
  lastName?: order_by | null;
  login?: order_by | null;
  name?: order_by | null;
  passwordHash?: order_by | null;
  ripplingEmployeeId?: order_by | null;
  telephone?: order_by | null;
}

/**
 * order by min() on columns of table "users"
 */
export interface users_min_order_by {
  avatarUrl?: order_by | null;
  defaultEmail?: order_by | null;
  firstName?: order_by | null;
  id?: order_by | null;
  lastName?: order_by | null;
  login?: order_by | null;
  name?: order_by | null;
  passwordHash?: order_by | null;
  ripplingEmployeeId?: order_by | null;
  telephone?: order_by | null;
}

/**
 * Ordering options when selecting data from "users".
 */
export interface users_order_by {
  admin?: admin_order_by | null;
  adminsByUserid_aggregate?: admin_aggregate_order_by | null;
  admins_aggregate?: admin_aggregate_order_by | null;
  avatarUrl?: order_by | null;
  client_blockers_aggregate?: client_blockers_aggregate_order_by | null;
  client_daily_standups_aggregate?: client_daily_standups_aggregate_order_by | null;
  client_project_users_aggregate?: client_project_users_aggregate_order_by | null;
  client_transactions_aggregate?: client_transactions_aggregate_order_by | null;
  client_users_aggregate?: client_users_aggregate_order_by | null;
  defaultEmail?: order_by | null;
  developer?: developers_order_by | null;
  developers_aggregate?: developers_aggregate_order_by | null;
  firstName?: order_by | null;
  git_users_aggregate?: git_users_aggregate_order_by | null;
  id?: order_by | null;
  lastName?: order_by | null;
  login?: order_by | null;
  name?: order_by | null;
  passwordHash?: order_by | null;
  project_daily_standups_aggregate?: project_daily_standup_aggregate_order_by | null;
  requestedTaskCreditApprovals_aggregate?: task_credit_approval_aggregate_order_by | null;
  reviewedTaskCreditApprovals_aggregate?: task_credit_approval_aggregate_order_by | null;
  ripplingEmployeeId?: order_by | null;
  task_daily_standups_aggregate?: task_daily_standups_aggregate_order_by | null;
  telephone?: order_by | null;
  ticketCreditApprovalsByReviewedbyuserid_aggregate?: ticket_credit_approvals_aggregate_order_by | null;
  ticket_credit_approvals_aggregate?: ticket_credit_approvals_aggregate_order_by | null;
  ticket_daily_standups_aggregate?: ticket_daily_standups_aggregate_order_by | null;
  ticket_refunds_aggregate?: ticket_refund_aggregate_order_by | null;
  userEmailByDefaultEmail?: user_emails_order_by | null;
  userEmailByUnverifiedemail?: user_emails_order_by | null;
  user_daily_standups_aggregate?: user_daily_standups_aggregate_order_by | null;
  user_emails_aggregate?: user_emails_aggregate_order_by | null;
}

/**
 * order by stddev() on columns of table "users"
 */
export interface users_stddev_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "users"
 */
export interface users_stddev_pop_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "users"
 */
export interface users_stddev_samp_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * order by sum() on columns of table "users"
 */
export interface users_sum_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * order by var_pop() on columns of table "users"
 */
export interface users_var_pop_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * order by var_samp() on columns of table "users"
 */
export interface users_var_samp_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * order by variance() on columns of table "users"
 */
export interface users_variance_order_by {
  id?: order_by | null;
  ripplingEmployeeId?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: hasura_uuid | null;
  _gt?: hasura_uuid | null;
  _gte?: hasura_uuid | null;
  _in?: hasura_uuid[] | null;
  _is_null?: boolean | null;
  _lt?: hasura_uuid | null;
  _lte?: hasura_uuid | null;
  _neq?: hasura_uuid | null;
  _nin?: hasura_uuid[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
