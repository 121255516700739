import gql from 'graphql-tag';

export const UPDATE_CLIENT_TRANSACTION = gql`
  mutation updateClientTransaction(
    $transactionId: Int!
    $transaction: client_transactions_set_input!
  ) {
    update_client_transactions(
      where: { id: { _eq: $transactionId } }
      _set: $transaction
    ) {
      affected_rows
    }
  }
`;

export const UNBLOCK_CLIENT_BLOCKER = gql`
  mutation unblockClientBlockers(
    $id: Int!
    $unblockDetails: String!
    $resolvedById: Int
  ) {
    update_client_blockers(
      where: { id: { _eq: $id } }
      _set: {
        unblockDetails: $unblockDetails
        resolvedById: $resolvedById
        status: resolved
      }
    ) {
      returning {
        id
        createdAt
        summary
        details
        updatedAt
        status
        resolvedById
        unblockDetails
      }
    }
  }
`;

export const INSERT_MEETING_AND_UPDATE_CLIENT_ON_BOARDED_AT = gql`
  mutation insertClientMeeting(
    $meeting: meetings_insert_input!
    $clientId: String!
    $onBoardedAt: timestamptz!
  ) {
    insert_meetings(objects: [$meeting]) {
      returning {
        id
      }
    }
    update_clients(
      where: { id: { _eq: $clientId } }
      _set: { onBoardedAt: $onBoardedAt }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CLIENT__STRIPE_CUSTOMER_ID = gql`
  mutation updateStripeClientCustomerId(
    $clientId: String!
    $stripeCustomerId: String
  ) {
    update_clients(
      where: { id: { _eq: $clientId } }
      _set: { stripeCustomerId: $stripeCustomerId }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_PAYMENT_METHOD_FOR_CLIENT = gql`
  mutation createPaymentMethodForClient(
    $clientId: String!
    $paymentMethodId: String
    $paymentMethod: StripePaymentMethodTypes
  ) {
    createClientPaymentMethod(
      clientId: $clientId
      paymentMethodId: $paymentMethodId
      paymentMethod: $paymentMethod
    ) {
      clientId
      clientSecret
    }
  }
`;

export const INSERT_STRIPE_CLIENT_TRANSACTION = gql`
  mutation insertStripeClientTransaction(
    $transaction: client_transactions_insert_input!
    $clientId: String!
    $stripeCustomerId: String
  ) {
    insert_client_transactions(objects: [$transaction]) {
      affected_rows
      returning {
        id
      }
    }

    update_clients(
      where: { id: { _eq: $clientId } }
      _set: { stripeCustomerId: $stripeCustomerId }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_NEW_CLIENT_TRANSACTION = gql`
  mutation insertNewClientTransaction(
    $transactions: [client_transactions_insert_input!]!
  ) {
    insert_client_transactions(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_NEW_TICKET_CLIENT_BLOCKER = gql`
  mutation insertNewTicketClientBlocker(
    $blocker: client_blockers_insert_input!
    $ticketId: Int!
    $onConflict: client_blockers_on_conflict!
  ) {
    insert_ticket_client_blockers(
      objects: [
        {
          ticketsId: $ticketId
          client_blocker: { data: $blocker, on_conflict: $onConflict }
        }
      ]
    ) {
      returning {
        client_blocker {
          id
        }
      }
    }
  }
`;

export const UPSERT_GIT_ORGANIZATION = gql`
  mutation updateGitOrganization(
    $clientId: String!
    $accountSid: String!
    $listOfInstallations: [git_organizations_insert_input!]!
    $onConflict: git_organizations_on_conflict
  ) {
    update_git_organizations(
      where: {
        _and: [
          { clientId: { _eq: $clientId } }
          {
            github_organization: {
              github_app_installations: { accountSid: { _eq: $accountSid } }
            }
          }
        ]
      }
      _set: { clientId: null }
    ) {
      returning {
        id
        clientId
      }
      affected_rows
    }
    insert_git_organizations(
      objects: $listOfInstallations
      on_conflict: $onConflict
    ) {
      returning {
        id
        clientId
        syncTableName
        syncSid
      }
      affected_rows
    }
  }
`;

export const UPDATE_CLIENT_BUDGET = gql`
  mutation updateClientBudget($clientId: String!, $budget: Int!) {
    update_clients(
      where: { id: { _eq: $clientId } }
      _set: { defaultCreditBudget: $budget }
    ) {
      affected_rows
    }
  }
`;

export const INITIATE_STRIPE_PAYMENT = gql`
  mutation initiateStripePayment(
    $invoiceIds: [Float!]!
    $clientId: String!
    $costInCredits: Float!
    $costInUSD: Float!
    $paymentMethodId: String
    $paymentMethod: StripePaymentMethodTypes
    $topUp: Boolean
  ) {
    initiateStripePayment(
      invoiceIds: $invoiceIds
      clientId: $clientId
      costInCredits: $costInCredits
      costInUSD: $costInUSD
      paymentMethodId: $paymentMethodId
      paymentMethod: $paymentMethod
      topUp: $topUp
    ) {
      clientId
      costInUSD
      stripePaymentIntentClientSecret
      id
      error
      invoiceIds
    }
  }
`;

export const INSERT_GIT_SOURCE = gql`
  mutation insertGitSource($input: insertGitSourceInput!, $token: String!) {
    insertGitSource(input: $input, token: $token) {
      id
      slicedFromBranchName
      slicedFromRepoId
      slicedToBranchName
      slicedToRepoId
      slicedFolders
      ignoredPaths
    }
  }
`;
