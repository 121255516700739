import { useAuth } from 'components/auth';
// TODO: Replace with dayjs (yarn add dayjs)
import moment from 'moment';
import { createStore, useStore } from 'react-hookstore';
import { theme } from 'template/theme';
import { useApolloQuery } from './client/utils';
import {
  fetchSLAs,
  fetchSLAsVariables,
} from '@engine/common/graphql/roles/user/generated/fetchSLAs';
import {
  fetchUserInfo,
  fetchUserInfoVariables,
  fetchUserInfo_user,
  fetchUserInfo_user_teams,
} from '@engine/common/graphql/roles/user/generated/fetchUserInfo';
import { FETCH_SLAS, FETCH_USER_INFO } from './graphql/roles/user/queries';
import { getHoursDiffFromNow } from './utils';

type SLAsType = fetchSLAs | null;

export type SLACounter = {
  value: number;
  color: string;
  id?: number | string | null;
};

export type SLACounters = {
  [key in keyof fetchSLAs]: SLACounter[];
};

const convertSLAToCounter = (
  SLAs?: { dueAt: hasura_timestamptz | null; __typename: string }[],
  redWhenLTE = 2,
  orangeWhenLTE = 6
) => {
  const { redCounter, orangeCounter } = SLAs?.reduce<{
    redCounter: number;
    orangeCounter: number;
  }>(
    (a, c) => {
      const addRed = getHoursDiffFromNow(c.dueAt) <= redWhenLTE ? 1 : 0;
      const addOrange =
        (getHoursDiffFromNow(c.dueAt) <= orangeWhenLTE ? 1 : 0) - addRed;
      return {
        redCounter: a.redCounter + addRed,
        orangeCounter: a.orangeCounter + addOrange,
      };
    },
    { redCounter: 0, orangeCounter: 0 }
  ) || { redCounter: 0, orangeCounter: 0 };

  let color = '';
  if (redCounter > 0) color = theme.palette.error.main;
  else if (orangeCounter > 0) color = theme.palette.warning.main;

  return {
    value: redCounter + orangeCounter,
    color,
  };
};

createStore<SLAsType>('SLAs', null);

export function useSLAs() {
  const ctx = useAuth(true);
  const [SLAs, setSLAs] = useStore<SLAsType>('SLAs');
  const { fetchQuery: refreshSLAs } = useApolloQuery<
    fetchSLAs,
    fetchSLAsVariables
  >(
    {
      ctx,
      role: 'user',
      query: FETCH_SLAS,
      variables: {
        userId: ctx.user?.id || -1,
      },
      autoRun: !SLAs,
      setData: d => setSLAs(d),
    },
    [SLAs]
  );

  const slaCounters: SLACounters = {
    ticket_standups_sla_view: [
      convertSLAToCounter(SLAs?.ticket_standups_sla_view),
    ],
    client_standups_sla_view: [
      convertSLAToCounter(SLAs?.client_standups_sla_view),
    ],
    user_standups_sla_view: [convertSLAToCounter(SLAs?.user_standups_sla_view)],
    task_standups_sla_view: [convertSLAToCounter(SLAs?.task_standups_sla_view)],
    // TODO: Replace with user_team_sla_view
    user_team_stats:
      SLAs?.user_team_stats.map(uts => ({
        id: uts.id,
        value: Number(uts.available_tickets_in_team ?? 0),
        color: theme.palette.primary.light,
      })) || [],
    // TODO: Replace with client_sla_view
    client_stats:
      SLAs?.client_stats.map(cs => ({
        id: cs.id,
        value: Number(cs.needs_credit_approval ?? 0),
        color:
          Number(cs.needs_credit_approval ?? 0) >= 3
            ? theme.palette.error.main
            : theme.palette.primary.light,
      })) || [],
  };

  return { SLAs, refreshSLAs, slaCounters };
}

export type UserInfo = fetchUserInfo_user & {
  user_teams: fetchUserInfo_user_teams[];
};
createStore<fetchUserInfo | null>('UserInfo', null);

export const useUserInfo = () => {
  const ctx = useAuth(true);
  const [userInfo, setUserInfo] = useStore<fetchUserInfo | null>('UserInfo');
  const { fetchQuery: refreshUserInfo, loading, error } = useApolloQuery<
    fetchUserInfo,
    fetchUserInfoVariables
  >(
    {
      ctx,
      role: 'user',
      query: FETCH_USER_INFO,
      variables: {
        id: ctx.user?.id || -1,
      },
      autoRun: !userInfo,
      setData: d => setUserInfo(d),
      fetchPolicy: 'cache-first',
    },
    [userInfo]
  );

  const returnedUserInfo: UserInfo | null = userInfo?.user
    ? { ...userInfo.user, user_teams: userInfo.user_teams }
    : null;

  return {
    userInfo: returnedUserInfo,
    refreshUserInfo,
    loading,
    error,
  };
};

export type Sprint = {
  startAt: Date;
  endAt: Date;
};

export const useSprintDates = (sprintLength = 14) => {
  const startOfDay = new Date(new Date().setHours(0, 0, 0, 0));
  const dayInCurrentSprint =
    moment(startOfDay).diff(new Date('Feb 1 2021'), 'days') % sprintLength;
  const startCurr = moment(startOfDay).subtract(dayInCurrentSprint, 'days');

  /**
   * Get dates (`startAt`, `endAt`) for a single sprint with the given sprint number.
   * @param {number} sprintNum {number} sprint number starting from 0 (current sprint) and increases backwards in time (e.g. 1 sprint back)
   */
  const getSingleSprint = (sprintNum = 0): Sprint => {
    const startAt = moment(startCurr)
      .subtract(sprintLength * sprintNum, 'days')
      .toDate();
    const endAt = moment(startAt)
      .add(sprintLength - 1, 'days')
      .toDate();

    return {
      startAt,
      endAt,
    };
  };

  /**
   * Get dates (`startAt`, `endAt`) from multiple sprints back from current sprint.
   * `endAt` is date of current sprint, and `startAt` is the start of the first sprint in the epoch.
   * @param {number} numberOfSprints number of sprints from current sprint. 1 = current sprint
   * @param {number} sprintShift number of sprints to shift back from. 0 = current sprint
   */
  const getMultipleSprints = (numberOfSprints = 1, sprintShift = 0): Sprint => {
    const startAt = moment(startCurr)
      .subtract(sprintLength * (numberOfSprints + sprintShift - 1), 'days')
      .toDate();
    const endAt = moment(startAt)
      .add(sprintLength * numberOfSprints - 1, 'days')
      .toDate();

    return {
      startAt,
      endAt,
    };
  };

  return {
    getMultipleSprints,
    getSingleSprint,
  };
};
