import { colors } from '@material-ui/core';
import { IntegrationType } from 'components/integrations/types';
import LibTypeDArray from 'crypto-js/lib-typedarrays';
import SHA1 from 'crypto-js/sha1';
import SHA256 from 'crypto-js/sha256';
import SHA512 from 'crypto-js/sha512';
import {
  generateGcpStorageLinks,
  generateGcpStorageLinksVariables,
} from '@engine/common/graphql/roles/user/generated/generateGcpStorageLinks';
import {
  client_user_roles_enum as CUR,
  ticket_credit_status_enum,
} from '@engine/common/graphql/roles/user/generated/globalTypes';
import { user_leave_types_enum } from 'lib/graphql/relay/__generated__/RequestLeaveMutation.graphql';
import moment, { MomentInput } from 'moment';
import getConfig from 'next/config';
import { Property } from 'csstype';
import { fetchDevTeamTickets_tickets_tasks } from '@engine/common/graphql/roles/developer/generated/fetchDevTeamTickets';
import { fetchTicketDetailsByTicketCode_tickets_tasks } from '@engine/common/graphql/roles/user/generated/fetchTicketDetailsByTicketCode';
import { GENERATE_GCP_STORAGE_LINKS } from 'lib/graphql/roles/user/mutations';
import { APP_DATE_FORMAT } from './constants';
import {
  ParticipantDataType,
  ParticipantsArrayType,
  ParticipantsMap,
  Context,
} from './types';
import { NextPageContext } from 'next';
import { useState, useEffect, useRef } from 'react';
import cookieNode from 'cookie';
import cookieJS from 'js-cookie';
import { State } from 'ui/utils';
import { ticket_status_enum } from './graphql/relay/__generated__/TicketUpdateModal_ticket.graphql';
import { tasks_status_enum } from './graphql/relay/__generated__/TicketUMTasks_ticket.graphql';
import { TicketDVCostItem_ticket } from 'lib/graphql/relay/__generated__/TicketDVCostItem_ticket.graphql';

const ENV = getConfig?.()?.publicRuntimeConfig?.ENV;

export function sleep(delay: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, delay));
}

export const getCookie = (ctx: NextPageContext, name: string) => {
  return ctx.req
    ? cookieNode.parse((ctx.req.headers.cookie as string) || '')[name]
    : cookieJS.get(name);
};

const getHostName = (url: string) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    return match[2];
  }
  return null;
};

const getDomain = (url: string) => {
  const hostName = getHostName(url);
  let domain = hostName;
  if (hostName !== null) {
    const parts = hostName.split('.').reverse();

    if (parts !== null && parts.length > 1) {
      domain = `${parts[1]}.${parts[0]}`;

      if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
        domain = `${parts[2]}.${domain}`;
      }
    }
  }

  return domain;
};

export const parseTicketLink = (url: string) => {
  const domain = getDomain(url);
  let ticketLinkTitle;
  switch (domain) {
    case 'github.com':
      ticketLinkTitle = 'OPEN IN GITHUB';
      break;
    case 'atlassian.net':
      ticketLinkTitle = 'OPEN IN JIRA';
      break;
    case 'gitlab.com':
      ticketLinkTitle = 'OPEN IN GITLAB';
      break;
    case 'asana.com':
      ticketLinkTitle = 'OPEN IN ASANA';
      break;
    case 'trello.com':
      ticketLinkTitle = 'OPEN IN TRELLO';
      break;
    default:
      ticketLinkTitle = 'OPEN TICKET';
  }

  return ticketLinkTitle;
};

export const getApprovalStatus = (status: ticket_credit_status_enum) => {
  const approvalStatusHumanReadible: {
    [key in ticket_credit_status_enum]: string;
  } = {
    approved: 'Approved',
    pending: 'Pending',
    rejected: 'Rejected',
    auto_approved: 'Auto Approved',
  };

  const statusColors: {
    [key in ticket_credit_status_enum]: string;
  } = {
    approved: colors.green[600],
    pending: colors.orange[600],
    rejected: colors.red[600],
    auto_approved: colors.green[600],
  };

  return {
    status: approvalStatusHumanReadible[status],
    color: statusColors[status],
  };
};

export const roundDecimalValue = (value: number) => {
  return Math.round(value * 100) / 100;
};

function readFileAsync(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export async function calculateHashOfFile(file: File) {
  const fileData = await readFileAsync(file);
  if (fileData) {
    const strigifiedFileData = LibTypeDArray.create(fileData);
    const sha1 = SHA1(strigifiedFileData).toString();
    const sha256 = SHA256(strigifiedFileData).toString();
    const sha512 = SHA512(strigifiedFileData).toString();
    return { sha1, sha256, sha512 };
  }
  throw new Error('No data in the file');
}

export async function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export type FileUploadData = {
  sid: string;
  readLink: string;
};

export async function uploadFile(
  file: File,
  ctx: Context
): Promise<FileUploadData> {
  const { sha1, sha256, sha512 } = await calculateHashOfFile(file);
  const extension = file.name.substring(file.name.lastIndexOf('.'));

  const gcpStorageLinksResponse = await ctx.graphqlClient?.mutate<
    generateGcpStorageLinks,
    generateGcpStorageLinksVariables
  >({
    mutation: GENERATE_GCP_STORAGE_LINKS,
    variables: {
      fileName: `${sha256}${extension}`,
      mimetype: file.type,
      sizeInBytes: file.size,
      extension: `${extension}`,
      originalFileName: file.name,
      sha1,
      sha256,
      sha512,
    },
    context: { role: 'user' },
  });

  if (gcpStorageLinksResponse?.data) {
    const {
      sid,
      readLink,
      uploadLink,
    } = gcpStorageLinksResponse.data.gcpStorageLinks;
    const resp = await fetch(uploadLink, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });
    if (!resp.ok) {
      const txt = await resp.text();
      console.error('Got back error uploading file: ', txt);
      throw new Error(`Got back error uploading file: ${txt}`);
    }
    return { sid, readLink };
  }
  throw new Error('Error occured! Please try again');
}

export const greetingText = () => {
  const now = moment();
  const currentHour = now.local().hour();
  if (currentHour >= 5 && currentHour < 12) return 'Good Morning';
  if (currentHour >= 12 && currentHour < 17) return 'Good Afternoon';
  if (currentHour >= 17 && currentHour < 22) return 'Good Evening';
  return 'Good Night';
};

export const getTimezones = [
  {
    value: '',
    label: 'None',
  },
  {
    value: '-12:00',
    label: '(GMT -12:00) Eniwetok, Kwajalein',
  },
  {
    value: '-11:00',
    label: '(GMT -11:00) Midway Island, Samoa',
  },
  {
    value: '-10:00',
    label: '(GMT -10:00) Hawaii',
  },
  {
    value: '-09:30',
    label: '(GMT -9:30) Taiohae',
  },
  {
    value: '-09:00',
    label: '(GMT -9:00) Alaska',
  },
  {
    value: '-08:00',
    label: '(GMT -8:00) Pacific Time (US & Canada)',
  },
  {
    value: '-07:00',
    label: '(GMT -7:00) Mountain Time (US & Canada)',
  },
  {
    value: '-06:00',
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  },
  {
    value: '-05:00',
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
  },
  {
    value: '-04:30',
    label: '(GMT -4:30) Caracas',
  },
  {
    value: '-04:00',
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  {
    value: '-03:30',
    label: '(GMT -3:30) Newfoundland',
  },
  {
    value: '-03:00',
    label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
  },
  {
    value: '-02:00',
    label: '(GMT -2:00) Mid-Atlantic',
  },
  {
    value: '-01:00',
    label: '(GMT -1:00) Azores, Cape Verde Islands',
  },
  {
    value: '+00:00',
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  {
    value: '+01:00',
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: '+02:00',
    label: '(GMT +2:00) Kaliningrad, South Africa',
  },
  {
    value: '+03:00',
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  {
    value: '+03:30',
    label: '(GMT +3:30) Tehran',
  },
  {
    value: '+04:00',
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
  },
  {
    value: '+04:30',
    label: '(GMT +4:30) Kabul',
  },
  {
    value: '+05:00',
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  {
    value: '+05:30',
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
  },
  {
    value: '+05:45',
    label: '(GMT +5:45) Kathmandu, Pokhara',
  },
  {
    value: '+06:00',
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
  },
  {
    value: '+06:30',
    label: '(GMT +6:30) Yangon, Mandalay',
  },
  {
    value: '+07:00',
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
  },
  {
    value: '+08:00',
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
  },
  {
    value: '+08:45',
    label: '(GMT +8:45) Eucla',
  },
  {
    value: '+09:00',
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  {
    value: '+09:30',
    label: '(GMT +9:30) Adelaide, Darwin',
  },
  {
    value: '+10:00',
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  {
    value: '+10:30',
    label: '(GMT +10:30) Lord Howe Island',
  },
  {
    value: '+11:00',
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  {
    value: '+11:30',
    label: '(GMT +11:30) Norfolk Island',
  },
  {
    value: '+12:00',
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
  {
    value: '+12:45',
    label: '(GMT +12:45) Chatham Islands',
  },
  {
    value: '+13:00',
    label: '(GMT +13:00) Apia, Nukualofa',
  },
  {
    value: '+14:00',
    label: '(GMT +14:00) Line Islands, Tokelau',
  },
];

export const getTicketParticipants = (
  tasks: fetchTicketDetailsByTicketCode_tickets_tasks[]
): ParticipantsArrayType[] => {
  const getParticipantByType = (
    key: string,
    prev: ParticipantsMap,
    participant: ParticipantDataType,
    participantType: string
  ) => {
    if (key in prev) {
      const types = prev[key]?.participantType.split(' & ');
      if (!types) return null;
      return {
        [key]: {
          ...participant,
          participantType:
            types.indexOf(participantType) > -1
              ? prev[key]?.participantType
              : `${prev[key]?.participantType} & ${participantType}`,
        },
      };
    }
    return { [key]: { ...participant, participantType } };
  };
  return Object.values(
    tasks.reduce<ParticipantsMap>(
      (
        acc: ParticipantsMap,
        task: fetchTicketDetailsByTicketCode_tickets_tasks
      ) => {
        const {
          developerByDeveloperid,
          developerByManagerid,
          developerByReviewerid,
        } = task;

        const developer =
          developerByDeveloperid &&
          getParticipantByType(
            developerByDeveloperid.id,
            acc,
            developerByDeveloperid,
            'Developer'
          );
        const manager =
          developerByManagerid &&
          getParticipantByType(
            developerByManagerid.id,
            acc,
            developerByManagerid,
            'Manager'
          );
        const reviewer =
          developerByReviewerid &&
          getParticipantByType(
            developerByReviewerid.id,
            acc,
            developerByReviewerid,
            'Reviewer'
          );
        return {
          ...acc,
          ...developer,
          ...manager,
          ...reviewer,
        } as ParticipantsMap;
      },
      {} as ParticipantsMap
    )
  );
};

export const getTicketParticipantCount = (
  tasks: fetchDevTeamTickets_tickets_tasks[]
) => {
  const participants = new Set();
  if (!tasks[0]) return null;
  const {
    developerByDeveloperid,
    developerByManagerid,
    developerByReviewerid,
    task_reviews,
  } = tasks[0];

  if (developerByDeveloperid) {
    participants.add(developerByDeveloperid.id);
  }
  if (developerByManagerid) {
    participants.add(developerByManagerid.id);
  }
  if (developerByReviewerid) {
    participants.add(developerByReviewerid.id);
  }

  task_reviews?.forEach(({ developerId, managerId }) => {
    if (developerId) {
      participants.add(developerId);
      participants.add(managerId);
    }
  });

  return participants.size;
};

export const getGitStartHooksOrigins = [
  'https://hooks.gitstart.dev',
  'https://hooks.gitstart.com',
];

export const checkEqSet = (
  a?: Set<IntegrationType>,
  b?: Set<IntegrationType>
) => {
  if (!a || !b) return false;
  if (a.size !== b.size) return false;
  for (const el of a) if (!b.has(el)) return false;
  return true;
};

export const isProd = () => ENV === 'production';

export const getAppHost = () =>
  isProd() ? 'app.gitstart.com' : 'app.gitstart.dev';

export const getAppDateFormat = (date?: string | null) => {
  return moment(date ?? undefined).format(APP_DATE_FORMAT);
};

export const isAdmin = (ctx: Context) => {
  if (ctx.user?.roles.length) {
    return !!ctx.user.roles.find(r => r === 'admin');
  }
  return false;
};

export const getHoursDiffFromNow = (timestamp: MomentInput) =>
  moment.duration(moment(timestamp).diff(new Date())).asHours();

export const getSecondsDiffFromNow = (timestamp: MomentInput): number =>
  moment.duration(moment(timestamp).diff(new Date())).asSeconds();

export const getMinutesDiffFromNow = (timestamp: MomentInput): number =>
  moment.duration(moment(timestamp).diff(new Date())).asMinutes();

export const getDaysDifferenceFromNow = (timestamp: MomentInput): number =>
  moment.duration(moment(timestamp).diff(new Date())).asDays();

export const getTimeAgo = (
  timestamp: MomentInput,
  timestamp2?: MomentInput
): string => {
  let duration;
  const secondsFromNow = Math.round(Math.abs(getSecondsDiffFromNow(timestamp)));
  if (timestamp2 !== undefined) {
    const secondsFromNowSecondTag = Math.round(
      Math.abs(getSecondsDiffFromNow(timestamp2))
    );
    switch (true) {
      case Math.abs(secondsFromNow - secondsFromNowSecondTag) < 60:
        return 'now';
      case Math.abs(secondsFromNow - secondsFromNowSecondTag) < 3600:
        duration = Math.abs(
          Math.round(Math.abs(getMinutesDiffFromNow(timestamp))) -
            Math.round(Math.abs(getMinutesDiffFromNow(timestamp2)))
        );
        return `${duration} ${duration === 1 ? 'min' : 'mins'}`;
      case Math.abs(secondsFromNow - secondsFromNowSecondTag) < 3600 * 24:
        duration = Math.abs(
          Math.round(Math.abs(getHoursDiffFromNow(timestamp))) -
            Math.round(Math.abs(getHoursDiffFromNow(timestamp2)))
        );
        return `${duration} ${duration === 1 ? 'hour' : 'hours'}`;
      default:
        duration = Math.abs(
          Math.round(Math.abs(getDaysDifferenceFromNow(timestamp))) -
            Math.round(Math.abs(getDaysDifferenceFromNow(timestamp2)))
        );
        return `${duration} ${duration === 1 ? 'day' : 'days'}`;
    }
  } else {
    switch (true) {
      case secondsFromNow < 60:
        return 'now';
      case secondsFromNow < 3600:
        duration = Math.round(Math.abs(getMinutesDiffFromNow(timestamp)));
        return `${duration} ${duration === 1 ? 'min' : 'mins'}`;
      case secondsFromNow < 3600 * 24:
        duration = Math.round(Math.abs(getHoursDiffFromNow(timestamp)));
        return `${duration} ${duration === 1 ? 'hour' : 'hours'}`;
      default:
        duration = Math.round(Math.abs(getDaysDifferenceFromNow(timestamp)));
        return `${duration} ${duration === 1 ? 'day' : 'days'}`;
    }
  }
};

export const stdFlex = (
  flexDirection: Property.FlexDirection,
  alignItems: Property.AlignItems = 'center',
  justifyContent: Property.JustifyContent = 'center',
  marginRight = 0,
  marginBottom = 0
) => ({
  display: 'flex',
  alignItems,
  justifyContent,
  flexDirection,
  '& > *:not(:last-child)': {
    marginRight,
    marginBottom,
  },
});

export const sanitizeNum = (num: number) => {
  if (Number.isNaN(num)) return 0;
  return num;
};

export const calculateBudgetFromTasks = (
  tasks: { lowerBudget: number | null; upperBudget: number | null }[]
) => {
  return tasks.reduce<{ lower: number; upper: number }>(
    (a, c) => ({
      lower: a.lower + sanitizeNum(c.lowerBudget ?? 0),
      upper: a.upper + sanitizeNum(c.upperBudget ?? 0),
    }),
    { lower: 0, upper: 0 }
  );
};

export const isClient = (
  userInfo: {
    client_users:
      | {
          clientId: string;
          role: CUR;
        }[]
      | null;
    developer: { id: string } | null;
  } | null,
  clientId: string
) => {
  return !!userInfo?.client_users?.find(
    cu => cu.clientId === clientId && [CUR.admin, CUR.user].includes(cu.role)
  );
};

export const isExclusivelyClient = (
  userInfo: {
    client_users:
      | {
          clientId: string;
          role: CUR;
        }[]
      | null;
    developer: { id: string } | null;
  } | null
) => {
  return !userInfo?.developer?.id || userInfo?.developer?.id == '';
};
/**
 *
 * @param userInfo
 * @param clientId
 * @returns boolean - true if current user is an internal client user. typically project managers are internal client users
 */
export const isInternalClientUser = (
  userInfo: {
    client_users:
      | {
          clientId: string;
          role: CUR;
        }[]
      | null;
    developer: { id: string } | null;
  } | null,
  clientId: string
) => {
  return !!userInfo?.client_users?.find(
    cu => cu.clientId === clientId && [CUR.internal].includes(cu.role)
  );
};

export const calcBillableBudget = (
  budget: number,
  originalCost: number,
  billableCost: number
) => {
  const costDiffInPercent = (originalCost - billableCost) / originalCost;
  return Math.round((1 - costDiffInPercent) * budget);
};

export const calcDiscountInPercent = (
  originalCost: number,
  billableCost: number
) => {
  return Math.round((100 * (originalCost - billableCost)) / originalCost);
};

//TODO : Nuke this function as a part of discount to refund migration
export const calcDiscount = (original: number, discount: number) => {
  return Math.round((1 - discount / 100) * original);
};

type OS = 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | null;

export function getOS(): OS {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os: OS = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

type factsNode = {
  id?: string;
  userId: number;
  leaveId: number | null;
  hoursLogged: number;
  hoursLoggedOnOther: number;
  hoursRequired: number;
  leaveIsApproved: boolean | null;
  occurredAt: string;
  user_leave?: {
    id: string;
    leaveReason: string | null;
    leaveTypeId: user_leave_types_enum;
    deletedAt: string | null;
  } | null;
  [key: string]: any;
};

export const getCalendarStatesFromNode = (
  node: factsNode
): CallendarCellInfoType => {
  const WORK_PERCENTAGE_REQUIRED = 0.85;
  let state: State;

  const isWeekend = function (date1: string): boolean {
    let formatDate = moment(date1).format('YYYY-MM-DD');
    let day = new Date(formatDate).getDay();
    if (day === 6 || day === 0) {
      return true;
    } else {
      return false;
    }
  };

  let leaveReason: string = '';
  if (
    node.leaveId !== null &&
    node.leaveIsApproved !== false &&
    node.user_leave?.deletedAt === null
  ) {
    state = node.leaveIsApproved ? State.Leave : State.Pending;
    leaveReason = node.user_leave?.leaveReason as string;

    if (isWeekend(node.occurredAt)) {
      state = State.Nothing;
    }
  } else if (node.hoursLogged > 0) {
    state =
      node.hoursLogged < node.hoursRequired * WORK_PERCENTAGE_REQUIRED
        ? State.Incomplete
        : State.Full;
  } else {
    state = node.hoursRequired > 0 ? State.Missing : State.Nothing;
  }

  return {
    color: state,
    required: node.hoursRequired,
    logged: node.hoursLogged,
    leaveReason,
    ...(node.user_leave && {
      leaveType: node.user_leave.leaveTypeId,
    }),
  };
};

export type CallendarCellInfoType = {
  color: State;
  required: number;
  logged: number;
  leaveReason: string;
  [key: string]: any;
};

export const getETAMessage = (futureTime: string | undefined): string => {
  if (!futureTime) return 'ETA N/A';

  const eta = moment(futureTime)
    .endOf('day')
    .diff(moment().endOf('day'), 'days');

  return eta < 0
    ? 'ETA Elapsed'
    : eta === 1
    ? `${eta} day remaining`
    : `${eta} days remaining`;
};

export const possibleTicketStatuses: ticket_status_enum[] = [
  'backlog',
  'available',
  'in_progress',
  'under_review',
  'partially_under_review',
  'finished',
  'cancelled',
];

export const possibleTaskStatuses: tasks_status_enum[] = [
  'backlog',
  'available',
  'in_progress',
  'internal_review',
  'client_review',
  'needs_changes',
  'finished',
  'cancelled',
];

export const getTicketLink = (ticketLink: string): string | null => {
  const linkName = ticketLink.includes('github')
    ? 'GitHub Link'
    : ticketLink.includes('gitlab')
    ? 'GitLab Link'
    : ticketLink.includes('atlassian.net')
    ? 'Jira Link'
    : 'Ticket Link';
  return ticketLink === ''
    ? null
    : `
    <a href="${ticketLink}">
      [${linkName}]
    </a>
  `;
};

/**
 * @param {function} useStateCallback  - is a function which acts as a state callback,
 * it receives an initial stateand an optional acllback function which runs after the state update.
 * Function is used in same manner as the @param setState in class based functions
 *
 * @param {T} initialState  - is the initial state when setting the state which is of generic type T
 * @param {React.SetStateAction<T>} updatedState - is the updated state
 * @returns {Array} - returns state, handleState in an array where the state is the resolved state while
 * handle state is the result of the callback
 */
export const useStateCallback = <T>(
  initialState: T
): [
  state: T,
  setState: (
    updatedState: React.SetStateAction<T>,
    callback?: (updatedState: T) => void
  ) => void
] => {
  /**
   * On load of the function the provided state is set as the initial function state
   * @param {React.MutableRefObject<((updated: T) => void) | undefined>} callbackRef - is a reference
   * variable which we use to trigger the callback function call
   */
  const [state, setState] = useState<T>(initialState);
  const callbackRef = useRef<(updated: T) => void>();

  /**
   * Here @param {function } handleState runs the provided callback function which is optionally passed
   * after the state has been updated
   */
  const handleSetState = (
    updatedState: React.SetStateAction<T>,
    callback?: (updatedState: T) => void
  ) => {
    callbackRef.current = callback;
    setState(updatedState);
  };

  /**
   * useEffect here is triggered everytime there is a state update which also calls the  callback
   * function if the callback function is provided
   * */
  useEffect(() => {
    if (typeof callbackRef.current === 'function') {
      callbackRef.current(state);
      callbackRef.current = undefined;
    }
  }, [state]);

  return [state, handleSetState];
};

//TODO: refactor the place where the function is copied from > TicketDVTimeItemUI.tsx
export function calcTimeSpentOnTicket(ticket: TicketDVCostItem_ticket): number {
  let totalTaskOrganizedTimeInSecs = 0;
  for (let i = 0; i < ticket.tasks.length; i++) {
    for (
      let j = 0;
      j < (ticket.tasks[i]?.task_time_user_totals?.length || 0);
      j++
    ) {
      totalTaskOrganizedTimeInSecs += +(
        ticket.tasks[i]?.task_time_user_totals[j]?.totalOrganizedTimeInSecs || 0
      );
    }
  }

  let totalTicketOrganizedTimeInSecs = 0;
  for (let i = 0; i < ticket.ticket_time_user_totals.length; i++) {
    totalTicketOrganizedTimeInSecs += +(
      ticket.ticket_time_user_totals[i]?.totalOrganizedTimeInSecs || 0
    );
  }

  return totalTicketOrganizedTimeInSecs > 0 || totalTaskOrganizedTimeInSecs > 0
    ? Math.round(
        moment
          .duration(
            Math.round(
              +totalTicketOrganizedTimeInSecs + totalTaskOrganizedTimeInSecs
            ),
            'seconds'
          )
          .asHours() * 10
      ) / 10
    : 0;
}

export function calcBusinessDays({
  startAt,
  endAt,
}: {
  startAt: string;
  endAt: string;
}) {
  // Counting from start date to (end date-1), excluding weekends
  let start = new Date(startAt);
  let end = new Date(endAt);
  let totalBusinessDays = 0;
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  end.setDate(end.getDate() - 1);
  while (start <= end) {
    let day = start.getDay();
    if (day >= 1 && day <= 5) {
      ++totalBusinessDays;
    }
    start.setDate(start.getDate() + 1);
  }
  return totalBusinessDays;
}

export const parseTime = (digit: number) =>
  digit < 10 ? `0${digit}` : `${digit}`;

export const getRemainingTimeToStandup = (
  standupDateTime: string | null | undefined,
  currentDate: moment.Moment
) => {
  const standupDate = moment(standupDateTime || '2020-12-20T11:30:00+00:00'); // Set default time to community-wide standup time (GMT)

  const standupDateObject = standupDate.set({
    year: currentDate.year(),
    month: currentDate.month(),
    day: currentDate.day(),
    hours: standupDate.hours(),
    minutes: standupDate.minutes(),
    seconds: standupDate.seconds(),
  });

  const currentDateObject = currentDate;

  let defaultStandupObject = standupDateObject;

  // If standup  has passed for the day
  if (standupDateObject.diff(currentDateObject) < 1) {
    const today = moment().day(); // Current day: Mon/Tue/Wed, etc
    const numberOfDaysToAdd = today === 5 ? 3 : today === 6 ? 2 : 1;
    const nextStandup = moment().add(numberOfDaysToAdd, 'd'); // Add 1/2/3 days to get the next standup depending on the day of the week

    defaultStandupObject = nextStandup.set({
      year: nextStandup.year(),
      month: nextStandup.month(),
      day: nextStandup.day(),
      hours: standupDateObject.hours(),
      minutes: standupDateObject.minutes(),
      seconds: standupDateObject.seconds(),
    });
  }

  const timeDiffToNextStandup = moment.duration(
    defaultStandupObject.diff(currentDateObject)
  );

  return {
    hours: parseTime(timeDiffToNextStandup.hours()),
    minutes: parseTime(timeDiffToNextStandup.minutes()),
    seconds: parseTime(timeDiffToNextStandup.seconds()),
  };
};

export const deriveTeamsStandupTime = (
  standupDateTime: string | null | undefined
) => {
  const standupDate = moment(standupDateTime || '2021-12-20T11:30:00+00:00'); // Set default time to community-wide standup time (GMT)
  const currentDate = moment();

  const newStandupTime = {
    year: currentDate.year(),
    month: currentDate.month(),
    day: currentDate.date(),
    hours: standupDate.hours(),
    minutes: standupDate.minutes(),
    seconds: standupDate.seconds(),
  };

  const today = moment().day(); // Current day: Mon/Tue/Wed, etc
  const daysToSubract = today === 1 ? 3 : 1; // If today is Monday, subtract 3 days to get standup on Friday

  return moment(newStandupTime).subtract(daysToSubract, 'd').format();
};

export const round = (num: number, decimalPlaces: number): number => {
  return +num.toFixed(decimalPlaces);
};

/**
 * @param {function} useSearch  - is a function which acts as a delayed search every time a user types,
 * it receives an initial state as first parameter and timeout as second parameter.
 * The timeout is the duration period to search after when a user is typing.
 * Everytime a user is typing the callback to search is cancelled and only when a user stops typing then
 * the callback for search will be made after the duration specified. Default value is @value 500
 * Function is used in same manner as the @param setState in class based functions
 *
 * @param {T} initialState  - is the initial state when setting the state which is of generic type T
 * @param {number} timeout - is the duration to perform search after, default @value 500
 * @param {React.SetStateAction<T>} updatedState - is the updated state
 * @returns {Array} - returns state, handleState in an array where the state is the resolved state while
 * handle state is the result of the callback
 */
export const useSearch = <T>(
  initialState: T,
  timeout?: number
): [
  state: T,
  setState: (
    updatedState: React.SetStateAction<T>,
    callback?: (updatedState: T) => void
  ) => void
] => {
  /**
   * On load of the function the provided state is set as the initial function state
   * @param {React.MutableRefObject<((updated: T) => void) | undefined>} callbackRef - is a reference
   * variable which we use to trigger the callback function call
   */
  const [state, setState] = useState<T>(initialState);
  const callbackRef = useRef<(updated: T) => void>();

  /**
   * Here @param {function } handleState runs the provided callback function which is optionally passed
   * after the state has been updated
   */
  const handleSetState = (
    updatedState: React.SetStateAction<T>,
    callback?: (updatedState: T) => void
  ) => {
    callbackRef.current = callback;
    setState(updatedState);
  };

  /**
   *
   * useEffect here is triggered everytime there is a state update, but after the specified duration to perform delayed call to the
   * callback function that can therefore be used to perform search
   *
   * */
  useEffect(() => {
    if (typeof callbackRef.current === 'function') {
      const timeOutId = setTimeout(() => {
        callbackRef.current?.(state);
        callbackRef.current = undefined;
      }, timeout || 500); // If no timeout period is provided, use 500 as the default value

      return () => clearTimeout(timeOutId);
    }
  }, [state]);

  return [state, handleSetState];
};

/**
 * @param {function} searchWord  - is a function which helps to perform regex search for
 * a word from a paragraph/sentence
 *
 * Can be used in searching on search fields mostly with filter functions
 *
 * NB: Function is not an actual regex, but just performs in the same manner as a regex search
 *
 * @param wordToSearch - this is the word you need to search for from the paragraph/sentence
 * @param stringToSearchFrom - this is the actual paragraph/sentence containing words to be search
 *
 * @returns {boolean} boolean - returns a boolean value if the word being searched exists or not
 */
export const searchWord = (
  wordToSearch: string,
  stringToSearchFrom?: string
): boolean => {
  if (stringToSearchFrom) {
    const plainString = stringToSearchFrom
      .replace(/[^\w\s]/gi, '') //remove special characters like ;-+=
      .toLowerCase();
    const trimmedWord = wordToSearch
      .replace(/[^\w\s]/gi, '') //remove special characters like ;-+=
      .replace(/\s/g, '') // remove any trailing whitespaces
      .toLowerCase();

    return plainString.includes(trimmedWord);
  } else {
    return false;
  }
};
