import React from 'react';
import MuiCardHeader, { CardHeaderProps } from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(1),
  },
  action: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.3),
    marginBottom: theme.spacing(-0.5),
  },
}));

const CardHeader = ({
  titleTypographyProps = { variant: 'overline' },
  className,
  action,
  children,
  ...other
}: CardHeaderProps) => {
  const classes = useStyles();
  return (
    <MuiCardHeader
      titleTypographyProps={titleTypographyProps}
      className={clsx(classes.cardHeader, className)}
      action={<div className={classes.action}>{action}</div>}
      {...other}
    >
      {children}
    </MuiCardHeader>
  );
};

export default CardHeader;
export type { CardHeaderProps };
