// inspired from: https://github.com/hasura/graphql-engine/blob/master/community/sample-apps/nextjs-8-serverless/with-apollo-jwt/app/lib/init-apollo.js
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
import { ApolloLink, HttpLink, concat } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import fetch from 'isomorphic-unfetch';
import { getUserFromToken } from '@engine/common/auth';

export interface Global {
  fetch: typeof fetch;
}

declare let global: Global;

// Polyfill fetch() on the server (used by apollo-client)
if (typeof window !== 'undefined') {
  global.fetch = fetch;
}

// TODO: Later, pipe these errors to Sentry
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`
      );
      switch (extensions?.code) {
        case 'invalid-jwt':
          console.error('Login session has expired!');
          break;
        case 'data-exception':
        case 'validation-failed':
          console.error(message);
          break;
        default:
          console.log(extensions?.code);
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export default function create<T>(
  {
    token,
    secret,
    role,
    path,
    host,
  }: {
    token?: string;
    secret?: string;
    role?: string;
    path: string;
    host: string;
  },
  initialState?: T
): ApolloClient<NormalizedCacheObject> {
  const isSecure = !(
    host.indexOf('.local') !== -1 || host.indexOf('localhost') !== -1
  );
  const user = getUserFromToken(token);

  const httpLink = concat(
    new ApolloLink((operation, forward) => {
      const ctx = operation.getContext();
      const authRole = ctx.role || role;
      operation.setContext({
        headers: {
          ...(token && !secret
            ? { authorization: `Bearer ${token}` }
            : { 'x-hasura-admin-secret': secret }),
          ...(authRole ? { 'x-hasura-role': authRole } : {}),
          // Required so that remote schemas in hasura also get this field
          // given token is lost but headers are not in transition
          ...(user && user.id ? { 'x-hasura-user-id': user.id } : {}),
        },
      });

      return forward(operation);
    }),
    new HttpLink({
      uri: `${isSecure ? 'https' : 'http'}://${host}${path}`,
      credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    })
  );

  const networkLink: ApolloLink = httpLink;
  return new ApolloClient({
    assumeImmutableResults: true,
    connectToDevTools: typeof window !== 'undefined',
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: errorLink.concat(networkLink),
    cache: new InMemoryCache({}).restore(initialState || {}),
    defaultOptions: { query: { errorPolicy: 'all' } },
  });
}
