import { Avatar as AvatarDefault, Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import getInitials from 'template/utils/getInitials';

type Props = {
  alt?: string;
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  src?: string;
  name?: string;
};
const Avatar = (props: Props) => {
  const { alt, onClick, className, src, name, children } = props;
  return (
    <div>
      <Tooltip title={`${name}`}>
        <AvatarDefault
          alt={alt}
          onClick={onClick}
          className={className}
          src={src}
        >
          {!src && getInitials(name)}
          {children}
        </AvatarDefault>
      </Tooltip>
    </div>
  );
};

export default Avatar;
