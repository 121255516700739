import { Typography } from '@material-ui/core';
import ClientPayments from 'components/InvoicePayment/ClientPayments';
import { PaymentContext } from 'components/InvoicePayment/index';
import { Dashboard } from 'components/layouts/Dashboard';
import React, { useState } from 'react';
import OnBoardingScreen from './onBoardingScreen';
import { ClientDashboardProps, UserData } from './types';

export const ClientDashboard: React.FC<ClientDashboardProps> = props => {
  const { onPaymentComplete } = props;

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [contextControl, setContext] = useState(0);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<number[] | null>(
    null
  );

  const paymentComplete = async () => {
    if (onPaymentComplete) {
      await onPaymentComplete();
    }
    setContext(prev => prev + 1);
  };

  const contextValue = {
    control: contextControl,
    selectedInvoiceIds,
    activate: (selectedInvoiceIds: number[] | null = null) => {
      setPaymentModalOpen(true);
      setSelectedInvoiceIds(selectedInvoiceIds);
    },
  };

  const { onBoardedAt, id, name } = props.client;
  const { firstName, lastName, defaultEmail } = props.user;
  const userData: UserData = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: defaultEmail || '',
    clientId: id,
    companyName: name,
  };

  return (
    <PaymentContext.Provider value={contextValue}>
      <div>
        {onBoardedAt ? (
          <div>
            {props.children}
            <ClientPayments
              clientId={id}
              open={paymentModalOpen}
              selectedInvoiceIds={selectedInvoiceIds}
              onClose={() => setPaymentModalOpen(false)}
              onPaymentComplete={paymentComplete}
            />
          </div>
        ) : (
          <Dashboard>
            <OnBoardingScreen
              className="root"
              displayButton
              displayMessage
              userData={userData}
            >
              <Typography>
                Your account is not yet activated. Please complete setting up
                your account to start using GitStart.
              </Typography>
            </OnBoardingScreen>
          </Dashboard>
        )}
      </div>
    </PaymentContext.Provider>
  );
};
