import InvoicePayment from 'components/InvoicePayment';
import Modal from 'components/modals/Modal';
import { useApolloQuery } from 'lib/client/utils';
import { CLIENT_OVERVIEW_INVOICES } from '@engine/common/graphql/roles/client/generated/CLIENT_OVERVIEW_INVOICES';
import {
  fetchClientInvoicesOverview,
  fetchClientInvoicesOverviewVariables,
} from '@engine/common/graphql/roles/client/generated/fetchClientInvoicesOverview';
import { FETCH_CLIENT_INVOICES_OVERVIEW } from 'lib/graphql/roles/client/queries';
import React, { useEffect, useState } from 'react';
import { getInvoicesAnalytics } from './index';
import { useAuth } from '../auth';

type Props = {
  clientId: string;
  open: boolean;
  selectedInvoiceIds: null | number[];
  onClose: () => void;
  onPaymentComplete: () => void;
};

type BillingDetails = {
  unpaidInvoices: CLIENT_OVERVIEW_INVOICES[];
};

const ClientPayments: React.FC<Props> = (props: Props) => {
  const ctx = useAuth(true);
  const clientId = props.clientId;
  const selectedInvoiceIds = props.selectedInvoiceIds;

  const [billingDetails, setBillingDetails] = useState<BillingDetails>({
    unpaidInvoices: [],
  });
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  const { open, onClose, onPaymentComplete } = props;

  const { fetchQuery } = useApolloQuery<
    fetchClientInvoicesOverview,
    fetchClientInvoicesOverviewVariables
  >({
    ctx,
    role: 'client',
    query: FETCH_CLIENT_INVOICES_OVERVIEW,
    setError,
    setLoading: (value: boolean) => {
      setDataReady(false);
      setLoading(value);
    },
    setData: data => {
      if (!data) return;

      const { client_invoices } = data;

      const { unPaidInvoices: unpaidInvoices } = getInvoicesAnalytics(
        client_invoices
      );

      setBillingDetails({
        unpaidInvoices,
      });
    },
    variables: { clientId },
  });

  const paymentComplete = async () => {
    await Promise.all([onPaymentComplete(), fetchQuery()]);
  };

  useEffect(() => {
    setDataReady(true);
  }, [billingDetails]);

  if (error || !clientId) {
    return (
      <Modal
        maxWidth="lg"
        onClose={onClose}
        open={open}
        error={error}
        loading={!clientId}
      />
    );
  }
  if (loading || !dataReady) {
    return (
      <Modal
        maxWidth="lg"
        onClose={onClose}
        open={open}
        error={error}
        loading
      />
    );
  }

  const { unpaidInvoices } = billingDetails;

  return (
    <InvoicePayment
      clientId={clientId}
      open={open}
      close={onClose}
      onPaymentComplete={paymentComplete}
      selectedInvoiceIds={selectedInvoiceIds}
      unpaidInvoices={unpaidInvoices}
    />
  );
};

export default ClientPayments;
