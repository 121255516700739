import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Theme } from 'template/theme';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { fullWidth: boolean }>(() => ({
  Loading: {
    position: 'relative',
    display: 'inline-block',
  },
  prevChildren: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'fit-content'),
    display: 'inline-block',
  },
  children: {
    position: 'absolute',
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'initial'),
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'white',
    opacity: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export type LoadingProps = {
  loading?: boolean;
  fullWidth?: boolean;
  className?: string;
  children: ReactNode;
};

const Loading = (props: LoadingProps) => {
  const classes = useStyles({ fullWidth: !!props.fullWidth });
  const childrenRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  });
  const animationSize = Math.min(size.height, size.width) * 0.7;

  useLayoutEffect(() => {
    if (childrenRef.current) {
      const { height, width } = window.getComputedStyle(childrenRef.current);
      const pHeight = parseFloat(height);
      const pWidth = parseFloat(width);
      if (size.height !== pHeight || size.width !== pWidth) {
        setSize({
          height: pHeight,
          width: pWidth,
        });
      }
    }
  }, []);

  if (props.loading) {
    return (
      <div
        className={clsx(classes.Loading, props.className)}
        style={{ height: size.height, width: size.width }}
      >
        <div className={classes.children}>{props.children}</div>
        <div className={classes.overlay}>
          <CircularProgress size={animationSize} />
        </div>
      </div>
    );
  }
  return (
    <div
      className={clsx(classes.prevChildren, props.className)}
      ref={childrenRef}
    >
      {props.children}
    </div>
  );
};

export default Loading;
